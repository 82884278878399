import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { ISubMenu } from '../../../../interfaces/ISubmenu';

@Component({
    selector: 'entity-status-menu',
    templateUrl: 'entity-status-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class EntityStatusMenuComponent implements ISubMenu {
    @ViewChild('menu') public menu: MatMenu;
}
