import { SearchAsyncPipe } from "./search.pipe";
import { Pipe, ChangeDetectorRef } from "@angular/core";

@Pipe({
    name: 'includeSearchAsync'
})
export class IncludeSearchAsyncPipe extends SearchAsyncPipe {
    constructor(ref: ChangeDetectorRef) {
        super(ref, IncludeSearchAsyncPipe.search);
    }

    private static search(needle: string, haystack: string, caseSensitive: boolean): boolean {
        if (caseSensitive) {
            return haystack.includes(needle);
        }
        else {
            return haystack.toLocaleLowerCase().includes(needle.toLocaleLowerCase());
        }
    }
}
