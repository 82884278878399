﻿import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@luis/core';
import { CSHeaderModule } from 'cs-header';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AnnouncementCenterComponent } from './components/announcement-center/announcement-center.component';
import { LoaderComponent } from './components/loader/loader.component';
import { OverviewExamplesComponent } from './components/overview-examples/overview-examples.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { IsRouteActiveAsyncPipe } from './pipes/is-route-active-async.pipe';

@NgModule({
	imports: [CoreModule, IntercomUIModule, NgxPaginationModule, TranslateModule, RouterModule],
	declarations: [SpinnerComponent, OverviewExamplesComponent, LoaderComponent, AnnouncementCenterComponent, IsRouteActiveAsyncPipe],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		IntercomUIModule,
		CoreModule,
		TranslateModule,
		SpinnerComponent,
		OverviewExamplesComponent,
		LoaderComponent,
		NgxPaginationModule,
		AnnouncementCenterComponent,
		IsRouteActiveAsyncPipe
	]
})
export class SharedModule {}
