import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LuisModel } from '@luis/core';
import { DatasetResultsGraphingService } from '@luis/ui';
import { BehaviorSubject } from 'rxjs/Rx';
import { IDatasetDto } from '../../../interfaces/IDatasetDto';
import { CONFUSION_TYPE } from '../../../models/entity-confusion.model';

@Component({
	selector: 'dataset-results',
	templateUrl: 'dataset-results.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DatasetResultsGraphingService]
})
export class DatasetResultsComponent {
	@Input() public datasetDto: IDatasetDto;

	public selectedModel: BehaviorSubject<LuisModel> = new BehaviorSubject<LuisModel>(null);
	public selectedConfusionType: BehaviorSubject<CONFUSION_TYPE> = new BehaviorSubject<CONFUSION_TYPE>(null);
}
