import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

/**
 * @description
 * A directive that restricts tabbing to indexed
 * elements with a specified classname within the host element.
 */
@Directive({
    selector: '[tabWithinElement]'
})
export class TabWithinElementDirective implements AfterViewInit {
    @Input('tabWithinElement') public tabbableElementClassName: string;

    public tabbableElements: HTMLElement[];
    public elementIndex: number = -1;

    constructor(private _elementRef: ElementRef) {
        this.tabbableElements = new Array<HTMLElement>();
    }

    public ngAfterViewInit(): void {
        this.tabbableElements = this._elementRef.nativeElement.getElementsByClassName(this.tabbableElementClassName);
        this.onKeyUp(null);
    }

    /** listen for 'Tab' key presses */
    @HostListener('keyup.tab', ['$event'])
    public onKeyUp(e: KeyboardEvent): void {
        if (this.tabbableElements.length) {
            this._switchTabFocus();
        }
    }

    /**
     * @description
     * Looks at the current index of the currently focused element
     * and then switches focus to either the next tabbable
     * element or the parent element.
     */
    private _switchTabFocus(): void {
        if (this.elementIndex < this.tabbableElements.length - 1) {
            this.elementIndex += 1;
            this.tabbableElements[this.elementIndex].focus();
        } else {
            this.elementIndex = -1;
            this._elementRef.nativeElement.focus();
        }
    }
}
