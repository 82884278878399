import { ComponentRef } from '@angular/core';
import { ComponentPortal, Portal, TemplatePortal } from './portal';

/**
 * @description
 * Implementation of the PortalHost
 * that will be used to attach and detach
 * Portals (floating pieces of UI).
 */
export interface PortalHost {
    attach(portal: Portal<any>): any;
    detach(): any;
    dispose(): void;
    hasAttached(): boolean;
}

/**
 * @description
 * Partial implementation of PortalHost
 * that will attach and detach Template
 * & Component portals.
 */
export abstract class BasePortalHost implements PortalHost {
    private _attachedPortal: Portal<any>;
    private _isDisposed: boolean = false;
    private _disposeFn: () => void;

    /**
     * @method
     * @description
     * Attaches a portal to the portal host.
     */
    attach(portal: Portal<any>): any {
        if (!portal) {
            throw new Error('Tried to attach null portal to portal host!');
        }

        if (this.hasAttached()) {
            throw new Error('Portal host already has an attached portal!');
        }

        if (this._isDisposed) {
            throw new Error('Portal host has already been disposed.');
        }

        // determine the type of portal
        if (portal instanceof TemplatePortal) {
            this._attachedPortal = portal;
            return this.attachTemplatePortal(portal);
        } else if (portal instanceof ComponentPortal) {
            this._attachedPortal = portal
            return this.attachComponentPortal(portal);
        }

        // unkown type of portal
        throw new Error(`Tried to attach unkown portal type! Must be of type 'ComponentPortal' or 'TemplatePortal'`);
    }

    /**
     * @method
     * @description
     * Detaches a portal from the portal host.
     */
    detach() {
        if (this.hasAttached()) {
            this._attachedPortal._setAttachedHost(null);
            this._attachedPortal = null;
        }

        this._invokeDisposeFn();
    }

    /**
     * @method
     * @description
     * Detaches the current portal if it exists,
     * and then invokes the host's dispose function.
     */
    dispose() {
        if (this.hasAttached()) {
            this.detach();
        }

        this._invokeDisposeFn();
        this._isDisposed = true;
    }

    /**
     * @method
     * @description
     * Whether or not the portal currently
     * has an attached portal.
     */
    hasAttached(): boolean {
        return !!this._attachedPortal;
    }

    /**
     * @method
     * @description
     * Sets the portal host's dispose function.
     */
    setDisposeFn(fn: () => void) {
        this._disposeFn = fn;
    }

    /**
     * @method
     * @description
     * If the portal host has a dispose function,
     * it will be called and then reset to null.
     */
    private _invokeDisposeFn() {
        if (this._disposeFn) {
            this._disposeFn();
            this._disposeFn = null;
        }
    }

    abstract attachTemplatePortal(portal: TemplatePortal): Map<string, any>;
    abstract attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T>;
}
