import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UtteranceSentiment } from '@luis/utterances';

@Component({
	selector: 'sentiment-inspector',
	templateUrl: './sentiment-inspector.component.html',
	styleUrls: ['./sentiment-inspector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SentimentInspectorComponent {
	@Input() public sentiment: UtteranceSentiment = new UtteranceSentiment();
}
