import { Entity, ENTITY_TYPES, EntityRole } from './entity.model';

/**
 * @description
 * Represents an entity that is of parent type (composite or hierarchical). Inherits
 * from the Entity class.
 *
 * @param children The children of the parent entity.
 */
export class ParentEntity extends Entity {
    public static readonly CHILDREN_LIMIT: number = 10;
    public children: Entity[];

    constructor(
        id: string = '', name: string = '',
        type: number = ENTITY_TYPES.COMPOSITE, children: Entity[] = [],
        roles: EntityRole[] = [], parent: Entity = null) {
        super(id, name, type, 0, roles, parent);
        this.children = children;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): ParentEntity {
        const parentEntity: ParentEntity = <ParentEntity>Entity.constructBaseEntity(apiObject, new ParentEntity());
        parentEntity.children = (<any[]>apiObject.children).map(c => Entity.importFromApi(c));
        parentEntity.children.forEach(e => e.setParent(parentEntity));

        if (parentEntity.type === ENTITY_TYPES.HIERARCHICAL) {
            parentEntity.children.forEach(e => e.type = ENTITY_TYPES.HIERARCHICAL_CHILD);
        }

        return parentEntity;
    }

    /**
     * @description
     * Checks if this entity is valid.
     *
     * @returns True if the entity is valid and false otherwise.
     */
    public isValid(): boolean {
        return super.isValid() && this.children.length && this.children.find(c => !c.name.trim().length) === undefined;
    }

    /**
     * @description
     * Appends a child to the entity's children.
     */
    public appendChild(): Entity {
        const e: Entity = new Entity();

        if (this.type === ENTITY_TYPES.HIERARCHICAL) {
            e.type = ENTITY_TYPES.HIERARCHICAL_CHILD;
        }

        e.setParent(this);
        this.children.push(e);

        return e;
    }

    /**
     * @description
     * Deletes the child at the given index from the entity.
     *
     * @param index The index of the child to remove.
     */
    public removeChildByIndex(index: number): void {
        this.children.splice(index, 1);
    }

    /**
     * @description
     * Remove child by child reference.
     *
     * @param child The child to delete.
     */
    public removeChild(child: Entity): void {
        this.children = this.children.filter(c => c.id !== child.id);
    }

    /**
     * @description
     * Gets this entity's children with their full name.
     *
     * @returns The children of this entity with their full name.
     */
    public getChildrenWithFullName(): Entity[] {
        if (this.type === ENTITY_TYPES.HIERARCHICAL) {
            return this.children.map(c => new Entity(
                c.id,
                `${this.name}::${c.name}`,
                this.type === ENTITY_TYPES.HIERARCHICAL ? ENTITY_TYPES.HIERARCHICAL_CHILD : ENTITY_TYPES.SIMPLE,
                undefined,
                [],
                this
            ));
        }
        else {
            return [];
        }
    }

    /**
     * @description
     * Gets the child with the given id with its name as the full name
     * of the hierarchical entity.
     * @param childId The child to get the full name for.
     * @returns The child entity with the updated name.
     */
    public getChildWithFullName(childId: string): Entity {
        const child: Entity = this.children.find(e => e.id === childId).clone();
        child.name = `${this.name}::${child.name}`;

        return child;
    }

    /**
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            id: this.id,
            name: this.name,
            children: this.children.map(c => c.name)
        };
    }

    /**
     * @description
     * Deep clones the resource object.
     *
     * @param entityParent The parent to use instead of the object's current parent
     * in case the parent is cloned so child references the new parent.
     * @returns A deep clone of this object.
     */
    public clone(entityParent?: Entity): ParentEntity {
        const parentEntity: ParentEntity = <ParentEntity>Entity.cloneBaseEntity(this, new ParentEntity(), entityParent);
        parentEntity.children = this.children.map(c => c.clone(parentEntity));

        return parentEntity;
    }
}
