import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DictionaryLocalStorageService, LuisConstants } from '@luis/core';

/**
 * @description
 * Represents the modal for asking the user to take a survey.
 */
@Component({
	selector: 'survey',
	templateUrl: 'survey.component.html',
	styleUrls: ['survey.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyComponent implements OnInit {
	constructor(private readonly _dictionaryStorageService: DictionaryLocalStorageService) {}

	public ngOnInit(): void {
		this._dictionaryStorageService.setDictionary<number>(LuisConstants.SURVEY, { 0: 1 });
	}
}
