import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

@Injectable()
export class BlobErrorHttpInterceptor implements HttpInterceptor {
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError(err => {
				if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
					return from(this._handleError(err)).pipe(flatMap(e => throwError(e)));
				}

				return throwError(err);
			})
		);
	}

	private _handleError(err: any): Promise<HttpEvent<any>> {
		return new Promise((_, reject) => {
			const reader = new FileReader();
			reader.onload = (e: Event) => {
				try {
					const errMsg = JSON.parse((<any>e.target).result);
					reject(
						new HttpErrorResponse({
							error: errMsg,
							headers: err.headers,
							status: err.status,
							statusText: err.statusText,
							url: err.url
						})
					);
				} catch (e) {
					reject(err);
				}
			};
			reader.onerror = e => {
				reject(err);
			};
			reader.readAsText(err.error);
		});
	}
}
