import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { GenericPromptService, IToasterService, PromptButtonTypes, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { Entity } from '@luis/entities';
import { Intent } from '@luis/intents';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IPatternService, PATTERN_SERVICE_TOKEN } from '../../interfaces/IPatternService';
import { Pattern } from '../../models/pattern.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * @description
 * Represents an pattern row in the utterance table. Manages mediating events emitting
 * from entity and intent changes to the parent table.
 */
@Component({
    selector: 'pattern-row',
    templateUrl: 'pattern-row.component.html',
    styleUrls: ['./pattern-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternRowComponent implements OnInit {
    @Input() public pattern: Pattern;
    @Input() public intents: Observable<Intent[]>;
    @Input() public entities: Observable<Entity[]>;
    @Input() public isSelected: Observable<boolean>;
    @Output() public selectionChanged: EventEmitter<void> = new EventEmitter<void>();

    public currentIntent: Observable<string>;
    public editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _i18n: TranslateService,
        private _promptService: GenericPromptService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(PATTERN_SERVICE_TOKEN) private _patternService: IPatternService
    ) {}

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @method
     * @description
     * Changes the intent for this pattern to the given intent.
     *
     * @param intentName The intent name that the pattern should
     * change to.
     */
    public onIntentChange(intent: Intent): void {
        this.pattern.intentName = intent.name;
        this._patternService
            .update(this.pattern)
            .trackProgress(this._toasterService.add())
            .subscribe();
    }

    /**
     * @method
     * @description
     * Updates the given pattern.
     *
     * @param pattern The pattern to update.
     */
    public updatePattern(pattern: Pattern): void {
        pattern.text = pattern.text.trim();

        if (pattern.text === '') {
            return;
        }

        if (this.pattern.text.trim() !== pattern.text) {
            this._patternService
                .update(pattern)
                .trackProgress(this._toasterService.add())
                .subscribe();
        }

        this.editMode.next(false);
    }

    /**
     * @method
     * @description
     * Deletes this pattern.
     */
    public deletePattern(): void {
        this._promptService
            .prompt(
                this._i18n.instant('patterns.pattern-row.delete_pattern_q'),
                this._i18n.instant('patterns.pattern-row.are_you_sure_you_want_to_delete_pattern'),
                { ok: this._i18n.instant('patterns.pattern-row.ok'), cancel: this._i18n.instant('patterns.pattern-row.cancel') },
                { ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
            )
            .filter(choice => choice === 'ok')
            .flatMap(() => this._patternService.delete(this.pattern))
            .trackProgress(this._toasterService.add())
            .subscribe(() => null);
    }

    /**
     * @method
     * @description
     * Initialize the component.
     */
    private _initState(): void {
        this.currentIntent = Observable.of(this.pattern.intentName);
    }
}
