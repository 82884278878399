import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlatformModule } from '../platform/platform.module';
import { FocusTrapDeprecatedDirective, FocusTrapDirective, FocusTrapFactory } from './focus-trap';
import { InteractivityChecker } from './interactivity-checker';

@NgModule({
  imports: [CommonModule, PlatformModule],
  declarations: [FocusTrapDirective, FocusTrapDeprecatedDirective],
  exports: [FocusTrapDirective, FocusTrapDeprecatedDirective],
  providers: [InteractivityChecker, FocusTrapFactory]
})
export class A11yModule {}
