import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	Renderer2,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { SPACE } from '../../utils/keyboard/keycodes';
import { TableService } from '../services/table.service';
import { TableCellComponent } from '../table-cell/table-cell.component';

export type RowPadding = 'full' | 'half' | 'none';

@Component({
	selector: '[m-table-row]',
	templateUrl: 'table-row.component.html',
	styleUrls: ['table-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class TableRowComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
	@Input() @HostBinding('class.m-table-row-clickable') public isClickable: boolean = true;
	@Input() public isSelectable: boolean = false;
	@Input() public isSelected: boolean = false;
	@Input() public padding: RowPadding = 'half';

	@Output() public selectionChanged: EventEmitter<void> = new EventEmitter<void>();

	@HostBinding('class.selected') public get selectionClass(): boolean {
		return this.isSelectable ? this.isSelected : false;
	}
	@HostBinding('attr.tabIndex') public get tabIndex(): string {
		return this.isClickable ? '0' : null;
	}

	@ContentChildren(TableCellComponent, { read: ElementRef, descendants: true }) public cells: QueryList<ElementRef>;

	public multiSelect: Observable<boolean>;
	public showCheckbox: Observable<boolean>;
	public rowHovered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _cellSubscription: Subscription = new Subscription();

	constructor(
		private readonly _el: ElementRef,
		private readonly _renderer: Renderer2,
		private readonly _tableService: TableService,
		private readonly _liveAnnouncer: LiveAnnouncer
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['isSelected']) {
			this._tableService.setSelectionOnRow(this, changes['isSelected'].currentValue);
		}
	}

	public ngAfterContentInit(): void {
		this._initContentState();
	}

	public ngOnDestroy(): void {
		this._cellSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Marks the row as hovered on 'unhovered' when the mouse
	 * enters or leaves respectively.
	 */
	@HostListener('mouseenter', ['true'])
	@HostListener('mouseleave', ['false'])
	public handleMouseHovers(isHovered: boolean): void {
		this.rowHovered.next(isHovered);
	}

	/**
	 * @description
	 * Notifies that this row was selected when it is pressed
	 * on via space.
	 *
	 * @param event The keyboard event that fired.
	 */
	@HostListener('keydown', ['$event'])
	public handleKeyPress(event: KeyboardEvent): void {
		if (event.keyCode !== SPACE) {
			return;
		}

		if (document.activeElement !== this._el.nativeElement) {
			return;
		}

		if (this.isClickable && this.isSelectable) {
			this._liveAnnouncer.announce(`Row ${this.isSelected ? 'unselected' : 'selected'}`);
			this.selectionChanged.emit();
			event.preventDefault();
		}
	}

	/**
	 * @description
	 * Initialize the component
	 */
	private _initState(): void {
		this._setRowProperties();

		this.multiSelect = this._tableService.getMultiSelectStatus();

		this.showCheckbox = Observable.combineLatest(this._tableService.getSelectedRows(), this.rowHovered.asObservable()).map(
			([selectedRows, rowHovered]) => this.isSelectable && (selectedRows.length !== 0 || rowHovered)
		);
	}

	/**
	 * @description
	 * Initializes the component content.
	 */
	private _initContentState(): void {
		this._cellSubscription = this.cells.changes.subscribe(() => this._updateCellAriaIndices());
		this._updateCellAriaIndices();
	}

	/**
	 * @description
	 * Sets classes and role for the current row element.
	 */
	private _setRowProperties(): void {
		this._renderer.addClass(this._el.nativeElement, 'm-table-row');
		this._renderer.addClass(this._el.nativeElement, `m-table-row-padding-${this.padding}`);
		this._renderer.setAttribute(this._el.nativeElement, 'role', 'row');
	}

	/**
	 * @description
	 * Updates the cells aria col indices.
	 */
	private _updateCellAriaIndices(): void {
		this.cells.forEach((cell, index) => this._renderer.setAttribute(cell.nativeElement, 'aria-colindex', `${index + 1}`));
	}
}
