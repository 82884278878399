import {
    Injectable,
    Injector,
    ReflectiveInjector,
    ViewContainerRef,
    ComponentFactoryResolver
} from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { IInputHandlerPayload } from '../interfaces/IInputHandlerPayload';
import { IInputHandlerService } from '../interfaces/IInputHandlerService';

/**
 * @description
 * Represents a simple manager to keep track of key stroke handlers
 * and execute them when required.
 */
@Injectable()
export class InputHandlerService {
    private _handlers: IInputHandlerService[];

    constructor(private _injector: Injector) {
        this._handlers = [];
    }

    /**
     * @method
     * @description
     * Registers a keystroke handler to be fired when the manager is
     * instructed to execute.
     *
     * NOTE: This function needs to be modified. With migration to Angular 6,
     * reflectiveInjector is deprecated, and therefore Injector is used, which
     * requires explicitly stating the service's dependencies. Till now we only
     * have brace-handler service, which only requires ComponentFactorResolver.
     * But if one day we need to generalize, then a solution must be found to
     * pass the dependencies with the handler in order to inject it.
     *
     * @param handler A key stroke handler service.
     */
    public registerHandler(handler: any): void {
        const injector: Injector = Injector.create([
            { provide: handler, deps: [ComponentFactoryResolver] }
        ], this._injector);
        const service: IInputHandlerService = injector.get(handler);

        this._handlers.push(service);
    }

    /**
     * @method
     * @description
     * Executes all the keystroke handlers already registered.
     *
     * @param text The text to send to the handler.
     * @param responseStream A stream for the handler to respond on.
     */
    public execute(inputField: HTMLInputElement, responseStream: Subject<IInputHandlerPayload>, componentBed: ViewContainerRef): void {
        this._handlers.map(h => h.execute(inputField, responseStream, componentBed));
    }
}
