import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthRefresherComponent } from './components/auth-refresher/auth-refresher.component';
import { AuthService } from './services/auth.service';
import { BotFrameworkAuthService } from './services/botframework-auth.service';
import { UserService } from './services/user.service';

@NgModule({
	imports: [],
	declarations: [AuthRefresherComponent],
	exports: [AuthRefresherComponent]
})
export class AuthModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [AuthService, UserService, BotFrameworkAuthService]
		};
	}
}
