import { Injectable } from '@angular/core';
import { Segment } from '@luis/entities';

/**
 * @description
 * Keeps track of the last focused segment index, since any changes
 * to the labeled entities triggers a rerender of the segment
 * components and they lose their focus state. This service pertains
 * this state and is used by the components to intitalize focus on
 * themselves if they were the segments candidtate for focus before
 * entities model change.
 */
@Injectable()
export class SegmentFocusService {
    private _lastFocusedIndex: number;

    /**
     * @description
     * Gets the last focused index that the service stores.
     *
     * @returns The index of the token that the focus should
     * be on pertained from the last labeled entities segment
     * render cycle.
     */
    public get lastFocusedIndex(): number {
        return this._lastFocusedIndex;
    }

    /**
     * @description
     * Sets the last focused index that the service should store.
     * This call is made when any change in the labeled entities
     * occurs. That is, when a label is either added, deleted, etc.
     */
    public set lastFocusedIndex(index: number) {
        this._lastFocusedIndex = index;
    }

    /**
     * @description
     * Given the segment, checks if the index that should be focused
     * or not. Each segment hsa the responsibility of handling the
     * specific token to focus based on its innards.
     *
     * @param segment The segment that is considering to focus.
     * @returns True if the last focused index belongs to this segment
     * and false otherwise.
     */
    public shouldSegmentFocus(segment: Segment): boolean {
        if (this._lastFocusedIndex === undefined) {
            return false;
        }

        return segment.start <= this._lastFocusedIndex && this._lastFocusedIndex <= segment.end;
    }
}
