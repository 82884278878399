import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, FileService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
/**
 * @description
 * Contains functions for user account related operations.
 */
@Injectable()
export class UserService {
	constructor(
		private readonly _baseService: BaseService,
		private readonly _fileService: FileService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {}

	/**
	 * @description
	 * Gets the base url for all user related apis.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.webApiUrl}/user`;
	}

	/**
	 * @description
	 * Gets the current user's information.
	 *
	 * @returns An observable of the user info.
	 */
	public get(): Observable<User> {
		const url: string = this._baseUrl;

		return this._cacheService.get(url, User.importFromApi, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Gets the url to export user's data.
	 *
	 * @returns An observable to indicate completion.
	 */
	public export(): Observable<void> {
		const options = this._baseService.defaultOptionsBuilder.build();

		return this._fileService.download(`${this._baseUrl}/detailedInfo`, `${this._baseService.configs.userEmail}.json`, options);
	}

	/**
	 * @description
	 * Updates the current user information.
	 *
	 * @param user The user object to update
	 * @returns An observable to indicate completion.
	 */
	public update(user: User): Observable<void> {
		const url: string = this._baseUrl;

		return this._cacheService.put(url, user, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Deletes the current user account.
	 *
	 * @returns An observable to indicate completion.
	 */
	public delete(): Observable<boolean> {
		const url: string = this._baseUrl;

		return this._cacheService.delete(url, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Polls the service until subscription key is activated.
	 *
	 * @returns An observable of the poll status.
	 */
	public getAndPoll(): Observable<User> {
		return this.get()
			.retryWhen(errors =>
				errors
					.scan((_, error) => {
						if (error.status !== 429 && error.status !== 401) {
							throw error;
						}
					})
					.delay(2000)
			)
			.first();
	}

	/**
	 * @description
	 * Accepts the terms of use for the current user.
	 *
	 * @param user The user information.
	 * @returns An observable to indicate completion.
	 */
	public acceptTou(user: User): Observable<void> {
		const url: string = `${this._baseUrl}/termsofuse`;
		const cacheUrl: string = this._baseUrl;

		return this._httpService
			.post(url, user.exportToApi(), this._baseService.defaultOptionsBuilder.build())
			.flatMap(() => this._cacheService.post(cacheUrl, user, this._baseService.optionsBuilder.useCacheOnly().build()));
	}

	/**
	 * @description
	 * Resets the user's programmatic key.
	 *
	 * @returns An observable of the new key.
	 */
	public resetKey(): Observable<string> {
		const url: string = `${this._baseService.configs.apiUrl}/programmatickey`;

		return this._httpService
			.put<string>(url, {}, this._baseService.defaultOptionsBuilder.build())
			.do(key => this._baseService.setConfigs({ userSubKey: key }));
	}
}
