import { Injectable } from '@angular/core';
import lodashThrottle from 'lodash.throttle';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { TooltipLayerComponent } from '../tooltip/tooltip-layer.component';
import { Alignment } from '../utils/popupAlignment';
import { IRect } from '../utils/rectangle';

export interface ITooltipServiceShowArguments {
    alignment: Alignment;
    bounds: IRect;
    label: string;
    style?: string;
}

@Injectable()
export class TooltipService {
    private showTooltipSource = new Subject<ITooltipServiceShowArguments>();
    private _tooltipLayerRef: TooltipLayerComponent;

    public showTooltipObservable: Observable<ITooltipServiceShowArguments> = this.showTooltipSource.asObservable();

    /** Shows the tooltip layer. */
    public show(args: ITooltipServiceShowArguments): void {
        this.showTooltipSource.next(args);
        // once showing, hide on scroll
        const throttledHandler = lodashThrottle(() => {
            document.removeEventListener('scroll', throttledHandler, true);
            this.hide();
        }, 5000);

        document.addEventListener('scroll', throttledHandler, true);
    }

    /** Hides the tooltip layer. */
    public hide(): void {
        if (this._tooltipLayerRef) {
            this._tooltipLayerRef.show = false;
            this.showTooltipSource.next(null);
        } else {
            throw new Error('Tried to hide tooltip via TooltipService but TooltipLayer reference is null!');
        }
    }

    /** Registers the tooltip layer with the service providing member access. */
    public registerTooltipLayer(layerRef: TooltipLayerComponent): void {
        this._tooltipLayerRef = layerRef;
    }
}
