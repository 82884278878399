import { Injectable } from '@angular/core';
import { IResourceApiParser } from '@luis/core';
import { ClosedEntity } from '../models/closed-entity.model';
import { Entity, ENTITY_TYPES } from '../models/entity.model';
import { ParentEntity } from '../models/parent-entity.model';
import { PatternAnyEntity } from '../models/pattern-any-entity.model';
import { PrebuiltEntity } from '../models/prebuilt-entity.model';
import { RegexEntity } from '../models/regex-entity.model';

/**
 * @description
 * Represents an entity "factory" that provides methods to create an entity based
 * on the entity type.
 */
@Injectable()
export class EntityFactoryService {

    /**
     * @method
     * @description
     * Creates an entity based on the model received from the api object from the web.
     *
     * @param modelsApiObject A model received from the web.
     * @returns Returns an entity casted from the models.
     */
    public importFromApi(apiObject: any): Entity {
        const parser: IResourceApiParser = this.getParserFunction(apiObject.typeId);

        return parser ? <Entity>parser(apiObject) : undefined;
    }

    /**
     * @method
     * @description
     * Returns the parser function to use to parse the api object
     * as received from the server to generate the entity of the
     * given type.
     *
     * @param entityType The entity type to create.
     * @returns An parser function that returns an entity.
     */
    public getParserFunction(entityType: number): IResourceApiParser {
        switch (entityType) {
            case ENTITY_TYPES.SIMPLE:
            case ENTITY_TYPES.DOMAIN:
            case ENTITY_TYPES.HIERARCHICAL_CHILD:
                return Entity.importFromApi;
            case ENTITY_TYPES.PREBUILT:
                return PrebuiltEntity.importFromApi;
            case ENTITY_TYPES.COMPOSITE:
            case ENTITY_TYPES.HIERARCHICAL:
                return ParentEntity.importFromApi;
            case ENTITY_TYPES.CLOSED:
                return ClosedEntity.importFromApi;
            case ENTITY_TYPES.REGEX:
                return RegexEntity.importFromApi;
            case ENTITY_TYPES.PATTERN_ANY:
                return PatternAnyEntity.importFromApi;
            default:
                return undefined;
        }
    }

    /**
     * @method
     * @description
     * Returns a map of entity types and the prefixes to use when building
     * the url path.
     *
     * @param isRoot Flags whether the url required is of a root entity or not.
     * @returns A type prefix map to build url path
     */
    public getUrlPrefixMap(isRoot: boolean = true, isPost: boolean = false): Map<number, string> {
        const prefixMap: Map<number, string> = new Map<number, string>();

        if (isRoot) {
            prefixMap.set(ENTITY_TYPES.SIMPLE, 'entities');
            prefixMap.set(ENTITY_TYPES.DOMAIN, isPost ? 'customprebuiltentities' : 'models');
            prefixMap.set(ENTITY_TYPES.PREBUILT, 'prebuilts');
            prefixMap.set(ENTITY_TYPES.COMPOSITE, 'compositeentities');
            prefixMap.set(ENTITY_TYPES.HIERARCHICAL, 'hierarchicalentities');
            prefixMap.set(ENTITY_TYPES.HIERARCHICAL_CHILD, 'entities');
            prefixMap.set(ENTITY_TYPES.CLOSED, 'closedlists');
            prefixMap.set(ENTITY_TYPES.CLOSED_SUBLIST, 'sublists');
            prefixMap.set(ENTITY_TYPES.PATTERN_ANY, 'patternanyentities');
            prefixMap.set(ENTITY_TYPES.WHITELIST_ITEM, 'explicitlist');
            prefixMap.set(ENTITY_TYPES.ROLE, 'roles');
            prefixMap.set(ENTITY_TYPES.REGEX, 'regexentities');
        }
        else {
            prefixMap.set(ENTITY_TYPES.SIMPLE, 'children');
            prefixMap.set(ENTITY_TYPES.DOMAIN, 'children');
            prefixMap.set(ENTITY_TYPES.PREBUILT, 'children');
            prefixMap.set(ENTITY_TYPES.COMPOSITE, 'children');
            prefixMap.set(ENTITY_TYPES.HIERARCHICAL_CHILD, 'children');
            prefixMap.set(ENTITY_TYPES.HIERARCHICAL, 'children');
            prefixMap.set(ENTITY_TYPES.CLOSED, 'children');
            prefixMap.set(ENTITY_TYPES.CLOSED_SUBLIST, 'sublists');
            prefixMap.set(ENTITY_TYPES.PATTERN_ANY, 'children');
            prefixMap.set(ENTITY_TYPES.WHITELIST_ITEM, 'explicitlist');
            prefixMap.set(ENTITY_TYPES.ROLE, 'roles');
            prefixMap.set(ENTITY_TYPES.REGEX, 'children');
        }

        return prefixMap;
    }
}
