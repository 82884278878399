import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IPhraseListUtilitiesService } from '../interfaces/IPhraseListUtilitiesService';
import { Suggestion, SuggestionApiParameter } from '../models/suggestion.model';

/**
 * @description
 * Contains functions for miscellaneous methods for phrase lists.
 */
@Injectable()
export class PhraseListUtilitiesService implements IPhraseListUtilitiesService {
	constructor(@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService, private readonly _baseService: BaseService) {}

	/**
	 * @description
	 * Gets the base url for phrase list util calls.
	 *
	 * @returns The base url for api calls.
	 */
	get _baseUrl(): string {
		return `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}`;
	}

	/**
	 * @description
	 * Gets value suggestions for the given feature.
	 *
	 * @param param A parameter that aids in suggestion matching.
	 * @returns An observable of the fetched suggestions.
	 */
	public getSuggestions(id: number = 0, param: SuggestionApiParameter): Observable<Suggestion> {
		const path: string = `${this._baseUrl}/phraselists/${id}/suggest`;
		const data: string = JSON.stringify(param.exportToApi());

		return this._httpService
			.post(path, data, this._baseService.defaultOptionsBuilder.build())
			.map(response => Suggestion.importFromApi(response));
	}

	/**
	 * @description
	 * Gets previous suggestions for the given phrase list feature.
	 *
	 * @param id The feature id of the feature to get suggestions for.
	 * @returns An observable of the suggestions for the feature.
	 */
	public getPreSuggestions(id: number = 0): Observable<Suggestion> {
		const path: string = `${this._baseUrl}/phraselists/${id}/presuggestion`;

		if (id === 0) {
			return Observable.of(null);
		}

		return this._httpService
			.get(path, this._baseService.defaultOptionsBuilder.build())
			.map(response => Suggestion.importFromApi(response));
	}
}
