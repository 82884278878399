import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { WordMatchAsyncPipe } from '../../pipes/word-match-bold-async.pipe';
import { SharedModule } from '../../shared/shared.module';
import { EntityDropdownComponent } from './entity-dropdown/entity-dropdown.component';
import { RoleDropdownComponent } from './role-dropdown/role-dropdown.component';

@NgModule({
    imports: [
        SharedModule,
        MatMenuModule,
        TranslateModule
    ],
    exports: [
        EntityDropdownComponent,
        RoleDropdownComponent
    ],
    declarations: [
        EntityDropdownComponent,
        RoleDropdownComponent,
        WordMatchAsyncPipe
    ],
    providers: []
})
export class DropdownsModule { }
