import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { BaseItemFilter, IToolBarOps, LuisConstants } from '@luis/core';
import { Entity } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { DropdownComponent } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { EntityItemFilter } from '../../../models/filters/entity-item-filter.model';
import { EVALUATION_TYPES, EvaluationFilter, EvaluationFilters } from '../../../models/filters/evaluation-filter.model';
import { ExternalFilters } from '../../../models/filters/external-filters.model';
import { INTENT_FILTER_TYPES, IntentItemFilter } from '../../../models/filters/intent-item-filter.model';
import { ScoreFilter } from '../../../models/filters/score-filter.model';
import { VIEW_OPTIONS } from '../../../models/plain-segment.model';
import { ItemTableService } from '../../../services/item-table.service';

export interface IPillValue {
	title: string;
	text: string;
	filter: number;
	value: any;
	icon: string;
}

@Component({
	selector: 'toolbar',
	templateUrl: 'toolbar.component.html',
	styleUrls: ['toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public results: number;
	@Input() public trainingDate: Date;
	@Input() public toolbarOps: Observable<IToolBarOps>;
	@Input() public utteranceEntities: Observable<boolean>;
	@Input() public externalFilters: Observable<ExternalFilters>;
	@Input() public modelId: Observable<string> = Observable.of('');

	@Output() public editClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public evaluationRequired: EventEmitter<void> = new EventEmitter();
	@Output() public deleteClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public queryChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() public addAsPatternClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public reassignClicked: EventEmitter<Intent> = new EventEmitter<Intent>();
	@Output() public tableViewChanged: EventEmitter<VIEW_OPTIONS> = new EventEmitter<VIEW_OPTIONS>();
	@Output() public filtersChanged: EventEmitter<BaseItemFilter[]> = new EventEmitter<BaseItemFilter[]>();

	public intents: Observable<Intent[]>;
	public filterEntities: Observable<Entity[]>;
	public entitiesToChoose: Observable<Entity[]>;
	public entityViewMode: Observable<VIEW_OPTIONS>;
	public filterSelectedIntents: Observable<Map<string, Intent>>;
	public filterSelectedEntities: Observable<Map<string, Entity>>;

	public trainingDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
	public filterButtonClicked: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	public isFilterActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public toggleSearchBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public filterPills: BehaviorSubject<IPillValue[]> = new BehaviorSubject<IPillValue[]>([]);
	public activeEvaluations: BehaviorSubject<EvaluationFilters> = new BehaviorSubject<EvaluationFilters>({
		correct: false,
		incorrect: false,
		unclear: false
	});
	public errorsViewMode: BehaviorSubject<VIEW_OPTIONS> = new BehaviorSubject<VIEW_OPTIONS>(VIEW_OPTIONS.ALL_VIEW);
	public tableViewMode: BehaviorSubject<VIEW_OPTIONS> = new BehaviorSubject<VIEW_OPTIONS>(VIEW_OPTIONS.SIMPLE_TABLE_VIEW);

	public viewOps: typeof VIEW_OPTIONS = VIEW_OPTIONS;
	public itemFilters: Map<LuisConstants.ITEM_TABLE_FILTERS, BaseItemFilter> = new Map<LuisConstants.ITEM_TABLE_FILTERS, BaseItemFilter>();

	public filterResults: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private readonly _unsubscribeSubject: Subject<void> = new Subject<void>();

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _itemTableService: ItemTableService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.results) {
			this.filterResults.next(changes.results.currentValue);
		}
		if (changes.trainingDate) {
			this.trainingDate$.next(changes.trainingDate.currentValue);
		}
	}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._unsubscribeSubject.next(null);
		this._unsubscribeSubject.complete();
	}

	/**
	 * @description
	 * Notifies the parent that the reassign button was clicked.
	 */
	public reassignIntent(intent: Intent, reassignDropdown: DropdownComponent): void {
		this.reassignClicked.emit(intent);

		if (reassignDropdown) {
			reassignDropdown._clearSelection();
		}
	}

	/**
	 * @description
	 * Notifies the parent that the delete button was clicked.
	 */
	public delete(): void {
		this.deleteClicked.emit();
	}

	/**
	 * @description
	 * Togelse the view of the selected option.
	 *
	 * @param option The selected option.
	 */
	public viewOptionsChanged(option: number, viewOptionsField: DropdownComponent): void {
		if (viewOptionsField) {
			viewOptionsField._clearSelection();
		}
		switch (option) {
			case VIEW_OPTIONS.ENTITY_LABEL_VIEW:
			case VIEW_OPTIONS.TOKEN_LABEL_VIEW:
				this._itemTableService.toggleViewMode();
				break;
			case VIEW_OPTIONS.SIMPLE_TABLE_VIEW:
			case VIEW_OPTIONS.DETAILED_TABLE_VIEW:
				this._toggleTableView(option, viewOptionsField);
			default:
		}
	}

	/**
	 * @description
	 * Toggles the search bar visiblity.
	 */
	public toggleSearch(): void {
		const currentState: boolean = this.toggleSearchBar.getValue();
		this.toggleSearchBar.next(!currentState);

		// It will be closed.
		if (currentState) {
			this.queryChanged.next('');
		}
	}

	/**
	 * @description
	 * Toggles the intent item filter activation.
	 */
	public toggleIntent(intent: Intent): void {
		this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.INTENT).toggleActivation(intent);
		this.evaluationRequired.emit();
		this._publishFiltersState();
	}

	public evaluationFilterChanged(types: EvaluationFilters): void {
		this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.EVALUATION).toggleActivation(types);
		this.evaluationRequired.emit();
		this._publishFiltersState();
	}

	public toggleScore([min, max]: [number, number]): void {
		this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.SCORE).toggleActivation([min, max]);
		this._publishFiltersState();
	}

	/**
	 * @description
	 * Toggles the entity item filter activation.
	 */
	public toggleEntity(entity: Entity): void {
		this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.ENTITY).toggleActivation(entity);
		this._publishFiltersState();
	}

	public clearFilters(): void {
		this.itemFilters.forEach(iF => {
			if (iF.isActive()) {
				iF.toggleActivation();
			}
		});
		this._publishFiltersState();
	}

	public deleteFilterPill(pill: IPillValue): void {
		switch (pill.filter) {
			case LuisConstants.ITEM_TABLE_FILTERS.ENTITY:
				this.toggleEntity(pill.value);
				break;
			case LuisConstants.ITEM_TABLE_FILTERS.INTENT:
				this.toggleIntent(pill.value);
				break;
			case LuisConstants.ITEM_TABLE_FILTERS.SCORE:
				this.toggleScore([0, 1]);
				break;
			case LuisConstants.ITEM_TABLE_FILTERS.EVALUATION:
				const types = (<EvaluationFilter>this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.EVALUATION)).getTypes();
				const currTypes = {
					correct: pill.value !== EVALUATION_TYPES.CORRECT ? types.correct : !types.correct,
					incorrect: pill.value !== EVALUATION_TYPES.INCORRECT ? types.incorrect : !types.incorrect,
					unclear: pill.value !== EVALUATION_TYPES.UNCLEAR ? types.unclear : !types.unclear
				};
				this.evaluationFilterChanged(currTypes);
				break;
			default:
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._registerItemFilters();

		this.intents = combineLatest(this._intentService.get(), this.modelId).pipe(
			map(([intents, id]) => intents.filter(i => i.id !== id))
		);

		this.entityViewMode = this._itemTableService.getViewMode();

		Observable.combineLatest(this.externalFilters, this.intents)
			.pipe(
				filter(([filters, intents]) => filters.applied),
				takeUntil(this._unsubscribeSubject)
			)
			.subscribe(([filters, intents]) => {
				this.viewOptionsChanged(VIEW_OPTIONS.DETAILED_TABLE_VIEW, null);
				this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.EVALUATION).toggleActivation(filters.evaluation);
				this.evaluationRequired.emit();
				intents.filter(i => filters.nearestRivalIntentsIds.indexOf(i.id) !== -1).forEach(i => this.toggleIntent(i));
				this._publishFiltersState();
			});
	}

	/**
	 * @description
	 * Toggles the table view.
	 *
	 * @param option The new mode.
	 */
	private _toggleTableView(option: number, viewOptionsField: DropdownComponent): void {
		this.tableViewMode.next(option);
		this.tableViewChanged.emit(option);

		if (viewOptionsField) {
			viewOptionsField._clearSelection();
		}
	}

	/**
	 * @description
	 * Registers the supported utterance filters.
	 */
	private _registerItemFilters(): void {
		const entityFilter: EntityItemFilter = new EntityItemFilter();
		const intentFilter: IntentItemFilter = new IntentItemFilter(INTENT_FILTER_TYPES.NEAREST_RIVAL);
		const scoreFilter: ScoreFilter = new ScoreFilter();
		const evaluationFilter: EvaluationFilter = new EvaluationFilter();

		entityFilter.setTrainingDate(this.trainingDate$);
		intentFilter.setTrainingDate(this.trainingDate$);
		scoreFilter.setTrainingDate(this.trainingDate$);
		evaluationFilter.setTrainingDate(this.trainingDate$);

		this.filterSelectedEntities = entityFilter.getEntities().map(entities => {
			const entitiesMap = new Map<string, Entity>();
			entities.forEach(e => entitiesMap.set(e.id, e));

			return entitiesMap;
		});
		this.filterSelectedIntents = intentFilter.getIntents().map(intents => {
			const intentsMap = new Map<string, Intent>();
			intents.forEach(i => intentsMap.set(i.id, i));

			return intentsMap;
		});
		this.activeEvaluations = evaluationFilter.getTypesAsync;

		this.itemFilters.set(LuisConstants.ITEM_TABLE_FILTERS.ENTITY, entityFilter);
		this.itemFilters.set(LuisConstants.ITEM_TABLE_FILTERS.INTENT, intentFilter);
		this.itemFilters.set(LuisConstants.ITEM_TABLE_FILTERS.SCORE, scoreFilter);
		this.itemFilters.set(LuisConstants.ITEM_TABLE_FILTERS.EVALUATION, evaluationFilter);
	}

	private _publishFiltersState(): void {
		const filters = Array.from(this.itemFilters.values());
		this.isFilterActive.next(filters.reduce((acc, filter) => acc || filter.isActive(), false));
		this.filtersChanged.emit(filters);

		const entries = Array.from(this.itemFilters.entries());
		const pills: IPillValue[] = [];
		entries.forEach(([k, v]) => {
			if (!v.isActive()) {
				return;
			}

			switch (k) {
				case LuisConstants.ITEM_TABLE_FILTERS.ENTITY:
					(<EntityItemFilter>v).entities.forEach(e =>
						pills.push({
							title: this._i18n.instant('utterances.toolbar.entity_filter_pill'),
							text: e.name,
							filter: LuisConstants.ITEM_TABLE_FILTERS.ENTITY,
							value: e,
							icon: ''
						})
					);
					break;
				case LuisConstants.ITEM_TABLE_FILTERS.SCORE:
					const [min, max]: [number, number] = (<ScoreFilter>v).getRnge();
					pills.push({
						title: this._i18n.instant('utterances.toolbar.score_filter_pill'),
						text: `${min} ${this._i18n.instant('utterances.toolbar.score_pill_text')} ${max}`,
						filter: LuisConstants.ITEM_TABLE_FILTERS.SCORE,
						value: [min, max],
						icon: ''
					});
					break;
				case LuisConstants.ITEM_TABLE_FILTERS.EVALUATION:
					this.viewOptionsChanged(VIEW_OPTIONS.DETAILED_TABLE_VIEW, null);
					const types = (<EvaluationFilter>v).getTypes();
					if (types.correct) {
						pills.push({
							title: this._i18n.instant('utterances.toolbar.difference_filter_pill_title'),
							text: this._i18n.instant('utterances.toolbar.difference_correct_pill'),
							filter: LuisConstants.ITEM_TABLE_FILTERS.EVALUATION,
							value: EVALUATION_TYPES.CORRECT,
							icon: ''
						});
					}
					if (types.incorrect) {
						pills.push({
							title: this._i18n.instant('utterances.toolbar.difference_filter_pill_title'),
							text: this._i18n.instant('utterances.toolbar.difference_incorrect_pill'),
							filter: LuisConstants.ITEM_TABLE_FILTERS.EVALUATION,
							value: EVALUATION_TYPES.INCORRECT,
							icon: 'SkypeCircleMinus'
						});
					}
					if (types.unclear) {
						pills.push({
							title: this._i18n.instant('utterances.toolbar.difference_filter_pill_title'),
							text: this._i18n.instant('utterances.toolbar.difference_unclear_pill'),
							filter: LuisConstants.ITEM_TABLE_FILTERS.EVALUATION,
							value: EVALUATION_TYPES.UNCLEAR,
							icon: 'AlertTriangle'
						});
					}
					break;
				case LuisConstants.ITEM_TABLE_FILTERS.INTENT:
					this.viewOptionsChanged(VIEW_OPTIONS.DETAILED_TABLE_VIEW, null);
					(<IntentItemFilter>v).intents.forEach(i =>
						pills.push({
							title: this._i18n.instant('utterances.toolbar.nerest_intent_filter_pill'),
							text: i.name,
							filter: LuisConstants.ITEM_TABLE_FILTERS.INTENT,
							value: i,
							icon: ''
						})
					);
					break;
				default:
			}
		});
		this.filterPills.next(pills);
	}
}
