﻿import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
	BaseService,
	GenericPromptService,
	IResourceOperation,
	IToasterService,
	LuisConstants,
	PaginationCacheService,
	PaginationUIPayload,
	ProgressTracker,
	PromptButtonTypes,
	TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/Rx';
import { APP_SERVICE_TOKEN, IAppService } from '../../interfaces/IAppService';
import { APP_UTILITIES_SERVICE_TOKEN, IAppUtilitiesService } from '../../interfaces/IAppUtilitiesService';
import { APP_OPERATIONS } from '../../models/app-operations.model';
import { App } from '../../models/app.model';
import { AppCreationModalComponent } from './app-creation-modal/app-creation-modal.component';
import { AppImportModalComponent } from './app-import-modal/app-import-modal.component';
import { AppLogsImportModalComponent } from './app-logs-import-modal/app-logs-import-modal.component';
import { AppRenameModalComponent } from './app-rename-modal/app-rename-modal.component';

/**
 * @description
 * Represents an app list component that hosts an app table. The app list is
 * responsible for any service communcation and mediating between children components and
 * external services.
 */
@Component({
	selector: 'app-list',
	templateUrl: './app-list.component.html',
	styleUrls: ['./app-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppListComponent implements OnInit, OnDestroy {
	@Output() public appClicked: EventEmitter<App> = new EventEmitter<App>();
	@Output() public newAppCreated: EventEmitter<App> = new EventEmitter<App>();

	public pageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
	public query$: BehaviorSubject<string> = new BehaviorSubject<string>('');

	public paginationUIPayload: PaginationUIPayload<App> = new PaginationUIPayload();

	private readonly _getPageSubscription: Subscription = new Subscription();
	private readonly _chunkingTracker: ProgressTracker = new ProgressTracker();
	private readonly _unsubscribeSubject: Subject<void> = new Subject<void>();

	constructor(
		private readonly _promptService: GenericPromptService,
		private readonly _baseService: BaseService,
		private readonly _i18n: TranslateService,
		private readonly _dialogService: MatDialog,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_UTILITIES_SERVICE_TOKEN) private readonly _appUtilService: IAppUtilitiesService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._getPageSubscription.unsubscribe();
		this._unsubscribeSubject.next(null);
		this._unsubscribeSubject.complete();
		this.paginationUIPayload.dispose();
	}

	/**
	 * @description
	 * Fires an event that an app was clicked.
	 *
	 * @param app The app that was clicked.
	 */
	public onAppClicked(app: App): void {
		this.appClicked.emit(app);
	}

	/**
	 * @description
	 * Applies the given operation on the app based on received.
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	public onOperationClicked(appOp: IResourceOperation): void {
		switch (appOp.operation) {
			case APP_OPERATIONS.RENAME:
				this._createRenameAppModal(appOp);
				break;
			case APP_OPERATIONS.EXPORT:
				this._exportApp(appOp);
				break;
			case APP_OPERATIONS.EXPORT_CONTAINER_PRODUCTION:
				this._exportForContainer(appOp, false);
				break;
			case APP_OPERATIONS.EXPORT_CONTAINER_STAGING:
				this._exportForContainer(appOp, true);
				break;
			case APP_OPERATIONS.EXPORT_LOGS:
				this._exportLogs(appOp);
				break;
			case APP_OPERATIONS.DELETE:
				this._deleteApps(appOp);
				break;
			case APP_OPERATIONS.ADD:
				this._createNewApp(appOp);
				break;
			case APP_OPERATIONS.IMPORT:
				this._importApp(appOp);
				break;
			case APP_OPERATIONS.IMPORT_LOGS:
				this._importLogs(appOp);
				break;
			default:
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		const chunkingTrigger$ = this.query$.pipe(map(query => query.length > 0));
		const page$ = combineLatest(this.pageIndex$, this.query$).pipe(
			switchMap(([pageIndex, query]) =>
				this._appService.get(pageIndex - 1, false, query.length > 0).pipe(takeUntil(this._unsubscribeSubject))
			)
		);

		this._appService.init(chunkingTrigger$, this._chunkingTracker);

		this.paginationUIPayload.init({
			page$,
			pageIndex$: this.pageIndex$,
			chunkingTrigger$,
			chunkingTracker: this._chunkingTracker,
			config: { ngxPaginationId: 'apps', pageSize: PaginationCacheService.PAGE_SIZE }
		});
	}

	/**
	 * @description
	 * Opens up the modal for app creation. Notifies the parent
	 * component that an app was created on successfull creation.
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	private _createNewApp(appOp: IResourceOperation): void {
		this._dialogService
			.open(AppCreationModalComponent)
			.afterClosed()
			.filter(app => app)
			.subscribe(app => {
				appOp.response.complete();
				this.newAppCreated.emit(app);
			});
	}

	/**
	 * @description
	 * Opens up the modal for app import. Notifies the parent
	 * component that an app was created on successfull creation.
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	private _importApp(appOp: IResourceOperation): void {
		this._dialogService
			.open(AppImportModalComponent, { width: '600px' })
			.afterClosed()
			.filter(app => app)
			.subscribe(app => {
				appOp.response.complete();
				this.newAppCreated.emit(app);
			});
	}

	/**
	 * @description
	 * Opens up the modal for app logs import.
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	private _importLogs(appOp: IResourceOperation): void {
		this._dialogService
			.open(AppLogsImportModalComponent, { width: '600px', data: appOp.items[0] })
			.afterClosed()
			.subscribe(() => appOp.response.complete());
	}

	/**
	 * @description
	 * Exports the given application.
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	private _exportApp(appOp: IResourceOperation): void {
		this._appUtilService.exportApp(<App>appOp.items[0]).subscribe(() => appOp.response.complete());
	}

	/**
	 * @description
	 * Exports the app as a container for the given slot.
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 * @param isStaging The slot to export.
	 */
	private _exportForContainer(appOp: IResourceOperation, isStaging: boolean): void {
		this._appUtilService
			.exportForContainer(<App>appOp.items[0], isStaging)
			.trackProgress(
				this._toasterService.add({
					endMsg: [
						{ text: this._i18n.instant('apps.app-list.export_toast') },
						{
							text: this._i18n.instant('apps.app-list.learn_more_toast'),
							handler: () => window.open(LuisConstants.LUIS_CONTAINER_DOCS, '_blank')
						}
					],
					isDismissible: true
				})
			)
			.subscribe(() => appOp.response.complete());
	}

	/**
	 * @description
	 * Exports the given application logs
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	private _exportLogs(appOp: IResourceOperation): void {
		this._appUtilService.getExportLogsUrl(<App>appOp.items[0]).subscribe(url => {
			appOp.response.complete();
			window.location.href = url;
		});
	}

	/**
	 * @description
	 * Creates a prompt modal to delete the given app(s).
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	private _deleteApps(appOp: IResourceOperation): void {
		const apps: App[] = <App[]>appOp.items;
		const isBatch: boolean = apps.length > 1;
		const isOwner: boolean = apps[0].ownerEmail.toLocaleLowerCase() === this._baseService.configs.userEmail.toLocaleLowerCase();
		const title: string = isBatch ? 'Delete apps?' : isOwner ? 'Delete app?' : 'Leave app?';
		const loader: string = isBatch ? 'Deleting apps' : isOwner ? 'Deleting app' : 'Leaving app';
		const message: string = isBatch
			? 'Are you sure you want to delete/leave those applications?'
			: isOwner
			? `Are you sure you want to delete app '${apps[0].name}'?`
			: `Are you sure you want to no longer be a collaborator on app '${apps[0].name}'?`;

		this._promptService
			.prompt(title, message, { Ok: 'Ok', Cancel: 'Cancel' }, { Cancel: PromptButtonTypes.Default, Ok: PromptButtonTypes.Danger })
			.filter(choice => choice === 'Ok')
			.flatMap(() => this._appService.batchDelete(apps).trackProgress(this._toasterService.add({ startMsg: loader })))
			.filter(response => response !== null)
			.subscribe(() => appOp.response.complete());
	}

	/**
	 * @description
	 * Creates an app rename modal.
	 *
	 * @param appOp The application(s) and the operation to apply on it.
	 */
	private _createRenameAppModal(appOp: IResourceOperation): void {
		this._dialogService
			.open(AppRenameModalComponent, { width: '600px', data: appOp.items[0] })
			.afterClosed()
			.subscribe(() => appOp.response.complete());
	}
}
