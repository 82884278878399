/**
 * @description
 * An enumeration of the operations that you can apply on an intent.
 */
export enum INTENT_OPERATIONS {
    ADD,
    ADD_PREBUILT,
    RENAME,
    DELETE
}
