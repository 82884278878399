import { Directive, HostBinding, HostListener } from '@angular/core';

/**
 * @description
 * This directive adds a focus container class to ensure that
 * focus styling appears only when keyboard is used.
 */
@Directive({
	selector: '[disableMouseFocus]'
})
export class DisableMouseFocusDirective {
	@HostBinding('class.m-focus-container') public hasClass: boolean = true;
	@HostBinding('class.m-has-focus') public isKeyFocus: boolean;

	/**
	 * @description
	 * Disable focus styling when mouse is used.
	 */
	@HostListener('document:mousedown')
	public onMouseDown(): void {
		this.isKeyFocus = false;
	}

	/**
	 * @description
	 * Enable focus styling when keyboar is used.
	 */
	@HostListener('document:keydown')
	public onKeyDown(): void {
		this.isKeyFocus = true;
	}
}
