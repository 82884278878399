import { IResource } from '@luis/core';

/**
 * @description
 * Represents the states a dataset can be in.
 */
export enum DATASET_RUN_STATE {
    NOT_RUN,
    RUNNING,
    SUCCESS,
    FAIL
}

/**
 * @description
 * Represents a batch dataset metadata object.
 */
export class DatasetMetadata implements IResource {
    public id: string;
    public name: string;
    public size: number;
    public lastRun: Date;
    public lastResult: number;

    constructor(
        id: string = '', name: string = '',
        size: number = null, lastRun: Date = null,
        lastResult: number = null
    ) {
        this.id = id;
        this.name = name;
        this.size = size;
        this.lastRun = lastRun;
        this.lastResult = lastResult;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): DatasetMetadata {
        return new DatasetMetadata(
            apiObject.id,
            apiObject.name,
            apiObject.utteranceCount,
            apiObject.lastTestDateTime ? new Date(apiObject.lastTestDateTime) : null,
            apiObject.lastTestUtteranceSuccessCount
        );
    }

    /**
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            id: this.id,
            name: this.name,
            utteranceCount: this.size,
            lastTestDateTime: this.lastRun,
            lastTestUtteranceSuccessCount: this.lastResult
        };
    }

    /**
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): DatasetMetadata {
        return new DatasetMetadata(
            this.id.slice(),
            this.name.slice(),
            this.size,
            this.lastRun ? new Date(this.lastRun.getTime()) : null,
            this.lastResult
        );
    }
}
