import {
    ComponentRef,
    ElementRef,
    Injector,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { ComponentType } from '../overlay/generic-component-type';
import { PortalHost } from './portal-host';


/**
 * @description
 * A portal is a floating piece of UI that
 * can be attached to a portal host.
 * (Think tooltip, pop-out menu, modal etc.)
 */
export abstract class Portal<T> {
    // reference to portal host
    private _attachedPortalHost: PortalHost;

    /**
     * @method
     * @description
     * Attaches the portal to a portal host and
     * returns a reference to the portal's UI
     * representation.
     * @param host Portal host to attach portal to
     */
    attach(host: PortalHost): T {
        if (host == null) {
            throw new Error('Tried to attach portal to null portal host!');
        }

        if (host.hasAttached()) {
            throw new Error('Tried to attach portal to portal host that already has an attached portal!');
        }

        // connect to host
        this._attachedPortalHost = host;
        return <T>host.attach(this);
    }

    /**
     * @method
     * @description
     * Detaches the portal from the portal host.
     */
    detach(): void {
        let host = this._attachedPortalHost;
        if (host == null) {
            throw new Error('Tried to detach portal from a null portal host!');
        }

        // disconnect from host
        this._attachedPortalHost = null;
        host.detach();
    }

    /**
     * @method
     * @description
     * Whether or not the portal is currently
     * attached to a portal host.
     */
    get isAttached(): boolean {
        return !!this._attachedPortalHost;
    }

    /**
     * @method
     * @description
     * Used directly by the portal host
     * to link the attached host and its
     * portal.
     * @param host Portal host to attach portal to
     */
    _setAttachedHost(host: PortalHost) {
        this._attachedPortalHost = host;
    }
}

/**
 * @description
 * Implementation of portal that represents a component instance.
 * (ViewContainerRef)
 */
export class ComponentPortal<T> extends Portal<ComponentRef<T>> {
    // type of component to be instantiated
    component: ComponentType<T>;

    // reference to where in the Angular app's
    // DOM the component 'should' be placed
    viewContainerRef: ViewContainerRef

    // injector to help instantiate the component
    injector: Injector;

    constructor(
        component: ComponentType<T>,
        viewContainerRef: ViewContainerRef,
        injector: Injector
    ) {
        super();
        this.component = component;
        this.viewContainerRef = viewContainerRef;
        this.injector = injector;
    }
}

/**
 * @description
 * Implementation of portal that represents some embedded template.
 * (TemplateRef)
 */
export class TemplatePortal extends Portal<Map<string, any>> {
    // embedded template that will be instantiated by the portal
    templateRef: TemplateRef<any>;

    // reference to view container into which the template will be inserted
    viewContainerRef: ViewContainerRef;

    /**
     * Additional locals for the instantiated embedded view.
     * These locals can be seen as "exports" for the template, such as how ngFor has
     * index / event / odd.
     * See https://angular.io/docs/ts/latest/api/core/index/EmbeddedViewRef-class.html
     */
    locals: Map<string, any> = new Map<string, any>();

    constructor(
        templateRef: TemplateRef<any>,
        viewContainerRef: ViewContainerRef
    ) {
        super();
        this.templateRef = templateRef;
        this.viewContainerRef = viewContainerRef;
    }

    /**
     * @method
     * @description
     * Gets the original element reference that the
     * template corresponds to.
     */
    get origin(): ElementRef {
        return this.templateRef.elementRef;
    }

    /**
     * @method
     * @description
     * Attaches the template to the portal host.
     * @param host The portal host to attach to.
     * @param locals Exports for the template (eg. index / for / event for ngFor).
     */
    attach(host: PortalHost, locals?: Map<string, any>): Map<string, any> {
        this.locals = locals == null ? new Map<string, any>() : locals;
        return super.attach(host);
    }

    /**
     * @method
     * @description
     * Detaches the template from the portal host.
     */
    detach(): void {
        this.locals = new Map<string, any>();
        super.detach();
    }
}
