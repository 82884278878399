import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { first, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { EntityHelpers } from '../../../../helpers/entity.helpers';
import { CompoundEntity } from '../../../../models/compound-entity.model';
import { Entity, EntityRole } from '../../../../models/entity.model';
import { ParentEntity } from '../../../../models/parent-entity.model';

/**
 * @description
 * Represents the entity creation dialog component for creating new
 * composite entities.
 */
@Component({
    selector: 'composite-creation',
    templateUrl: 'composite-creation.component.html',
    styleUrls: ['composite-creation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompositeCreationComponent implements OnInit {
    @Input() public parent: ParentEntity;
    @Input() public inProgress: Observable<boolean>;
    @Input() public entities: Observable<Entity[]>;
    @Output() public changedEntity: EventEmitter<ParentEntity> = new EventEmitter<ParentEntity>();

    @ViewChild('rowsContainer') public rowsContainer: ElementRef;
    @ViewChild('addLink') public addLink: ElementRef;

    public selectableEntitiesData: Observable<[CompoundEntity[], ParentEntity]>;
    public childrenLimit: number = ParentEntity.CHILDREN_LIMIT;
    public constructCompoundId: typeof CompoundEntity.constructCompoundId = CompoundEntity.constructCompoundId;

    private _changeTrigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Appends a new child to the children array for the parent entity.
     */
    public appendChild(): void {
        this.parent.appendChild();
        this.changedEntity.emit(this.parent);
        window.requestAnimationFrame(() => (this.rowsContainer.nativeElement.scrollTop = this.rowsContainer.nativeElement.scrollHeight));
    }

    /**
     * @description
     * Removes the child at the given index from the array of entities.
     *
     * @param index The index of the child to remove.
     */
    public removeChild(index: number): void {
        this.parent.removeChildByIndex(index);
        this.changedEntity.emit(this.parent);
        setTimeout(() => this.addLink.nativeElement.focus());
    }

    /**
     * @description
     * Fired when a new composite child entity is selected in the dropdown
     * and notifies the parent that a child has changed.
     *
     * @param compoundEntityId Id of the newly-selected child compund entity.
     * @param child The child that changed.
     */
    public onChildSelect(compoundEntityId: string, child: Entity): void {
        this.selectableEntitiesData
            .pipe(
                first(),
                tap(([entities]) => {
                    const roles: EntityRole[] = [];
                    const matchedEntity = entities.find(e => e.id === compoundEntityId);

                    if (matchedEntity.hasRole) {
                        roles.push(matchedEntity.role.clone());
                    }

                    child.id = matchedEntity.entity.id;
                    child.name = matchedEntity.entity.name;
                    child.roles = roles;
                })
            )
            .subscribe(() => this.changedEntity.emit(this.parent));
        this._changeTrigger.next(true);
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.selectableEntitiesData = Observable.combineLatest(this.entities, this._changeTrigger.asObservable())
            .map(([entities]) => entities)
            .map(entities => EntityHelpers.expandChildren(entities))
            .map(entities => CompoundEntity.expandEntityRoles(entities))
            .map(e => <[CompoundEntity[], ParentEntity]>[e, this.parent]);
    }
}
