import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { EndpointKey } from '../models/azure-key.model';

export const KEY_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IKeyService');

/**
 * @description
 * Represents an interface for a LUIS key service. Declares functions
 * to fetch, assign, and unassign keys and other useful info that
 * is used to manipulate keys.
 */
export interface IKeyService {
    getAppKeys(): Observable<EndpointKey[]>;

    getKeysWithNoAccess(): Observable<EndpointKey[]>;

    assignKeyToApp(key: EndpointKey): Observable<void>;

    unassignKeysFromApp(keys: EndpointKey[]): Observable<void>;
}
