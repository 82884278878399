import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SearchAsyncPipe } from "./search.pipe";

const DEFAULT_OPTS = {
    caseSensitive: false
};

@Pipe({
    name: 'fuzzySearchAsync'
})
export class FuzzySearchAsyncPipe extends SearchAsyncPipe {
    constructor(ref: ChangeDetectorRef) {
        super(ref, FuzzySearchAsyncPipe.fuzzysearch);
    }

    /**
    * This code is directly taken from https://github.com/bevacqua/fuzzysearch
    * Converted to typescript for better integration
    */
    private static fuzzysearch(needle: string, haystack: string, caseSensitive: boolean): boolean {
        const hlen = haystack.length;
        const nlen = needle.length;

        if (nlen > hlen) {
            return false;
        }

        if (nlen === hlen) {
            return needle === haystack;
        }

        if (!caseSensitive) {
            haystack = haystack.toLocaleLowerCase();
            needle = needle.toLocaleLowerCase();
        }

        outer: for (let i = 0, j = 0; i < nlen; i++) {
            const nch = needle.charCodeAt(i);
            while (j < hlen) {
                if (haystack.charCodeAt(j++) === nch) {
                    continue outer;
                }
            }

            return false;
        }

        return true;
    }
}
