import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'loading',
    templateUrl: 'loading.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {
    @Input() public visible: boolean = true;
}
