import { BaseItemFilter } from '@luis/core';
import { BehaviorSubject } from 'rxjs';
import { Utterance } from '../utterance.model';

export enum EVALUATION_TYPES {
	CORRECT,
	INCORRECT,
	UNCLEAR
}

export type EvaluationFilters = {
	correct: boolean;
	incorrect: boolean;
	unclear: boolean;
};

export class EvaluationFilter extends BaseItemFilter {
	private _currentFilters: EvaluationFilters = { correct: false, incorrect: false, unclear: false };
	private _trainingDate: BehaviorSubject<Date>;
	private readonly _currentFiltersSubject: BehaviorSubject<EvaluationFilters> = new BehaviorSubject<EvaluationFilters>(
		this._currentFilters
	);

	public setTrainingDate(date: BehaviorSubject<Date>): void {
		this._trainingDate = date;
	}

	/**
	 * @method
	 * @description
	 * Toggles the activation of the filter.
	 */
	public toggleActivation(filters: EvaluationFilters): void {
		this._currentFilters = filters ? filters : { correct: false, incorrect: false, unclear: false };
		this._currentFiltersSubject.next(this._currentFilters);
	}

	/**
	 * @method
	 * @description
	 * Returns the active state of the filter.
	 */
	public isActive(): boolean {
		return this._currentFilters.correct || this._currentFilters.incorrect || this._currentFilters.unclear;
	}

	public getTypes(): EvaluationFilters {
		return this._currentFilters;
	}

	public get getTypesAsync(): BehaviorSubject<EvaluationFilters> {
		return this._currentFiltersSubject;
	}

	/**
	 * @method
	 * @description
	 * Filters the given item by whether it is correct,
	 * incorrect or unclear given the specified types of filters.
	 *
	 * @param item The item to filter.
	 * @returns True if the item should be displayed
	 * and false if it should not be displayed.
	 */
	public filter(item: Utterance): boolean {
		if (this._trainingDate && item instanceof Utterance && this._trainingDate.getValue() < item.assignedDate) {
			return true;
		}

		return (
			(this._currentFilters.correct && item.trainingEvaluation === undefined) ||
			(item.trainingEvaluation !== undefined &&
				((this._currentFilters.incorrect && item.trainingEvaluation.incorrectIntents.length !== 0) ||
					(this._currentFilters.unclear &&
						item.trainingEvaluation.ambiguousIntents.length !== 0 &&
						item.trainingEvaluation.incorrectIntents.length === 0)))
		);
	}
}
