import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, ENVIRONMENT } from '@luis/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ARM_TOKEN_SERVICE_TOKEN, IArmTokenService } from '../interfaces/IArmTokenService';
import { IKeyStoreService } from '../interfaces/IKeyStoreService';
import { EndpointKey } from '../models/azure-key.model';
import { Subscription } from '../models/subscription.model';
import { Tenant } from '../models/tenant.model';

/**
 * @description
 * Represents a key store service that fetches all the
 * keys for all the subscriptions for all the tenants. The service
 * is smart enough to not block on erroneous calls.
 */
@Injectable()
export class KeyStoreService implements IKeyStoreService {
	private readonly _isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private readonly _tenantSubscriptions: Map<string, Subscription[]> = new Map<string, Subscription[]>();
	private readonly _subscriptionKeys: Map<string, EndpointKey[]> = new Map<string, EndpointKey[]>();
	private _tenants: Tenant[] = [];
	private _filledKeys: EndpointKey[] = [];

	constructor(
		private readonly _baseService: BaseService,
		@Inject(ARM_TOKEN_SERVICE_TOKEN) private readonly _armTokenService: IArmTokenService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {}

	/**
	 * @description
	 * Gets the base web url for manipulating ARM tokens.
	 *
	 * @returns The base url for ARM tokens manipulation.
	 */
	private get _baseUrl(): string {
		if (this._baseService.configs.env === ENVIRONMENT.USGOVVIRGINIA) {
			return 'https://management.usgovcloudapi.net';
		}
		else if(this._baseService.configs.env === ENVIRONMENT.CHINAEAST2)
		{
			return 'https://management.chinacloudapi.cn';
		} else {
			return 'https://management.azure.com';
		}
	}

	/**
	 * @description
	 * Gets all tenants for the account.
	 *
	 * @returns An observable of the tenants.
	 */
	public getTenants(): Observable<Tenant[]> {
		return this._isLoaded
			.asObservable()
			.filter(isLoaded => isLoaded)
			.map(() => this._tenants);
	}

	/**
	 * @description
	 * Gets all the subscriptions for the given tenant.
	 *
	 * @param tenantId The id of the tenant to get the
	 * subscriptions for.
	 * @returns An observable of the subscriptions
	 * for the given tenant.
	 */
	public getTenantSubscriptions(tenantId: string): Observable<Subscription[]> {
		return this._isLoaded
			.asObservable()
			.filter(isLoaded => isLoaded)
			.map(() => this._tenantSubscriptions.get(tenantId));
	}

	/**
	 * @description
	 * Gets all the keys for the given subscription.
	 *
	 * @param subscriptionId The id of the subscription to
	 * get the keys for.
	 * @returns An observable of the endpoint keys
	 * for this subscription.
	 */
	public getSubscriptionKeys(subscriptionId: string): Observable<EndpointKey[]> {
		return this._isLoaded
			.asObservable()
			.filter(isLoaded => isLoaded)
			.map(() => this._subscriptionKeys.get(subscriptionId));
	}

	/**
	 * @description
	 * Searches for a key by the given id.
	 *
	 * @param id The id of the key to get.
	 * @returns The key if found and undefined if not found.
	 */
	public getKeyById(id: string): Observable<EndpointKey> {
		return this._isLoaded
			.asObservable()
			.filter(isLoaded => isLoaded)
			.map(() => this._filledKeys.find(k => k.id === id));
	}

	/**
	 * @description
	 * Initiates the service and fetches all ARM resources for the user account.
	 */
	public initService(): void {
		this._armTokenService
			.getDefaultToken()
			.flatMap(neutralToken => (neutralToken ? this._getTenants(neutralToken) : Observable.of([])))
			.map(tenants => (tenants ? tenants : []))
			.flatMap(tenants => {
				let retObs: Observable<string[]>;

				if (tenants.length) {
					retObs = Observable.combineLatest(tenants.map(t => this._armTokenService.getTokenForTenant(t.id)));
				} else {
					retObs = Observable.of([]);
				}

				return retObs.map(tokens => ({ tenants: tenants, tokens: tokens.filter(t => t !== null) }));
			})
			.flatMap(data => {
				let retObs: Observable<any>;

				if (data.tenants.length) {
					retObs = Observable.combineLatest(data.tokens.map((t, i) => this._getTenantSubscriptions(t, data.tenants[i].id)));
				} else {
					retObs = Observable.of([]);
				}

				return retObs.map(subscriptions => ({ tokens: data.tokens, subscriptions: subscriptions }));
			})
			.map(data => {
				const nullableIndeces: number[] = data.subscriptions.map((s, i) => (s === null ? i : null)).filter(i => i !== null);
				const subscriptions: Subscription[][] = data.subscriptions.filter(s => s !== null);
				const tokens: string[] = data.tokens.filter((s, i) => nullableIndeces.indexOf(i) === -1);

				return { subscriptions: subscriptions, tokens: tokens };
			})
			.flatMap(data => {
				const { subscriptions, tokens } = this._flattenSubscriptions(data);
				let retObs: Observable<any>;

				if (subscriptions.length) {
					retObs = Observable.combineLatest(subscriptions.map((s, i) => this._getSubscriptionKeys(s.id, tokens[i])));
				} else {
					retObs = Observable.of([]);
				}

				return retObs.map(keys => ({ keys: keys, tokens: this._flattenSubscriptions(data).tokens }));
			})
			.map(data => {
				const nullableIndeces: number[] = data.keys.map((k, i) => (k === null ? i : null)).filter(i => i !== null);
				const keys: EndpointKey[][] = data.keys.filter(s => s !== null);
				const tokens: string[] = data.tokens.filter((s, i) => nullableIndeces.indexOf(i) === -1);

				return { keys: keys, tokens: tokens };
			})
			.flatMap(data => {
				const flattenedKeys: EndpointKey[] = data.keys.reduce((acc, curr) => acc.concat(curr), []);
				const flattenedTokens: string[] = data.keys.reduce(
					(acc: string[], curr, index) => acc.concat(Array(curr.length).fill(data.tokens[index])),
					[]
				);
				let retObs: Observable<any>;

				if (flattenedKeys.length) {
					retObs = Observable.combineLatest(flattenedKeys.map((k, i) => this._getKeyString(k.keyPath, flattenedTokens[i])));
				} else {
					retObs = Observable.of([]);
				}

				return retObs.map(keyStrings => ({
					keys: data.keys.reduce((acc, curr) => acc.concat(curr), []),
					keyStrings: keyStrings
				}));
			})
			.do(data => data.keys.forEach((k, i) => (k.keyStrings = data.keyStrings[i])))
			.map(data => data.keys)
			.subscribe(keys => {
				this._filledKeys = keys;
				this._isLoaded.next(true);
			});
	}

	/**
	 * @description
	 * Gets a list of the all the tenants under the user's azure subscription.
	 *
	 * @param neutralToken A neutral ARM token needed to call Azure apis.
	 * @returns An observable of the available tenants in the
	 * user's azure account.
	 */
	private _getTenants(neutralToken: string): Observable<Tenant[]> {
		const url: string = `${this._baseUrl}/tenants?api-version=2018-01-01`;
		const parser: (data: any) => Tenant[] = data => data.value.map(d => Tenant.importFromApi(d));

		return this._httpService
			.get(
				url,
				this._baseService.optionsBuilder
					.useJSON()
					.useARMToken(neutralToken)
					.build()
			)
			.map(parser)
			.do(tenants => (this._tenants = tenants))
			.catch(error => Observable.of(null));
	}

	/**
	 * @description
	 * Gets a list of the all the subscriptions under a specific tenant.
	 *
	 * @param tenantToken A tenant specific ARM token needed to fetch
	 * resources for a specific tenant.
	 * @returns An observable of the available subscriptions
	 * in the user's tenant.
	 */
	private _getTenantSubscriptions(tenantToken: string, tenantId: string): Observable<Subscription[]> {
		const url: string = `${this._baseUrl}/subscriptions?api-version=2016-06-01`;
		const parser: (data: any) => Subscription[] = data => data.value.map(d => Subscription.importFromApi(d));

		return this._httpService
			.get(
				url,
				this._baseService.optionsBuilder
					.useJSON()
					.useARMToken(tenantToken)
					.build()
			)
			.map(parser)
			.do(subscriptions => this._tenantSubscriptions.set(tenantId, subscriptions))
			.catch(error => Observable.of(null));
	}

	/**
	 * @description
	 * Gets a list of the all the keys under a specific subscription.
	 *
	 * @param subscriptionId The specific subscription id to get the
	 * keys for.
	 * @param tenantToken A tenant specific ARM token needed to fetch
	 * resources for a specific tenant.
	 * @returns An observable of the available keys
	 * in the user's subscription.
	 */
	private _getSubscriptionKeys(subscriptionId: string, tenantToken: string): Observable<EndpointKey[]> {
		const supportedKeyKinds = ['luis', 'cognitiveservices'];
		const resourceUrl: string = 'providers/Microsoft.CognitiveServices/accounts?api-version=2016-02-01-preview';
		const url: string = `${this._baseUrl}/subscriptions/${subscriptionId}/${resourceUrl}`;
		const parser: (data: any) => EndpointKey[] = (data: any) => {
			return (<any[]>data.value)
				.map(d => EndpointKey.importFromApi(d))
				.filter(k => supportedKeyKinds.indexOf(k.kind.toLocaleLowerCase()) !== -1);
		};

		return this._httpService
			.get(
				url,
				this._baseService.optionsBuilder
					.useJSON()
					.useARMToken(tenantToken)
					.build()
			)
			.map(parser)
			.do(keys => this._subscriptionKeys.set(subscriptionId, keys))
			.catch(error => Observable.of(null));
	}

	/**
	 * @description
	 * Gets the key string for a given key path.
	 *
	 * @param path The specific key path.
	 * @param tenantToken A tenant specific ARM token needed to fetch
	 * resources for a specific tenant.
	 * @returns An observable of the key strings
	 * for the given key path.
	 */
	private _getKeyString(path: string, tenantToken: string): Observable<{ key1: string; key2: string }> {
		const url: string = `${this._baseUrl}/${path}/listKeys?api-version=2016-02-01-preview`;

		return <Observable<{ key1: string; key2: string }>>this._httpService
			.post<{ key1: string; key2: string }>(
				url,
				null,
				this._baseService.optionsBuilder
					.useJSON()
					.useARMToken(tenantToken)
					.build()
			)
			.catch(error => Observable.of({ key1: '', key2: '' }));
	}

	/**
	 * @description
	 * A helper function that flattens out subscriptions and tokens
	 * from double to uni dimensional arrays.
	 */
	private _flattenSubscriptions(data: {
		tokens: string[];
		subscriptions: Subscription[][];
	}): { subscriptions: Subscription[]; tokens: string[] } {
		const flattenedSubscriptions: Subscription[] = data.subscriptions.reduce((acc, curr) => acc.concat(curr), []);
		const flattenedTokens: string[] = data.subscriptions.reduce(
			(acc: string[], curr, index) => acc.concat(Array<string>(curr.length).fill(data.tokens[index])),
			[]
		);

		return {
			subscriptions: flattenedSubscriptions,
			tokens: flattenedTokens
		};
	}
}
