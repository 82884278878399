export class AppRouteBuilder {
	private _route: string;

	constructor(prefix: string = '') {
		this._route = prefix;
	}

	public dashboard(): AppRouteBuilder {
		this._route = this._route.concat('/dashboard');

		return this;
	}

	public intent(id?: string): AppRouteBuilder {
		const route: string = id ? `/build/intents/${id}` : '/build/intents';
		this._route = this._route.concat(route);

		return this;
	}

	public entity(id?: string): AppRouteBuilder {
		const route: string = id ? `/build/entities/${id}` : '/build/entities';
		this._route = this._route.concat(route);

		return this;
	}

	public phraseList(): AppRouteBuilder {
		this._route = this._route.concat('/build/phraseLists');

		return this;
	}

	public patterns(): AppRouteBuilder {
		this._route = this._route.concat('/build/patterns');

		return this;
	}

	public review(): AppRouteBuilder {
		this._route = this._route.concat('/build/review');

		return this;
	}

	public prebuiltDomains(): AppRouteBuilder {
		this._route = this._route.concat('/build/domains');

		return this;
	}

	public generalSettings(): AppRouteBuilder {
		this._route = this._route.concat('/manage/general');

		return this;
	}

	public versionsSettings(): AppRouteBuilder {
		this._route = this._route.concat('/manage/versions');

		return this;
	}

	public publishSettings(): AppRouteBuilder {
		this._route = this._route.concat('/manage/publish');

		return this;
	}

	public endpointsSettings(): AppRouteBuilder {
		this._route = this._route.concat('/manage/endpoints');

		return this;
	}

	public collaboratorsSettings(): AppRouteBuilder {
		this._route = this._route.concat('/manage/collaborators');

		return this;
	}

	public build(): string {
		return this._route;
	}
}
