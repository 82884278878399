import { Pipe, PipeTransform } from '@angular/core';
import { UtteranceIntent } from '../models/utterance-intent.model';

/**
 * @description
 * Represents a pipe for the intent dropdown scoring
 * display. If the score is null, then the prediction
 * is still loading, otherwise it is displayed. If the
 * show scores flag is false, the score is not displayed
 * alltogether.
 */
@Pipe({
    name: 'intentScore'
})
export class IntentScorePipe implements PipeTransform {
    /**
     * @method
     * @description
     * Displays the correct intent name, with/without score or
     * loading indicator based on the utterance intent score
     * value and score visibility flag.
     *
     * @param intent The utterance intent to display
     * the data for.
     * @param showScores A flag to indicate whether the
     * scores should be displayed or not.
     */
    public transform(intent: UtteranceIntent, showScores: boolean = true, decimalPoints: number = 3): any {
        if (!showScores) {
            return intent.name;
        }
        else if (intent.score !== null) {
            return `${intent.name} (${intent.score.toFixed(decimalPoints)})`;
        }
        else {
            return `${intent.name} (Loading ... )`;
        }
    }
}
