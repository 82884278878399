import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from '@luis/core';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppCreationModalComponent } from './components/app-list/app-creation-modal/app-creation-modal.component';
import { AppImportModalComponent } from './components/app-list/app-import-modal/app-import-modal.component';
import { AppListComponent } from './components/app-list/app-list.component';
import { AppLogsImportModalComponent } from './components/app-list/app-logs-import-modal/app-logs-import-modal.component';
import { AppRenameModalComponent } from './components/app-list/app-rename-modal/app-rename-modal.component';
import { AppTableComponent } from './components/app-list/app-table/app-table.component';
import { AppVersionCloneModalComponent } from './components/version-list/version-clone-modal/version-clone-modal.component';
import { AppVersionImportModalComponent } from './components/version-list/version-import-modal/version-import-modal.component';
import { VersionNameInputComponent } from './components/version-list/version-name-input/version-name-input.component';
import { AppVersionRenameModalComponent } from './components/version-list/version-rename-modal/version-rename-modal.component';
import { AppVersionTableComponent } from './components/version-list/version-table/version-table.component';
import { APP_PERMISSIONS_SERVICE_TOKEN } from './interfaces/IAppPermissionsService';
import { APP_SERVICE_TOKEN } from './interfaces/IAppService';
import { APP_SETTINGS_SERVICE_TOKEN } from './interfaces/IAppSettingsService';
import { APP_STATS_SERVICE_TOKEN } from './interfaces/IAppStatsService';
import { APP_UTILITIES_SERVICE_TOKEN } from './interfaces/IAppUtilitiesService';
import { APP_VERSION_SERVICE_TOKEN } from './interfaces/IAppVersionService';
import { VersionLegendAsyncPipe } from './pipes/version-legend-async.pipe';
import { AppPermissionsService } from './services/app-permissions.service';
import { AppSettingsService } from './services/app-settings.service';
import { AppStatsService } from './services/app-stats.service';
import { AppUtilitiesService } from './services/app-util.service';
import { AppVersionService } from './services/app-version.service';
import { AppService } from './services/app.service';

@NgModule({
	imports: [CoreModule, FormsModule, IntercomUIModule, ReactiveFormsModule, NgxPaginationModule, TranslateModule, MatDialogModule],
	exports: [AppListComponent, AppVersionTableComponent, VersionNameInputComponent, VersionLegendAsyncPipe],
	declarations: [
		AppListComponent,
		AppTableComponent,
		AppCreationModalComponent,
		AppImportModalComponent,
		AppRenameModalComponent,
		VersionNameInputComponent,
		AppVersionTableComponent,
		AppVersionCloneModalComponent,
		AppVersionImportModalComponent,
		AppVersionRenameModalComponent,
		AppLogsImportModalComponent,
		VersionLegendAsyncPipe
	],
	entryComponents: [
		AppCreationModalComponent,
		AppImportModalComponent,
		AppRenameModalComponent,
		AppVersionImportModalComponent,
		AppVersionCloneModalComponent,
		AppVersionRenameModalComponent,
		AppLogsImportModalComponent
	]
})
export class AppsModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: CoreModule,
			providers: [
				{
					provide: APP_SERVICE_TOKEN,
					useClass: AppService
				},
				{
					provide: APP_VERSION_SERVICE_TOKEN,
					useClass: AppVersionService
				},
				{
					provide: APP_UTILITIES_SERVICE_TOKEN,
					useClass: AppUtilitiesService
				}
			]
		};
	}

	public static providersForChild(): Provider[] {
		return [
			{
				provide: APP_PERMISSIONS_SERVICE_TOKEN,
				useClass: AppPermissionsService
			},
			{
				provide: APP_SETTINGS_SERVICE_TOKEN,
				useClass: AppSettingsService
			},
			{
				provide: APP_STATS_SERVICE_TOKEN,
				useClass: AppStatsService
			}
		];
	}
}
