import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_SERVICE_TOKEN } from './interfaces/IHttpService';
import { HttpService } from './services/http.service';
import { WebsocketService } from './services/websocket.service';

@NgModule({
	imports: [],
	declarations: [],
	exports: []
})
export class ApiModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: ApiModule,
			providers: [
				WebsocketService,
				{
					provide: HTTP_SERVICE_TOKEN,
					useClass: HttpService
				}
			]
		};
	}
}
