import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BLADES, BladeTrackerService } from '@luis/ui';
import { Utterance } from '@luis/utterances';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';

@Component({
	selector: 'chat-container',
	templateUrl: 'chat-container.component.html',
	styleUrls: ['chat-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatContainerComponent implements OnInit {
	@Input() public utterances: Observable<Utterance[]>;
	@Input() public currentDebugUtterance: Observable<Utterance>;
	@Input() public debugBladeId: string;
	@Output() public utteranceSelected: EventEmitter<Utterance> = new EventEmitter<Utterance>();

	public isDebugBladeOpen: Observable<boolean>;

	constructor(private readonly _bladeTrackerService: BladeTrackerService) {}

	public ngOnInit(): void {
		this.isDebugBladeOpen = this._bladeTrackerService.getBladeStates().pipe(map(bladeStates => bladeStates.get(BLADES.DEBUG) === true));
	}
}
