import { Pipe, PipeTransform } from '@angular/core';
import { CompoundEntity } from '../models/compound-entity.model';
import { Entity, ENTITY_TYPES } from '../models/entity.model';
import { ParentEntity } from '../models/parent-entity.model';

/**
 * @description
 */
@Pipe({
    name: 'selectableChildren'
})
export class SelectableCompositeChildrenPipe implements PipeTransform {
    public transform(data: [CompoundEntity[], ParentEntity], entity: Entity): CompoundEntity[] {
        if (data === undefined) {
            return [];
        }

        const [entities, contextParent] = data;
        const currentChild: CompoundEntity = entities.find(e => e.id === CompoundEntity.constructCompoundId(entity));

        return entities
            .filter(e => e.entity.type !== ENTITY_TYPES.COMPOSITE && e.entity.type !== ENTITY_TYPES.PATTERN_ANY)
            .filter(
                e => contextParent.children.find(childEntity => CompoundEntity.constructCompoundId(childEntity) === e.id) === undefined
            )
            .concat(currentChild ? [currentChild] : [])
            .reverse();
    }
}
