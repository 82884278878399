import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    Renderer
} from '@angular/core';

@Component({
    selector: 'loader-screen',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnDestroy {
    constructor(
        private _renderer: Renderer
    ) { }

    public ngOnInit(): void {
        this._renderer.setElementStyle(document.body, 'overflow', 'hidden');
    }

    public ngOnDestroy(): void {
        this._renderer.setElementStyle(document.body, 'overflow', 'auto');
    }
}
