import { Component } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { IModalComponent } from '../modal/IModal';

/** Determines the style of the button. (blue, gray, red, green) */
export type ButtonType = 'primary' | 'secondary' | 'destructive' | 'green';

/** Representation of a button contained within a dialog. */
export class DialogButton {
    constructor(text: string, type: ButtonType = 'primary', outlined: boolean = false) {
        this.text = text;
        this.type = type;
        this.outlined = outlined;
    }

    public text: string;
    public type: ButtonType;
    public outlined: boolean;
}

@Component({
    selector: 'm-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements IModalComponent<string> {
    public title: string;
    public description: string;
    public buttons = new Array<DialogButton>();
    public response = new Subject<string>();
    public data: any;
    public maxDescriptionWidth: number = 448;

    /** Adds a button to the modal dialog's button row. */
    public addButton(button: DialogButton): void {
        this.buttons.push(button);
    }

    /** Sets the title text of the modal dialog. */
    public setTitle(text: string): void {
        this.title = text;
    }

    /** Sets the description text of the modal dialog. */
    public setDescription(text: string): void {
        this.description = text;
    }

    /** Exposes the value of a pressed button within the dialog.  */
    public emitButtonValue(value: string) {
        this.response.next(value);
    }

    public setMaxDescriptionWidth(maxDescriptionWidth: number): void {
        this.maxDescriptionWidth = maxDescriptionWidth;
    }
}
