import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ICountService } from '../../interfaces/services/utils/ICountService';
import { BaseService } from './base.service';

/**
 * @description
 * An implementation of the ICountService that can be used together with PaginationResourceCacheService to
 * get the count for the specified resource.
 */
@Injectable()
export class ResourceCountService implements ICountService {
	private readonly _cacheMap: Map<string, number> = new Map();

	constructor(private readonly _baseService: BaseService, @Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService) {}

	/**
	 * @description
	 * Gets the count for the specified model.
	 *
	 * @param fetchPath The path to be used to fetch the count.
	 *
	 * @returns An observable containing the count.
	 */
	public getCount(fetchPath: string): Observable<number> {
		const countPath = this._getCountPath(fetchPath);
		let count$: Observable<number>;

		if (this._cacheMap.has(fetchPath)) {
			count$ = of(this._cacheMap.get(fetchPath));
		} else {
			count$ = this._httpService
				.get<number>(countPath, this._baseService.defaultOptionsBuilder.build())
				.pipe(tap(count => this._cacheMap.set(fetchPath, count)));
		}

		return count$;
	}

	public getAllCount(): Observable<Map<string, number>> {
		throw new Error('Method not implemented');
	}

	private _getCountPath(modelName: string): string {
		return `${this._baseService.configs.webApiUrl}/resources/${modelName}/count`;
	}
}
