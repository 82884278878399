import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { IModalComponentToken } from '../../interfaces/utils/IModalComponent';
import { ModalHostService } from './modal-host.service';

export enum PromptButtonTypes {
    Primary,
    PrimaryOutline,
    Danger,
    DangerOutline,
    Success,
    SuccessOutline,
    Default,
    DefaultOutline
}

export const PROMPT_PANEL_COMP_TOKEN: InjectionToken<string> = new InjectionToken('PromptPanelComponent');

/**
 * @description
 */
@Injectable()
export class GenericPromptService {
    constructor(
        @Inject(PROMPT_PANEL_COMP_TOKEN) private _promptPanel: IModalComponentToken<string>,
        private _modalHostService: ModalHostService
    ) { }

    /**
     * @description
     *
     * @param title
     * @param message
     * @param buttonMap
     * @param classes
     */
    public prompt(
        title?: string, message?: string,
        buttonMap?: { [value: string]: string }, classes?: { [value: string]: PromptButtonTypes }
    ): Observable<string> {
        const suppliedOptions = {};

        if (title) {
            suppliedOptions['title'] = title;
        }
        if (message) {
            suppliedOptions['contentText'] = message;
        }
        if (buttonMap) {
            suppliedOptions['buttons'] = buttonMap;
        }
        if (classes) {
            suppliedOptions['classes'] = classes;
        }

        return this._modalHostService.create(this._promptPanel, suppliedOptions);
    }

    /**
     * @description
     * Closes the prompt.
     */
    public closePrompt(): void {
        this._modalHostService.destroy();
    }
}
