import { Directive, HostListener } from '@angular/core';
import { ENTER } from '../utils/keyboard/keycodes';

/**
 * @description
 * Defines a directive that prevents the default click behaviour and propogation
 * on the element the directive was placed on.
 */
@Directive({
    selector: '[preventClick]'
})
export class PreventClickDirective {
    /**
     * @description
     * Stops the event from propogating out of this element.
     * 
     * @param event The event that caused the firing of this function.
     */
    @HostListener('click', ['$event'])
    @HostListener('keydown', ['$event'])
    public onClick(event: Event): void {
        if (!(event instanceof KeyboardEvent) || (event instanceof KeyboardEvent && event.keyCode === ENTER)) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
