import { Injectable } from '@angular/core';
import { RequestOptionsBuilder } from '@luis/api';
import { Observable } from 'rxjs';
import { ILuisConfigs } from '../../interfaces/models/ILuisConfigs';
import { ConfigService } from './config.service';

@Injectable()
export class BaseService {
	constructor(private readonly _configService: ConfigService) {}

	/**
	 * @description
	 * Gets the configs read from the environment file.
	 */
	get configs(): ILuisConfigs {
		return this._configService.getConfig();
	}

	/**
	 * @description
	 * Gets the configs read from the environment file as
	 * an observable.
	 */
	get configsAsync(): Observable<ILuisConfigs> {
		return this._configService.getConfigAsync();
	}

	/**
	 * @description
	 * The plain non-altered builder. Usually used when requests
	 * are cache only or full customization is needed.
	 */
	get optionsBuilder(): RequestOptionsBuilder {
		return new RequestOptionsBuilder();
	}

	/**
	 * @description
	 * Gets the most commonly used request options accross the portal.
	 *
	 * @returns The most common built upon options builder.
	 */
	get defaultOptionsBuilder(): RequestOptionsBuilder {
		const options: RequestOptionsBuilder = this._getCommonRequestOptions();
		options.useJSON();

		return options;
	}

	/**
	 * @description
	 * Returns the request options for raw files.
	 *
	 * @returns The builder to be used when dealing with raw files.
	 */
	get rawFileOptionsBuilder(): RequestOptionsBuilder {
		return this._getCommonRequestOptions();
	}

	/**
	 * @description
	 * Sets the given partial configs to the configs given.
	 *
	 * @param configs The configs to set.
	 */
	public setConfigs(configs: Partial<ILuisConfigs>): void {
		this._configService.setConfig(configs);
	}

	/**
	 * @description
	 * Gets the commonly used options to call LUIS apis. Appends a subscription
	 * key and handles CORS values based on the type of environment we are
	 * currently handling.
	 *
	 * @returns The builder for those particular request options.
	 */
	private _getCommonRequestOptions(): RequestOptionsBuilder {
		const options: RequestOptionsBuilder = new RequestOptionsBuilder().useSubKey(this.configs.userSubKey);

		if (this.configs.isDevelopment) {
			options.useCORS();
		}

		return options;
	}
}
