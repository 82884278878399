import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';

/**
 * @description
 * A pipe that applies a given callback method on the given array. Receives
 * and outputs an observable.
 */
@Pipe({
    name: 'sharedAsync'
})
export class SharedObservablePipe implements PipeTransform {
    /**
     * @method
     * @description
     * Applies the given callback function to all values of the incoming array.
     *
     * @param data The observable data array to apply map on.
     * @param callback The function to apply on each element.
     * @returns The data with the map function applied.
     */
    public transform(data: Observable<any>): Observable<any> {
        return data.flatMap(d => Observable.of(d));
    }
}
