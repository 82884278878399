import { Inject, Pipe, PipeTransform } from '@angular/core';
import { BaseService, IUrlSettings, ENDPOINT_VERSIONS } from '@luis/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPublishService, PUBLISH_SERVICE_TOKEN } from '../interfaces/IPublishService';
import { EndpointKey } from '../models/azure-key.model';
import { EndpointUrlBuilder } from '../models/endpoint-url-builder.model';
import { EndpointV3UrlBuilder } from '../models/endpoint-v3-url-builder.model';

@Pipe({
	name: 'endpointUrlGeneratorAsync'
})
export class EndpointUrlGeneratorPipe implements PipeTransform {
	constructor(
		private readonly _baseService: BaseService,
		@Inject(PUBLISH_SERVICE_TOKEN) private readonly _publishService: IPublishService
	) {}

	/**
	 * @description
	 * Generates the correct endpoint url given the key and settings.
	 *
	 * @param key The key to generate the url for.
	 * @param urlSettings The settings required.
	 * @param bingKey The bing key string.
	 * @returns The observable of the generated url.
	 */
	public transform(key: EndpointKey, urlSettings: Observable<IUrlSettings>, bingKey: string = ''): Observable<string> {
		const regions = this._publishService.getRegionEndpoints();

		return combineLatest(urlSettings, regions).pipe(map(([s, r]) => this._generateUrl(key, s, r, bingKey)));
	}

	/**
	 * @description
	 * Generates the correct endpoint url given the key and desired endpoint
	 * settings to generate the url with.
	 *
	 * @param key The key to generate the url for.
	 * @param appId The current application id.
	 * @param urlSettings The url settings desired.
	 * @param regions The endpoint regions.
	 * @param bingKey The bing key string.
	 * @returns The url generated.
	 */
	private _generateUrl(key: EndpointKey, urlSettings: IUrlSettings, regions: Map<string, string>, bingKey: string = ''): string {
		const builder = urlSettings.endpointVersion === ENDPOINT_VERSIONS.VERSION_3 ? new EndpointV3UrlBuilder() : new EndpointUrlBuilder();

		return builder
			.setBaseUrl(key, regions, this._baseService.configs.appId)
			.setSlot(urlSettings.staging)
			.setSpellCheck(urlSettings.bing, bingKey || '{YOUR_BING_KEY_HERE}')
			.setVerbosity(urlSettings.verbose)
			.setTimezone(key.timeZone.value)
			.setSubscriptionKey(key.keyStrings.key1)
			.setMultIntent(urlSettings.multiIntentEnabled)
			.build();
	}
}
