import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LuisModel, ProgressTracker } from '@luis/core';
import { Entity, EntityHelpers } from '@luis/entities';
import { Intent } from '@luis/intents';
import { ISearchCreateItem, ItemType } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { VIEW_OPTIONS } from '../../../../models/plain-segment.model';
import { ItemTableService } from '../../../../services/item-table.service';

@Component({
	selector: 'suggest-table-toolbar',
	templateUrl: 'table-toolbar.component.html',
	styleUrls: ['table-toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestTableToolbarComponent implements OnInit {
	@Input() public intents: Observable<Intent[]>;
	@Input() public entities: Observable<Entity[]>;
	@Output() public modelPicked = new EventEmitter<[LuisModel, string | undefined]>();

	public query: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public options: Observable<ISearchCreateItem[]>;
	public modelTracker: ProgressTracker = new ProgressTracker();
	public pickedModel: BehaviorSubject<ISearchCreateItem> = new BehaviorSubject<ISearchCreateItem>(null);
	public viewMode: Observable<VIEW_OPTIONS>;
	public viewModes: typeof VIEW_OPTIONS = VIEW_OPTIONS;
	public isListVisible: boolean = false;

	constructor(private readonly _itemTableService: ItemTableService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Resets the selected model in the models dropdown when
	 * it is focused.
	 */
	public onFocus(): void {
		this.isListVisible = true;
		this.pickedModel.next(null);
		this.query.next('');
	}

	/**
	 * @description
	 * Notifies parent of the picked model from the dropdown menu.
	 *
	 * @param pickedModel The model picked.
	 */
	public onModelPick(pickedModel: ISearchCreateItem): void {
		this.pickedModel.next(pickedModel);

		Observable.combineLatest(this.intents, this.entities)
			.first()
			.do(([intents, entities]) => {
				const [modelId, roleId] = EntityHelpers.splitQuery(pickedModel.id);
				const foundModel = intents.find(i => i.id === modelId) || entities.find(i => i.id === modelId);

				this.modelPicked.emit([foundModel, roleId]);
			})
			.subscribe();
	}

	/**
	 * @description
	 * Changes the label view mode to the value given.
	 */
	public changeViewMode(): void {
		this._itemTableService.toggleViewMode();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.options = Observable.combineLatest(
			this.intents.map(intents => intents.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1))),
			this.entities.map(entities => entities.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1)))
		)
			.map(([intents, entities]) => [
				{ id: '', text: 'Intents', subtext: null, type: ItemType.group },
				...intents.map(i => ({ id: i.id, text: i.name, subtext: null, type: ItemType.normal })),
				{ id: '', text: 'Entities', subtext: null, type: ItemType.group },
				...entities
					.filter(e => Entity.isMachineLearned(e.type))
					.map(e => ({ id: e.id, text: e.name, subtext: null, type: ItemType.normal })),
				{ id: '', text: 'Roles', subtext: null, type: ItemType.group },
				...entities.reduce(this._entitiesToRolesReducer, [])
			])
			.do(options => this.onModelPick(options[1]))
			.trackProgress(this.modelTracker.getTracker());

		this.viewMode = this._itemTableService.getViewMode();
	}

	private _entitiesToRolesReducer(acc: ISearchCreateItem[], entity: Entity): ISearchCreateItem[] {
		const rolesSearchItem = entity.roles.map(role => {
			return { id: `${entity.id}:${role.id}`, text: `${entity.name}:${role.name}`, subtext: null, type: ItemType.normal };
		});
		return [...acc, ...rolesSearchItem];
	}
}
