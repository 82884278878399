import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { IOffset, ISelection } from '../models/plain-segment.model';

/**
 * @description
 * Represents a directive used in the plain text segment component only. This directive emits
 * the native element's (usually a plain text word token span element) position relative to the
 * their parent with the token's width and height added on the top and left offsett respectively.
 */
@Directive({
    selector: '[token-position]'
})
export class TokenPositionDirective implements OnInit, OnDestroy {
    @Input('token-position-index') public tokenIndex: number;
    @Input('token-position-selection') public selection: Observable<ISelection>;
    @Output('token-position') public tokenPosition: EventEmitter<IOffset> = new EventEmitter();

    private _triggerSubscription: Subscription = new Subscription();

    constructor(private _elementRef: ElementRef) { }

    public ngOnInit(): void {
        this._subscribeToTrigger();
    }

    public ngOnDestroy(): void {
        this._triggerSubscription.unsubscribe();
    }

    /**
     * @method
     * @description
     * Subscribes to trigger observable only when its value changes. Whenever the trigger is fired,
     * the position of this token is emitted.
     */
    private _subscribeToTrigger(): void {
        this.selection
            .distinctUntilChanged((l, r) => l.start === r.start && l.end === r.end)
            .map(s => s.end === this.tokenIndex)
            .filter(b => b)
            .subscribe(b => {
                const element: HTMLElement = (<HTMLElement>this._elementRef.nativeElement);
                const box: ClientRect = element.getBoundingClientRect();

                this.tokenPosition.emit({
                    top: element.offsetTop + box.height + 10,
                    left: element.offsetLeft
                });
            });
    }
}
