import {
    Directive,
    EventEmitter,
    HostListener,
    Output
} from '@angular/core';
import { ENTER } from '../utils/keyboard/keycodes';

/**
 * @description
 * A directive to check if a mouse click was outside of the element the directive is on.
 */
@Directive({
    selector: '[clickOrEnter]'
})
export class ClickOrEnterDirective {
    @Output() public clickOrEnter: EventEmitter<Event> = new EventEmitter<Event>();

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        this.clickOrEnter.emit(event);
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        if (event.keyCode === ENTER) {
            this.clickOrEnter.emit(event);
        }
    }
}
