import { InjectionToken } from '@angular/core';
import { IRequestOptions } from '@luis/api';
import { Observable } from 'rxjs/Rx';
import { ILinkable } from '../../models/ILinkable';
import { Id, IResource, IResourceApiParser } from '../../models/IResource';

export const LINKABLE_CACHE_ADAPTER_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('ILinkableCacheAdapterService');

/**
 * @description
 * Represents an interface for an intermediate adapter between Ilinkable data resources and
 * the data service layer responsible for making web calls and caching.
 */
export interface ILinkableCacheAdapterService {
	add(
		data: ILinkable & IResource,
		parser: IResourceApiParser,
		prefixMap: Map<number, string>,
		rootPrefixMap: Map<number, string>,
		options: IRequestOptions,
		cachePath: string
	): Observable<Id | void>;

	update(
		data: ILinkable & IResource,
		prefixMap: Map<number, string>,
		rootPrefixMap: Map<number, string>,
		options: IRequestOptions,
		cachePath: string,
		refresh?: boolean,
		parser?: IResourceApiParser
	): Observable<void>;

	delete(
		data: ILinkable & IResource,
		prefixMap: Map<number, string>,
		rootPrefixMap: Map<number, string>,
		options: IRequestOptions,
		cachePath: string,
		bodyData?: Object
	): Observable<void>;
}
