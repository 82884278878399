import { BehaviorSubject, Observable } from 'rxjs/Rx';

const CACHE_SENTINEL: { sentinel: boolean } = { sentinel: true };

/**
 * @description
 * Represents a reactive genertic cache. Implements generic basic caching functions to cache generic objects.
 * Notifies subscribers when changes in the cache item occur.
 */
export class GenericCache {
    private _item: Object;
    private _subject: BehaviorSubject<Object>;

    constructor() {
        this._subject = new BehaviorSubject(CACHE_SENTINEL);
    }

    /**
     * @method
     * @description
     * Gets the current item subject. This stream updates whenever item
     * is set with a new value.
     *
     * @returns An observable to subscribe to whenever changes occur
     *                               to the underlying stream.
     */
    public get(): Observable<Object> {
        return this._subject.asObservable()
            .filter(d => d !== CACHE_SENTINEL);
    }

    /**
     * @method
     * @description
     * Sets a new item to the cache.
     *
     * @param data The data to set.
     */
    public set(data: Object): void {
        this._item = data;
        this._notify();
    }

    /**
     * @method
     * @description
     * Throws an error on the stream.
     *
     * @param error The error to throw on the stream.
     */
    public throwStreamError(error: Error): void {
        this._subject.error(error);
    }

    /**
     * @method
     * @description
     * Notifies all subscribers to the current cache item.
     */
    private _notify(): void {
        this._subject.next(this._item);
    }
}
