import { Component, Input } from '@angular/core';

@Component({
    selector: 'm-nav-bar-anchor',
    templateUrl: 'nav-bar-anchor.component.html',
    styleUrls: ['../nav-bar.component.scss']
})

export class NavBarAnchorComponent {
    @Input() href?: string;
    @Input() text: string;
    @Input() disabled?: boolean;
}
