import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { DatasetMetadata } from '../models/dataset-metadata.model';
import { DatasetResultItem } from '../models/dataset-result-item.model';

export const BATCH_TESTING_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IBatchTestingService');

/**
 * @description
 * An interface for CRUD operations for LUIS batch test datasets.
 */
export interface IBatchTestingService {
    get(): Observable<DatasetMetadata[]>;

    export(dataset: DatasetMetadata): Observable<void>;

    run(dataset: DatasetMetadata): Observable<DatasetResultItem[]>;

    import(json: string, name: string): Observable<DatasetMetadata>;

    update(dataset: DatasetMetadata): Observable<void>;

    rename(dataset: DatasetMetadata): Observable<void>;

    delete(dataset: DatasetMetadata): Observable<void>;
}
