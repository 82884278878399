import { Injectable } from '@angular/core';
import { BaseService } from '@luis/core';
import { MainRouteBuilder } from '../models/route-builder/main-route-builder.model';

@Injectable()
export class AppRouteService {
    constructor(
        private _baseService: BaseService
    ) { }

    public builder(): MainRouteBuilder {
        return new MainRouteBuilder(this._baseService.configs.appId, this._baseService.configs.versionId);
    }

    public doesNotRequireAuth(route: string): boolean {
        return [
            new MainRouteBuilder().staticPages().home().build(),
            new MainRouteBuilder().staticPages().error().build(),
            new MainRouteBuilder().user().bfLogin().build(),
            new MainRouteBuilder().user().bfLoginLegacy().build()
        ].indexOf(route) !== -1;
    }
}
