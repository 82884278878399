import { QueryList } from '@angular/core';
import { DOWN_ARROW, UP_ARROW } from '../keyboard/keycodes';
import { FocusDirection, IDisableable, ListKeyManager } from './list-key-manager';

/**
 * @description
 * This is the interface for focusable items (used by the FocusKeyManager).
 * Each item must know how to focus itself and whether or not it is currently disabled.
 */
export interface IFocusable extends IDisableable {
    focus(): boolean | void;
}

export class FocusKeyManager extends ListKeyManager<IFocusable> {
    private _lastKeyPressed: number;

    constructor(items: QueryList<IFocusable>, focusDirection: FocusDirection = 'vertical') {
        super(items, focusDirection);
    }

    public set lastKeyPressed(keyCode: number) {
        this._lastKeyPressed = keyCode;
    }

    /**
     * This method sets the active item to the item at the specified index.
     * It also adds focuses the newly active item.
     */
    public setActiveItem(index: number): void {
        super.setActiveItem(index);

        if (this.activeItem) {
            const focusResult: boolean = <boolean>this.activeItem.focus();

            if (focusResult === false) {
                switch (this._lastKeyPressed) {
                    case UP_ARROW:
                        this.setPreviousItemActive();
                        break;
                    default:
                        this.setNextItemActive();
                }
            }
        }
    }
}
