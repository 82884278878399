import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'm-tooltip-bubble',
	styleUrls: ['./tooltip-bubble.component.scss'],
	template: `
		<div #bubbleContainer [class.callout-bubble]="calloutStyling" class="tooltip-bubble {{ style }}">
			<span class="tooltip-bubble-text" role="tooltip">
				{{ label }}
				<ng-content></ng-content>
			</span>
		</div>
	`
})
export class TooltipBubbleComponent {
	@Input() public label: string;
	@Input() public style: string = '';
	@Input() public calloutStyling?: boolean = false;
	public setPositionTop: boolean = true;
	public setPositionBottom: boolean = false;
	public setPositionLeft: boolean = false;

	@ViewChild('bubbleContainer') public bubbleContainer: ElementRef;

	public measure(): ClientRect {
		return this.bubbleContainer.nativeElement.getBoundingClientRect();
	}
}
