import { Observable } from 'rxjs/Rx';
import { PROGRESS_STATES, ProgressTracker } from './progress-tracker.model';

/**
 * @description
 * An interface describing a message segment that can
 * be used in a toast.
 */
export interface IToastMessageSegment {
    text: string;

    handler?: Function;

    context?: any;
}

/**
 * @description
 * Toast options to create a toast.
 */
export interface IToastOptions {
    startMsg?: IToastMessageSegment[] | string;

    endMsg?: IToastMessageSegment[] | string;

    errorMsg?: IToastMessageSegment[] | string;

    isDismissible?: boolean;

    returnToast?: boolean;
}

/**
 * @description
 * Represents a toast that can appear in the toaster segment of the website.
 * Shows loading, success, and error messages.
 */
export class Toast extends ProgressTracker {
    public visibleMsg: Observable<IToastMessageSegment[]>;
    public options: IToastOptions;

    constructor(options: IToastOptions = {}) {
        super();
        this.options = this._validateAndConstructToastOptions(options);
        this._initState();
    }

    /**
     * @description
     * Initializes the toast visible message stream.
     */
    private _initState(): void {
        this.visibleMsg = this._subject
            .asObservable()
            .map(progress => {
                switch (progress.state) {
                    case PROGRESS_STATES.STARTED:
                        return <IToastMessageSegment[]>this.options.startMsg;
                    case PROGRESS_STATES.HAS_DATA:
                    case PROGRESS_STATES.ENDED:
                        return <IToastMessageSegment[]>this.options.endMsg;
                    case PROGRESS_STATES.ERROR:
                        if (this.options.errorMsg) {
                            return <IToastMessageSegment[]>this.options.errorMsg;
                        }
                        else if (progress.error) {
                            return [{ text: progress.error.message }];
                        }
                        else {
                            return null;
                        }
                    default:
                        return <IToastMessageSegment[]>this.options.startMsg;
                }
            });
    }

    /**
     * @description
     * Preprocesses the toast options and validates them.
     *
     * @param options The options to validate.
     * @returns The validated options.
     */
    private _validateAndConstructToastOptions(options: IToastOptions): IToastOptions {
        const newOptions: IToastOptions = {};

        if (options === null || options === undefined) {
            newOptions.startMsg = null;
            newOptions.endMsg = null;
            newOptions.errorMsg = null;
            newOptions.isDismissible = false;
            newOptions.returnToast = false;
        }
        else {
            newOptions.startMsg = this._preprocessMsg(options.startMsg);
            newOptions.endMsg = this._preprocessMsg(options.endMsg);
            newOptions.errorMsg = this._preprocessMsg(options.errorMsg);
            newOptions.isDismissible = options.isDismissible;
            newOptions.returnToast = options.returnToast;
        }

        return newOptions;
    }

    /**
     * @description
     * Preprocesses the message segments to create a validated
     * one to be added to the toast.
     *
     * @param msg The message segments to validate.
     * @returns The validated message segments.
     */
    private _preprocessMsg(msg: IToastMessageSegment[] | string): IToastMessageSegment[] {
        if (msg === undefined || msg === null) {
            return null;
        }
        if (typeof msg === 'string') {
            return [{ text: msg }];
        }
        if (typeof msg === 'object') {
            return msg;
        }

        return null;
    }
}
