import { Entity } from '@luis/entities';
import { IPattern, IPatternFsmResult } from '../../interfaces/IPatternFsm';
import { PatternEntityFsm } from './pattern-entity-fsm.model';
import { PatternValidator } from '../parser/pattern-validator.model';

/**
 * @description
 * A utility class that contains the runner functions for validating
 * patterns.
 */
export class FsmRunner {

    /**
     * @description
     * Runs the validators on the given query and with the given entities.
     *
     * @param text The text to run the validators on.
     * @param entities The entities that exist in the system.
     * @param isTerminal Whether the text is to be submitted or not.
     * @returns Returns a result of the running of
     * all the validators.
     */
    public static runValidators(text: string, entities: Entity[], isTerminal: boolean): IPatternFsmResult {
        const validators: IPattern[] = [new PatternValidator(text)];

        if (entities !== null) {
            validators.push(new PatternEntityFsm(text, entities));
        }

        for (const validator of validators) {
            const output: IPatternFsmResult = validator.evaluate(isTerminal);
            if (!output.result) {
                return output;
            }
        }

        return { result: true, error: null };
    }
}
