import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { LuisModel, ProgressTracker } from '@luis/core';
import { Entity, ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { combineLatest } from 'rxjs';
import { filter, flatMap, map, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { VIEW_OPTIONS } from '../../../models/plain-segment.model';
import { ItemTableService } from '../../../services/item-table.service';
import { ISuggestUtteranceDto, SuggestUtterancesService } from '../../../services/suggest-utterances.service';

@Component({
	selector: 'suggest-table',
	templateUrl: 'suggest-table.component.html',
	styleUrls: ['suggest-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ItemTableService]
})
export class SuggestTableComponent implements OnInit {
	@Output() public editEntity: EventEmitter<Entity> = new EventEmitter<Entity>();

	public modelToSuggest: BehaviorSubject<[LuisModel, string]> = new BehaviorSubject<[LuisModel, string]>([null, undefined]);
	public loadMoreTrigger: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	public suggestedUtterances: Observable<ISuggestUtteranceDto[]>;
	public intents: Observable<Intent[]>;
	public entities: Observable<Entity[]>;

	public utteranceTracker: ProgressTracker = new ProgressTracker();

	private readonly _keys: any = { E: 69 };

	constructor(
		private readonly _itemTableService: ItemTableService,
		private readonly _suggestUtterancesService: SuggestUtterancesService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Switches between the view modes either entity or token
	 * view for the utterance table.
	 *
	 * @param event The keyboard event fired with
	 * the shortcut.
	 */
	@HostListener('window:keydown', ['$event'])
	public switchViewModes(event: KeyboardEvent): void {
		if (event.keyCode === this._keys.E && event.ctrlKey) {
			this._itemTableService
				.getViewMode()
				.first()
				.map(viewMode =>
					viewMode === VIEW_OPTIONS.ENTITY_LABEL_VIEW ? VIEW_OPTIONS.TOKEN_LABEL_VIEW : VIEW_OPTIONS.ENTITY_LABEL_VIEW
				)
				.subscribe(viewMode => this._itemTableService.setViewMode(viewMode));

			// Required as Ctrl + E is tied to a browser event to focus the URL bar.
			event.preventDefault();
			event.stopPropagation();
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.intents = this._intentService.get();

		this.entities = this._entityService.get();

		this.suggestedUtterances = combineLatest(
			this.modelToSuggest.asObservable().distinctUntilChanged(),
			this.loadMoreTrigger.asObservable()
		).pipe(
			map(([modelTuple]) => modelTuple),
			filter(([model]) => model !== null),
			tap(() => (this.utteranceTracker = new ProgressTracker())),
			flatMap(([model, roleId]) => this._suggestUtterancesService.getSuggestedUtterances(model, this.utteranceTracker, roleId)),
			map(utterancesMap => Array.from(utterancesMap.values())),
			map(utterances => utterances.filter(u => u.isTopLevel)),
			shareReplay()
		);
	}
}
