import { TranslateService } from '@ngx-translate/core';

/**
 * @description
 * Represents a collection of slot settings popup information
 * and helper functions.
 */
export namespace POPUPS {
	/**
	 * @description
	 * An enumeration of the type of supported popups
	 * in the system.
	 */
	export enum TYPES {
		BING,
		SPEECH
	}

	/**
	 * @description
	 * Represents an interface for the pop up data.
	 */
	export interface IPopupData {
		title: string;
		body: string;
	}

	/**
	 * @description
	 * A factory function for creating popUp data objects.
	 *
	 * @param type The type of the pop up to create.
	 * @returns An object that implements the IPopupData interface.
	 */
	export function createPopUp(type: TYPES, _i18n: TranslateService): IPopupData {
		const BING_POPUP: IPopupData = {
			title: _i18n.instant('publish-pane.popup-messages.get_your_bing_key'),
			body: _i18n.instant('publish-pane.popup-messages.you_have_enabled_bing_spell_check', {
				tryCognitiveLink: 'https://azure.microsoft.com/en-gb/try/cognitive-services/?api=spellcheck-api'
			})
		};

		const SPEECH_POPUP: IPopupData = {
			title: _i18n.instant('publish-pane.popup-messages.enabling_speech_priming'),
			body: _i18n.instant('publish-pane.popup-messages.all_your_app_data_with_be_shared_with_bing_search')
		};
		const popupData: IPopupData = { title: '', body: '' };

		switch (type) {
			case TYPES.BING:
				popupData.title = BING_POPUP.title;
				popupData.body = BING_POPUP.body;
				break;
			case TYPES.SPEECH:
			default:
				popupData.title = SPEECH_POPUP.title;
				popupData.body = SPEECH_POPUP.body;
		}

		return popupData;
	}
}
