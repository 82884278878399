import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    EventBusService,
    GenericPromptService,
    IToasterService,
    PromptButtonTypes,
    TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import {
    filter,
    finalize,
    first,
    flatMap,
    tap
} from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { RegexEntity } from '../../../models/regex-entity.model';

/**
 * @description
 * Displays a regex entity's details. These details are a value
 * containing the regex of the entity.
 */
@Component({
    selector: 'regex-details',
    templateUrl: 'regex-details.component.html',
    styleUrls: ['regex-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegexDetailsComponent implements OnInit {
    @Input() public entity: Observable<RegexEntity>;

    @ViewChild('field') public field: ElementRef;

    public currentValue: BehaviorSubject<string> = new BehaviorSubject<string>('');

    private _lastSavedRegex: string = '';
    // This is here to prevent the blur and keydown.up event from racing each other
    private _inflightPrompt: boolean = false;

    constructor(
        private _eventBus: EventBusService,
        private _promptService: GenericPromptService,
        private _i18n: TranslateService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) {}

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @method
     * @description
     * Updates the entity regex with the new value.
     *
     * @param newRegex The new regex to update the entity with.
     */
    public updateEntityRegex(): void {
        const regexToSubmit: string = this.currentValue.getValue().trim();

        if (this._canUpdate(regexToSubmit) && !this._inflightPrompt) {
            this.entity
                .pipe(
                    first(),
                    flatMap(e => {
                        // Show prompt only if the entity has roles (because if it does, it can be labelled).
                        if (e.roles.length === 0) {
                            return this._updateEntity(e, regexToSubmit);
                        }

                        this._inflightPrompt = true;

                        return this._promptService
                            .prompt(
                                this._i18n.instant('entities.regex-details.prompt_title'),
                                this._i18n.instant('entities.regex-details.prompt_body'),
                                { ok: this._i18n.instant('entities.regex-details.ok_button'), cancel: this._i18n.instant('entities.regex-details.cancel_button') },
                                { cancel: PromptButtonTypes.Default, ok: PromptButtonTypes.Danger }
                            )
                            .pipe(
                                tap(choice => {
                                    if (choice === 'cancel') {
                                        this.field.nativeElement.focus();
                                    }
                                }),
                                filter(choice => choice === 'ok'),
                                flatMap(() => this._updateEntity(e, regexToSubmit))
                            );
                    }),
                    finalize(() => (this._inflightPrompt = false))
                )
                .subscribe();
        }
    }

    /**
     * @description
     * Initializes the state of the component.
     */
    private _initState(): void {
        this.entity.pipe(first()).subscribe(entity => {
            this.currentValue.next(entity.regex);
            this._lastSavedRegex = entity.regex;
        });
    }

    /**
     * @description
     * Updates the given regex entity with the new regex string.
     *
     * @param entity The entity to update.
     * @param newRegex The regex string to update it with.
     * @returns An observable to indicate completion.
     */
    private _updateEntity(entity: RegexEntity, newRegex: string): Observable<void> {
        const newEntity = entity.clone();
        newEntity.regex = newRegex;

        return this._entityService
            .update(newEntity)
            .pipe(tap(() => (this._lastSavedRegex = newRegex)))
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.regex-details.update_toast') }));
    }

    /**
     * @description
     * Checks if an update call is needed based on the new and
     * old regex patterns.
     *
     * @param newRegex The new regex string.
     * @param oldRegex The old regex string.
     * @returns True if the regex should be updated and
     * false otherwise.
     */
    private _canUpdate(newRegex: string): boolean {
        return newRegex.length > 0 && newRegex.length < 500 && this._lastSavedRegex !== newRegex;
    }
}
