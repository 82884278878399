import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BaseItemComponent } from '../base-item/base-item.component';

@Component({
    selector: 'plain-item',
    templateUrl: 'plain-item.component.html',
    styleUrls: ['plain-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: BaseItemComponent, useExisting: PlainItemComponent }]
})
export class PlainItemComponent extends BaseItemComponent {
    @Input() public isVisible: boolean = true;
    @Input() public isDisabled: boolean = false;
    @Input() public isSelected: boolean = false;
    @Input() public isBold: boolean = false;
    @Input() public prefixIcon: string = null;
    @Input() public suffixIcon: string = null;
    @Input() public secondaryText: string = null;
    @Input() public isExpanded: boolean = null;

    @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('item') public item: ElementRef;

    /**
     * @description
     * Notifies parent that the item was clicked.
     */
    public onClick(event: Event): void {
        event.stopPropagation();

        if (!this.isDisabled) {
            this.clicked.emit();
        }
    }

    /**
     * @description
     * Focuses the element.
     */
    public focus(): boolean {
        if (this.item && !this.isDisabled) {
            this.item.nativeElement.focus();

            return true;
        }

        return false;
    }
}
