import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from '@luis/core';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { PhraseBuilderComponent } from './components/phrase-list-list/phrase-list-creation-modal/phrase-builder/phrase-builder.component';
import { PhraseListCreationModalComponent } from './components/phrase-list-list/phrase-list-creation-modal/phrase-list-creation-modal.component';
import { PhraseListListComponent } from './components/phrase-list-list/phrase-list-list.component';
import { PhraseListTableComponent } from './components/phrase-list-list/phrase-list-table/phrase-list-table.component';
import { PHRASE_LIST_SERVICE_TOKEN } from './interfaces/IPhraseListService';
import { PHRASE_LIST_UTILITIES_SERVICE_TOKEN } from './interfaces/IPhraseListUtilitiesService';
import { SelectedPhraseListsModePipe } from './pipes/selected-phrase-lists-mode.pipe';
import { PhraseListUtilitiesService } from './services/phrase-list-utils.service';
import { PhraseListService } from './services/phrase-list.service';

@NgModule({
	imports: [CoreModule, IntercomUIModule, FormsModule, ReactiveFormsModule, TranslateModule, MatDialogModule],
	exports: [PhraseListListComponent, SelectedPhraseListsModePipe],
	declarations: [
		PhraseListListComponent,
		PhraseListTableComponent,
		PhraseListCreationModalComponent,
		PhraseBuilderComponent,
		SelectedPhraseListsModePipe
	],
	entryComponents: [PhraseListCreationModalComponent]
})
export class FeaturesModule {
	public static providersForChild(): Provider[] {
		return [
			{
				provide: PHRASE_LIST_SERVICE_TOKEN,
				useClass: PhraseListService
			},
			{
				provide: PHRASE_LIST_UTILITIES_SERVICE_TOKEN,
				useClass: PhraseListUtilitiesService
			}
		];
	}
}
