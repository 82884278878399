import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer } from '@angular/core';
import { Entity, ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IInputHandlerPayload } from '../../interfaces/IInputHandlerPayload';
import { IFsmError } from '../../interfaces/IPatternFsm';
import { FsmRunner } from '../../models/fsms/fsm-runner.model';
import { Pattern, PatternEntity } from '../../models/pattern.model';

/**
 * @description
 * Represents an object that contains data about
 * pattern validity and its current value.
 */
export interface IPatternState {
    isValid: boolean;

    text: string;
}

/**
 * @description
 * Represents a pattern input field. Contains controls
 * and constructs to enter pattern templates.
 */
@Component({
    selector: 'pattern-input',
    templateUrl: 'pattern-input.component.html',
    styleUrls: ['pattern-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternInputComponent implements OnInit {
    @Input() public pattern: Pattern;
    @Input() public isHeader: boolean = true;
    @Output() public patternSubmitted: EventEmitter<Pattern> = new EventEmitter<Pattern>();

    public inputPayload: BehaviorSubject<IInputHandlerPayload> = new BehaviorSubject<IInputHandlerPayload>({
        currentText: '',
        currentKeyEvent: null
    });
    public inputEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public inputFieldClickStream: BehaviorSubject<MouseEvent> = new BehaviorSubject<MouseEvent>(null);
    public patternError: BehaviorSubject<IFsmError> = new BehaviorSubject<IFsmError>(null);
    public patternEntityStream: BehaviorSubject<PatternEntity[]> = new BehaviorSubject<PatternEntity[]>([]);

    public intents: Observable<Intent[]>;
    public entities: Observable<Entity[]>;
    public errorTooltipPosition: Observable<number>;
    public state: Observable<IPatternState>;
    public requiredIntentError: boolean = false;
    public currentIntent: string;

    constructor(
        private _i18n: TranslateService,
        private _elementRef: ElementRef,
        private _renderer: Renderer,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService,
        @Inject(INTENT_SERVICE_TOKEN) private _intentService: IIntentService
    ) {}

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Ensures that the key event is not propagated to the parent.
     *
     * @param event The key event fired.
     */
    public onKeyDown(event: KeyboardEvent): void {
        event.stopPropagation();
    }

    /**
     * @description
     * Listens for keyboard strokes.
     *
     * @param text The pattern new text.
     * @param event The event object received.
     */
    public onKeyUp(text: string, event: KeyboardEvent): void {
        this.inputPayload.next({ currentText: text, currentKeyEvent: event });
    }

    /**
     * @method
     * @description
     * Submits the pattern written in the input.
     */
    public submitPattern(): void {
        const text: string = this.inputPayload.getValue().currentText.trim();

        this._isValid(text, true)
            .filter(isValid => isValid)
            .subscribe(() => {
                const patternToSubmit: Pattern = this.pattern.clone();

                patternToSubmit.text = this.inputPayload.getValue().currentText.trim();
                patternToSubmit.entities = this.patternEntityStream.getValue();
                patternToSubmit.intentName = patternToSubmit.intentName === '' ? this.currentIntent : patternToSubmit.intentName;
                this.patternSubmitted.emit(patternToSubmit);
                this.inputPayload.next({ currentText: '', currentKeyEvent: null });
            });
    }

    /**
     * @method
     * @description
     * The event fired when an intent is selected.
     *
     * @param intent The intent selected.
     */
    public onIntentChange(intent: Intent, patternField: HTMLInputElement): void {
        if (intent) {
            this.currentIntent = intent.name;
            this.inputEnabled.next(true);
            setTimeout(() => patternField.focus(), 0);
        } else {
            this.inputEnabled.next(false);
        }
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.intents = this._intentService.get();

        this.entities = this._entityService.get();

        this.inputEnabled.next(!this.isHeader);

        this.pattern = this.pattern ? this.pattern.clone() : new Pattern();

        this.state = this.inputPayload
            .asObservable()
            .switchMap(payload => this._isValid(payload.currentText).map(isValid => ({ text: payload.currentText, valid: isValid })))
            .map(data => ({ isValid: data.valid, text: data.text }));

        this.inputPayload.next({ currentText: this.pattern.text, currentKeyEvent: null });

        this.errorTooltipPosition = this.patternError
            .asObservable()
            .filter(error => error !== null)
            .map(error => error.index * 8.5 + 16);

        this._renderer.setElementClass(this._elementRef.nativeElement, 'header', this.isHeader);
    }

    /**
     * @description
     * Checks if the given pattern is valid or not.
     *
     * @param text The string to validate.
     * @param isComplete True if the text is complete pattern sentence and false if not.
     * @returns True if valid and false otherwise.
     */
    private _isValid(text: string, isComplete: boolean = false): Observable<boolean> {
        if (!text.length) {
            return Observable.of(false);
        }

        return this.entities
            .first()
            .map(entities => FsmRunner.runValidators(text, entities, isComplete))
            .do(result => {
                if (result.error) {
                    result.error.message = result.error.message
                        ? result.error.message
                        : this._i18n.instant('patterns.pattern-input.incomplete_expression');
                    this.patternError.next(result.error);
                }
            })
            .map(result => result.result);
    }
}
