import { IResource } from '@luis/core';

/**
 * @description
 * Represents an app settings class. App settings are app wide settings that are
 * specfic for each app (not version).
 */
export class AppSettings implements IResource {
    public id: string;
    public isPublic: boolean;

    constructor(id: string = '', isPublic: boolean = false) {
        this.id = id;
        this.isPublic = isPublic;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): AppSettings {
        return new AppSettings(
            apiObject.id,
            apiObject.public
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            public: this.isPublic
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): AppSettings {
        return new AppSettings(
            this.id.slice(),
            this.isPublic
        );
    }
}
