import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService, IRequestOptions } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	CHUNKING_PROCESS_TYPE,
	DirtyBitService,
	EventBusMessage,
	EventBusService,
	Id,
	IModelChangeBusMessage,
	IPage,
	IPageInfo,
	LuisModel,
	MODEL_CHANGE_TYPE,
	PaginatedCache,
	PaginationCacheService
} from '@luis/core';
import { Entity, ENTITY_TYPES, ParentEntity, UtteranceEntity, EntityRole } from '@luis/entities';
import { INTENT_TYPES } from '@luis/intents';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { Observable, Subscription } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { IUtteranceCacheService } from '../interfaces/services/IUtteranceCacheService';
import { Utterance } from '../models/utterance.model';
import { UtterancesCountService } from './utterances-count.service';

/**
 * @description
 * A concrete implementation of the utterance cache service interface. This class is to be
 * used for production environments. It interfaces between the cacheing layer and the
 * utterance service layer exposed to client objects.
 */
@Injectable()
export class UtteranceCacheService extends PaginationCacheService<Utterance> implements IUtteranceCacheService, OnDestroy {
	public static sortingProps: ISortPipeProps = { property: 'id', type: SORT_TYPE.DESCENDING };

	protected chunkingType: CHUNKING_PROCESS_TYPE = CHUNKING_PROCESS_TYPE.UTTERANCES;
	protected importNewIdsFromBatchApi: (response: Object) => number[] = Utterance.importNewIdsFromBatchApi;
	protected importNewIdFromApi: (response: Object) => number = Utterance.importNewIdFromApi;
	protected importFromApi: (response: Object) => Utterance = Utterance.importFromApi;

	private _modelChangeSubscription: Subscription = new Subscription();
	private _roleChangeSubscription: Subscription = new Subscription();
	private _noneIntentSubscription: Subscription = new Subscription();
	private _invalidationSubscription: Subscription = new Subscription();

	constructor(
		private readonly _baseService: BaseService,
		private readonly _dirtyBitService: DirtyBitService,
		private readonly _eventBus: EventBusService,
		private readonly _utteranceCountService: UtterancesCountService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		super(_eventBus);
		this._subscribeToEventBus();
	}

	public ngOnDestroy(): void {
		this._noneIntentSubscription.unsubscribe();
		this._modelChangeSubscription.unsubscribe();
		this._roleChangeSubscription.unsubscribe();
		this._invalidationSubscription.unsubscribe();
	}

	public get(
		basePath: string,
		modelId: string,
		pageIndex: number,
		getAll: boolean = false,
		options?: IRequestOptions
	): Observable<IPage<Utterance>> {
		return this._utteranceCountService.getAllCount().pipe(
			first(),
			tap(labelsCount => this.prepopulateCaches(labelsCount, UtteranceCacheService.sortingProps)),
			switchMap(() =>
				super.get(basePath, modelId, pageIndex, getAll, options, Utterance.importFromApi, UtteranceCacheService.sortingProps)
			)
		);
	}

	/**
	 * @description
	 * Adds an utterance to the web service. Reflects this addition to the
	 * relevant caches stored in memory.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the utterances for.
	 * @param utterance The utterance to add.
	 * @returns An observable to indicate completion.
	 */
	public post(basePath: string, modelId: string, utterance: Utterance, options?: IRequestOptions): Observable<number> {
		return super.post(basePath, modelId, utterance, options, Utterance.importNewIdFromApi, false).pipe(
			map(id => parseInt(`${id}`, 10)),
			tap(this._utteranceChanged),
			tap(this._markDirty)
		);
	}

	/**
	 * @description
	 * Batch adds multiple utterances to the web service. Reflects the additions
	 * to the caches stored in memory.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the utterances for.
	 * @param utterances The utterances to add.
	 * @returns An array of ids resulting from the addition process. If any of the entries is null, the corresponding utterance
	 * addition failed.
	 */
	public batchPost(
		basePath: string,
		modelId: string,
		utterances: Utterance[],
		options?: IRequestOptions,
		parser?: typeof Utterance.importNewIdsFromBatchApi,
		type?: 'intent' | 'entity'
	): Observable<number[]> {
		return super.batchPost(basePath, modelId, utterances, options, Utterance.importNewIdsFromBatchApi, type).pipe(
			map(ids => ids.map(id => parseInt(`${id}`, 10))),
			tap(this._utteranceChanged),
			tap(this._markDirty)
		);
	}

	/**
	 * @description
	 * Replaces the new utterance with an the old utterance with the given id.
	 * Adds the new utterance in the same ordered place as the old utterance.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the utterances for.
	 * @param utterance The utterance to add and replace in the cache.
	 * @param idToReplace The id of the utterance to replace.
	 * @returns An observable of the new utterance id.
	 */
	public replace(
		basePath: string,
		modelId: string,
		utterance: Utterance,
		idToReplace: number,
		options?: IRequestOptions,
		parser?: typeof Utterance.importNewIdFromApi
	): Observable<number> {
		return super.replace(basePath, modelId, utterance, idToReplace, options, Utterance.importNewIdFromApi).pipe(
			map(id => parseInt(`${id}`, 10)),
			tap(this._utteranceChanged),
			tap(this._markDirty)
		);
	}

	/**
	 * @description
	 * Deletes the utterances with the given ids from the application and caches.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the utterances for.
	 * @param ids The ids of the utterances to delete.
	 * @returns An observable to indicate completion.
	 */
	public batchDelete(
		basePath: string,
		modelId: string,
		ids: number[],
		options?: IRequestOptions,
		parser?: typeof Utterance.importFromApi
	): Observable<number[]> {
		return super.batchDelete(basePath, modelId, ids, options, Utterance.importFromApi).pipe(
			map(newIds => newIds.map(id => parseInt(`${id}`, 10))),
			tap(this._utteranceChanged),
			tap(this._markDirty)
		);
	}

	/**
	 * @description
	 * A concrete implementation for the GET http function.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the models for.
	 * @param pageInfo The skip and take values.
	 * @param options Http request options.
	 * @returns An observable of the newly fetched utterances.
	 */
	protected httpGet(
		basePath: string,
		modelId: string,
		pageInfo: IPageInfo,
		options: IRequestOptions = this._baseService.defaultOptionsBuilder.build()
	): Observable<any> {
		const path: string = `${this._baseService.configs.webApiUrl}/${basePath}/models/${modelId}`;
		const reviewLabelsPath = `${path}/reviewLabels?skip=${pageInfo.skip}&take=${pageInfo.take}`;

		return this._httpService.get(reviewLabelsPath, options);
	}

	/**
	 * @description
	 * A concrete implementation for the POST http function.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the models for.
	 * @param utterances Object(s) to be posted.
	 * @param options Http request options.
	 * @returns An observable of the newly added utterances.
	 */
	protected httpPost(
		basePath: string,
		modelId: string,
		utterances: Object | Object[],
		options: IRequestOptions = this._baseService.defaultOptionsBuilder.build()
	): Observable<any> {
		return this._httpService.post(`${this._baseService.configs.apiUrl}/${basePath}/${modelId}`, utterances, options);
	}

	/**
	 * @description
	 * A concrete implementation for the DELETE http function.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the models for.
	 * @param id The id of the model to delete.
	 * @param options Http request options.
	 * @returns An observable with the deleted ids
	 */
	protected httpDelete(
		basePath: string,
		modelId: string,
		id: number,
		options: IRequestOptions = this._baseService.defaultOptionsBuilder.build()
	): Observable<number> {
		return this._httpService.delete(`${this._baseService.configs.apiUrl}/${basePath}/${modelId}/${id}`, options);
	}

	protected httpPut(basePath: string, modelId: string, model: Object | Object[], options: IRequestOptions): Observable<any> {
		return this.httpPost(basePath, modelId, model, options);
	}

	/**
	 * @description
	 * Adds or removes an utterance from utterance caches based on the models
	 * detected in that utterance.
	 *
	 * @param utterance The utterance to add or remove from caches.
	 * @param idToReplace An optional id to replace if the utterance is to be
	 *                             in place of another exisitng utterance id.
	 */
	protected reflectModelInCaches(basePath: string, utterance: Utterance, idToReplace?: number): Observable<void> {
		// Construct existing model names map from utterance models.
		const modelIdsMap: Map<string, number> = new Map<string, number>();
		modelIdsMap.set(utterance.labeledIntent.id, 1);
		utterance.labeledEntities.map(e => modelIdsMap.set(e.id, 1));

		const pageFetchObservables: Observable<IPage<Utterance>>[] = [];

		// Loop over caches. If cache model name exists in utterance model names, utterance belongs
		// to that cache. If it doesn't, utterance should be deleted from that cache.
		// TODO: Handle the case where cache for an intent/entity does not exist and the
		//       label count for that intent/entity still needs to be updated.
		for (const [modelId, cache] of this._cacheMap) {
			let pageInfo: IPageInfo;

			if (modelIdsMap.has(modelId)) {
				cache.addOrUpdate(utterance, idToReplace);
			} else {
				pageInfo = cache.delete(utterance.id);
				if (pageInfo) {
					cache.setRefreshing(true);
				}
			}
			if (pageInfo) {
				pageFetchObservables.push(
					this.refreshPage(basePath, modelId, pageInfo.skip / pageInfo.take, undefined, Utterance.importFromApi)
				);
			}
		}

		return pageFetchObservables.length === 0
			? Observable.of(null)
			: Observable.from(pageFetchObservables)
					.concatAll()
					.mapTo(undefined);
	}

	/**
	 * @description
	 * Adds or removes utterances from utterance caches based on the models
	 * detected in those utterances.
	 *
	 * @param utterances The utterances to add or remove to caches.
	 */
	protected reflectModelsInCaches(basePath: string, utterances: Utterance[], type: 'intent' | 'entity'): Observable<void> {
		// Construct existing model names map from the utterances models. Each model name
		// has an array of utterances that include that model name as an intent or entity.
		const modelUtteranceMap: Map<string, Utterance[]> = utterances.reduce((m: Map<string, Utterance[]>, u) => {
			this._addUtteranceToModelUtteranceMap(m, u.labeledIntent.id, u);
			u.labeledEntities.map(e => this._addUtteranceToModelUtteranceMap(m, e.id, u));

			return m;
		}, new Map<string, Utterance[]>());

		const pageFetchObservables: Observable<IPage<Utterance>>[] = [];

		// Loop over caches. If cache model name exists in the utterances model names map, then the
		// that list of utterances belong to that cache. If it doesn't, then all utterances in all
		// lists should be deleted from that cache.
		for (const [modelId, cache] of this._cacheMap) {
			let pageInfo: IPageInfo;
			// If the utterances belong to an intent, then invalidate it so that if the user requests
			// its utterances again we refetch its data.
			if (modelUtteranceMap.has(modelId)) {
				if (type === 'entity') {
					cache.mergeBatch(modelUtteranceMap.get(modelId));
				} else {
					cache.invalidate(true);
				}
			} else {
				pageInfo = cache.deleteBatch(
					Array.from(modelUtteranceMap.values())
						.reduce((result, utteranceArray) => [...result, ...utteranceArray], [])
						.map(u => u.id)
				);
				if (pageInfo) {
					cache.setRefreshing(true);
				}
			}
			if (pageInfo) {
				pageFetchObservables.push(
					this.refreshPage(basePath, modelId, pageInfo.skip / pageInfo.take, undefined, Utterance.importFromApi)
				);
			}
		}

		return pageFetchObservables.length === 0
			? Observable.of(null)
			: Observable.from(pageFetchObservables)
					.concatAll()
					.mapTo(null);
	}

	/**
	 * @description
	 * Adds an utterance to model name entry in the model name utterance map. If the entry is the first
	 * for the given model name, then a new array is constructed. If the model name had existed before,
	 * the new utterance is appended to the existing array of utterances for that model name.
	 *
	 * @param map The map to add the utterance to.
	 * @param modelId The model name to add the utterance for.
	 * @param utterance The utterance to add.
	 */
	private _addUtteranceToModelUtteranceMap(map: Map<string, Utterance[]>, modelId: string, utterance: Utterance): void {
		if (map.has(modelId)) {
			map.get(modelId).push(utterance);
		} else {
			map.set(modelId, [utterance]);
		}
	}

	/**
	 * @description
	 * Subscribe to intent changes (deletion/rename) in the application.
	 */
	private _subscribeToEventBus(): void {
		this._modelChangeSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.MODEL_CHANGED, (msg: IModelChangeBusMessage) => {
			this._updateModelInUtteranceCaches(msg.model, msg.changeType);
		});

		this._roleChangeSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.ROLE_CHANGED, (msg: IModelChangeBusMessage) => {
			this._updateRoleInUtteranceCaches(msg.model, msg.changeType);
		});

		this._noneIntentSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.DELETE_NONE_INTENT, id => this._cacheMap.delete(id));

		this._invalidationSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.INVALIDATE_UTTERANCES_CACHE, () => this.invalidate());
	}

	/**
	 * @description
	 * Changes the reference of all instances of the old model to match
	 * the new one, or removes it all together if deleted.
	 *
	 * @param u The utterance to mutate.
	 * @param model The new model after change was applied.
	 * @param changeType The type of the change.
	 */
	private _handleIntentChangeForUtterance(u: Utterance, model: LuisModel, changeType: MODEL_CHANGE_TYPE): void {
		switch (changeType) {
			case MODEL_CHANGE_TYPE.DELETE:
				if (model.type === INTENT_TYPES.SIMPLE) {
					u.predictedIntents = u.predictedIntents.filter(i => i.id !== model.id);
				}
				break;
			case MODEL_CHANGE_TYPE.UPDATE:
				if (model.type === INTENT_TYPES.SIMPLE) {
					u.labeledIntent.name = u.labeledIntent.id === model.id ? model.name : u.labeledIntent.name;
					u.predictedIntents.filter(i => i.id === model.id).forEach(i => (i.name = model.name));
				}
				break;
			default:
		}
	}

	/**
	 * @description
	 * Changes the reference of all instances of the old model to match
	 * the new one, or removes it all together if deleted.
	 *
	 * @param u The utterance to mutate.
	 * @param model The new model after change was applied.
	 * @param changeType The type of the change.
	 */
	private _handleEntityChangeForUtterance(u: Utterance, model: LuisModel, changeType: MODEL_CHANGE_TYPE): void {
		switch (changeType) {
			case MODEL_CHANGE_TYPE.DELETE:
				if (model.type === ENTITY_TYPES.HIERARCHICAL) {
					const parent: ParentEntity = <ParentEntity>model;
					const idsToRemove: Id[] = [parent.id, ...parent.children.map(c => c.id)];
					const filteringFunction = (uE: UtteranceEntity) => idsToRemove.indexOf(uE.id) === -1;

					u.labeledEntities = u.labeledEntities.filter(filteringFunction);
					u.predictedEntities = u.predictedEntities.filter(filteringFunction);
				} else if (model.type === ENTITY_TYPES.HIERARCHICAL_CHILD) {
					const parent: Entity = (<Entity>model).parent;
					const childUpdater = (e: UtteranceEntity) => {
						e.id = parent.id;
						e.name = parent.name;
						e.type = parent.type;
					};

					u.labeledEntities.filter(lE => lE.id === model.id).forEach(childUpdater);
					u.predictedEntities.filter(lE => lE.id === model.id).forEach(childUpdater);
				} else {
					u.labeledEntities = u.labeledEntities.filter(e => e.id !== model.id);
					u.predictedEntities = u.predictedEntities.filter(e => e.id !== model.id);
				}
				break;

			case MODEL_CHANGE_TYPE.UPDATE:
				const childNameUpdater = (e: UtteranceEntity, p: ParentEntity) => (e.name = p.getChildWithFullName(e.id).name);

				u.labeledEntities.filter(e => e.id === model.id).forEach(e => (e.name = model.name));
				u.predictedEntities.filter(e => e.id === model.id).forEach(e => (e.name = model.name));

				if (model.type === ENTITY_TYPES.HIERARCHICAL) {
					const parent: ParentEntity = <ParentEntity>model;
					const filteringFunction = (e: UtteranceEntity) => parent.children.findIndex(c => e.id === c.id) !== -1;

					u.labeledEntities.filter(filteringFunction).forEach(lE => childNameUpdater(lE, parent));
					u.predictedEntities.filter(filteringFunction).forEach(pE => childNameUpdater(pE, parent));
				} else if (model.type === ENTITY_TYPES.HIERARCHICAL_CHILD) {
					const parent: ParentEntity = <ParentEntity>(<Entity>model).parent;

					u.labeledEntities.filter(lE => lE.id === model.id).forEach(lE => childNameUpdater(lE, parent));
					u.predictedEntities.filter(pE => pE.id === model.id).forEach(pE => childNameUpdater(pE, parent));
				} else if (model.type === ENTITY_TYPES.REGEX) {
					u.labeledEntities = u.labeledEntities.filter(e => e.id !== model.id);
					u.predictedEntities = u.predictedEntities.filter(e => e.id !== model.id);
				}
				break;
			default:
		}
	}

	/**
	 * @description
	 * Reflects role changes in the utterance cache.
	 */
	private _updateRoleInUtteranceCaches(model: LuisModel, changeType: number): void {
		const updateUtteranceEntity = (entity: UtteranceEntity) => {
			if (entity.roleId === model.id) {
				if (changeType === MODEL_CHANGE_TYPE.UPDATE) {
					entity.role = model.name;
				} else if (changeType === MODEL_CHANGE_TYPE.DELETE) {
					entity.roleId = '';
					entity.role = '';
				}
			}
		};

		this._cacheMap.forEach(cache => {
			const data = cache.getAll();
			data.forEach(u => {
				u.labeledEntities.forEach(updateUtteranceEntity);
				u.predictedEntities.forEach(updateUtteranceEntity);
			});
		});
    }
    
	/**
	 * @description
	 * Updates all references of the old model name, either by deleting them or renaming them
	 * with the new model name provided. The references for the model name will be in the
	 * labeled model and predicted model properties in the utterance. Also deletes the
	 * cache for the given model id if the model was delete.
	 *
	 * @param model The model to update.
	 * @param changeType The update type to apply.
	 */
	private _updateModelInUtteranceCaches(model: LuisModel, changeType: number): void {
		if (changeType === MODEL_CHANGE_TYPE.ADD) {
			this._cacheMap.set(
				model.id,
				new PaginatedCache<Utterance>(UtteranceCacheService.PAGE_SIZE, 0, UtteranceCacheService.sortingProps)
			);
		}

		if (changeType === MODEL_CHANGE_TYPE.DELETE) {
			this._cacheMap.delete(model.id);
		}

		this._cacheMap.forEach(cache => {
			const data = cache.getAll();
			data.forEach(u => {
				this._handleIntentChangeForUtterance(u, model, changeType);
				this._handleEntityChangeForUtterance(u, model, changeType);
			});
			cache.mergeBatch(data);
		});
	}

	/**
	 * @description
	 * Marks a model as dirty
	 */
	private _markDirty = () => {
		this._dirtyBitService.setDirty(true);
	};

	private _utteranceChanged = () => {
		this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.UTTERANCE_CHANGED, true));
	};
}
