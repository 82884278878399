import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { IFocusable } from '../../utils/accessibility/focus-key-manager';
import { coerceBooleanProperty } from '../../utils/coercion/boolean-property';
import { MenuTriggerDirective } from '../menu-trigger';
import { MenuComponent } from '../menu.component';

@Component({
    selector: '[m-menu-item]',
    templateUrl: 'menu-item.component.html',
    styleUrls: ['menu-item.component.scss'],
    host: {
        'role': 'menuitem',
        '[class.m-menu-item]': 'true',
        '[attr.tabindex]': 'getTabIndex()',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[attr.disabled]': 'getDisabledAttr()',
        '(click)': 'checkDisabled($event)',
        '(mouseenter)': 'setAsActiveItem()',
        '(keyup.ENTER)': 'handleKeydownEnter($event)'
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent implements IFocusable, OnInit {
    // If supplied, generates the corresponding melder icon class.
    @Input() icon: string = null;
    @Input() active: boolean = false;
    @Input() prefixIcon: string = null;

    @Output() public selected: EventEmitter<void> = new EventEmitter<void>();

    // Reference to containing menu.
    public menu: MenuComponent;

    // Whether the menu item is disabled
    private _disabled: boolean = false;

    // Reference to item's trigger directive, if present.
    @ContentChild(forwardRef(() => MenuTriggerDirective)) trigger: MenuTriggerDirective;

    constructor(private _elementRef: ElementRef) { }

    public ngOnInit(): void {
        if (this.active) {
            this.setAsActiveItem();
        }
    }


    // Focuses the menu item.
    focus(): void {
        this._getHostElement().focus();
    }

    // Whether the menu item is disabled.
    @Input()
    get disabled() { return this._disabled; }
    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
    }

    /**
     * @description
     * Used to set the `tabindex`.
     */
    public getTabIndex(): string {
        return this._disabled ? '-1' : '0';
    }

    /**
     * @description
     * Used to set the HTML `disabled` attribute. Necessary for links to be disabled properly.
     */
    public getDisabledAttr(): boolean {
        return this._disabled ? true : null;
    }

    /**
     * @description
     * Prevents the default element actions if it is disabled.
     */
    public checkDisabled(event: Event): void {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this._handleMouseDown(event);
        }
    }

    /**
     * @description
     * Handles ENTER presses.
     */
    public handleKeydownEnter(event: Event): void {
        // Close parent menu if this is not a menu trigger
        this.trigger ? event.preventDefault() : this.menu._emitCloseEvent();
        this.selected.emit();
    }

    /**
     * @description
     * Sets this item as currently active in the containing menu.
     */
    public setAsActiveItem(): void {
        this.menu.setCurrentlyActiveItem(this);
    }

    /**
     * @description
     * Handles mouse down events.
     */
    private _handleMouseDown(event: Event): void {
        // Close parent menu if this is not a menu trigger
        this.trigger ? event.preventDefault() : this.menu._emitCloseEvent();
        this.selected.emit();
    }

    /**
     * @description
     * Returns the host DOM element.
     */
    private _getHostElement(): HTMLElement {
        return this._elementRef.nativeElement;
    }
}
