import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { GenericPromptService, IToasterService, PromptButtonTypes, SelectionMap, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { DropdownComponent } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { EntityHelpers } from '../../../helpers/entity.helpers';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { CompoundEntity } from '../../../models/compound-entity.model';
import { Entity, ENTITY_TYPES } from '../../../models/entity.model';
import { ParentEntity } from '../../../models/parent-entity.model';

/**
 * @description
 * Represents the entity details for a composite entity. Contains controls
 * for adding, deleting and updating composite entity children.
 */
@Component({
    selector: 'composite-details',
    templateUrl: 'composite-details.component.html',
    styleUrls: ['composite-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompositeDetailsComponent implements OnInit, OnDestroy {
    @Input() public entity: Observable<ParentEntity>;

    public entities: Observable<Entity[]>;
    public dropDownEntities: Observable<CompoundEntity[]>;
    public refreshableEntity: BehaviorSubject<ParentEntity> = new BehaviorSubject<ParentEntity>(null);
    public selectionMap: SelectionMap = new SelectionMap();
    public children: Observable<Entity[]>;

    private _entitySubscription: Subscription = new Subscription();

    constructor(
        private _promptService: GenericPromptService,
        private _i18n: TranslateService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) {}

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        this._entitySubscription.unsubscribe();
    }

    /**
     * @description
     * Add a new child to the entity children.
     *
     * @param compoundId The child id to be added.
     * @param entitiesDropDown The dropdown of entities.
     */
    public addChild(compoundId: string, entitiesDropDown: DropdownComponent): void {
        Observable.combineLatest(this.entities.first(), this.entity.first())
            .flatMap(([entities, parent]) => {
                const [entityId, roleId] = CompoundEntity.splitCompoundId(compoundId);
                const p: ParentEntity = parent.clone();
                const foundChild: Entity = entities.find(e => e.id === entityId);
                const newChild: Entity = foundChild.clone(parent);

                newChild.name = CompoundEntity.constructEntityWithRoleName(newChild, roleId);

                parent.children.push(newChild);

                return this._entityService.add(newChild);
            })
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.composite-details.add_toast') }))
            .subscribe(() => entitiesDropDown._clearSelection(), () => this._revertChanges());
    }

    /**
     * @description
     * Deletes the selected childs from the selection map.
     */
    public onDeleteClicked(): void {
        const ids = this.selectionMap.selectedItems;

        this._promptService
            .prompt(
                'Delete child?',
                `Are you sure you want to delete ${ids.length > 1 ? 'those children' : 'this child'}?`,
                { ok: 'Ok', cancel: 'Cancel' },
                { ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
            )
            .filter(response => response === 'ok')
            .flatMap(() => this.entity.first())
            .map(parent => ({ parent: parent, children: ids.map(id => parent.children.find(c => c.id === id)) }))
            .do(data => data.children.forEach(child => data.parent.removeChild(child)))
            .flatMap(data =>
                Observable.combineLatest(data.children.map(child => this._entityService.delete(child))).trackProgress(
                    this._toasterService.add({ startMsg: `Deleting ${ids.length > 1 ? 'children' : 'child'}` })
                )
            )
            .subscribe(() => this.selectionMap.markUnselected(ids), () => this._revertChanges());
    }

    /**
     * @description
     * Angular for loop tracking function avoids rerendering.
     */
    public trackChild(index: number, child: any): number {
        return child.name;
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.entities = this._entityService.get().map(entities => EntityHelpers.expandChildren(entities));

        this.dropDownEntities = combineLatest(this.entity, this.entities)
            .map(([parent, entities]) => <[ParentEntity, CompoundEntity[]]>[parent, CompoundEntity.expandEntityRoles(entities)])
            .map(([parent, entities]) => entities.filter(e => parent.children.find(c => c.name === e.name) === undefined))
            .map(entities => entities.filter(e => e.entity.type !== ENTITY_TYPES.COMPOSITE && e.entity.type !== ENTITY_TYPES.PATTERN_ANY));

        this._entitySubscription = this.entity
            .filter(e => e.type === ENTITY_TYPES.COMPOSITE)
            .map(e => e.clone())
            .do(e => e.children.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1)))
            .subscribe(e => this.refreshableEntity.next(e));

        this.children = this.refreshableEntity.map(e => e.children);
    }

    /**
     * @description
     * Reverts the changes to the editable entity with the original
     * entity stream value.
     */
    private _revertChanges(): void {
        this.entity.first().subscribe(e => this.refreshableEntity.next(e.clone()));
    }
}
