import { HttpParams } from '@angular/common/http';
import { EndpointKey } from './azure-key.model';

/**
 * @description
 * Builds the endpoint url to show in the keys table using
 * the settings provided by the IUrlSettings interface.
 */
export class EndpointV3UrlBuilder {
	private _url: string;
	private _params: HttpParams;

	constructor() {
		this._url = '';
		this._params = new HttpParams();
	}

	/**
	 * @description
	 * Sets the base url for the endpoint url. First tries to fetch from the
	 * endpoint url from the regions object and if it fails, gets the url
	 * from the key endpoint property.
	 *
	 * @param key The key to generate the url for.
	 * @param regions An object containing regions/base-url pairs.
	 */
	public setBaseUrl(key: EndpointKey, regions: Map<string, string>, appId: string): EndpointV3UrlBuilder {
		let url: string;

		if (regions.has(key.location)) {
			url = regions.get(key.location);
		}
		// Fallback to the Azure endpoint URL when the region is not found in
		// the regions map.
		else {
			url = key.endpoint.concat(`${key.endpoint.endsWith('/') ? '' : '/'}`);

			if (url.indexOf('luis') === -1) {
				url = url.concat('luis/v2.0/');
			}

			url = url.replace('/v1/', '/v2.0/');
			url = url.concat(`apps/${appId}`);
		}

		this._url = url.replace('v2.0', 'v3.0-preview');

		return this;
	}

	/**
	 * @description
	 * Sets the subscription key with the one given.
	 *
	 * @param key The subscription key to set.
	 */
	public setSubscriptionKey(key: string): EndpointV3UrlBuilder {
		this._params = this._params.set('subscription-key', key);

		return this;
	}

	/**
	 * @description
	 * Sets the spell check key to either on or off based on the settings
	 * given. Appends the bing spell check key if on.
	 *
	 * @param useSpellCheck Whether to use spell check or not.
	 * @param bingKey The key to use.
	 */
	public setSpellCheck(useSpellCheck: boolean, bingKey: string): EndpointV3UrlBuilder {
		if (useSpellCheck) {
			this._params = this._params.set('spellCheck', 'true');
			this._params = this._params.set('bing-spell-check-subscription-key', bingKey);
		}

		return this;
	}

	/**
	 * @description
	 * Sets the timezone in the url.
	 *
	 * @param timezone The timezone to set.
	 */
	public setTimezone(timezone: number): EndpointV3UrlBuilder {
		this._params = this._params.set('timezoneOffset', `${timezone * 60}`);

		return this;
	}

	/**
	 * @description
	 * Sets the verbosity setting of the endpoint call (whether to
	 * show all intents or not).
	 *
	 * @param verbosity The setting to apply.
	 */
	public setVerbosity(verbosity: boolean): EndpointV3UrlBuilder {
		if (verbosity) {
			this._params = this._params.set('verbose', 'true');
		}

		return this;
	}

	/**
	 * @description
	 * Sets the multi intent setting of the endpoint call (whether to
	 * show all intents or not).
	 *
	 * @param verbosity The setting to apply.
	 */
	public setMultIntent(isMultIntentEnabled: boolean): EndpointV3UrlBuilder {
		if (isMultIntentEnabled) {
			this._params = this._params.set('multiple-intents', 'true');
		}

		return this;
	}

	/**
	 * @description
	 * Sets the endpoint slot to use. We have two slots available,
	 * production and staging.
	 *
	 * @param isStaging True if the slot is staging and false otherwise.
	 */
	public setSlot(isStaging: boolean): EndpointV3UrlBuilder {
		this._url = this._url.concat(`/slots/${isStaging ? 'staging' : 'production'}`);

		return this;
	}

	/**
	 * @description
	 * Builds the url by adding the query suffix parameter and
	 * joining the parameters on the url encoding character. Finally
	 * concatenates the url with the parameters to use.
	 */
	public build(): string {
		this._params = this._params.set('query', '');

		return this._url.concat(`/predict?${this._params.toString()}`);
	}
}
