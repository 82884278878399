import { IApiExportable, ICloneable } from '@luis/core';

/**
 * @description
 * Represents the utterance pattern. An utterance pattern appear in
 * the result of prediction calls of utterances.
 *
 * @param patternId The id of the pattern that was fired.
 * @param patternName The name of the pattern that was fired.
 * @param intentName The intent that this pattern belongs to.
 * @param order The order of the pattern.
 */
export class UtterancePattern implements ICloneable, IApiExportable {
    public patternName: string;
    public intentName: string;
    public order: number;

    constructor(patternName: string = '', intentName: string = '', order: number = 0) {
        this.patternName = patternName;
        this.intentName = intentName;
        this.order = order;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): UtterancePattern {
        return new UtterancePattern(
            apiObject.pattern,
            apiObject.intent,
            apiObject.order
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            pattern: this.patternName,
            intent: this.intentName,
            order: this.order
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): UtterancePattern {
        return new UtterancePattern(this.patternName.slice(), this.intentName.slice(), this.order);
    }
}
