import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '../../utils/coercion/boolean-property';

/**
 * @description
 * Directive used for applying context menu ancor point
 * styles to a component.
 */
@Component({
    selector: '[m-menu-button]',
    templateUrl: 'menu-button.component.html',
    styleUrls: ['menu-button.component.scss'],
    host: {
        '[class.m-menu-button]': 'true',
        '[attr.tabindex]': '_getTabIndex()',
        '(click)': '_checkDisabled($event)',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[attr.disabled]': '_getDisabledAttr()',
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuButtonComponent {
    /** Whether or not the button is disabled. */
    _disabled: boolean = false;

    /** If supplied, generates the corresponding melder icon class. */
    @Input() icon: string = null;

    @Input()
    get disabled(): any {
        return this._disabled;
    }
    set disabled(v: any) {
        this._disabled = coerceBooleanProperty(v);
    }

    /** Returns the tab index depending on if the element is disabled */
    _getTabIndex(): string {
        if (this.disabled) {
            return '-1';
        }
        return '0';
    }

    /** Used to set the HTML `disabled` attribute. Necessary for links to be disabled properly. */
    _getDisabledAttr(): boolean {
        return this._disabled ? true : null;
    }

    /** Prevents the default element actions if it is disabled. */
    _checkDisabled(event: Event): void {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
