import { ICloneable } from '@luis/core';

/**
 * @dynamic
 * @description
 * Represents a prebuilt entity info datastructure. A prebuilt entity info object
 * contains metadata about a specific prebuilt entity.
 */
export class PrebuiltEntityInfo implements ICloneable {
    public name: string;
    public description: string;
    public examples: string;

    constructor(name: string = '', description: string = '', examples: string = '') {
        this.name = name;
        this.description = description;
        this.examples = examples;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): PrebuiltEntityInfo {
        return new PrebuiltEntityInfo(
            apiObject.name,
            apiObject.description,
            apiObject.examples
        );
    }

    /**
     * @method
     * @description
     * Creates an array of new resource objects from the api object
     * received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importListFromApi(apiObject: any[]): PrebuiltEntityInfo[] {
        return apiObject.map(p => PrebuiltEntityInfo.importFromApi(p));
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): PrebuiltEntityInfo {
        return new PrebuiltEntityInfo(
            this.name.slice(),
            this.description.slice(),
            this.examples.slice()
        );
    }
}
