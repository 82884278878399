import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App, APP_SERVICE_TOKEN, APP_VERSION_SERVICE_TOKEN, AppEndpointConfig, IAppService, IAppVersionService } from '@luis/apps';
import {
	BaseModalComponent,
	BaseService,
	BUS_EVENTS,
	EventBusMessage,
	EventBusService,
	IModalComponent,
	IToasterService,
	TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { IKeyService, KEY_SERVICE_TOKEN } from '../../interfaces/IKeyService';
import { IPublishService, PUBLISH_SERVICE_TOKEN } from '../../interfaces/IPublishService';
import { PublishSettings } from '../../models/publish.model';

export const PUBLISH_MODAL_COMP_TOKEN: InjectionToken<string> = new InjectionToken('PublishModalComponent');

/**
 * @description
 * Represents the modal for creating a new app.
 */
@Component({
	selector: 'publish-modal',
	templateUrl: 'publish-modal.component.html',
	styleUrls: ['publish-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishModalComponent extends BaseModalComponent implements OnInit, IModalComponent<App> {
	@Input() public response: Subject<App>;
	@Input() public keysSettingsPageUrl: string;

	public isStagingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public slot: Observable<AppEndpointConfig>;
	public publishDuration: Observable<string>;

	constructor(
		private readonly _router: Router,
		private readonly _i18n: TranslateService,
		private readonly _baseService: BaseService,
		private readonly _eventBus: EventBusService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(KEY_SERVICE_TOKEN) private readonly _keyService: IKeyService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(PUBLISH_SERVICE_TOKEN) private readonly _publishService: IPublishService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _appVersionService: IAppVersionService
	) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this._initState();
	}

	/**
	 * @method
	 * @description
	 * Publishes the application to the regions given
	 * by the assigned keys.
	 */
	public publish(): void {
		this._keyService
			.getAppKeys()
			.flatMap(keys => {
				const vId: string = this._baseService.configs.versionId;
				const filteredRegions: string[] = Array.from(new Set(keys.filter(k => k).map(k => k.location)));
				const { tracker, toast } = this._toasterService.add({
					startMsg: this._i18n.instant('publish-pane.publish-modal.publishing_app_to_regions', {
						regions: filteredRegions.join(', ')
					}),
					endMsg: [
						{ text: this._i18n.instant('publish-pane.publish-modal.publishing_complete_toast') },
						{
							text: this._i18n.instant('publish-pane.publish-modal.refer_to_the_list_of_endpoints'),
							handler: () => {
								this._router.navigate([this.keysSettingsPageUrl]);
								this._toasterService.delete(toast);
							},
							context: this
						},
						{ text: this._i18n.instant('publish-pane.publish-modal.to_acess_your_endpoint_url') }
					],
					isDismissible: true,
					returnToast: true
				});

				return this._publishService
					.publish(new PublishSettings(vId, this.isStagingSubject.getValue(), filteredRegions.join(',')))
					.flatMap(() => this._appService.getSingle().first())
					.map(app => app.clone())
					.do(app => this._addConfig(app, new AppEndpointConfig('', vId, '', new Date(), this.isStagingSubject.getValue())))
					.flatMap(app => this._appService.update(app, true))
					.flatMap(() => this._appVersionService.getVersion(vId, null, true).first())
					.do(version => {
						version.publishedDate = new Date();
						this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.PUBLISH_OCCURRED, true));
					})
					.flatMap(version => this._appVersionService.put(null, version))
					.trackProgress(tracker);
			})
			.first()
			.subscribe();

		this.response.next(null);
		this.response.complete();
	}

	/**
	 * @description
	 * Updates the app endpoint config in the app object.
	 *
	 * @param app The app object to add the config to.
	 * @param config The config to add.
	 */
	private _addConfig(app: App, config: AppEndpointConfig): void {
		const conIndex: number = app.endpointConfigs.findIndex(c => c.isStaging === config.isStaging);
		if (conIndex === -1) {
			app.endpointConfigs.push(config);
		} else {
			app.endpointConfigs[conIndex] = config;
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.slot = Observable.combineLatest(this.isStagingSubject.asObservable(), this._appService.getSingle()).pipe(
			map(([isStaging, app]) => app.endpointConfigs.find(c => c.isStaging === isStaging) || new AppEndpointConfig()),
			shareReplay(1)
		);

		this.publishDuration = Observable.combineLatest(this.slot, Observable.timer(0, 30000))
			.map(([slot]) => (slot && slot.publishedDate ? slot.publishedDate.getTime() : null))
			.map(publishedTime => this._getDateDifferenceMessage(Date.now(), publishedTime));
	}

	/**
	 * @description
	 * Given two timestamps, get the difference between them in wording.
	 *
	 * @param currentTime The current time
	 * @param previousTime The previous time
	 * @returns A wording of the difference between the times.
	 */
	private _getDateDifferenceMessage(currentTime: number, previousTime: number): string {
		const msPerMinute: number = 60 * 1000;
		const msPerHour: number = msPerMinute * 60;
		const msPerDay: number = msPerHour * 24;
		const msPerMonth: number = msPerDay * 30;
		const msPerYear: number = msPerDay * 365;
		const elapsed: number = currentTime - previousTime;
		let time: number;

		if (previousTime === null) {
			return this._i18n.instant('publish-pane.publish-modal.application_not_published');
		}

		if (elapsed < msPerMinute) {
			time = Math.round(elapsed / 1000);

			if (time === 0) {
				return this._i18n.instant('publish-pane.publish-modal.just_now');
			} else {
				return this._i18n.instant('publish-pane.publish-modal.seconds_ago', { time });
			}
		} else if (elapsed < msPerHour) {
			time = Math.round(elapsed / msPerMinute);

			return this._i18n.instant('publish-pane.publish-modal.minutes_ago', { time });
		} else if (elapsed < msPerDay) {
			time = Math.round(elapsed / msPerHour);

			return this._i18n.instant('publish-pane.publish-modal.hours_ago', { time });
		} else if (elapsed < msPerMonth) {
			time = Math.round(elapsed / msPerDay);

			return this._i18n.instant('publish-pane.publish-modal.approximately_days_ago', { time });
		} else if (elapsed < msPerYear) {
			time = Math.round(elapsed / msPerMonth);

			return this._i18n.instant('publish-pane.publish-modal.approximately_months_ago', { time });
		} else {
			time = Math.round(elapsed / msPerYear);

			return this._i18n.instant('publish-pane.publish-modal.approximately_years_ago', { time });
		}
	}
}
