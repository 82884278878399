import { Directive, HostListener, Input, Renderer2 } from '@angular/core';

/**
 * @description
 * A user voice widget loader to load the library when the link is clicked.
 * The rationale behind creating this directive was that if the library was
 * loaded in the initialization of the app or of the feedback panel, there
 * was no way to delay loading the library before the widget anchor was
 * renderered into the DOM. Therefore, the loading is now lazy and loads
 * when the user has interacted with the clickable link to load the widget.
 */
@Directive({
	selector: '[userVoiceLoader]'
})
export class UserVoiceLoaderDirective {
	@Input('userVoiceLoader') public userVoiceAnchor: HTMLElement;

	private _isLoaded: boolean = false;

	constructor(private readonly _renderer: Renderer2) {}

	@HostListener('click', ['$event'])
	@HostListener('keydown.enter', ['$event'])
	public async onClick(event: Event): Promise<void> {
		event.stopPropagation();
		await this._loadLibraryAndDispatchClick().then(() => this.userVoiceAnchor.dispatchEvent(new MouseEvent('click')));
	}

	/**
	 * @description
	 * Loads the uservoice widget if it hasn't been loaded before. After it loads it,
	 * it also creates a programmatic click on the current element to fire up the
	 * widget for first time use.
	 *
	 * If the widget was used, do nothing, and rely on the widget script to react to the
	 * fixed id of 'general-inquiry-link' that the widget depends on for firing the modal.
	 */
	private async _loadLibraryAndDispatchClick(): Promise<any> {
		return new Promise(resolve => {
			if (this._isLoaded) {
				return resolve();
			}

			this._isLoaded = true;
			this._renderer.setAttribute(this.userVoiceAnchor, 'id', 'general-inquiry-link');
			this._createScriptElement('assets/scripts/user_voice.js');

			setTimeout(() => {
				window.addEventListener('click', () => (<any>window).UserVoice.push(['hide']));
				window.addEventListener('focus', () => (<any>window).UserVoice.push(['hide']));

				return resolve();
			}, 100);
		});
	}

	/**
	 * @description
	 * Creates a script element with the given src attribute value.
	 *
	 * @param src The value to use a src attribute.
	 */
	private _createScriptElement(src: string): void {
		const scriptElement: HTMLScriptElement = document.createElement('script');
		scriptElement.src = src;
		scriptElement.charset = 'UTF-8';
		document.head.appendChild(scriptElement);
	}
}
