import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	DirtyBitService,
	EventBusMessage,
	EventBusService,
	GENERIC_CACHE_SERVICE_TOKEN,
	IGenericCacheService
} from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IDomainService } from '../interfaces/IDomainService';
import { Domain } from '../models/domain.model';

/**
 * @description
 * Represents a concrete implementation of the IDomainService interface for domain related
 * operations for a LUIS app. This implementation is meant for production use.
 */
@Injectable()
export class DomainService implements IDomainService {
	constructor(
		private readonly _baseService: BaseService,
		private readonly _eventBus: EventBusService,
		private readonly _dirtyBitService: DirtyBitService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	/**
	 * @description
	 * Gets the base url for the domain scoped api calls.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}`;
	}

	/**
	 * @method
	 * @description
	 * Gets the domains for the given app culture.
	 *
	 * @param culture The culture to get the domains for.
	 * @returns An observable of the fetched domains.
	 */
	public get(culture: string = 'en-us'): Observable<Domain[]> {
		const url: string = `${this._baseService.configs.apiUrl}/apps/customprebuiltdomains/${culture}`;
		const parser: (data: Object[]) => Domain[] = (data: Object[]) => data.map(d => Domain.importFromApi(d));

		return this._cacheService.get(url, parser, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @method
	 * @description
	 * Adds the given domain to the app.
	 *
	 * @param domain The domain to add.
	 * @returns An observable of the model ids added.
	 */
	public add(domain: Domain): Observable<string[]> {
		const url: string = `${this._baseUrl}/customprebuiltdomains`;

		return this._httpService
			.post<string[]>(url, domain.exportToApi(), this._baseService.defaultOptionsBuilder.build())
			.map(domain => {
				this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null));

				return <string[]>domain;
			})
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.DOMAIN_CHANGED, domain)))
			.do(this._markDirty.bind(this));
	}

	/**
	 * @method
	 * @description
	 * Deletes the given domain from the app.
	 *
	 * @param domain The domain to delete.
	 * @returns An observable to indicate completion.
	 */
	public delete(domain: Domain): Observable<void> {
		const url: string = `${this._baseUrl}/customprebuiltdomains/${domain.name}`;

		return this._httpService
			.delete<void>(url, this._baseService.defaultOptionsBuilder.build())
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)))
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.DOMAIN_CHANGED, domain)))
			.do(this._markDirty.bind(this));
	}

	/**
	 * @description
	 * Marks the current app as dirty.
	 */
	private _markDirty(): void {
		this._dirtyBitService.setDirty(true);
	}
}
