import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IEntityMenuEvent } from '../../../../interfaces/IEntityMenuEvent';
import { ISubMenu } from '../../../../interfaces/ISubmenu';
import { ClosedEntity, ClosedSublist } from '../../../../models/closed-entity.model';
import { EntityService } from '../../../../services/entity.service';
import { MenuSearchItemComponent } from '../../items/menu-search-item/menu-search-item.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'normalized-values-menu',
    templateUrl: 'normalized-values-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EntityService],
    encapsulation: ViewEncapsulation.None
})
export class NormalizedValuesMenuComponent implements ISubMenu {
    @Output() public existingEntityClick: EventEmitter<IEntityMenuEvent> = new EventEmitter();

    @ViewChild('menu') public menu: MatMenu;
    @ViewChild('searchItem') private _searchItem: MenuSearchItemComponent;

    public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        private _entityService: EntityService,
        private _i18n: TranslateService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService
    ) { }

    /**
     * @description
     * Adds the selected label to the specified sublist.
     *
     * @param list The sublist to which the label should be added.
     */
    public selectSublist(closedEntity: ClosedEntity, list: ClosedSublist): void {
        this.existingEntityClick.emit({ entity: closedEntity, eventData: list });
    }

    /**
     * @description
     * Adds a new sublist to the app and adds the selected label as synonym.
     */
    public addSublist(closedEntity: ClosedEntity, event?: KeyboardEvent): void {
        if (event) {
            event.stopPropagation();
        }

        const sublistName: string = this.querySubject.getValue().trim();

        if (!sublistName.length) {
            return;
        }
        const closedEntityClone = closedEntity.clone();

        const newSublist = new ClosedSublist(null, sublistName, [], closedEntityClone);
        newSublist.parent.sublists = [...newSublist.parent.sublists, newSublist];

        this._searchItem.disabled = true;

        this._entityService
            .add(newSublist)
            .pipe(
                finalize(() => {
                    this._searchItem.disabled = false;
                    this.querySubject.next('');
                })
            )
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.normalized-values-menu.create_toast') }))
            .subscribe(id => {
                newSublist.id = parseInt(<string>id, 10);
                this.existingEntityClick.emit({ entity: closedEntityClone, eventData: newSublist });
            });
    }
}
