import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input
} from '@angular/core';

@Directive({
    selector: '[positioningFromParent]'
})
export class PositioningFromParent implements AfterViewInit {
    @Input() public positioningProperties: string[] = [];
    @Input() public positioningRelativeTo: string = '';
    @Input() public positioningFromParent: string = '';

    private nativeEl: HTMLElement;

    constructor(el: ElementRef) {
        this.nativeEl = el.nativeElement;
    }

    public ngAfterViewInit(): void {
        let props: ClientRect = this.findParentNode(this.positioningFromParent).getBoundingClientRect();

        if (this.positioningRelativeTo) {
            props = this.relativizePosition(props);
        }

        for (const property of this.positioningProperties) {
            const v: number = props[property];
            if (!v) {
                continue;
            }
            this.nativeEl.style[property] = v + 'px';
        }
    }

    private relativizePosition(p: ClientRect): ClientRect {
        const parentProps: ClientRect = this.findParentNode(this.positioningRelativeTo).getBoundingClientRect();

        return {
            // Left and top of parent < child
            left: p.left - parentProps.left,
            top: p.top - parentProps.top,

            // Bottom and right of parent > child
            bottom: parentProps.bottom - p.bottom,
            right: parentProps.right - p.right,

            height: p.height,
            width: p.width
        };
    }

    private findParentNode(sel: string): HTMLElement {
        let parent: HTMLElement = this.nativeEl.parentElement;
        if (!sel) {
            return parent;
        }

        while (parent.parentElement && parent !== document.documentElement) {
            const nodes: NodeListOf<Element> = parent.parentElement.querySelectorAll(sel);

            if (this.isImmediateChild(nodes, parent)) {
                return parent;
            }

            parent = parent.parentElement;
        }

        throw new Error(`Unable to find parent with selector ${this.positioningFromParent}`);
    }

    private isImmediateChild(nodes: NodeListOf<Element>, elm: Element): boolean {
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].parentElement === elm.parentElement && nodes[i] === elm) {
                return true;
            }
        }

        return false;
    }
}
