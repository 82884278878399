import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2
} from '@angular/core';

/**
 * @description
 * Represents the base cell in the table row in a
 * table component.
 */
@Component({
    selector: 'base-cell',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseCellComponent implements OnInit {
    @Input() public width: number;

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Initializes the component.
     */
    protected _initState(): void {
        this._renderer.addClass(this._elementRef.nativeElement, `m-col-xs-${this.width}`);
        this._renderer.addClass(this._elementRef.nativeElement, `m-table-cell`);
    }
}
