import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, BUS_EVENTS, EventBusService, IPageInfo, ITrackerMessage, MODEL_CHANGE_TYPE, ProgressTracker } from '@luis/core';
import { Entity, ENTITY_TYPES } from '@luis/entities';
import { BehaviorSubject, Observable, Observer, of, Subscription } from 'rxjs';
import { combineLatest, filter, flatMap, map, switchMap, tap } from 'rxjs/operators';
import { IUtteranceCacheService, UTTERANCE_CACHE_SERVICE_TOKEN } from '../interfaces/services/IUtteranceCacheService';
import { DEFAULT_PREDICT_OPTIONS, IPredictOptions } from '../interfaces/utils/IPredictOptions';
import { Utterance } from '../models/utterance.model';

interface ICacheEntry {
	utterance: Utterance;
	upToDate: boolean;
}

@Injectable()
export class PredictionCacheService implements OnDestroy {
	private readonly _cache: Map<number, ICacheEntry>;

	private _trainSubscription: Subscription = new Subscription();
	private _modelChangeSubscription: Subscription = new Subscription();
	private _invalidationSubscription: Subscription = new Subscription();

	private readonly _upToDateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private readonly _triggerSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);

	private get _baseUrl(): string {
		return `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}`;
	}

	constructor(
		private readonly _baseService: BaseService,
		private readonly _eventBusService: EventBusService,
		@Inject(UTTERANCE_CACHE_SERVICE_TOKEN) private readonly _utteranceCacheService: IUtteranceCacheService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		this._cache = new Map();
		this._initState();
	}

	public static mergeUtterances(utteranceWithPredictions: Utterance, cacheUtterance: Utterance): Utterance {
		const fresh: Utterance = utteranceWithPredictions.clone();
		const cached: Utterance = cacheUtterance ? cacheUtterance.clone() : null;
		const isFreshPrediction: boolean = fresh.text === '';

		if (!cached) {
			return fresh;
		}

		const labeledIntent = cached.predictedIntents.filter(pI => pI.id === fresh.labeledIntent.id)[0];
		fresh.labeledIntent.score = labeledIntent.score;

		return new Utterance(
			fresh.id || cached.id,
			isFreshPrediction ? cached.text : fresh.text,
			isFreshPrediction ? cached.tokenizedText : fresh.tokenizedText,
			isFreshPrediction ? cached.labeledIntent : fresh.labeledIntent,
			isFreshPrediction ? fresh.predictedIntents : cached.predictedIntents,
			isFreshPrediction ? cached.labeledEntities : fresh.labeledEntities,
			isFreshPrediction ? fresh.predictedEntities : cached.predictedEntities,
			isFreshPrediction ? fresh.predictedPatterns : cached.predictedPatterns,
			undefined,
			isFreshPrediction ? fresh.alteredText : cached.alteredText,
			isFreshPrediction ? cached.assignedDate : fresh.assignedDate
		);
	}

	public ngOnDestroy(): void {
		this._trainSubscription.unsubscribe();
		this._upToDateSubject.unsubscribe();
		this._triggerSubject.unsubscribe();
		this._modelChangeSubscription.unsubscribe();
		this._invalidationSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Given some text, calls the prediction API, caches the result and returns it.
	 *
	 * @param utteranceText Utterance text to be predicted.
	 * @param options Prediction options
	 * @returns The predicted utterance
	 */
	public predict = (utteranceText: string, options: IPredictOptions = DEFAULT_PREDICT_OPTIONS): Observable<Utterance> => {
		let path: string = `${this._baseUrl}/predict?example=${encodeURIComponent(utteranceText)}`;

		path = path.concat(options.includePatterns ? '&patternDetails=true' : '');
		path = path.concat(options.includeMultiIntents ? '&multiple-intents=true' : '');

		return this._httpService.get(path, this._baseService.defaultOptionsBuilder.build()).pipe(
			map(response => Utterance.importFromApi(response)),
			tap(utterance => this._cache.set(utterance.id, { utterance, upToDate: true }))
		);
	};

	/**
	 * @description
	 * Gets the predictions of a specific model using the page information provided.
	 *
	 * @param modelId The model to get the examples from.
	 * @param pageInfo An object containing skip and take parameters.
	 * @returns An observable of utterances array
	 */
	public predictRange(modelId: string, pageInfo: IPageInfo): Observable<Utterance[]> {
		const path = `${this._baseUrl}/models/${modelId}/reviewPredictions?skip=${pageInfo.skip}&take=${pageInfo.take}`;

		return this._httpService.get<Object[]>(path, this._baseService.defaultOptionsBuilder.build()).pipe(
			map(response => response.map(Utterance.importFromApi)),
			tap(utterances => utterances.forEach(utterance => this._cache.set(utterance.id, { utterance, upToDate: true })))
		);
	}

	/**
	 * @description
	 * Gets the predictions of the given utterances, updates the predictions cache and the utterance cache.
	 *
	 * @param modelId The model to be updated when the new utterances with predictions are fetched
	 * @param utterances The utterances to be predicted
	 * @param tracker A tracker to be used when refreshing utterances
	 */
	public batchPredictAndUpdateCache(modelId: string, utterances: Utterance[], tracker?: Observer<ITrackerMessage>): Observable<{}> {
		return of(utterances)
			.pipe(
				combineLatest(this._triggerSubject),
				map(([items]) => items),
				filter(items => items.length > 0),
				switchMap(items =>
					this._getPredictions(items)
						.pipe(
							tap(newUtterances =>
								newUtterances.forEach(utterance => this._cache.set(utterance.id, { utterance, upToDate: true }))
							)
						)
						.trackProgress(() =>
							this.needsRefresh(items) && tracker !== undefined ? tracker : new ProgressTracker().getTracker()
						)
				),
				tap((newUtterances: Utterance[]) =>
					this._utteranceCacheService.mergeBatch(modelId, newUtterances, PredictionCacheService.mergeUtterances)
				)
			)
			.mapTo(null);
	}

	/**
	 * @description
	 * Marks the predictions in the cache dirty.
	 */
	public refreshPredictions(): void {
		this._upToDateSubject.next(false);
	}

	private _initState(): void {
		this._trainSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.TRAIN_OCCURRED, wasUpToDate =>
			this._upToDateSubject.next(wasUpToDate)
		);

		this._upToDateSubject.subscribe(wasUpToDate => {
			if (!wasUpToDate) {
				this._cache.forEach(cacheEntry => (cacheEntry.upToDate = false));
				this._triggerSubject.next(null);
			}
		});

		this._modelChangeSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.MODEL_CHANGED, msg => {
			const isNonDelete: boolean = msg.changeType !== MODEL_CHANGE_TYPE.DELETE;
			const isNonLabelable: boolean = !Entity.isMachineLearned(msg.model.type) && msg.model.type !== ENTITY_TYPES.PATTERN_ANY;

			if (isNonDelete && isNonLabelable) {
				this._upToDateSubject.next(false);
			}
		});

		this._invalidationSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.INVALIDATE_UTTERANCES_CACHE, () => {
			this._upToDateSubject.next(false);
		});
	}

	private _getPredictions(utterances: Utterance[], options: IPredictOptions = DEFAULT_PREDICT_OPTIONS): Observable<Utterance[]> {
		return of(utterances).pipe(
			map(this._reduceUtterances),
			flatMap(({ cachedUtterances, utterancesToBeFetched }) =>
				this._fetchPredictions(utterancesToBeFetched.map(({ text }) => text), options).pipe(
					tap(freshUtterances =>
						freshUtterances.forEach(utterance => this._cache.set(utterance.id, { utterance, upToDate: true }))
					),
					map(freshUtterances => {
						const oldPredictedUtterances = cachedUtterances.map(({ id }) => this._cache.get(id).utterance);

						return [...freshUtterances, ...oldPredictedUtterances];
					})
				)
			)
		);
	}

	/**
	 * @description
	 * Given an array of text, calls the prediction API and returns them.
	 *
	 * @param texts Utterance texts to be predicted.
	 * @param options Prediction options
	 * @returns An observable of the predicted utterances
	 */
	private _fetchPredictions(texts: string[], options: IPredictOptions = DEFAULT_PREDICT_OPTIONS): Observable<Utterance[]> {
		if (texts.length === 0) {
			return of([]);
		}

		let path: string = `${this._baseUrl}/predict`;
		path = path.concat('?getExampleIds=true');
		path = path.concat(options.includePatterns ? '&patternDetails=true' : '');

		return this._httpService
			.post(path, texts, this._baseService.defaultOptionsBuilder.build())
			.pipe(map((response: any[]) => response.map(Utterance.importFromApi)));
	}

	/**
	 * @description
	 * Checks whether an utterance should be refetched or not.
	 */
	private _shouldRefresh = (utterance: Utterance): boolean => !this._cache.has(utterance.id) || !this._cache.get(utterance.id).upToDate;

	/**
	 * @description
	 * Checks whether an utterance is fetched before but not cached in the utterance cache service.
	 */
	private _isFetchedNotCached = (utterance: Utterance): boolean =>
		this._cache.has(utterance.id) && utterance.predictedIntents.length === 0;

	private _reduceUtterances = (utterances: Utterance[]): { cachedUtterances: Utterance[]; utterancesToBeFetched: Utterance[] } =>
		utterances.reduce(
			(acc, elem) => {
				if (this._shouldRefresh(elem)) {
					return {
						...acc,
						utterancesToBeFetched: [...acc.utterancesToBeFetched, elem]
					};
				}
				if (this._isFetchedNotCached(elem)) {
					return { ...acc, cachedUtterances: [...acc.cachedUtterances, elem] };
				}

				return acc;
			},
			{ cachedUtterances: [], utterancesToBeFetched: [] }
		);

	/**
	 * @description
	 * Checks whether the given set of utterances have some not up to date utterances
	 */
	private needsRefresh(utterances: Utterance[]): boolean {
		return [...utterances.entries()]
			.map(item => this._cache.get(item[1].id))
			.filter(item => item !== undefined)
			.some(({ upToDate }) => !upToDate);
	}
}
