import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '../../interfaces/services/utils/IToasterService';
import { ITrainingService } from '../../interfaces/services/utils/ITrainingService';
import { BUS_EVENTS, EventBusMessage } from '../../models/utils/event-bus.model';
import {
	ITrainingStatus,
	MODEL_STATUS,
	ModelTrainStatus,
	PROGRESS_SUBSTATUS,
	SERVICE_STATUS,
	TRAIN_STATUS
} from '../../models/utils/training.model';
import { BaseService } from './base.service';
import { DirtyBitService } from './dirty-bit.service';
import { EventBusService } from './event-bus.service';

@Injectable()
export class TrainingService implements OnDestroy, ITrainingService {
	private readonly _isAppTrained: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _serviceStatus: BehaviorSubject<SERVICE_STATUS> = new BehaviorSubject<SERVICE_STATUS>(SERVICE_STATUS.READY);
	private readonly _trainStatus: BehaviorSubject<ITrainingStatus> = new BehaviorSubject<ITrainingStatus>(null);

	private _appTrainedSubscription: Subscription = new Subscription();

	constructor(
		private readonly _baseService: BaseService,
		private readonly _eventBus: EventBusService,
		private readonly _dirtyBitService: DirtyBitService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		this._initBusSubscribers();
	}

	public ngOnDestroy(): void {
		this._appTrainedSubscription.unsubscribe();
	}

	/**
	 * @method
	 * @description
	 * Should be called once on the beginning of application load
	 * to match the current version's training status.
	 *
	 * @param status True app is trained and false otherwise.
	 */
	public initializeTrainingStatus(status: boolean): void {
		this._isAppTrained.next(status);
	}

	/**
	 * @method
	 * @description
	 * Gets the observable for the current app's trained status.
	 *
	 * @returns An observable of the app train status.
	 */
	public appTrainedStatus(): Observable<boolean> {
		return this._isAppTrained.asObservable().distinctUntilChanged();
	}

	/**
	 * @method
	 * @description
	 * Gets the observable for the training service's status.
	 *
	 * @returns An observable of the service's status.
	 */
	public serviceStatus(): Observable<SERVICE_STATUS> {
		return this._serviceStatus.asObservable().distinctUntilChanged();
	}

	/**
	 * @method
	 * @description
	 * Gets the observable for the current training's status.
	 *
	 * @returns An observable of the current training's status.
	 */
	public trainStatus(): Observable<ITrainingStatus> {
		return this._trainStatus
			.asObservable()
			.filter(s => s !== null)
			.distinctUntilChanged();
	}

	/**
	 * @method
	 * @description
	 * Issues a new training request.
	 */
	public train(): void {
		if (this._serviceStatus.getValue() === SERVICE_STATUS.READY) {
			const url: string = `${this._baseUrl}/train`;
			this._serviceStatus.next(SERVICE_STATUS.QUEUED);

			this._httpService
				.post(url, null, this._baseService.defaultOptionsBuilder.build())
				.map(() => this._handleTrainingResults(0))
				.catch(error => this._handleAlreadyQueuedException(error))
				.trackProgress(this._toasterService.add())
				.subscribe(() => null, error => error);
		}
	}

	/**
	 * @description
	 * Handles a new training request by starting the polling status.
	 */
	private _handleTrainingResults(currentTrial: number): void {
		const trial: number = currentTrial + 1;

		this._poll().subscribe(
			models => {
				const failedModel: ModelTrainStatus = this._getFailedModel(models);

				if (this._isQueued(models)) {
					this._serviceStatus.next(SERVICE_STATUS.QUEUED);
					setTimeout(() => this._handleTrainingResults(trial), this._getPollTime(trial));
				} else if (failedModel !== undefined) {
					this._serviceStatus.next(SERVICE_STATUS.READY);
					this._isAppTrained.next(false);
					this._trainStatus.next({
						trainStatus: TRAIN_STATUS.FAIL,
						failedModel: failedModel
					});
				} else if (this._isUpToDate(models)) {
					this._serviceStatus.next(SERVICE_STATUS.READY);
					this._isAppTrained.next(true);
					this._trainStatus.next({
						trainStatus: TRAIN_STATUS.UP_TO_DATE
					});
					this._dirtyBitService.setDirty(false);
					this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.TRAIN_OCCURRED, true));
				} else if (this._isCompleted(models)) {
					this._serviceStatus.next(SERVICE_STATUS.READY);
					this._isAppTrained.next(true);
					this._trainStatus.next({
						trainStatus: TRAIN_STATUS.SUCCESS,
						modelCount: models.length
					});
					this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.TRAIN_OCCURRED, false));
				} else if (this._isCollectingData(models)) {
					this._serviceStatus.next(SERVICE_STATUS.IN_PROGRESS);
					this._trainStatus.next({
						trainStatus: TRAIN_STATUS.COLLECTING_DATA,
						modelCount: models.length
					});
					setTimeout(() => this._handleTrainingResults(trial), this._getPollTime(trial));
				} else if (this._isUploadingModel(models)) {
					this._serviceStatus.next(SERVICE_STATUS.IN_PROGRESS);
					this._trainStatus.next({
						trainStatus: TRAIN_STATUS.UPLOADING_MODEL,
						modelCount: models.length
					});
					setTimeout(() => this._handleTrainingResults(trial), this._getPollTime(trial));
				} else {
					this._serviceStatus.next(SERVICE_STATUS.IN_PROGRESS);
					this._trainStatus.next({
						trainStatus: TRAIN_STATUS.IN_PROGRESS,
						modelCount: models.length,
						completedCount: this._getCompletedModels(models)
					});
					setTimeout(() => this._handleTrainingResults(trial), this._getPollTime(trial));
				}
			},
			() => {
				this._serviceStatus.next(SERVICE_STATUS.READY);
				this._isAppTrained.next(false);
				this._trainStatus.next({ trainStatus: TRAIN_STATUS.FAIL });
			}
		);
	}

	/**
	 * @description
	 * Gets the baser url for the training service calls.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}`;
	}

	/**
	 * @description
	 * Polls the training service for the latest training results.
	 *
	 * @returns An observable of the current training results.
	 */
	private _poll(): Observable<ModelTrainStatus[]> {
		const url: string = `${this._baseUrl}/train`;

		return this._httpService
			.get(url, this._baseService.defaultOptionsBuilder.build())
			.map((status: Object[]) => status.map(s => ModelTrainStatus.importFromApi(s)));
	}

	/**
	 * @description
	 * Checks if all of the models are queued for training or not.
	 *
	 * @param models The models to check.
	 * @returns True if all the models are queued and vice versa.
	 */
	private _isQueued(models: ModelTrainStatus[]): boolean {
		return models.every(m => m.status.statusId === MODEL_STATUS.QUEUED);
	}

	/**
	 * @description
	 * Checks if any of the models failed in training or not.
	 *
	 * @param models The models to check.
	 * @returns The model that failed and undefined if not found.
	 */
	private _getFailedModel(models: ModelTrainStatus[]): ModelTrainStatus {
		return models.find(m => m.status.statusId === MODEL_STATUS.FAIL);
	}

	/**
	 * @description
	 * Checks if any of the models are still uploading.
	 *
	 * @param models The models to check.
	 * @returns True if any of the models is still collecting data and vice versa.
	 */
	private _isUploadingModel(models: ModelTrainStatus[]): boolean {
		return models.some(m => m.status.progressSubstatus === PROGRESS_SUBSTATUS.UPLOADING_MODEL);
	}

	/**
	 * @description
	 * Checks if any of the models are still collecting data or not.
	 *
	 * @param models The models to check.
	 * @returns True if any of the models is still collecting data and vice versa.
	 */
	private _isCollectingData(models: ModelTrainStatus[]): boolean {
		return models.every(m => m.status.progressSubstatus === PROGRESS_SUBSTATUS.COLLECTING_DATA);
	}

	/**
	 * @description
	 * Checks if all of the models are up to date or not.
	 *
	 * @param models The models to check.
	 * @returns True if all of the models are up to date and vice versa.
	 */
	private _isUpToDate(models: ModelTrainStatus[]): boolean {
		return models.every(m => m.status.statusId === MODEL_STATUS.UP_TO_DATE);
	}

	/**
	 * @description
	 * Checks if all of the models completed training or not.
	 *
	 * @param models The models to check.
	 * @returns True if all of the models completed training and vice versa.
	 */
	private _isCompleted(models: ModelTrainStatus[]): boolean {
		const successStates: MODEL_STATUS[] = [MODEL_STATUS.SUCCESS, MODEL_STATUS.SAMPLING, MODEL_STATUS.UP_TO_DATE];

		return models.filter(m => successStates.indexOf(m.status.statusId) !== -1).length === models.length;
	}

	/**
	 * @description
	 * Gets the number of completed models.
	 *
	 * @param models The models to check.
	 * @returns The number of completed models.
	 */
	private _getCompletedModels(models: ModelTrainStatus[]): number {
		return models.filter(m => m.status.statusId === MODEL_STATUS.SUCCESS || m.status.statusId === MODEL_STATUS.UP_TO_DATE).length;
	}

	/**
	 * @description
	 * Subscribes to changes in models and features and sets the app training status to false
	 * when changes are detected.
	 */
	private _initBusSubscribers(): void {
		this._appTrainedSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.NEEDS_TRAINING, () => this._isAppTrained.next(false));
	}

	/**
	 * @description
	 * Gets poll time with exponential backoff.
	 */
	private _getPollTime(trial: number): number {
		const resetCount: number = 13;

		return Math.exp((trial % resetCount) * 0.3) * 1000;
	}

	private _handleAlreadyQueuedException(error: Error): Observable<any> {
		if (error.name === '409') {
			this._handleTrainingResults(0);

			return Observable.of(null);
		}

		return Observable.of(error);
	}
}
