import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { APP_VERSION_SERVICE_TOKEN, IAppVersionService } from '../../../interfaces/IAppVersionService';
import { AppVersion } from '../../../models/app-version.model';
import { VersionNameInputComponent } from '../version-name-input/version-name-input.component';

/**
 * @description
 * Represents the modal for creating a new app.
 */
@Component({
	selector: 'version-rename-modal',
	templateUrl: 'version-rename-modal.component.html',
	styleUrls: ['version-rename-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionRenameModalComponent implements OnInit {
	@ViewChild('versionNameInput') public versionNameInput: VersionNameInputComponent;

	public isButtonDisabled: Observable<boolean>;

	private _originalVersionName: string;
	private readonly _inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<AppVersionRenameModalComponent>,
		@Inject(MAT_DIALOG_DATA) public readonly version: AppVersion,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _versionService: IAppVersionService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Renames the app version.
	 */
	public renameVersion(): void {
		this.isButtonDisabled
			.first()
			.filter(value => !value)
			.do(() => this._inProgress.next(true))
			.map(() => {
				const clone = this.version.clone();
				clone.id = this.versionNameInput.versionName;

				return clone;
			})
			.flatMap(c =>
				this._versionService
					.rename(null, this._originalVersionName, c)
					.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('apps.version-rename-modal.rename_toast') }))
					.finally(() => this._inProgress.next(false))
			)
			.subscribe(() => this._dialogRef.close(this.version));
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._originalVersionName = this.version.id;

		this.isButtonDisabled = Observable.combineLatest(
			this._inProgress.asObservable(),
			this.versionNameInput.versionNameValid.asObservable()
		).map(data => {
			const isInProgress: boolean = data[0];
			const isValid: boolean = data[1];

			return isInProgress || !isValid;
		});
	}
}
