import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseItemFilter, IToolBarOps, LuisModel, PaginationUIPayload, ProgressTracker, SelectionMap } from '@luis/core';
import { Entity, ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { ITrainingResultService, TRAINING_RESULT_SERVICE_TOKEN } from '@luis/training';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EvaluationFilter } from '../../../models/filters/evaluation-filter.model';
import { ExternalFilters } from '../../../models/filters/external-filters.model';
import { VIEW_OPTIONS } from '../../../models/plain-segment.model';
import { Utterance } from '../../../models/utterance.model';
import { ItemTableService } from '../../../services/item-table.service';
import { UtteranceStoreService } from '../../../services/utterance-store.service';

@Component({
	selector: 'utterance-table',
	templateUrl: 'utterance-table.component.html',
	styleUrls: ['utterance-table.component.scss'],
	providers: [ItemTableService, UtteranceStoreService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtteranceTableComponent implements OnInit, OnDestroy {
	@Input() public modelId: Observable<string> = Observable.of('');
	@Input() public pageSize: number = 10;
	@Input() public externalFilters: Observable<ExternalFilters> = Observable.of({
		evaluation: { correct: false, incorrect: false, unclear: false },
		nearestRivalIntentsIds: [],
		applied: false
	});

	@Output() public editEntity: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() public manageEntity: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() public unclearFilterActive: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public incorrectFilterActive: EventEmitter<boolean> = new EventEmitter<boolean>();

	public viewOps: typeof VIEW_OPTIONS = VIEW_OPTIONS;
	public selectionMap: SelectionMap = new SelectionMap();
	public fetchTracker: ProgressTracker = new ProgressTracker();
	public submitTracker: ProgressTracker = new ProgressTracker();
	public chunkingTracker: ProgressTracker = new ProgressTracker();
	public predictionsTracker: ProgressTracker = new ProgressTracker();
	public paginationUIPayload: PaginationUIPayload<Utterance> = new PaginationUIPayload<Utterance>();

	public intents: Observable<Intent[]>;
	public entities: Observable<Entity[]>;
	public trainingDate: Observable<Date>;
	public isIntentPage: Observable<boolean>;
	public toolbarOps: Observable<IToolBarOps>;
	public utteranceEntities: Observable<boolean>;
	public disablePredictions: Observable<boolean>;

	public query: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public pageIndex: BehaviorSubject<number> = new BehaviorSubject<number>(1);
	public editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public forceSubmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
		property: 'id',
		type: SORT_TYPE.DESCENDING
	});
	public evaluationRequired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public filters: BehaviorSubject<BaseItemFilter[]> = new BehaviorSubject<BaseItemFilter[]>([]);
	public tableViewMode: BehaviorSubject<VIEW_OPTIONS> = new BehaviorSubject<VIEW_OPTIONS>(VIEW_OPTIONS.SIMPLE_TABLE_VIEW);

	private _areFiltersActive: Observable<boolean>;
	private _dataTypeTogglerSubscription: Subscription = new Subscription();
	private _storeInitializationSubscription: Subscription = new Subscription();
	private readonly _unsubscribeSubject: Subject<void> = new Subject<void>();

	constructor(
		private readonly _itemTableService: ItemTableService,
		private readonly _utteranceStoreService: UtteranceStoreService,
		@Inject(TRAINING_RESULT_SERVICE_TOKEN) private readonly _trainingResultService: ITrainingResultService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._dataTypeTogglerSubscription.unsubscribe();
		this._storeInitializationSubscription.unsubscribe();
		this._unsubscribeSubject.next(null);
		this._unsubscribeSubject.complete();
		this.paginationUIPayload.dispose();
	}

	public filtersChanged(filters: BaseItemFilter[]): void {
		filters.forEach(f => {
			if (f instanceof EvaluationFilter) {
				const activeEvalFilters = f.getTypes();
				this.incorrectFilterActive.emit(activeEvalFilters.incorrect);
				this.unclearFilterActive.emit(activeEvalFilters.unclear);
			}
		});
		this.filters.next(filters);
	}

	public setPage(newIndex: number): void {
		this.pageIndex.next(newIndex);
	}

	public trackByFun(_: number, item: Utterance): number {
		return item.id;
	}

	/**
	 * @description
	 * Submits the item given using the item store.
	 *
	 * @param item The item to submit.
	 */
	public onItemSubmitted(item: Utterance): void {
		this.forceSubmit.next(false);
		this._utteranceStoreService
			.submitItem(item)
			.trackProgress(this.submitTracker.getTracker())
			.subscribe();
	}

	/**
	 * @description
	 * Reassigns the selected items to the given intent.
	 *
	 * @param intent The intent to reassign to.
	 */
	public batchReassignItems(intent: Intent): void {
		this._utteranceStoreService
			.batchReassign(intent)
			.pipe(takeUntil(this._unsubscribeSubject))
			.subscribe();
	}

	/**
	 * @description
	 * Adds this utterance as new pattern.
	 */
	public addAsPattern(): void {
		this._utteranceStoreService
			.addAsPattern()
			.pipe(takeUntil(this._unsubscribeSubject))
			.subscribe();
	}

	/**
	 * @description
	 * Deletes the selected items.
	 */
	public batchDeleteItems(): void {
		this._utteranceStoreService
			.batchDelete()
			.pipe(takeUntil(this._unsubscribeSubject))
			.subscribe();
	}

	public tableViewChanged(view: number): void {
		this.tableViewMode.next(view);
	}

	public selectionChanged(id: number): void {
		this.selectionMap.toggleSelection([id]);
		this.editMode.next(false);
	}

	public get noCreatedItems(): Observable<boolean> {
		return Observable.combineLatest(this.paginationUIPayload.isEmpty, this.submitTracker.isNotStarted, this._areFiltersActive).pipe(
			map(([isEmpty, submitStarted, isFiltersActive]) => isEmpty && submitStarted && !isFiltersActive)
		);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._areFiltersActive = this.filters.pipe(map(filters => filters.reduce((acc, val) => val.isActive() || acc, false)));

		this.intents = this._intentService.get();

		this.entities = this._entityService.get();

		this.isIntentPage = combineLatest(this.intents, this.modelId).pipe(
			map(([intents, id]) => intents.find(i => i.id === id)),
			map(intent => (intent ? true : false))
		);

		this.toolbarOps = this.selectionMap.toolbarOps;

		const chunkingTrigger = combineLatest(this.query, this._areFiltersActive).pipe(
			map(([query, filters]) => query.length !== 0 || filters)
		);

		const page = combineLatest(this.pageIndex, this.query, this._areFiltersActive, this.tableViewMode, this.evaluationRequired).pipe(
			switchMap(([pageIndex, query, filters, tableMode, evaluation]) =>
				this._utteranceStoreService
					.fetch(pageIndex - 1, query.length !== 0 || filters, evaluation || tableMode === this.viewOps.DETAILED_TABLE_VIEW)
					.pipe(takeUntil(this._unsubscribeSubject))
			)
		);

		this.paginationUIPayload.init({
			page$: page,
			pageIndex$: this.pageIndex,
			chunkingTrigger$: chunkingTrigger,
			config: { ngxPaginationId: 'utterances', pageSize: this.pageSize },
			chunkingTracker: this.chunkingTracker
		});

		this.utteranceEntities = Observable.combineLatest(page, this.selectionMap.selectedItemsAsync).map(([p, selectedIds]) =>
			p.data
				.filter(u => selectedIds.indexOf(u.id) !== -1)
				.map(u => [...u.labeledEntities, ...u.predictedEntities])
				.reduce((acc, value) => acc || value.length > 0, false)
		);

		this._storeInitializationSubscription = combineLatest(this.intents, this.entities, this.modelId)
			.pipe(
				first(),
				map(([intents, entities, id]) => {
					const foundIntent = intents.find(i => i.id === id);
					if (foundIntent) {
						return { model: foundIntent, type: 'intent' };
					}
					const foundEntity = entities.find(e => e.id === id);
					if (foundEntity) {
						return { model: foundEntity, type: 'entity' };
					}

					return { model: new LuisModel(), type: 'intent' };
				}),
				tap(({ model, type }: { model: LuisModel; type: 'intent' | 'entity' }) =>
					this._utteranceStoreService.init({
						model,
						type,
						selectionMap: this.selectionMap,
						chunkingTrigger,
						predictionsTracker: this.predictionsTracker,
						chunkingTracker: this.chunkingTracker
					})
				)
			)
			.subscribe();

		this._dataTypeTogglerSubscription = fromEvent(window, 'keydown')
			.pipe(
				filter((ev: KeyboardEvent) => ev.keyCode === 69 && ev.ctrlKey),
				tap((ev: KeyboardEvent) => ev.preventDefault()),
				tap((ev: KeyboardEvent) => ev.stopPropagation())
			)
			.subscribe(() => this._itemTableService.toggleViewMode());

		this.trainingDate = this._trainingResultService.getLastTrainingDate().pipe(
			filter(date => date !== null),
			shareReplay()
		);
	}
}
