import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseService } from '@luis/core';
import { Observable } from 'rxjs/Rx';

@Component({
	selector: 'auth-refresher',
	template: '<iframe id="renewSession" hidden #myFrame></iframe>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthRefresherComponent implements OnInit {
	@ViewChild('myFrame') private _frame: ElementRef;

	private _idleTime: number = 0;

	constructor(private readonly _baseService: BaseService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._initLoginRefresher();
		this._initIdleRefresher();
	}

	/**
	 * @description
	 * Initializes the login refresher.
	 */
	private _initLoginRefresher(): void {
		const baseUrl: string = this._baseService.configs.directApiUrl.substring(
			0,
			this._baseService.configs.directApiUrl.indexOf('/api/v2.0')
		);
		const redirectUrl: string = `${baseUrl}/Identity/SignIn`;

		Observable.interval(3540000).subscribe(() => {
			const frame: HTMLIFrameElement = this._frame.nativeElement;
			frame.src = redirectUrl;
		});
	}

	/**
	 * @description
	 * Initializes the webpage idle time refresher. Checks if the
	 * webpage has been idle every minute. Refreshes the idle time
	 * counter if any mouse movement or keypress occured.
	 */
	private _initIdleRefresher(): void {
		setInterval(this._incrementIdleTimer.bind(this), 60000);

		document.addEventListener('mousemove', () => (this._idleTime = 0));
		document.addEventListener('keypress', () => (this._idleTime = 0));
	}

	/**
	 * @description
	 * Increments the idle timer by one. If it reaches 60,
	 * equivalent to an hour of time, it refreshes the page.
	 */
	private _incrementIdleTimer(): void {
		this._idleTime = this._idleTime + 1;

		if (this._idleTime > 60) {
			window.location.reload();
		}
	}
}
