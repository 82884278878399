import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { IDataItem } from '../interfaces/models/IDataItem';
import { BaseItemFilter } from '../models/utils/base-item-filter.model';

/**
 * @description
 * Represents a pipe used to execute filters on the given
 * dataset of models.
 */
@Pipe({
    name: 'itemFilterAsync'
})
export class ItemFilterAsyncPipe implements PipeTransform {
    /**
     * @method
     * @description
     * Filters the given models with the given model filters.
     *
     * @param dataset The models to filter.
     * @param itemFilters The filters to apply.
     * @returns An observable of the filtered models.
     */
    public transform<T extends IDataItem>(dataset: Observable<T[]>, itemFilters: Observable<BaseItemFilter[]>): Observable<T[]> {
        return Observable.combineLatest(dataset, itemFilters)
            .map(([data, filters]) =>
                data.filter(u => {
                    for (const filter of filters) {
                        if (filter.isActive() && !filter.filter(u)) {
                            return false;
                        }
                    }

                    return true;
                })
            );
    }
}
