﻿import { Directive, HostListener, Input } from '@angular/core';
import { ENTER } from '@luis/ui';
import { AnalyticsService } from '../services/utils/analytics.service';

/**
 * Event tracking directive.
 * When the host element is clicked, it sends event data to ga's servers
 * The event's information are taken as inputs
 */
@Directive({
	selector: '[event-category]'
})
export class EventDirective {
	@Input('event-category') public category: string;
	@Input('event-action') public action: string;
	@Input('event-label') public label: string;
	@Input('event-value') public value: number;

	constructor(private readonly analyticsService: AnalyticsService) {}

	/**
	 * @description
	 * Tracks the click events on the current element.
	 */
	@HostListener('click')
	public onClick(): void {
		this._trackEvent();
	}

	/**
	 * @description
	 * Tracks key up events on the current element.
	 *
	 * @param event The event that was fired
	 * with the click.
	 */
	@HostListener('keyup', ['$event'])
	public onKeyUp(event: KeyboardEvent): void {
		if (event.keyCode === ENTER) {
			this._trackEvent();
		}
	}

	/**
	 * @description
	 * Sends the event information to the GA service.
	 */
	private _trackEvent(): void {
		this.analyticsService.trackEvent(this.category, this.action, this.label, this.value);
	}
}
