import { Pipe, PipeTransform } from '@angular/core';
import { UtterancePattern } from '@luis/patterns';
import { Utterance } from '@luis/utterances';

@Pipe({ name: 'showPatternError' })
export class ShowPatternErrorPipe implements PipeTransform {
	public transform(utterance: Utterance, pattern: UtterancePattern, index: number): any {
		return utterance.predictedIntents[0].name !== pattern.intentName && index === 0;
	}
}
