/**
 * @description
 * The confustion type of the entity.
 */
export enum CONFUSION_TYPE {
	TRUE_POSITIVE = 1,
	TRUE_NEGATIVE = 2,
	FALSE_POSITIVE = 3,
	FALSE_NEGATIVE = 4
}

/**
 * @dynamic
 * @description
 * Represents an entry in the confustion matrix for an entity.
 */
export class EntityConfusion {
	public type: CONFUSION_TYPE;
	public score: number;

	constructor(type: number = null, score: number = null) {
		this.type = type;
		this.score = score;
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): EntityConfusion[] {
		return apiObject.map(obj => new EntityConfusion(obj.confusionType, obj.score));
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): EntityConfusion {
		return new EntityConfusion(this.type, this.score);
	}
}
