import { IDataItem } from '../../interfaces/models/IDataItem';

/**
 * @description
 * Represents a base class for all item filters that
 * are to be used on the item table.
 */
export abstract class BaseItemFilter {
    private _isActive: boolean = false;

    /**
     * @method
     * @description
     * Toggles the activation of the filter.
     */
    public toggleActivation(data?: any): void {
        this._isActive = !this._isActive;
    }

    /**
     * @method
     * @description
     * Returns the active state of the filter.
     */
    public isActive(): boolean {
        return this._isActive;
    }

    /**
     * @method
     * @description
     * Checks if the data item given passes this filter's criteria.
     *
     * @param item The data item to filter.
     * @returns True if the utterance should be displayed
     * and false otherwise.
     */
    public abstract filter(item: IDataItem): boolean;
}
