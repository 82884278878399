import { Injectable, OnDestroy } from '@angular/core';
import { BUS_EVENTS, EventBusService, ICountService } from '@luis/core';
import { EntityUtilitiesService } from '@luis/entities';
import { IntentUtilitiesService } from '@luis/intents';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @description
 * An implementation of the ICountService that can be used together with UtteranceCacheService to
 * get the count for the specified model.
 */
@Injectable()
export class UtterancesCountService implements ICountService, OnDestroy {
    private _eventBusSubscription: Subscription = new Subscription();
    private _countSubscription: Subscription = new Subscription();

    constructor(
        private _eventBus: EventBusService,
        private _intentUtilService: IntentUtilitiesService,
        private _entityUtilService: EntityUtilitiesService
    ) {
        this._subscribeToDirtybitChanges();
    }

    public ngOnDestroy(): void {
        this._eventBusSubscription.unsubscribe();
        this._countSubscription.unsubscribe();
    }

    public getCount(fetchPath: string): Observable<number> {
        throw new Error('Method not implemented');
    }

    /**
     * @description
     * Gets the count of both labels per intent and examples per entity
     *
     * @returns Map of all the counts
     */
    public getAllCount(): Observable<Map<string, number>> {
        return combineLatest(this._intentUtilService.getLabelsPerIntent(), this._entityUtilService.getExamplesPerEntity()).pipe(
            map(([intentLabelsCount, entityExamplesCount]) => {
                entityExamplesCount.forEach((val, key) => intentLabelsCount.set(key, val));

                return intentLabelsCount;
            })
        );
    }

    /**
     * @description
     * Subscribe to the dirtybit flag, when it's set to true,
     * the function refreshes both labels per intent count and examples per entity count
     * TODO: This needs to be revisited, we are refreshing all counts whenever the user does deletes or adds an utterance
     */
    private _subscribeToDirtybitChanges(): void {
        this._eventBusSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.UTTERANCE_CHANGED, flag => {
            if (flag) {
                this._countSubscription = combineLatest(
                    this._intentUtilService.getLabelsPerIntent(true),
                    this._entityUtilService.getExamplesPerEntity(true),
                    this._entityUtilService.getLabelsPerEntity(true)
                ).subscribe();
            }
        });
    }
}
