import { ILinkable, IResource, LuisModel } from '@luis/core';

/**
 * @description
 * The supported entity types in LUIS.
 */
export enum ENTITY_TYPES {
	SIMPLE = 1,
	PREBUILT = 2,
	HIERARCHICAL = 3,
	COMPOSITE = 4,
	CLOSED = 5,
	HIERARCHICAL_CHILD = 6,
	PATTERN_ANY = 7,
	REGEX = 8,
	CLOSED_SUBLIST = 9,
	WHITELIST_ITEM = 10,
	ROLE = 11,
	DOMAIN = 100
}

/**
 * @description
 * Represents a LUIS entity.
 *
 * @param id The entity unique identifier.
 * @param name The entity name.
 * @param type The entity type from the types enum.
 * @param parent A reference to the parent of the entity. Null if root entity.
 */
export class Entity extends LuisModel implements IResource, ILinkable {
	constructor(
		public id: string = '',
		public name: string = '',
		public type: number = ENTITY_TYPES.SIMPLE,
		public labelCount: number = 0,
		public roles: EntityRole[] = [],
		public parent: Entity = null,
		public domainName: string = null,
		public originalEntityName: string = null
	) {
		super(id, name, type);
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): Entity {
		return Entity.constructBaseEntity(apiObject, new Entity());
	}

	/**
	 * @description
	 * Checks if the type given is of manually labelable entities or not.
	 *
	 * @returns True if entity type is manually labeable and false otherwise.
	 */
	public static isMachineLearned(type: number): boolean {
		switch (type) {
			case ENTITY_TYPES.REGEX:
			case ENTITY_TYPES.CLOSED:
			case ENTITY_TYPES.PREBUILT:
			case ENTITY_TYPES.PATTERN_ANY:
				return false;
			default:
				return true;
		}
	}

	/**
	 * @description
	 * Defines a base factory function that includes all the
	 * commong mappings for all entities.
	 *
	 * @param apiObject The web api object.
	 * @param target The target entity to fill.
	 * @returns The base entity with the parameters set.
	 */
	protected static constructBaseEntity(apiObject: any, target: Entity): Entity {
		target.id = apiObject.id ? apiObject.id : target.id;
		target.name = apiObject.name ? apiObject.name : target.name;
		target.type = apiObject.typeId ? apiObject.typeId : target.type;
		target.labelCount = 0;
		target.roles = apiObject.roles ? (<any[]>apiObject.roles).map(r => EntityRole.importFromApi(r)) : [];
		target.roles.forEach(r => r.setParent(target));
		target.domainName = apiObject.customPrebuiltDomainName ? apiObject.customPrebuiltDomainName : null;
		target.originalEntityName = apiObject.customPrebuiltModelName ? apiObject.customPrebuiltModelName : null;

		return target;
	}

	/**
	 * @description
	 * Defines a base factory function to clone the common
	 * properties for all entities.
	 *
	 * @param source The source entity to clone from.
	 * @param target The target entity to clone to.
	 * @param entityParent An optional parent the new entity points to.
	 * @returns The entity with the common properties filled.
	 */
	protected static cloneBaseEntity(source: Entity, target: Entity, entityParent?: Entity): Entity {
		target.id = source.id.slice();
		target.name = source.name.slice();
		target.type = source.type;
		target.labelCount = source.labelCount;
		target.roles = source.roles.map(r => r.clone(target));
		target.parent = entityParent ? entityParent : source.parent;
		target.domainName = source.domainName ? source.domainName.slice() : null;
		target.originalEntityName = source.originalEntityName ? source.originalEntityName.slice() : null;

		return target;
	}

	/**
	 * @method
	 * @description
	 * Appends a role to the entity's roles.
	 */
	public appendRole(roleName: string): EntityRole {
		const r: EntityRole = new EntityRole('', roleName);
		r.setParent(this);
		this.roles.push(r);

		return r;
	}

	/**
	 * @method
	 * @description
	 * Deletes the role at the given index from the entity.
	 *
	 * @param index The index of the role to remove.
	 */
	public removeRole(index: number): void {
		this.roles.splice(index, 1);
	}

	/**
	 * @method
	 * @description
	 * Checks if the current entity object is valid or not.
	 *
	 * @returns True if valid and false otherwise.
	 */
	public isValid(): boolean {
		const entityNameLength: number = this.name.trim().length;

		return entityNameLength > 0 && entityNameLength <= 50;
	}

	/**
	 * @method
	 * @description
	 * Prints out a readable version of the entity type.
	 *
	 * @returns A human readable representation of the type.
	 */
	public prettyPrintType(): string {
		let t: string = '';

		if (this.domainName !== null) {
			t = 'Domain-';
		}

		switch (this.type) {
			case ENTITY_TYPES.SIMPLE:
				return `${t}Simple`;
			case ENTITY_TYPES.HIERARCHICAL:
				return `${t}Hierarchical`;
			case ENTITY_TYPES.COMPOSITE:
				return `${t}Composite`;
			case ENTITY_TYPES.PREBUILT:
				return `${t}Prebuilt`;
			case ENTITY_TYPES.CLOSED:
				return `${t}List`;
			case ENTITY_TYPES.REGEX:
				return `${t}Regex`;
			case ENTITY_TYPES.PATTERN_ANY:
				return `${t}Pattern.any`;
			default:
				return `${t}Simple`;
		}
	}

	/**
	 * @method
	 * @description
	 * Checks if this entity is manually labelable or not. Prebuilt entities
	 * are not manually labeable through user interactions.
	 *
	 * @returns True if entity is manually labeable and false otherwise.
	 */
	public isEntityMachineLearned(): boolean {
		return Entity.isMachineLearned(this.type);
	}

	/**
	 * @method
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			id: this.id,
			name: this.name,
			modelName: this.originalEntityName,
			domainName: this.domainName
		};
	}

	/**
	 * @method
	 * @description
	 * Returns the name of the entity. This method is mainly used for prebuilt
	 * entities or parent entity children.
	 *
	 * @returns The name of the entity.
	 */
	public exportAsNameToApi(): string {
		return this.name;
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @param entityParent The parent to use instead of the object's current parent,
	 * used in case the parent is cloned so child references the new parent.
	 * @returns A deep clone of this object.
	 */
	public clone(entityParent?: Entity): Entity {
		return Entity.cloneBaseEntity(this, new Entity(), entityParent);
	}

	/**
	 * @method
	 * @description
	 * Sets this entity's parent to the passed argument value
	 *
	 * @param parentEntity The parent entity to assign this entity to.
	 */
	public setParent(parentEntity: Entity): void {
		this.parent = parentEntity;
	}

	/**
	 * @description
	 * Returns the unique name of the entity.
	 *
	 * @return the unique name.
	 */
	public get uniqueName(): string {
		if (this.type === ENTITY_TYPES.PREBUILT) {
			return undefined;
		}

		return this.domainName ? `${this.domainName}.${this.originalEntityName}` : this.name;
	}
}

/**
 * @description
 * Represents an entity role to be used in patterns.
 *
 * @param id The role id.
 * @param name The role name.
 * @param type The role type.
 * @param parent The parent the role belongs to.
 */
export class EntityRole extends LuisModel implements IResource, ILinkable {
	constructor(public id: string = '', public name: string = '', public type: number = ENTITY_TYPES.ROLE, public parent: Entity = null) {
		super(id, name, type);
	}

	public get fullname(): string {
		return (this.parent ? `${this.parent.name}:` : '') + this.name;
	}
	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): EntityRole {
		return new EntityRole(apiObject.id, apiObject.name);
	}

	/**
	 * @method
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			id: this.id,
			name: this.name
		};
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @param entityParent The parent to use instead of the object's current parent,
	 * used in case the parent is cloned so child references the new parent.
	 * @returns A deep clone of this object.
	 */
	public clone(entityParent?: Entity): EntityRole {
		return new EntityRole(this.id.slice(), this.name.slice(), this.type, entityParent ? entityParent : this.parent);
	}

	/**
	 * @method
	 * @description
	 * Sets this role's parent to the passed argument value
	 *
	 * @param parentEntity The parent entity to assign this entry to.
	 */
	public setParent(parentEntity: Entity): void {
		this.parent = parentEntity;
	}
}
