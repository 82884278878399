﻿import { Inject, Injectable } from '@angular/core';
import { RequestOptionsBuilder } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	EventBusMessage,
	EventBusService,
	Id,
	IResourceCacheService,
	RESOURCE_CACHE_SERVICE_TOKEN
} from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IPhraseListService } from '../interfaces/IPhraseListService';
import { PhraseList } from '../models/phrase-list.model';

@Injectable()
export class PhraseListService implements IPhraseListService {
	constructor(
		private _baseService: BaseService,
		private _eventBus: EventBusService,
		@Inject(RESOURCE_CACHE_SERVICE_TOKEN) private _resourceCacheService: IResourceCacheService
	) {}

	/**
	 * @description
	 * Gets the base web url for manipulating application's phrase lists.
	 *
	 * @returns The base url for phrase lists manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}/phraselists`;
	}

	/**
	 * @description
	 * Gets the phrase lists of the application.
	 *
	 * @param refresh Force cache refresh if the value is true.
	 * @returns An observable of the application's phrase lists.
	 */
	public get(refresh: boolean = false): Observable<PhraseList[]> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return <Observable<PhraseList[]>>this._resourceCacheService.get(this._baseUrl, PhraseList.importFromApi, builder.build());
	}

	/**
	 * @description
	 * Adds a phrase list to the application.
	 *
	 * @param phraseList The phrase list to add.
	 * @param refresh If true, the phrase list is refresehd from the api after adding it.
	 * @returns An observable of the id of the newly added phrase list.
	 */
	public add(phraseList: PhraseList, refresh: boolean = false): Observable<number> {
		return this._resourceCacheService
			.post(this._baseUrl, phraseList, this._baseService.defaultOptionsBuilder.build(), PhraseList.importFromApi)
			.map(id => <number>id)
			.do(id => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	/**
	 * @description
	 * Updates an existing phrase list in the application.
	 *
	 * @param phraseList The phrase list to update.
	 * @returns An observable to indicate completion.
	 */
	public update(phraseList: PhraseList): Observable<void> {
		return this._resourceCacheService
			.put(`${this._baseUrl}/${phraseList.id}`, phraseList, this._baseService.defaultOptionsBuilder.build(), this._baseUrl)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	/**
	 * @description
	 * Deletes an existing phrase list from the application.
	 *
	 * @param id The id of the phrase list to delete.
	 * @returns An observable to indicate completion.
	 */
	public delete(id: number): Observable<void> {
		return this._resourceCacheService
			.delete(`${this._baseUrl}/${id}`, id, this._baseService.defaultOptionsBuilder.build(), this._baseUrl)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	/**
	 * @description
	 * Deletes an existing phrase list from the application.
	 *
	 * @param id The id of the phrase list to delete.
	 * @returns An observable to indicate completion.
	 */
	public batchDelete(phraseLists: PhraseList[]): Observable<Id[]> {
		return this._resourceCacheService
			.batchDelete(`${this._baseUrl}`, phraseLists.map(p => p.id), this._baseService.defaultOptionsBuilder.build(), false)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}
}
