﻿import { Inject, Injectable } from '@angular/core';
import { BaseService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs';
import { AdminSettings } from '../models/admin.model';

/**
 * @description
 * Represents a lightweight service to get administrator settings for the LUIS
 * website.
 */
@Injectable()
export class AdminService {
	constructor(
		private readonly _baseService: BaseService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	/**
	 * @description
	 * Gets the admin settings for the website.
	 *
	 * @returns An observable of the admin settings.
	 */
	public getSettings(): Observable<AdminSettings> {
		const url: string = `/api/AdminSettings/${this._baseService.configs.env}`;

		return this._cacheService.get(url, AdminSettings.importFromApi, this._baseService.optionsBuilder.useJSON().build());
	}
}
