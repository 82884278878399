import { Pipe, PipeTransform } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { ISubMenu } from '../interfaces/ISubmenu';
import { Entity, ENTITY_TYPES } from '../models/entity.model';

/**
 * @description
 */
@Pipe({
	name: 'entityDropdownSubMenu'
})
export class EntityDropdownSubMenu implements PipeTransform {
	public transform(entity: Entity, hierarchicalMenu: ISubMenu, roles: ISubMenu): MatMenu {
		return entity.type === ENTITY_TYPES.HIERARCHICAL ? hierarchicalMenu.menu : roles.menu;
	}
}
