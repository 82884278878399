import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TableService } from './services/table.service';
import { TableHeaderComponent } from './table-header/table-header.component';
import { TableRowComponent } from './table-row/table-row.component';

@Component({
    selector: '[m-table]',
    templateUrl: 'table.component.html',
    styleUrls: ['table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [TableService]
})
export class TableComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() public multiSelectEnabled: boolean = false;

    @ContentChildren(TableRowComponent, { read: ElementRef, descendants: true }) public rows: QueryList<ElementRef>;
    @ContentChild(TableHeaderComponent) public headerRow: TableHeaderComponent;

    private _rowSubscription: Subscription = new Subscription();
    private _headerCellSubscription: Subscription = new Subscription();

    constructor(
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private _tableService: TableService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    public ngAfterContentInit(): void {
        this._initContentState();
    }

    public ngOnDestroy(): void {
        this._rowSubscription.unsubscribe();
        this._headerCellSubscription.unsubscribe();
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this._tableService.setMultiSelectStatus(this.multiSelectEnabled);
        this._renderer.setAttribute(this._elementRef.nativeElement, 'role', 'grid');
        this._renderer.addClass(this._elementRef.nativeElement, 'm-table');
    }

    /**
     * @description
     * Initializes the component content state.
     */
    private _initContentState(): void {
        this._rowSubscription = this.rows.changes.subscribe(() => this._updateTableRowAriaIndices());
        this._updateTableRowAriaIndices();

        if (this.headerRow) {
            this._headerCellSubscription = this.headerRow.headerCells.changes.subscribe(() => this._updateTableColAriaIndices());
            this._updateTableColAriaIndices();
        }
    }

    /**
     * @description
     * Updates the aria row indices of the rows in the table.
     */
    private _updateTableRowAriaIndices(): void {
        this.rows.forEach((row, index) => this._renderer.setAttribute(row.nativeElement, 'aria-rowindex', `${index + 1}`));
        this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-rowcount', `${this.rows.length + 1}`);
    }

    /**
     * @description
     * Updates the aria col count of the table based on the col count
     * of the table header.
     */
    private _updateTableColAriaIndices(): void {
        this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-colcount', `${this.headerRow.headerCells.length}`);
    }
}
