import {
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector,
    ViewContainerRef
} from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { DialogButton, DialogComponent } from '../dialog/dialog.component';
import { IModalComponent } from '../modal/IModal';
import { ComponentType, ModalComponent } from '../modal/modal.component';

export interface ModalOptions {
    maxDescriptionWidth?: number;
}

/** Allows for the creation of a modal host component that will overlay the screen
 *  that can then have other components hosted within it. Dialogs created by the service
 *  all follow the same style and structure, but the showCustomModal() method allows any
 *  component to be hosted within the modal and overlaid on the sreen.
 */
@Injectable()
export class ModalService {
    /** Handle for the modal. */
    private _modalRef: ComponentRef<ModalComponent>;

    /** Handle for component instantiated within modal. */
    private _modalRefContent: ComponentRef<IModalComponent<any>>;

    /** Handle for dialog component */
    private _dialogRef: ComponentRef<DialogComponent>;

    /** View container of the root app which the modal will be placed next to. */
    private _appViewContainerRef: ViewContainerRef;

    /** Subscription to open modal's close event. */
    private _closeEventSubscription: Subscription;

    /** Subscription to open modal's response value. */
    private _responseValueSubscription: Subscription;

    /** Cached overflow style for <body> element. */
    private _cachedBodyOverflowStyle: string;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector
    ) { }

    /** Registers the root app view container with the modal. So the modal can be inserted at the same DOM level.
     *  Required before using the modal service.
    */
    public registerAppViewContainer(appViewContainerRef: ViewContainerRef) {
        this._appViewContainerRef = appViewContainerRef;
    }

    /** Creates a modal then projects a component into the modal.
     *  The custom component must implement IModalComponent
     *
     *  T = type of component to be created
     *  K = expected response type from component being hosted in modal
     */
    public showCustomModal<T extends IModalComponent<K>, K>(projectedComp: ComponentType<T>, ...data: any[]): Subject<K> {
        if (this._modalRef || this._modalRefContent || this._dialogRef) {
            throw new Error('Cannot create more than one modal or dialog at a time!');
        }

        // create modal component (essentially the overlay and the hosting box with X icon)
        this._modalRef = this.createModal(ModalComponent);
        // create component to be hosted within the modal component
        this._modalRefContent = this._modalRef.instance._createModal(projectedComp);

        // pass data (if any) to hosted component
        if (data) {
            this._modalRefContent.instance.data = data;
        }

        // create modal response
        const modalResponse = new Subject<K>();

        // set up backdrop subscription
        this._closeEventSubscription = this._modalRef.instance._closeEventFired
            .asObservable()
            .distinctUntilChanged()
            .subscribe(closeEvent => {
                if (closeEvent) {
                    this.hideModal();
                }
            });

        // subscribe to the response value of the modal
        this._responseValueSubscription = this._modalRefContent.instance.response.subscribe(responseFromModal => {
            modalResponse.next(responseFromModal);
        });

        // prevent the document from scrolling behind the modal
        this._cachedBodyOverflowStyle = document.body.style.overflow;
        document.body.style.overflow = 'hidden';

        return modalResponse;
    }

    /** Creates an instance of the modal component that will host some other component.
     *  This creates the overlay and the floating 'box' that is displayed.
     */
    public createModal<T>(modalCompType: ComponentType<T>): ComponentRef<T> {
        // check if there is a registered application ViewContainerRef
        if (!this._appViewContainerRef) {
            throw new Error('Must first set application view container before using Modal Service!'
                + '\nTry calling registerAppViewContainer() method.');
        }

        // create component and insert it next to the application ViewContainerREf
        const modalType: ComponentType<T> = modalCompType;
        const modalFactory = this._componentFactoryResolver.resolveComponentFactory(modalType);
        return this._appViewContainerRef.createComponent(modalFactory, 0, this._injector);
    }

    /** Destroys the modal instance and cleans up subscriptions. */
    public hideModal() {
        // destroy modal references
        if (this._modalRefContent) {
            this._modalRefContent.destroy();
            this._modalRefContent = null;
        }
        if (this._modalRef) {
            this._modalRef.destroy();
            this._modalRef = null;
        }
        if (this._dialogRef) {
            this._dialogRef.destroy();
            this._dialogRef = null;
        }

        // clean up subscriptions
        this._dispose();
    }

    /** Creates and returns an instance of a dialog to be customized and hosted within the modal. */
    public showDialog(title: string, description: string, buttons: DialogButton[], options: ModalOptions = {}): Subject<string> {
        if (this._modalRef || this._modalRefContent || this._dialogRef) {
            throw new Error('Cannot create more than one modal or dialog at a time!');
        }

        // create a modal component that will host the dialog
        this._modalRef = this.createModal(ModalComponent);
        // create the dialog component that will be hosted within the modal
        this._dialogRef = this._modalRef.instance._createModal(DialogComponent);

        // customize dialog with text and buttons
        const dialog = this._dialogRef.instance;

        dialog.setTitle(title);
        dialog.setDescription(description);

        if (options.maxDescriptionWidth) {
            dialog.setMaxDescriptionWidth(options.maxDescriptionWidth);
        }

        buttons.forEach(b => {
            dialog.addButton(b);
        });

        // set up backdrop subscription
        this._closeEventSubscription = this._modalRef.instance._closeEventFired
            .asObservable()
            .distinctUntilChanged()
            .subscribe(closeEvent => {
                if (closeEvent) {
                    this.hideModal();
                }
            });

        // return dialog's response
        return this._dialogRef.instance.response;
    }

    /** Cleans up service subscriptions. */
    private _dispose() {
        if (this._closeEventSubscription) {
            this._closeEventSubscription.unsubscribe();
            this._closeEventSubscription = null;
        }
        if (this._responseValueSubscription) {
            this._responseValueSubscription.unsubscribe();
            this._responseValueSubscription = null;
        }

        // allow the document to scroll
        document.body.style.overflow = this._cachedBodyOverflowStyle;
    }
}
