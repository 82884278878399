import { Injectable } from '@angular/core';
import { CompositeSegment, Entity, ENTITY_TYPES, EntitySegment, Segment, UtteranceEntity, EntityRole } from '@luis/entities';
import { Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs/operators';

export interface ICompoundEntity {
	parent: UtteranceEntity | null;
	children: UtteranceEntity[];
}

@Injectable()
export class SegmentsExtractionService {
	private static _precedenceMap = {
		[ENTITY_TYPES.COMPOSITE]: 3,
		[ENTITY_TYPES.SIMPLE]: 3,
		[ENTITY_TYPES.CLOSED]: 2,
		[ENTITY_TYPES.HIERARCHICAL]: 2,
		[ENTITY_TYPES.PATTERN_ANY]: 2,
		[ENTITY_TYPES.REGEX]: 2,
		[ENTITY_TYPES.DOMAIN]: 1,
		[ENTITY_TYPES.PREBUILT]: 0
	};

	/**
	 * @description
	 * Wraps the transformation algorithm with an observable interface.
	 *
	 * @param tokenizedText Utterance tokenized text array.
	 * @param labeledEntities Observable of utterance labeled entities.
	 * @param predictedEntities Observable of utterance predicted entities.
	 * @returns Observable of segments
	 */
	public transform(
		tokenizedText: string[],
		labeledEntities: Observable<UtteranceEntity[]>,
		predictedEntities: Observable<UtteranceEntity[]>
	): Observable<Segment[]> {
		return labeledEntities.pipe(
			combineLatest(predictedEntities),
			map(([streamedLabeledEntities, streamedPredictedEntities]) =>
				this._transform(tokenizedText, streamedLabeledEntities, streamedPredictedEntities)
			)
		);
	}

	/**
	 * @description
	 * Removes overlapping entities between labeled and non-machine learned entities. Machine learned entities are not displayed to the user
	 * instead they are only used for predictions.
	 *
	 * @param labeledEntities Utterance labeled entities.
	 * @param nonMachineLearnedEntities Array of non-machine learned entities
	 * @returns Array of labeleled and non-machine learned entities combined with no overlaps
	 */
	public resolveOverlappingEntities(labeledEntities: UtteranceEntity[], nonMachineLearnedEntities: UtteranceEntity[]): UtteranceEntity[] {
		const compositeEntities = labeledEntities.filter(({ type }) => type === ENTITY_TYPES.COMPOSITE);
		const nonCompositeEntities = labeledEntities.filter(({ type }) => type !== ENTITY_TYPES.COMPOSITE);

		const sortedLabeledEntities = this._sortByLength(nonCompositeEntities);
		let sortedNonMachineLearnedEntities = this._sortByLength(nonMachineLearnedEntities);
		sortedNonMachineLearnedEntities = this._sortByType(sortedNonMachineLearnedEntities);

		const resolvedLabeledEntities = this._removeOverlappingEntites(sortedLabeledEntities);
		const resolvedNonMachineLearnedEntities = this._removeOverlappingEntites(sortedNonMachineLearnedEntities);

		const resolvedEntities = this._removeOverlappingLabelsAndNonMachineLearnedEntities(
			resolvedLabeledEntities,
			resolvedNonMachineLearnedEntities
		);

		return [...compositeEntities, ...resolvedEntities];
	}

	/**
	 * @description
	 * Takes an array of tokenized text, labeled entities and predicted entities and returns an array of segments.
	 *
	 * @param tokenizedText Utterance tokenized text array.
	 * @param labeledEntities Utterance labeled entities.
	 * @param predictedEntities Utterance predicted entities.
	 *
	 * @returns Array of segments that gets fed to the UI
	 */
	private _transform(tokenizedText: string[], labeledEntities: UtteranceEntity[], predictedEntities: UtteranceEntity[]): Segment[] {
		const nonMachineLearnedEntities = predictedEntities.filter(({ type }) => !Entity.isMachineLearned(type));
		const machineLearnedEntities = predictedEntities.filter(({ type }) => Entity.isMachineLearned(type));

		const resolvedEntities = this.resolveOverlappingEntities(labeledEntities, nonMachineLearnedEntities);

		const segments = this._constructSegments(tokenizedText, resolvedEntities);

		return this._attachPredictions(segments, machineLearnedEntities);
	}

	/**
	 * @description
	 * Constructs CompoundEntity array of composite entities and their children and orphan entities.
	 * Then loops over this array extracting segments from each item; keeping track of the used tokens
	 * in tokenizedTextFlags boolean array.
	 *
	 * @param tokenizedText Utterance tokenized text array
	 * @param entities Utterance labelable entities
	 *
	 * @returns Array of segments that's then fed to the UI
	 */
	private _constructSegments(tokenizedText: string[], entities: UtteranceEntity[]): Segment[] {
		if (entities.length === 0) {
			return [new Segment(tokenizedText, 0, tokenizedText.length - 1)];
		}

		let segments: Segment[] = [];

		const tokenizedTextFlags: boolean[] = tokenizedText.map(() => false);

		const utteranceEntities = this._preproccessEntities(entities);

		// Extract composite segments first
		utteranceEntities
			.filter(({ parent }) => parent !== null)
			.forEach(({ parent, children }) => {
				const startIndex = parent.startTokenIndex;
				const endIndex = parent.endTokenIndex;

				const compositeTokenizedText = tokenizedText.slice(startIndex, endIndex + 1);

				const childrenSegments = this._getChildrenSegments(
					compositeTokenizedText,
					children,
					compositeTokenizedText.map(() => false),
					startIndex
				);

				const entity = new Entity(
					parent.id,
					parent.name,
					parent.type,
					0,
					parent.roleId ? [new EntityRole(parent.roleId, parent.role)] : []
				);

				segments.push(new CompositeSegment(compositeTokenizedText, startIndex, endIndex, entity, childrenSegments, parent.role));

				tokenizedTextFlags.fill(true, startIndex, endIndex + 1);
			});

		// Extract non-composite segments afterwards
		utteranceEntities
			.filter(({ parent }) => parent === null)
			.forEach(({ children }) => {
				const childrenSegments = this._getChildrenSegments(tokenizedText, children, tokenizedTextFlags);
				segments = segments.concat(childrenSegments);
			});

		return this._sortSegments(segments);
	}

	/**
	 * @description
	 * Transforms entities into an array of CompoundEntities.
	 * Composite entities get associated with their children as parent and children
	 * in the CompoundEntities array. Other entities (orphan ones) are added as children with
	 * the parent set to null.
	 *
	 * @param utteranceEntities Array of utterance labeled entities
	 *
	 * @returns Array of CompoundEntity associating composite entities with their children entities
	 */
	private _preproccessEntities(utteranceEntities: UtteranceEntity[]): ICompoundEntity[] {
		const nonCompositeEntities = utteranceEntities.filter(utteranceEntity => utteranceEntity.type !== ENTITY_TYPES.COMPOSITE);
		const compositeEntities = utteranceEntities.filter(utteranceEntity => utteranceEntity.type === ENTITY_TYPES.COMPOSITE);

		const compositeEntitiesObj = compositeEntities.map(entity => {
			const compositeEntityChildren = this._getCompositeEntityChildren(entity, nonCompositeEntities);

			compositeEntityChildren.forEach(child => {
				const index = nonCompositeEntities.indexOf(child);
				if (index > -1) {
					nonCompositeEntities.splice(index, 1);
				}
			});

			return { parent: entity, children: compositeEntityChildren };
		});

		return [...compositeEntitiesObj, { parent: null, children: nonCompositeEntities }];
	}

	/**
	 * @description
	 * Filters out overlapping entities - longer entities eclipse shorter ones.
	 *
	 * @param utteranceEntities Array of utterance entity sorted by the token span size
	 * @returns Array of utterance entities with no overlaps
	 */
	private _removeOverlappingEntites(utteranceEntities: UtteranceEntity[]): UtteranceEntity[] {
		const utteranceEntitiesCopy = utteranceEntities.slice();

		for (let i = 0; i < utteranceEntitiesCopy.length; i = i + 1) {
			const longerEntity = utteranceEntitiesCopy[i];
			for (let j = i + 1; j < utteranceEntitiesCopy.length; j = j + 1) {
				const shorterEntity = utteranceEntitiesCopy[j];
				if (this._doEntitiesOverlap(longerEntity, shorterEntity)) {
					utteranceEntitiesCopy.splice(j, 1);
					j = j - 1;
				}
			}
		}

		return utteranceEntitiesCopy;
	}

	/**
	 * @description
	 * Remove overlaps between labled and non-machine learned entities.
	 *
	 * @param labeledEntities Array of utterance labeled entities.
	 * @param nonMachineLearnedEntities Array of non-machine learned entities
	 *
	 * @returns Utterance entities with no overlaps
	 */
	private _removeOverlappingLabelsAndNonMachineLearnedEntities(
		labeledEntities: UtteranceEntity[],
		nonMachineLearnedEntities: UtteranceEntity[]
	): UtteranceEntity[] {
		const nonMachineLearnedEntitiesCopy = nonMachineLearnedEntities.slice();
		for (const labeledEntity of labeledEntities) {
			for (let j = nonMachineLearnedEntitiesCopy.length - 1; j >= 0; j = j - 1) {
				const predictedEntity = nonMachineLearnedEntitiesCopy[j];
				if (this._doEntitiesOverlap(labeledEntity, predictedEntity)) {
					nonMachineLearnedEntitiesCopy.splice(j, 1);
				}
			}
		}

		return [...labeledEntities, ...nonMachineLearnedEntitiesCopy];
	}

	/**
	 * @description
	 * Checks if the two labeled entities overlap or not.
	 *
	 * @param a The first entity.
	 * @param b The second entity.
	 * @returns True if the entities overlap and false otherwise.
	 */
	private _doEntitiesOverlap(a: UtteranceEntity, b: UtteranceEntity): boolean {
		if (a.startTokenIndex <= b.startTokenIndex && a.endTokenIndex >= b.startTokenIndex) {
			return true;
		}
		if (a.startTokenIndex >= b.startTokenIndex && a.startTokenIndex <= b.endTokenIndex) {
			return true;
		}

		return false;
	}

	/**
	 * @description
	 * Sorts Utterance array by the length of the entity - longer entities get added first
	 * and then shorter ones.
	 *
	 * @param utteranceEntities Array of utterance entities
	 * @returns Sorted utterance entities
	 */
	private _sortByLength(utteranceEntities: UtteranceEntity[]): UtteranceEntity[] {
		return utteranceEntities
			.slice()
			.sort((l, r) => (l.endTokenIndex - l.startTokenIndex < r.endTokenIndex - r.startTokenIndex ? 1 : -1));
	}

	/**
	 * @description
	 * Sorts Utterance array by the type of the entity with respect to the precedence map.
	 *
	 * @param utteranceEntities Array of utterance entities
	 * @returns Sorted utterance entities
	 */
	private _sortByType(utteranceEntities: UtteranceEntity[]): UtteranceEntity[] {
		return utteranceEntities
			.slice()
			.sort((l, r) =>
				SegmentsExtractionService._precedenceMap[l.type] <= SegmentsExtractionService._precedenceMap[r.type] ? 1 : -1
			);
	}

	/**
	 * @description
	 * Sorts the segments array by the starting index of each segment.
	 *
	 * @param segments Unordered array of segments
	 *
	 * @returns Sorted array segments
	 */
	private _sortSegments(segments: Segment[]): Segment[] {
		return segments.slice().sort((l, r) => (l.start > r.start ? 1 : -1));
	}

	/**
	 * @description
	 * Determines whether the given segment contains the given entity or not
	 *
	 * @param segment The examined segment
	 * @param entity
	 *
	 * @returns True if the segment given contains the entity
	 */
	private _segmentContainsEntity(segment: Segment, entity: UtteranceEntity): boolean {
		return (
			(segment.start <= entity.startTokenIndex && segment.end >= entity.endTokenIndex) ||
			(segment.start >= entity.startTokenIndex && segment.start <= entity.endTokenIndex)
		);
	}

	/**
	 * @description
	 * It returns all the entity children in the parent composite segment.
	 *
	 * @param parentEntity The parent composite entity
	 * @param labeledEntities All the other labeled non-composite entities
	 *
	 * @returns The composite entity children
	 */
	private _getCompositeEntityChildren(parentEntity: UtteranceEntity, labeledEntities: UtteranceEntity[]): UtteranceEntity[] {
		return labeledEntities.filter(
			entity => parentEntity.startTokenIndex <= entity.startTokenIndex && parentEntity.endTokenIndex >= entity.endTokenIndex
		);
	}

	/**
	 * @description
	 * The main algorithm that constructs the segments out of the given tokenizedText and
	 * labeled entities.
	 *
	 * @param tokenizedText Array of text tokens
	 * @param utteranceEntities Array of labeled entities
	 * @param labeledEntitiesFlags Array of flags which determines which token indices have been taken
	 * by an entity
	 * @param padding A number which indicates the start index of a composite entity. Gets set
	 * to zero if entities don't have a parent
	 *
	 * @returns Array of segments
	 */
	private _getChildrenSegments(
		tokenizedText: string[],
		utteranceEntities: UtteranceEntity[],
		labeledEntitiesFlags: boolean[] = tokenizedText.map(() => false),
		padding: number = 0
	): Segment[] {
		const segments: Segment[] = [];

		utteranceEntities.forEach(utteranceEntity => {
			const startIndex: number = utteranceEntity.startTokenIndex - padding;
			const endIndex: number = utteranceEntity.endTokenIndex - padding;

			const labeledText: string[] = tokenizedText.slice(startIndex, endIndex + 1);

			const entity: Entity = new Entity(
				utteranceEntity.id,
				utteranceEntity.name,
				utteranceEntity.type,
				0,
				utteranceEntity.roleId ? [new EntityRole(utteranceEntity.roleId, utteranceEntity.role)] : []
			);

			segments.push(new EntitySegment(labeledText, startIndex + padding, endIndex + padding, entity, [], utteranceEntity.role));

			labeledEntitiesFlags.fill(true, startIndex, endIndex + 1);
		});

		let currentPlainSegment: { text: string; index: number }[] = [];

		labeledEntitiesFlags.forEach((flag, index) => {
			if (flag) {
				if (currentPlainSegment.length !== 0) {
					const newSegment = new Segment(
						currentPlainSegment.map(({ text }) => text),
						currentPlainSegment[0].index + padding,
						currentPlainSegment[currentPlainSegment.length - 1].index + padding
					);
					segments.push(newSegment);

					currentPlainSegment = [];
				}
			} else {
				currentPlainSegment.push({ text: tokenizedText[index], index });
			}
		});

		if (currentPlainSegment.length !== 0) {
			segments.push(
				new Segment(
					currentPlainSegment.map(({ text }) => text),
					currentPlainSegment[0].index + padding,
					currentPlainSegment[currentPlainSegment.length - 1].index + padding
				)
			);
		}

		return this._sortSegments(segments);
	}

	/**
	 * @description
	 * Attaches predictions to their corresponding segments.
	 *
	 * @param segments An Array of segments
	 * @param machineLearnedEntities Array of machine learned entities used only in predictions
	 *
	 * @returns Array of segments with the predictions attached
	 */
	private _attachPredictions(segments: Segment[], machineLearnedEntities: UtteranceEntity[]): Segment[] {
		segments.forEach(segment => {
			if (segment instanceof CompositeSegment) {
				segment.children = this._attachPredictions(segment.children, machineLearnedEntities);
			} else {
				machineLearnedEntities
					.filter(predictedEntity => this._segmentContainsEntity(segment, predictedEntity))
					.forEach(predictedEntity => segment.predictions.push(predictedEntity));
			}
		});

		return segments;
	}
}
