import { ICloneable } from '@luis/core';

/**
 * @description
 * Represents an event message received from LUIS's REDIS when an app
 * collaborator causes a change event.
 *
 * @param method The HTTP method called.
 * @param url The url that was called.
 */
export class CollaboratorEvent implements ICloneable {
    public method: string;
    public url: string;

    constructor(method: string, url: string) {
        this.method = method;
        this.url = url;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): CollaboratorEvent {
        return new CollaboratorEvent(apiObject.method, apiObject.resource);
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): CollaboratorEvent {
        return new CollaboratorEvent(this.method.slice(), this.url.slice());
    }

    /**
     * @method
     * @description
     * Checks if the event received should incur change in the
     * current application state or not.
     *
     * @returns True if it should mutate the application
     * state and false otherwise.
     */
    public isMutative(): boolean {
        return this.method !== 'get';
    }

    /**
     * @method
     * @description
     * Checks if the change was in the intents.
     *
     * @returns True if validated and false otherwise.
     */
    public isIntentChange(): boolean {
        return this.url.search(/intents/i) !== -1;
    }

    /**
     * @method
     * @description
     * Checks if the change was in the entities.
     *
     * @returns True if validated and false otherwise.
     */
    public isEntityChange(): boolean {
        return this.url.search(/entities|compositeentities|prebuilts|closedlists|hierarchicalentities/i) !== -1;
    }

    /**
     * @method
     * @description
     * Checks if the change was in the features.
     *
     * @returns True if validated and false otherwise.
     */
    public isFeatureChange(): boolean {
        return this.url.search(/phraselists/i) !== -1;
    }

    /**
     * @method
     * @description
     * Checks if the change was in the versions.
     *
     * @returns True if validated and false otherwise.
     */
    public isVersionChange(): boolean {
        return this.url.search(/apps\/[^\/]+\/versions\/[^\/]+\/(clone|import|)$/i) !== -1;
    }

    /**
     * @method
     * @description
     * Checks if the change was in the apps.
     *
     * @returns True if validated and false otherwise.
     */
    public isAppChange(): boolean {
        return this.url.search(/apps\/[^\/]+(\/|)$/i) !== -1;
    }

    /**
     * @method
     * @description
     * Checks if change was a training request.
     *
     * @returns True if validated and false otherwise.
     */
    public isTrain(): boolean {
        return this.url.search(/train/i) !== -1;
    }

    /**
     * @method
     * @description
     * Checks if change was a publish request.
     *
     * @returns True if validated and false otherwise.
     */
    public isPublish(): boolean {
        return this.url.search(/publish/i) !== -1;
    }
}
