import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ISearchPipeProps, SEARCH_TYPES } from '@luis/core';
import {
    Entity,
    ENTITY_SERVICE_TOKEN,
    EntityHelpers,
    IEntityService,
    ItemsContainerComponent
} from '@luis/entities';
import {
    DOWN_ARROW,
    ENTER,
    LEFT_ARROW,
    RIGHT_ARROW,
    TAB,
    UP_ARROW
} from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';

/**
 * @description
 * The entity dropdown that appears when the user types in a pattern
 * in the pattern input field, and types an entity opener character,
 * currently it is the '{' character. Displays the entities in the application,
 * gives the user the ability to create entities on the fly as well.
 */
@Component({
    selector: 'pattern-entity-dropdown',
    templateUrl: 'entity-dropdown.component.html',
    styleUrls: ['entity-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityDropdownComponent implements OnInit {
    @Input() public searchTerm: string = '';
    @Input() public fieldRef: HTMLInputElement;
    @Output() public newEntityClick: EventEmitter<[string, string]> = new EventEmitter<[string, string]>();
    @Output() public existingEntityClicked: EventEmitter<Entity> = new EventEmitter<Entity>();
    @Output() public requestClose: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(ItemsContainerComponent) public container: ItemsContainerComponent;

    public entities: Observable<Entity[]>;
    public hasBeenNavigated: boolean;
    public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public searchProps: Observable<ISearchPipeProps> = Observable.of({ caseSensitive: false, searchType: SEARCH_TYPES.STARTS_WITH });
    public searchTypes: typeof SEARCH_TYPES = SEARCH_TYPES;

    constructor(
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Emits an event when a new entity in the dropdown is requested to be added.
     */
    public onNewEntityClick(): void {
        const query: string = this.querySubject.getValue().trim();
        this.newEntityClick.emit([query, undefined]);
    }

    /**
     * @description
     * Handles the keydown for accessbility for this component.
     */
    @HostListener('keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent): void {
        let stopEvent: boolean = true;

        switch (event.keyCode) {
            case DOWN_ARROW:
                if (!this.hasBeenNavigated) {
                    this.container.focus();
                }
                this.hasBeenNavigated = true;
                break;
            case UP_ARROW:
            case TAB:
                this.hasBeenNavigated = true;
                break;
            case ENTER:
                if (!this.hasBeenNavigated) {
                    this.container.focus();
                }
                break;
            case LEFT_ARROW:
            case RIGHT_ARROW:
                this.fieldRef.focus();
                break;
            default:
                this.fieldRef.focus();
                stopEvent = false;
        }

        if (stopEvent) {
            event.stopPropagation();
        }
    }

    /**
     * @description
     * Initializes the dropdown component.
     */
    private _initState(): void {
        this.querySubject.next(this.searchTerm ? this.searchTerm : '');

        this.entities = this._entityService.get()
            .map(entities => entities.sort((a, b) => a.name.length > b.name.length ? 1 : -1))
            .map(entities => EntityHelpers.expandChildren(entities));
    }
}
