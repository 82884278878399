import { IApiExportable, ICloneable } from '@luis/core';

/**
 * @description
 * Represents an utterance entity. An utterance entity is returned
 * with the indeces of its appearance in the utterance.
 *
 * @param string The entity id.
 * @param string The entity name.
 * @param number The entity type.
 * @param string The entity role.
 * @param startTokenIndex The entity start token index in the utterance.
 * @param endTokenIndex The entity end token index in the utterance.
 * @param startCharIndex The entity start character index in the utterance.
 * @param endCharIndex The entity end character index in the utterance.
 */
export class UtteranceEntity implements ICloneable, IApiExportable {
	constructor(
		public id: string = '',
		public name: string = '',
		public type: number = null,
		public role: string = '',
		public startTokenIndex: number = null,
		public endTokenIndex: number = null,
		public startCharIndex: number = null,
		public endCharIndex: number = null,
		public roleId: string = null
	) {}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): UtteranceEntity {
		return new UtteranceEntity(
			apiObject.id,
			apiObject.entityName,
			apiObject.entityType,
			apiObject.role,
			apiObject.startTokenIndex,
			apiObject.endTokenIndex,
			null,
			null,
			apiObject.roleId
		);
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from the endpoint.
	 *
	 * @param apiObject The object received by the endpoint api.
	 * @returns A new object of this resource.
	 */
	public static importFromEndpointV3(apiObject: any): UtteranceEntity {
		return new UtteranceEntity(
			'',
			apiObject.type,
			apiObject.modelTypeId,
			apiObject.role,
			undefined,
			undefined,
			apiObject.startIndex,
			<number>apiObject.startIndex + <number>apiObject.length
		);
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): UtteranceEntity {
		return new UtteranceEntity(
			this.id ? this.id.slice() : this.id,
			this.name.slice(),
			this.type,
			this.role ? this.role.slice() : '',
			this.startTokenIndex,
			this.endTokenIndex,
			this.startCharIndex,
			this.endCharIndex,
			this.roleId ? this.roleId.slice() : ''
		);
	}

	/**
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			entityName: this.name,
			startCharIndex: this.startCharIndex,
			endCharIndex: this.endCharIndex,
			role: this.role
		};
	}

	/**
	 * @description
	 * Converts the character indeces to token indeces.
	 *
	 * @param charStart The starting character index of the entity
	 * @param charEnd The ending character index of the entity
	 * @param tokenziedText The tokenized utterance text.
	 */
	public charToTokenIndeces(charStart: number, charEnd: number, tokenziedText: string[]): void {
		let startLength: number = 0;
		let endLength: number = -2;

		for (let i: number = 0; i < tokenziedText.length; i = i + 1) {
			if (charStart - startLength === 0) {
				this.startTokenIndex = i;
				break;
			} else {
				startLength += tokenziedText[i].length + 1;
			}
		}

		for (let i: number = 0; i < tokenziedText.length; i = i + 1) {
			endLength += tokenziedText[i].length + 1;

			if (charEnd - endLength === 0) {
				this.endTokenIndex = i;
				break;
			}
		}
	}

	/**
	 * @description
	 * Sets current entity character indeces converted from token indeces. Requires original utternace
	 * text and tokenized text for calculations.
	 *
	 * @param text The original utterance text the entity was labeled in.
	 * @param tokenizedText The original utterance tokenized text.
	 */
	public tokenToCharIndeces(text: string, tokenizedText: string[]): void {
		const tokenCharacterMap: Map<number, number> = this._getCharIndexForTokens(text, tokenizedText);

		if (tokenCharacterMap.get(this.startTokenIndex) != null && tokenCharacterMap.get(this.endTokenIndex) != null) {
			this.startCharIndex = tokenCharacterMap.get(this.startTokenIndex);
			this.endCharIndex = tokenCharacterMap.get(this.endTokenIndex) + tokenizedText[this.endTokenIndex].length - 1;
		}
	}

	/**
	 * @description
	 * Gets the character starting index for each token of the text.
	 *
	 * @param text The original text.
	 * @param tokenizedText The tokenized text.
	 */
	private _getCharIndexForTokens(text: string, tokenizedText: string[]): Map<number, number> {
		const output: Map<number, number> = new Map<number, number>();
		tokenizedText.reduce((lastIndex, t, i) => {
			output.set(i, text.indexOf(t, lastIndex));

			return lastIndex + t.length + (text[lastIndex + t.length] === ' ' ? 1 : 0);
		}, 0);

		return output;
	}
}
