import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { delayWhen, retryWhen } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
	private readonly _retriableErrors: Set<number> = new Set([429]);
	private readonly _delay: number = 300;
	private readonly _maxRetries: number = 5;

	/**
	 * @description
	 * Retries the call with a delay if the error thrown had a status of that
	 * of one of the retriable errors.
	 *
	 * @param req The request object receive from the pipline.
	 * @param next The handler of the next interceptor in the pipeline.
	 */
	public intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
		return next.handle(req).pipe(retryWhen(this._handleRetriableErrors(this._retriableErrors, this._maxRetries, this._delay)));
	}

	/**
	 * @description
	 * Returns a function that does exponential back-off while retrying for retriable
	 * error status codes
	 *
	 * @param times The number of times to retry
	 * @param delayIncrement The increment back-off step size
	 * @returns A method that can be passed to retryWhen of an observable
	 */
	private _handleRetriableErrors(
		retriableErrors: Set<number>,
		maxRetries: number,
		delay: number
	): (errors: Observable<HttpErrorResponse>) => Observable<HttpErrorResponse> {
		let i: number = 0;

		return (errors: Observable<HttpErrorResponse>) =>
			errors.pipe(
				delayWhen(error => {
					i = i + 1;

					if (retriableErrors.has(error.status) && i < maxRetries) {
						return timer(delay);
					}

					return throwError(error);
				})
			);
	}
}
