/**
 * @description
 * An enumeration of the supported types of search
 * in a dataset.
 */
export enum SEARCH_TYPES {
    PREFIX,
    STARTS_WITH
}

/**
 * @description
 * Represents the search options allowable in the dataset
 * search pipe.
 */
export interface ISearchPipeProps {
    caseSensitive: boolean;

    searchType: SEARCH_TYPES;
}

/**
 * @description
 * A deafult value for the search props.
 */
export const DEFAULT_SEARCH_PROPS: ISearchPipeProps = { caseSensitive: false, searchType: SEARCH_TYPES.PREFIX };
