import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { Intent } from '../../models/intent.model';
import { UtteranceIntent } from '../../models/utterance-intent.model';

/**
 * @description
 * Represents an intent dropdown for an utterance row. Displays the utterance
 * predicted intents' scores. Responsible for handling when the user changes an
 * intent from the dropdown.
 */
@Component({
    selector: 'intent-dropdown',
    templateUrl: './intent-dropdown.component.html',
    styleUrls: ['./intent-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntentDropdownComponent implements OnInit, OnChanges {
    @Input() public appIntents: Observable<Intent[]>;
    @Input() public predictedIntents: UtteranceIntent[] = [];
    @Input() public defaultIntentName: Observable<string> = Observable.of('');
    @Input() public showScores: boolean = true;
    @Output() public selectedIntent: EventEmitter<Intent> = new EventEmitter<Intent>();

    public displayedIntents: Observable<UtteranceIntent[]>;
    public isErroneous: Observable<boolean>;

    private rerenderSubject: BehaviorSubject<void> = new BehaviorSubject(null);

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.predictedIntents && changes.predictedIntents.previousValue !== changes.predictedIntents.currentValue) {
            this.rerenderSubject.next(null);
        }
    }

    /**
     * @method
     * @description
     * Notifies parent that the intent dropdown value has been
     * changed, that is, the utterance is going to be reassigned to
     * another intent.
     *
     * @param intentName The intent name that the utterance is going to be reassigned to.
     */
    public onIntentChange(intentId: string): void {
        this.appIntents
            .first()
            .map(intents => intents.find(i => i.id === intentId))
            .map(intent => this.selectedIntent.emit(intent))
            .subscribe(null);
    }

    /**
     * @method
     * @description
     * Initializes the component state.
     */
    private _initState(): void {
        this.isErroneous = Observable.combineLatest(this.defaultIntentName, this.rerenderSubject)
            .filter(([name]) => name !== '')
            .map(([name]) => this._isErroneous(name));

        this.displayedIntents = Observable.combineLatest(
            this.appIntents,
            this.defaultIntentName,
            this.rerenderSubject,
            (appIntents, defaultName) => {
                return appIntents
                    .map(
                        appIntent =>
                            this.predictedIntents.find(i => appIntent.id === i.id) ||
                            new UtteranceIntent(appIntent.id, appIntent.name, null)
                    )
                    .sort((l, r) => {
                        if (l.name === defaultName) {
                            return -1;
                        }
                        if (r.name === defaultName) {
                            return 1;
                        }
                        if (l.score === null || r.score === null) {
                            return l.name.toLocaleLowerCase() > r.name.toLocaleLowerCase() ? 1 : -1;
                        }

                        return l.score < r.score ? 1 : -1;
                    });
            }
        );
    }

    /**
     * @description
     * Checks if the current intent name is erroneous with respect
     * to the top prediceted intent.
     *
     * @returns True if error in intent prediction occurs
     * and false otherwise.
     */
    private _isErroneous(name: string): boolean {
        if (!this.predictedIntents.length) {
            return false;
        }
        if (this.predictedIntents[0].score === -1) {
            return false;
        }
        if (name === this.predictedIntents[0].name) {
            return false;
        }

        const matchedPredictedIntent: UtteranceIntent = this.predictedIntents.find(pI => pI.name === name);

        if (matchedPredictedIntent === undefined) {
            return false;
        }
        if (matchedPredictedIntent.score === -1) {
            return false;
        }

        return true;
    }
}
