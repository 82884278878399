import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { EMPTY_SORT_PROPS, ISortPipeProps } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IDatasetDto } from '../../../interfaces/IDatasetDto';
import { DATASET_OPERATIONS, IDatasetOperation } from '../../../interfaces/IDatasetOperation';
import { DatasetMetadata } from '../../../models/dataset-metadata.model';

/**
 * @description
 * Represents a batch dataset table. Lists the datasets in an application. Provides
 * tools for manipulating the datasets in the table.
 */
@Component({
    selector: 'dataset-table',
    templateUrl: './dataset-table.component.html',
    styleUrls: ['./dataset-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetTableComponent implements OnInit {
    @Input() public datasetDtos: Observable<IDatasetDto[]>;

    @Output() public applyOperation: EventEmitter<IDatasetOperation> = new EventEmitter<IDatasetOperation>();

    public shareableDtos: Observable<Map<string, IDatasetDto>>;
    public datasets: Observable<DatasetMetadata[]>;
    public datasetOps: any = DATASET_OPERATIONS;
    public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>(EMPTY_SORT_PROPS);

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Notifies parent that an operation was clicked to apply to the given app.
     *
     * @param dataset The dataset to apply the op on.
     * @param operation The operation to run. Should be one of the allowed operations
     * in the DATASET_OPERATIONS enum.
     */
    public onOperationClick(dataset: DatasetMetadata, operation: number): void {
        this.applyOperation.emit({
            dataset: dataset,
            operation: operation
        });
    }

    /**
     * @description
     * Initialize the component.
     */
    private _initState(): void {
        this.shareableDtos = this.datasetDtos
            .map(dtos => dtos.reduce((acc, dto) => acc.set(dto.dataset.id, dto), new Map<string, IDatasetDto>()))
            .shareReplay();

        this.datasets = this.datasetDtos.map(dtos => dtos.map(dto => dto.dataset));
    }
}
