import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { DatasetResultsGraphingService } from '@luis/ui';
import { ItemTableService, Utterance, VIEW_OPTIONS } from '@luis/utterances';
import { Observable } from 'rxjs/Rx';
import { IDatasetDto } from '../../../../interfaces/IDatasetDto';

@Component({
	selector: 'result-utterances',
	templateUrl: 'result-utterances.component.html',
	styleUrls: ['result-utterances.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ItemTableService]
})
export class ResultUtterancesComponent implements OnInit {
	@Input() public datasetDto: IDatasetDto;

	public utterances: Observable<Utterance[]>;
	private readonly _keys: { E: number } = { E: 69 };

	constructor(
		private readonly _datasetGraphingService: DatasetResultsGraphingService,
		private readonly _itemTableService: ItemTableService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Switches between the view modes either entity or token
	 * view for the utterance table.
	 *
	 * @param event The keyboard event fired with the shortcut.
	 */
	@HostListener('window:keydown', ['$event'])
	public switchViewModes(event: KeyboardEvent): void {
		if (event.keyCode === this._keys.E && event.ctrlKey) {
			this._itemTableService
				.getViewMode()
				.first()
				.map(viewMode =>
					viewMode === VIEW_OPTIONS.ENTITY_LABEL_VIEW ? VIEW_OPTIONS.TOKEN_LABEL_VIEW : VIEW_OPTIONS.ENTITY_LABEL_VIEW
				)
				.subscribe(viewMode => this._itemTableService.setViewMode(viewMode));

			event.preventDefault();
			event.stopPropagation();
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.utterances = this._datasetGraphingService
			.get()
			.map(ids => this.datasetDto.scorer.resultItems.filter(item => ids.indexOf(item.utterance.id) !== -1))
			.map(items => items.map(i => i.utterance));
	}
}
