import { Pipe, PipeTransform } from '@angular/core';
import lodashFlatten from 'lodash.flatten';

@Pipe({
    name: 'append'
})
export class AppendPipe implements PipeTransform {
    public transform(res: any[], more: any[]): any {
        return res.concat(lodashFlatten(more));
    }
}

@Pipe({
    name: 'prepend'
})
export class PrependPipe implements PipeTransform {
    public transform(res: any[], more: any[]): any {
        return lodashFlatten(more).concat(res);
    }
}
