import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
	ElementRef,
	AfterContentInit
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { first, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { getMenuState, MENU_STATES } from '../../helpers/entity-dropdown.model';
import { EntityHelpers } from '../../helpers/entity.helpers';
import { ICompositeWrapMenuEvent } from '../../interfaces/ICompositeWrapMenuEvent';
import { ENTITY_DROPDOWN_OPERATIONS, IEntityDropdownOperationPayload } from '../../interfaces/IEntityDropdownOperationPayload';
import { IEntityMenuEvent } from '../../interfaces/IEntityMenuEvent';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../interfaces/IEntityService';
import { ClosedEntity } from '../../models/closed-entity.model';
import { Entity, ENTITY_TYPES } from '../../models/entity.model';
import { ParentEntity } from '../../models/parent-entity.model';
import { EntityWrapService } from '../../services/entity-wrap.service';
import { NewMenuItemComponent } from './items/new-menu-item/new-menu-item.component';

/**
 * @description
 * Represents the entity dropdown that displays when a token in a labelable utterance
 * segment is clicked. The contents of the menu is dependent on the context entity that
 * was provided to the component. The context entity is the entity label type
 * when the token was clicked.
 */
@Component({
	selector: 'entity-dropdown',
	templateUrl: 'entity-dropdown.component.html',
	styleUrls: ['entity-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class EntityDropdownComponent implements OnInit, OnDestroy, AfterContentInit {
	@Input() public height: number;
	@Input() public entityName: string;
	@Input() public roleName: string;
	@Input() public parentName: string;
	@Input() public parentRoleName: string;
	@Input() public predictions: string;
	@Input() public showRemoveLink: boolean = true;
	@Input() public initialSearchTerm: string = '';
	@Input() public menuClosed: Observable<void>;

	@Output() public onOperation: EventEmitter<IEntityDropdownOperationPayload> = new EventEmitter<IEntityDropdownOperationPayload>();
	@Output() public wrapEntityClicked: EventEmitter<ICompositeWrapMenuEvent> = new EventEmitter<ICompositeWrapMenuEvent>();

	public enableCompositeChildren: Observable<boolean>;
	public entities: Observable<Entity[]>;
	public entity: Observable<Entity>;
	public parent: Observable<ParentEntity>;
	public mainEntity: Observable<Entity>;
	public secondaryEntity: Observable<Entity>;
	public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public menuState: Observable<number>;
	public MENU_STATES: typeof MENU_STATES = MENU_STATES;
	public ENTITY_TYPE: typeof ENTITY_TYPES = ENTITY_TYPES;
	public ENTITY_DROPDOWN_OPERATIONS: typeof ENTITY_DROPDOWN_OPERATIONS = ENTITY_DROPDOWN_OPERATIONS;
	public showNewEntityItem: typeof NewMenuItemComponent.showNewEntityItem = NewMenuItemComponent.showNewEntityItem;
	public entitiesContainerMaxHeight$: BehaviorSubject<string> = new BehaviorSubject('unset');

	@ViewChild('mainMenu') public menu: MatMenu;
	@ViewChild('menuContent') public menuContent: ElementRef<HTMLDivElement>;

	private _menuClosedSubscription: Subscription = new Subscription();
	private _parentSubscription: Subscription = new Subscription();

	constructor(private _entityWrapService: EntityWrapService, @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService) {}

	/**
	 * @description
	 * Getter function that calculates the height and position of the menu dynamically based on the
	 * the segment's position.
	 */
	public get dimensions() {
		const isBelow = this.height > 300;
		return {
			height: (isBelow ? this.height : window.innerHeight - this.height) - 60,
			position: isBelow ? 'below' : 'above'
		};
	}

	public ngOnInit(): void {
		this._initState();
	}

	public ngAfterContentInit(): void {
		// Gets called everytime the menu is opened, since EntityDropdownComponent itself doesn't get destroyed
		// everytime it's hidden, we can't rely on Angular's life cycle methods.
		this.menu._onAnimationStart = () => {
			this._calculateEntitiesContainerMaxHeight();
		};
	}

	public ngOnDestroy(): void {
		this._menuClosedSubscription.unsubscribe();
		this._parentSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Checks if the status menu should be shown or not based
	 * on the label and the predictions given to the item.
	 *
	 * @param label The entity label for this token.
	 * @param predictions The entity predictions for this token.
	 * @returns True if the label and prediction don't match and
	 * vice versa.
	 */
	public isErrorVisible(label: string, predictions: string): boolean {
		const normalizedLabel: string = label ? label : '';
		const normalizedPredictions: string = predictions ? predictions : '';

		return normalizedLabel !== normalizedPredictions;
	}

	/**
	 * @description
	 * If a composite already exists it adds a role to it otherwise it requests wrapping composite.
	 */
	public handleCompositeEntityClick(event: IEntityMenuEvent): void {
		this._parentSubscription = this.parent.pipe(first()).subscribe(parent => {
			if (parent) {
				this.onOperation.emit({ operation: ENTITY_DROPDOWN_OPERATIONS.LABEL_COMPOSITE_WITH_ROLE, event });
			} else {
				this.onWrapEntityClicked({
					entity: <ParentEntity>event.entity,
					role: event.eventData.role,
					isNew: false,
					closeMenu: false
				});
			}
		});
	}

	/**
	 * @description
	 * Fires when the user clicks the enter key in the text field. Creates a new
	 * entity if no suggestions exist for that specific query, or picks the top
	 * matched entity if they do.
	 *
	 * @param query The current query in the text field.
	 */
	public onSearchEnter(): void {
		const query = this.querySubject.getValue();

		const [entity, role] = EntityHelpers.splitQuery(query);
		Observable.combineLatest(this.entities.pipe(first()), this.mainEntity.pipe(first())).subscribe(([entities, mainEntity]) => {
			if (mainEntity && mainEntity.name === entity.trim()) {
				return;
			}

			const entityObj: Entity = entities.find(e => e.name.trim().toLocaleLowerCase() === entity.trim().toLocaleLowerCase());

			if (entityObj) {
				this.onOperation.emit({ operation: ENTITY_DROPDOWN_OPERATIONS.EXISTING_ENTITY, event: { entity: entities[0] } });
			} else if (entity !== '') {
				this.onOperation.emit({
					operation: ENTITY_DROPDOWN_OPERATIONS.NEW_ENTITY,
					event: { entity: new Entity('', entity), eventData: role }
				});
			}
		});
	}

	/**
	 * @description
	 * Emits an event when prebuilt entity modal should appear.
	 */
	public onPrebuiltEntityClicked(): void {
		this.onOperation.emit({ operation: ENTITY_DROPDOWN_OPERATIONS.PREBUILT_ENTITY });
	}

	/**
	 * @description
	 * Emits an event when an entity in the dropdown is requested to be deleted.
	 */
	public onDeleteEntity(): void {
		// If the parent was defined and is of hierchical type, then removing a label should be removing the child
		// not the parent itself. If the parent was defined and of composite type, removing the label should remove the parent.
		// If the parent was not defined, removing the label removes the entity.
		this.parent
			.first()
			.flatMap(p => (p && p.type === ENTITY_TYPES.HIERARCHICAL ? this.secondaryEntity : this.mainEntity))
			.subscribe(e => this.onOperation.emit({ operation: ENTITY_DROPDOWN_OPERATIONS.DELETE_ENTITY, event: { entity: e } }));
	}

	/**
	 * @description
	 * Emits an event when an entity in the dropdown is requested to be edited (navigated to).
	 */
	public onEditEntity(): void {
		this.mainEntity
			.first()
			.subscribe(e => this.onOperation.emit({ operation: ENTITY_DROPDOWN_OPERATIONS.EDIT_ENTITY, event: { entity: e } }));
	}

	/**
	 * @description
	 * Notifies the parent that a composite entity was clicked
	 * to wrap children entities.
	 *
	 * @param entity The composite entity that was clicked.
	 */
	public onWrapEntityClicked(
		event: ICompositeWrapMenuEvent = { entity: null, isNew: false, closeMenu: false },
		keyboardEvent?: KeyboardEvent
	): void {
		if (keyboardEvent) {
			keyboardEvent.stopPropagation();
		}
		this.wrapEntityClicked.emit(event);
	}

	/**
	 * @description
	 * Emits an event when an entity is selected from the menu.
	 *
	 * @param keyboardEvent Native click event.
	 * @param entity The entity object that's selected.
	 */
	public onExisitngEntityClicked(keyboardEvent: KeyboardEvent, entity: Entity): void {
		keyboardEvent.stopPropagation();

		if (entity.type === ENTITY_TYPES.CLOSED) {
			return;
		}
		this.onOperation.emit({ operation: ENTITY_DROPDOWN_OPERATIONS.EXISTING_ENTITY, event: { entity } });
	}

	/**
	 * @description
	 * Closes off the entity wrapping mode.
	 */
	public onWrapEntityClosed(): void {
		this._entityWrapService.setWrapMode(false);
	}

	/**
	 * @description
	 * Initializes the dropdown component.
	 */
	private _initState(): void {
		this.querySubject.next(this.initialSearchTerm);

		this.entity = this._entityService
			.get()
			.map(entities => EntityHelpers.expandChildren(entities))
			.map(entities => entities.find(e => e.name === this.entityName));

		this.parent = this._entityService
			.get()
			.map(entities => entities.filter(e => e instanceof ParentEntity))
			.map(parents => this._findParent(<ParentEntity[]>parents));

		this.mainEntity = Observable.combineLatest(this.parent, this.entity).map(([parent, entity]) => parent || entity);

		this.secondaryEntity = Observable.combineLatest(this.parent, this.entity).map(([parent, child]) => (parent ? child : undefined));

		this.menuState = Observable.combineLatest(this.entity, this.parent, this._entityWrapService.getWrapMode()).map(
			([entity, parent, wrapMode]) => getMenuState(entity, parent, wrapMode)
		);

		this.entities = Observable.combineLatest(this._entityService.get(), this.entity, this.parent, this.menuState).map(
			([entities, mainEntity, parentEntity, menuState]) =>
				this._getEntitiesForContextEntity(mainEntity, parentEntity, entities, menuState === MENU_STATES.WRAP)
		);

		// This component doesn't get destroyed and recreated everytime the menu appears and disappears,
		// hence, the query is reset manually when the menu is closed.
		this._menuClosedSubscription = this.menuClosed.subscribe(() => {
			this.querySubject.next('');
		});

		this.enableCompositeChildren = Observable.combineLatest(this._entityWrapService.getIsCompositeChildren(), this.menuState).pipe(
			map(([isCompositeChildren, menuState]) => isCompositeChildren && menuState === MENU_STATES.COMPOSITE)
		);
	}

	/**
	 * @description
	 * Calculates the max height of the entities container, if the parent (menu content) scrolls,
	 * then disable scrolling the entities container.
	 */
	private _calculateEntitiesContainerMaxHeight(): void {
		if (this.menuContent) {
			const menuHasScrollBar = this.menuContent.nativeElement.clientHeight !== this.menuContent.nativeElement.scrollHeight;
			this.entitiesContainerMaxHeight$.next(menuHasScrollBar ? 'unset' : '300px');
		} else {
			requestAnimationFrame(() => {
				this._calculateEntitiesContainerMaxHeight();
			});
		}
	}

	/**
	 * @description
	 * Finds the parent entity of the given context entity. If the context parent entity
	 * was defined, then search parents for the name. Else, search for hierarchical entity
	 * child name in all parents' children.
	 *
	 * @param parentEntities The array of parent entities in this app.
	 * @returns The parent of the given context entity.
	 */
	private _findParent(parentEntities: ParentEntity[]): ParentEntity {
		return (
			parentEntities.find(p => p.name === this.parentName) ||
			parentEntities.find(p => p.getChildrenWithFullName().find(c => c.name === this.entityName) !== undefined)
		);
	}

	/**
	 * @description
	 * Gets the dropdown menu entities that should be visible based on the root context entity passed
	 * to the component.
	 *
	 * @param contextEntity The dropdown context entity.
	 * @param contextParentEntity The dropdown context parent entity if the context entity
	 *                                           was a child.
	 * @param entities A list of all application entities.
	 * @param wrapMode Boolean flag indicating whether the menu is wrap mode or not.
	 * @returns A list of filtered entities based on the context entity and parent entity.
	 */
	private _getEntitiesForContextEntity(
		contextEntity: Entity,
		contextParentEntity: ParentEntity,
		entities: Entity[],
		wrapMode: boolean
	): Entity[] {
		let filteredEntities: Entity[] = entities.filter(e => e.isEntityMachineLearned() || e instanceof ClosedEntity);

		if (contextEntity !== undefined) {
			filteredEntities = filteredEntities.filter(e => e.id !== contextEntity.id);
		}

		if (contextParentEntity !== undefined) {
			filteredEntities = filteredEntities.filter(e => e.id !== contextParentEntity.id);
		}

		if (!wrapMode) {
			filteredEntities = filteredEntities.filter(e => e.type !== ENTITY_TYPES.COMPOSITE);
		}

		return filteredEntities;
	}
}
