import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService, RequestOptionsBuilder } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	EventBusService,
	GENERIC_CACHE_SERVICE_TOKEN,
	IGenericCacheService,
	IModelChangeBusMessage,
	MODEL_CHANGE_TYPE
} from '@luis/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { Entity, ENTITY_TYPES } from '../models/entity.model';
import { ParentEntity } from '../models/parent-entity.model';
import { PrebuiltEntityInfo } from '../models/prebuilt-entity-info.model';
import { Suggestion, SuggestionApiParameter } from '../models/suggestion.model';

@Injectable()
export class EntityUtilitiesService implements OnDestroy {
	private _entityChangeSubscription: Subscription = new Subscription();
	private _domainChangeSubscription: Subscription = new Subscription();

	constructor(
		private readonly _baseService: BaseService,
		private readonly _eventBusService: EventBusService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {
		this._subscribeToEventBus();
	}

	public ngOnDestroy(): void {
		this._domainChangeSubscription.unsubscribe();
		this._entityChangeSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Getter for the base web service url.
	 *
	 * @returns The base web service url.
	 */
	private get _baseUrl(): string {
		return `apps/${this._baseService.configs.appId}/versions/${this._baseService.configs.versionId}`;
	}

	/**
	 * @method
	 * @description
	 * Gets a list of prebuilt entity metadata that are available for addition for
	 * this application.
	 */
	public getPrebuiltEntitiesInfo(): Observable<PrebuiltEntityInfo[]> {
		return this._cacheService.get(
			`${this._baseService.configs.apiUrl}/${this._baseUrl}/listprebuilts`,
			PrebuiltEntityInfo.importListFromApi,
			this._baseService.defaultOptionsBuilder.build()
		);
	}

	/**
	 * @method
	 * @description
	 * Gets value suggestions for the given feature.
	 *
	 * @param param A parameter that aids in suggestion matching.
	 * @returns An observable of the fetched suggestions.
	 */
	public getSuggestions(id: string, param: SuggestionApiParameter): Observable<Suggestion> {
		const path: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/closedlists/${id}/suggest`;
		const data: string = JSON.stringify(param.exportToApi());

		return this._httpService
			.post(path, data, this._baseService.defaultOptionsBuilder.build())
			.map(response => Suggestion.importFromApi(response));
	}

	/**
	 * @method
	 * @description
	 * Gets previous suggestions for the given phrase list feature.
	 *
	 * @param id The feature id of the feature to get suggestions for.
	 * @returns An observable of the suggestions for the feature.
	 */
	public getPreSuggestions(id: string): Observable<Suggestion> {
		const path: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/closedlists/${id}/presuggestion`;

		return this._httpService
			.get(path, this._baseService.defaultOptionsBuilder.build())
			.map(response => Suggestion.importFromApi(response));
	}

	/**
	 * @method
	 * @description
	 * Gets the number of labeled utterances for each entity from the
	 * generic cache service.
	 *
	 * @returns A map of entity id/label count pairs.
	 */
	public getLabelsPerEntity(refresh: boolean = false): Observable<Map<string, number>> {
		const url: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/stats/labelsperentity`;
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return this._cacheService.get(url, this._getStatisticPerEntity, builder.build());
	}

	/**
	 * @description
	 * Gets the number of examples for each entity from the
	 * generic cache service.
	 *
	 * @returns A map of entity id/label count pairs.
	 */
	public getExamplesPerEntity(refresh: boolean = false): Observable<Map<string, number>> {
		const url: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/stats/examplesperentity`;
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return this._cacheService.get(url, this._getStatisticPerEntity, builder.build());
	}

	/**
	 * @method
	 * @description
	 * Gets the number of endpoint hits for each entity.
	 *
	 * @returns A map of entity id/label count pairs.
	 */
	public getEndpointsPerEntity(): Observable<Map<string, number>> {
		const url: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/entities/stats/endpointscores`;

		return this._cacheService.get(url, this._getStatisticPerEntity, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Converts the given dictionary of entity-id/number pairs into a map.
	 *
	 * @param dictionary The dictionary of entity-id/number pairs.
	 * @returns A map of entity-id/number pairs.
	 */
	private _getStatisticPerEntity(dictionary: any): Map<string, number> {
		return Object.keys(dictionary).reduce((map, k) => {
			map.set(k, dictionary[k]);

			return map;
		}, new Map<string, number>());
	}

	/**
	 * @description
	 * Subscribes to the event bus for utterance changes to any of the entities
	 * in the application to update their cached utterance count.
	 */
	private _subscribeToEventBus(): void {
		this._entityChangeSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.MODEL_CHANGED, (ev: IModelChangeBusMessage) => {
			if (ev.model instanceof Entity && Entity.isMachineLearned(ev.model.type) && ev.changeType !== MODEL_CHANGE_TYPE.UPDATE) {
				const labelsMap: Observable<Map<string, number>> = this.getLabelsPerEntity().first();
				const cacheUrl: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/stats/labelsperentity`;
				const options = this._baseService.optionsBuilder.useCacheOnly().build();
				const idsToChange: string[] = [ev.model.id];
				let opObservable: Observable<Map<string, number>>;

				if (ev.model.type === ENTITY_TYPES.HIERARCHICAL) {
					(<ParentEntity>ev.model).children.forEach(c => idsToChange.push(c.id));
				}

				if (ev.changeType === MODEL_CHANGE_TYPE.ADD) {
					opObservable = labelsMap.do(map => idsToChange.forEach(id => map.set(id, 0)));
				} else {
					opObservable = labelsMap.do(map => idsToChange.forEach(id => map.delete(id)));
				}

				opObservable.flatMap(map => this._cacheService.put(cacheUrl, map, options)).subscribe();
			}
		});

		this._domainChangeSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.DOMAIN_CHANGED, () =>
			this.getLabelsPerEntity(true)
		);
	}
}
