import { ILinkable, IResource } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { Entity, ENTITY_TYPES, EntityRole } from './entity.model';

/**
 * @description
 * Represents a closed entity. A closed entity is a special LUIS
 * entity that is not machined learned, instead it is a simple white list
 * that is matched with user utterances.
 *
 * @param sublists The sublists of canonical-form/synonyms pairs.
 */
export class ClosedEntity extends Entity {
	public sublists: ClosedSublist[];
	constructor(id: string = '', name: string = '', sublists: ClosedSublist[] = [], roles: EntityRole[] = [], parent: Entity = null) {
		super(id, name, ENTITY_TYPES.CLOSED, null, roles, parent);
		this.sublists = sublists;
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): ClosedEntity {
		const closedEntity: ClosedEntity = <ClosedEntity>Entity.constructBaseEntity(apiObject, new ClosedEntity());
		closedEntity.labelCount = null;
		closedEntity.sublists = (<any[]>apiObject.subLists).map(s => ClosedSublist.importFromApi(s));
		closedEntity.sublists.forEach(e => e.setParent(closedEntity));

		return closedEntity;
	}

	/**
	 * @description
	 * Checks if the closed entity is valid or not.
	 *
	 * @returns True if valid and false otherwise.
	 */
	public isValid(): boolean {
		return super.isValid() && this.sublists.find(e => !e.isValid()) === undefined;
	}

	/**
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			id: this.id,
			name: this.name,
			subLists: this.sublists.map(e => e.exportToApi())
		};
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @param entityParent The parent to use instead of the object's current parent,
	 * used in case the parent is cloned so child references the new parent.
	 * @returns A deep clone of this object.
	 */
	public clone(entityParent?: Entity): ClosedEntity {
		const closedEntity: ClosedEntity = <ClosedEntity>Entity.cloneBaseEntity(this, new ClosedEntity(), entityParent);
		closedEntity.sublists = this.sublists.map(s => s.clone(closedEntity));

		return closedEntity;
	}
}

/**
 * @dynamic
 * @description
 * Represents an sublist in a closed entity.
 *
 * @param id The sublist id.
 * @param name The sublist name, a.k.a canonical form.
 * @param values The sublist values, a.k.a synonyms.
 * @param parent A reference to the parent of the sublist.
 * @param type The sublist type from the types enum.
 */
export class ClosedSublist implements IResource, ILinkable {
	public id: number;
	public name: string;
	public values: string[];
	public parent: ClosedEntity;
	public type: number;
	public valuesObservable: Observable<string[]>;

	constructor(
		id: number = -1,
		name: string = '',
		values: string[] = [],
		parent: ClosedEntity = null,
		type: number = ENTITY_TYPES.CLOSED_SUBLIST
	) {
		this.id = id;
		this.name = name;
		this.values = values;
		this.parent = parent;
		this.type = type;
		this.valuesObservable = Observable.of(this.values);
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): ClosedSublist {
		return new ClosedSublist(apiObject.id, apiObject.canonicalForm, apiObject.list);
	}

	/**
	 * @description
	 * Imports multiple resource objects from a file.
	 *
	 * @param fileObjects An array of objects read from a file.
	 */
	public static importFromFile(fileObjects: any[]): ClosedSublist[] {
		return fileObjects.map(f => ClosedSublist.importFromApi(f));
	}

	/**
	 * @description
	 * Adds the given value to the entity's sublists.
	 *
	 * @param value The value to add.
	 */
	public addValue(value: string): void {
		this.values.push(value);
	}

	/**
	 * @description
	 * Deletes the value at the given index.
	 *
	 * @param index The index of the value to delete.
	 */
	public deleteValue(index: number): void {
		this.values.splice(index, 1);
	}

	/**
	 * @method
	 * @description
	 * Checks if the this sublist has valid name and values or not.
	 *
	 * @returns True if valid and false otherwise.
	 */
	public isValid(): boolean {
		return this.name.trim().length !== 0 && this.values.find(v => v.trim().length === 0) === undefined;
	}

	/**
	 * @description
	 * Searches for the given query in the sublist name or values. Returns true if the query
	 * was found in any of them.
	 *
	 * @param query The query to searh for.
	 * @returns True if the query was matched in either name or values and false otherwise.
	 */
	public isQueryFound(query: string, exact: boolean = false): boolean {
		const searchFunction = (needle: string, haystack: string) => (exact ? needle === haystack : haystack.indexOf(needle) !== -1);
		const normalizedQuery = query.trim().toLocaleLowerCase();

		if (!normalizedQuery.length) {
			return true;
		}

		const isNameMatched: boolean = searchFunction(normalizedQuery, this.name.toLocaleLowerCase());
		const isValueMatched: boolean = this.values.find(v => searchFunction(normalizedQuery, v.toLocaleLowerCase())) !== undefined;

		return isNameMatched || isValueMatched;
	}

	/**
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			id: this.id,
			canonicalForm: this.name,
			list: this.values
		};
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @param entityParent The parent to use instead of the object's current parent,
	 * used in case the parent is cloned so child references the new parent.
	 * @returns A deep clone of this object.
	 */
	public clone(entityParent?: ClosedEntity): ClosedSublist {
		return new ClosedSublist(
			this.id,
			this.name.slice(),
			this.values.map(v => (v ? v.slice() : v)),
			entityParent ? entityParent : this.parent,
			this.type
		);
	}

	/**
	 * @description
	 * Sets this sublist's parent to the passed argument value
	 *
	 * @param parentEntity The parent entity to assign this sublist to.
	 */
	public setParent(parentEntity: ClosedEntity): void {
		this.parent = parentEntity;
	}
}
