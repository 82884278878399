import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
/**
 * Text field that toggles between edit and non-edit mode using a button.
 * In edit mode it looks like a text field and in non-edit mode it looks like a label with a button.
 *
 */
@Component({
    selector: "m-edit-label",
    templateUrl: './edit-label.component.html',
    styleUrls: ['./edit-label.component.scss']
})

export class EditLabelComponent implements OnChanges {
    /**
     * Value for the edit label field.
     */
    @Input("value") value: string;

    @Input("placeHolder") placeHolder: string= "";

    /**
     * onSave callback - invoked when the user commits the edited value.
     */
    @Output("save") emitSave: EventEmitter<string> = new EventEmitter();

    @ViewChild('iconButton', { read: ElementRef }) public iconButton: ElementRef;

    private _inEditMode: boolean = false;

    public get inEditMode(): boolean {
        return this._inEditMode;
    }

    public onEditButtonClick(): void {
        this._inEditMode = true;
    }

    public save(): void {
        // We don't want blur and enter key (both bound to this) to emit - so check/toggle edit mode here.
        if (this._inEditMode) {
            this._inEditMode = false;
            this.emitSave.emit(this.value);
            setTimeout(() => this.iconButton.nativeElement.querySelector('button').focus(), 0);
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (propName === "value") {
                if (changes[propName].currentValue === undefined) {
                    throw new Error("EditLabelComponent value must be defined.");
                }
            }
        }
    }
}
