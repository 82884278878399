import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as c3 from 'c3';
import { Observable, Subscription } from 'rxjs';
import { AxisData, CHART_TYPES } from '../../models/chart.model';
import { ChartsRendererService } from '../../services/charts-renderer.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
	selector: 'donut-chart',
	template: '<loading [visible]="!(isInitialized)"></loading>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent extends BaseChartComponent implements AfterViewInit, OnDestroy {
	@Input() public isLabelVisible: boolean = true;
	@Input() public donutWidth: number = 60;

	private _xDataSubscription: Subscription = new Subscription();
	private _titleSubscription: Subscription = new Subscription();

	constructor(
		private readonly i18n: TranslateService,
		private readonly elementRef: ElementRef,
		private readonly chartsRenderer: ChartsRendererService
	) {
		super(i18n, elementRef, chartsRenderer);
	}

	public ngAfterViewInit(): void {
		this._xDataSubscription = Observable.combineLatest(this.xData, this.yData).subscribe(conf => {
			const config: c3.ChartConfiguration = this._getConfig(conf[0], conf[1]);
			if (!this.isInitialized) {
				this.init(config);
				this.isInitialized = true;
			} else {
				this.update({ data: config.data });
			}
		});
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this._xDataSubscription.unsubscribe();
		this._titleSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Initializes the chart component with the required configuration
	 */
	protected init(config: c3.ChartConfiguration): void {
		super.init({
			...config,
			bindto: this.elementRef.nativeElement
		});
	}

	/**
	 * @description
	 * Transforms xAxisData and yAxisData to a data structure accepted by ChartRendererService
	 */
	private _transformAxisDataToColumns(xData: AxisData, yData: AxisData): c3.PrimitiveArray[] {
		return xData.data.map((intentName: string, index: number) => [intentName, yData.data[index]]);
	}

	/**
	 * @description
	 * Creates the configuration accepted by the ChartRendererService for generating a bar chart
	 */
	private _getConfig(xData: AxisData, yData: AxisData): c3.ChartConfiguration {
		const data: any = {
			columns: this._transformAxisDataToColumns(xData, yData),
			type: CHART_TYPES.DONUT,
			empty: {
				label: {
					text: this.emptyLabelText
				}
			},
			color: (_, d) => {
				const index: number = xData.data.indexOf(d) % this.colors.length;

				return this.colors[index < 0 ? 0 : index];
			},
			order: null
		};

		const interaction: any = {
			enabled: false
		};

		const padding: any = this.padding;

		const donut: any = {
			width: this.donutWidth,
			label: {
				show: this.isLabelVisible
			}
		};

		return { data, padding, donut, onrendered: this._afterDonutRendered.bind(this), interaction };
	}

	private _afterDonutRendered(): void {
		this._titleSubscription = this.chartTitle
			.filter(title => title !== null)
			.subscribe(title => {
				const donut = this.elementRef.nativeElement.querySelector('accuracy-chart .c3-chart-arcs-title');
				if (donut) {
					donut.innerHTML = title;
					donut.setAttribute('x', '1.5mm');
				}

				const overallDonut = this.elementRef.nativeElement.querySelector('faulty-intents .overall-chart .c3-chart-arcs-title');
				if (overallDonut) {
					overallDonut.innerHTML = title;
					overallDonut.setAttribute('x', '1mm');
				}
			});
		this.onLoad();
	}
}
