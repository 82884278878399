import * as AngularA11y from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionBodyComponent, AccordionComponent, AccordionHeadComponent } from './accordion/accordion.component';
import { AnchorComponent } from './anchor/anchor.component';
import { BladeComponent } from './blade/blade.component';
import { ButtonComponent } from './button/button.component';
import { CalloutComponent } from './callout/callout.component';
import { BarChartComponent } from './charts/components/bar-chart/bar-chart.component';
import { BaseChartComponent } from './charts/components/base-chart/base-chart.component';
import { ConfusionMatrixComponent } from './charts/components/confusion-matrix/confusion-matrix.component';
import { DonutChartComponent } from './charts/components/donut-chart/donut-chart.component';
import { LineChartComponent } from './charts/components/line-chart/line-chart.componenet';
import { LoadingComponent } from './charts/components/loading/loading.component';
import { PieChartComponent } from './charts/components/pie-chart/pie-chart.component';
import { StackedBarChartComponent } from './charts/components/stacked-bar-chart/stacked-bar-chart.component';
import { ChartsRendererService } from './charts/services/charts-renderer.service';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ClippedTextComponent } from './clipped-text/clipped-text.component';
import { CopyIconComponent } from './copy-icon/copy-icon.component';
import { DialogComponent } from './dialog/dialog.component';
import { ClickOrEnterDirective } from './directives/click-or-enter.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DisableMouseFocusComponent } from './directives/disable-mouse-focus.component';
import { DisableMouseFocusDirective } from './directives/disable-mouse-focus.directive';
import { FocusAfterInitDirective } from './directives/focus-after-init.directive';
import { OnCreateFocusDirective } from './directives/on-create-focus.directive';
import { PreventClickDirective } from './directives/prevent-click.directive';
import { DropdownItemComponent } from './dropdown/dropdown-item/dropdown-item.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EditLabelComponent } from './edit-label/edit-label.component';
import { ExtendableCardComponent } from './extendable-card/extendable-card.component';
import { FilePickerComponent } from './file-picker/file-picker.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { InputComponent } from './input/input.component';
import { LabelComponent } from './label/label.component';
import { MenuButtonComponent } from './menu/menu-button/menu-button.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { MenuTriggerDirective } from './menu/menu-trigger';
import { MenuComponent } from './menu/menu.component';
import { ModalComponent } from './modal/modal.component';
import { NavBarAnchorComponent } from './nav-bar/nav-bar-anchor/nav-bar-anchor.component';
import { NavBarButtonComponent } from './nav-bar/nav-bar-button/nav-bar-button.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { OverlayModule } from './overlay/overlay-directives';
import { ScrollDispatchModule } from './overlay/scroll/scroll-dispatch.module';
import { PillValuesComponent } from './pill-values/pill-values.component';
import { AppendPipe, PrependPipe } from './pipes/array.pipe';
import { FuzzySearchAsyncPipe } from './pipes/fuzzySearch.pipe';
import { IncludeSearchAsyncPipe } from './pipes/includeSearch.pipe';
import { PrefixSearchAsyncPipe } from './pipes/prefixSearch.pipe';
import { PopupMeasuringComponent } from './popup-measuring/popup-measuring.component';
import { PopupRendererDirective } from './popup/directives/popup-renderer.directive';
import { PopupComponent } from './popup/popup.component';
import { PortalModule } from './portal/portal-directives';
import { SearchCreateComponent } from './search-create/search-create.component';
import { BladeTrackerService } from './services';
import { PopupMeasurementService } from './services/popup-measurement.service';
import { TooltipService } from './services/tooltip.service';
import { UuidGeneratorService } from './services/uuid-generator.service';
import { SidebarItemComponent } from './sidebar/sidebar-item/sidebar-item.component';
import { SidebarSectionComponent } from './sidebar/sidebar-section/sidebar-section.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BaseCellComponent } from './table/base-cell/base-cell.component';
import { AriaSortAsyncPipe } from './table/pipes/aria-sort-async.pipe';
import { SortIconComponent } from './table/sort-icon/sort-icon.component';
import { TableCellComponent } from './table/table-cell/table-cell.component';
import { TableHeaderCellComponent } from './table/table-header-cell/table-header-cell.component';
import { TableHeaderComponent } from './table/table-header/table-header.component';
import { TableRowComponent } from './table/table-row/table-row.component';
import { TableToolbarComponent } from './table/table-toolbar/table-toolbar.component';
import { ToolbarGroupComponent } from './table/table-toolbar/toolbar-group/toolbar-group.component';
import { ToolbarInputComponent } from './table/table-toolbar/toolbar-input/toolbar-input.component';
import { ToolbarItemComponent } from './table/table-toolbar/toolbar-item/toolbar-item.component';
import { TableComponent } from './table/table.component';
import { ToggleComponent } from './toggle/toggle.component';
import { TooltipBubbleComponent } from './tooltip/tooltip-bubble.component';
import { TooltipLayerComponent } from './tooltip/tooltip-layer.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { A11yModule } from './utils/accessibility/a11y.module';
import { FocusAfterDestroyDirective } from './utils/accessibility/focus-after-destroy.directive';
import { FocusTrapDirective } from './utils/accessibility/focus-trap.directive';
import { PlatformModule } from './utils/platform/platform.module';

// export keyboard & coercion utilities
export * from './utils/keyboard/keycodes';
export * from './utils/coercion/boolean-property';
export * from './utils/coercion/number-property';
export * from './utils/popupAlignment';

@NgModule({
	imports: [
		A11yModule,
		AngularA11y.A11yModule,
		CommonModule,
		FormsModule,
		OverlayModule,
		PlatformModule,
		PortalModule,
		ScrollDispatchModule,
		A11yModule,
		TranslateModule
	],
	declarations: [
		BarChartComponent,
		BaseChartComponent,
		BladeComponent,
		ConfusionMatrixComponent,
		LineChartComponent,
		LoadingComponent,
		PieChartComponent,
		DonutChartComponent,
		StackedBarChartComponent,
		AccordionBodyComponent,
		AccordionComponent,
		AccordionHeadComponent,
		AnchorComponent,
		AppendPipe,
		ButtonComponent,
		CalloutComponent,
		CheckboxComponent,
		ClickOrEnterDirective,
		ClickOutsideDirective,
		ClippedTextComponent,
		DialogComponent,
		DropdownComponent,
		DropdownItemComponent,
		EditLabelComponent,
		FocusAfterInitDirective,
		OnCreateFocusDirective,
		FuzzySearchAsyncPipe,
		IconButtonComponent,
		IncludeSearchAsyncPipe,
		InputComponent,
		LabelComponent,
		MenuComponent,
		MenuItemComponent,
		MenuTriggerDirective,
		MenuButtonComponent,
		ModalComponent,
		NavBarAnchorComponent,
		NavBarButtonComponent,
		NavBarComponent,
		PopupMeasuringComponent,
		PrefixSearchAsyncPipe,
		PrependPipe,
		SearchCreateComponent,
		ToggleComponent,
		TooltipComponent,
		TooltipBubbleComponent,
		TooltipLayerComponent,
		TableComponent,
		TableHeaderComponent,
		TableRowComponent,
		TableCellComponent,
		SortIconComponent,
		TableToolbarComponent,
		ToolbarGroupComponent,
		ToolbarInputComponent,
		ToolbarItemComponent,
		PreventClickDirective,
		CopyIconComponent,
		SidebarComponent,
		SidebarSectionComponent,
		ExtendableCardComponent,
		SidebarItemComponent,
		PillValuesComponent,
		FilePickerComponent,
		AriaSortAsyncPipe,
		BaseCellComponent,
		TableHeaderCellComponent,
		DisableMouseFocusDirective,
		DisableMouseFocusComponent,
		PopupComponent,
		PopupRendererDirective,
		FocusTrapDirective,
		FocusAfterDestroyDirective
	],
	exports: [
		BarChartComponent,
		BladeComponent,
		ConfusionMatrixComponent,
		LineChartComponent,
		LoadingComponent,
		PieChartComponent,
		StackedBarChartComponent,
		DonutChartComponent,
		AccordionBodyComponent,
		AccordionComponent,
		AccordionHeadComponent,
		AnchorComponent,
		AppendPipe,
		ButtonComponent,
		CalloutComponent,
		CheckboxComponent,
		ClickOutsideDirective,
		ClippedTextComponent,
		DialogComponent,
		DropdownComponent,
		DropdownItemComponent,
		EditLabelComponent,
		FocusAfterInitDirective,
		FuzzySearchAsyncPipe,
		IconButtonComponent,
		IncludeSearchAsyncPipe,
		InputComponent,
		LabelComponent,
		MenuComponent,
		MenuItemComponent,
		MenuTriggerDirective,
		MenuButtonComponent,
		ModalComponent,
		NavBarAnchorComponent,
		NavBarButtonComponent,
		NavBarComponent,
		PopupMeasuringComponent,
		PrefixSearchAsyncPipe,
		PrependPipe,
		SearchCreateComponent,
		ToggleComponent,
		TooltipComponent,
		TooltipBubbleComponent,
		TooltipLayerComponent,
		TableComponent,
		TableHeaderComponent,
		TableRowComponent,
		TableCellComponent,
		SortIconComponent,
		TableToolbarComponent,
		ToolbarGroupComponent,
		ToolbarInputComponent,
		ToolbarItemComponent,
		PreventClickDirective,
		CopyIconComponent,
		SidebarComponent,
		SidebarSectionComponent,
		ExtendableCardComponent,
		SidebarItemComponent,
		PillValuesComponent,
		FilePickerComponent,
		AriaSortAsyncPipe,
		TableHeaderCellComponent,
		DisableMouseFocusDirective,
		DisableMouseFocusComponent,
		PopupRendererDirective,
		ClickOrEnterDirective,
		OnCreateFocusDirective,
		FocusTrapDirective,
		FocusAfterDestroyDirective
	],
	providers: [],
	entryComponents: [DialogComponent, ModalComponent, TooltipBubbleComponent, PopupComponent]
})
export class IntercomUIModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: IntercomUIModule,
			providers: [PopupMeasurementService, TooltipService, UuidGeneratorService, ChartsRendererService]
		};
	}
}
