import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { PopupMeasurementService } from '../services/popup-measurement.service';
import { ITooltipServiceShowArguments, TooltipService } from '../services/tooltip.service';
import { Alignment, findPopupBoundsBestFit, IBestFit } from '../utils/popupAlignment';
import { IRect, ISize, rectToStyle } from '../utils/rectangle';
import { TooltipBubbleComponent } from './tooltip-bubble.component';

@Component({
    selector: 'm-tooltip-layer',
    styleUrls: ['./tooltip-layer.component.scss'],
    templateUrl: './tooltip-layer.component.html'
})
export class TooltipLayerComponent implements AfterViewChecked, OnDestroy {
    private alignment: Alignment = 'auto';
    private showTooltipSubscription: Subscription;
    private targetBounds: IRect;

    public label: string;
    public tooltipSize: ISize;
    public documentHeight: number;
    public measuring: boolean = false;
    public style: string = '';
    public show: boolean = false;
    public targetBoundsStyle: any;
    public tooltipBoundsStyle: any;

    @ViewChild('tooltipBubble', { read: TooltipBubbleComponent }) tooltipBubble: TooltipBubbleComponent;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private tooltipService: TooltipService,
        private popupMeasurementService: PopupMeasurementService
    ) {
        this.showTooltipSubscription = this.tooltipService.showTooltipObservable.subscribe(this.showTooltipChanged.bind(this));
        this.tooltipService.registerTooltipLayer(this);
    }

    private showTooltipChanged(args: ITooltipServiceShowArguments): void {
        if (args === null) {
            this.show = false;
            this.changeDetector.detectChanges();
            return;
        }
        this.alignment = args.alignment;
        this.measuring = true;
        this.label = args.label;
        this.show = true;
        this.style = args.style;
        this.targetBounds = args.bounds;
        this.targetBoundsStyle = null;
        this.tooltipBoundsStyle = null;
    }

    public async repositionTooltip() {
        const tooltipSize = await this.popupMeasurementService.measure(TooltipBubbleComponent, { label: this.label });
        const documentRect = document.documentElement.getBoundingClientRect();
        const viewport = {
            height: document.documentElement.clientHeight,
            left: -documentRect.left,
            top: -documentRect.top,
            width: document.documentElement.clientWidth
        };
        const tooltipBestFit: IBestFit = findPopupBoundsBestFit(this.targetBounds, tooltipSize, this.alignment, 12, viewport);

        this.targetBoundsStyle = rectToStyle(tooltipBestFit.target);
        this.tooltipBoundsStyle = rectToStyle(tooltipBestFit.popup);
        this.documentHeight = document.documentElement.offsetHeight;
        this.changeDetector.detectChanges();
    }

    public ngAfterViewChecked(): void {
        if (this.measuring) {
            this.measuring = false;
            this.repositionTooltip();
            this.changeDetector.detectChanges();
        }
    }

    public ngOnDestroy(): void {
        this.showTooltipSubscription.unsubscribe();
        this.showTooltipSubscription = null;
    }
}
