import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';

@Component({
    selector: '[m-toolbar-item]',
    templateUrl: 'toolbar-item.component.html',
    styleUrls: ['toolbar-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarItemComponent {
    @Input() @HostBinding('class.padding') public padding: boolean = true;
    @Input() @HostBinding('class.clickable') public clickable: boolean = true;
    @Input() @HostBinding('class.disabled') public disabled: boolean = false;
    @Input('aria-label') @HostBinding('attr.aria-label') public ariaLabel: string = null;
    @Input() public icon: string = null;

    @HostBinding('attr.tabindex') public get tabIndex(): string { return this.clickable ? '0' : null; }
    @HostBinding('attr.role') public get role(): string { return this.clickable ? 'button' : null; }
}
