import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AppSettings } from '../models/app-settings.model';

export const APP_SETTINGS_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IAppSettingsService');

/**
 * @description
 * Represents an interface for apps settings service. Settings service
 * should contain CRUD operations for app settings.
 */
export interface IAppSettingsService {
    get(): Observable<AppSettings>;

    update(settings: AppSettings): Observable<void>;
}
