import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityHelpers } from '../../../../helpers/entity.helpers';
import { LuisModel } from '@luis/core';

@Component({
	selector: 'new-menu-item',
	templateUrl: 'new-menu-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewMenuItemComponent {
	@Input() query: string;
	@Output() clicked: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

	public splitQuery: typeof EntityHelpers.splitQuery = EntityHelpers.splitQuery;

	/**
	 * @description
	 * Determines whether create new entity item should be visible or not
	 *
	 * @param entities Filtered entities from the template.
	 * @returns Whether the create entity item should be visible or not.
	 */
	public static showNewEntityItem(query: string, entities: LuisModel[], mainEntity?: LuisModel): boolean {
		// tslint:disable-next-line:no-unnecessary-local-variable to make ngc happy
		const result = entities ? entities.every(entity => entity.name !== query) : false;

		return result && (mainEntity ? mainEntity.name !== query : true);
	}
}
