import { IntentMetadata } from './intent-metadata.model';

/**
 * @description
 * The supported entity types in LUIS.
 */
export enum STATUS_CODES {
	NOT_STARTED,
	RUNNING,
	COMPLETED,
	FAILED
}

export type VersionsComparison = {
	correct: number;
	unclear: number;
	incorrect: number;
	numIntents: number;
	numEntities: number;
	numUtterances: number;
};

export type FilteredIntentInfo = {
	selectedIntentId: string;
	filters: {
		evaluation: {
			correct: boolean;
			incorrect: boolean;
			unclear: boolean;
		};
		nearestRivalIntentsIds: string[];
		applied: boolean;
	};
};

export type FaultyIntent = {
	name: string;
	id: string;
	utterancesCount: number;
};

/**
 * @description
 * Represents a LUIS training result metadata resource class.
 *
 * @param numCorrectUtterances The number of the utterances that are correct.
 * @param numAmbiguousUtterances The number of the utterances that are ambiguous.
 * @param numAmbiguousIncorrectUtterances The number of the utterances that are ambiguous and incorrect.
 * @param numIncorrectUtterances The number of the utterances that are incorrect.
 * @param date The date of last training.
 * @param version The version of the application which trained.
 * @param timeElapsed The time of training process.
 * @param numIntents The number of trained intents.
 * @param numEntities The number of trained entities.
 * @param numUtterances The number of trained utterances.
 * @param numPatterns The number of trained patterns.
 * @param numPhraseLists The number of trained phraseLists.
 * @param utterancesPerIntent A map of number of utterances per intent with key is intent name.
 */
export class TrainingResultMetaData {
	public version: string;

	constructor(
		public numCorrectUtterances: number = 0,
		public numAmbiguousUtterances: number = 0,
		public numAmbiguousIncorrectUtterances: number = 0,
		public numIncorrectUtterances: number = 0,
		public date: Date = new Date(),
		public timeElapsed: Date = new Date(),
		public numIntents: number = 0,
		public numEntities: number = 0,
		public numUtterances: number = 0,
		public numPatterns: number = 0,
		public numPhraseLists: number = 0,
		public utterancesPerIntent: Map<string, IntentMetadata> = new Map<string, IntentMetadata>()
	) {}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): TrainingResultMetaData {
		const incorrectUtterances: number = apiObject.misclassifiedUtterancesCount;
		const unclearUtterances: number = apiObject.ambiguousUtterancesCount - apiObject.misclassifiedAmbiguousUtterancesCount;
		const correctUtterances: number = apiObject.appVersionUtterancesCount - incorrectUtterances - unclearUtterances;

		const utterancesPerIntent: Map<string, IntentMetadata> = new Map<string, IntentMetadata>();
		apiObject.modelsMetadata.forEach(intent => {
			utterancesPerIntent.set(intent.modelName, IntentMetadata.importFromApi(intent));
		});

		return new TrainingResultMetaData(
			correctUtterances,
			unclearUtterances,
			apiObject.misclassifiedAmbiguousUtterancesCount,
			incorrectUtterances,
			new Date(apiObject.lastTrainDate),
			new Date(`1234-12-12T${apiObject.trainingTime}`),
			apiObject.intentsCount,
			apiObject.entitiesCount,
			apiObject.appVersionUtterancesCount,
			apiObject.patternsCount,
			apiObject.phraseListsCount,
			utterancesPerIntent
		);
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): TrainingResultMetaData {
		const mapClone: Map<string, IntentMetadata> = new Map<string, IntentMetadata>();
		this.utterancesPerIntent.forEach((intent, name) => mapClone.set(name, intent.clone()));

		return new TrainingResultMetaData(
			this.numCorrectUtterances,
			this.numAmbiguousUtterances,
			this.numAmbiguousIncorrectUtterances,
			this.numIncorrectUtterances,
			new Date(this.date.getTime()),
			new Date(this.timeElapsed.getTime()),
			this.numIntents,
			this.numEntities,
			this.numUtterances,
			this.numPatterns,
			this.numPhraseLists,
			mapClone
		);
	}
}
