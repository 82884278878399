import { DatasetMetadata } from '../models/dataset-metadata.model';

/**
 * @description
 * An enumeration of the operations that you can apply on a dataset.
 */
export enum DATASET_OPERATIONS {
    IMPORT,
    RUN,
    RENAME,
    EXPORT,
    DELETE,
    OPEN
}

/**
 * @description
 * An interface to send messages between the dataset table and its parent when
 * a dataset is clicked for a specific operation.
 */
export interface IDatasetOperation {
    operation: number;

    dataset?: DatasetMetadata;
}
