import { IDataItem } from '@luis/core';

/**
 * @description
 * Represents a LUIS pattern that is added to an intent.
 *
 * @param id The pattern id.
 * @param text The pattern text.
 * @param intentName The pattern intent name.
 * @param entities The entities in the pattern.
 */
export class Pattern implements IDataItem {
    public id: string;
    public text: string;
    public intentName: string;
    public entities: PatternEntity[];

    private readonly _fullParanthesisExp: RegExp = /{[^{}]*}/g;

    constructor(id: string = '', text: string = '', intentName: string = '', entities: PatternEntity[] = []) {
        this.id = id;
        this.text = text;
        this.intentName = intentName;
        this.entities = entities;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): Pattern {
        return new Pattern(
            apiObject.id,
            apiObject.pattern,
            apiObject.intent
        );
    }

    /**
     * @method
     * @description
     * Checks if the given pattern is equal to this
     * pattern object. This performs a deep comparison
     * of the two objects.
     *
     * @param p The pattern to compare this one to.
     * @returns True if the patterns are equal
     * and vice versa.
     */
    public isEqual(p: Pattern): boolean {
        if (p === undefined) {
            return false;
        }
        if (p.id !== this.id) {
            return false;
        }
        if (p.text !== this.text) {
            return false;
        }
        if (p.intentName !== this.intentName) {
            return false;
        }

        return true;
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            id: this.id,
            pattern: this.text,
            intent: this.intentName
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): Pattern {
        return new Pattern(
            this.id.slice(),
            this.text.slice(),
            this.intentName.slice(),
            this.entities.map(e => e.clone())
        );
    }

    /**
     * @method
     * @description
     * Converts the raw text (the one with the entity names) to
     * a pattern text format, which contains indeces of the
     * pattern entity references in place of the entity names in
     * the raw original text.
     *
     * @returns The pattern text which contains indeces
     * corresponding to the entity references in the pattern.
     */
    public rawToPattern(): string {
        let patternText: string = this.text;
        this._fullParanthesisExp.lastIndex = 0;
        const matches: RegExpMatchArray = this.text.match(this._fullParanthesisExp);

        if (matches) {
            matches.forEach((match, index) => patternText = patternText.replace(match, `{${index}}`));
        }

        return patternText;
    }
}

/**
 * @description
 * Represents an entity defined in a pattern.
 *
 * @param id The entity id.
 * @param name The entity name.
 * @param type The entity type.
 * @param alias (Optional) The entity role alias.
 */
export class PatternEntity {
    public id: string;
    public name: string;
    public index: number;
    public type: number;
    public roleId: string;

    constructor(id: string = '', name: string = '', index: number = null, type: number = null, roleId: string = '') {
        this.id = id;
        this.name = name;
        this.index = index;
        this.type = type;
        this.roleId = roleId;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): PatternEntity {
        return new PatternEntity(
            apiObject.entityId,
            apiObject.name,
            apiObject.index,
            apiObject.typeId,
            apiObject.roleId
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            entityId: this.id,
            name: this.name,
            index: this.index,
            typeId: this.type,
            roleId: this.roleId
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): PatternEntity {
        return new PatternEntity(
            this.id.slice(),
            this.name.slice(),
            this.index,
            this.type,
            this.roleId.slice()
        );
    }
}
