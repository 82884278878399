import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
    InjectionToken,
    Input,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    BaseModalComponent,
    IModalComponent,
    IToasterService,
    TOASTER_SERVICE_TOKEN
} from '@luis/core';
import {
    IPatternService,
    IPatternState,
    Pattern,
    PATTERN_SERVICE_TOKEN,
    PatternInputComponent
} from '@luis/patterns';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';

export const UTTERANCE_TO_PATTERN_MODAL_COMP_TOKEN: InjectionToken<string> = new InjectionToken('UtteranceToPatternModalComponent');

/**
 * @description
 * Represents the modal for renaming an intent.
 */
@Component({
    selector: 'utterance-to-pattern-modal',
    templateUrl: 'utterance-to-pattern-modal.component.html',
    styleUrls: ['utterance-to-pattern-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtteranceToPatternModalComponent extends BaseModalComponent implements OnInit, IModalComponent<void>, AfterViewInit {
    @Input() public response: Subject<void>;
    @Input() public intentName: string;
    @Input() public patterns: Pattern[];

    @ViewChildren(PatternInputComponent) public patternInputs: QueryList<PatternInputComponent>;

    public isButtonDisabled: Observable<boolean>;
    public confirmedPatterns: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);

    private _patternValidity: Observable<boolean[]>;
    private _checkboxValidity: Observable<boolean[]>;

    constructor(
        private _i18n: TranslateService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(PATTERN_SERVICE_TOKEN) private _patternService: IPatternService
    ) { super(); }

    public ngOnInit(): void {
        super.ngOnInit();
        this._initState();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this._initViewState();
    }

    /**
     * @description
     * Submits selected patterns.
     */
    public addPatterns(): void {
        const confirmedPatterns: boolean[] = this.confirmedPatterns.getValue();

        const stateObs: Observable<IPatternState>[] = this.patternInputs
            .filter((_, i) => confirmedPatterns[i])
            .map(pI => pI.state);

        this._patternService.get()
            .first()
            .flatMap(() =>
                Observable.combineLatest(stateObs)
                    .map(states => states.map(s => s.text))
                    .map(patternTexts => patternTexts.reduce((acc, val) => acc.add(val), new Set<string>()))
                    .map(set => Array.from(set.values()))
                    .map(patternTexts => patternTexts.map(patternText => new Pattern('', patternText, this.intentName)))
                    .map(patterns => patterns.map(pattern => this._patternService.add(pattern)))
                    .flatMap(addTasks => Observable.combineLatest(addTasks))
            )
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('utterances.utterance-to-pattern.add_toast') }))
            .subscribe(() => this.response.complete());
    }

    /**
     * @description
     * Fires when a pattern is checked.
     */
    public onPatternChecked(patternChecked: boolean, index: number): void {
        const confirmedPatterns: boolean[] = this.confirmedPatterns.getValue();
        confirmedPatterns[index] = patternChecked;
        this.confirmedPatterns.next(confirmedPatterns);
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.confirmedPatterns.next(this.patterns.map(p => true));
    }

    /**
     * @description
     * Initializes the children components.
     */
    private _initViewState(): void {
        this._patternValidity = Observable.combineLatest(this.patternInputs.map(pI => pI.state))
            .map(states => states.map(state => state.isValid));

        this._checkboxValidity = this.confirmedPatterns.asObservable();

        this.isButtonDisabled = Observable.combineLatest(this._patternValidity, this._checkboxValidity)
            .map(([patternsValidity, patternsSelection]) => patternsValidity.filter((v, i) => patternsSelection[i]))
            .map(checkedPatternsValidity => checkedPatternsValidity.length === 0 || checkedPatternsValidity.filter(v => !v).length !== 0);
    }
}
