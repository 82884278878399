/**
 * @description
 * An enumeration of the filter options that you can apply on an distribution chart.
 */
export enum FILTER_OPTIONS {
	CORRECT,
	AMBIGUOUS,
	INCORRECT,
	NONE
}
