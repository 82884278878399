import {
    AfterViewInit,
    Directive,
    ElementRef,
    Renderer
} from '@angular/core';

/**
 * @description
 * Set focus to this element after init.
 * Used to set focus on an element when it is materialized.
 */
@Directive({
    selector: '[focus-after-init]'
})
export class FocusAfterInitDirective implements AfterViewInit {
    public constructor(private _element: ElementRef, private _renderer: Renderer) { }

    public ngAfterViewInit(): void {
        this._renderer.invokeElementMethod(this._element.nativeElement, 'focus');
    }
}
