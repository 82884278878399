﻿import { IResource } from '@luis/core';
import { Suggestion } from './suggestion.model';

/**
 * @description
 * Represents a LUIS phrase list feature.
 */
export class PhraseList implements IResource {
    public id: number;
    public name: string;
    public phrases: string;
    public mode: boolean;
    public isActive: boolean;
    public previousSuggestion: Suggestion;

    constructor(
        id: number = null, name: string = '',
        phrases: string = '', mode: boolean = true,
        isActive: boolean = true, previousSuggestion: Suggestion = null
    ) {
        this.id = id;
        this.name = name;
        this.phrases = phrases;
        this.mode = mode;
        this.isActive = isActive;
        this.previousSuggestion = previousSuggestion;
    }

    /**
     * Imports phrase list features from the features object returned by the api.
     * @param webApiFeatures The features object recevied by the api.
     * @returns An array of phrase list features.
     */
    public static importFromFeaturesApi(apiObject: any): PhraseList[] {
        const phraseLists: any[] = apiObject.phraselistFeatures;

        return phraseLists.map(p => PhraseList.importFromApi(p));
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): PhraseList {
        return new PhraseList(
            apiObject.id,
            apiObject.name,
            apiObject.phrases,
            apiObject.isExchangeable,
            apiObject.isActive
        );
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): PhraseList {
        return new PhraseList(
            this.id,
            this.name.slice(),
            this.phrases.slice(),
            this.mode,
            this.isActive,
            this.previousSuggestion
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            name: this.name,
            phrases: this.phrases,
            isActive: this.isActive,
            isExchangeable: this.mode,
            previousResult: this.previousSuggestion ? this.previousSuggestion.exportToApi() : null
        };
    }

    /**
     * @method
     * @description
     * Checks if the current phrase list is valid or not.
     *
     * @returns True if valid and false otherwise.
     */
    public isValid(): boolean {
        if (this.name.trim() === '' || this.phrases.trim() === '') {
            return false;
        }

        return true;
    }
}
