import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Subject } from "rxjs/Rx";

/**
 * Simple Accordion - clicking on the header toggles visibility (actually inclusion in the dom) of the body.
 * Usage:
 *  <m-accordion>
 *      <m-accordion-head>Stuff to display in the head</m-accordion-head>
 *      <m-accordion-body>Stuff to display in the body</m-accordion-body>
 *  </m-accordion>
 */
@Component({
    selector: "m-accordion",
    templateUrl: "./accordion.component.html",
    styleUrls: ["./accordion.component.scss"]
})
export class AccordionComponent implements OnInit {
    /**
     * Is the body visible?
     */
    @Input("body-visible") bodyVisible: boolean = false;
    @Output("body-visible-change") bodyVisibleChange = new EventEmitter<boolean>();

    private _toggle = new Subject();

    /**
     * Toggle visibility... public for testing.
     */
    public toggleVisibile() {
        this.bodyVisible = !this.bodyVisible;
        this.bodyVisibleChange.emit(this.bodyVisible);
    }

    public ngOnInit() {
        this._toggle
            .asObservable()
            .throttleTime(200)
            .subscribe(() => { this.toggleVisibile(); });
    }

    _throttleToggleVisible(): void {
        this._toggle.next();
    }
}

/**
 * Accordion head. @see AccordionComponent.
 */
@Component({
    selector: "m-accordion-head",
    template: `<ng-content></ng-content>`
})
export class AccordionHeadComponent {
}

/**
 * Accordion body. @see AccordionComponent.
 */
@Component({
    selector: "m-accordion-body",
    template: `<ng-content></ng-content>`
})
export class AccordionBodyComponent {
}
