export namespace LuisConstants {
	export const DEBUG_SETTINGS_KEY: string = 'debugSettings';
	export const PROGRAMMATIC_KEY_ID: string = 'PROGRAMMATIC';
	export const PROGRAMMATIC_KEY_NAME: string = 'Starter_Key';
	export const USER_ASSIGNED_KEYPATH: string = 'userassigned';
	export enum ITEM_TABLE_FILTERS {
		ERROR,
		ENTITY,
		INTENT,
		SCORE,
		EVALUATION
	}
	export const AAD_ERROR_COOKIE_NAME: string = 'luis_aad_error';
	export const LUIS_CONTAINER_DOCS: string = 'http://aka.ms/luis-container-how-to';
	export const FEATURE_RATINGS_STORAGE_KEY: string = 'luis_feature_ratings';
	export const FEATURE_OPT_SETTINGS_STORAGE_KEY: string = 'luis_feature_opt_settings';
	export const SURVEY: string = 'survey';
}
