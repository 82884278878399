import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseService } from '@luis/core';

/**
 * @description
 * This component is resposible for injecting @font-face into the header of the document.
 */
@Component({
	selector: 'font-injector',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontInjectorComponent implements OnInit, OnDestroy {
	private readonly _styleElem: HTMLStyleElement = document.createElement('style');

	constructor(private readonly _baseService: BaseService) {}

	public ngOnInit(): void {
		this._styleElem.appendChild(
			document.createTextNode(`
                @font-face {
                    font-family: "Segoe UI Mono";
                    src: url("${this._baseService.configs.blobStoreUrl}/prod-assets/fonts/SegoeUI-Mono.ttf");
                    font-style: normal;
                    font-weight: normal;
                }
            `)
		);

		document.head.appendChild(this._styleElem);
	}

	public ngOnDestroy(): void {
		document.head.removeChild(this._styleElem);
	}
}
