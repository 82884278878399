import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { GATEKEEPER_FEATURE, GenericPromptService, ProgressTracker, PromptButtonTypes } from '@luis/core';
import { CheckboxComponent } from '@luis/ui';
import { Observable } from 'rxjs/Rx';
import { IPublishService, PUBLISH_SERVICE_TOKEN } from '../../interfaces/IPublishService';
import { POPUPS } from '../../models/popup-messages.model';
import { PersistantPublishSettings } from '../../models/publish.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'external-settings',
    templateUrl: 'external-settings.component.html',
    styleUrls: ['external-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalSettingsComponent implements OnInit {
    public persistantPublishSettings: Observable<PersistantPublishSettings>;
    public externalSettingsTracker: ProgressTracker = new ProgressTracker();
    public popUpEnums: typeof POPUPS.TYPES = POPUPS.TYPES;
    public botUrl: string;
    public gateKeeperFeature: typeof GATEKEEPER_FEATURE = GATEKEEPER_FEATURE;

    constructor(
        private _i18n: TranslateService,
        private _promptService: GenericPromptService,
        @Inject(PUBLISH_SERVICE_TOKEN) private _publishService: IPublishService,
    ) {}

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Updates the publish setting given and updates the url if it has
     * a url constituent.
     *
     * @param key The key of the setting.
     * @param value The value of the setting.
     * @param updateUrl A flag to whether update the url or not.
     */
    public updateSetting(key: string, value: any, checkboxComp: CheckboxComponent, popupType?: POPUPS.TYPES): void {
        this.persistantPublishSettings
            .first()
            .do(settings => (settings[key] = value))
            .flatMap(settings => this._publishService.updatePublishSettings(settings))
            .trackProgress(this.externalSettingsTracker.getTracker())
            .subscribe(() =>
                setTimeout(() => {
                    checkboxComp._onFocus();
                    if (popupType !== undefined && value) {
                        this._showPopup(popupType);
                    }
                }),
            );
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.persistantPublishSettings = this._publishService.getPublishSettings();

        this.botUrl = this._publishService.getBotUrl();
    }

    /**
     * @description
     * Shows the popup with the given enumeration.
     *
     * @param type The popup to open.
     */
    private _showPopup(type: POPUPS.TYPES): void {
        const popupData: POPUPS.IPopupData = POPUPS.createPopUp(type, this._i18n);
        this._promptService
            .prompt(
                popupData.title,
                popupData.body,
                { ok: this._i18n.instant('publish-pane.external-settings.ok') },
                { ok: PromptButtonTypes.Primary },
            )
            .subscribe();
    }
}
