import { Entity, ENTITY_TYPES, EntityRole } from '../models/entity.model';
import { ParentEntity } from '../models/parent-entity.model';

/**
 * @dynamic
 * @description
 */
export class EntityHelpers {
	public static extractRoles(entities: Entity[]): EntityRole[] {
		return entities.reduce((acc, entity) => [...acc, ...(entity.roles || [])], []);
	}

	public static expandChildren(entities: Entity[]): Entity[] {
		return entities.reduce((acc, e) => acc.concat(e instanceof ParentEntity ? [e, ...e.getChildrenWithFullName()] : e), []);
	}

	/**
	 * @description
	 * Checks if an entity is trainable.
	 *
	 * @param type The entity type to check.
	 * @returns true if the entity is trainable, false otherwise
	 */
	public static isTrainable(type: number): boolean {
		return !(
			type === ENTITY_TYPES.CLOSED ||
			type === ENTITY_TYPES.CLOSED_SUBLIST ||
			type === ENTITY_TYPES.PREBUILT ||
			type === ENTITY_TYPES.REGEX ||
			type === ENTITY_TYPES.PATTERN_ANY
		);
	}

	/**
	 * @description
	 * Splits the query into entity name and role name
	 *
	 * @param query
	 * @returns A tuple of both the entity name and role name
	 */
	public static splitQuery(query: string): [string, string] {
		const [entity, role] = query.trim().split(':');

		return [entity, role];
	}
}
