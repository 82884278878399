import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import { BaseCellComponent } from '../base-cell/base-cell.component';

@Component({
    selector: '[m-table-cell]',
    templateUrl: 'table-cell.component.html',
    styleUrls: ['table-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TableCellComponent extends BaseCellComponent {
    @Input() public loader: boolean = false;
    @Input() public announce: boolean = false;

    @HostBinding('attr.role') public role: string = 'gridcell';

    constructor(private _elRef: ElementRef, private _renderer2: Renderer2) {
        super(_elRef, _renderer2);
    }
}
