import { NgModule } from '@angular/core';
import { ShowPatternErrorPipe } from '../../pipes/show-pattern-error.pipe';
import { SharedModule } from '../../shared/shared.module';
import { EntityInspectorComponent } from './entity-inspector/entity-inspector.component';
import { IntentInspectorComponent } from './intent-inspector/intent-inspector.component';
import { PatternInspectorComponent } from './pattern-inspector/pattern-inspector.component';
import { SentimentInspectorComponent } from './sentiment-inspector/sentiment-inspector.component';
import { UtteranceInspectorComponent } from './utterance-inspector.component';

@NgModule({
	imports: [SharedModule],
	declarations: [
		UtteranceInspectorComponent,
		IntentInspectorComponent,
		PatternInspectorComponent,
		SentimentInspectorComponent,
		EntityInspectorComponent,
		ShowPatternErrorPipe
	],
	exports: [
		UtteranceInspectorComponent,
		IntentInspectorComponent,
		PatternInspectorComponent,
		SentimentInspectorComponent,
		EntityInspectorComponent
	],
	providers: []
})
export class UtteranceInspectorModule {}
