import { InjectionToken } from '@angular/core';
import { Id, IPage, ProgressTracker } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { AppVersion } from '../models/app-version.model';
import { App } from '../models/app.model';

export const APP_VERSION_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IAppVersionService');

/**
 * @description
 * Represents an interface for an app version service. An app version service should contain
 * CRUD methods for LUIS app versions.
 */
export interface IAppVersionService {
    init(trigger: Observable<boolean>, chunkingTracker: ProgressTracker): void;

    get(pageIndex: number, app?: App, refresh?: boolean, getAll?: boolean): Observable<IPage<AppVersion>>;

    getVersion(versionId: string, app?: App, refresh?: boolean): Observable<AppVersion>;

    put(app: App, version: AppVersion): Observable<void>;

    rename(app: App, oldVersionId: string, version: AppVersion): Observable<void>;

    import(jsonString: string, versionName?: string): Observable<AppVersion>;

    export(version: AppVersion): Observable<void>;

    exportForContainer(version: AppVersion): Observable<void>;

    clone(versionId: string, newVersionName: string): Observable<AppVersion>;

    delete(versionId: string): Observable<void>;

    batchDelete(versions: AppVersion[]): Observable<Id[]>;
}
