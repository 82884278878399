import { NgModule, Provider } from '@angular/core';
import { BatchTestingBladeModule } from './components/batch-testing-blade/batch-testing-blade.module';
import { BladesModule } from './components/blades/blades.module';
import { UtteranceInspectorModule } from './components/utterance-inspector/utterance-inspector.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
	imports: [SharedModule, BatchTestingBladeModule, UtteranceInspectorModule, BladesModule],
	exports: [BatchTestingBladeModule, UtteranceInspectorModule, BladesModule]
})
export class TestPaneModule {
	public static providersForChild(): Provider[] {
		return [...BatchTestingBladeModule.providersForChild()];
	}
}
