import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Input,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { FocusKeyManager } from '../utils/accessibility/focus-key-manager';
import { ESCAPE } from '../utils/keyboard/keycodes';
import { IPopupOption } from './interfaces/IPopupOption';

/**
 * @description
 * This is the popup component that appears when an element that has the
 * popoutRenderer directive is right clicked on. Lists the options passed
 * and handles key and events mouse events on it.
 */
@Component({
    selector: 'm-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent implements AfterViewInit {
    @Input() options: IPopupOption[];
    // ClientRect
    @Input() origin: any;
    @Input() closeSubject: Subject<void>;

    @ViewChildren('optionItem') public optionItems: QueryList<ElementRef>;

    private _keyManager: FocusKeyManager;

    public ngAfterViewInit(): void {
        this._initState();
    }

    /**
     * @description
     * Closes the dropdown if escape was pressed and handles
     * all the other accessibility features via key manager.
     *
     * @param event The keyboard event fired.
     */
    @HostListener('keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent): void {
        event.stopPropagation();

        switch (event.keyCode) {
            case ESCAPE:
                this.closeSubject.complete();
                break;
            default:
                this._keyManager.onKeydown(event);
        }
    }

    /**
     * @description
     * Closes the popup if any option was clicked or if the
     * document was scrolled.
     */
    @HostListener('document:scroll')
    @HostListener('click')
    public closePopup(): void {
        this.closeSubject.complete();
    }

    /**
     * @description
     * Initializes the state.
     */
    private _initState(): void {
        this.optionItems.reset(this.optionItems.toArray().map(e => e.nativeElement));
        this._keyManager = new FocusKeyManager(<any>this.optionItems).withWrap();
        this._keyManager.setFirstItemActive();
        this._keyManager.tabOut.first().subscribe(() => this.closeSubject.complete());
    }
}
