/**
 * @description
 * Represents the object that returns when the a user accesses the azure connector
 * page. This object contains the programmatic key and the endpoint base url for
 * the account that will be used to connect with Azure.
 *
 * @param programmaticKey The user's programmatic key.
 * @param endpointUrl The base endpoint url for this account.
 * (Might be constant for all accounts, in this case, there
 * will be no need to get it from a web api call.)
 */
export class ProgrammaticKeyWithEndpointUrl {
	public programmaticKey: string;
	public endpointUrl: string;

	constructor(programmaticKey: string = null, endpointUrl: string = null) {
		this.programmaticKey = programmaticKey;
		this.endpointUrl = endpointUrl;
	}

	/**
	 * @description
	 * Import a new class object using the web api object recieved.
	 *
	 * @param webApiKey The object received from the web api.
	 * @returns A new ProgrammaticKeyWithEndpointUrl object.
	 */
	public static importApiReady(webApiKeyWithUrl: any): ProgrammaticKeyWithEndpointUrl {
		return new ProgrammaticKeyWithEndpointUrl(webApiKeyWithUrl.programmaticKey, webApiKeyWithUrl.endpointBaseUrl);
	}

	/**
	 * @description
	 * Mutates the endpoint url by replacing the original string in the
	 * endpoint url by the replacment given.
	 *
	 * @param original The original substring to replace.
	 * @param replacement The replacement string to put.
	 */
	public mutateEndpointUrl(original: string, replacement: string): void {
		this.endpointUrl = this.endpointUrl.replace(original, replacement);
	}

	/**
	 * @description
	 * Exports the object in the azure connector expected format.
	 *
	 * @returns An object with parameters to match the connector api.
	 */
	public exportToAzureConnector(): Object {
		return {
			key: this.programmaticKey,
			BaseUrl: this.endpointUrl
		};
	}

	/**
	 * @description
	 * Clones the current object into a new one.
	 *
	 * @returns A clone of the current ProgrammaticKeyWithEndpointUrl object.
	 */
	public clone(): ProgrammaticKeyWithEndpointUrl {
		return new ProgrammaticKeyWithEndpointUrl(this.programmaticKey.slice(0), this.endpointUrl.slice(0));
	}
}
