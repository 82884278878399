import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Toast } from '../models/utils/toast.model';

/**
 * @description
 */
@Pipe({
    name: 'toastIconAsync'
})
export class ToastIconAsyncPipe implements PipeTransform {
    /**
     * @description
     *
     * @param toast
     * @param mode
     */
    public transform(toast: Toast, mode: string): Observable<string> {
        return Observable.combineLatest(toast.isNotStarted, toast.isStarted, toast.hasData, toast.isEnded, toast.isError)
            .map(([notStarted, started, hasData, ended, error]) => {
                if (notStarted || started) {
                    return mode === 'class' ? 'info' : 'm-icon-InfoSolid';
                }
                if (hasData || ended) {
                    return mode === 'class' ? 'success' : 'm-icon-CompletedSolid';
                }
                if (error) {
                    return mode === 'class' ? 'error' : 'm-icon-AlertCircle';
                }
            });
    }
}
