import { IApiExportable, ICloneable } from '@luis/core';

/**
 * @description
 * Represents a semantic dictionary suggestion parameter.
 */
export class SuggestionApiParameter implements ICloneable, IApiExportable {
    public words: string[];
    public count: number;
    public previousSuggestion: Suggestion;

    constructor(words: string[] = [], count: number = 10, previousSuggestion: Suggestion = null) {
        this.words = words;
        this.count = count;
        this.previousSuggestion = previousSuggestion;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): SuggestionApiParameter {
        return new SuggestionApiParameter(
            apiObject.Words,
            apiObject.TopN,
            Suggestion.importFromApi(apiObject.PreviousResult)
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            Words: this.words,
            TopN: this.count,
            PreviousResult: this.previousSuggestion ? this.previousSuggestion.exportToApi() : null
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): SuggestionApiParameter {
        return new SuggestionApiParameter(
            this.words.map(w => w.slice()),
            this.count,
            this.previousSuggestion.clone()
        );
    }
}

/**
 * @description
 */
export class Suggestion implements ICloneable, IApiExportable {
    public entries: string[];
    private _rawObject: any;

    constructor(entries: string[], rawObject: any) {
        this.entries = entries;
        this._rawObject = rawObject;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): Suggestion {
        if (apiObject == null) {
            return null;
        }
        else {
            return new Suggestion(apiObject.SuggestedEntries, apiObject);
        }
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return this._rawObject;
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): Suggestion {
        return new Suggestion(
            this.entries.map(e => e.slice()),
            this._rawObject
        );
    }
}
