import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { DropdownItemComponent, DropdownItemData } from '../dropdown/dropdown-item/dropdown-item.component';
import { DropdownComponent } from '../dropdown/dropdown.component';

/** Service used as a communication interface between
 *  Dropdown and DropdownItem components.
 */
@Injectable()
export class DropdownControlService {
    public dropdown: DropdownComponent;
    public items = new Array<DropdownItemComponent>();
    public currentValue = new BehaviorSubject<DropdownItemData>(null);

    /** Registers a dropdown to the the service. */
    public registerDropdown(d: DropdownComponent) {
        this.dropdown = d;
    }

    /** Registers a dropdown item to the service. */
    public registerItem(i: DropdownItemComponent) {
        this.items.push(i);
    }

    /** Find item for g */
    public findItemForValue(v: any): DropdownItemComponent {
        return this.items.find(i => i.value === v);
    }

    /** Updates the current dropdown value. */
    public updateValue(data: DropdownItemData) {
        this.currentValue.next(data);
    }
}
