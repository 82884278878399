import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILuisConfigs } from '../../interfaces/models/ILuisConfigs';
import { ENVIRONMENT_TOKEN, IEnvironment } from '../../interfaces/utils/IEnvironment';

/**
 * @description
 * Contains methods to get and set the configuration properties
 * of the LUIS portal.
 */
@Injectable()
export class ConfigService {
	private _configs: ILuisConfigs;
	private readonly _configsSubject: BehaviorSubject<ILuisConfigs> = new BehaviorSubject<ILuisConfigs>(undefined);

	constructor(@Inject(ENVIRONMENT_TOKEN) private readonly _env: IEnvironment) {
		this._initState();
	}

	/**
	 * @description
	 * Gets the current configurations.
	 */
	public getConfig(): ILuisConfigs {
		return this._configs;
	}

	/**
	 * @description
	 * Gets the current configurations as an observable.
	 */
	public getConfigAsync(): Observable<ILuisConfigs> {
		return this._configsSubject.asObservable().filter(configs => configs !== undefined);
	}

	/**
	 * @description
	 * Sets the current configurations with the given config object.
	 *
	 * @param configs The configs to set.
	 */
	public setConfig(configs: Partial<ILuisConfigs>): void {
		this._configs = { ...this._configs, ...configs };
		this._notify();
	}

	/**
	 * @description
	 * Initializes the service.
	 */
	private _initState(): void {
		const config: ILuisConfigs = {
			env: this._env.env,
			isDevelopment: !this._env.production,
			apiUrl: this._env.apiUrl,
			webApiUrl: this._env.webApiUrl,
			directApiUrl: this._env.directApiUrl,
			directWebApiUrl: this._env.directWebApiUrl,
			blobStoreUrl: this._env.blobStoreUrl,
			userEmail: '',
			userSubKey: '',
			appId: '',
			versionId: ''
		};

		this.setConfig(config);
	}

	/**
	 * @description
	 * Updates the config stream for all listeners.
	 */
	private _notify(): void {
		this._configsSubject.next(this._configs);
	}
}
