import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild
} from '@angular/core';

const IconSizes = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
    huge: 'hg'
};

@Component({
    selector: 'm-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
    @Input() disabled?: boolean = false;
    @Input() swallowEvents: boolean = false;
    @Input('m-icon-button-size') size: 'small' | 'medium' | 'large' | 'huge' = 'small';
    @Input('m-anchor-style') anchorStyle: boolean = false;
    @Input('text') text: string = null;
    @Input('icon') icon: string = null;
    @Input('aria-label') ariaLabel: string = null;
    @ViewChild('iconButton') public iconButton: ElementRef;

    public get iconClass(): string {
        const size = (this.size && IconSizes[this.size.toLocaleLowerCase()]) || 'sm';

        return `m-icon-${this.icon} m-icon-${size}`;
    }

    public swallowEvent(event: MouseEvent): void {
        if (this.swallowEvents) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    public focus(): void {
        this.iconButton.nativeElement.focus();
    }
}
