import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Intent } from '@luis/intents';
import { IUtteranceService, Utterance, UTTERANCE_SERVICE_TOKEN } from '@luis/utterances';
import { Observable } from 'rxjs';

@Component({
	selector: 'intent-inspector',
	templateUrl: './intent-inspector.component.html',
	styleUrls: ['./intent-inspector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntentInspectorComponent {
	@Input() public utterance: Utterance;
	@Input() public intents: Observable<Intent[]>;
	@Input() public isEditable: boolean;

	public editIntentMode: boolean = false;

	constructor(@Inject(UTTERANCE_SERVICE_TOKEN) private readonly _utteranceService: IUtteranceService) {}

	/**
	 * @description
	 * Edit the utterance or pattern with the given intent.
	 *
	 * @param data The utterance or pattern to update.
	 * @param intentName The intent name to change to.
	 */
	public editIntent(data: Utterance, intent: Intent): void {
		this._utteranceService.updateTestingUtterance(data, intent.name);
	}
}
