import { ICloneable } from '@luis/core';

/**
 * @description
 * Represents a class for the limits of the maximum allowed resources in an app.
 *
 * @param intents The maximum allowed intents in an app.
 * @param entities The maximum allowed entities in an app.
 * @param features The maximum allowed features in an app.
 * @param listEntities The maximum allowed list entities (subtype of entities) in an app.
 * @param listValues The maximum allowed list values in each list entity in an app.
 */
export class AppResourceLimits implements ICloneable {
    public intents: number;
    public entities: number;
    public features: number;
    public listEntities: number;
    public listValues: number;

    constructor(intents: number = 80, entities: number = 30, features: number = 10, listEntities: number = 50, listValues: number = 20000) {
        this.intents = intents;
        this.entities = entities;
        this.features = features;
        this.listEntities = listEntities;
        this.listValues = listValues;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): AppResourceLimits {
        if (!apiObject) {
            return new AppResourceLimits();
        } else {
            return new AppResourceLimits(
                apiObject.MaximumNumberOfIntents,
                apiObject.MaximumNumberOfEntities,
                apiObject.MaximumNumberOfFeatures,
                apiObject.MaximumNumberOfClosedLists,
                apiObject.MaximumNumberOfPhrasesPerClosedList
            );
        }
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): AppResourceLimits {
        return new AppResourceLimits(
            this.intents,
            this.entities,
            this.features,
            this.listEntities,
            this.listValues
        );
    }
}
