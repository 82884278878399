import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BUS_EVENTS, DirtyBitService, EventBusService, ProgressTracker } from '@luis/core';
import { BLADE_OPS, BladeComponent, BLADES, BladeTrackerService, DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, UP_ARROW } from '@luis/ui';
import { PredictionCacheService, Utterance } from '@luis/utterances';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';

@Component({
	selector: 'chat-blade',
	templateUrl: 'chat-blade.component.html',
	styleUrls: ['chat-blade.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBladeComponent extends BladeComponent implements OnInit, OnDestroy {
	@Input() public debugBladeId: string;
	@Output() public currentUtterance: EventEmitter<Utterance> = new EventEmitter<Utterance>();

	public utterances: BehaviorSubject<Utterance[]> = new BehaviorSubject<Utterance[]>([]);
	public currentDebugUtterance: BehaviorSubject<Utterance> = new BehaviorSubject<Utterance>(null);
	public hideTools: Observable<boolean>;
	public isAppDirty: Observable<boolean>;
	public predictTracker: ProgressTracker = new ProgressTracker();
	public predictionsTracker: ProgressTracker = new ProgressTracker();

	protected currentBlade: BLADES = BLADES.CHAT;

	private _trainSubscription: Subscription = new Subscription();

	constructor(
		private readonly _bladeTrackerService: BladeTrackerService,
		private readonly _dirtyBitService: DirtyBitService,
		private readonly _eventBusService: EventBusService,
		private readonly _predictionCacheServiceService: PredictionCacheService
	) {
		super(_bladeTrackerService);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this._initState();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this._trainSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Opens the batch testing console.
	 */
	public openBatchTesting(): void {
		this._bladeTrackerService.applyOp(BLADE_OPS.BATCH_TESTING_OPEN);
		this.closeBlade();
	}

	/**
	 * @description
	 * Runs a prediction on the text in the given text field.
	 *
	 * @param chatField The text field where the user types
	 * in the utterance to test.
	 */
	public predictUtterance(chatField: HTMLInputElement): void {
		if (chatField.value.trim() !== '') {
			this._predictionCacheServiceService
				.predict(chatField.value.trim(), { includePatterns: true, includeMultiIntents: true })
				.trackProgress(this.predictTracker.getTracker())
				.subscribe(u => {
					const utterances: Utterance[] = this.utterances.getValue();

					utterances.unshift(u);
					this.utterances.next(utterances);

					chatField.value = '';
					this.currentDebugUtterance.next(u);
					this.currentUtterance.emit(u);
				});
		}
	}

	/**
	 * @description
	 * Notifies parent to open the debug console with the given
	 * utterance as the debug context.
	 *
	 * @param utterance The utterance to set as the debug
	 * console context.
	 */
	public onUtteranceSelect(utterance: Utterance): void {
		this.currentDebugUtterance.next(utterance);
		this.currentUtterance.emit(utterance);
		this._bladeTrackerService.applyOp(BLADE_OPS.DEBUG_OPEN);
	}

	/**
	 * @description
	 * Clears the console.
	 */
	public resetConsole(): void {
		this.utterances.next([]);
		this.currentUtterance.emit(null);
	}

	/**
	 * @description
	 * Stop bubbling up the event in case of using arrow keys
	 */
	public onKeyDown(event: KeyboardEvent): void {
		if (event.keyCode === DOWN_ARROW || event.keyCode === UP_ARROW || event.keyCode === LEFT_ARROW || event.keyCode === RIGHT_ARROW) {
			event.stopPropagation();
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.isAppDirty = this._dirtyBitService.observe();

		this.hideTools = this._bladeTrackerService.getBladeStates().pipe(map(m => m.get(BLADES.DEBUG)));

		this._trainSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.TRAIN_OCCURRED, isUpToDate =>
			!isUpToDate ? this._refreshUtterancePredictions() : null
		);
	}

	/**
	 * @description
	 * Refreshes the predictions of the existing utterances in the chat
	 * blade whenever a training occurs.
	 */
	private _refreshUtterancePredictions(): void {
		const utterances: Utterance[] = this.utterances.getValue();
		const currentDebugUtterance: Utterance = this.currentDebugUtterance.getValue();
		const currentDebugUtteranceIndex: number = utterances.findIndex(u => u === currentDebugUtterance);

		if (!utterances.length) {
			return;
		}

		Observable.combineLatest(
			utterances.map(u => this._predictionCacheServiceService.predict(u.text, { includePatterns: true, includeMultiIntents: true }))
		)
			.trackProgress(this.predictionsTracker.getTracker())
			.subscribe(predictedUtterances => {
				this.utterances.next(predictedUtterances);
				this.currentDebugUtterance.next(predictedUtterances[currentDebugUtteranceIndex]);
				this.currentUtterance.emit(predictedUtterances[currentDebugUtteranceIndex]);
			});
	}
}
