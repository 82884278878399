const STORAGE_KEY = 'localization_lang';

export enum LANGS {
	EN = 'en',
	DE = 'de',
	ES = 'es',
	FR = 'fr',
	IT = 'it',
	ZH_HANS = 'zh-Hans'
}

export const persistLanguage = (lang: LANGS) => {
	localStorage.setItem(STORAGE_KEY, lang);
};

export const retrieveLanguage = (): LANGS => <LANGS>localStorage.getItem(STORAGE_KEY) || getBrowserLanguage();

const getBrowserLanguage = () => {
	if ('language' in navigator) {
		if (navigator.language.includes('en')) return LANGS.EN;
		if (navigator.language.includes('de')) return LANGS.DE;
		if (navigator.language.includes('es')) return LANGS.ES;
		if (navigator.language.includes('fr')) return LANGS.FR;
		if (navigator.language.includes('it')) return LANGS.IT;
		if (navigator.language.includes('zh')) return LANGS.ZH_HANS;
	}

	return LANGS.EN;
};
