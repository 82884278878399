import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { BaseService } from '../services/utils/base.service';

/**
 * @description
 * Directive that takes in the blobPath, constructs the full blob url and sets
 * it as the image of the target element.
 */
@Directive({
	selector: '[blobPath]'
})
export class BlobPathDirective implements OnInit {
	@Input() public blobPath: string;
	@Input() public targetProp: 'src' | 'backgroundImage' = 'src';

	constructor(private readonly _baseService: BaseService, private readonly _el: ElementRef) {}

	public ngOnInit(): void {
		const url = `${this._baseService.configs.blobStoreUrl}/${this.blobPath}`;

		if (this.targetProp === 'src') {
			this._el.nativeElement.src = url;

			return;
		}

		this._el.nativeElement.style.backgroundImage = `url(${url})`;
	}
}
