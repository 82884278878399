import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_SERVICE_TOKEN, IAppService } from '@luis/apps';
import { IToasterService, ProgressTracker, SelectionMap, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { Entity, ENTITY_SERVICE_TOKEN, ENTITY_TYPES, EntityUtilitiesService, IEntityService } from '@luis/entities';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, first, flatMap, map } from 'rxjs/operators';
import { DOMAIN_SERVICE_TOKEN, IDomainService } from '../../interfaces/IDomainService';
import { Domain, DomainModel } from '../../models/domain.model';

/**
 * @description
 * Represents the modal for listing and adding prebuilt domain entities.
 */
@Component({
	selector: 'domain-entities-modal',
	templateUrl: 'domain-entities-modal.component.html',
	styleUrls: ['domain-entities-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainEntitiesModalComponent implements OnInit {
	public domainEntitiesToDisplay: Observable<DomainModel[]>;
	public isValid: Observable<boolean>;
	public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public tracker: ProgressTracker = new ProgressTracker();
	public selectionMap: SelectionMap = new SelectionMap();

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _entityUtilService: EntityUtilitiesService,
		private readonly _dialogRef: MatDialogRef<DomainEntitiesModalComponent>,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(DOMAIN_SERVICE_TOKEN) private readonly _domainService: IDomainService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Updates the search query stream with the new entered query.
	 */
	public onQueryChange(query: string): void {
		this.querySubject.next(query.trim());
	}

	/**
	 * @description
	 * Updates the selection map when a checkbox value changes.
	 *
	 * @param domainEntityName The domain entity name of the checkbox that changed.
	 */
	public onCheckboxChange(domainEntityName: string): void {
		if (this.selectionMap.isSelected(domainEntityName)) {
			this.selectionMap.markUnselected([domainEntityName]);
		} else {
			this.selectionMap.markSelected([domainEntityName]);
		}
	}

	/**
	 * @description
	 * Adds the selected entities to the application.
	 */
	public onDoneClick(): void {
		const domainEntities: Entity[] = this.selectionMap.selectedItems
			.map(name => (<string>name).split('.'))
			.map(names => new Entity('', `${names[0]}.${names[1]}`, ENTITY_TYPES.DOMAIN, 0, [], null, names[0], names[1]));

		this.inProgress.next(true);

		combineLatest(domainEntities.map(i => this._entityService.add(i)))
			.pipe(
				flatMap(() => this._entityUtilService.getLabelsPerEntity(true)),
				finalize(() => this.inProgress.next(false))
			)
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('domains.domain-entities-modal.adding_entity_toast') }))
			.subscribe(() => this._dialogRef.close());
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.domainEntitiesToDisplay = this._appService
			.getSingle()
			.pipe(
				first(),
				flatMap(app => Observable.combineLatest(this._entityService.get(), this._domainService.get(app.culture))),
				map(([entities, domains]) => this._filterDomainEntities(entities, domains))
			)
			.trackProgress(this.tracker.getTracker());

		this.isValid = this.selectionMap.selectedItemsAsync.map(ids => ids.length !== 0);
	}

	/**
	 * @description
	 * Filters the domain entities to display based on what of them have
	 * already been added before.
	 *
	 * @param entities The entities that are added in the application.
	 * @param domains The list of domains that are available.
	 * @returns The domain entities while ensuring that they are not already added.
	 */
	private _filterDomainEntities(entities: Entity[], domains: Domain[]): DomainModel[] {
		const addedDomainEntities: Entity[] = entities.filter(i => i.domainName !== null);
		const filteredDomainEntities: DomainModel[] = domains
			.reduce((acc, curr) => acc.concat(curr.entities), [])
			.filter(dE => addedDomainEntities.find(e => e.uniqueName === dE.uniqueName) === undefined);

		this.selectionMap.setMapItems(filteredDomainEntities.map(dE => dE.uniqueName));

		return filteredDomainEntities;
	}
}
