import { Entity, ENTITY_TYPES } from './entity.model';

/**
 * @description
 * Represents a regex entity. A regex entity is a special LUIS entity
 * that is not machined learned, instead it matches using a regular expression.
 *
 * @param regex The regular expression of the entity.
 */
export class RegexEntity extends Entity {
    public regex: string;

    constructor(id: string = '', name: string = '', regex: string = '', parent: Entity = null) {
        super(id, name, ENTITY_TYPES.REGEX, 0, [], parent);
        this.regex = regex;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): RegexEntity {
        const regexEntity: RegexEntity = <RegexEntity>Entity.constructBaseEntity(apiObject, new RegexEntity());
        regexEntity.labelCount = null;
        regexEntity.regex = apiObject.regexPattern;

        return regexEntity;
    }

    /**
     * @method
     * @description
     * Checks if the regex entity is valid or not.
     *
     * @returns True if valid and false otherwise.
     */
    public isValid(): boolean {
        const entityRegexLength: number = this.regex.trim().length;

        return entityRegexLength > 0 && entityRegexLength <= 500 && super.isValid();
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            id: this.id,
            name: this.name,
            regexPattern: this.regex
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @param entityParent The parent to use instead of the object's current parent,
     * used in case the parent is cloned so child references the new parent.
     * @returns A deep clone of this object.
     */
    public clone(entityParent?: Entity): RegexEntity {
        const regexEntity: RegexEntity = <RegexEntity>Entity.cloneBaseEntity(this, new RegexEntity(), entityParent);
        regexEntity.regex = this.regex.slice();

        return regexEntity;
    }
}
