import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, FileService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService, PaginationResourceCacheService } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { APP_SERVICE_TOKEN, IAppService } from '../interfaces/IAppService';
import { IAppUtilitiesService } from '../interfaces/IAppUtilitiesService';
import { APP_VERSION_SERVICE_TOKEN, IAppVersionService } from '../interfaces/IAppVersionService';
import { AppCulture } from '../models/app-culture.model';
import { AppResourceLimits } from '../models/app-resource-limits.model';
import { App } from '../models/app.model';

/**
 * @description
 * Represents a service class for utlity functions for applications. Utility functions are
 * functions other than the main CRUD operations, such like importing, exporting, assigning keys, etc.
 */
@Injectable()
export class AppUtilitiesService implements IAppUtilitiesService {
	constructor(
		private readonly _baseService: BaseService,
		private readonly _fileService: FileService,
		private readonly _paginationCacheService: PaginationResourceCacheService<App>,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _appVersionService: IAppVersionService
	) {}

	/**
	 * @description
	 * Gets the base web url for manipulating apps.
	 *
	 * @returns The base url for apps manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps`;
	}

	/**
	 * @description
	 * Gets the list of cultures LUIS supports.
	 *
	 * @returns An observable of the supported cultures.
	 */
	public getCultures(): Observable<AppCulture[]> {
		const url: string = `${this._baseUrl}/cultures`;
		const parser = (cultures: Object[]) => cultures.map(culture => AppCulture.importFromApi(culture));

		return this._cacheService.get(url, parser, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Imports the application JSON string to the system.
	 *
	 * @param appJsonString The application JSON string.
	 * @param name An optional app name.
	 * @returns Returns an observable of the application id.
	 */
	public importApp(appJsonString: string, name: string = ''): Observable<App> {
		let url: string = `${this._baseUrl}/import?subscription-key=${this._baseService.configs.userSubKey}`;
		const options = this._baseService.defaultOptionsBuilder.build();

		if (name) {
			url = `${url}&appName=${name}`;
		}

		return this._fileService.upload<string>(url, appJsonString, options).flatMap(appId => this._appService.getSingle(appId).first());
	}

	/**
	 * @description
	 * Uploads the given CSV logs to the app.
	 *
	 * @param app The app to upload the unlabeled data to.
	 * @param logsCsvFile The logs csv file.
	 * @returns An observable to indicate completion.
	 */
	public importLogs(app: App, logsCsvFile: string): Observable<void> {
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${app.id}/unlabeled`;
		const options = this._baseService.rawFileOptionsBuilder.build();

		const formData = new FormData();
		formData.set('file', logsCsvFile);

		return this._fileService.upload(url, formData, options);
	}

	/**
	 * @description
	 * Gets the given application's export url. The application version is decided either by current
	 * active version set in the app or the last modified version for the app.
	 *
	 * @param app The application to export.
	 * @returns An observable of the export Url.
	 */
	public exportApp(app: App): Observable<void> {
		let versionIdObservable: Observable<string>;
		const options = this._baseService.defaultOptionsBuilder.build();

		if (app.activeVersion.trim().length) {
			versionIdObservable = Observable.of(app.activeVersion);
		} else {
			versionIdObservable = this._appVersionService
				.get(0, app, true)
				.filter(versions => versions.data.length !== 0)
				.first()
				.map(versions => versions.data.sort((l, r) => (l.modifiedDate < r.modifiedDate ? 1 : -1)))
				.map(sortedVersions => sortedVersions[0].id);
		}

		return versionIdObservable
			.map(versionId => `${this._baseUrl}/${app.id}/versions/${versionId}`)
			.map(baseUrl => `${baseUrl}/export?subscription-key=${this._baseService.configs.userSubKey}`)
			.flatMap(url => this._fileService.download(url, `${app.name}.json`, options));
	}

	/**
	 * @description
	 * Export the app for container.
	 *
	 * @param app The app to export.
	 * @param isStaging The slot to export.
	 * @returns An observable to indicate completion.
	 */
	public exportForContainer(app: App, isStaging: boolean): Observable<void> {
		const options = this._baseService.defaultOptionsBuilder.build();

		return this._fileService.download(
			`${this._baseService.configs.webApiUrl}/package/${app.id}/slot/${isStaging ? 'STAGING' : 'PRODUCTION'}/gzip`,
			`${app.id}_${isStaging ? 'STAGING' : 'PRODUCTION'}.gz`,
			options,
			false
		);
	}

	/**
	 * @description
	 * Gets the given application's export logs url.
	 *
	 * @param app The application to export.
	 * @returns The export logs url.
	 */
	public getExportLogsUrl(app: App): Observable<string> {
		const createdStatus: number = 201;
		const url: string = `${this._baseUrl}/${app.id}/queryLogsasync?subscription-key=${this._baseService.configs.userSubKey}`;

		return this._httpService.post(url, undefined, this._baseService.defaultOptionsBuilder.build()).flatMap(() =>
			Observable.interval(2000)
				.flatMap(() => this._httpService.get<void>(url, this._baseService.defaultOptionsBuilder.build(), true))
				.filter(response => response.status === createdStatus)
				.first()
				.map(() => url)
		);
	}

	/**
	 * @description
	 * Gets the limits for an application's resources.
	 *
	 * @returns An observable of the app limits.
	 */
	public getAppLimits(): Observable<AppResourceLimits> {
		const url: string = `${this._baseService.configs.webApiUrl}/limits`;

		return this._cacheService.get(url, AppResourceLimits.importFromApi, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Sets the given version to the given application as the active one.
	 *
	 * @param app The app to set the version for.
	 * @param versionId The version to set.
	 * @returns An observable to indicate completion.
	 */
	public setAppActiveVersion(app: App, versionId: string): Observable<void> {
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${app.id}/activeversion`;
		const cacheUrl: string = `${this._baseService.configs.apiUrl}/apps`;

		app.activeVersion = versionId;

		return this._httpService
			.put(url, JSON.stringify(versionId), this._baseService.defaultOptionsBuilder.build())
			.flatMap(() =>
				this._paginationCacheService.replace(
					cacheUrl,
					cacheUrl,
					app,
					app.id,
					this._baseService.optionsBuilder.useCacheOnly().build()
				)
			)
			.mapTo(null);
	}
}
