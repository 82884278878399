import { Inject, Injectable } from '@angular/core';
import { CUSTOM_HEADERS, HTTP_SERVICE_TOKEN, IHttpService, IRequestOptions } from '@luis/api';
import { Observable } from 'rxjs/Rx';
import { IApiExportable } from '../../interfaces/models/IApiExportable';
import { IGenericCacheService, ReturnTypeEnhanced } from '../../interfaces/services/caches/IGenericCacheService';
import { GenericCache } from '../../models/caches/generic-cache.model';
import { DirtyBitService } from './../utils/dirty-bit.service';

/**
 * @description
 * Represents a concrete implementation for the generic cache service interface. This implementation
 * is meant for production usage.
 */
@Injectable()
export class GenericCacheService implements IGenericCacheService {
	private readonly _cacheMap: Map<string, GenericCache>;

	constructor(private _dirtyBitService: DirtyBitService, @Inject(HTTP_SERVICE_TOKEN) private _httpService: IHttpService) {
		this._cacheMap = new Map<string, GenericCache>();
	}

	/**
	 * @description
	 * Gets the resource with the given path.
	 *
	 * @param path The path to call.
	 * @param parser The callback to use to parse the api response.
	 * @param options Options to pass to the http service.
	 * @returns An observable of the cache object.
	 */
	public get<T extends (...args: any[]) => any | null>(
		path: string,
		parser: T,
		options: IRequestOptions
	): Observable<ReturnTypeEnhanced<T>> {
		let cache: GenericCache;
		if (!this._checkForCache(path) || options.headers.has(CUSTOM_HEADERS.FORCE_CACHE_REFRESH)) {
			if (options.headers.has(CUSTOM_HEADERS.CACHE_ONLY)) {
				return Observable.of(undefined);
			}

			cache = this._getCache(path);

			this._httpService
				.get(path, options)
				.map(response => parser(response))
				.subscribe(
					data => cache.set(data),
					error => {
						this._cacheMap.delete(path);
						cache.throwStreamError(error);
					}
				);
		} else {
			cache = this._getCache(path);
		}

		return <Observable<any>>cache.get();
	}

	/**
	 * @description
	 * Posts a new object to the given path. Caches the added object on success.
	 *
	 * @param path The path to call.
	 * @param data The data to add. Must implement IApiExportable interface.
	 * @param options Options to pass to the http service.
	 * @returns An observable to indicate completion.
	 */
	public post(path: string, data: IApiExportable | any, options: IRequestOptions): Observable<void> {
		const cache: GenericCache = this._getCache(path);
		const exportedData: any = data.exportToApi ? data.exportToApi() : data;
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		if (options.headers.has(CUSTOM_HEADERS.CACHE_ONLY)) {
			return Observable.of(cache.set(data));
		}

		return this._httpService
			.post<void>(path, exportedData, options)
			.map(() => cache.set(data))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	/**
	 * @description
	 * Updates an existing object to the given path. Caches the updated object on success.
	 *
	 * @param path The path to call.
	 * @param data The data to add. Must implement IApiExportable interface.
	 * @param options Options to pass to the http service.
	 * @returns An observable indicating completion.
	 */
	public put(path: string, data: IApiExportable | any, options: IRequestOptions): Observable<void> {
		const cache: GenericCache = this._getCache(path);
		const exportedData: any = data.exportToApi ? data.exportToApi() : data;
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		if (options.headers.has(CUSTOM_HEADERS.CACHE_ONLY)) {
			return Observable.of(cache.set(data));
		}

		return this._httpService
			.put(path, exportedData, options)
			.map(() => cache.set(data))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	/**
	 * @description
	 * Deletes an existing object from the given path. Deletes the object cache itself on success.
	 *
	 * @param path The path to call.
	 * @param options Options to pass to the http service.
	 * @returns An observable indicating whether a cache object was actually deleted.
	 */
	public delete(path: string, options: IRequestOptions): Observable<boolean> {
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		if (options.headers.has(CUSTOM_HEADERS.CACHE_ONLY)) {
			return Observable.of(this._cacheMap.delete(path));
		}

		return this._httpService
			.delete(path, options)
			.map(() => this._cacheMap.delete(path))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	public invalidate(path: string): void {
		this._cacheMap.delete(path);
	}

	/**
	 * @description
	 * Gets the cache for the path given. If no cache exists, it is created.
	 *
	 * @param path The path for the resource that is saved in this cache.
	 * @returns A generic cache for the path given.
	 */
	private _getCache(path: string): GenericCache {
		if (!this._cacheMap.has(path)) {
			this._cacheMap.set(path, new GenericCache());
		}

		return this._cacheMap.get(path);
	}

	/**
	 * @description
	 * Checks for the existence of a cache for the specified path.
	 *
	 * @param path A url path for the cache resource.
	 * @returns True if the cache exists and false otherwise.
	 */
	private _checkForCache(path: string): boolean {
		return this._cacheMap.has(path);
	}

	/**
	 * @description
	 * Marks the current app as dirty.
	 */
	private _markDirty(): void {
		this._dirtyBitService.setDirty(true);
	}
}
