export const FOCUS_TRAP_ATTRIBUTE: string = 'focusTrap';

export type SEARCH_DIRECTION = 'next' | 'prev';

/**
 * @description
 * Gets all the focusable DOM elements that are children to the given root element.
 *
 * @param el The current element that is being iterated on (its children being checked).
 * @param root The root element where the search started.
 * @param excludeFromSearch A list of attributes that if an element has, should be excluded
 * from the search and its children.
 */
export function getFocusableItems(
    el: HTMLElement,
    root: HTMLElement,
    excludeFromSearch: string[] = [FOCUS_TRAP_ATTRIBUTE]
): HTMLElement[] {
    let output = [];

    // If element is not visible, don't bother checking its children.
    if (!el || !isElementVisible(el)) {
        return output;
    }

    if (isElementFocusable(el) && el !== root) {
        output.push(el);
    }

    // Checking if the element is the root is basically checking if this is
    // the first iteration if the user started the search from the root element that
    // they provided, which is usually the case. In many times, the root element itself
    // has one of the non allowed attributes, eg: focusTrap, which would cause the condition
    // to not fire and the function exit. In order to allow searching root element children
    // where root elements may be focus trap elements or have any other non allowed attribute,
    // I check if this is the first iteration of recursion.
    if (!elementHasAnyAttrs(el, excludeFromSearch) || el === root) {
        for (let i = 0; i < el.children.length; i = i + 1) {
            output = output.concat(getFocusableItems(<HTMLElement>el.children.item(i), root));
        }
    }

    return output;
}

/**
 * @description
 * Gets the element that should be focused next after the given
 * element. The "next" element is based on the direction of the
 * focus search should be.
 *
 * @param el The current element being searched. In the first iteration
 * it has the same value as the root parameter.
 * @param root The first parameter that was searched from the beginning
 * of the recursion process.
 * @param isFirstItr A flag to indicate whether this is the first iteration
 * or not. If this is the first iteration, we skip comparisons between the
 * root element and the current element, which is used to detected if the
 * search cycled the tree or not. But the first iteration is to be skipped
 * because the root and current elements will always be equal then.
 * @param direction The direction the search should go.
 * @param skipChildren A flag to whether skip searching the children of a
 * node or not. This is needed because due to the preorder nature of this
 * traversal function, all children are visited before the parent is accessed,
 * that means that the parent will probably not need to search its children
 * again when visited for the first time, thus this flag is needed.
 * @param includeFocusZoneChildren A flag to indicate whether to include children
 * in any focus zones found in the search or not.
 * @returns The first HTML element that is focusable 'after' the current element,
 * where the direction of 'after' depends on the direction parameter.
 */
export function getFollowingFocusableItem(
    el: HTMLElement,
    root: HTMLElement,
    isFirstItr: boolean,
    direction: SEARCH_DIRECTION = 'next',
    skipChildren: boolean = false,
    excludeFromSearch: string[] = [FOCUS_TRAP_ATTRIBUTE]
): HTMLElement {
    // If the element is non existent or the tree has been searched enough
    // to do a full cycle and reach the same start element again, then
    // no other focusable elements were found, return null.
    if (!el || (!isFirstItr && el === root)) {
        return null;
    }

    // If this elemenet is a candidate for focus, then our job is done.
    if (el !== root && isElementVisible(el) && isElementFocusable(el)) {
        return el;
    }

    // Try searching for focusable elements in the children. Note that we
    // always start with first/last child, other children will be traversed
    // by themselves via next/prev sibling iteration.
    if (!skipChildren && isElementVisible(el) && (!elementHasAnyAttrs(el, excludeFromSearch))) {
        const childMatch = getFollowingFocusableItem(
            direction === 'next' ? <HTMLElement>el.firstElementChild : <HTMLElement>el.lastElementChild,
            root,
            false,
            direction,
            false,
            excludeFromSearch
        );

        if (childMatch) {
            return childMatch;
        }
    }

    // If I found no matches in my children, move on to the next sibling and
    // try searching it recursively.
    const siblingMatch = getFollowingFocusableItem(
        direction === 'next' ? <HTMLElement>el.nextElementSibling : <HTMLElement>el.previousElementSibling,
        root,
        false,
        direction,
        false,
        excludeFromSearch
    );

    if (siblingMatch) {
        return siblingMatch;
    }

    // This will be reached only when all children have been iterated over already,
    // so we pass false in the skip Children flag to avoid searching the same children
    // again when the parent that we'll call starts being searched. The only case
    // where the skip children flag is to be sent true in this case is if the
    // parent of this element is document body element, that means the root of
    // the document has been reached and we need to start searching the elements
    // from the root down, which also marks the beginning of the search "rotation"
    // about the tree.
    const parentMatch = getFollowingFocusableItem(
        <HTMLElement>el.parentElement,
        root,
        false,
        direction,
        el.parentElement === document.body ? false : true,
        excludeFromSearch
    );

    if (parentMatch) {
        return parentMatch;
    }
}

/**
 * @description
 * Checks if the given element is focusable via keyboard or not.
 *
 * @param el The element to check.
 */
export function isElementFocusable(el: HTMLElement): boolean {
    if (!el || (<HTMLButtonElement>el).disabled || !isElementVisible(el)) {
        return false;
    }

    let tabIndex = 0;
    let tabIndexAttributeValue = null;

    if (el && el.getAttribute) {
        tabIndexAttributeValue = el.getAttribute('tabIndex');

        if (tabIndexAttributeValue) {
            tabIndex = parseInt(tabIndexAttributeValue, 10);
        }
    }

    const isTabIndexSet = tabIndexAttributeValue !== null && tabIndex >= 0;
    const tags = ['A', 'BUTTON', 'INPUT', 'TEXTAREA'];

    return !!el && (tags.indexOf(el.tagName) !== -1 || isTabIndexSet || (el.getAttribute && el.getAttribute('role') === 'button'));
}

/**
 * @description
 * Checks if the given element is visible in the DOM or not.
 *
 * @param el The element to check.
 */
export function isElementVisible(el: HTMLElement): boolean {
    if (!el || !el.style) {
        throw new Error('Element must be a defined HTML element.');
    }

    const elStyles = window.getComputedStyle(el);

    if (elStyles.display === 'none' || elStyles.visibility === 'hidden') {
        return false;
    }

    return el.offsetHeight !== 0 || el.offsetParent !== null;
}

/**
 * @description
 * Checks if the element has any of the attributes given.
 *
 * @param element The element to check.
 * @param attrs The attributes to check the element has or not.
 * @returns True if the element has any of the attributes and
 * false otherwise.
 */
export function elementHasAnyAttrs(element: Element, attrs: string[]): boolean {
    if (attrs === undefined || attrs.length === 0) {
        throw new Error('Attributes must be defined');
    }

    for (const attr of attrs) {
        if (element && element.hasAttribute && element.hasAttribute(attr)) {
            return true;
        }
    }

    return false;
}
