import { Injectable } from '@angular/core';
import { BaseService, ENVIRONMENT } from '@luis/core';
import { IKeyHelpersService, RegionsMap } from '../interfaces/IKeyHelpersService';
import { CONTINENT } from '../models/continent.model';

@Injectable()
export class KeyHelpersService implements IKeyHelpersService {
	constructor(private readonly _baseService: BaseService) {}

	/**
	 * @description
	 * Gets constant information for the current environment we are in.
	 */
	public getDomainInfo(): { region: string; continent: CONTINENT; timeZone: number } {
		const env: ENVIRONMENT = this._baseService.configs.env;

		switch (env) {
			case ENVIRONMENT.CENTRALUS:
			default:
				return {
					region: 'westus',
					continent: CONTINENT.US,
					timeZone: -6
				};
			case ENVIRONMENT.WESTEUROPE:
				return {
					region: 'westeurope',
					continent: CONTINENT.EU,
					timeZone: 1
				};
			case ENVIRONMENT.AUSTRALIAEAST:
				return {
					region: 'australiaeast',
					continent: CONTINENT.AU,
					timeZone: 10
				};
			case ENVIRONMENT.USGOVVIRGINIA:
				return {
					region: 'usgovvirginia',
					continent: CONTINENT.US,
					timeZone: -4
				};
			case ENVIRONMENT.CHINAEAST2:
				return {
					region: 'chinaeast2',
					continent: CONTINENT.ASIA,
					timeZone: 8
				};
		}
	}

	/**
	 * @description
	 * Gets the regions that belong to each continent.
	 */
	public getContinentRegions(): Map<CONTINENT, string[]> {
		const continentsDirectory: Map<number, string[]> = new Map<CONTINENT, string[]>();

		continentsDirectory.set(CONTINENT.US, [
			'westus',
			'westus2',
			'eastus',
			'eastus2',
			'westcentralus',
			'southcentralus',
			'northcentralus',
			'centraluseuap',
			'canadacentral',
			'centralus',
			'usgovvirginia'
		]);
		continentsDirectory.set(CONTINENT.EU, ['westeurope', 'northeurope', 'uksouth', 'francecentral']);
		continentsDirectory.set(CONTINENT.ASIA, [
			'eastasia',
			'southeastasia',
			'koreacentral',
			'japaneast',
			'japanwest',
			'centralindia',
			'chinaeast2'
		]);
		continentsDirectory.set(CONTINENT.AU, ['australiaeast']);
		continentsDirectory.set(CONTINENT.BRAZIL, ['brazilsouth']);
		continentsDirectory.set(CONTINENT.ALL, Array.from(continentsDirectory.values()).reduce((acc, val) => acc.concat(val), []));

		return continentsDirectory;
	}

	public getReadableRegionNameMap(): RegionsMap {
		return {
			westus: 'West US',
			eastus: 'East US',
			westus2: 'West US 2',
			eastus2: 'East US 2',
			eastasia: 'East Asia',
			centralus: 'Central US',
			japaneast: 'Japan East',
			japanwest: 'Japan West',
			brazilsouth: 'Brazil South',
			centralindia: 'Central India',
			koreacentral: 'Korea Central',
			canadacentral: 'Canada Central',
			southeastasia: 'South East Asia',
			westcentralus: 'West Central US',
			southcentralus: 'South Central US',
			northcentralus: 'North Central US'
		};
	}
}
