import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { Consent, CookieConsentService } from '@luis/core';
import { Cookie } from 'ng2-cookies';

@Component({
	selector: 'cookie-consent',
	templateUrl: 'cookie-consent.component.html'
})
export class CookieConsentComponent implements OnInit {
	public markup: SafeHtml;
	public css: SafeStyle;
	public isVisible: boolean = false;

	private readonly _delay: number = 1000;
	private _consent: Consent;

	constructor(private readonly _sanitizer: DomSanitizer, private readonly _consentService: CookieConsentService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Approves the consent by setting a cookie and hiding the message.
	 */
	public approveConsent(): void {
		this._setCookie(this._consent);
		this.isVisible = false;
		this._consentService.setConsent(true);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._consentService.checkConsent().subscribe(consent => {
			this._consent = consent;

			if (!this._consent.isConsentRequired) {
				this.isVisible = false;
			} else {
				this.isVisible = !Cookie.check(consent.cookieName);
			}

			if (this.isVisible) {
				this.css = this._sanitizer.bypassSecurityTrustResourceUrl(consent.css);
				this.markup = this._sanitizer.bypassSecurityTrustHtml(consent.markup);
				setTimeout(() => this._createScriptElement(consent.js), this._delay);
			} else {
				setTimeout(() => this._consentService.setConsent(true), this._delay);
			}
		});
	}

	/**
	 * @description
	 * Sets a cookie to signal consent.
	 *
	 * @param consent The consent object.
	 */
	private _setCookie(consent: Consent): void {
		const now: Date = new Date();
		now.setMonth(now.getMonth() + 13);
		Cookie.set(consent.cookieName, 'true', now);
	}

	/**
	 * @description
	 * Creates a script tag and appends it on the website.
	 *
	 * @param srcUrl The source url of the script to append.
	 */
	private _createScriptElement(srcUrl: string): void {
		const script: HTMLScriptElement = document.createElement('script');
		script.src = srcUrl;
		document.head.appendChild(script);
	}
}
