import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { ITrainingStatus, SERVICE_STATUS } from '../../../models/utils/training.model';

export const TRAINING_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('ITrainingService');

/**
 * @description
 * Represents an interface for the training service operations.
 */
export interface ITrainingService {
	initializeTrainingStatus(status: boolean): void;

	appTrainedStatus(): Observable<boolean>;

	serviceStatus(): Observable<SERVICE_STATUS>;

	trainStatus(): Observable<ITrainingStatus>;

	train(): void;
}
