import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IPhraseListService, PHRASE_LIST_SERVICE_TOKEN } from '../../../interfaces/IPhraseListService';
import { PhraseList } from '../../../models/phrase-list.model';
import { Suggestion } from '../../../models/suggestion.model';

interface IPhraseListModalData {
	showSuggestions: boolean;

	phraseList: PhraseList;
}

/**
 * @description
 * Represents a phrase list modal. Contains form controls to create and edit phrase lists.
 */
@Component({
	selector: 'phrase-list-creation',
	templateUrl: 'phrase-list-creation-modal.component.html',
	styleUrls: ['phrase-list-creation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhraseListCreationModalComponent {
	public isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public previousSuggestion: Suggestion;

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<PhraseListCreationModalComponent>,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(PHRASE_LIST_SERVICE_TOKEN) private readonly _phraseListService: IPhraseListService,
		@Inject(MAT_DIALOG_DATA) public readonly data: IPhraseListModalData = { showSuggestions: false, phraseList: new PhraseList() }
	) {}

	/**
	 * @description
	 * Triggered when the phrase list changes in any way (name or phrases). Validates
	 * the phrase list.
	 */
	public onPhraseListChange(): void {
		this.isValid.next(this._isPhraseListValid());
	}

	/**
	 * @description
	 * Creates or updates the phrase list based on whether
	 * it was new or not.
	 */
	public submitPhraseList(): void {
		this.data.phraseList.previousSuggestion = this.previousSuggestion;
		this.inProgress.next(true);
		this.data.phraseList.phrases = this._removeDuplicatePhrases(this.data.phraseList.phrases);

		if (this.data.phraseList.id === null) {
			this._phraseListService
				.add(this.data.phraseList)
				.pipe(finalize(() => this.inProgress.next(false)))
				.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('features.phrase-list-creation-modal.add_toast') }))
				.subscribe(id => {
					this.data.phraseList.id = id;
					this._dialogRef.close(this.data.phraseList);
				});
		} else {
			this._phraseListService
				.update(this.data.phraseList)
				.pipe(finalize(() => this.inProgress.next(false)))
				.trackProgress(
					this._toasterService.add({ startMsg: this._i18n.instant('features.phrase-list-creation-modal.update_toast') })
				)
				.subscribe(() => this._dialogRef.close(this.data.phraseList));
		}
	}

	/**
	 * @description
	 * Toggles selection of interchangeability of the phrase list.
	 */
	public toggleSelection(): void {
		this.data.phraseList.mode = !this.data.phraseList.mode;
	}

	/**
	 * @description
	 * Clears duplicate words from the phrase.
	 *
	 * @param phrases The phrases to submit.
	 * @returns The phrases with any duplicates removed.
	 */
	private _removeDuplicatePhrases(phrases: string): string {
		const uniqifier: Set<string> = new Set<string>(phrases.split(',').map(p => p.toLocaleLowerCase()));

		return Array.from(uniqifier.values()).join(',');
	}

	/**
	 * @description
	 * Checks if the phrase list is valid or not.
	 *
	 * @returns True if valid and false otherwise.
	 */
	private _isPhraseListValid(): boolean {
		return this.data.phraseList.name.trim().length > 0 && this.data.phraseList.phrases.trim().length > 0;
	}
}
