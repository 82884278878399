/**
 * @description
 * Represents a time zone supported by LUIS endpoints.
 */
export interface ITimeZone {
    text: string;

    value: number;
}

/**
 * @description
 * A listing of the supported time zones in LUIS.
 */
export const timeZones: ITimeZone[] = [
    { text: 'GMT -12:00', value: -12 },
    { text: 'GMT -11:00', value: -11 },
    { text: 'GMT -10:00', value: -10 },
    { text: 'GMT -9:00', value: -9 },
    { text: 'GMT -8:00', value: -8 },
    { text: 'GMT -7:00', value: -7 },
    { text: 'GMT -6:00', value: -6 },
    { text: 'GMT -5:00', value: -5 },
    { text: 'GMT -4:00', value: -4 },
    { text: 'GMT -3:30', value: -3.5 },
    { text: 'GMT -3:00', value: -3 },
    { text: 'GMT -2:00', value: -2 },
    { text: 'GMT -1:00', value: -1 },
    { text: 'GMT  0:00', value: 0 },
    { text: 'GMT +1:00', value: 1 },
    { text: 'GMT +2:00', value: 2 },
    { text: 'GMT +3:00', value: 3 },
    { text: 'GMT +3:30', value: 3.5 },
    { text: 'GMT +4:00', value: 4 },
    { text: 'GMT +4:30', value: 4.5 },
    { text: 'GMT +5:00', value: 5 },
    { text: 'GMT +5:30', value: 5.5 },
    { text: 'GMT +5:45', value: 5.75 },
    { text: 'GMT +6:00', value: 6 },
    { text: 'GMT +7:00', value: 7 },
    { text: 'GMT +8:00', value: 8 },
    { text: 'GMT +9:00', value: 9 },
    { text: 'GMT +9:30', value: 9.5 },
    { text: 'GMT +10:00', value: 10 },
    { text: 'GMT +11:00', value: 11 },
    { text: 'GMT +12:00', value: 12 }
];
