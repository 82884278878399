import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BaseItemFilter, IDataItem, LuisModel, ProgressTracker, SelectionMap } from '@luis/core';
import { Entity, ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IItemTableToolbarOps } from '../../interfaces/IItemTableToolbarOps';
import { Pattern } from '../../models/pattern.model';
import { PatternStoreService } from '../../services/pattern-store.service';

@Component({
    selector: 'pattern-table',
    templateUrl: 'pattern-table.component.html',
    styleUrls: ['pattern-table.component.scss'],
    providers: [PatternStoreService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternTableComponent implements OnInit {
    @Input() public modelId: Observable<string> = Observable.of('');
    @Input() public pageSize: number = 10;
    @Input() public fetchSize: number = 10000;
    @Output() public editEntity: EventEmitter<Entity> = new EventEmitter<Entity>();

    public items: Observable<IDataItem[]>;
    public intents: Observable<Intent[]>;
    public entities: Observable<Entity[]>;
    public toolbarOps: Observable<IItemTableToolbarOps>;
    public query: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public filters: BehaviorSubject<BaseItemFilter[]> = new BehaviorSubject<BaseItemFilter[]>([]);

    public currentPage: number;
    public selectionMap: SelectionMap = new SelectionMap();

    public fetchTracker: ProgressTracker = new ProgressTracker();
    public submitTracker: ProgressTracker = new ProgressTracker();
    public predictionsTracker: ProgressTracker = new ProgressTracker();

    public haveNoItems: Observable<boolean>;

    constructor(
        private _patternStoreService: PatternStoreService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService,
        @Inject(INTENT_SERVICE_TOKEN) private _intentService: IIntentService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Submits the item given using the item store.
     *
     * @param item The item to submit.
     */
    public onPatternSubmitted(item: Pattern): void {
        this._patternStoreService
            .submitItem(item)
            .trackProgress(this.submitTracker.getTracker())
            .subscribe(() => null);
    }

    /**
     * @description
     * Reassigns the selected items to the given intent.
     *
     * @param intent The intent to reassign to.
     */
    public batchReassignItems(intent: Intent): void {
        this._patternStoreService.batchReassign(intent).subscribe(() => null);
    }

    /**
     * @description
     * Deletes the selected items.
     */
    public batchDeleteItems(): void {
        this._patternStoreService.batchDelete().subscribe(() => null);
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.intents = this._intentService.get();

        this.entities = this._entityService.get();

        this.toolbarOps = this._patternStoreService.getToolbarOps();

        Observable.combineLatest(this.intents, this.entities, this.modelId)
            .first()
            .map(([intents, entities, id]) => intents.find(i => i.id === id) || entities.find(e => e.id === id) || new LuisModel())
            .subscribe(model =>
                this._patternStoreService.initialize({
                    model: model,
                    selectionMap: this.selectionMap,
                    skip: 0,
                    take: this.fetchSize,
                    predictionsTracker: this.predictionsTracker
                })
            );

        this.items = this._patternStoreService.fetch().trackProgress(this.fetchTracker.getTracker());

        this.haveNoItems = Observable.combineLatest(this.items, this.fetchTracker.hasData, this.submitTracker.isStarted)
            .map(([items, hasData, isStarted]) => hasData && items.length === 0 && !isStarted);
    }
}
