import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { first, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { EntityHelpers } from '../../../../helpers/entity.helpers';
import { ICompositeWrapMenuEvent } from '../../../../interfaces/ICompositeWrapMenuEvent';
import { Entity, ENTITY_TYPES } from '../../../../models/entity.model';
import { ParentEntity } from '../../../../models/parent-entity.model';
import { EntityWrapService } from '../../../../services/entity-wrap.service';
import { NewMenuItemComponent } from '../../items/new-menu-item/new-menu-item.component';
import { RolesMenuComponent } from '../roles-menu/roles-menu.component';

/**
 * @description
 * Represents the sub menu that contains the controls
 * to wrap tokens with a composite entity.
 */
@Component({
	selector: 'wrap-menu-content',
	templateUrl: 'wrap-menu-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrapMenuContentComponent implements OnInit {
	@Input() public entities: Observable<Entity[]>;
	@Input() public rolesMenu: RolesMenuComponent;

	@Output() public wrapClicked: EventEmitter<ICompositeWrapMenuEvent> = new EventEmitter<ICompositeWrapMenuEvent>();
	@Output() public requestClose: EventEmitter<void> = new EventEmitter<void>();

	public validComposites: Observable<ParentEntity[]>;
	public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public showNewEntityItem: typeof NewMenuItemComponent.showNewEntityItem = NewMenuItemComponent.showNewEntityItem;
	private _allComposites: Observable<ParentEntity[]>;

	constructor(private _entityWrapService: EntityWrapService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Notifies the parent that either a composite entity was selected
	 * or a new one was requested.
	 *
	 * @param filteredComposites The array of composite entities that
	 * match the current query string.
	 */
	public onQueryEnterPress(filteredComposites: ParentEntity[]): void {
		const query: string = this.querySubject.getValue().trim();
		const [entity, role] = EntityHelpers.splitQuery(query);
		const match: boolean =
			filteredComposites.length &&
			filteredComposites[0].name.toLocaleLowerCase().includes(entity.toLocaleLowerCase()) &&
			!this.showNewEntityItem(query, filteredComposites);

		if (!entity.length) {
			return;
		}

		if (filteredComposites.length === 0 || !match) {
			this._entityWrapService
				.getWrappedEntities()
				.pipe(
					first(),
					map(children => this._getDistinctChildren(children)),
					map(children => new ParentEntity('', entity.trim(), ENTITY_TYPES.COMPOSITE, children)),
					map(parentEntity => ({ entity: parentEntity, isNew: true, role, closeMenu: true })),
					tap(emitValue => this.wrapClicked.emit(emitValue))
				)
				.subscribe();
		} else {
			this.wrapClicked.emit({ entity: filteredComposites[0], isNew: false, closeMenu: true });
		}
	}

	/**
	 * @description
	 * Notifies the parent that a composite wrap operation was requested.
	 *
	 * @param entity The composite entity that was clicked to actually
	 * wrap with.
	 */
	public applyWrap(entity: ParentEntity): void {
		this.wrapClicked.emit({ entity: entity, isNew: false, closeMenu: true });
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._allComposites = this.entities.map(entities => <ParentEntity[]>entities.filter(e => e.type === ENTITY_TYPES.COMPOSITE));

		this.validComposites = Observable.combineLatest(this._entityWrapService.getWrappedEntities(), this._allComposites).pipe(
			map(([wrappedEntities, allComposites]) => allComposites.filter(c => this._areChildrenOf(c, wrappedEntities)))
		);
	}

	/**
	 * @description
	 * Checks if the given wrapped entity or their roles ids are all children of the given parent.
	 *
	 * @param parent The parent to check the children of.
	 * @param children The children to check.
	 * @returns True if all the children belong to the parent
	 * and false otherwise.
	 */
	private _areChildrenOf(parent: ParentEntity, wrappedEntities: Entity[]): boolean {
		return (
			wrappedEntities
				.map(
					wrappedEntity =>
						parent.children.find(compositeChild =>
							// If the wrapped entity has a role applied, then we check against it,
							// else we check for the entity id
							wrappedEntity.roles[0]
								? wrappedEntity.roles[0].id === compositeChild.id
								: wrappedEntity.id === compositeChild.id
						) !== undefined
				)
				.find(b => !b) === undefined
		);
	}

	/**
	 * @description
	 * Removes duplicate children.
	 *
	 * @param children The children entities to check.
	 * @returns The children entities after removing duplicates.
	 */
	private _getDistinctChildren(children: Entity[]): Entity[] {
		const childrenIdsSet: Set<string> = children.reduce((acc, val) => acc.add(val.id), new Set<string>());

		return children.filter(c => childrenIdsSet.has(c.id));
	}
}
