import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IUtteranceResults } from '../../../interfaces/IUtteranceResults';

@Component({
	selector: 'entity-inspector',
	templateUrl: './entity-inspector.component.html',
	styleUrls: ['./entity-inspector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('expandRow', [
			state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class EntityInspectorComponent {
	@Input() public utteranceResults: IUtteranceResults;

	public expandedRow: any;
}
