import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService } from '@luis/core';
import { Observable } from 'rxjs';
import { ProgrammaticKeyWithEndpointUrl } from '../models/botframework-auth.model';

@Injectable()
export class BotFrameworkAuthService {
	constructor(private readonly _baseService: BaseService, @Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService) {}

	/**
	 * @description
	 * Gets the programmatic key and the base endpoint url for this account. This is
	 * used in the azure connector page to get the user's data for the external service
	 * that wants to connect via Azure.
	 */
	public getProgrammaticKeyAndEndpointBaseUrl(): Observable<ProgrammaticKeyWithEndpointUrl> {
		const url: string = `${this._baseService.configs.webApiUrl}/user/programmaticKeyWithEndpointUrl`;

		return this._httpService
			.get(url, this._baseService.defaultOptionsBuilder.build())
			.map(response => ProgrammaticKeyWithEndpointUrl.importApiReady(response));
	}
}
