import { Directive, ElementRef, OnInit } from '@angular/core';

/**
 * @description
 * A directive to focus on the element the directive is placed on
 * on the time of creation of that element.
 */
@Directive({
    selector: '[onCreateFocus]'
})
export class OnCreateFocusDirective implements OnInit {
    constructor(private _elementRef: ElementRef) { }

    public ngOnInit(): void {
        this._elementRef.nativeElement.focus();
    }
}
