import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
	IToasterService,
	ITrackerMessage,
	ITrainingService,
	ITrainingStatus,
	LuisModel,
	PROGRESS_STATES,
	SERVICE_STATUS,
	TOASTER_SERVICE_TOKEN,
	TRAIN_STATUS,
	TRAINING_SERVICE_TOKEN
} from '@luis/core';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { IIntentService, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer, Subscription } from 'rxjs/Rx';

@Injectable()
export class TrainingEventsProxyService implements OnDestroy {
	private _trainingEventsSubscription: Subscription;
	private _trainingStatusSubscription: Subscription;
	private _trainingTracker: Observer<ITrackerMessage>;

	constructor(
		private _i18n: TranslateService,
		@Inject(TRAINING_SERVICE_TOKEN) private _trainingService: ITrainingService,
		@Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
		@Inject(INTENT_SERVICE_TOKEN) private _intentService: IIntentService,
		@Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
	) {}

	public ngOnDestroy(): void {
		this._trainingEventsSubscription.unsubscribe();
		this._trainingStatusSubscription.unsubscribe();

		if (this._trainingTracker) {
			this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
		}
	}

	/**
	 * @method
	 * @description
	 * Initializes the service.
	 */
	public initService(): void {
		this._trainingStatusSubscription = this._trainingService
			.serviceStatus()
			.filter(status => status === SERVICE_STATUS.QUEUED)
			.subscribe(
				() =>
					(this._trainingTracker = this._toasterService.add({
						startMsg: this._i18n.instant('portal.training-events-proxy.queued_for_training_toast')
					}))
			);

		this._trainingEventsSubscription = this._trainingService
			.trainStatus()
			.switchMap(status =>
				Observable.combineLatest(this._intentService.get(), this._entityService.get())
					.first()
					.map(models => ({ status: status, models: models }))
			)
			.map(data => {
				const status: ITrainingStatus = data.status;
				let loadingMessage: string;
				let successMessage: string;
				let errorMessage: string;

				switch (status.trainStatus) {
					case TRAIN_STATUS.COLLECTING_DATA:
						loadingMessage = this._i18n.instant('portal.training-events-proxy.collecting_training_data_toast');
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						this._trainingTracker = this._toasterService.add({ startMsg: loadingMessage });
						break;
					case TRAIN_STATUS.UPLOADING_MODEL:
						loadingMessage = this._i18n.instant('portal.training-events-proxy.finalizing_training_toast');
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						this._trainingTracker = this._toasterService.add({ startMsg: loadingMessage });
						break;
					case TRAIN_STATUS.IN_PROGRESS:
						loadingMessage = this._i18n.instant('portal.training-events-proxy.training_app_toast', {
							status: `${status.completedCount}/${status.modelCount}`
						});
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						this._trainingTracker = this._toasterService.add({ startMsg: loadingMessage });
						break;
					case TRAIN_STATUS.SUCCESS:
						successMessage = this._i18n.instant('portal.training-events-proxy.app_successfully_training_toast', {
							date: new Date().toUTCString()
						});
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						this._trainingTracker = this._toasterService.add({ endMsg: successMessage });
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						break;
					case TRAIN_STATUS.UP_TO_DATE:
						successMessage = this._i18n.instant('portal.training-events-proxy.app_up_to_date_toast');
						successMessage = 'App up to date';
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						this._trainingTracker = this._toasterService.add({ endMsg: successMessage });
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						break;
					case TRAIN_STATUS.FAIL:
						const failedModel: LuisModel =
							data.models[0].find(i => i.id === status.failedModel.id) ||
							data.models[1].find(i => i.id === status.failedModel.id);

						if (failedModel) {
							errorMessage = this._i18n.instant('portal.training-events-proxy.app_training_failed_params_toast', {
								name: failedModel.name,
								error: status.failedModel.status.error
							});
						} else {
							errorMessage = this._i18n.instant('portal.training-events-proxy.app_training_failed_toast');
						}
						this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
						this._trainingTracker = this._toasterService.add({ errorMsg: errorMessage });
						this._trainingTracker.next({ state: PROGRESS_STATES.ERROR });
						break;
					default:
				}
			})
			.subscribe(() => {});
	}
}
