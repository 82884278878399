import { IResource } from '../../interfaces/models/IResource';

/**
 * @description
 * An enumeration of the change types that can occur on a feature.
 */
export enum FEATURE_CHANGE_TYPE {
    ADD,
    UPDATE,
    DELETE
}

/**
 * @description
 * An interface for event bus messages that notify that a certain
 * LUIS feature has changed.
 *
 * @param feature The feature that was changed.
 * @param changeType The type of change that occurred.
 */
export interface IFeatureChangeBusMessage {
    feature: IResource;
    changeType: number;
}
