﻿import { Announcement } from './announcement.model';

/**
 * @description
 * The environments supported.
 */
export enum ENVIRONMENT {
	DEV,
	PROD,
	PROD_EU,
	PROD_AU
}

/**
 * @description
 * Represents the admin settings readable by the website.
 *
 * @param maintenanceMode The maintenance mode of the website.
 * @param announcements The announcements to display.
 */
export class AdminSettings {
	public maintenanceMode: boolean;
	public announcements: Announcement[];

	constructor(maintenanceMode: boolean, announcements: Announcement[]) {
		this.maintenanceMode = maintenanceMode;
		this.announcements = announcements ? announcements : [];
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): AdminSettings {
		return new AdminSettings(
			apiObject.maintenanceMode,
			apiObject.announcements ? (<any[]>apiObject.announcements).map(a => Announcement.importFromApi(a)) : []
		);
	}

	/**
	 * @method
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			maintenanceMode: this.maintenanceMode,
			announcements: this.announcements.map(a => a.exportToApi())
		};
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): AdminSettings {
		return new AdminSettings(this.maintenanceMode, this.announcements.map(a => a.clone()));
	}
}
