import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { IEntityMenuEvent } from '../../../../interfaces/IEntityMenuEvent';
import { ISubMenu } from '../../../../interfaces/ISubmenu';
import { Entity } from '../../../../models/entity.model';
import { ParentEntity } from '../../../../models/parent-entity.model';

@Component({
    selector: 'hierarchical-menu',
    templateUrl: 'hierarchical-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchicalMenuComponent implements ISubMenu {
    @ViewChild('menu') public menu: MatMenu;

    @Input() public childEntity: Entity;
    @Output() public existingEntityClick: EventEmitter<IEntityMenuEvent> = new EventEmitter();

    /**
     * @description
     * Selects a specific entity child from.
     *
     * @param parent Parent hierarchical menu.
     * @param child Selected entity child.
     */
    public selectChild(parent: ParentEntity, child: Entity): void {
        const newChild = parent.getChildWithFullName(child.id);
        this.existingEntityClick.emit({ entity: newChild });
    }
}
