import { ENVIRONMENT } from './IEnvironment';

/**
 * @description
 * Describes a state that is sent to the gate keeper service
 * to get the permissions possible for a feature.
 */
export interface IUserState {
	email?: string;

	culture?: string;

	env?: ENVIRONMENT;
}

/**
 * @description
 * An enumeration of the possible features to control in the portal.
 */
export const GATEKEEPER_FEATURE = {
	SPEECH: 'SPEECH',
	SENTIMENT: 'SENTIMENT',
	DOMAINS: 'DOMAINS',
	KEYS_FILTER: 'KEYS_FILTER',
	SPELLCHECK: 'SPELLCHECK'
};
