﻿import { Directive, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/utils/analytics.service';

/**
 * @description
 * When the element is loaded, sends the page name for
 * GA analytics and tracking.
 */
@Directive({
    selector: '[page-view]'
})
export class PageViewDirective implements OnInit {
    @Input('page-view') public pageName: string;

    constructor(
        private _analyticsService: AnalyticsService
    ) { }

    public ngOnInit(): void {
        this._analyticsService.trackPage(this.pageName);
    }
}
