import { BaseItemFilter } from '@luis/core';
import { BehaviorSubject } from 'rxjs';
import { Utterance } from '../utterance.model';

export class ScoreFilter extends BaseItemFilter {
	private _minScore: number = 0;
	private _maxScore: number = 1;
	private _trainingDate: BehaviorSubject<Date>;

	public setTrainingDate(date: BehaviorSubject<Date>): void {
		this._trainingDate = date;
	}

	/**
	 * @method
	 * @description
	 * Toggles the activation of the filter.
	 */
	public toggleActivation(range: [number, number]): void {
		if (range) {
			this._minScore = range[0] ? range[0] : 0;
			this._maxScore = range[1] ? range[1] : 1;
		} else {
			this._minScore = 0;
			this._maxScore = 1;
		}
	}

	/**
	 * @method
	 * @description
	 * Returns the active state of the filter.
	 */
	public isActive(): boolean {
		return this._minScore !== 0 || this._maxScore !== 1;
	}

	public getRnge(): [number, number] {
		return [this._minScore, this._maxScore];
	}

	/**
	 * @method
	 * @description
	 * Filters the given item by whether it contains the
	 * given entities or not.
	 *
	 * @param item The item to filter.
	 * @returns True if the item should be displayed
	 * and false if it should not be displayed.
	 */
	public filter(item: Utterance): boolean {
		if (this._trainingDate && item instanceof Utterance && this._trainingDate.getValue() < item.assignedDate) {
			return true;
		}
		const score: number = Number(item.labeledIntent.score.toFixed(2));

		return score >= this._minScore && score <= this._maxScore;
	}
}
