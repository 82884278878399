import { ITimeZone, timeZones } from '../interfaces/ITimeZone';

/**
 * @description
 * Represents an azure account.
 */
export class EndpointKey {
    public keyPath: string;
    public id: string;
    public name: string;
    public location: string;
    public kind: string;
    public endpoint: string;
    public createdDate: Date;
    public state: string;
    public timeZone: ITimeZone;
    public keyStrings: { key1: string; key2: string };

    constructor(
        keyPath: string = '', id: string = '',
        name: string = '', location: string = '',
        kind: string = 'LUIS', endpoint: string = '',
        createdDate: Date = new Date(), state: string = '',
        keyStrings: { key1: string; key2: string } = { key1: '', key2: '' },
        timeZone: ITimeZone = timeZones.find(t => t.value === 0)
    ) {
        this.keyPath = keyPath;
        this.id = id;
        this.name = name;
        this.location = location;
        this.kind = kind;
        this.endpoint = endpoint;
        this.createdDate = createdDate;
        this.state = state;
        this.timeZone = timeZone;
        this.keyStrings = keyStrings;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): EndpointKey {
        return new EndpointKey(
            apiObject.id,
            apiObject.properties.internalId,
            apiObject.name,
            apiObject.location ? apiObject.location.toLocaleLowerCase().split(' ').join('') : apiObject.location,
            apiObject.kind,
            apiObject.properties.endpoint,
            apiObject.properties.dateCreated ? new Date(apiObject.properties.dateCreated) : null,
            apiObject.properties.provisioningState
        );
    }

    /**
     * @description
     * Imports the endpoint key from the LUIS apis for subscriptions.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromAssignedKeysApi(apiObject: any): EndpointKey {
        return new EndpointKey(
            apiObject.KeyPath,
            apiObject.EndpointId,
            '',
            apiObject.Region ? apiObject.Region.toLocaleLowerCase().split(' ').join('') : apiObject.Region
        );
    }

    /**
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): EndpointKey {
        return new EndpointKey(
            this.keyPath ? this.keyPath.slice() : this.keyPath,
            this.id.slice(),
            this.name.slice(),
            this.location.slice(),
            this.kind.slice(),
            this.endpoint.slice(),
            this.createdDate ? new Date(this.createdDate.getTime()) : null,
            this.state.slice(),
            { key1: this.keyStrings.key1, key2: this.keyStrings.key2 },
            { text: this.timeZone.text, value: this.timeZone.value }
        );
    }

    /**
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            subscriptionId: this.id,
            region: this.location,
            keyPath: this.keyPath
        };
    }
}
