import { Pipe, PipeTransform } from '@angular/core';
import { LuisModel } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IModelConfusionAggregate } from '../interfaces/IModelConfusionAggregate';
import { CONFUSION_TYPE } from '../models/entity-confusion.model';

@Pipe({
    name: 'confusionCountAsync'
})
export class ConfusionCountAsyncPipe implements PipeTransform {
    public transform(
        confusionType: CONFUSION_TYPE,
        modelAggregates: Observable<Map<string, IModelConfusionAggregate>>,
        activeModel: Observable<LuisModel>
    ): Observable<number> {
        return Observable.combineLatest(activeModel, modelAggregates)
            .map(([model, map]) => map.get(model.id))
            .map(results => results ? Array.from(results.utteranceScores.keys())
                .filter(id => results.utteranceScores.get(id).filter(uS => uS.confusion === confusionType).length > 0) : [])
            .map(ids => ids.length);
    }
}
