import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@luis/auth';
import { AppRouteService } from 'src/common/services/app-route.service';

/**
 * @description
 * Represents the LUIS welcome page.
 */
@Component({
	selector: 'luis-overview',
	templateUrl: 'overview.component.html',
	styleUrls: ['overview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements OnInit {
	constructor(
		private readonly _router: Router,
		private readonly _routeService: AppRouteService,
		private readonly _userService: UserService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Skips the tutorial.
	 */
	public onSkip(): void {
		this._router.navigate([
			this._routeService
				.builder()
				.apps()
				.build()
		]);
	}

	/**
	 * @description
	 * Initializes the component
	 */
	private _initState(): void {
		this._userService
			.get()
			.first()
			.do(info => (info.tutorial = true))
			.flatMap(info => this._userService.update(info))
			.subscribe();
	}
}
