import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * @description
 * Represents the application navigation sidebar in the application
 * page.
 */
@Component({
    selector: '[m-sidebar]',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent { }
