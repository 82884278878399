import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from './error-handler.model';

/**
 * @description
 * Represents a chain of error handlers that when executed,
 * will keep on calling handlers on till one of them succeeds.
 */
export class ErrorPipeline {
	private _head: ErrorHandler;
	private _tail: ErrorHandler;

	/**
	 * @description
	 * Adds a new handler to the pipeline of handlers
	 * to execute. Attaches the last handler in the chain
	 * (if exists) to the new handler.
	 *
	 * @param handler The handler to add to the chain.
	 */
	public add(handler: ErrorHandler): void {
		if (!this._head) {
			this._head = handler;
			this._tail = handler;
		} else {
			this._tail.setNext(handler);
			this._tail = handler;
		}
	}

	/**
	 * @description
	 * Handles the error given by passing it to the error
	 * handlers responsibility chain.
	 *
	 * @param error The error to handle.
	 * @returns A well formatted error object.
	 */
	public handle(error: HttpErrorResponse): Error {
		if (this._head) {
			return this._head.handle(error);
		}

		throw new Error('Pipeline has no handlers.');
	}
}
