import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    public transform(value: string, args: number): string {
        const limit: number = args ? args : 10;
        const trail: string = ' ...';
        const valueToShow: string = value.endsWith('.') ? value.substring(0, value.length - 1) : value;
        const closestSpaceToLimit: number = valueToShow.lastIndexOf(' ', limit);

        return valueToShow.length > limit ? valueToShow.substring(0, closestSpaceToLimit) + trail : valueToShow + trail;
    }
}
