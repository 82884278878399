import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { ClosedEntity } from '../../../models/closed-entity.model';
import { Entity, ENTITY_TYPES } from '../../../models/entity.model';
import { ParentEntity } from '../../../models/parent-entity.model';
import { PatternAnyEntity } from '../../../models/pattern-any-entity.model';

/**
 * @description
 * Represents the interface for the parameter object that should be
 * provided for this component.
 */
export interface IEntityCreationModalParams {
	initialEntity?: Entity;

	selectedTokens?: string;

	isTypeLocked?: boolean;

	typesToExclude?: ENTITY_TYPES[];

	role?: string;
}

/**
 * @description
 * Represents the entity creation dialog component for creating new entities.
 * This component is to be plugged in the modal host to appear as a modal.
 */
@Component({
	selector: 'entity-creation-modal',
	templateUrl: './entity-creation-modal.component.html',
	styleUrls: ['./entity-creation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityCreationModalComponent implements OnInit {
	public entityTypes: typeof ENTITY_TYPES = ENTITY_TYPES;
	public entities: Observable<Entity[]>;
	public isValid: Observable<boolean>;
	public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public contextEntity: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(new Entity());
	public entityNameObserver: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<EntityCreationModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IEntityCreationModalParams,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Gets the current value of the context entity name on the stream.
	 *
	 * @returns The name of the entity on the stream.
	 */
	get contextEntityName(): string {
		return this.contextEntity.getValue().name;
	}

	/**
	 * @description
	 * Sets the stream's context entity name with the given argument.
	 *
	 * @param name The name to set.
	 */
	set contextEntityName(name: string) {
		const e: Entity = this.contextEntity.getValue();
		e.name = name;
		this.contextEntity.next(e);
	}

	/**
	 * @description
	 * Changes the context entity to an empty entity as recieved
	 * from the child.
	 *
	 * @param emptyEntity An empty entity to set the context entity as.
	 */
	public onTypeChange(emptyEntity: Entity): void {
		const currentEntity: Entity = this.contextEntity.getValue();
		emptyEntity.name = currentEntity.name;
		this.contextEntity.next(emptyEntity);
	}

	/**
	 * @description
	 * Sets the context entity stream to the updated entity received
	 * in the event.
	 *
	 * @param entity The updated entity to set the stream to.
	 */
	public onEntityChange(entity: Entity): void {
		this.contextEntity.next(entity);
	}

	/**
	 * @description
	 * Reacts to the enter click of any of the creation child components to submit
	 * the entity if it is valid.
	 */
	public onEnterClick(): void {
		if (this.contextEntity.getValue().isValid()) {
			this.onDoneClick();
		}
	}

	/**
	 * @description
	 * Emits the entity when finished to the response stream.
	 */
	public onDoneClick(): void {
		const e: Entity = this.contextEntity.getValue();
		const refresh: boolean = this._requiresRefresh(e.type);
		this._preprocessEntityNames(e);
		this.inProgress.next(true);

		this._entityService
			.add(e, refresh)
			.finally(() => this.inProgress.next(false))
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.entity-creation-modal.create_toast') }))
			.flatMap((data: any) => {
				if (!this.data.role) {
					return of(data);
				}

				e.id = data instanceof Entity ? data.id : data;
				const entityRole = e.appendRole(this.data.role);

				return this._entityService.add(entityRole).mapTo(data);
			})
			.subscribe((data: any) => {
				e.id = data instanceof Entity ? data.id : data;
				this._dialogRef.close(e);
			});
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.data = { initialEntity: new Entity(), isTypeLocked: false, typesToExclude: [], ...this.data };
		this.contextEntity.next(this.data.initialEntity);
		this.entities = this._entityService.get();
		this.isValid = this.contextEntity.asObservable().map(e => e.isValid());
	}

	/**
	 * @description
	 * Trims entity name, and if children/closed values are present,
	 * trims them as well.
	 *
	 * @param e The entity to preprocess.
	 */
	private _preprocessEntityNames(e: Entity): void {
		e.name = e.name.trim();

		if (e.type === ENTITY_TYPES.COMPOSITE && e instanceof ParentEntity) {
			e.children.forEach(childEntity => {
				childEntity.name = childEntity.name + (childEntity.roles[0] ? `:${childEntity.roles[0].name}` : '');
			});
		}

		if (e instanceof ParentEntity) {
			e.children.forEach(c => (c.name = c.name.trim()));
		} else if (e instanceof ClosedEntity) {
			e.sublists.forEach(sublist => {
				sublist.name = sublist.name.trim();
				sublist.values = sublist.values.map(v => v.trim());
			});
		} else if (e instanceof PatternAnyEntity) {
			e.roles.forEach(r => (r.name = r.name.trim()));
		}
	}

	/**
	 * @description
	 * Checks if the entity type requires refresh after adding
	 * or not.
	 *
	 * @param type The entity type to check.
	 * @returns True if requires refresh and false otherwise.
	 */
	private _requiresRefresh(type: number): boolean {
		switch (type) {
			case ENTITY_TYPES.HIERARCHICAL:
			case ENTITY_TYPES.PATTERN_ANY:
			case ENTITY_TYPES.CLOSED:
			case ENTITY_TYPES.COMPOSITE:
				return true;
			default:
				return false;
		}
	}
}
