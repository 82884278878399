import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

@Component({
    selector: 'file-picker',
    templateUrl: 'file-picker.component.html',
    styleUrls: ['file-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilePickerComponent implements OnInit {
    @Input() public label: string;
    @Output() public fileChanged: EventEmitter<File> = new EventEmitter<File>();

    public visibleMessage: string;

    public ngOnInit(): void {
        this.visibleMessage = this.label;
    }

    /**
     * @description
     * Notifies parent that the file has changed.
     *
     * @param fileField The file that was selected.
     */
    public onFileChange(fileField: HTMLInputElement): void {
        const file: File = fileField.files.item(0);

        this.visibleMessage = file.name;
        this.fileChanged.emit(file);
    }
}
