import { NgModule, Provider } from '@angular/core';
import { EntityDetailsModule } from './components/entity-details/entity-details.module';
import { DropdownsModule } from './components/entity-dropdown/entity-dropdown.module';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { EntityTableComponent } from './components/entity-list/entity-table/entity-table.component';
import { ModalsModule } from './components/modals/modals.module';
import { ENTITY_SERVICE_TOKEN } from './interfaces/IEntityService';
import { EntityFactoryService } from './services/entity-factory.service';
import { EntityUtilitiesService } from './services/entity-util.service';
import { EntityWrapService } from './services/entity-wrap.service';
import { EntityService } from './services/entity.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
	imports: [SharedModule, EntityDetailsModule, DropdownsModule, ModalsModule],
	exports: [EntityListComponent, EntityDetailsModule, DropdownsModule, ModalsModule],
	declarations: [EntityListComponent, EntityTableComponent],
	providers: [EntityFactoryService, EntityWrapService]
})
export class EntitiesModule {
	public static providersForChild(): Provider[] {
		return [
			EntityUtilitiesService,
			{
				provide: ENTITY_SERVICE_TOKEN,
				useClass: EntityService
			}
		];
	}
}
