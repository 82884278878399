import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import {
	ClosedEntity,
	ClosedSublist,
	Entity,
	ENTITY_DROPDOWN_OPERATIONS,
	ENTITY_SERVICE_TOKEN,
	ENTITY_TYPES,
	EntityCreationModalComponent,
	EntitySegment,
	EntityWrapService,
	ICompositeWrapMenuEvent,
	IEntityCreationModalParams,
	IEntityService,
	IUtteranceEntityChangeEvent,
	PrebuiltEntityListModalComponent,
	Segment,
	SEGMENT_TYPES,
	UtteranceEntity
} from '@luis/entities';
import { ESCAPE } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { map, filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { SegmentFocusService } from '../../services/segment-focus.service';
import { SegmentsExtractionService } from '../../services/segments-extraction.service';

export interface ILabelableOperationPayload {
	operation: ENTITY_DROPDOWN_OPERATIONS;
	event: IUtteranceEntityChangeEvent;
}

/**
 * @description
 * Represents the editable text segment of an utterance. Manages the display and manipulation
 * of tokens' entity labels.
 */
@Component({
	selector: 'labelable-utterance',
	templateUrl: 'labelable-utterance.component.html',
	styleUrls: ['labelable-utterance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [EntityWrapService, SegmentFocusService]
})
export class LabelableUtteranceComponent implements OnInit {
	@Input() public tokenizedText: string[];
	@Input() public labeledEntities: UtteranceEntity[];
	@Input() public predictedEntities: UtteranceEntity[];
	@Output() public entityChanges: EventEmitter<UtteranceEntity[]> = new EventEmitter<UtteranceEntity[]>();
	@Output() public editEntity: EventEmitter<Entity> = new EventEmitter<Entity>();

	public segmentTypes: any = SEGMENT_TYPES;
	public labeledEntities$: BehaviorSubject<UtteranceEntity[]> = new BehaviorSubject<UtteranceEntity[]>([]);
	public predictedEntities$: BehaviorSubject<UtteranceEntity[]> = new BehaviorSubject<UtteranceEntity[]>([]);
	public utteranceModelState: Observable<Segment[]>;
	public clickOutsideChecker: typeof LabelableUtteranceComponent.clickOutsideChecker = LabelableUtteranceComponent.clickOutsideChecker;

	constructor(
		private readonly _dialogService: MatDialog,
		private readonly _segmentExtractionService: SegmentsExtractionService,
		private readonly _entityWrapService: EntityWrapService,
		private readonly _segmentFocusService: SegmentFocusService,
		private readonly _liveAnnouncer: LiveAnnouncer,
		private readonly _i18n: TranslateService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService
	) {}

	public static clickOutsideChecker(element: HTMLElement, clickedOn: HTMLElement): boolean {
		const menuItemClass = 'mat-menu-item';
		const menuPanelClass = 'mat-menu-panel';

		return (
			clickedOn &&
			!clickedOn.classList.contains(menuItemClass) &&
			!clickedOn.classList.contains(menuPanelClass) &&
			clickedOn.offsetParent &&
			!clickedOn.offsetParent.classList.contains(menuItemClass) &&
			!clickedOn.offsetParent.classList.contains(menuPanelClass)
		);
	}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.predictedEntities && changes.predictedEntities.currentValue !== changes.predictedEntities.previousValue) {
			this.predictedEntities$.next(changes.predictedEntities.currentValue);
		}
		if (changes.labeledEntities && changes.labeledEntities.currentValue !== changes.labeledEntities.previousValue) {
			this.labeledEntities$.next(changes.labeledEntities.currentValue);
		}
	}

	public trackByFunc(index: number, segment: Segment): string {
		return segment.start + segment.end + (segment instanceof EntitySegment ? segment.entity.id + segment.role : '');
	}

	/**
	 * @description
	 * Handles the keydown for accessbility for this component.
	 */
	@HostListener('document:keydown', ['$event'])
	public handleKeyDown(event: KeyboardEvent): void {
		switch (event.keyCode) {
			case ESCAPE:
				event.stopPropagation();
				this.onClickOutside();
				break;
			default:
		}
	}

	/**
	 * @description
	 * Disable composite wrap mode if the user clicked outside
	 * this utterance.
	 */
	public onClickOutside(): void {
		this._entityWrapService.setWrapMode(false);
	}

	/**
	 * @description
	 * Handles any entity change operations emitted from labeling changes.
	 *
	 * @param operationPayload The payload that was emmited from the label change.
	 */
	public handleOperation(operationPayload: ILabelableOperationPayload): void {
		const { event, operation } = operationPayload;
		switch (operation) {
			case ENTITY_DROPDOWN_OPERATIONS.EXISTING_ENTITY: {
				this.onExistingEntityAddRequest(event);
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.LABEL_COMPOSITE_WITH_ROLE: {
				this.onLabelCompositeWithRoleRequest(event);
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.NEW_ENTITY: {
				this.onNewEntityAddRequest(event);
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.DELETE_ENTITY: {
				this.onDeleteEntityRequest(event);
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.PREBUILT_ENTITY: {
				this.onPrebuilEntityClicked();
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.EDIT_ENTITY: {
				this.onEditEntityRequest(event);
				break;
			}
			default:
				throw new Error('Operation code must be included in the ENTITY_DROPDOWN_OPERATIONS enum.');
		}
	}

	/**
	 * @description
	 * Updates the utterance with an existing entity. The utterance label might be a new label
	 * or an updated old utterance label.
	 *
	 * @param utteranceEntity The utterance entity to add.
	 * @param oldUtteranceEntity The old utterance entity that was updated if the utterance entity
	 * was updated.
	 */
	private onExistingEntityAddRequest(changeEvent: IUtteranceEntityChangeEvent): void {
		if (changeEvent.oldUtteranceEntity !== null) {
			const indexToDelete: number = this.labeledEntities.findIndex(
				e =>
					e.startTokenIndex === changeEvent.oldUtteranceEntity.startTokenIndex &&
					e.endTokenIndex === changeEvent.oldUtteranceEntity.endTokenIndex &&
					e.id === changeEvent.oldUtteranceEntity.id
			);

			if (indexToDelete !== -1) {
				this.labeledEntities.splice(indexToDelete, 1);
			}
		}

		if (changeEvent.entityEvent) {
			this._handleExistingEntityPayload(changeEvent);
		}

		this._segmentFocusService.lastFocusedIndex = changeEvent.newUtteranceEntity.startTokenIndex;
		this.labeledEntities.push(changeEvent.newUtteranceEntity);
		this.labeledEntities$.next(this.labeledEntities);
		this.entityChanges.emit(this.labeledEntities);
	}

	/**
	 * @description
	 * Adds a role to composite label.
	 */
	private onLabelCompositeWithRoleRequest(changeEvent: IUtteranceEntityChangeEvent): void {
		const uEntity = this.labeledEntities.find(
			({ id, endTokenIndex, startTokenIndex }) =>
				id === changeEvent.newUtteranceEntity.id &&
				endTokenIndex === changeEvent.newUtteranceEntity.endTokenIndex &&
				startTokenIndex === changeEvent.newUtteranceEntity.startTokenIndex
		);

		this._segmentFocusService.lastFocusedIndex = uEntity.startTokenIndex;
		uEntity.role = changeEvent.entityEvent.eventData.role;
		uEntity.roleId = changeEvent.entityEvent.eventData.roleId;

		this.labeledEntities$.next(this.labeledEntities);
		this.entityChanges.emit(this.labeledEntities);
	}

	/**
	 * @description
	 * Fires a trigger to the parent row to add a new entity or replace the old one with
	 * the new one and label it.
	 *
	 * @param utteranceEntity The utterance entity to add.
	 * @param oldUtteranceEntity The old utterance entity (if exists) that was updated if
	 * the utterance entity was updated.
	 */
	private onNewEntityAddRequest(changeEvent: IUtteranceEntityChangeEvent): void {
		const utteranceEntity = changeEvent.newUtteranceEntity;
		const entity = new Entity('', utteranceEntity.name);

		const modalParams: IEntityCreationModalParams = {
			initialEntity: entity,
			role: changeEvent.entityEvent && <string>changeEvent.entityEvent.eventData,
			selectedTokens: this.tokenizedText.slice(utteranceEntity.startTokenIndex, utteranceEntity.endTokenIndex + 1).join(' '),
			typesToExclude: [ENTITY_TYPES.REGEX, ENTITY_TYPES.PATTERN_ANY]
		};

		this._segmentFocusService.lastFocusedIndex = utteranceEntity.startTokenIndex;

		this._dialogService
			.open(EntityCreationModalComponent, { width: '600px', data: modalParams })
			.afterClosed()
			.filter(e => e)
			.subscribe(e => {
				utteranceEntity.id = e.id;
				utteranceEntity.name = e.name;
				utteranceEntity.type = e.type;
				utteranceEntity.roleId = (e.roles[0] && e.roles[0].id) || '';
				utteranceEntity.role = (e.roles[0] && e.roles[0].name) || '';

				if (changeEvent.oldUtteranceEntity) {
					const indexToDelete: number = this.labeledEntities.findIndex(
						lE =>
							lE.startTokenIndex === changeEvent.oldUtteranceEntity.startTokenIndex &&
							lE.endTokenIndex === changeEvent.oldUtteranceEntity.endTokenIndex &&
							lE.id === changeEvent.oldUtteranceEntity.id
					);

					if (indexToDelete !== -1) {
						this.labeledEntities.splice(indexToDelete, 1);
					}
				}

				this.labeledEntities.push(utteranceEntity);
				this.labeledEntities$.next(this.labeledEntities);
				this.entityChanges.emit(this.labeledEntities);
			});
	}

	/**
	 * @description
	 * Fires a trigger for the parent to manage the entity given.
	 *
	 * @param entity The entity to manage.
	 */
	private onEditEntityRequest(changeEvent: IUtteranceEntityChangeEvent): void {
		this.editEntity.emit(changeEvent.entityEvent.entity);
	}

	/**
	 * @description
	 * Handles prebuilt entity click event
	 */
	private onPrebuilEntityClicked(): void {
		this._dialogService
			.open(PrebuiltEntityListModalComponent, { width: '800px' })
			.afterClosed()
			.filter(entities => entities)
			.subscribe(entities => entities);
	}

	/**
	 * @description
	 * Deletes the utterance entity given from the labeled entities of this utterance.
	 *
	 * @param utteranceEntity The utterance entity to delete.
	 */
	private onDeleteEntityRequest(changeEvent: IUtteranceEntityChangeEvent): void {
		const utteranceEntity = changeEvent.oldUtteranceEntity;
		const indexToDelete: number = this.labeledEntities.findIndex(
			e =>
				e.startTokenIndex === utteranceEntity.startTokenIndex &&
				e.endTokenIndex === utteranceEntity.endTokenIndex &&
				e.id === utteranceEntity.id
		);

		this._segmentFocusService.lastFocusedIndex = utteranceEntity.startTokenIndex;

		if (indexToDelete !== -1) {
			this.labeledEntities.splice(indexToDelete, 1);
			this.labeledEntities$.next(this.labeledEntities);
			this.entityChanges.emit(this.labeledEntities);
			this._liveAnnouncer.announce(this._i18n.instant('utterances.labelable-utterance.delete_toast'));
		}
	}

	/**
	 * @description
	 * Handles the wrap composite event. If the wrap was already in
	 * progress, then this event was triggered by a choice of composite
	 * entity to wrap. If the wrap was not already in progress, then this
	 * will trigger the wrap to start. The indeces of the wrap are all
	 * automatically updated when the user clicks on any of the segments.
	 *
	 * @param event The composite wrap event that
	 * occured.
	 */
	public onWrapEntityRequest(event: ICompositeWrapMenuEvent): void {
		this._entityWrapService
			.getWrapMode()
			.first()
			.subscribe(mode => {
				if (mode) {
					this._handleCompositeWrapEvent(event);
				} else {
					this._entityWrapService.setWrapMode(true);
					this._entityWrapService.setWrapIndeces(event.startIndex, event.endIndex);
				}
			});
	}

	/**
	 * @description
	 * Initialize component state.
	 */
	private _initState(): void {
		this.labeledEntities$.next(this.labeledEntities);

		this.predictedEntities$.next(this.predictedEntities);

		this.utteranceModelState = this._segmentExtractionService.transform(
			this.tokenizedText,
			this.labeledEntities$,
			this.predictedEntities$
		);

		this._entityWrapService.initService(this.utteranceModelState);
	}

	/**
	 * @description
	 * Creates a new composite utterance segment through the selected
	 * tokens. The composite entity to label could be an existing entity
	 * or a new one that needs a creation modal dialog to be opened.
	 *
	 * @param e The composite wrap event
	 * that was fired. Contains metadata to govern the creation process.
	 */
	private _handleCompositeWrapEvent(e: ICompositeWrapMenuEvent): void {
		this._entityWrapService
			.getWrapIndeces()
			.first()
			.flatMap(indeces => {
				const { start, end } = indeces;
				if (e.isNew) {
					const modalParams: IEntityCreationModalParams = {
						initialEntity: e.entity,
						selectedTokens: this.tokenizedText.slice(start, end + 1).join(' '),
						isTypeLocked: true,
						role: e.role
					};

					return this._dialogService
						.open(EntityCreationModalComponent, { width: '600px', data: modalParams })
						.afterClosed()
						.pipe(
							filter(entity => entity),
							map(entity => new UtteranceEntity(entity.id, entity.name, entity.type, e.role || '', start, end))
						);
				}

				return Observable.of(new UtteranceEntity(e.entity.id, e.entity.name, e.entity.type, e.role || '', start, end));
			})
			.finally(() => this._entityWrapService.setWrapMode(false))
			.subscribe(
				utteranceEntity => {
					this.labeledEntities.push(utteranceEntity);
					this.labeledEntities$.next(this.labeledEntities);
					this.entityChanges.emit(this.labeledEntities);
				},
				error => error
			);
	}

	/**
	 * @description
	 * Handles the entity payload for existing entity, doing appropriate calls to service or updates collections.
	 *
	 * @param changeEvent The changeEvent
	 */
	private _handleExistingEntityPayload(changeEvent: IUtteranceEntityChangeEvent): void {
		if (changeEvent.entityEvent.eventData && changeEvent.entityEvent.eventData instanceof ClosedSublist) {
			const uttr: UtteranceEntity = changeEvent.newUtteranceEntity;
			const text: string = this.tokenizedText
				.slice(uttr.startTokenIndex, uttr.endTokenIndex + 1)
				.join(' ')
				.trim();
			this._handleClosedSublistEvent(<ClosedEntity>changeEvent.entityEvent.entity, changeEvent.entityEvent.eventData, text);
		}
	}

	/**
	 * @description
	 * Based on {entry} and {token} either creates or adds sublist to synonym list
	 *
	 * @param sublist
	 * @param token
	 */
	private _handleClosedSublistEvent(entity: ClosedEntity, sublist: ClosedSublist, token: string): void {
		if (!sublist.name) {
			const parentEntity: ClosedEntity = entity.clone();
			sublist.name = token;
			sublist.parent = parentEntity;
			parentEntity.sublists = [...parentEntity.sublists, sublist];
			this._entityService
				.add(sublist)
				.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('utterances.labelable-utterance.add_toast') }))
				.subscribe(() => this.entityChanges.emit(this.labeledEntities));
		} else {
			const updatedEntity: ClosedEntity = entity.clone();
			const updatingEntry: ClosedSublist = updatedEntity.sublists.find(e => e.id === sublist.id);
			updatingEntry.values.push(token);
			this._entityService.update(updatedEntity).subscribe(() => this.entityChanges.emit(this.labeledEntities));
		}
	}
}
