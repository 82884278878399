﻿import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, ENVIRONMENT, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs';
import { IPublishService } from '../interfaces/IPublishService';
import { PersistantPublishSettings, PublishSettings } from '../models/publish.model';

@Injectable()
export class PublishService implements IPublishService {
	constructor(
		private readonly _baseService: BaseService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	/**
	 * @description
	 * Publishes the app with the given settings.
	 *
	 * @param settings The settings to publish with.
	 * @returns An observable to indicate completion.
	 */
	public publish(settings: PublishSettings): Observable<any> {
		const url: string = `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/publish`;

		return this._httpService.post(url, settings.exportToApi(), this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Gets the endpoint urls for this app for all the regions.
	 *
	 * @returns An observable with a map of
	 * region/url pairs.
	 */
	public getRegionEndpoints(): Observable<Map<string, string>> {
		const url: string = `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/endpoints`;
		const parser: (apiObject: Object) => Map<string, string> = (apiObject: Object) =>
			Object.keys(apiObject).reduce((acc, key) => acc.set(key, apiObject[key]), new Map<string, string>());

		return this._cacheService.get(url, parser, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Gets the persistent publish settings from the backend.
	 *
	 * @returns An observable of the settings.
	 */
	public getPublishSettings(): Observable<PersistantPublishSettings> {
		const url: string = `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/publishsettings`;

		return this._cacheService.get(url, PersistantPublishSettings.importFromApi, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Updates the publish settings with the given settings.
	 *
	 * @param settings The settings to update.
	 * @returns An observable to indicate completion.
	 */
	public updatePublishSettings(settings: PersistantPublishSettings): Observable<void> {
		const url: string = `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/publishsettings`;

		return this._cacheService.put(url, settings, this._baseService.defaultOptionsBuilder.skipMarkDirty().build());
	}

	/**
	 * @description
	 * Returns the correct bot url based on the current region.
	 *
	 * @returns The bot url.
	 */
	public getBotUrl(): string {
		const env: ENVIRONMENT = this._baseService.configs.env;
		let region: string;

		switch (env) {
			case ENVIRONMENT.CENTRALUS:
			default:
				region = 'US';
				break;
			case ENVIRONMENT.WESTEUROPE:
				region = 'EU';
				break;
			case ENVIRONMENT.AUSTRALIAEAST:
				region = 'AU';
		}

		const baseUrl: string = 'https://ms.portal.azure.com/?feature.canmodifystamps=true&Microsoft_Azure_BotService=v4';
		const appIdParam: string = `Microsoft_Azure_BotService_LuisAppId=${this._baseService.configs.appId}`;
		const regionParam: string = `Microsoft_Azure_BotService_LuisRegion=${region}`;

		return `${baseUrl}&${appIdParam}&${regionParam}#create/Microsoft.BotServiceSdkGalleryPackage`;
	}
}
