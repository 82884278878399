import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { IToasterService, ProgressTracker, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../../interfaces/IEntityService';
import { ClosedEntity, ClosedSublist } from '../../../../models/closed-entity.model';
import { Suggestion, SuggestionApiParameter } from '../../../../models/suggestion.model';
import { EntityUtilitiesService } from '../../../../services/entity-util.service';

/**
 * @description
 * Contains functioniality to get suggestions for closed
 * list entities.
 */
@Component({
    selector: 'suggestion-builder',
    templateUrl: 'suggestion-builder.component.html',
    styleUrls: ['suggestion-builder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionBuilderComponent implements OnInit, OnDestroy {
    @Input() public entity: Observable<ClosedEntity>;
    @Input() public onNewValueEntered: Observable<boolean>;
    @Output() public onEnterClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() public previousSuggestion: EventEmitter<Suggestion> = new EventEmitter<Suggestion>();

    public suggestions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public suggestionTrigger: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public suggestionsTracker: ProgressTracker = new ProgressTracker();

    private _triggerSubscription: Subscription = new Subscription();
    private _valueEntrySubscription: Subscription = new Subscription();
    private _previousSuggestion: BehaviorSubject<Suggestion> = new BehaviorSubject<Suggestion>(null);
    private readonly _triggerAt: number = 2;

    constructor(
        private _entityUtilService: EntityUtilitiesService,
        private _i18n: TranslateService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        this._triggerSubscription.unsubscribe();
        this._valueEntrySubscription.unsubscribe();
    }

    /**
     * @method
     * @description
     * Gets suggestions for the given values. If no values are present, the function
     * does nothing.
     */
    public getSuggestions(): void {
        this.entity
            .first()
            .map(e => {
                if (e.sublists.length > 0) {
                    this._previousSuggestion.asObservable()
                        .flatMap(pSugg => pSugg ? Observable.of(pSugg) : this._entityUtilService.getPreSuggestions(e.id)
                            .catch(err => Observable.of(new Suggestion([], null)))
                        )
                        .do(pSugg => this.previousSuggestion.emit(pSugg))
                        .flatMap(pSugg => this._entityUtilService.getSuggestions(e.id,
                            new SuggestionApiParameter(e.sublists.map(et => et.name), 10, pSugg)))
                        .first()
                        .trackProgress(this._toasterService.add({
                            startMsg: this._i18n.instant('entities.suggestion-builder.fetching_toast')
                        }))
                        .trackProgress(this.suggestionsTracker.getTracker())
                        .subscribe(suggestion => {
                            if (suggestion) {
                                this._previousSuggestion.next(suggestion);
                                this.suggestions.next(suggestion.entries);
                            }
                        });
                }
            })
            .subscribe();
    }

    /**
     * @method
     * @description
     * Adds a suggestion to the phrase list phrases and the phrases stream.
     *
     * @param suggestion The suggestion to add.
     */
    public addSuggestion(suggestion: string): void {
        this.entity
            .first()
            .map(e => new ClosedSublist(-1, suggestion, [], e))
            .do(e => e.parent.sublists = [...e.parent.sublists, e])
            .flatMap(e => this._entityService.add(e))
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.suggestion-builder.add_toast') }))
            .subscribe();
    }

    /**
     * @method
     * @description
     * Adds all the suggestions available to the current phrases stream
     * and the phrase list phrases.
     */
    public addAllSuggestions(): void {
        const currSuggestions: string[] = this.suggestions.getValue();
        let sublists: ClosedSublist[];
        this.suggestions.next([]);
        this.getSuggestions();

        this.entity
            .first()
            .do(e => sublists = currSuggestions.map(s => new ClosedSublist(-1, s, [], e)))
            .map(e => new ClosedEntity(e.id, e.name, [...e.sublists, ...sublists], e.roles, e.parent))
            .flatMap(e => this._entityService.update(e, true))
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.suggestion-builder.update_toast') }))
            .subscribe();
    }

    /**
     * @description
     * Initializes the component state.
     */
    private _initState(): void {
        this._valueEntrySubscription = this.onNewValueEntered
            .do(() => this._checkSuggestionTrigger(1))
            .subscribe(() => null);

        this._triggerSubscription = this.suggestionTrigger.subscribe(count => {
            if (count > this._triggerAt) {
                this.getSuggestions();
                this.suggestionTrigger.next(0);
            }
        });

        this.getSuggestions();
    }

    /**
     * @method
     * @description
     * Updates the value count increase tracker stream. This value triggers suggestion
     * fetching if it more than the defined 'triggerAt' value.
     *
     * @param valueIncrease The value to increase the trigger tracker stream by.
     */
    private _checkSuggestionTrigger(valueIncrease: number): void {
        let currentTriggerVal: number = this.suggestionTrigger.getValue();
        currentTriggerVal += valueIncrease;
        this.suggestionTrigger.next(currentTriggerVal);
    }
}
