import { Component, HostListener, OnInit } from '@angular/core';
import { IFocusable, RIGHT_ARROW } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'base-item',
    template: '<ng-content></ng-content>'
})
export class BaseItemComponent implements OnInit, IFocusable {
    public isSubMenuVisible: Observable<boolean>;

    private _isSubMenuVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    ngOnInit(): void {
        this._initComponent();
    }

    /**
     * @description
     * Will be overridden in children.
     */
    public focus(): boolean {
        return true;
    }

    /**
     * @description
     * Marks the submenu in the base component as visible.
     */
    public showSubMenu(): void {
        this._isSubMenuVisible.next(true);
    }

    /**
     * @description
     * Marks the submenu in the base component as invisible
     * and focuses on the current element.
     */
    public hideSubMenu(focusParent: boolean = false): void {
        this._isSubMenuVisible.next(false);
        if (focusParent) {
            this.focus();
        }
    }

    /**
     * @description
     * Handles the keydown for accessbility for this component.
     */
    @HostListener('keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent): void {
        switch (event.keyCode) {
            case RIGHT_ARROW:
                this.showSubMenu();
                break;
            default:
        }
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initComponent(): void {
        this.isSubMenuVisible = this._isSubMenuVisible
            .asObservable()
            .distinctUntilChanged()
            .debounceTime(150);
    }
}
