import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Entity, ENTITY_SERVICE_TOKEN, EntityHelpers, IEntityService } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { BLADE_OPS, BLADES, BladeTrackerService } from '@luis/ui';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { filter, flatMap, map, shareReplay, skip, startWith, takeUntil } from 'rxjs/operators';
import { EvaluationFilters } from '../../models/filters/evaluation-filter.model';

/**
 * @description
 * Represents the filters blade.
 */
@Component({
	selector: '[filter-blade]',
	templateUrl: 'filter-blade.component.html',
	styleUrls: ['filter-blade.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBladeComponent implements OnInit, OnDestroy {
	@Input() public results: Observable<number>;
	@Input() public filterButtonClicked: Observable<void>;
	@Input() public intentsToChoose: Observable<Intent[]>;
	@Input() public filteredEntities: Observable<Map<string, Entity>>;
	@Input() public filteredIntents: Observable<Map<string, Intent>>;
	@Input() public activeEvaluations: BehaviorSubject<EvaluationFilters> = new BehaviorSubject<EvaluationFilters>({
		correct: false,
		incorrect: false,
		unclear: false
	});
	@Input() public active: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	@Output() public evaluationFilter: EventEmitter<EvaluationFilters> = new EventEmitter<EvaluationFilters>();
	@Output() public clearFilters: EventEmitter<void> = new EventEmitter<void>();
	@Output() public filterUtterancesByIntent: EventEmitter<Intent> = new EventEmitter<Intent>();
	@Output() public filterUtterancesByEntity: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() public scoreFilter: EventEmitter<[number, number]> = new EventEmitter<[number, number]>();

	public containerPosition: Observable<number>;
	public activeBlade: Observable<BLADES>;
	public states: Observable<Map<BLADES, boolean>>;

	public entitiesToChoose: Observable<Entity[]>;
	public containerWidth: Observable<number>;
	public bladeWidth: Observable<number>;

	public readonly blades: typeof BLADES = BLADES;

	private readonly _isPaneOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _destroy: Subject<boolean> = new Subject<boolean>();

	private readonly _bladeWidth: number = 400;
	private readonly _containerWidth: number = 1200;

	constructor(
		private readonly _bladeTrackerService: BladeTrackerService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._destroy.next(true);
		this._destroy.unsubscribe();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.entitiesToChoose = this._entityService.get().map(es => EntityHelpers.expandChildren(es));

		this.states = this._bladeTrackerService.getBladeStates();

		this.activeBlade = this._bladeTrackerService.getActiveBlade();

		this.containerWidth = fromEvent(window, 'resize').pipe(
			map(_ => (this._containerWidth < window.innerWidth ? this._containerWidth : window.innerWidth)),
			startWith(this._containerWidth)
		);

		// Controls the width of the blades based on the blade count and the container width.
		this.bladeWidth = this.containerWidth.pipe(
			map(width => width / 3),
			shareReplay()
		);

		// Controls the container position based on the current active blade and whether the pane is open or not.
		this.containerPosition = combineLatest(this._isPaneOpen.asObservable(), this.activeBlade, this.containerWidth).pipe(
			map(([isPaneOpen, blade, containerWidth]) => (isPaneOpen && blade ? this._bladeWidth - containerWidth : -containerWidth - 100))
		);

		// Toggles the test pane close status whenever the test button trigger is recieved.
		this.filterButtonClicked
			.pipe(
				takeUntil(this._destroy),
				skip(1)
			)
			.subscribe(() => this._isPaneOpen.next(!this._isPaneOpen.getValue()));

		// Closes the pane if no active blades were found. That means that the user closed all blades.
		this.activeBlade.pipe(takeUntil(this._destroy)).subscribe(blade => (!blade ? this._isPaneOpen.next(false) : undefined));

		// Opens the filter blade by default if the filter pane is opened and no active blades were found.
		this._isPaneOpen
			.asObservable()
			.pipe(
				filter(isFilterPaneOpen => isFilterPaneOpen),
				flatMap(() => this.activeBlade.first()),
				map(activeBlade => (!activeBlade ? this._bladeTrackerService.applyOp(BLADE_OPS.FILTER_OPEN) : null)),
				takeUntil(this._destroy)
			)
			.subscribe();
	}
}
