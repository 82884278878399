import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * @description
 * Ensures that the website only loads when the translation files are loaded into memory.
 *
 * @param i18n The translation service.
 * @param injector Dependency injection container instance.
 */
export const appTranslationInitializerFactory = (i18n: TranslateService, injector: Injector) => {
    return () => new Promise(resolve => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve());

        locationInitialized.then(() => {
            i18n.setDefaultLang('en');
            i18n.use('en').subscribe(undefined, err => console.error('Problem with language initialization.'), resolve);
        });
    });
};
