import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

export type ItemHeight = 'normal' | 'full';

/**
 * @description
 * Represents an item in the sidebar page.
 */
@Component({
    selector: '[m-sidebar-item]',
    templateUrl: 'sidebar-item.component.html',
    styleUrls: ['sidebar-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarItemComponent {
    @Input() public isActive: boolean = false;
    @Input() public icon: string = null;
    @Input() public height: ItemHeight = 'normal';

    @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();
}
