import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    InjectionToken,
    Input,
    OnInit
} from '@angular/core';
import { BaseModalComponent, IModalComponent, ProgressTracker } from '@luis/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { BATCH_TESTING_SERVICE_TOKEN, IBatchTestingService } from '../../../interfaces/IBatchTestingService';
import { DatasetMetadata } from '../../../models/dataset-metadata.model';

export const RENAME_DATASET_MODAL_COMP_TOKEN: InjectionToken<string> = new InjectionToken('DatasetRenameModalComponent');

/**
 * @description
 * Represents the modal for importing a new dataset.
 */
@Component({
    selector: 'dataset-rename-modal',
    templateUrl: 'dataset-rename-modal.component.html',
    styleUrls: ['dataset-rename-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetRenameModalComponent extends BaseModalComponent implements OnInit, IModalComponent<void> {
    @Input() public response: Subject<void>;
    @Input() public datasetToRename: DatasetMetadata;

    public dataset: DatasetMetadata;
    public isButtonDisabled: Observable<boolean>;
    public _tracker: ProgressTracker = new ProgressTracker();
    private _isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        @Inject(BATCH_TESTING_SERVICE_TOKEN) private _batchTestingService: IBatchTestingService
    ) { super(); }

    public ngOnInit(): void {
        super.ngOnInit();
        this._initState();
    }

    /**
     * @method
     * @description
     * Notifies any listeners to the stream whether the dataset is valid or not.
     */
    public onModelChange(): void {
        this._isValid.next(this._isDatasetValid(this.dataset));
    }

    /**
     * @method
     * @description
     * Validates the dataset, and if successfully validated, renames it.
     */
    public renameDataset(): void {
        if (this._isDatasetValid(this.dataset)) {
            this._batchTestingService.rename(this.dataset)
                .trackProgress(this._tracker.getTracker())
                .subscribe(() => {
                    this.response.next();
                    this.response.complete();
                });
        }
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.dataset = this.datasetToRename.clone();

        this.isButtonDisabled = Observable.combineLatest(
            this._tracker.isStarted,
            this._isValid
        )
            .startWith([false, true])
            .map(data => {
                const [inProgress, isValid] = data;

                return inProgress || !isValid;
            });
    }

    /**
     * @description
     * Checks if the dataset name is not empty.
     *
     * @param dataset The dataset to check.
     * @returns True if the dataset is valid and false otherwise.
     */
    private _isDatasetValid(dataset: DatasetMetadata): boolean {
        return dataset.name.trim().length !== 0;
    }
}
