﻿import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import {
	BUS_EVENTS,
	EventBusService,
	IToasterService,
	ITrackerMessage,
	PROGRESS_STATES,
	TOASTER_SERVICE_TOKEN,
	retrieveLanguage
} from '@luis/core';
import { IKeyStoreService, KEY_STORE_SERVICE_TOKEN } from '@luis/publish-pane';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs/Rx';
import { ApplicationComponent } from '../application/application.component';
import { LoaderPageService } from '../common/services/loader-page.service';

@Component({
	selector: 'luis-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
	public showGlobalToaster: Observable<boolean>;
	public showLoader: Observable<boolean>;
	public toasterPosition: number;
	private pageLoadingTracker: Observer<ITrackerMessage>;

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _titleService: Title,
		private _eventBus: EventBusService,
		private _loaderPageService: LoaderPageService,
		private _i18n: TranslateService,
		@Inject(KEY_STORE_SERVICE_TOKEN) private _keyStoreService: IKeyStoreService,
		@Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._loaderPageService.setVisibility(true);
		this._initServices();
		this._subscribeToEventBus();
		this._initRouterSubscriptions();
		this._initI18n();
	}

	/**
	 * @description
	 * Initializes one-time fire services that are needed
	 * application wide.
	 */
	private _initServices(): void {
		this._keyStoreService.initService();

		this.showLoader = this._loaderPageService.observe().distinctUntilChanged();

		this._loaderPageService.setVisibility(true);

		// Ensures that the loader remains visible until the first navigation succeeds.
		this._router.events
			.filter(e => e instanceof NavigationEnd)
			.first()
			.subscribe(() => this._loaderPageService.setVisibility(false));
	}

	/**
	 * @description
	 * Subscribes to any events on the event bus.
	 */
	private _subscribeToEventBus(): void {
		this._eventBus.subscribeToBus(BUS_EVENTS.MODAL_TOGGLED, isOpen => (this.toasterPosition = isOpen ? 0 : null));
	}

	/**
	 * @description
	 * Fixes an angular router bug that ensures that the page is
	 * always scrolled to the top when navigated to.
	 */
	private _initRouterSubscriptions(): void {
		this.showGlobalToaster = this._router.events
			.filter(e => e instanceof NavigationEnd)
			.map(e => this._isComponentInRoutePath(this._router.routerState.root, ApplicationComponent))
			.startWith(true);

		this._router.events
			.filter((e: RouterEvent) => e.url !== this._router.routerState.snapshot.url)
			.subscribe(() => window.scrollTo(0, 0));

		this._router.events.filter(e => e instanceof NavigationStart).subscribe(() => this._refreshToastLoader());

		this._router.events
			.filter(e => e instanceof NavigationEnd)
			.subscribe(() => this.pageLoadingTracker.next({ state: PROGRESS_STATES.ENDED }));

		this._router.events
			.filter(e => e instanceof NavigationEnd)
			.map(() => this._activatedRoute)
			.map(route => {
				let iterableRoute: ActivatedRoute = route;

				while (iterableRoute.firstChild) {
					iterableRoute = iterableRoute.firstChild;
				}

				return iterableRoute;
			})
			.filter(route => route.outlet === 'primary')
			.mergeMap(route => route.data)
			.subscribe(data => this._titleService.setTitle((<any>data).title));
	}

	/**
	 * @description
	 * Initializes localization settings.
	 */
	private _initI18n(): void {
		const persistedLang = retrieveLanguage();
		this._i18n.defaultLang = persistedLang;
		this._i18n.use(persistedLang);
	}

	/**
	 * @description
	 * Checks if the given component is activated in the current url path.
	 *
	 * @param route The route to the check.
	 * @param component The component to check.
	 * @returns True if the component is in the route and false otherwise.
	 */
	private _isComponentInRoutePath(route: ActivatedRoute, component: any): boolean {
		let routeToTravser: ActivatedRoute = route;

		while (routeToTravser.children.length) {
			routeToTravser = routeToTravser.children[0];
			if (routeToTravser.component === component) {
				return false;
			}
		}

		return true;
	}

	/**
	 * @description
	 * Refreshes the toast notification by removing and readding it.
	 */
	private _refreshToastLoader(): void {
		if (this.pageLoadingTracker) {
			this.pageLoadingTracker.next({ state: PROGRESS_STATES.ENDED });
		}
		this.pageLoadingTracker = this._toasterService.add({ startMsg: this._i18n.instant('portal.app.loading_page') });
	}

	/**
	 * @description
	 * Creates and appends a script element with the given src.
	 *
	 * @param src The src attribute to use on the script.
	 */
	private _createScriptElement(src: string): void {
		const scriptElement: HTMLScriptElement = document.createElement('script');
		scriptElement.src = src;
		scriptElement.charset = 'UTF-8';
		document.head.appendChild(scriptElement);
	}
}
