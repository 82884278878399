import { InjectionToken } from '@angular/core';
import { AppVersion } from '@luis/apps';
import { PersistantPublishSettings } from '@luis/publish-pane';
import { Observable } from 'rxjs';
import { IntentMetadata } from '../models/intent-metadata.model';
import { IntentTrainingResult } from '../models/intent-training-result.model';
import { FaultyIntent, TrainingResultMetaData, VersionsComparison } from '../models/training-result-metadata.model';

export const TRAINING_RESULT_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('ITrainingResultService');

/**
 * @description
 * Represents an interface for an training result service.
 */
export interface ITrainingResultService {
	initService(): void;
	resetService(): void;

	getVersions(): Observable<AppVersion[]>;
	getPublishSetting(isStaging?: boolean): Observable<PersistantPublishSettings>;

	getLastTrainingDate(versionId?: string): Observable<Date>;
	getStatus(version?: string): Observable<number>;

	getMetaData(version?: string): Observable<TrainingResultMetaData>;

	getIntentsMetaData(): Observable<IntentMetadata[]>;
	getIntentsWithLowNumOfUtterances(): Observable<IntentMetadata[]>;
	getIntentsWithHighNumOfUtterances(): Observable<IntentMetadata[]>;
	getAmbiguousIntents(): Observable<IntentMetadata[]>;
	getIncorrectIntents(): Observable<IntentMetadata[]>;

	getAmbiguousWith(intentId: string): Observable<FaultyIntent[]>;
	getIncorrectWith(intentId: string): Observable<FaultyIntent[]>;

	compareWithVersion(version: string): Observable<VersionsComparison>;

	getFaultyUtterancesPerIntent(intentId: string): Observable<IntentTrainingResult>;
}
