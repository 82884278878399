import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { ISortPipeProps, SORT_TYPE } from '../interfaces/ISortPipeProps';

/**
 * @description
 * Generates the correct aria sorting class based on the current header cell
 * property and the sorting properties.
 */
@Pipe({
    name: 'ariaSortAsync'
})
export class AriaSortAsyncPipe implements PipeTransform {
    public transform(propertyName: string, sortProps: Observable<ISortPipeProps>): Observable<string> {
        return sortProps
            .map(s => {
                if (propertyName && s.property === propertyName) {
                    return s.type === SORT_TYPE.ASCENDING ? 'ascending' : 'descending';
                }

                return 'none';
            });
    }
}
