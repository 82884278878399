/**
 * @description
 * A utility class for pipe functions that can be applied to
 * map/combinatory/mutation pipes.
 */
export class PipeUtilityFunctions {

    /**
     * @method
     * @description
     * Checks if the given data array is empty or not.
     *
     * @param data The data array to check.
     * @returns True if empty and false otherwise.
     */
    public static isEmpty(data: Object[]): boolean {
        return data !== undefined && data.length === 0;
    }
}
