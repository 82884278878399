import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '../../shared/shared.module';
import { ItemsContainerComponent } from './containers/items-container/items-container.component';
import { EntityDropdownComponent } from './entity-dropdown.component';
import { BaseItemComponent } from './items/base-item/base-item.component';
import { MenuItemComponent } from './items/menu-item/menu-item.component';
import { MenuSearchItemComponent } from './items/menu-search-item/menu-search-item.component';
import { NewMenuItemComponent } from './items/new-menu-item/new-menu-item.component';
import { PlainItemComponent } from './items/plain-item/plain-item.component';
import { EntityStatusMenuComponent } from './sub-menus/entity-status-menu/entity-status-menu.component';
import { HierarchicalMenuComponent } from './sub-menus/hierarchical-menu/hierarchical-menu.component';
import { NormalizedValuesMenuComponent } from './sub-menus/normalized-values-menu/normalized-values-menu.component';
import { RolesMenuComponent } from './sub-menus/roles-menu/roles-menu.component';
import { WrapMenuContentComponent } from './sub-menus/wrap-menu-content/wrap-menu-content.component';
import { CompositeChildrenMenuContentComponent } from './sub-menus/composite-children-menu-content/composite-children-menu-content.component';

@NgModule({
	imports: [SharedModule, MatMenuModule],
	declarations: [
		EntityDropdownComponent,
		PlainItemComponent,
		EntityStatusMenuComponent,
		BaseItemComponent,
		ItemsContainerComponent,
		WrapMenuContentComponent,
		MenuSearchItemComponent,
		RolesMenuComponent,
		NormalizedValuesMenuComponent,
		HierarchicalMenuComponent,
		NewMenuItemComponent,
		MenuItemComponent,
		CompositeChildrenMenuContentComponent
	],
	exports: [
		EntityDropdownComponent,
		PlainItemComponent,
		EntityStatusMenuComponent,
		BaseItemComponent,
		ItemsContainerComponent,
		WrapMenuContentComponent,
		MenuSearchItemComponent,
		RolesMenuComponent,
		NormalizedValuesMenuComponent,
		HierarchicalMenuComponent,
		NewMenuItemComponent,
		MenuItemComponent,
		CompositeChildrenMenuContentComponent
	]
})
export class DropdownsModule {}
