import { InjectionToken } from '@angular/core';
import { IRequestOptions } from '@luis/api';
import { Observable } from 'rxjs/Rx';
import { IApiExportable } from '../../models/IApiExportable';

export const GENERIC_CACHE_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IGenericCacheService');

// tslint doesn't play nicely with this new syntax
// tslint:disable-next-line
export type ReturnTypeEnhanced<T extends (...args: any[]) => any | null> = T extends (...args: any[]) => infer R ? R : Object;

/**
 * @description
 * Represents an interface for a generic data service. A data service is responsible for
 * making web calls and cacheing their results in a central cache for all LUIS generic
 * data across the service.
 */
export interface IGenericCacheService {
	get<T extends (...args: any[]) => any | null>(path: string, parser: T, options: IRequestOptions): Observable<ReturnTypeEnhanced<T>>;

	post(path: string, data: IApiExportable | any, options: IRequestOptions): Observable<void>;

	put(path: string, data: IApiExportable | any, options: IRequestOptions): Observable<void>;

	delete(path: string, options: IRequestOptions): Observable<boolean>;

	invalidate(path: string): void;
}
