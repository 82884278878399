import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';

@Component({
    selector: 'tile',
    styleUrls: ['tile.component.scss'],
    templateUrl: 'tile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent { }
