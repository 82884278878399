import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';

/**
 * @description
 * Represents a data transform pipe for arrays. Checks if the given query exactly matches
 * the field of any of the given result set or not. Returns true if a non-exact match is found
 * and false if the query does not exist or matches exactly.
 */
@Pipe({
    name: 'exactMatchAsync'
})
export class ExactMatchAsyncPipe implements PipeTransform {

    public transform(
        resultSet: Observable<Object[]>, field: string,
        query: Observable<string>, caseSensitive: boolean = false
    ): Observable<boolean> {
        return Observable.combineLatest(resultSet, query).map(data => {
            const [objects, queryString] = data;

            if (!queryString) {
                return false;
            }

            if (objects.length === 0) {
                return true;
            }

            return this._isExactMatch(objects, field, queryString, caseSensitive) === false;
        });
    }

    /**
     * @method
     * @description
     * Given an unpacked array of data and query string return true or
     * false indicating if there is an exact match.
     *
     * @param resultSet Result set filtered from pipes.
     * @param query The value of search field.
     * @param caseSensitive A flag indicating if match is case-sensistive or not.
     */
    private _isExactMatch(resultSet: any[], field: string, query: string, caseSensitive: boolean): boolean {
        if (!caseSensitive) {
            query = query.toLocaleLowerCase();
        }

        return resultSet
            .map(i => caseSensitive ? i[field] : i[field].toLocaleLowerCase())
            .filter(name => name === query).length !== 0;
    }
}
