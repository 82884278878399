import { ISortPipeProps } from '@luis/ui';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Id, IResource } from '../../interfaces/models/IResource';

export interface IPageInfo {
	skip: number;
	take: number;
}

/**
 * @description
 * This the type of payload that the paginated cache model returns when asked for a page or all pages.
 */
export interface IPage<T extends IResource> {
	data: T[];
	totalItems?: number;
	isRefreshing: boolean;
	isInitialized: boolean;
}

/**
 * @description
 * Represents a reactive model cache. Implements basic caching functions. Notifies subscribers
 * when changes in the cache occur. Supports defragmenting itself whenever a delete or add operation is
 * performed.
 * Note: This assumes that there is only one active subscriber at any given time.
 */
export class PaginatedCache<T extends IResource> {
	public isChunkingProcessRunning: boolean = false;
	public isInitialized: boolean = false;

	private _pages: Map<number, Map<Id, T>>;
	private _outOfOrderItems: Map<Id, T>;

	private _selectedPageIndex: number = -1;
	private _emptyPage: IPage<T> = {
		data: [],
		totalItems: 0,
		isInitialized: false,
		isRefreshing: false
	};
	private _selectedPageStream: BehaviorSubject<IPage<T>> = new BehaviorSubject<IPage<T>>(this._emptyPage);

	private _allPagesStream: BehaviorSubject<IPage<T>> = new BehaviorSubject<IPage<T>>(this._emptyPage);

	private _isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private _pageSize: number = 10, private _totalSize: number, private _sortingProps: ISortPipeProps) {
		this._pages = new Map();
		this._outOfOrderItems = new Map();
	}

	/**
	 * @description
	 * Helper static method that converts native JS Maps into Arrays.
	 *
	 * @param map The map to be converted.
	 * @returns An array of the items from the given map.
	 */
	private static _convertMapToArray<T>(map: Map<Id, T>): T[] {
		return [...map.values()];
	}

	/**
	 * @description
	 * Getter function that returns the total number of items in the backend.
	 */
	public get totalSize(): number {
		return this._totalSize;
	}

	/**
	 * @description
	 * Setter function that sets the total number of items in the backend.
	 */
	public set totalSize(newTotalSize: number) {
		this._totalSize = newTotalSize;
	}

	/**
	 * @description
	 * Getter function that returns the current number of items in the cache.
	 */
	public get currentSize(): number {
		let totalSize = 0;
		for (const [, page] of this._pages) {
			totalSize = totalSize + page.size;
		}

		return totalSize;
	}

	private get _numOfPages(): number {
		return Math.max(this._pages.size, Math.ceil(this._totalSize / this._pageSize));
	}

	/**
	 * @description
	 * Sets the refreshing stream to true or false. This indicates to the UI whether
	 * there is a backend request in-flight or not.
	 */
	public setRefreshing(isRefreshing: boolean): void {
		this._isRefreshing.next(isRefreshing);
	}

	/**
	 * @description
	 * Checks whether the given page index is already fetched or not.
	 */
	public hasPage(pageIndex: number): boolean {
		return this._pages.has(pageIndex);
	}

	/**
	 * @description
	 * Invalidates the cache starting from the first missing page. This is usually used together with
	 * the chunking service to avoid having gaps while doing the chunking process.
	 *
	 * @param all When true the cache gets invalidated all together.
	 */
	public invalidate(all: boolean = false): number {
		if (all) {
			this._pages.clear();
			this.isInitialized = false;
			this._selectedPageIndex = -1;

			return 0;
		}

		let emptyPageIndex = -1;
		const numOfPages = this._numOfPages;
		for (let index = 0; index < numOfPages; index = index + 1) {
			const page = this._pages.get(index);
			if ((page === undefined || page.size < this._pageSize) && emptyPageIndex === -1) {
				emptyPageIndex = index;
			}

			if (emptyPageIndex !== -1) {
				this._pages.delete(index);
			}
		}
		if (this.currentSize === 0) {
			this.isInitialized = false;
		}

		return emptyPageIndex;
	}

	/**
	 * @description
	 * Gets a page stream of the specified index. Will be notified whenever the data in the cache changes.
	 *
	 * @param pageIndex The index of the requested page.
	 * @returns An observable of the specified page.
	 */
	public getPageStream(pageIndex: number): Observable<IPage<T>> {
		this._updateStreams(pageIndex);

		return combineLatest(this._selectedPageStream, this._isRefreshing).pipe(
			map(([page, isRefreshing]) => ({
				...page,
				isRefreshing
			}))
		);
	}

	/**
	 * @description
	 * Gets a stream of all the items inside the cache. Will be notified whenever the data inside the cache changes.
	 *
	 * @returns An observable of all the items inside cache
	 */
	public getAllStream(): Observable<IPage<T>> {
		return this._allPagesStream.pipe(
			map(() => {
				const data = this.getAll();

				return {
					data,
					isRefreshing: false,
					isInitialized: data.length === 0 && this._totalSize !== 0 ? false : true
				};
			})
		);
	}

	/**
	 * @description
	 * Gets the model with the given id. If the model doesn't exist,
	 * returns null.
	 *
	 * @param id The model id to get.
	 * @returns The model with the given id if found and null if not found.
	 */
	public getById(id: Id): T {
		return this._get(id);
	}

	/**
	 * @description
	 * Gets the page at the specified index.
	 *
	 * @param pageIndex The index of the requested page.
	 * @returns The requested page.
	 */
	public getPage(pageIndex: number): IPage<T> {
		const page = this._pages.get(pageIndex);
		const misplacedModels = pageIndex === 0 ? PaginatedCache._convertMapToArray(this._outOfOrderItems) : [];

		if (page === undefined || !this.isInitialized) {
			return {
				data: misplacedModels,
				totalItems: this.totalSize,
				isRefreshing: this._isRefreshing.getValue(),
				isInitialized: false
			};
		}

		return {
			data: [...misplacedModels, ...PaginatedCache._convertMapToArray(page)],
			totalItems: this.totalSize,
			isRefreshing: this._isRefreshing.getValue(),
			isInitialized: true
		};
	}

	/**
	 * @description
	 * Gets all the items inside the cache.
	 *
	 * @returns An array of all the items inside cache
	 */
	public getAll(): T[] {
		const misplacedModels = PaginatedCache._convertMapToArray(this._outOfOrderItems);
		let res = [];
		for (const page of this._pages.values()) {
			const pageArr = PaginatedCache._convertMapToArray(page);
			res = [...res, ...pageArr];
		}

		return res.concat(misplacedModels);
	}

	/**
	 * @description
	 * Adds a page of items to the cache at the specified index.
	 *
	 * @param models An array of items to be inserted
	 * @param pageIndex The page index that items will be inserted in.
	 */
	public addPage(models: T[], pageIndex: number): void {
		this.isInitialized = true;

		if (models.length > this._pageSize) {
			console.error('Pages cannot exceed the page size limit');

			return;
		}

		if (models === undefined || models.length === 0) {
			this._pages.set(pageIndex, new Map());
		} else {
			models.forEach(model => this._addToPage(model, pageIndex, true));
			this._sortPage(this._pages.get(pageIndex));
		}

		this._updateStreams();
	}

	/**
	 * @description
	 * Adds or updates a model in the cache.
	 *
	 * @param model The model to be added or updated.
	 * @param idToReplace An optional model id to replace the new model with.
	 * @param outOfOrder An optional flag when set, the added model will be stored separately
	 * and viewed in the first page as the first element. When the item gets added again with its correct
	 * position it gets removed from the first page.
	 *
	 * @returns The page the needs to be refreshed.
	 */
	public addOrUpdate(model: T, idToReplace?: Id, outOfOrder?: boolean): IPageInfo | undefined {
		const requiresDefragmentation = this._addOrUpdate(model, idToReplace, outOfOrder);
		let pageInfo: IPageInfo;

		if (requiresDefragmentation) {
			pageInfo = this._defragment();
		}
		this._updateStreams();

		return pageInfo;
	}

	/**
	 * @description
	 * Deletes the model with the given id.
	 *
	 * @param id The id of the model to delete.
	 * @returns The page the needs to be refreshed.
	 */
	public delete(id: Id): IPageInfo | undefined {
		const requiresDefragmentation = this._delete(id);

		if (requiresDefragmentation) {
			const pageInfo = this._defragment();
			this._updateStreams();

			return pageInfo;
		}

		this._updateStreams();
	}

	/**
	 * @description
	 * Deletes a batch of models with the given ids.
	 *
	 * @param id The ids of the models to delete.
	 * @returns The page the needs to be refreshed.
	 */
	public deleteBatch(ids: Id[]): IPageInfo | undefined {
		const requiresDefragmentation = ids.map(id => this._delete(id)).reduce((acc, currentVal) => acc || currentVal, false);

		if (requiresDefragmentation) {
			const pageInfo = this._defragment();
			this._updateStreams();

			return pageInfo;
		}

		this._updateStreams();
	}

	/**
	 * @description
	 * Merges a batch of new models or updates if were existing already. Notifies
	 * subscribers with change.
	 *
	 * @param models The models to add or update.
	 * @param updateFunction An optional update the function that can be used to merge
	 * two models together
	 *
	 * @returns The page the needs to be refreshed.
	 */
	public mergeBatch(models: T[], updateFunction?: (oldModel: T, newModel: T) => T): IPageInfo | undefined {
		if (models.length === 0) {
			return;
		}

		const requiresDefragmentation = models
			.map(model => this._addOrUpdate(model, undefined, false, updateFunction))
			.reduce((acc, currentVal) => acc || currentVal, false);

		if (requiresDefragmentation) {
			return this._defragment();
		}
		this._updateStreams();
	}

	/**
	 * @description
	 * Adds a model to a specific page.
	 *
	 * @param model The model to be instered.
	 * @param pageIndex The page index that the model will be inserted in.
	 * @param removeOutOfOrder A boolean flag indicating whether we should delete the added item from the
	 * misplaced models.
	 */
	private _addToPage(model: T, pageIndex: number = 0, removeOutOfOrder: boolean = false): void {
		let page = this._pages.get(pageIndex);
		if (!page) {
			page = new Map();
			this._pages.set(pageIndex, page);
		}
		page.set(model.id, model);

		// Remove the model from the misplaced models list if it exists
		if (removeOutOfOrder) {
			this._outOfOrderItems.delete(model.id);
		}
	}

	/**
	 * @description
	 * Adds a new model or updates if it was existing already.
	 *
	 * @param model The model to add or update.
	 * @param idToReplace An optional model id to replace the new model with.
	 * This ensures that the new model appears in the same order with the old model.
	 *
	 * @returns Whether the cache needs to be fragmented or not.
	 */
	private _addOrUpdate(model: T, idToReplace?: Id, outOfOrder?: boolean, updateFunction?: (oldModel: T, newModel: T) => T): boolean {
		const oldModel = this._get(model.id);

		if (outOfOrder && oldModel === null) {
			if (!this._outOfOrderItems.has(model.id)) {
				this._totalSize = this._totalSize + 1;
			}

			this._outOfOrderItems.set(model.id, model);

			return false;
		}

		if (oldModel !== null && updateFunction !== undefined) {
			// Update the already existing model with the update function
			const newModel = updateFunction(oldModel, model);

			this._replace(newModel, model.id);
		} else if (oldModel !== null) {
			// Update the already existing model
			this._replace(model, model.id);
		} else if (idToReplace !== undefined) {
			// Replace an item with another one
			this._replace(model, idToReplace);
		} else {
			// Create a new item
			this._addToPage(model, 0);
			this._sortPage(this._pages.get(0));
			this._totalSize = this._totalSize + 1;

			return true;
		}

		return false;
	}

	/**
	 * @description
	 * Gets the model with the given id. If the model doesn't exist,
	 * returns null.
	 *
	 * @param id The model id to get.
	 * @returns The model with the given id if found and null if not found.
	 */
	private _get(id: Id): T {
		for (const page of this._pages.values()) {
			if (page.has(id)) {
				return page.get(id);
			}
		}

		return null;
	}

	/**
	 * @description
	 * Deletes the model with the specified id.
	 *
	 * @param id The id of the model that should be deleted.
	 * @returns True if the id is found, false otherwise.
	 */
	private _delete(id: Id): boolean {
		if (this._outOfOrderItems.has(id)) {
			this._outOfOrderItems.delete(id);
			this._totalSize = this._totalSize - 1;

			return false;
		}

		for (const page of this._pages.values()) {
			if (page.has(id)) {
				page.delete(id);
				this._totalSize = this._totalSize - 1;

				return true;
			}
		}

		return false;
	}

	/**
	 * @description
	 * Replaces the model with the given id with the provided model.
	 *
	 * @param model The new model.
	 * @param id The id of the model to be replaced.
	 */
	private _replace(model: T, id: Id): void {
		for (const page of this._pages.values()) {
			if (page.has(id)) {
				page.delete(id);
				page.set(model.id, model);
				this._sortPage(page);
			}
		}
	}

	/**
	 * @description
	 * Updates both the selectedPageStream and the allPagesStream. This is usually invoked when
	 * there is a data change happening in the cache.
	 *
	 * @param pageIndex An optional page index that can be used to update the currently selected page index
	 * inside the cache.
	 */
	private _updateStreams(pageIndex?: number): void {
		this._allPagesStream.next({
			data: this.getAll(),
			isInitialized: true,
			isRefreshing: false
		});

		if (pageIndex === this._selectedPageIndex) {
			return;
		}

		if (pageIndex !== undefined) {
			this._selectedPageIndex = pageIndex;
		}

		this._selectedPageStream.next(this.getPage(this._selectedPageIndex));
	}

	// Defragmentation code

	/**
	 * @description
	 * This method makes sure that the shape of the cache is always intact; it does so by carrying out two processes.
	 * 1) Distribute
	 * 2) Reconcile
	 *
	 * @returns A pageInfo object that represent the page that should refreshed from the backend.
	 */
	private _defragment(): IPageInfo | undefined {
		let activePageIndex = this.distribute();

		// Skip refreshing if the active page is the last one, except for if the last page is the first page.
		if (activePageIndex !== 0 && activePageIndex === this._numOfPages - 1) {
			return undefined;
		}

		const deletedPageIndex = this._reconcile(activePageIndex);

		if (deletedPageIndex) {
			activePageIndex = deletedPageIndex;
		}

		const activePage = this._pages.get(activePageIndex);

		if (
			activePageIndex !== -1 &&
			((activePage === undefined || activePage.size !== this._pageSize) && this.currentSize !== this.totalSize)
		) {
			return { skip: activePageIndex * this._pageSize, take: this._pageSize };
		}

		return undefined;
	}

	/**
	 * @description
	 * Distributes the data evenly between the pages so that no page can ever exceed the page size.
	 *
	 * @returns The index of the page that had the overflow or the missing items. If it's -1 it means that
	 * all pages have the correct page size
	 */
	private distribute(): number {
		let activePageIndex = -1;
		let sizeDiff = NaN;
		const numOfPages = this._numOfPages;
		for (let i = 0; i < numOfPages; i = i + 1) {
			const page = this._pages.get(i);
			const prevPage = this._pages.get(i - 1);
			const nextPage = this._pages.get(i + 1);

			sizeDiff = this._recalculateSizeDiff(sizeDiff, prevPage);

			if (activePageIndex === -1 && page !== undefined && page.size !== this._pageSize) {
				activePageIndex = i;
				sizeDiff = this._pageSize - page.size;
			}

			let toBeSortedPage: Map<Id, T>;
			if (sizeDiff > 0) {
				if (activePageIndex !== i) {
					toBeSortedPage = this._shiftElements(page, prevPage, sizeDiff);
				}
			} else if (sizeDiff < 0) {
				toBeSortedPage = this._shiftElements(page, nextPage, sizeDiff);
			}

			if (toBeSortedPage) {
				this._sortPage(toBeSortedPage);
			}
		}

		return activePageIndex;
	}

	/**
	 * @description
	 * Reconciles the cache to an acceptable state.
	 * The rules are:
	 * - All pages must have the page size if not they must be deleted.
	 * - If the active page size doesn't match the page size, then the method should return the active page index
	 * and it's the responsibility of the caller to refresh that page.
	 * - If the chunking process is running, then it's guaranteed (giving that the invalidation ran
	 * before the start of the chunking process) that only the last fetched page might have missing elements in that case,
	 * the method returns the last fetched page index and it's the responsibility of the caller to refresh that page.
	 *
	 * @param activePageIndex The index of the active page.
	 * @returns The index of the page to be refreshed
	 */
	private _reconcile(activePageIndex: number): number | undefined {
		const totalSize = this._totalSize;
		let accumlatedSize = 0;
		for (const [pageIndex, page] of this._pages) {
			accumlatedSize += page.size;

			if (pageIndex === activePageIndex) {
				continue;
			}

			// TODO: If the last page has 10 items and user adds a new utterance the new last page is already fetched
			if (page.size !== this._pageSize && accumlatedSize < totalSize) {
				if (this.isChunkingProcessRunning) {
					return pageIndex;
				}

				this._pages.delete(pageIndex);
			}
		}
	}

	/**
	 * @description
	 * Shifts some elements from one map to another.
	 *
	 * @param from The providing map.
	 * @param to The receiving map.
	 * @param count The total number of items to be shifted
	 *
	 * @returns The receiving map.
	 */
	private _shiftElements(from: Map<Id, T>, to: Map<Id, T>, count: number): Map<Id, T> {
		if (from === undefined) {
			return;
		}
		const data = count < 0 ? [...from.entries()] : [...from.entries()].reverse();
		let absCount = Math.abs(count);
		for (const [key, model] of data) {
			if (absCount === 0) {
				return to;
			}
			if (to !== undefined) {
				to.set(key, model);
			}
			from.delete(key);
			absCount = absCount - 1;
		}

		return to;
	}

	/**
	 * @description
	 * Sorts page's data in-place, according to the sorting props provided in the constructor.
	 *
	 * @param page The page to be sorted.
	 */
	private _sortPage(page: Map<Id, T>): void {
		// Reverse the sorted array so that it gets inserted in the map in the correct order
		const data = [...page.entries()]
			.sort(([, a], [, b]) =>
				a[this._sortingProps.property] < b[this._sortingProps.property] ? this._sortingProps.type * 1 : this._sortingProps.type * -1
			)
			.reverse();

		page.clear();
		data.forEach(([key, model]) => page.set(key, model));
	}

	/**
	 * @description
	 * Returns the max absolute value between the old size difference, the difference between the page size
	 * and previous page.
	 * This is used when the size difference between pages is not uniform, this happens when the user deletes
	 * items from different pages in one batch operation (when filters or searcg are applied).
	 */
	private _recalculateSizeDiff(oldSizeDiff: number, prevPage: Map<Id, T>): number {
		if (isNaN(oldSizeDiff)) {
			return NaN;
		}
		const prevPageSizeDiff = (prevPage && this._pageSize - prevPage.size) || 0;
		const absSizeDifference = Math.abs(oldSizeDiff);
		const absPrevPageSizeDiff = Math.abs(prevPageSizeDiff);

		return absSizeDifference > absPrevPageSizeDiff ? oldSizeDiff : prevPageSizeDiff;
	}
}
