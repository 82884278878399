import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    forwardRef
} from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { FocusKeyManager } from '../utils/accessibility/focus-key-manager';
import { ESCAPE } from '../utils/keyboard/keycodes';
import { throwMdMenuInvalidPositionX, throwMdMenuInvalidPositionY } from './menu-errors';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuPositionX, MenuPositionY, MenuTriggerDirective } from './menu-trigger';

@Component({
    selector: 'm-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
    private _keyManager: FocusKeyManager;
    private _xPosition: MenuPositionX = 'after';
    private _yPosition: MenuPositionY = 'below';

    /** Subscription to tab events on the menu panel */
    private _tabSubscription: Subscription;

    /** Config object to be passed into the menu's ngClass */
    _classList: any = {};

    /** References the menu's trigger. */
    public _triggerRef: MenuTriggerDirective;

    /** References the currently active menu item. */
    public _currentlyActiveItem: MenuItemComponent = null;

    /** References child menu's */
    private _childMenus: MenuComponent[] = new Array<MenuComponent>();

    /** References the menu that is the parent of the menu. */
    @Input('subMenuOf') parentMenu: MenuComponent;

    /** Position of the menu in the X axis. */
    @Input()
    get xPosition() { return this._xPosition; }
    set xPosition(value: MenuPositionX) {
        if (value !== 'before' && value !== 'after' && value !== 'toRight' && value !== 'toLeft') {
            throwMdMenuInvalidPositionX();
        }
        this._xPosition = value;
        this.setPositionClasses();
    }

    /** Position of the menu in the Y axis. */
    @Input()
    get yPosition() { return this._yPosition; }
    set yPosition(value: MenuPositionY) {
        if (value !== 'above' && value !== 'below') {
            throwMdMenuInvalidPositionY();
        }
        this._yPosition = value;
        this.setPositionClasses();
    }

    @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

    /** List of the items inside of a menu. */
    @ContentChildren(forwardRef(() => MenuItemComponent)) items: QueryList<MenuItemComponent>;

    /** Whether the menu should overlap its trigger. */
    @Input() overlapTrigger = true;

    /**
     * This method takes classes set on the host m-menu element and applies them on the
     * menu template that displays in the overlay container.  Otherwise, it's difficult
     * to style the containing menu from outside the component.
     * @param classes list of class names
     */
    @Input('class')
    set classList(classes: string) {
        if (classes && classes.length) {
            this._classList = classes.split(' ').reduce((obj: any, className: string) => {
                obj[className] = true;
                return obj;
            }, {});

            this._elementRef.nativeElement.className = '';
            this.setPositionClasses();
        }
    }

    /** Event emitted when the menu is closed. */
    @Output() close = new EventEmitter<void>();

    constructor(private _elementRef: ElementRef) { }

    /** Set up references and subscriptions. */
    ngAfterContentInit() {
        this._keyManager = new FocusKeyManager(this.items).withWrap();
        this._tabSubscription = this._keyManager.tabOut.subscribe(() => this._emitCloseEvent());

        // Let menu items know about their containing menu
        this.items.forEach(i => {
            i.menu = this;
        });

        // If this menu is a nested menu,
        // have the parent menu be aware of this
        // menu and its close events.
        setTimeout(() => {
            if (this.parentMenu) {
                this.parentMenu.addChildMenu(this);
                this.parentMenu._triggerRef.subscribeToChildMenuClose(this);
            }
        }, 0);
    }

    ngOnDestroy() {
        if (this._tabSubscription) {
            this._tabSubscription.unsubscribe();
            this._currentlyActiveItem = null;
            this._triggerRef = null;
        }
    }

    /** Handle a keyboard event from the menu, delegating to the appropriate action. */
    _handleKeydown(event: KeyboardEvent) {
        switch (event.keyCode) {
            case ESCAPE:
                // NOTE(toanzian): If using the 'booleanTrigger' input
                // on menuTrigger directive, it is up to the author of the
                // component to catch this close event on ESCAPE and flip the
                // input boolean back to previous state.
                this._emitCloseEvent();
                return;
            // TODO(toanzian): add handling for arrow keys???
            default:
                this._keyManager.onKeydown(event);
        }
    }

    /** Sets the currently active item, and closes the last item if it was a trigger. */
    setCurrentlyActiveItem(i: MenuItemComponent) {
        // Close the previously active item's menu
        // and its children if it was a trigger
        if (this._currentlyActiveItem && this._currentlyActiveItem.trigger) {
            this._currentlyActiveItem.trigger.menu.closeSelfAndChildMenus();
        }

        // Assign the new item as the currently active item.
        this._currentlyActiveItem = i;
    }

    /** Adds a child menu reference to this menu. */
    addChildMenu(menu: MenuComponent) {
        this._childMenus.push(menu);
    }

    /** Closes this menu and all child menus. */
    closeSelfAndChildMenus() {
        if (this._childMenus.length) {
            this._childMenus.forEach(m => {
                m.closeSelfAndChildMenus();
            });
        }
        // Check if menu is open before closing it, otherwise we get leftover focus
        // from unopened menus. (See default value for _triggerRef._openedByMouse)
        this._triggerRef.menuOpen ? this._triggerRef.closeMenu() : null;
    }

    /**
     * Focus the first item in the menu. This method is used by the menu trigger
     * to focus the first item when the menu is opened by the ENTER key.
     */
    focusFirstItem() {
        this._keyManager.setFirstItemActive();
    }

    /**
     * This emits a close event to which the trigger is subscribed. When emitted, the
     * trigger will close the menu.
     */
    _emitCloseEvent(): void {
        this.close.emit();
    }

    /**
     * It's necessary to set position-based classes to ensure the menu panel animation
     * folds out from the correct direction.
     */
    setPositionClasses(posX = this.xPosition, posY = this.yPosition): void {
        this._classList['m-menu-before'] = posX === 'before';
        this._classList['m-menu-after'] = posX === 'after';
        this._classList['m-menu-above'] = posY === 'above';
        this._classList['m-menu-below'] = posY === 'below';
    }
}
