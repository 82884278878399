import { Inject, Injectable } from '@angular/core';
import { BaseService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs';
import { IArmTokenService } from '../interfaces/IArmTokenService';

@Injectable()
export class ArmTokenService implements IArmTokenService {
	constructor(
		private readonly _baseService: BaseService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	/**
	 * @description
	 * Gets the base web url for manipulating ARM tokens.
	 *
	 * @returns The base url for ARM tokens manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.directWebApiUrl}/token/arm`;
	}

	/**
	 * @description
	 * Gets a default ARM token for tenant neutral apis.
	 *
	 * @returns An observable with the ARM token.
	 */
	public getDefaultToken(): Observable<string> {
		const url: string = this._baseUrl;

		return this._cacheService
			.get(
				url,
				this._armTokenParser,
				this._baseService.optionsBuilder
					.useJSON()
					.useSubKey(this._baseService.configs.userSubKey)
					.useCORS()
					.build()
			)
			.catch(() => Observable.of(null));
	}

	/**
	 * @description
	 * Gets an ARM token specific for the given tenant id.
	 *
	 * @param tenantId The tenant id of the tenant to get the token for.
	 * @returns An observable with the ARM token.
	 */
	public getTokenForTenant(tenantId: string): Observable<string> {
		const url: string = `${this._baseUrl}/${tenantId}`;

		return this._cacheService
			.get(
				url,
				this._armTokenParser,
				this._baseService.optionsBuilder
					.useJSON()
					.useSubKey(this._baseService.configs.userSubKey)
					.useCORS()
					.build()
			)
			.catch(() => Observable.of(null));
	}

	private _armTokenParser = (apiObject: any) => apiObject.token;
}
