import { NgModule } from '@angular/core';
import { CoreModule } from '@luis/core';
import { EntitiesModule } from '@luis/entities';
import { PatternEntityHighlighterAsyncPipe } from '../pipes/pattern-entity-higlighter-async.pipe';

@NgModule({
    imports: [
        CoreModule,
        EntitiesModule
    ],
    exports: [
        CoreModule,
        EntitiesModule,
        PatternEntityHighlighterAsyncPipe
    ],
    declarations: [PatternEntityHighlighterAsyncPipe],
    providers: []
})
export class SharedModule { }
