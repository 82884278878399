﻿import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@luis/auth';
import { Observable } from 'rxjs/Rx';

@Component({
	selector: 'home-page',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
	public isLoggedIn: Observable<boolean>;

	constructor(private readonly _authService: AuthService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Logs in the user to LUIS.
	 */
	public login(): void {
		this._authService.login();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.isLoggedIn = this._authService
			.isBusy()
			.filter(inProgress => !inProgress)
			.flatMap(() => this._authService.isLoggedIn());
	}
}
