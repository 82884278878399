import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LuisModel } from '@luis/core';
import { DatasetResultsGraphingService } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { IDatasetDto } from '../../../../interfaces/IDatasetDto';
import { IModelConfusionAggregate } from '../../../../interfaces/IModelConfusionAggregate';
import { CONFUSION_TYPE } from '../../../../models/entity-confusion.model';

@Component({
	selector: 'model-results',
	templateUrl: './model-results.component.html',
	styleUrls: ['./model-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelResultsComponent implements OnInit, OnDestroy {
	@Input() public activeModel: Observable<LuisModel>;
	@Input() public activeConfusionType: Observable<CONFUSION_TYPE>;
	@Input() public datasetDto: IDatasetDto;

	public precision: number | string;
	public recall: number | string;
	public fScore: number | string;
	public selectionMessage: Observable<string>;

	private _modelSubscription: Subscription = new Subscription();

	constructor(private readonly _i18n: TranslateService, private readonly _datasetGraphingService: DatasetResultsGraphingService) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._modelSubscription.unsubscribe();
	}

	private _initState(): void {
		this._modelSubscription = Observable.combineLatest(this.activeModel, this.datasetDto.scorer.modelConfusionAggregates)
			.filter(([model]) => model !== null)
			.subscribe(([model, confusions]) => this._calculateModelStatistics(confusions.get(model.id)));

		this.selectionMessage = Observable.combineLatest(
			this.activeConfusionType,
			this._datasetGraphingService.getPointClickedStream()
		).map(([type, isPointClicked]) => {
			if (isPointClicked) {
				return this._i18n.instant('test-pane.results-graph.custom_point_selected');
			} else if (type === CONFUSION_TYPE.TRUE_POSITIVE) {
				return '(True Positive)';
			} else if (type === CONFUSION_TYPE.TRUE_NEGATIVE) {
				return '(True Negative)';
			} else if (type === CONFUSION_TYPE.FALSE_POSITIVE) {
				return '(False Positive)';
			} else if (type === CONFUSION_TYPE.FALSE_NEGATIVE) {
				return '(False Negative)';
			} else {
				return '';
			}
		});
	}

	/**
	 * @description
	 * Calculates the precision, recall, and F score statistics for the
	 * given model.
	 *
	 * @param model The model to calculate the statistics for.
	 */
	private _calculateModelStatistics(confusionData: IModelConfusionAggregate): void {
		if (confusionData) {
			this.precision = this._isNanFormat(this._round(confusionData.tp / (confusionData.tp + confusionData.fp)));
			this.recall = this._isNanFormat(this._round(confusionData.tp / (confusionData.tp + confusionData.fn)));

			if (typeof this.precision !== 'string' && typeof this.recall !== 'string') {
				this.fScore = this._isNanFormat(this._round((this.precision * this.recall * 2) / (this.precision + this.recall)));
			}
		}
	}

	/**
	 * @description
	 * Rounds the number to the nearest digit.
	 *
	 * @param val The value to round.
	 * @returns The rounded value.
	 */
	private _round(val: number): number {
		return Math.round(val * 100) / 100;
	}

	/**
	 * @description
	 * Checks if the value is not a number or not. If not
	 * returns an appropiate string to indicate so.
	 *
	 * @param val The value to check
	 * @returns The number itself if it is
	 * a number and a string if it is not.
	 */
	private _isNanFormat(val: number): number | string {
		return isNaN(val) ? 'Not applicable' : val;
	}
}
