import { Pipe, PipeTransform } from '@angular/core';
import { Segment } from '@luis/entities';

/**
 * @description
 * Represents a pipe that checks if the current token has
 * a misprediction error or not.
 */
@Pipe({
    name: 'hasError'
})
export class HasErrorPipe implements PipeTransform {
    public transform(index: number, segment: Segment): boolean {
        return segment.predictions.find(p => p.startTokenIndex <= index && index <= p.endTokenIndex) !== undefined;
    }
}
