import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';

/**
 * @description
 * This pipe finds whether the given route is active or not
 * based on the router state.
 */
@Pipe({ name: 'isRouteActiveAsync' })
export class IsRouteActiveAsyncPipe implements PipeTransform {
    constructor(
        private _router: Router,
        private _route: ActivatedRoute
    ) { }

    /**
     * @description
     * Listens to the changes in the current activated route and checks
     * if the given route string is active according to the router state.
     * 
     * @param route The route to check if active.
     * @returns Observable of true if the route is active and false otherwise.
     */
    public transform(route: string): Observable<boolean> {
        // Since this pipe is created after the routing has finished,
        // manually start the observable stream with a dummy Navigation End
        // object to find the initial active state of the given route.
        return this._router.events
            .startWith(new NavigationEnd(null, null, null))
            .filter(e => e instanceof NavigationEnd)
            .map(_ => this._router.createUrlTree([route], { relativeTo: this._route }))
            .map(urlTree => this._router.isActive(urlTree, false));
    }
}
