export enum SORT_TYPE {
    ASCENDING = -1,
    DESCENDING = 1
}

/**
 * @description
 * Represents an interface for sorting properties to use with the data
 * sorting pipe.
 *
 * @param property The property to sort on.
 * @param number A value of either 1 or -1 to indicate ascending
 *                      or descending order respectively.
 */
export interface ISortPipeProps {
    property: string;
    type: number;
}

/**
 * A constant empty sort properties object to initialize subjects with.
 */
export const EMPTY_SORT_PROPS: ISortPipeProps = { property: '', type: SORT_TYPE.ASCENDING };
