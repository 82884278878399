import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/**
 * @description
 * An enumeration of the possible blade operations.
 * Note that the design would have to drastically
 * change if the number of blades is generalized to
 * n blades. But since that is not the case (the number
 * of blades will not exceed 3) then it is safe to apply
 * this design.
 */
export enum BLADE_OPS {
	CHAT_OPEN,
	CHAT_CLOSE,
	DEBUG_OPEN,
	DEBUG_CLOSE,
	ENDPOINT_DEBUG_OPEN,
	ENDPOINT_DEBUG_CLOSE,
	BATCH_TESTING_OPEN,
	BATCH_TESTING_CLOSE,
	// Filter blades operations.
	FILTER_OPEN,
	FILTER_CLOSE,
	INTENT_OPEN,
	INTENT_CLOSE,
	ENTITIES_OPEN,
	ENTITIES_CLOSE
}

/**
 * @description
 * An enumration of the blade types in the system. Note
 * that the enumeration would cease to exist if the number
 * of blades is to be generalized to support 'n' blades, but
 * this is not the case and probably never will, thus the
 * enumeration is sufficient.
 */
export enum BLADES {
	CHAT = 1,
	DEBUG,
	ENDPOINT_DEBUG,
	BATCH_TESTING,
	// Filter blades
	FILTER,
	INTENT,
	ENTITIES
}

@Injectable()
export class BladeTrackerService {
	private readonly _bladeStream: BehaviorSubject<BLADE_OPS> = new BehaviorSubject<BLADE_OPS>(null);
	private _bladeStates: Observable<Map<BLADES, boolean>>;

	constructor() {
		this._initState();
	}

	/**
	 * @description
	 * Applies the given blade operation to the blade
	 * operations stream to notify all listeners.
	 *
	 * @param op The operation to apply.
	 */
	public applyOp(op: BLADE_OPS): void {
		this._bladeStream.next(op);
	}

	/**
	 * @description
	 * Gets the current blade operation in effect.
	 *
	 * @returns An observable of the
	 * blade operation to perform.
	 */
	public getOpStream(): Observable<BLADE_OPS> {
		return this._bladeStream.asObservable();
	}

	/**
	 * @description
	 * Gets the current states of the blade, whether they are
	 * active or not.
	 *
	 * @returns An observable of
	 * the current blade states.
	 */
	public getBladeStates(): Observable<Map<BLADES, boolean>> {
		return this._bladeStates;
	}

	/**
	 * @description
	 * Gets the currently active blade, which is the rightmost visible blade
	 * in the test pane.
	 *
	 * @returns The currently active blade in the test pane.
	 */
	public getActiveBlade(): Observable<BLADES> {
		return this.getBladeStates().pipe(
			map(states => Array.from(states.entries())),
			map(entries => entries.sort((a, b) => (a[0] < b[0] ? 1 : -1))),
			map(sortedEntries => sortedEntries.find(e => e[1])),
			map(bladeEntry => (bladeEntry ? bladeEntry[0] : null))
		);
	}

	/**
	 * @description
	 * Gets the operation to use to close the blade given
	 * in the arguments.
	 *
	 * @param blade The blade to close.
	 * @returns The operation that will close the blade.
	 */
	public getOpToUse(blade: BLADES): BLADE_OPS {
		switch (blade) {
			case BLADES.CHAT:
				return BLADE_OPS.CHAT_CLOSE;
			case BLADES.DEBUG:
				return BLADE_OPS.DEBUG_CLOSE;
			case BLADES.ENDPOINT_DEBUG:
				return BLADE_OPS.ENDPOINT_DEBUG_CLOSE;
			case BLADES.BATCH_TESTING:
				return BLADE_OPS.BATCH_TESTING_CLOSE;
			case BLADES.FILTER:
				return BLADE_OPS.FILTER_CLOSE;
			case BLADES.INTENT:
				return BLADE_OPS.INTENT_CLOSE;
			case BLADES.ENTITIES:
				return BLADE_OPS.ENTITIES_CLOSE;
			default:
		}
	}

	/**
	 * @description
	 * Closes the currently active blade. The active blade is the rightmost
	 * blade currently open.
	 */
	public closeActiveBlade(): void {
		this.getActiveBlade()
			.pipe(first())
			.subscribe(blade => this.applyOp(this.getOpToUse(blade)));
	}

	/**
	 * @description
	 * Initializes the service.
	 */
	private _initState(): void {
		this._bladeStates = this._bladeStream.asObservable().scan((acc, e) => {
			switch (e) {
				case BLADE_OPS.CHAT_OPEN:
					acc.set(BLADES.CHAT, true);
					break;
				case BLADE_OPS.CHAT_CLOSE:
					acc.set(BLADES.CHAT, false);
					break;
				case BLADE_OPS.DEBUG_OPEN:
					acc.set(BLADES.DEBUG, true);
					break;
				case BLADE_OPS.DEBUG_CLOSE:
					acc.set(BLADES.DEBUG, false);
					break;
				case BLADE_OPS.ENDPOINT_DEBUG_OPEN:
					acc.set(BLADES.ENDPOINT_DEBUG, true);
					break;
				case BLADE_OPS.ENDPOINT_DEBUG_CLOSE:
					acc.set(BLADES.ENDPOINT_DEBUG, false);
					break;
				case BLADE_OPS.BATCH_TESTING_OPEN:
					acc.set(BLADES.BATCH_TESTING, true);
					break;
				case BLADE_OPS.BATCH_TESTING_CLOSE:
					acc.set(BLADES.BATCH_TESTING, false);
					break;
				// Filters blades.
				case BLADE_OPS.FILTER_OPEN:
					acc.set(BLADES.FILTER, true);
					break;
				case BLADE_OPS.FILTER_CLOSE:
					acc.set(BLADES.FILTER, false);
					break;
				case BLADE_OPS.INTENT_OPEN:
					acc.set(BLADES.INTENT, true);
					break;
				case BLADE_OPS.INTENT_CLOSE:
					acc.set(BLADES.INTENT, false);
					break;
				case BLADE_OPS.ENTITIES_OPEN:
					acc.set(BLADES.ENTITIES, true);
					break;
				case BLADE_OPS.ENTITIES_CLOSE:
					acc.set(BLADES.ENTITIES, false);
					break;
				default:
			}

			return acc;
		}, new Map<BLADES, boolean>());
	}
}
