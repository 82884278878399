'use strict';

import { Component, Input } from '@angular/core';

@Component({
    selector: 'm-clipped-text',
    styleUrls: ['./clipped-text.component.scss'],
    templateUrl: './clipped-text.component.html'
})
export class ClippedTextComponent {
    @Input() alignment?: string;
    @Input() text: string;
    @Input() textClass?: string;
    @Input() textStyle?: any;
}
