import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EntityHelpers } from '../../../../helpers/entity.helpers';
import { ENTITY_DROPDOWN_OPERATIONS, IEntityDropdownOperationPayload } from '../../../../interfaces/IEntityDropdownOperationPayload';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../../interfaces/IEntityService';
import { Entity, ENTITY_TYPES } from '../../../../models/entity.model';
import { ParentEntity } from '../../../../models/parent-entity.model';

@Component({
	selector: 'composite-children-menu-content',
	templateUrl: 'composite-children-menu-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class CompositeChildrenMenuContentComponent implements OnInit {
	@Input() public entity: Observable<Entity>;
	@Input() public parentEntity: Observable<ParentEntity>;
	@Input() public role: string | undefined;

	@Output() public onOperation: EventEmitter<IEntityDropdownOperationPayload> = new EventEmitter<IEntityDropdownOperationPayload>();

	public querySubject: BehaviorSubject<string> = new BehaviorSubject('');
	public childrenEntities: Observable<Entity[]>;
	public ENTITY_DROPDOWN_OPERATIONS: typeof ENTITY_DROPDOWN_OPERATIONS = ENTITY_DROPDOWN_OPERATIONS;

	constructor(@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Indicates whether the main entity label can be removed or not.
	 */
	public isLabelRemovable({ type }: Entity): boolean {
		return Entity.isMachineLearned(type) || this.role !== '';
	}

	/**
	 * @description
	 * Emits an event when an entity is selected from the menu.
	 *
	 * @param keyboardEvent Native click event.
	 * @param entity The entity object that's selected.
	 */
	public onExisitngEntityClicked(keyboardEvent: KeyboardEvent, entity: Entity): void {
		keyboardEvent.stopPropagation();

		const [entityName, roleName] = EntityHelpers.splitQuery(entity.name);

		if (roleName) {
			const roleObj = entity.roles.find(({ name }) => name === roleName);
			// Remove the role from the name
			entity.name = entityName;
			this.onOperation.emit({
				operation: ENTITY_DROPDOWN_OPERATIONS.EXISTING_ENTITY,
				event: { entity, eventData: { role: roleObj.name, roleId: roleObj.id } }
			});
		} else {
			this.onOperation.emit({
				operation: ENTITY_DROPDOWN_OPERATIONS.EXISTING_ENTITY,
				event: { entity }
			});
		}
	}

	/**
	 * @description
	 * Gets the entity name concatenated with the role name if it exits
	 */
	public getEntityFullName(entity: Entity, role: string): string {
		if (entity) {
			return entity.name + (role ? `:${role}` : '');
		}

		return '';
	}

	/**
	 * @description
	 * Decides whether the list submenu should be displayed or not.
	 *
	 * @param child child entity.
	 */
	public showListSubmenu(child: Entity): Observable<boolean> {
		return this.entity.pipe(
			map(entity => {
				const [childEntityName] = EntityHelpers.splitQuery(child.name);

				// Returns true if the entity is closed list and it differs from the selected entity
				return child.type === ENTITY_TYPES.CLOSED && entity.name !== childEntityName;
			}),
			startWith(true)
		);
	}

	private _initState(): void {
		this.childrenEntities = Observable.combineLatest(this._entityService.get(), this.entity, this.parentEntity, this.querySubject).pipe(
			map(([entities, selectedLabel, parentEnity, query]) =>
				parentEnity.children
					// Filter out the selected child entity
					.filter(child => child.name !== this.getEntityFullName(selectedLabel, this.role))
					// Apply the query
					.filter(this._searchEntities(query))
					// Filter out non-machine learned entities
					.filter(this._filterChildrenEntities(selectedLabel, entities))
					.map(child => {
						const [entityName] = EntityHelpers.splitQuery(child.name);
						const originalEntity = entities.find(({ name }) => name === entityName);
						const clone = originalEntity.clone();
						clone.name = child.name;

						return clone;
					})
			)
		);
	}

	private readonly _searchEntities = (query: string) => (child: Entity): boolean =>
		this.getEntityFullName(child, this.role)
			.toLowerCase()
			.includes(query.toLocaleLowerCase());

	/**
	 * @description
	 * Returns whether to display the given child or not based on some rules.
	 * 1) If there is no selected label or the selected label is ML then display all ML entities
	 * and list entities that don't have roles applied to them.
	 * 2) If the selected label is non-ML then display ML entities and list entities that don't have roles applied to them.
	 * 3) If the selected label is non-ML then display ML entities and entities that match the selected
	 * label name with a role applied to them.
	 */
	private readonly _filterChildrenEntities = (selectedLabel: Entity, entities: Entity[]) => (child: Entity): boolean => {
		const [childName, roleName] = EntityHelpers.splitQuery(child.name);
		const childEntity = entities.find(({ name }) => name === childName);
		const isChildML = Entity.isMachineLearned(childEntity.type);
		const isListEntityWithoutRole = childEntity.type === ENTITY_TYPES.CLOSED && roleName === undefined;

		// If there is no selected label or the selected label is a machine learned one then
		// filter out all the non-machine learned entities except for list entities without roles.
		if (selectedLabel === undefined || Entity.isMachineLearned(selectedLabel.type)) {
			return isChildML || isListEntityWithoutRole;
		}

		// If the selected label is a non-machine learned one, then filter out non-machine learned
		// entities except for entities that have the same name and a role applied to them or list entities
		// without roles.
		return isChildML || isListEntityWithoutRole || (childName === selectedLabel.name && roleName !== undefined);
	};
}
