import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { PersistantPublishSettings, PublishSettings } from '../models/publish.model';

export const PUBLISH_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IPublishService');

/**
 * @description
 * Represents an interface for publish service operations. Publish
 * service operations including publishing a LUIS app and LUIS
 * endpoint listing.
 */
export interface IPublishService {
    publish(settings: PublishSettings): Observable<any>;

    getRegionEndpoints(): Observable<Map<string, string>>;

    getPublishSettings(): Observable<PersistantPublishSettings>;

    updatePublishSettings(settings: PersistantPublishSettings): Observable<void>;

    getBotUrl(): string;
}
