import { IFsmTransition, IPattern, IPatternFsmResult } from '../../interfaces/IPatternFsm';

/**
 * @constant
 * @description
 * The enumeration of states allowable.
 */
enum STATES {
    INITIAL,
    ESCAPE_BRACES,
    OPEN_BRACES,
    BRACES_CONTENT,
    ROLE_START,
    ROLE_CONTENT,
    HIERARCHICAL_CONTENT
}

/**
 * @constant
 * @description
 * The allowable states for the braces finite state machine
 */
const TRANSITIONS: IFsmTransition[] = [
    { from: STATES.INITIAL, to: STATES.ESCAPE_BRACES, action: /\\/ },
    { from: STATES.INITIAL, to: STATES.INITIAL, action: /[^{]/ },
    { from: STATES.INITIAL, to: STATES.OPEN_BRACES, action: /{/ },
    { from: STATES.ESCAPE_BRACES, to: STATES.INITIAL, action: /./ },
    { from: STATES.OPEN_BRACES, to: STATES.BRACES_CONTENT, action: /[^}]/ },
    { from: STATES.OPEN_BRACES, to: STATES.INITIAL, action: /}/ },
    { from: STATES.BRACES_CONTENT, to: STATES.ROLE_START, action: /:/ },
    { from: STATES.BRACES_CONTENT, to: STATES.INITIAL, action: /}/ },
    { from: STATES.BRACES_CONTENT, to: STATES.BRACES_CONTENT, action: /[^}]/ },
    { from: STATES.ROLE_START, to: STATES.HIERARCHICAL_CONTENT, action: /:/ },
    { from: STATES.ROLE_START, to: STATES.INITIAL, action: /}/ },
    { from: STATES.ROLE_START, to: STATES.ROLE_CONTENT, action: /[^:}]/ },
    { from: STATES.ROLE_CONTENT, to: STATES.INITIAL, action: /}/ },
    { from: STATES.ROLE_CONTENT, to: STATES.ROLE_CONTENT, action: /[^}]/ },
    { from: STATES.HIERARCHICAL_CONTENT, to: STATES.HIERARCHICAL_CONTENT, action: /[^}]/ },
    { from: STATES.HIERARCHICAL_CONTENT, to: STATES.INITIAL, action: /}/ }
];

/**
 * @description
 * A finite state machine to resolve entities in braces.
 */
export class BracesFsm implements IPattern {
    private _text: string;
    private _currentState: STATES;
    private _prevTransition: IFsmTransition;

    constructor(text: string) {
        this._text = text;
        this._currentState = STATES.INITIAL;
    }

    /**
     * @method
     * @description
     * Checks if the current cursor is between braces or not.
     *
     * @returns The resulting validity of
     * the FSM.
     */
    public evaluate(): IPatternFsmResult {
        let itr: number = 0;

        if (!this._text || this._text.length === 0) {
            return { result: false };
        }

        while (itr < this._text.length) {
            this._moveState(itr);
            itr = itr + 1;
        }

        if (this._currentState !== STATES.INITIAL && this._currentState !== STATES.ESCAPE_BRACES) {
            return { result: true };
        }

        return { result: false };
    }

    /**
     * @method
     * @description
     * Checks if the machine is in a role state or not.
     *
     * @returns True if in a role state and
     * false otherwise.
     */
    public isRoleState(): boolean {
        return this._currentState === STATES.ROLE_START || this._currentState === STATES.ROLE_CONTENT;
    }

    /**
     * @description
     * Moves the FSM to the next state based on the matched action.
     *
     * @param index The current index of the character to check in the string.
     */
    private _moveState(index: number): void {
        const char: string = this._text[index];
        this._prevTransition = TRANSITIONS.filter(t => t.from === this._currentState).find(t => t.action.test(char));
        this._currentState = this._prevTransition.to;
    }
}
