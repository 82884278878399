import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { Observable, ReplaySubject } from 'rxjs';
import { IUserState } from '../../interfaces/Utils/IGateKeeper';
import { BaseService } from '../utils/base.service';

/**
 * @description
 * Represents the object of features permission returned from gate keeper service.
 */
export interface IGateKeeperPermissions {
	[key: string]: boolean;
}

/**
 * @description
 * Responsible for communication with LUIS web app to get the features permission.
 */
@Injectable()
export class GateKeeperService {
	private readonly _userPermision: ReplaySubject<IGateKeeperPermissions> = new ReplaySubject<IGateKeeperPermissions>(1);

	constructor(private readonly _baseService: BaseService, @Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService) {}

	/**
	 * @description
	 * Subscribes to the Gate that contains the features permission.
	 *
	 * @returns Observable of feature permission.
	 */
	public subscribeToGate(): Observable<IGateKeeperPermissions> {
		return this._userPermision.asObservable();
	}

	/**
	 * @description
	 * Publish the user state to the web app to get the feature permission object
	 * for this current state.
	 *
	 * @param state The current user state in the application.
	 */
	public fetchPermissions(state: IUserState): void {
		const url: string = `/api/GateKeeperSettings/${this._baseService.configs.env}`;

		this._httpService
			.post<IGateKeeperPermissions>(url, state, this._baseService.optionsBuilder.useJSON().build())
			.subscribe(permissions => this._userPermision.next(<IGateKeeperPermissions>permissions));
	}
}
