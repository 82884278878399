import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreModule } from '@luis/core';
import { IntentsModule } from '@luis/intents';
import { IntercomUIModule } from '@luis/ui';
import { UtterancesModule } from '@luis/utterances';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [],
	imports: [],
	exports: [
		CommonModule,
		CoreModule,
		IntercomUIModule,
		FormsModule,
		ReactiveFormsModule,
		UtterancesModule,
		IntentsModule,
		TranslateModule,
		MatSelectModule,
		MatExpansionModule,
		MatTableModule,
		MatDividerModule,
		MatTooltipModule,
		MatDialogModule
	],
	providers: []
})
export class SharedModule {}
