export class IntentMetadata {
	constructor(
		public intentName: string = '',
		public intentId: string = '',
		public numUtterances: number = 0,
		public numCorrectUtterances: number = 0,
		public numUnclearUtterances: number = 0,
		public numIncorrectUtterances: number = 0,
		public correctAccuracy: number = 0,
		public unclearAccuracy: number = 0,
		public incorrectAccuracy: number = 0
	) {}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): IntentMetadata {
		const total: number = apiObject.utterancesCount;
		const incorrect: number = apiObject.misclassifiedUtterancesCount;
		const unclear: number = apiObject.ambiguousUtterancesCount - apiObject.misclassifiedAmbiguousUtterancesCount;
		const correct: number = total - incorrect - unclear;

		return new IntentMetadata(
			apiObject.modelName,
			apiObject.modelId,
			total,
			correct,
			unclear,
			incorrect,
			total === 0 ? 0 : (correct * 100) / total,
			total === 0 ? 0 : (unclear * 100) / total,
			total === 0 ? 0 : (incorrect * 100) / total
		);
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): IntentMetadata {
		return new IntentMetadata(
			this.intentName.slice(),
			this.intentId.slice(),
			this.numUtterances,
			this.numCorrectUtterances,
			this.numUnclearUtterances,
			this.numIncorrectUtterances,
			this.correctAccuracy,
			this.unclearAccuracy,
			this.incorrectAccuracy
		);
	}
}
