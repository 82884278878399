import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BLADES, BladeTrackerService } from '../services/blade-tracker.service';
import { IFocusable } from '../utils';
import { ESCAPE } from '../utils/keyboard/keycodes';

@Component({
	selector: 'blade',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BladeComponent implements OnInit, OnDestroy, IFocusable {
	@Output() public focusBlade: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
	@ViewChild('closeLink', { read: ElementRef }) public closeLink: ElementRef;

	protected currentBlade: BLADES;

	private _bladeTrackerSubscription: Subscription = new Subscription();

	constructor(private readonly _bladesTrackerService: BladeTrackerService) {}

	public ngOnInit(): void {
		this._bladeTrackerSubscription = this._bladesTrackerService
			.getActiveBlade()
			.subscribe(activeBlade => (activeBlade === this.currentBlade ? this.focus() : undefined));
	}

	public ngOnDestroy(): void {
		this._bladeTrackerSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Closes the blade when the escape key is pressed.
	 *
	 * @param event The keyboard event that was fired.
	 */
	@HostListener('keydown', ['$event'])
	public onKeyPress(event: KeyboardEvent): void {
		if (event.keyCode === ESCAPE) {
			this._bladesTrackerService.closeActiveBlade();
			event.preventDefault();
			event.stopPropagation();
		}
	}

	/**
	 * @description
	 * Focuses the close link in the pane.
	 */
	public focus(): void {
		setTimeout(() => this.focusBlade.emit(this.closeLink));
	}

	/**
	 * @description
	 * Closes the blade according to the CURRENT blade property. This is different
	 * than closing the active blade.
	 */
	public closeBlade(): void {
		this._bladesTrackerService.applyOp(this._bladesTrackerService.getOpToUse(this.currentBlade));
	}
}
