import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { CoreModule } from '@luis/core';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { EntityDropdownSubMenu } from '../pipes/entity-dropdown-submenu.pipe';
import { SelectableCompositeChildrenPipe } from '../pipes/selectable-composite-children.pipe';

@NgModule({
	imports: [
		CommonModule,
		CoreModule,
		IntercomUIModule,
		NgxPaginationModule,
		FormsModule,
		MatSelectModule,
		MatOptionModule,
		TranslateModule
	],
	declarations: [SelectableCompositeChildrenPipe, EntityDropdownSubMenu],
	exports: [
		CommonModule,
		CoreModule,
		FormsModule,
		IntercomUIModule,
		NgxPaginationModule,
		SelectableCompositeChildrenPipe,
		EntityDropdownSubMenu,
		MatSelectModule,
		MatOptionModule,
		TranslateModule,
		MatDialogModule
	]
})
export class SharedModule {}
