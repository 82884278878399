import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DropdownControlService } from '../../services/dropdown-control.service';
import { IFocusable } from '../../utils/accessibility/focus-key-manager';
import { coerceBooleanProperty } from '../../utils/coercion/boolean-property';

export enum DropdownItemSelectionMode {
    User,
    Programmatic
}

export type DropdownItemData = { text: string, value: any, mode: DropdownItemSelectionMode };

@Component({
    selector: 'm-dropdown-item',
    templateUrl: 'dropdown-item.component.html',
    styleUrls: ['dropdown-item.component.scss'],
    host: {
        '[class.m-dropdown-item-comp]': 'true',
        '[attr.role]': '"menuitem"',
        '[attr.tabindex]': '_getTabIndex()',
        '(click)': '_handleClick($event)',
        '(keydown.ENTER)': '_handleKeydownEnter($event)'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DropdownItemComponent implements IFocusable, OnInit {
    private _disabled: boolean = false;
    public showTitle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    @Input() value: any;
    @Input() isSelected: boolean;
    @Input() text: string;
    @Input() isDefault: boolean = false;
    @Input('icon') icon: string = '';

    @Input()
    set disabled(v: any) {
        this._disabled = coerceBooleanProperty(v);
    }
    get disabled(): any {
        return this._disabled;
    }

    constructor(
        private _elementRef: ElementRef,
        private _dropdownService: DropdownControlService
    ) { }

    public ngOnInit(): void {
        // Register with the service so that parent knows about it
        this._dropdownService.registerItem(this);

        // Set as initial value if default
        if (coerceBooleanProperty(this.isDefault)) {
            this.select(DropdownItemSelectionMode.Programmatic);
        }
    }

    /**
     * Focuses the dropdown item.
     */
    public focus(): void {
        this._getHostElement()
            .focus();
    }

    public select(mode: DropdownItemSelectionMode): void {
        const value: DropdownItemData = { text: this.text, value: this.value, mode };
        this._dropdownService.updateValue(value);
    }

    /**
     * Returns the HTMLElement representing the menu item.
     */
    public _getHostElement(): HTMLElement {
        return this._elementRef.nativeElement;
    }

    /**
     * Spits out a tabindex value depending on the disabled status of the item.
     */
    public _getTabIndex(): string {
        return this._disabled ? '-1' : '0';
    }

    /**
     * Handles a click event on the menu item.
     */
    public _handleClick(event: Event): void {
        if (this._disabled) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.stopPropagation();
            this._selectItemValue();
        }
    }

    /**
     * Handles an ENTER press on the menu item.
     */
    public _handleKeydownEnter(event: Event): void {
        // Updating the value already causes the dropdown to be closed,
        // however, the ENTER event will still bubble up to the dropdown after
        // the close is called, causing the ENTER to trigger a dropdown open.
        // This results in the dropdown always being open when selecting an item
        // with ENTER. So we stop propogation to combat that.
        event.stopPropagation();
        this._selectItemValue();
    }

    /**
     * Emits the value of the selected menu item and reflects it in the parent dropdown.
     */
    public _selectItemValue(): void {
        this.select(DropdownItemSelectionMode.User);
    }
}
