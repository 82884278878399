import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 * Trims the given string to a given length. Adds ellipsis in the end.
 */
@Pipe({
    name: 'trim'
})
export class TrimPipe implements PipeTransform {
    public transform(data: string, length: number): string {
        return data ? data.slice(0, length + 1).concat(data.length > length ? ' ...' : '') : '';
    }
}
