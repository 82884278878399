﻿/**
 * @description
 * Represents user account info.
 *
 * @param id The user id.
 * @param organization The user's organization/company.
 * @param country The user's country.
 * @param source Choice of where the user heard about LUIS from.
 * @param newsletter The user's news letter acceptance setting.
 * @param tutorial Whether the user completed the tutorial or not.
 */
export class User {
	public id: string;
	public organization: string;
	public country: string;
	public source: string;
	public newsletter: boolean;
	public tutorial: boolean;

	constructor(
		id: string = '',
		country: string = '',
		organization: string = '',
		source: string = '',
		newsletter: boolean = false,
		tutorial: boolean = false
	) {
		this.id = id;
		this.country = country;
		this.organization = organization;
		this.source = source;
		this.newsletter = newsletter;
		this.tutorial = tutorial;
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): User {
		return new User(
			apiObject.id,
			apiObject.country,
			apiObject.organization,
			apiObject.heardAboutUsSource,
			apiObject.isNewsLetterAccepted,
			apiObject.hasCompletedTutorial
		);
	}

	/**
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			id: this.id,
			country: this.country,
			organization: this.organization,
			heardAboutUsSource: this.source,
			isNewsLetterAccepted: this.newsletter,
			hasCompletedTutorial: this.tutorial
		};
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): User {
		return new User(
			this.id.slice(),
			this.country.slice(),
			this.organization.slice(),
			this.source.slice(),
			this.newsletter,
			this.tutorial
		);
	}
}
