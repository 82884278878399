import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@luis/auth';

@Component({
	selector: 'logged-out-header',
	templateUrl: 'logged-out-header.component.html',
	styleUrls: ['./logged-out-header.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoggedOutHeaderComponent {
	constructor(private readonly _authService: AuthService, private readonly _router: Router) {}

	/**
	 * @description
	 * Login the user using the auth service.
	 */
	public login(): void {
		this._authService.login();
	}

	/**
	 * @description
	 * Routes to the home page.
	 */
	public routeToHome(event: KeyboardEvent): void {
		event.preventDefault();
		// routerLink="/home" queryParamsHandling="merge" routerLinkActive="active"
		this._router.navigate(['/home'], {
			queryParams: { force: 1 },
			queryParamsHandling: 'merge'
		});
	}
}
