import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ESCAPE, getFocusableItems, TAB } from '@luis/ui';
import { Subject } from 'rxjs/Rx';

/**
 * @description
 * A base modal component for all modals in the system.
 */
@Component({
	selector: 'base-modal',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseModalComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() public response: Subject<any>;

	private _focusedBeforeOpen: Element;
	private _firstFocusableElement: Element;
	private _lastFocusableElement: Element;

	public ngOnInit(): void {
		this._onCreate();
	}

	public ngOnDestroy(): void {
		this._onDestroy();
	}

	public ngAfterViewInit(): void {
		this._findFocusableElements();
		if (this._firstFocusableElement) {
			(<any>this._firstFocusableElement).focus();
		}
	}

	/**
	 * @description
	 * Checks if the key pressed corresponds to any special
	 * handler for keys.
	 *
	 * @param ev The event object received.
	 */
	@HostListener('document:keydown', ['$event'])
	public onKeyUp(ev: KeyboardEvent): void {
		if (ev.keyCode === ESCAPE) {
			this.onCancelClick();
		} else if (ev.keyCode === TAB) {
			this._handleTabKeys(ev);
		}
	}

	/**
	 * @description
	 * Emits the event that the dialog was cancelled.
	 *
	 * @param event The event that fired this function.
	 */
	public onCancelClick(event?: Event): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
			event.stopImmediatePropagation();
		}

		this.response.error({ cancel: true });
		this.response.complete();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _onCreate(): void {
		this._focusedBeforeOpen = document.activeElement;
	}

	/**
	 * @description
	 * Destroys the component.
	 */
	private _onDestroy(): void {
		if (this._focusedBeforeOpen) {
			(<any>this._focusedBeforeOpen).focus();
		}
	}

	/**
	 * @description
	 * Gets all the selectable elements in the currently open dialog
	 * and finds the first and last focusable elements in it.
	 */
	private _findFocusableElements(): void {
		const modalContainer: Element = document.querySelector('modal-host');
		const focusableItems = getFocusableItems(<HTMLElement>modalContainer, <HTMLElement>modalContainer);

		this._firstFocusableElement = focusableItems[0];
		this._lastFocusableElement = focusableItems[focusableItems.length - 1];
	}

	/**
	 * @description
	 * Handles the tab key clicks. Ensures that the focus never leaves
	 * the modal dialog.
	 *
	 * @param keyEvent The keyboard event object.
	 */
	private _handleTabKeys(keyEvent: KeyboardEvent): void {
		if (!keyEvent.shiftKey) {
			if (document.activeElement === this._lastFocusableElement) {
				(<any>this._firstFocusableElement).focus();
				keyEvent.preventDefault();
			}
		} else {
			if (document.activeElement === this._firstFocusableElement) {
				(<any>this._lastFocusableElement).focus();
				keyEvent.preventDefault();
			}
		}
	}
}
