import { ICloneable } from '@luis/core';

/**
 * @dynamic
 * @description
 * Represents an app endpoint configuration. There are two main endpoint configurations for
 * staging and production slots respectively.
 *
 * @param name The slot name of the configuration
 * @param versionId The version id the configuration belongs to.
 * @param key The endpoint key assigned to this configuration.
 * @param url The endpoint url of this configuration
 * @param date The last published date of this configuration.
 * @param isStaging A flag to indicate whether this configuration is staging or production.
 * @param fullUrl The full endpoint url of this configuration.
 */
export class AppEndpointConfig implements ICloneable {
    public name: string;
    public versionId: string;
    public url: string;
    public publishedDate: Date;
    public isStaging: boolean;

    constructor(
        name: string = '', versionId: string = '',
        url: string = '', date: Date = null,
        isStaging: boolean = false
    ) {
        this.name = name;
        this.versionId = versionId;
        this.url = url;
        this.publishedDate = date;
        this.isStaging = isStaging;
    }

    /**
     * @description
     * Creates a new array of resource objects from the api object received from web.
     *
     * @param AppEndpointConfig The object received by the web api.
     * @returns An array of objects of this resource.
     */
    public static importBatchFromApi(apiObject: any): AppEndpointConfig[] {
        return Object.keys(apiObject).map(name => AppEndpointConfig.importFromApi(name, apiObject[name]));
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param name The slot name of the configuration provided.
     * @param AppEndpointConfig The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(name: string, apiObject: any): AppEndpointConfig {
        return new AppEndpointConfig(
            name,
            apiObject.versionId,
            apiObject.endpointUrl,
            apiObject.publishedDateTime ? new Date(apiObject.publishedDateTime) : null,
            apiObject.isStaging
        );
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): AppEndpointConfig {
        return new AppEndpointConfig(
            this.name.slice(),
            this.versionId.slice(),
            this.url.slice(),
            this.publishedDate ? new Date(this.publishedDate.getTime()) : null,
            this.isStaging
        );
    }
}
