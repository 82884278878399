import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileService, IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ClosedSublist } from '../../../../models/closed-entity.model';

/**
 * @description
 * Represents the modal for importing closed list sublists from a file.
 */
@Component({
	selector: 'import-list-modal',
	templateUrl: 'import-list-modal.component.html',
	styleUrls: ['import-list-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportListModalComponent {
	public isHelpVisible: boolean = false;
	public isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _selectedFile: File;

	constructor(
		private readonly _fileService: FileService,
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<ImportListModalComponent>,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService
	) {}

	/**
	 * @description
	 * Fires when the file field changes. Sets the validation subject stream
	 * based on whether the file field is valid or not.
	 *
	 * @param file The file to validate.
	 */
	public onImportFieldChange(file: File): void {
		this._selectedFile = file;
		this.isValid.next(this._validateFile(file));
	}

	/**
	 * @description
	 * Reads and parses the file and responds with its contents.
	 *
	 * @param importField The file field to get the file from.
	 */
	public onDoneClick(): void {
		this.inProgress.next(true);

		if (this._validateFile(this._selectedFile)) {
			this._fileService
				.read(this._selectedFile)
				.map(content => ClosedSublist.importFromFile(JSON.parse(content)))
				.trackProgress(
					this._toasterService.add({
						errorMsg: this._i18n.instant('entities.import-list-modal.invalid_syntax')
					})
				)
				.finally(() => this.inProgress.next(false))
				.subscribe(sublists => this._dialogRef.close(sublists));
		}
	}

	/**
	 * @description
	 * Checks if the file name and extension are valid.
	 *
	 * @param file The file to validate
	 * @returns True if the file is valid and false otherwise.
	 */
	private _validateFile(file: File): boolean {
		if (!file.name.trim().length) {
			return false;
		}

		const fileExtension: string = file.name.replace(/^.*\./, '');
		if (fileExtension.toLocaleLowerCase() !== 'json') {
			return false;
		}

		return true;
	}
}
