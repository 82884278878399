import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IWrapIndeces } from '../interfaces/IWrapIndeces';
import { Entity } from '../models/entity.model';
import { EntitySegment, Segment, SEGMENT_TYPES } from '../models/segment.model';

@Injectable()
export class EntityWrapService {
	/**
     * @description
     * Indicates whether the labelling is inside the composite (labelling children of the composite),
     * or outside of it 
     */
	private _isCompositeChildren = new BehaviorSubject(false);
	private _isWrapModeOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _wrapIndeces: BehaviorSubject<IWrapIndeces> = new BehaviorSubject<IWrapIndeces>({
		start: Number.POSITIVE_INFINITY,
		end: Number.NEGATIVE_INFINITY
	});
	private _wrapHoverIndeces: BehaviorSubject<IWrapIndeces> = new BehaviorSubject<IWrapIndeces>({
		start: Number.POSITIVE_INFINITY,
		end: Number.NEGATIVE_INFINITY
	});
	private _utteranceModelState: Observable<Segment[]>;

	/**
	 *
	 * @param utteranceModelState
	 */
	public initService(utteranceModelState: Observable<Segment[]>): void {
		this._utteranceModelState = utteranceModelState;
	}

    public getIsCompositeChildren(): Observable<boolean> {
		return this._isCompositeChildren.asObservable();
    }
    
    public setIsCompositeChildren(enable: boolean): void {
        this._isCompositeChildren.next(enable);
    }

	/**
	 * @method
	 * @description
	 */
	public getWrapMode(): Observable<boolean> {
		return this._isWrapModeOn.asObservable();
	}

	/**
	 *
	 * @param mode
	 */
	public setWrapMode(mode: boolean): void {
		if (!mode) {
			this._resetIndeces();
		}

		this._isWrapModeOn.next(mode);
	}

	/**
	 * @method
	 * @description
	 */
	public getWrapIndeces(): Observable<IWrapIndeces> {
		return this._wrapIndeces.asObservable();
	}

	/**
	 * @method
	 * @description
	 */
	public getWrapHoverIndeces(): Observable<IWrapIndeces> {
		return this._wrapHoverIndeces.asObservable();
	}

	/**
	 * @method
	 * @description
	 */
	public setWrapIndeces(start: number = null, end: number = null): void {
		if (!this._isWrapModeOn.getValue()) {
			return;
		}

		const wrapIndeces: IWrapIndeces = this._wrapIndeces.getValue();

		if (start !== null && end !== null && wrapIndeces.start <= start && end <= wrapIndeces.end) {
			wrapIndeces.end = end;
		}

		if (start !== null && start < wrapIndeces.start) {
			wrapIndeces.start = start;
		}

		if (end !== null && end > wrapIndeces.end) {
			wrapIndeces.end = end;
		}

		this._wrapIndeces.next(wrapIndeces);
	}

	/**
	 * @method
	 * @description
	 */
	public setWrapHoverIndeces(start: number = null, end: number = null): void {
		if (!this._isWrapModeOn.getValue()) {
			return;
		}

		const wrapIndeces: IWrapIndeces = this._wrapIndeces.getValue();
		const wrapHoverIndeces: IWrapIndeces = this._wrapHoverIndeces.getValue();

		if (start !== null && end !== null && wrapIndeces.start <= start && end <= wrapIndeces.end) {
			wrapHoverIndeces.start = wrapIndeces.start;
			wrapHoverIndeces.end = end;
		} else {
			if (start !== null && start < wrapIndeces.start) {
				wrapHoverIndeces.start = start;
			} else {
				wrapHoverIndeces.start = wrapIndeces.start;
			}

			if (end !== null && end > wrapIndeces.end) {
				wrapHoverIndeces.end = end;
			} else {
				wrapHoverIndeces.end = wrapIndeces.end;
			}
		}

		this._wrapHoverIndeces.next(wrapHoverIndeces);
	}

	/**
	 * @method
	 * @description
	 */
	public getWrappedEntities(): Observable<Entity[]> {
		return Observable.combineLatest(this._utteranceModelState, this._wrapIndeces.asObservable())
			.map(([segments, indeces]) => segments.filter(s => indeces.start <= s.start && s.end <= indeces.end))
			.map(segments => segments.filter(s => s.type === SEGMENT_TYPES.ENTITY))
			.map(segments => segments.map(s => (<EntitySegment>s).entity));
	}

	/**
	 *
	 * @param index
	 */
	public isValidSelection(index: number): Observable<boolean> {
		return Observable.combineLatest(this._utteranceModelState, this._wrapIndeces.asObservable()).map(([segments, indeces]) => {
			let { start, end } = indeces;

			if (index < start) {
				start = index;
			} else if (end < index) {
				end = index;
			}

			return segments.filter(s => start <= s.start && s.end <= end).find(s => s.type === SEGMENT_TYPES.COMPOSITE) === undefined;
		});
	}

	/**
	 * @description
	 */
	private _resetIndeces(): void {
		const wrapIndeces: IWrapIndeces = this._wrapIndeces.getValue();
		const wrapHoverIndeces: IWrapIndeces = this._wrapHoverIndeces.getValue();
		wrapIndeces.start = Number.POSITIVE_INFINITY;
		wrapIndeces.end = Number.NEGATIVE_INFINITY;
		wrapHoverIndeces.start = Number.POSITIVE_INFINITY;
		wrapHoverIndeces.end = Number.NEGATIVE_INFINITY;
		this._wrapIndeces.next(wrapIndeces);
		this._wrapHoverIndeces.next(wrapHoverIndeces);
	}
}
