/**
 * @method
 * @description
 * A class that represents an external subscription key.
 * Each key has a type and a key string.
 *
 * @param key The key value itself
 * @param type The type of the external key.
 */
export class ExternalKey {
    public static TYPES: string[] = [
        'BingSpellCheck'
    ];

    public key: string;
    public type: string;

    constructor(key: string = '', type: string = '') {
        this.key = key;
        this.type = type;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any[]): ExternalKey[] {
        const keys: ExternalKey[] = [];
        apiObject.forEach(pair => keys.push.apply(keys, (<any[]>pair.values).map(v => new ExternalKey(v, pair.type))));

        return keys;
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            value: this.key,
            type: this.type
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): ExternalKey {
        return new ExternalKey(this.key.slice(), this.type.slice());
    }
}
