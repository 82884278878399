import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '@luis/auth';
import { CookieConsentService, LANGS, persistLanguage, retrieveLanguage } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntroSlidesModalComponent } from '../../../../static/components/intro-slides-modal/intro-slides-modal.component';

enum PANELS {
	NONE = '',
	SETTINGS = 'Settings',
	HELP = 'Help',
	FEEDBACK = 'Feedback',
	PROFILE = 'Profile'
}

@Component({
	selector: 'logged-in-header',
	templateUrl: 'logged-in-header.component.html',
	styleUrls: ['./logged-in-header.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoggedInHeaderComponent implements OnInit {
	public isConsented: Observable<boolean>;
	public usernameInitials: Observable<string>;
	public selectedLanguage: BehaviorSubject<LANGS> = new BehaviorSubject<LANGS>(retrieveLanguage());

	public supportedLanguagesText: any = {
		[LANGS.EN]: 'English',
		[LANGS.FR]: 'French',
		[LANGS.DE]: 'German',
		[LANGS.ZH_HANS]: 'Chinese',
		[LANGS.IT]: 'Italy',
		[LANGS.ES]: 'Spanish'
	};
	public LANGS = LANGS;
	public PANELS = PANELS;
	public selectedPanel = PANELS.NONE;

	constructor(
		private readonly _router: Router,
		private readonly _authService: AuthService,
		private readonly _dialogService: MatDialog,
		private readonly _translateService: TranslateService,
		private readonly _consentService: CookieConsentService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Routes to the home page.
	 */
	public async routeToHome(event: KeyboardEvent): Promise<void> {
		event.preventDefault();
		// routerLink="/home" queryParamsHandling="merge" routerLinkActive="active"
		await this._router.navigate(['/home'], {
			queryParams: { force: 1 },
			queryParamsHandling: 'merge'
		});
	}

	/**
	 * @description
	 * Toggles a panel and if the panel is settings, it cancels the applied changes.
	 */
	public togglePanel(panel: PANELS): void {
		if (this.selectedPanel === panel) {
			this.cancelChanges();
			this.selectedPanel = PANELS.NONE;

			return;
		}

		this.selectedPanel = panel;
	}

	/**
	 * @description
	 * Closes a panel and if the panel is settings, it cancels the applied changes.
	 */
	public closePanel(panel: PANELS): void {
		if (this.selectedPanel === panel) {
			this.cancelChanges();
			this.selectedPanel = PANELS.NONE;
		}
	}

	/**
	 * @description
	 * Change and persist the selected language
	 */
	public applyChanges(): void {
		const selectedLanguage = this.selectedLanguage.getValue();
		this._translateService.use(selectedLanguage);
		persistLanguage(selectedLanguage);
		this.selectedPanel = PANELS.NONE;
	}

	/**
	 * @description
	 * Revert changes applied in the settings pane.
	 */
	public cancelChanges(): void {
		if (this.selectedPanel === PANELS.SETTINGS) {
			const persistedLang = retrieveLanguage();
			this.selectedLanguage.next(persistedLang);
		}
	}

	/**
	 * @description
	 * Logout the user using the auth service.
	 */
	public logout(): void {
		this._authService.logout();
	}

	public showTutorial(): void {
		this._dialogService.open(IntroSlidesModalComponent);
	}

	/**
	 * @description
	 * Initializes the component state.
	 */
	private _initState(): void {
		this.usernameInitials = this._authService
			.isBusy()
			.filter(p => !p)
			.flatMap(() => this._authService.isLoggedIn())
			.filter(s => s)
			.map(() => this._getInitials(this._authService.authInfo.name));

		this.isConsented = this._consentService.isConsented();
	}

	/**
	 * @description
	 * Gets the first two letters of the user's name.
	 */
	private _getInitials(name: string): string {
		const nameArr = name.split(' ');

		switch (nameArr.length) {
			case 1:
				return nameArr[0].charAt(0).toUpperCase();
			default:
				return nameArr[0].charAt(0).toUpperCase() + nameArr[1].charAt(0).toUpperCase();
		}
	}
}
