import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	Renderer2,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { ISortPipeProps } from '../interfaces/ISortPipeProps';
import { TableService } from '../services/table.service';
import { TableHeaderCellComponent } from '../table-header-cell/table-header-cell.component';

@Component({
	selector: '[m-table-header]',
	templateUrl: 'table-header.component.html',
	styleUrls: ['table-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class TableHeaderComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
	@Input() public isSelected: boolean;
	@Input() public sortProps: BehaviorSubject<ISortPipeProps>;
	@Output() public selectionChanged: EventEmitter<void> = new EventEmitter<void>();

	@ContentChildren(TableHeaderCellComponent, { read: ElementRef, descendants: true }) public headerCells: QueryList<ElementRef>;

	public multiSelect: Observable<boolean>;

	private _cellSubscription: Subscription = new Subscription();

	constructor(
		private readonly _elementRef: ElementRef,
		private readonly _renderer: Renderer2,
		private readonly _tableService: TableService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['isSelected']) {
			this._tableService.setSelectAll(changes['isSelected'].currentValue);
		}
	}

	public ngAfterContentInit(): void {
		this._initContentState();
	}

	public ngOnDestroy(): void {
		this._cellSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._tableService.setSortPropsSubject(this.sortProps);
		this._setRowProperties();
	}

	private _setRowProperties(): void {
		this._renderer.addClass(this._elementRef.nativeElement, 'm-table-header');
		this._renderer.setAttribute(this._elementRef.nativeElement, 'role', 'row');
	}

	/**
	 * @description
	 * Initializes the component state after the content
	 * got initialized.
	 */
	private _initContentState(): void {
		this.multiSelect = this._tableService.getMultiSelectStatus();
		this._cellSubscription = this.headerCells.changes.subscribe(() => this._updateHeaderCellAriaIndices());
		this._updateHeaderCellAriaIndices();
	}

	/**
	 * @description
	 * Adds correct indices to the column header cells.
	 */
	private _updateHeaderCellAriaIndices(): void {
		this.headerCells.forEach((cell, index) => this._renderer.setAttribute(cell.nativeElement, 'aria-colindex', `${index + 1}`));
	}
}
