import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FEATURE_RATING_TYPES } from '../../models/utils/feature-rating.model';
import { FeatureRatingService } from '../../services/utils/feature-rating.service';

@Component({
	selector: 'feature-rating',
	templateUrl: './feature-rating.component.html',
	styleUrls: ['./feature-rating.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureRatingComponent implements OnInit {
	@Input() public eventCategory: string;
	@Input() public eventAction: string;
	@Input() public featureRatingType: FEATURE_RATING_TYPES;

	public selection: boolean;

	constructor(private readonly _featureRatingService: FeatureRatingService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * @param value
	 */
	public setRating(value: boolean): void {
		this._featureRatingService.setFeatureRating(this.featureRatingType, value);
		this.selection = value;
	}

	/**
	 * @description
	 */
	private _initState(): void {
		this.selection = this._featureRatingService.getFeatureRating(this.featureRatingType);
	}
}
