import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Suggestion, SuggestionApiParameter } from '../models/suggestion.model';

export const PHRASE_LIST_UTILITIES_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IPhraseListUtilitiesService');

/**
 * @description
 * Represents an interface for a phrase list utilities service. A phrase list utility service should contain
 * helper methods for LUIS phrase list features.
 */
export interface IPhraseListUtilitiesService {
    getSuggestions(id?: number, param?: SuggestionApiParameter): Observable<Suggestion>;

    getPreSuggestions(id?: number): Observable<Suggestion>;
}
