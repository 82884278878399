import { LuisModel } from '../../models/resources/luis-model.model';

/**
 * @description
 * An enumeration of the change types that can occur on a model.
 */
export enum MODEL_CHANGE_TYPE {
    DELETE,
    UPDATE,
    ADD
}

/**
 * @description
 * An interface for event bus messages that notify that a certain
 * LUIS model has changed.
 *
 * @param model The model that was changed.
 * @param changeType The type of change that occurred.
 */
export interface IModelChangeBusMessage {
    model: LuisModel;

    changeType: number;
}
