import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnInit
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '../../interfaces/services/utils/IToasterService';
import { Toast } from '../../models/utils/toast.model';

@Component({
    selector: 'toaster',
    templateUrl: 'toaster.component.html',
    styleUrls: ['toaster.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToasterComponent implements OnInit {
    @Input() public top: number;

    public toasts: Observable<Toast[]>;
    public firstVisibleToast: Observable<Toast>;
    public showAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public areMultipleToastsAvailable: Observable<boolean>;

    constructor(
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Toggles whether to show the first toast only
     * or all the toasts in the system.
     */
    public toggleShowAll(): void {
        this.showAll.next(!this.showAll.getValue());
    }

    /**
     * @description
     * Deletes the given toast from the toast service.
     *
     * @param toast The toast to delete.
     */
    public deleteToast(toast: Toast): void {
        this._toasterService.delete(toast);
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.toasts = this._toasterService.get();

        this.firstVisibleToast = this.toasts.flatMap(toasts => this._getFirstVisibleToast(toasts));

        this.areMultipleToastsAvailable = this.toasts
            .flatMap(toasts => this._getToastsWithVisibleMessagesCount(toasts))
            .map(toastCount => toastCount > 1);
    }

    /**
     * @description
     * Gets the number of toasts that have non-null current messages.
     *
     * @param toasts The toasts to count.
     * @returns The number of toasts with visible messages.
     */
    private _getToastsWithVisibleMessagesCount(toasts: Toast[]): Observable<number> {
        if (!toasts.length) {
            return Observable.of(0);
        }

        return Observable.combineLatest(toasts.map(t => t.visibleMsg))
            .map(messages => messages.filter(m => m !== null))
            .map(nonNullMessages => nonNullMessages.length);
    }

    /**
     * @description
     * Gets the first toast with a visible message that is not null.
     *
     * @param toasts The toasts currently in the system.
     * @returns The toast to show.
     */
    private _getFirstVisibleToast(toasts: Toast[]): Observable<Toast> {
        if (!toasts.length) {
            return Observable.of(null);
        }

        return Observable.combineLatest(toasts.map(t => t.visibleMsg))
            .map(messages => messages.findIndex(m => m !== null))
            .map(index => index !== -1 ? toasts[index] : null);
    }
}
