import { InjectionToken } from '@angular/core';
import { Id } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { PhraseList } from '../models/phrase-list.model';

export const PHRASE_LIST_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IPhraseListService');

/**
 * @description
 * Represents an interface for a phrase list service. A phrase list service should contain
 * CRUD methods for LUIS phrase list features.
 */
export interface IPhraseListService {
    get(refresh?: boolean): Observable<PhraseList[]>;

    add(phraseList: PhraseList, refresh?: boolean): Observable<number>;

    update(phraseList: PhraseList): Observable<void>;

    delete(id: number): Observable<void>;

    batchDelete(phraseLists: PhraseList[]): Observable<Id[]>;
}
