import { BehaviorSubject, Observable, Observer } from 'rxjs/Rx';
import { ITrackerMessage, PROGRESS_STATES } from '../../helpers/track-progress.operator';

/**
 * @export
 * @description
 * Re-export the interface and progress states. All consumers of the ProgressTracker
 * class will need them anyway.
 */
export { ITrackerMessage, PROGRESS_STATES };

/**
 * @description
 * Represents a tracker that can be used with the progress tracker observable operator to
 * track and react to changes in the underlying observable's state.
 */
export class ProgressTracker {
	public isStarted: Observable<boolean>;
	public isNotStarted: Observable<boolean>;
	public hasData: Observable<boolean>;
	public isEnded: Observable<boolean>;
	public isError: Observable<boolean>;
	public error: Observable<Error>;

	protected _subject: BehaviorSubject<ITrackerMessage>;

	constructor() {
		this._initStreams();
	}

	/**
	 * @description
	 * Exposes the inner subject as an observer. Used to provide tracker to the
	 * progress tracker observable operator.
	 */
	public getTracker(): Observer<ITrackerMessage> {
		return this._subject;
	}

	/**
	 * @description
	 * Initializes the streams needed to track progress of underlying observable.
	 */
	private _initStreams(): void {
		this._subject = new BehaviorSubject<ITrackerMessage>({ state: PROGRESS_STATES.NOT_STARTED });
		this.isStarted = this._subject.asObservable().map(progress => progress.state === PROGRESS_STATES.STARTED);
		this.isNotStarted = this._subject.asObservable().map(progress => progress.state === PROGRESS_STATES.NOT_STARTED);
		this.hasData = this._subject.asObservable().map(progress => progress.state === PROGRESS_STATES.HAS_DATA);
		this.isEnded = this._subject.asObservable().map(progress => progress.state === PROGRESS_STATES.ENDED);
		this.isError = this._subject.asObservable().map(progress => progress.state === PROGRESS_STATES.ERROR);
		this.error = this._subject
			.asObservable()
			.filter(progress => progress.state === PROGRESS_STATES.ERROR)
			.map(progress => progress.error);
	}
}
