import { Injectable } from '@angular/core';
import { PopupMeasuringComponent } from '../popup-measuring/popup-measuring.component';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ISize, IRect } from '../utils/rectangle';

export interface MeasureableComponent {
    measure: () => ISize
}

export interface ComponentInstance {
    component: MeasureableComponent,
    props: any,
    reject: (err: Error) => void,
    resolve: (size: ISize) => void
}

/** Service used as a communication interface between a component
 * using a popup (and needing the size of that popup)
 * and the PopupMeasuringComponent
 */
@Injectable()
export class PopupMeasurementService {
    private _measureSource = new Subject<ComponentInstance>();
    private _popupMeasurementComponent: PopupMeasuringComponent;

    public measureObservable: Observable<ComponentInstance> = this._measureSource.asObservable();

    public async measure(component: any, props: any): Promise<ISize> {
        return new Promise<ISize>((resolve, reject) => {
            this._measureSource.next({ component, props, reject, resolve });
        });
    }

    public registerMeasurementComponent(measurementComponent: PopupMeasuringComponent) {
        this._popupMeasurementComponent = measurementComponent;
    }
}