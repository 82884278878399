import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as c3 from 'c3';
import { AxisData, CHART_TYPES } from '../../models/chart.model';
import { ChartsRendererService } from '../../services/charts-renderer.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
	selector: 'line-chart',
	template: '<loading [visible]="!(isInitialized)"></loading>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent extends BaseChartComponent implements OnInit {
	constructor(
		private readonly i18n: TranslateService,
		private readonly elementRef: ElementRef,
		private readonly chartsRenderer: ChartsRendererService
	) {
		super(i18n, elementRef, chartsRenderer);
	}

	public ngOnInit(): void {
		this.xData
			.zip(this.yData, (xData, yData) => ({ xData, yData }))
			.map(this.filterZeroedData)
			.subscribe(({ xData, yData }) => {
				const config: c3.ChartConfiguration = this._getConfig(xData, yData);

				if (!this.isInitialized) {
					this.init(config);
					this.isInitialized = true;
				} else {
					this.update({ data: config.data, columns: config.data.columns }, yData.data.length === 0);
				}
			});
	}

	/**
	 * @description
	 * Initializes the chart component with the required configuration
	 */
	protected init(config: c3.ChartConfiguration): void {
		const padding: any =
			config.data.columns.length > 0
				? {
						bottom: 38,
						right: 16
				  }
				: {
						bottom: 32,
						left: 16,
						right: 16
				  };
		super.init({
			...config,
			padding,
			bindto: this.elementRef.nativeElement
		});
	}

	/**
	 * @description
	 * Formating function used in c3
	 */
	private _tickFormat(xData: AxisData): (data: Date) => string | Date {
		return (data: Date): string | Date => {
			if (xData.isTimeSeries) {
				const day: number = data.getDate();
				const month: string = data.toLocaleString(this.i18n.getDefaultLang(), {
					month: 'short'
				});

				return `${month} ${day}`;
			}

			return data;
		};
	}

	/**
	 * @description
	 * Creates the configuration accepted by the ChartRendererService for generating a pie chart
	 */
	private _getConfig(xData: AxisData, yData: AxisData): c3.ChartConfiguration {
		const data: any = {
			x: xData.title,
			xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
			columns: yData.data.length > 0 ? [[xData.title, ...xData.data], [yData.title, ...yData.data]] : [],
			empty: {
				label: {
					text: yData.data.length > 0 ? '' : this.emptyLabelText
				}
			},
			type: CHART_TYPES.LINE
		};

		const grid: any = {
			y: {
				show: true
			}
		};

		const axis: any = {
			x: {
				type: xData.isTimeSeries ? 'timeseries' : 'category',
				tick: {
					count: 8,
					show: true,
					format: this._tickFormat(xData),
					outer: false,
					fit: true,
					width: 0
				}
			},
			y: {
				show: Boolean(yData.data.length),
				tick: {
					count: 6,
					format: (d: number) => d.toFixed()
				},
				min: 0,
				padding: { top: 0, bottom: 0 }
			}
		};

		return { data, axis, grid, onrendered: this.onLoad };
	}
}
