import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input
} from '@angular/core';

@Component({
    selector: 'm-toolbar-group',
    templateUrl: 'toolbar-group.component.html',
    styleUrls: ['toolbar-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarGroupComponent {
    @Input() @HostBinding('class.right') public right: boolean = false;
}
