import { HttpEvent, HttpResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IRequestOptions } from './IRequestOptions';

export const HTTP_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IHttpService');

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

/**
 * @description
 * Represents an interface for a generic html service. An html service is responsible for
 * making web calls with no caching.
 */
export interface IHttpService {
	// Angular typing is getting in the way of adding the "verbose" option in the IRequestOptions
	// interface without having to resort to using 'any' keywords. Typescript doesn't allow
	// overloading with different named values of a single property in an interface.
	get<T>(url: string, options?: IRequestOptions): Observable<T>;
	get<T>(url: string, options: IRequestOptions, verbose: boolean): Observable<HttpResponse<T>>;

	post<T>(url: string, body: Object, options?: IRequestOptions, verbose?: boolean): Observable<T | HttpResponse<T>>;

	put<T>(url: string, body: Object, options?: IRequestOptions): Observable<T>;

	delete<T>(url: string, options?: IRequestOptions): Observable<T>;

	request<T>(method: HttpMethod, url: string, body?: Object, options?: IRequestOptions): Observable<T>;

	download(url: string, options?: IRequestOptions): Observable<HttpEvent<Blob>>;

	upload<T>(url: string, body: Object, options?: IRequestOptions): Observable<HttpEvent<T>>;
}
