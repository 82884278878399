import { InjectionToken } from '@angular/core';
import { IPage, LuisModel } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { Utterance } from '../../models/utterance.model';

export const UTTERANCE_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IUtteranceService');

/**
 * @description
 * Represents an interface for an utterance service. An utterance service should contain
 * CRUD methods for LUIS utterances.
 */
export interface IUtteranceService {
	get(modelId: string, pageIndex: number, getAll?: boolean): Observable<IPage<Utterance>>;

	add(utterance: Utterance): Observable<number>;

	addSync(utterance: Utterance): void;

	batchAdd(utterances: Utterance[], type?: 'intent' | 'entity'): Observable<number[]>;

	replace(utterance: Utterance, idToReplace: number): Observable<void>;

	delete(utteranceId: number): Observable<void>;

	batchDelete(utteranceIds: number[]): Observable<number[]>;

	suggest(model: LuisModel, roleId?: string): Observable<Utterance[]>;

	deleteSuggest(utterances: Utterance[]): Observable<any>;

	updateTestingUtterance(utterance: Utterance, intentName: string): void;
}
