import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { Id, IResource } from '../../interfaces/models/IResource';

/**
 * @description
 * Represent the object that used to state the toolbar diffrent options states.
 * none: there is no selected item.
 * single: there is one selected item.
 * batch: there is more than one selected item/
 */
export interface IToolBarOps {
	none: boolean;

	single: boolean;

	batch: boolean;
}

export enum SELECT_ALL_STATE {
	NONE,
	ALL,
	PARTIAL
}

/**
 * @description
 * Abstracts away multi selection tracking functionality
 * into a separate class.
 */
export class SelectionMap {
	private readonly _selectAllStateSubject: BehaviorSubject<SELECT_ALL_STATE> = new BehaviorSubject<SELECT_ALL_STATE>(
		SELECT_ALL_STATE.NONE
	);
	private readonly _mapSubject: BehaviorSubject<Map<Id, boolean>> = new BehaviorSubject<Map<Id, boolean>>(new Map<Id, boolean>());
	private _toolBarOps: Observable<IToolBarOps>;
	private _selectAllState: Observable<boolean>;

	constructor() {
		this._initState();
	}

	/**
	 * @description
	 * Gets an observable of the current toolbar operations
	 *
	 * @returns An observable of the toolbar operations to
	 * display based on the selection state.
	 */
	public get toolbarOps(): Observable<IToolBarOps> {
		return this._toolBarOps;
	}

	/**
	 * @description
	 * Gets the current select all checkbox state as
	 * an observable.
	 *
	 * @returns An observable of boolean where true means
	 * that the select checkbox should be selected and
	 * false otherwise.
	 */
	public get selectAllState(): Observable<boolean> {
		return this._selectAllState;
	}

	/**
	 * @description
	 * Returns all the ids in the selection map, whether selected
	 * or not.
	 *
	 * @returns An array of the ids in the selection map
	 */
	public get allItems(): Id[] {
		return Array.from(this._mapSubject.getValue().keys());
	}

	/**
	 * @description
	 * Gets the currently selected ids. Serves as a snapshot
	 * alternative to the next function.
	 *
	 * @returns An array of currently selected ids.
	 */
	public get selectedItems(): Id[] {
		return Array.from(this._mapSubject.getValue().entries())
			.filter(([, isSelected]) => isSelected)
			.map(([id]) => id);
	}

	/**
	 * @description
	 * Gets an observable of the selected ids currently
	 * in the selection map. Useful for functions that need
	 * to react to selection changes.
	 *
	 * @returns An observable of the selected ids.
	 */
	public get selectedItemsAsync(): Observable<Id[]> {
		return this._mapSubject
			.asObservable()
			.map(map => Array.from(map.entries()))
			.map(entries => entries.filter(([_, isSelected]) => isSelected))
			.map(entries => entries.map(([id]) => id));
	}

	/**
	 * @description
	 * Used to construct the map.
	 * Sets initial state of all ids to false.
	 *
	 * @param ids The array of items id used as a key in the map.
	 */
	public setMapItems(ids: Id[]): void {
		const map = this._mapSubject.getValue();
		map.clear();
		ids.forEach(id => map.set(id, false));
		this._mapSubject.next(map);
	}

	/**
	 * @description
	 * Constructs the map with the initial ids using the original
	 * data objects.
	 *
	 * @param data The array of data.
	 * @param param The identifier parameter of the data.
	 */
	public setMapItemsByObject(data: IResource[], keyParam: string): void {
		const map = this._mapSubject.getValue();
		map.clear();
		data.forEach(datum => map.set(datum[keyParam], false));
		this._mapSubject.next(map);
	}

	/**
	 * @description
	 * Toggles the select all state of the stream based
	 * on the current value already.
	 */
	public toggleSelectAll(): void {
		let newValue: SELECT_ALL_STATE;

		if (this.allItems.length === 0) {
			return;
		}

		switch (this._selectAllStateSubject.getValue()) {
			case SELECT_ALL_STATE.NONE:
			case SELECT_ALL_STATE.PARTIAL:
			default:
				newValue = SELECT_ALL_STATE.ALL;
				break;
			case SELECT_ALL_STATE.ALL:
				newValue = SELECT_ALL_STATE.NONE;
		}

		this._selectAllStateSubject.next(newValue);
	}

	/**
	 * @description
	 * Used to toggle the items selections.
	 *
	 * @param ids The array of ids of items to be toggled.
	 */
	public toggleSelection(ids: Id[]): void {
		const map = this._mapSubject.getValue();
		ids.filter(id => map.has(id)).forEach(id => map.set(id, !map.get(id)));
		this._mapSubject.next(map);
	}

	/**
	 * @description
	 * Used to mark the items as selected.
	 *
	 * @param ids The array of ids of items to be selected.
	 */
	public markSelected(ids: Id[]): void {
		const map = this._mapSubject.getValue();
		ids.filter(id => map.has(id)).forEach(id => map.set(id, true));
		this._mapSubject.next(map);
	}

	/**
	 * @description
	 * Used to mark the items as unselected.
	 *
	 * @param ids The array of ids of items to be unselected.
	 */
	public markUnselected(ids: Id[]): void {
		if (!this.selectedItems.length) {
			return;
		}

		const map = this._mapSubject.getValue();
		ids.filter(id => map.has(id)).forEach(id => map.set(id, false));
		this._mapSubject.next(map);
	}

	/**
	 * @description
	 * Checks if the given item is selected or not.
	 *
	 * @param id The id to be checked
	 */
	public isSelected(id: Id): boolean {
		return this._mapSubject.getValue().get(id);
	}

	/**
	 * @description
	 * Checks if the given id is selected and keeps subscribing to it.
	 *
	 * @param id The id to check.
	 * @returns An observable of whether the id selected or not.
	 */
	public isSelectedAsync(id: Id): Observable<boolean> {
		return this.selectedItemsAsync.map(() => this.isSelected(id));
	}

	/**
	 * @description
	 * Initializes the state of the class.
	 */
	private _initState(): void {
		this._toolBarOps = this._mapSubject
			.asObservable()
			.map(() => this.selectedItems.length)
			.map(count => {
				if (count === 0) {
					return { none: true, single: false, batch: false };
				}
				if (count === 1) {
					return { none: false, single: true, batch: false };
				}
				if (count > 1) {
					return { none: false, single: false, batch: true };
				}
			})
			.shareReplay();

		this._selectAllState = this._selectAllStateSubject
			.asObservable()
			.distinctUntilChanged()
			.do(state => {
				if (state === SELECT_ALL_STATE.ALL) {
					this.markSelected(this.allItems);
				} else if (state === SELECT_ALL_STATE.NONE) {
					this.markUnselected(this.allItems);
				}
			})
			.map(state => (state === SELECT_ALL_STATE.ALL ? true : false));

		this.selectedItemsAsync
			.map(selectedIds => {
				if (selectedIds.length === 0) {
					return SELECT_ALL_STATE.NONE;
				}
				if (selectedIds.length === this.allItems.length) {
					return SELECT_ALL_STATE.ALL;
				}
				if (selectedIds.length > 0 && selectedIds.length < this.allItems.length) {
					return SELECT_ALL_STATE.PARTIAL;
				}
			})
			.subscribe(selectAllState => this._selectAllStateSubject.next(selectAllState));
	}
}
