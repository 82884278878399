import { Component, HostListener, Renderer2 } from '@angular/core';

/**
 * @description
 * This directive adds a focus container class to ensure that
 * focus styling appears only when keyboard is used.
 */
@Component({
	selector: 'disable-mouse-focus',
	template: ''
})
export class DisableMouseFocusComponent {
	constructor(private readonly _renderer: Renderer2) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Disable focus styling when mouse is used.
	 */
	@HostListener('document:mousedown')
	public onMouseDown(): void {
		this._renderer.removeClass(document.body, 'm-has-focus');
	}

	/**
	 * @description
	 * Enable focus styling when keyboar is used.
	 */
	@HostListener('document:keydown')
	public onKeyDown(): void {
		this._renderer.addClass(document.body, 'm-has-focus');
	}

	/**
	 * @description
	 * Initialize the component.
	 */
	private _initState(): void {
		this._renderer.addClass(document.body, 'm-focus-container');
	}
}
