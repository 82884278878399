import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { Consent } from '../../models/utils/cookie-consent.model';

/**
 * @description
 * Contains methods for applying the Microsoft consent cookie and
 * enabling/disabling website features based on the consent value.
 */
@Injectable()
export class CookieConsentService {
	private readonly _isConsented: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

	constructor(@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService) {}

	/**
	 * @description
	 * Gets the value on the consent stream.
	 *
	 * @returns An observable of boolean that states the consent.
	 */
	public isConsented(): Observable<boolean> {
		return this._isConsented.asObservable().pipe(filter(isConsented => isConsented !== undefined));
	}

	/**
	 * @description
	 * Sets the consent stream to the value given.
	 *
	 * @param value The value to set for the stream.
	 */
	public setConsent(value: boolean): void {
		this._isConsented.next(value);
	}

	/**
	 * @description
	 * Checks if the current locale and language require or not.
	 *
	 * @param locale The current locale of the website to check the cookie consent for.
	 * @param country The current country the website is being used in. If the country
	 * is not provided, consent is given based on locale.
	 * @returns An observable of the consent for the given parameters.
	 */
	public checkConsent(locale: string = 'en-us', country: string = 'euregion'): Observable<Consent> {
		const url: string = `https://uhf.microsoft.com/${locale}/shell/api/mscc`;
		const params: string = `?sitename=Cognitive Services (LUIS)&domain=www.luis.ai&country=${country}&mscc_eudomain=true`;

		return this._httpService.get(`${url}${params}`).map(data => Consent.importFromApi(data));
	}
}
