import { Entity, ENTITY_TYPES, EntityRole } from './entity.model';

/**
 * @description
 * Represents a LUIS prebuilt entity. Inherits the same properties of a simple
 * entity but has a differnt way to export to the API.
 */
export class PrebuiltEntity extends Entity {
    constructor(id: string = '', name: string = '', roles: EntityRole[] = [], parent: Entity = null) {
        super(id, name, ENTITY_TYPES.PREBUILT, null, roles, parent);
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): PrebuiltEntity {
        const prebuiltEntity: PrebuiltEntity = <PrebuiltEntity>Entity.constructBaseEntity(apiObject, new PrebuiltEntity());
        prebuiltEntity.labelCount = null;

        return prebuiltEntity;
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): string {
        return this.name;
    }
}
