import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericPromptService, IResourceOperation, IToasterService, PromptButtonTypes, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { combineLatest } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { IPhraseListService, PHRASE_LIST_SERVICE_TOKEN } from '../../interfaces/IPhraseListService';
import { PHRASE_LIST_OPERATIONS } from '../../models/phrase-list-operations.model';
import { PhraseList } from '../../models/phrase-list.model';
import { PhraseListCreationModalComponent } from './phrase-list-creation-modal/phrase-list-creation-modal.component';

/**
 * @description
 * Represents an phrase-list list component that hosts a phrase-list table. The phrase-list list is
 * responsible for any service communcation and mediating between children components and
 * external services.
 */
@Component({
	selector: 'phrase-list-list',
	templateUrl: 'phrase-list-list.component.html',
	styleUrls: ['phrase-list-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhraseListListComponent implements OnInit {
	@Input() public areSuggestionsEnabled: boolean = true;
	@Input() public featuresLimit: number = 10;

	public phraseLists: Observable<PhraseList[]>;

	constructor(
		private readonly _dialogService: MatDialog,
		private readonly _promptService: GenericPromptService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(PHRASE_LIST_SERVICE_TOKEN) private readonly _phraseListService: IPhraseListService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Applies the given operation on the phrase list based on received.
	 *
	 * @param phraseListOp The phrase list(s) and the operation to apply on it.
	 */
	public onOperationClicked(phraseListOp: IResourceOperation): void {
		switch (phraseListOp.operation) {
			case PHRASE_LIST_OPERATIONS.DELETE:
				this._deletePhraseList(phraseListOp);
				break;
			case PHRASE_LIST_OPERATIONS.ADD:
				this._createModal().subscribe();
				phraseListOp.response.complete();
				break;
			case PHRASE_LIST_OPERATIONS.ACTIVATE:
			case PHRASE_LIST_OPERATIONS.DEACTIVATE:
				this._updatePhraseList(phraseListOp);
				break;
			default:
		}
	}

	/**
	 * @description
	 * Opens the phrase list modal in edit mode with the given phrase list.
	 *
	 * @param phraseList The phrase list to edit.
	 */
	public openEditModal(phraseList: PhraseList): void {
		this._createModal(phraseList).subscribe();
	}

	/**
	 * @description
	 * Initializes the component state.
	 */
	private _initState(): void {
		this.phraseLists = this._phraseListService.get();
	}

	/**
	 * @description
	 * Creates a phrase list modal to display the phrase list in.
	 *
	 * @param phraseList The phrase list to show in the modal.
	 */
	private _createModal(phraseList: PhraseList = new PhraseList()): Observable<PhraseList> {
		const baseParameters = { phraseList: phraseList, showSuggestions: this.areSuggestionsEnabled };

		return this._dialogService.open(PhraseListCreationModalComponent, { width: '900px', data: baseParameters }).afterClosed();
	}

	/**
	 * @description
	 * Updates the given phrase list(s) in the application.
	 *
	 * @param phraseListOp The phrase list(s) and the operation to apply on it.
	 */
	private _updatePhraseList(phraseListOp: IResourceOperation): void {
		const phraseLists: PhraseList[] = <PhraseList[]>phraseListOp.items;

		phraseLists.forEach(p => {
			switch (phraseListOp.operation) {
				case PHRASE_LIST_OPERATIONS.ACTIVATE:
					p.isActive = true;
					break;
				case PHRASE_LIST_OPERATIONS.DEACTIVATE:
					p.isActive = false;
					break;
				default:
			}
		});

		combineLatest(phraseLists.map(phraseList => this._phraseListService.update(phraseList)))
			.trackProgress(this._toasterService.add({ startMsg: `Updating phrase list${phraseLists.length > 1 ? 's' : ''}` }))
			.subscribe(() => phraseListOp.response.complete());
	}

	/**
	 * @description
	 * Deletes the given phrase list from the application
	 *
	 * @param phraseListOp The phrase list(s) and the operation to apply on it.
	 */
	private _deletePhraseList(phraseListOp: IResourceOperation): void {
		const phraseLists: PhraseList[] = <PhraseList[]>phraseListOp.items;
		const isBatch = phraseLists.length > 1;

		this._promptService
			.prompt(
				`Delete phrase ${isBatch ? 'lists' : 'list'}?`,
				`Are you sure you want to delete ${isBatch ? 'the selected phrase lists' : 'phrase list "' + phraseLists[0].name}" ?`,
				{ ok: 'Ok', cancel: 'Cancel' },
				{ ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
			)
			.pipe(
				filter(choice => choice === 'ok'),
				flatMap(() =>
					this._phraseListService
						.batchDelete(phraseLists)
						.trackProgress(this._toasterService.add({ startMsg: `Deleting phrase list${isBatch ? 's' : ''}` }))
				)
			)
			.subscribe(() => phraseListOp.response.complete());
	}
}
