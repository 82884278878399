import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { GenericPromptService, IToasterService, PromptButtonTypes, SelectionMap, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { ToolbarInputComponent } from '@luis/ui';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { Entity, EntityRole } from '../../../models/entity.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * @description
 * Represents the roles table for entities. Contains controls
 * for adding, deleting and updating roles for each entity.
 */
@Component({
    selector: 'role-details',
    templateUrl: 'role-details.component.html',
    styleUrls: ['role-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleDetailsComponent implements OnInit, OnDestroy {
    @Input() public entity: Observable<Entity>;

    public selectionMap: SelectionMap = new SelectionMap();
    public refreshableEntity: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(new Entity());

    private _entitySubscription: Subscription = new Subscription();

    constructor(
        private _promptService: GenericPromptService,
        private _i18n: TranslateService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        this._entitySubscription.unsubscribe();
    }

    /**
     * @description
     * Adds a new role to the entity.
     *
     * @param roleName The role name to add.
     * @param roleInputField The role name input element.
     */
    public addRole(roleName: string, roleInputField: ToolbarInputComponent): void {
        const name: string = roleName.trim();

        if (!name.length) {
            return;
        }

        this.entity
            .first()
            .map(entity => entity.clone())
            .map(entity => entity.appendRole(name))
            .flatMap(role => this._entityService.add(role))
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.role-details.add_toast') }))
            .subscribe(() => {
                roleInputField.inputField.nativeElement.value = '';
            });
    }

    /**
     * @description
     * Fires when an enter key is pressed in any of the role
     * text fields.
     */
    public updateRole(role: EntityRole): void {
        const name: string = role.name.trim();

        if (!name.length) {
            return;
        }

        role.name = name;

        this.entity
            .first()
            .map(entity => entity.clone())
            .map(entity => entity.roles.find(r => r.id === role.id))
            .filter(unmutatedRole => unmutatedRole.name !== role.name)
            .do(unmutatedRole => (unmutatedRole.name = role.name))
            .flatMap(() => this._entityService.update(role))
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.role-details.rename_toast') }))
            .subscribe(null, () => this._revertChanges());
    }

    /**
     * @description
     * Deletes the selected roles from the selection map.
     */
    public onDeleteClicked(): void {
        const ids = this.selectionMap.selectedItems;

        this._promptService
            .prompt(
                'Delete role?',
                `Are you sure you want to delete ${ids.length > 1 ? 'those roles' : 'this role'}?`,
                { ok: 'Ok', cancel: 'Cancel' },
                { ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
            )
            .filter(response => response === 'ok')
            .flatMap(() => this.entity.first())
            .map(entity => entity.clone())
            .map(entity => ({ entity: entity, roles: ids.map(id => entity.roles.find(r => r.id === id)) }))
            .do(data => data.roles.forEach(role => data.entity.removeRole(data.entity.roles.findIndex(r => r.id === role.id))))
            .flatMap(data =>
                Observable.combineLatest(data.roles.map(role => this._entityService.delete(role))).trackProgress(
                    this._toasterService.add({ startMsg: `Deleting ${ids.length > 1 ? 'roles' : 'role'}` })
                )
            )
            .subscribe(null, () => this._revertChanges());
    }

    /**
     * @description
     * Angular for loop tracking function avoids rerendering.
     */
    public trackRole(index: number, role: any): number {
        return role.id;
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this._entitySubscription = this.entity
            .map(e => e.clone())
            .do(e => e.roles.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1)))
            .subscribe(e => this.refreshableEntity.next(e));
    }

    /**
     * @description
     * Reverts any changes made if the call errored.
     */
    private _revertChanges(): void {
        this.entity.first().subscribe(e => this.refreshableEntity.next(e.clone()));
    }
}
