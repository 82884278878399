import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injector
} from '@angular/core';
import { ComponentPortal, TemplatePortal } from './portal';
import { BasePortalHost } from './portal-host';

/**
 * @description
 * Portal host implementation that is used to attach pieces of
 * UI (portals) to an arbitrary DOM element outside of the
 * application scope.
 *
 * (See Overlay service, which creates a container in doc body)
 */
export class DomPortalHost extends BasePortalHost {

    constructor(
        private _hostDomElement: Element,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _applicationRef: ApplicationRef,
        private _injector: Injector
    ) {
        super();
    }

    /**
     * @method
     * @description
     * Attaches a component instance to the DOM.
     * @param portal Portal to be attached to the DOM.
     */
    attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        // create a component reference with a factory resolver, using the component type provided by the portal
        let componentFactory = this._componentFactoryResolver.resolveComponentFactory(portal.component);
        let componentRef = componentFactory.create(portal.injector || this._injector)
        componentRef.hostView.detectChanges();

        // If the portal provides a viewRef that we can attach to, we will attach the
        // component there, just in terms of the component tree.
        // If not we will manually attach the component view to the application.
        if (portal.viewContainerRef) {
            portal.viewContainerRef.insert(componentRef.hostView);
            this.setDisposeFn(() => {
                componentRef.destroy();
            });
        } else {
            this._applicationRef.attachView(componentRef.hostView);
            this.setDisposeFn(() => {
                this._applicationRef.detachView(componentRef.hostView);
                componentRef.destroy();
            })
        }

        // inject the component into the host DOM element (eg. overlay container)
        this._hostDomElement.appendChild(this._getComponentRootNode(componentRef));
        return componentRef;
    }

    /**
     * @method
     * @description
     * Attaches a template to the DOM as an embedded view.
     * @param portal Portal to be attached to the DOM.
     */
    attachTemplatePortal<T>(portal: TemplatePortal): Map<string, any> {
        // create embedded view based off of the portal's template
        let viewContainer = portal.viewContainerRef;
        let viewRef = viewContainer.createEmbeddedView(portal.templateRef);
        viewRef.detectChanges();

        // append all root nodes of the embedded template to the DOM host (eg. overlay container)
        viewRef.rootNodes.forEach(rootNode => {
            this._hostDomElement.appendChild(rootNode);
        });

        // set up dispose function to remove template nodes from the DOM
        this.setDisposeFn(() => {
            let index = viewContainer.indexOf(viewRef);
            if (index !== -1) {
                viewContainer.remove(index);
            }
        });

        // doesn't do anything right now, supposed to use locals (remove???)
        return new Map<string, any>();
    }

    /**
     * @method
     * @description
     * Removes a portal from the DOM.
     */
    dispose() {
        super.dispose();
        // if the host element has a parent node (overlay container / portal host container)
        if (this._hostDomElement.parentNode != null) {
            // remove this element and its children from the DOM
            this._hostDomElement.parentNode.removeChild(this._hostDomElement);
        }
    }

    /**
     * @method
     * @description
     * Gets the root HTML element reference of an NG component.
     */
    _getComponentRootNode(component: ComponentRef<any>): HTMLElement {
        return (component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    }
}
