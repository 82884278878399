import { Injectable } from '@angular/core';
import * as c3 from 'c3';

/**
 * @description
 * An abstraction over c3 for drawing charts across the app.
 *
 * @export
 * ChartsRendererService
 */
@Injectable()
export class ChartsRendererService {
    /**
     * @description
     * Draws and renders the chart given the specified configuration.
     *
     * @param  chartConfiguration configuration accepted by c3
     * @returns chart c3 istance
     */
    public draw(chartConfiguration: c3.ChartConfiguration): c3.ChartAPI {
        return c3.generate(chartConfiguration);
    }
}
