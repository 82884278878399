import { InjectionToken } from '@angular/core';
import { CONTINENT } from '../models/continent.model';

export const KEY_HELPERS_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IKeyHelpersService');

export type RegionsMap = {
	westus: string;
	eastus: string;
	westus2: string;
	eastus2: string;
	eastasia: string;
	centralus: string;
	japaneast: string;
	japanwest: string;
	brazilsouth: string;
	centralindia: string;
	koreacentral: string;
	southeastasia: string;
	westcentralus: string;
	canadacentral: string;
	southcentralus: string;
	northcentralus: string;
};

/**
 * @description
 * Represents an interface for a helpers service for keys. It provides mapping
 * between regions, continents, and the current user domain.
 */
export interface IKeyHelpersService {
	getDomainInfo(): { region: string; continent: CONTINENT; timeZone: number };

	getContinentRegions(): Map<CONTINENT, string[]>;

	getReadableRegionNameMap(): RegionsMap;
}
