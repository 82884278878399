import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiErrorHandler } from '../models/api-error-handler.model';
import { EndpointErrorHandler } from '../models/endpoint-error-handler.model';
import { ErrorPipeline } from '../models/error-pipeline.model';
import { GenericErrorHandler } from '../models/generic-error-handler.model';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private _pipeline: ErrorPipeline;

	constructor() {
		this._initState();
	}

	/**
	 * @description
	 * Tries handling the error received by one of the handlers in the chain.
	 *
	 * @param req The request that was being served.
	 * @param next The next http interceptor to be called.
	 */
	public intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
		return next.handle(req).pipe(catchError((error: HttpErrorResponse) => throwError(this._pipeline.handle(error))));
	}

	/**
	 * @description
	 * Initializes the interceptor.
	 */
	private _initState(): void {
		this._pipeline = new ErrorPipeline();

		this._pipeline.add(new ApiErrorHandler());
		this._pipeline.add(new EndpointErrorHandler());
		this._pipeline.add(new GenericErrorHandler());
	}
}
