import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Entity } from '@luis/entities';
import { AxisData } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { filter, flatMap, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ITrainingResultService, TRAINING_RESULT_SERVICE_TOKEN } from '../../interfaces/ITrainingResultService';
import { FILTER_OPTIONS } from '../../models/filter.model';
import { IntentMetadata } from '../../models/intent-metadata.model';
import { FaultyIntent, FilteredIntentInfo, TrainingResultMetaData } from '../../models/training-result-metadata.model';
import { ANALYTICS_UI_STATE_SERVICE_TOKEN, AnalyticsUIStatusService, CARDS } from '../../services/analytics-ui-status.service';

@Component({
	selector: 'faulty-intents',
	templateUrl: 'faulty-intents.component.html',
	styleUrls: ['faulty-intents.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaultyIntentsComponent implements OnInit {
	@Output() public manageEntity: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() public intentClicked: EventEmitter<string> = new EventEmitter<string>();
	@Output() public filteredIntent: EventEmitter<FilteredIntentInfo> = new EventEmitter<FilteredIntentInfo>();

	public filterOps: any = FILTER_OPTIONS;
	public overallDonutPadding: any = {
		top: -95,
		bottom: 0
	};
	public incorrectDonutPadding: any = {
		top: -125,
		bottom: 0
	};
	public unclearDonutPadding: any = {
		top: -125,
		bottom: 0
	};

	public mean: Observable<number>;
	public chartTitle: Observable<string>;
	public isMerging: Observable<boolean>;
	public isSpliting: Observable<boolean>;
	public addPattern: Observable<boolean>;
	public xOverallData: Observable<AxisData>;
	public yOverallData: Observable<AxisData>;
	public items: Observable<IntentMetadata[]>;
	public xIncorrectData: Observable<AxisData>;
	public yIncorrectData: Observable<AxisData>;
	public xAmbiguousData: Observable<AxisData>;
	public yAmbiguousData: Observable<AxisData>;
	public isDangerBalance: Observable<boolean>;
	public fewestIntent: Observable<IntentMetadata>;
	public mostGiantIntent: Observable<IntentMetadata>;
	public incorrectIntents: Observable<FaultyIntent[]>;
	public ambiguousIntents: Observable<FaultyIntent[]>;

	public percentage: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public selectedIntentIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public selectedIntent: BehaviorSubject<IntentMetadata> = new BehaviorSubject<IntentMetadata>(null);

	private _selectedIntentObs: Observable<IntentMetadata>;
	private _trainingResult: Observable<TrainingResultMetaData>;
	private _lowUtterancesIntents: Observable<IntentMetadata[]>;
	private _highUtterancesIntents: Observable<IntentMetadata[]>;

	constructor(
		private readonly _i18n: TranslateService,
		@Inject(TRAINING_RESULT_SERVICE_TOKEN) private readonly _trainingResultService: ITrainingResultService,
		@Inject(ANALYTICS_UI_STATE_SERVICE_TOKEN) public readonly analyticsUIStateService: AnalyticsUIStatusService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public filteredIntentClicked(intentId: string, evaluation: number, nearestRivalId?: string): void {
		this.analyticsUIStateService.lastUsedCard = CARDS.INTENT_DETAILED_VIEW;
		this.filteredIntent.emit({
			selectedIntentId: intentId,
			filters: {
				evaluation: {
					correct: evaluation === 0,
					incorrect: evaluation === 1,
					unclear: evaluation === 2
				},
				nearestRivalIntentsIds: nearestRivalId ? [nearestRivalId] : [],
				applied: true
			}
		});
	}

	public selectIntent(index: number, intent: IntentMetadata): void {
		this.analyticsUIStateService.selectedIndex = index;
		this.selectedIntent.next(intent);
	}

	public get isLowImbalncedIntent(): Observable<boolean> {
		return this._lowUtterancesIntents.pipe(
			map(intents => intents.reduce((acc, val) => acc || this.selectedIntent.getValue().intentId === val.intentId, false))
		);
	}

	public get isHighImbalncedIntent(): Observable<boolean> {
		return this._highUtterancesIntents.pipe(
			map(intents => intents.reduce((acc, val) => acc || this.selectedIntent.getValue().intentId === val.intentId, false))
		);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._trainingResult = this._trainingResultService.getMetaData().pipe(shareReplay());
		this._lowUtterancesIntents = this._trainingResultService.getIntentsWithLowNumOfUtterances().pipe(shareReplay());
		this._highUtterancesIntents = this._trainingResultService.getIntentsWithHighNumOfUtterances().pipe(shareReplay());
		this._selectedIntentObs = this.selectedIntent.pipe(
			filter(intent => intent !== undefined && intent !== null),
			shareReplay()
		);

		this._initCharts();

		this._getDimmingRules();

		// Get the intents in the menu of the side bar.
		this.items = Observable.combineLatest(
			this._trainingResult,
			this.analyticsUIStateService.appliedFilter,
			this.percentage,
			this._lowUtterancesIntents,
			this._trainingResultService.getAmbiguousIntents(),
			this._trainingResultService.getIncorrectIntents(),
			this._highUtterancesIntents
		).pipe(
			map(
				([tR, opt, perc, lowUtterances, ambiguous, incorrect, highUtterances]: [
					TrainingResultMetaData,
					number,
					string,
					IntentMetadata[],
					IntentMetadata[],
					IntentMetadata[],
					IntentMetadata[]
				]) => {
					let res: IntentMetadata[];
					if (opt === this.filterOps.NONE) {
						this.analyticsUIStateService.filterRatio = '—';

						res = incorrect
							.slice(0, 3)
							.concat(ambiguous.slice(0, 3))
							.concat(lowUtterances.slice(0, 3))
							.concat(highUtterances.slice(0, 3))
							.filter((item, i, arr) => arr.map(a => a.intentId).indexOf(item.intentId) === i)
							.sort((a, b) => (a.intentName < b.intentName ? -1 : 1));
					} else {
						if (this.analyticsUIStateService.filterRatio === '—') {
							switch (opt) {
								case this.filterOps.CORRECT:
									this.analyticsUIStateService.filterRatio = '60';
									break;
								case this.filterOps.INCORRECT:
								case this.filterOps.AMBIGUOUS:
									this.analyticsUIStateService.filterRatio = '15';
								default:
							}
						}
						this.analyticsUIStateService.filterRatio =
							this.analyticsUIStateService.filterRatio.trim().length === 0
								? '0'
								: this.analyticsUIStateService.filterRatio.trim();
						res = Array.from(tR.utterancesPerIntent.values())
							.filter(item => this._filterIntent(opt, item))
							.sort((a, b) => {
								switch (opt) {
									case this.filterOps.CORRECT:
										return a.correctAccuracy < b.correctAccuracy ? -1 : 1;
									case this.filterOps.INCORRECT:
										return a.incorrectAccuracy > b.incorrectAccuracy ? -1 : 1;
									case this.filterOps.AMBIGUOUS:
										return a.unclearAccuracy > b.unclearAccuracy ? -1 : 1;
									default:
								}
							});
					}
					const intent = res[this.analyticsUIStateService.selectedIntentIndex.getValue()];
					this.selectedIntent.next(intent);

					return res;
				}
			)
		);

		this.chartTitle = this.selectedIntent.asObservable().pipe(
			filter(intent => intent !== null && intent !== undefined),
			map(
				intent =>
					`${
						intent.correctAccuracy === 100 ? intent.correctAccuracy : intent.correctAccuracy.toFixed(1)
					}%<tspan y='5mm' x='0.1mm'style='font-size: 13px; line-height: 20px;'>${this._i18n.instant(
						'training.faulty-intents.donut_title'
					)}</tspan>`
			)
		);
	}

	private _filterIntent(option: number, item: IntentMetadata): boolean {
		const value = parseInt(this.analyticsUIStateService.filterRatio, 10);

		return (
			(option === this.filterOps.CORRECT && this.analyticsUIStateService.filterRatio.trim() !== '' && item.correctAccuracy < value) ||
			(option === this.filterOps.INCORRECT &&
				this.analyticsUIStateService.filterRatio.trim() !== '' &&
				item.incorrectAccuracy > value) ||
			(option === this.filterOps.AMBIGUOUS && this.analyticsUIStateService.filterRatio.trim() !== '' && item.unclearAccuracy > value)
		);
	}

	private _getDimmingRules(): void {
		this.isDangerBalance = Observable.combineLatest(
			this._selectedIntentObs,
			this._lowUtterancesIntents,
			this._highUtterancesIntents
		).map(([intent, lowIntents, highIntents]) => lowIntents.concat(highIntents).filter(i => i.intentId === intent.intentId).length > 0);

		this.isMerging = Observable.combineLatest(this._selectedIntentObs, this.incorrectIntents).pipe(
			map(([intent, intents]) =>
				intents
					.filter(i => i.id !== intent.intentId)
					.reduce((acc, value) => {
						const ratio = value.utterancesCount / intent.numUtterances;

						return acc || ratio > 0.4;
					}, false)
			)
		);

		this.isSpliting = Observable.combineLatest(this.incorrectIntents, this.ambiguousIntents).pipe(
			map(([incorrect, unclear]) => {
				const intents = new Map<string, FaultyIntent>();
				incorrect.forEach(i => intents.set(i.id, i));
				unclear.forEach(i => {
					if (intents.has(i.id)) {
						intents.set(i.id, {
							id: i.id,
							name: i.name,
							utterancesCount: i.utterancesCount + intents.get(i.id).utterancesCount
						});
					} else {
						intents.set(i.id, i);
					}
				});
				const intentsArr = Array.from(intents.entries()).sort((a, b) => (a[1].utterancesCount < b[1].utterancesCount ? 1 : -1));

				return intentsArr.length > 0 ? intentsArr[0][1] : null;
			}),
			filter(intent => intent !== null),
			flatMap(giantIntent =>
				Observable.combineLatest(
					Observable.of(giantIntent),
					this._trainingResultService.getAmbiguousWith(giantIntent.id),
					this._trainingResultService.getIncorrectWith(giantIntent.id),
					this._trainingResult
				)
			),
			map(([giantIntent, incorrect, unclear, tR]) => {
				const intents = new Map<string, FaultyIntent>();
				incorrect.forEach(i => intents.set(i.id, i));
				unclear.forEach(i => {
					if (intents.has(i.id)) {
						intents.set(i.id, {
							id: i.id,
							name: i.name,
							utterancesCount: i.utterancesCount + intents.get(i.id).utterancesCount
						});
					} else {
						intents.set(i.id, i);
					}
				});
				const _selectedIntent = intents.get(this.selectedIntent.getValue().intentId);

				return (
					_selectedIntent &&
					this.selectedIntent.getValue() &&
					(giantIntent.utterancesCount * _selectedIntent.utterancesCount) /
						(this.selectedIntent.getValue().numUtterances * tR.utterancesPerIntent.get(giantIntent.name).numUtterances) >
						0.05
				);
			})
		);

		this.addPattern = Observable.combineLatest(this.incorrectIntents, this.ambiguousIntents, this._trainingResult).pipe(
			map(([incorrect, unclear, tR]) => {
				const intents = new Map<string, FaultyIntent>();
				incorrect.forEach(i => intents.set(i.id, i));
				unclear.forEach(i => {
					if (intents.has(i.id)) {
						intents.set(i.id, {
							id: i.id,
							name: i.name,
							utterancesCount: i.utterancesCount + intents.get(i.id).utterancesCount
						});
					} else {
						intents.set(i.id, i);
					}
				});
				const threshold = tR.numIntents > 3 ? Math.min(1, 1 / Math.log10(tR.numIntents - 1)) : 1;

				return intents.size / (tR.numIntents - 1) > threshold * 0.2;
			})
		);
	}

	private _initCharts(): void {
		// Data of overall chart.
		this.xOverallData = Observable.of(new AxisData('', ['Correctly predicted', 'Unclear', 'Incorrectly predicted'], false));

		this.yOverallData = this._selectedIntentObs.map(
			intent => new AxisData('Utterances numbers', [intent.correctAccuracy, intent.unclearAccuracy, intent.incorrectAccuracy], false)
		);

		// Data of data impalance chart.
		const intents = this._trainingResultService.getIntentsMetaData();
		this.mean = this._trainingResult.map(tR => tR.numUtterances / tR.numIntents);
		this.mostGiantIntent = intents.map(intentsArr => intentsArr[intentsArr.length - 1]);
		this.fewestIntent = intents.map(intentsArr => intentsArr[0]);

		this._initIncorrectChart();
		this._initUnclearChart();
	}

	private _initUnclearChart(): void {
		// Data of ambiguous predictions chart.
		this.ambiguousIntents = this._selectedIntentObs.flatMap(intent => this._trainingResultService.getAmbiguousWith(intent.intentId));
		this.xAmbiguousData = this.ambiguousIntents
			.filter(intents => intents.length > 0)
			.map(() => new AxisData('', ['Correct', 'TopConflictingUnclearIntent', 'SecondUnclearIntent', 'Other', 'Incorrect'], false));

		this.yAmbiguousData = this.ambiguousIntents
			.filter(intents => intents.length > 0)
			.map(intents => new AxisData('', this._getUnclearDonutData(intents), false));
	}

	private _getUnclearDonutData(intents: FaultyIntent[]): number[] {
		return [
			// Number of correct utterances of the selected intent.
			this.selectedIntent.getValue().numCorrectUtterances,
			// Number of unclear utterances between the selected intent and the top conflicting intent.
			intents[0].utterancesCount,
			// Number of unclear utterances between the selected intent and the second conflicting intent.
			intents.length > 1 ? intents[1].utterancesCount : 0,
			// Number of unclear utterances between the selected intent and the other conflicting intents.
			this.selectedIntent.getValue().numUnclearUtterances -
				intents[0].utterancesCount -
				(intents.length > 1 ? intents[1].utterancesCount : 0),
			// Number of incorrect utterances of the selected intent.
			this.selectedIntent.getValue().numIncorrectUtterances
		];
	}

	private _initIncorrectChart(): void {
		// Data of incorrect predictions chart.
		this.incorrectIntents = this._selectedIntentObs.flatMap(intent => this._trainingResultService.getIncorrectWith(intent.intentId));
		this.xIncorrectData = this.incorrectIntents
			.filter(intents => intents.length > 0)
			.map(() => new AxisData('', ['Correct', 'unclear', 'TopConflictingIncorrectIntent', 'SecondIncorrectIntent', 'Other'], false));

		this.yIncorrectData = this.incorrectIntents
			.filter(intents => intents.length > 0)
			.map(intents => new AxisData('', this._getIncorrectDonutData(intents), false));
	}

	private _getIncorrectDonutData(intents: FaultyIntent[]): number[] {
		return [
			// Number of correct utterances of the selected intent.
			this.selectedIntent.getValue().numCorrectUtterances,
			// Number of unclear utterances of the selected intent.
			this.selectedIntent.getValue().numUnclearUtterances,
			// Number of incorrect utterances between the selected intent and the top conflicting intent.
			intents[0].utterancesCount,
			// Number of incorrect utterances between the selected intent and the second conflicting intent.
			intents.length > 1 ? intents[1].utterancesCount : 0,
			// Number of incorrect utterances between the selected intent and the other conflicting intents.
			this.selectedIntent.getValue().numIncorrectUtterances -
				intents[0].utterancesCount -
				(intents.length > 1 ? intents[1].utterancesCount : 0)
		];
	}
}
