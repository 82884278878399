/**
 * @dynamic
 * @description
 * Represents a LUIS prebuilt domain object.
 *
 * @param name The name of the prebuilt domain.
 * @param culture The culture of the prebuilt domain.
 * @param description The description of the prebuilt domain.
 * @param examples Some examples of the utterances in the prebuilt domain.
 * @param intents The intents in the prebuilt domain.
 * @param entities The entities in the prebuilt domain.
 */
export class Domain {
    public name: string;
    public culture: string;
    public description: string;
    public examples: string;
    public intents: DomainModel[];
    public entities: DomainModel[];

    constructor(
        name: string, culture: string,
        description: string, examples: string,
        intents: DomainModel[], entities: DomainModel[]
    ) {
        this.name = name;
        this.culture = culture;
        this.description = description;
        this.examples = examples;
        this.intents = intents;
        this.entities = entities;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): Domain {
        return new Domain(
            apiObject.name,
            apiObject.culture,
            apiObject.description,
            apiObject.examples,
            (<any[]>apiObject.intents).map(i => DomainModel.importFromApi(i, apiObject.name)),
            (<any[]>apiObject.entities).map(e => DomainModel.importFromApi(e, apiObject.name))
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            domainName: this.name,
            culture: this.culture
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): Domain {
        return new Domain(
            this.name.slice(),
            this.culture.slice(),
            this.description.slice(),
            this.examples.slice(),
            this.intents.map(i => i.clone()),
            this.entities.map(e => e.clone())
        );
    }
}

/**
 * @description
 *
 * @param id
 * @param domainName
 * @param name
 * @param description
 * @param examples
 */
export class DomainModel {
    public id: number;
    public domainName: string;
    public name: string;
    public description: string;
    public examples: string;

    constructor(
        id: number, name: string,
        domainName: string, description: string,
        examples: string
    ) {
        this.id = id;
        this.name = name;
        this.domainName = domainName;
        this.description = description;
        this.examples = examples;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any, domainName: string): DomainModel {
        return new DomainModel(
            apiObject.id,
            apiObject.name,
            domainName,
            apiObject.description,
            apiObject.examples
        );
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): DomainModel {
        return new DomainModel(
            this.id,
            this.name.slice(),
            this.domainName.slice(),
            this.description.slice(),
            this.examples.slice()
        );
    }

    /**
     * @description
     * Returns the unique name of the model.
     *
     * @return the unique name.
     */
    public get uniqueName(): string {
        return `${this.domainName}.${this.name}`;
    }
}
