﻿import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';

/**
 * @description
 * Represents a mediator service between the chart and the batch
 * testing components responsible for viewing utterances in
 * tabular format.
 */
@Injectable()
export class DatasetResultsGraphingService {
    private _pointClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _selectedIds: number[] = [];
    private _selectedXValue: number = Infinity;
    private _selectedIdsStream: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

    /**
     * @method
     * @description
     * Gets the stream of notifications of when the user
     * clicked on a graph point.
     */
    public getPointClickedStream(): Observable<boolean> {
        return this._pointClickedSubject.asObservable();
    }

    /**
     * @method
     * @description
     * Adds the given id in the selected utterances stream or clears the array
     * if the id was the only was added before.
     *
     * @param id The utterance id to add to the selected utterances.
     */
    public onGraphPointClicked(id: number, xValue: number): void {
        if (this._selectedIds.length === 1 && this._selectedIds[0] === id && this._selectedXValue === xValue) {
            this._selectedIds = [];
            this._selectedXValue = Infinity;
            this._pointClickedSubject.next(false);
        }
        else {
            this._selectedIds = [id];
            this._selectedXValue = xValue;
            this._pointClickedSubject.next(true);
        }

        this._selectedIdsStream.next(this._selectedIds);
    }

    /**
     * @method
     * @description
     * Sets the given ids as the utterances to display.
     *
     * @param ids The utterance ids to set.
     */
    public setIds(ids: number[]): void {
        this._selectedIds = ids.slice();
        this._selectedIdsStream.next(this._selectedIds);
        this._pointClickedSubject.next(false);
    }

    /**
     * @method
     * @description
     * Clears the utterances to show.
     */
    public reset(): void {
        this._selectedIds = [];
        this._selectedIdsStream.next(this._selectedIds);
    }

    /**
     * @method
     * @description
     * Gets the utterances to show stream as an observable.
     *
     * @returns An observable of the ids of the
     * utterances to show.
     */
    public get(): Observable<number[]> {
        return this._selectedIdsStream.asObservable();
    }
}
