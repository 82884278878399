import { NgModule, Provider } from '@angular/core';
import { EntitiesModule } from '@luis/entities';
import { FilterBladeModule } from './components/filter-blade/filter-blade.module';
import { LabelableUtteranceModule } from './components/labelable-utterance/labelable-utterance.module';
import { TablesModule } from './components/tables/tables.module';
import { UTTERANCE_CACHE_SERVICE_TOKEN } from './interfaces/services/IUtteranceCacheService';
import { UTTERANCE_SERVICE_TOKEN } from './interfaces/services/IUtteranceService';
import { PredictionCacheService } from './services/prediction-cache.service';
import { SegmentsExtractionService } from './services/segments-extraction.service';
import { SuggestUtterancesService } from './services/suggest-utterances.service';
import { UtteranceCacheService } from './services/utterance-cache.service';
import { UtteranceChunkingService } from './services/utterance-chunking.service';
import { UtteranceService } from './services/utterance.service';
import { UtterancesCountService } from './services/utterances-count.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
	imports: [SharedModule, TablesModule, LabelableUtteranceModule, EntitiesModule, FilterBladeModule],
	exports: [TablesModule, LabelableUtteranceModule, FilterBladeModule],
	declarations: [],
	providers: [SegmentsExtractionService],
	entryComponents: []
})
export class UtterancesModule {
	public static providersForChild(): Provider[] {
		return [
			SuggestUtterancesService,
			{
				provide: UTTERANCE_CACHE_SERVICE_TOKEN,
				useClass: UtteranceCacheService
			},
			{
				provide: UTTERANCE_SERVICE_TOKEN,
				useClass: UtteranceService
			},
			PredictionCacheService,
			UtteranceChunkingService,
			UtterancesCountService
		];
	}
}
