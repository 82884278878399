import { InjectionToken } from '@angular/core';

export const ENVIRONMENT_TOKEN: InjectionToken<string> = new InjectionToken('IEnvironment');

/**
 * @description
 * The environments supported.
 */
export enum ENVIRONMENT {
    STAGING,
    CENTRALUS,
    WESTEUROPE,
    AUSTRALIAEAST,
    USGOVVIRGINIA,
    CHINAEAST2
}

/**
 * @description
 * Represents an interface for environment configurations.
 */
export interface IEnvironment {
	env: ENVIRONMENT;

	production: boolean;

	directApiUrl: string;

	directWebApiUrl: string;

	apiUrl: string;

	webApiUrl: string;

	blobStoreUrl: string;
}
