import { Inject, Injectable } from '@angular/core';
import { BaseService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IAppSettingsService } from '../interfaces/IAppSettingsService';
import { AppSettings } from '../models/app-settings.model';

/**
 * @description
 * Represents a concrete implementation of the IAppSettingsService interface for app
 * settings related operations for a LUIS app. This implementation is meant for production use.
 */
@Injectable()
export class AppSettingsService implements IAppSettingsService {
	constructor(private _baseService: BaseService, @Inject(GENERIC_CACHE_SERVICE_TOKEN) private _cacheService: IGenericCacheService) {}

	/**
	 * @method
	 * @description
	 * Gets the base web url for manipulating app settings.
	 *
	 * @returns The base url for app settings manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/settings`;
	}

	/**
	 * @method
	 * @description
	 * Gets the app settings.
	 *
	 * @returns An observable of the app settings.
	 */
	public get(): Observable<AppSettings> {
		const url: string = this._baseUrl;

		return this._cacheService.get(url, AppSettings.importFromApi, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @method
	 * @description
	 * Updates the app settings.
	 *
	 * @param settings The settings to update.
	 * @returns An observable to indicate.
	 */
	public update(settings: AppSettings): Observable<void> {
		const url: string = this._baseUrl;

		return this._cacheService.put(url, settings, this._baseService.defaultOptionsBuilder.skipMarkDirty().build());
	}
}
