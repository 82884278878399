import { HttpErrorResponse } from '@angular/common/http';

/**
 * @description
 * An abstract class for any error handler to be used
 * with the http request pipeline.
 */
export abstract class ErrorHandler {
	protected _next: ErrorHandler;

	/**
	 * @description
	 * Handles the error response and creates a standardized
	 * error object for the website to use.
	 *
	 * @param error The error response message received.
	 * @returns A formatted Error object.
	 */
	public abstract handle(error: HttpErrorResponse): Error;

	/**
	 * @description
	 * Sets the given handler to the next handler in the chain of
	 * responsibility to fire if THIS handler can't handle the error.
	 *
	 * @param handler The handler to set as the next handler to fire.
	 */
	public setNext(handler: ErrorHandler): void {
		this._next = handler;
	}

	/**
	 * @description
	 * Delegates the handling of the error to the next handler in the
	 * chain if it exists and throws an error if no other handler
	 * exists in the chain.
	 *
	 * @param err The error to handle.
	 * @returns A formatted Error object.
	 */
	protected _delegate(err: HttpErrorResponse): Error {
		if (!this._next) {
			throw new Error('Could not handle error object.');
		}

		return this._next.handle(err);
	}
}
