import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpMethod, IHttpService } from '../interfaces/IHttpService';
import { IRequestOptions } from '../interfaces/IRequestOptions';

/**
 * @description
 * Represents a concrete implementation for the generic html service interface.
 */
@Injectable()
export class HttpService implements IHttpService {
	constructor(private readonly _http: HttpClient) {}

	/**
	 * @description
	 * Issues a GET call.
	 *
	 * @param url The url to get.
	 * @param options The options to use.
	 */
	public get<T>(url: string, options: IRequestOptions = {}, verbose: boolean = false): Observable<T | HttpResponse<T>> {
		if (!verbose) {
			return this._http.get<T>(url, options);
		} else {
			return this._http.get<T>(url, { ...options, observe: 'response' });
		}
	}

	/**
	 * @description
	 * Issues a POST call.
	 *
	 * @param url The url to post to.
	 * @param body The data to send.
	 * @param options The options to use.
	 */
	public post<T>(url: string, body: Object, options: IRequestOptions = {}, verbose: boolean = false): Observable<T | HttpResponse<T>> {
		if (!verbose) {
			return this._http.post<T>(url, body, options);
		} else {
			return this._http.post<T>(url, body, { ...options, observe: 'response' });
		}
	}

	/**
	 * @description
	 * Issues a PUT call.
	 *
	 * @param url The url to put to.
	 * @param body The data to send.
	 * @param options The options to use.
	 */
	public put<T>(url: string, body: Object, options: IRequestOptions = {}): Observable<T> {
		return this._http.put<T>(url, body, options);
	}

	/**
	 * @description
	 * Issues a POST call.
	 *
	 * @param url The url to post to.
	 * @param body The data to send.
	 * @param options The options to use.
	 */
	public delete<T>(url: string, options: IRequestOptions = {}): Observable<T> {
		return this._http.delete<T>(url, options);
	}

	/**
	 * @description
	 * Issues a GET call to download a file
	 *
	 * @param url The url to get.
	 * @param options The options to use.
	 */
	public download(url: string, options: IRequestOptions = {}): Observable<HttpEvent<Blob>> {
		return this._http.get(url, {
			...options,
			observe: 'events',
			responseType: 'blob',
			reportProgress: true
		});
	}

	/**
	 * @description
	 * Issues a POST call to upload a file
	 *
	 * @param url The url to post to.
	 * @param body The data to send.
	 * @param options The options to use.
	 */
	public upload<T>(url: string, body: Object, options: IRequestOptions = {}): Observable<HttpEvent<T>> {
		return this._http.post<T>(url, body, {
			...options,
			observe: 'events',
			reportProgress: true
		});
	}

	/**
	 * @description
	 * Issues a custom call with a generic http request.
	 *
	 * @param method The method to use.
	 * @param url The url to post to.
	 * @param body The data to send.
	 * @param options The options to use.
	 */
	public request<T>(method: HttpMethod, url: string, body?: Object, options: IRequestOptions = {}): Observable<T> {
		return this._http.request<T>(method, url, { body, ...options });
	}
}
