﻿import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, UserService } from '@luis/auth';
import { Observable } from 'rxjs/Rx';
import { AppRouteService } from '../../common/services/app-route.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private readonly _router: Router,
		private readonly _routeService: AppRouteService,
		private readonly _authService: AuthService,
		private readonly _userService: UserService
	) {}

	/**
	 * @description
	 * Checks if the rouer should go through with the navigation command or not.
	 *
	 * @param futureState The future state of the url router.
	 */
	public canActivate(_: any, futureState: RouterStateSnapshot): Observable<boolean> {
		return this._authService
			.isBusy()
			.filter(busy => !busy)
			.flatMap(() => this._authService.isLoggedIn())
			.flatMap(status => (status ? this._handleLoggedInState(futureState.url) : this._handleLoggedOutState(futureState.url)));
	}

	/**
	 * @description
	 * Handles the logic for logged in state as follows:
	 * a. If the user navigates to the special bot framework login page, the navigation is allowed.
	 * b. If the user hasn't accepted the terms of use, they are redirected to the terms of use page.
	 * c. If the user navigates to the terms of use page while have already accepted them, they
	 * are navigated back to the applications page.
	 * d. If the user hasn't passed through the tutorial page, they are redirected to the welcome page.
	 * e. If the user navigates to the homepage, they are redirected to the applications page.
	 * f. If all above does not apply, then check if there is a URL stored in the session variable.
	 * If there is, navigate to that URL Else continue the navigation process.
	 *
	 * @param nextUrl The url to navigate to.
	 * @returns An observable of boolean where true allows the
	 * original route navigation to continue and false to cancel
	 * the original routing navigation.
	 */
	private _handleLoggedInState(nextUrl: string): Observable<boolean> {
		const homepageUrl: string = this._routeService
			.builder()
			.staticPages()
			.home()
			.build();
		const touUrl: string = this._routeService
			.builder()
			.user()
			.tou()
			.build();

		return this._userService.getAndPoll().map(user => {
			if (this._isUrlNavigatedToBotFramework(nextUrl)) {
				return true;
			}

			if (!this._authService.authInfo.tou) {
				return this._renavigate(touUrl, nextUrl);
			} else if (nextUrl === touUrl) {
				return this._renavigate(homepageUrl, nextUrl);
			}

			if (!user.tutorial) {
				return this._renavigate(
					this._routeService
						.builder()
						.staticPages()
						.welcome()
						.build(),
					nextUrl
				);
			}

			if (nextUrl === homepageUrl) {
				return this._renavigate(
					this._routeService
						.builder()
						.apps()
						.build(),
					nextUrl
				);
			}

			return this._redirectIfAvailable();
		});
	}

	/**
	 * @description
	 * Handles the logic behind redirecting the user correctly
	 * when the user is not logged in as follows:
	 * a. If the user navigates to the homepage, the navigation is allowed.
	 * b. If the user navigates to any other page that requires the user be signed in,
	 * the website saves the navigation url in a session variable, redirects to the sign in credentials page.
	 * c. If the user navigates to the special bot framework login page, the navigation is allowed.
	 *
	 * @param nextUrl The url that will be navigated to.
	 * @returns An observable of boolean where true allows the
	 * original route navigation to continue and false to cancel
	 * the original routing navigation.
	 */
	private _handleLoggedOutState(nextUrl: string): Observable<boolean> {
		if (this._routeService.doesNotRequireAuth(nextUrl) || this._isUrlNavigatedToBotFramework(nextUrl)) {
			return Observable.of(true);
		}

		this._authService.sessionRedirectUrl = nextUrl;
		this._authService.login();

		return Observable.of(false);
	}

	/**
	 * @description
	 * Checks if the url matches those of the bot framework page.
	 *
	 * @param url The url to check.
	 * @returns True if the url matches and false otherwise.
	 */
	private _isUrlNavigatedToBotFramework(url: string): boolean {
		const urlWithOutParams = url.split('?')[0];

		return (
			urlWithOutParams ===
				this._routeService
					.builder()
					.user()
					.bfLogin()
					.build() ||
			urlWithOutParams ===
				this._routeService
					.builder()
					.user()
					.bfLoginLegacy()
					.build()
		);
	}

	/**
	 * @description
	 * Redirects to the session stored url if available, and if not
	 * continues the login flow.
	 */
	private _redirectIfAvailable(): boolean {
		const authRedirectUrl: string = this._authService.sessionRedirectUrl;
		this._authService.sessionRedirectUrl = null;

		if (authRedirectUrl) {
			this._router.navigateByUrl(authRedirectUrl);

			return false;
		}

		return true;
	}

	/**
	 * @description
	 * Navigates to the url given. If the url given was already
	 * the page that was open, then the navigation is canceled.
	 *
	 * @param url The url to navigate to.
	 * @returns True if the navigation is to be done and false otherwise.
	 */
	private _renavigate(nextUrl: string, oldUrl: string): boolean {
		if (oldUrl === nextUrl) {
			return true;
		}

		this._router.navigate([nextUrl]);

		return false;
	}
}
