import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, IPage, IToasterService, LuisModel, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { Entity } from '@luis/entities';
import { Intent, UtteranceIntent } from '@luis/intents';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Rx';
import { IUtteranceCacheService, UTTERANCE_CACHE_SERVICE_TOKEN } from '../interfaces/services/IUtteranceCacheService';
import { IUtteranceService } from '../interfaces/services/IUtteranceService';
import { Utterance } from '../models/utterance.model';

/**
 * @description
 * A concrete implementation of the utterance service interface. This class is to be
 * used in production environments. This service layer interfaces between client logic
 * and the utterance cache service layer. It provides methods for basic CRUD operations.
 */
@Injectable()
export class UtteranceService implements IUtteranceService {
	constructor(
		private readonly _baseService: BaseService,
		private readonly _i18n: TranslateService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(UTTERANCE_CACHE_SERVICE_TOKEN) private readonly _utteranceCacheService: IUtteranceCacheService
	) {}

	private get _baseUrl(): string {
		return `apps/${this._baseService.configs.appId}/versions/${this._baseService.configs.versionId}`;
	}

	/**
	 * @description
	 * Gets the utterances for the given model name. Ensures that the skip and take parameters
	 * don't exceed the current count of utterances for the given model id. If they do, the take
	 * parameter is readjusted to accomodate.
	 *
	 * @param modelId The model id of the model to get the utterances for.
	 * @param skip The number of utterances to skip.
	 * @param take The maximum number of utterances to take.
	 * @returns An observable of the array of utterances to get.
	 */
	public get(modelId: string, pageIndex: number, getAll?: boolean): Observable<IPage<Utterance>> {
		return this._utteranceCacheService.get(this._baseUrl, modelId, pageIndex, getAll);
	}

	/**
	 * @description
	 * Adds the given utterance to the application.
	 *
	 * @param utterance The utterance to add.
	 * @returns An observable of the utterance id of the utterance added.
	 */
	public add(utterance: Utterance): Observable<number> {
		utterance.labeledEntities.forEach(e => e.tokenToCharIndeces(utterance.text, utterance.tokenizedText));

		return this._utteranceCacheService.post(this._baseUrl, 'example', utterance);
	}

	/**
	 * @description
	 * Adds the given utterance to the application synchronously.
	 *
	 * @param utterance The utterance to add.
	 * @returns An observable of the utterance id of the utterance added.
	 */
	public addSync(utterance: Utterance): void {
		const path: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/example`;
		const xhr: XMLHttpRequest = new XMLHttpRequest();
		const options = this._baseService.defaultOptionsBuilder.build();

		utterance.labeledEntities.forEach(e => e.tokenToCharIndeces(utterance.text, utterance.tokenizedText));
		xhr.open('POST', path, false);
		options.headers.keys().forEach(key => xhr.setRequestHeader(key, options.headers.get(key)));
		xhr.send(JSON.stringify(utterance.exportToApi()));
	}

	/**
	 * @description
	 * Adds the given utterances to the application.
	 *
	 * @param utterances The utterances to add.
	 * @returns An observable of the ids added.
	 */
	public batchAdd(utterances: Utterance[], type?: 'intent' | 'entity'): Observable<number[]> {
		utterances.forEach(u => u.labeledEntities.forEach(e => e.tokenToCharIndeces(u.text, u.tokenizedText)));

		return this._utteranceCacheService.batchPost(this._baseUrl, 'examples', utterances, undefined, undefined, type);
	}

	/**
	 * @description
	 * Replaces the utterance in the cache with the new utterance given. Positions
	 * the new utterance in the same ordered position as the old utterance. Deletes the
	 * old utterance from all the caches.
	 *
	 * @param utterance The new utterance to add.
	 * @param idToReplace The id of the utterance to replace.
	 * @returns An observable to indicate completion.
	 */
	public replace(utterance: Utterance, idToReplace: number): Observable<void> {
		utterance.labeledEntities.forEach(e => e.tokenToCharIndeces(utterance.text, utterance.tokenizedText));

		return this._utteranceCacheService
			.replace(this._baseUrl, 'example', utterance, idToReplace)
			.flatMap(id => this._utteranceCacheService.delete(this._baseUrl, 'examples', idToReplace));
	}

	/**
	 * @description
	 * Deletes the given utterance from the application.
	 *
	 * @param utteranceId The id of the utterance to delete.
	 * @returns An observable to indicate completion.
	 */
	public delete(utteranceId: number): Observable<void> {
		return this._utteranceCacheService.delete(this._baseUrl, 'examples', utteranceId);
	}

	/**
	 * @description
	 * Deletes the utterances with the given utterance ids from the application.
	 *
	 * @param utteranceIds The utterance ids to delete.
	 * @returns An observable of the ids deleted.
	 */
	public batchDelete(utteranceIds: number[]): Observable<number[]> {
		return this._utteranceCacheService.batchDelete(this._baseUrl, 'examples', utteranceIds);
	}

	/**
	 * @description
	 * Gets the suggestions for the given model.
	 *
	 * @param model The model to suggest utterances for.
	 * @returns An observable of the suggested utterances.
	 */
	public suggest(model: LuisModel, roleId?: string): Observable<Utterance[]> {
		const modelType: string = model instanceof Intent ? 'intents' : 'entities';
		const rolesPathInfix = roleId ? `roles/${roleId}/` : ''
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/${modelType}/${model.id}/${rolesPathInfix}suggest?multiple-intents=true`;

		return this._httpService
			.get<Object[]>(url, this._baseService.defaultOptionsBuilder.build())
			.map(data => data.map(d => Utterance.importFromApi(d)));
	}

	/**
	 * @description
	 * Deletes the given suggested utterances from the app's unlabeled data.
	 *
	 * @param utterances The utterances to delete.
	 * @returns An observable to indicate completion.
	 */
	public deleteSuggest(utterances: Utterance[]): Observable<any> {
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/suggest`;

		return Observable.combineLatest(
			utterances.map(u => {
				const options = this._baseService.defaultOptionsBuilder.build();

				return this._httpService.request('DELETE', url, JSON.stringify(u.text), options);
			})
		);
	}

	public updateTestingUtterance(utterance: Utterance, intentName: string): void {
		const u: Utterance = utterance.clone();
		const newIntent: UtteranceIntent = u.predictedIntents.find(i => i.name === intentName);
		u.labeledIntent = new UtteranceIntent(newIntent.id, newIntent.name);
		u.labeledEntities = u.predictedEntities.filter(e => Entity.isMachineLearned(e.type)).map(e => e.clone());
		this.add(u)
			.first()
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('utterances.utterance-service.add_toast') }))
			.subscribe();
	}
}
