import { IResource } from '@luis/core';
import { UtteranceEntity } from '@luis/entities';
import { UtteranceIntent } from '@luis/intents';
import { UtterancePattern } from '@luis/patterns';
import { TrainingResultUtterance } from '@luis/training';
import { UtteranceSentiment } from './utterance-sentiment.model';

/**
 * @description
 * The status of the utterance either unclear, incorrect or correct.
 */
export enum UTTERANCE_STATUS {
	CORRECT,
	UNCLEAR,
	INCORRECT
}

/**
 * @dynamic
 * @description
 * Represents a LUIS utterance resource class.
 *
 * @param id The utterance unique id.
 * @param text The utterance text.
 * @param tokenizedText The utterance tokenized text.
 * @param labeledIntent The utterance user labeled intent.
 * @param predictedIntents The utterance app intent predictions.
 * @param labeledEntities The utterance user labeled entities.
 * @param predictedEntities The utterance app entity predictions.
 */
export class Utterance implements IResource {
	constructor(
		public id: number = null,
		public text: string = '',
		public tokenizedText: string[] = [],
		public labeledIntent: UtteranceIntent = new UtteranceIntent(),
		public predictedIntents: UtteranceIntent[] = [],
		public labeledEntities: UtteranceEntity[] = [],
		public predictedEntities: UtteranceEntity[] = [],
		public predictedPatterns: UtterancePattern[] = [],
		public sentiment: UtteranceSentiment = new UtteranceSentiment(),
		public alteredText: string = '',
		public assignedDate: Date = new Date(),
		public trainingEvaluation?: TrainingResultUtterance,
		public multiIntentUtterances: Utterance[] = []
	) {}

	/**
	 * @description
	 * Gets a new uttrance id from the result of successfuly adding a new label.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns The new utterance id.
	 */
	public static importNewIdFromApi(apiObject: any): number {
		return apiObject.ExampleId;
	}

	/**
	 * @description
	 * Gets utterance ids from the result of successfully adding batch labels.
	 * If anyone of the labels fail, null is returned in its index instead.
	 *
	 * @param apiObject The array of results
	 * @returns The utterance ids of the added labels.
	 */
	public static importNewIdsFromBatchApi(apiObject: any[]): number[] {
		return apiObject.map(a => (a.hasError === true ? null : a.value.ExampleId));
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): Utterance {
		const entityPredictions = apiObject.entityPredictions
			.map(e => UtteranceEntity.importFromApi(e))
			.sort((a, b) => (a.startTokenIndex > b.endTokenIndex ? 1 : -1));

		const patternPredictions = apiObject.patternPredictions
			? apiObject.patternPredictions.map(p => UtterancePattern.importFromApi(p)).sort((a, b) => (a.order > b.order ? 1 : -1))
			: [];

		return new Utterance(
			apiObject.id,
			apiObject.text || '',
			apiObject.tokenizedText || [],
			UtteranceIntent.importFromApi({ id: apiObject.intentId, name: apiObject.intentLabel }),
			apiObject.intentPredictions.map(i => UtteranceIntent.importFromApi(i)),
			apiObject.entityLabels ? apiObject.entityLabels.map(e => UtteranceEntity.importFromApi(e)) : [],
			entityPredictions,
			patternPredictions,
			apiObject.sentimentAnalysis ? UtteranceSentiment.importFromApi(apiObject.sentimentAnalysis) : new UtteranceSentiment(),
			apiObject.alteredText || '',
			apiObject.modifiedDateTime ? new Date(apiObject.modifiedDateTime) : new Date(),
			undefined,
			apiObject.multiIntentPredictions ? apiObject.multiIntentPredictions.map(u => Utterance.importFromApi(u)) : []
		);
	}

	/**
	 * @description
	 * Constructs an utterance object from the endpoint response and the
	 * utterance tokenzied text (can be obtained from the prediction service).
	 *
	 * @param endpointObject The object received from the api.
	 * @returns A new object of this resource.
	 */
	public static importFromEndpoint(endpointObject: any): Utterance {
		const intents: UtteranceIntent[] = (<any[]>endpointObject.intents).map(i => new UtteranceIntent('', i.intent, i.score));

		const entities: UtteranceEntity[] = (<any[]>endpointObject.entities)
			.map(e => new UtteranceEntity('', e.type, null, e.role, null, null, e.startIndex, e.endIndex))
			.sort((a, b) => (a.startCharIndex > b.endCharIndex ? 1 : -1));

		const sentiment: UtteranceSentiment = endpointObject.sentimentAnalysis
			? UtteranceSentiment.importFromApi(endpointObject.sentimentAnalysis)
			: new UtteranceSentiment();

		return new Utterance(
			null,
			endpointObject.query,
			[],
			new UtteranceIntent(),
			intents,
			[],
			entities,
			[],
			sentiment,
			endpointObject.query,
			new Date(),
			undefined,
			endpointObject.multiIntents ? endpointObject.multiIntents.map(u => Utterance.importFromEndpoint(u)) : []
		);
	}

	/**
	 * @description
	 * Constructs an utterance object from the endpoint response and the
	 * utterance tokenzied text (can be obtained from the prediction service).
	 *
	 * @param endpointObject The object received from the api.
	 * @returns A new object of this resource.
	 */
	public static importFromEndpointV3(endpointObject: any): Utterance {
		const prediction = endpointObject.prediction;

		const predictedIntents = Object.keys(prediction.intents).map(name => new UtteranceIntent('', name, prediction.intents[name].score));

		const predictedEntities = prediction.entities.$instance
			? Object.keys(prediction.entities.$instance).reduce((output, name) => {
					const entityInstances: any[] = prediction.entities.$instance[name];
					const utteranceEntities = entityInstances.map(entity => UtteranceEntity.importFromEndpointV3(entity));

					return output.concat(utteranceEntities);
			  }, [])
			: [];

		const predictedSentiment: UtteranceSentiment = prediction.sentiment
			? UtteranceSentiment.importFromApi(prediction.sentiment)
			: new UtteranceSentiment();

		return new Utterance(
			undefined,
			prediction.normalizedQuery,
			[],
			new UtteranceIntent(),
			predictedIntents,
			[],
			predictedEntities,
			[],
			predictedSentiment,
			prediction.normalizedQuery,
			prediction.intents.MultipleIntents
				? prediction.intents.MultipleIntents.predictions.map(s => Utterance.importFromEndpointV3({ prediction: s }))
				: []
		);
	}

	/**
	 * @description
	 * Checks if the given utterance is identical to this utterance object.
	 *
	 * @param u The utterance to compare with this utterance.
	 * @returns True if the two utterances are identical and false otherwise.
	 */
	public isEqual(u: Utterance): boolean {
		if (u === undefined) {
			return false;
		}
		if (this.id !== u.id) {
			return false;
		}
		if (this.text !== u.text) {
			return false;
		}
		if (this.tokenizedText.length !== u.tokenizedText.length) {
			return false;
		}
		if (this.tokenizedText.find((t, i) => t !== u.tokenizedText[i]) !== undefined) {
			return false;
		}
		if (this.labeledIntent.name !== u.labeledIntent.name || this.labeledIntent.score !== u.labeledIntent.score) {
			return false;
		}
		if (this.predictedIntents.length !== u.predictedIntents.length) {
			return false;
		}
		if (
			this.predictedIntents.find(
				(intent, i) => intent.name !== u.predictedIntents[i].name || intent.score !== u.predictedIntents[i].score
			) !== undefined
		) {
			return false;
		}
		if (this.labeledEntities.length !== u.labeledEntities.length) {
			return false;
		}
		if (
			this.labeledEntities.find(
				(e, i) =>
					e.name !== u.labeledEntities[i].name ||
					e.startTokenIndex !== u.labeledEntities[i].startTokenIndex ||
					e.endTokenIndex !== u.labeledEntities[i].endTokenIndex
			) !== undefined
		) {
			return false;
		}
		if (this.predictedEntities.length !== u.predictedEntities.length) {
			return false;
		}
		if (
			this.predictedEntities.find(
				(e, i) =>
					e.name !== u.predictedEntities[i].name ||
					e.startTokenIndex !== u.predictedEntities[i].startTokenIndex ||
					e.endTokenIndex !== u.predictedEntities[i].endTokenIndex
			) !== undefined
		) {
			return false;
		}
		if (this.predictedPatterns.length !== u.predictedPatterns.length) {
			return false;
		}
		if (
			this.predictedPatterns.find(
				(p, i) =>
					p.intentName !== u.predictedPatterns[i].intentName ||
					p.patternName !== u.predictedPatterns[i].patternName ||
					p.order !== u.predictedPatterns[i].order
			) !== undefined
		) {
			return false;
		}
		if (this.sentiment.score !== u.sentiment.score && this.sentiment.label !== u.sentiment.label) {
			return false;
		}

		return true;
	}

	public get nearestRival(): UtteranceIntent {
		if (this.trainingEvaluation) {
			if (this.trainingEvaluation.incorrectIntents.length > 0) {
				return this.trainingEvaluation.incorrectIntents[0];
			} else if (this.trainingEvaluation.ambiguousIntents.length > 0) {
				return this.trainingEvaluation.ambiguousIntents[0];
			}
		}

		return this.predictedIntents.length > 0 ? this.predictedIntents.filter(pI => pI.id !== this.labeledIntent.id)[0] : undefined;
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): Utterance {
		return new Utterance(
			this.id,
			this.text.slice(),
			this.tokenizedText.map(t => t.slice()),
			this.labeledIntent.clone(),
			this.predictedIntents.map(i => i.clone()),
			this.labeledEntities.map(e => e.clone()),
			this.predictedEntities.map(e => e.clone()),
			this.predictedPatterns.map(p => p.clone()),
			this.sentiment.clone(),
			this.alteredText.slice(),
			new Date(this.assignedDate.getTime()),
			this.trainingEvaluation ? this.trainingEvaluation.clone() : undefined,
			this.multiIntentUtterances ? this.multiIntentUtterances.map(u => u.clone()) : undefined
		);
	}

	/**
	 * @description
	 * Converts this resource to an object that matches the web api.
	 *
	 * @returns An object that matches the web api of this resource.
	 */
	public exportToApi(): Object {
		return {
			text: this.text,
			intentName: this.labeledIntent.name,
			entityLabels: this.labeledEntities.map(e => e.exportToApi())
		};
	}
}
