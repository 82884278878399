import { InjectionToken } from '@angular/core';
import { Id, IPage, ProgressTracker } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { App } from '../models/app.model';

export const APP_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IAppService');

/**
 * @description
 * Represents an interface for an app service. An app service should contain
 * CRUD methods for LUIS apps.
 */
export interface IAppService {
    init(trigger: Observable<boolean>, chunkingTracker: ProgressTracker): void;

    get(pageIndex: number, refresh?: boolean, getAll?: boolean): Observable<IPage<App>>;

    getSingle(id?: string, refresh?: boolean): Observable<App>;

    add(app: App, refresh?: boolean): Observable<Id | void>;

    update(app: App, cacheOnly?: boolean): Observable<void>;

    delete(appId: string): Observable<void>;

    batchDelete(apps: App[]): Observable<Id[]>;
}
