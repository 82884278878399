import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { SelectionMap } from '../models/utils/selection-map.model';

@Pipe({
    name: 'isTableItemDeleteVisibleAsync'
})
export class IsTableItemDeleteVisibleAsyncPipe implements PipeTransform {
    /**
     * @description
     * Checks the toolbar options and the selected items length to enable/disable
     * the delete toolbar button.
     *
     * @param selectionMap The selection map.
     * @param data The observable data to check with selected items.
     * @returns True if visible and vice versa.
     */
    public transform(selectionMap: SelectionMap, data: Observable<any[]>): Observable<boolean> {
        return Observable.combineLatest(data, selectionMap.selectedItemsAsync, selectionMap.toolbarOps)
            .map(([allItems, selected, ops]) => selected.length === allItems.length && (ops.single || ops.batch));
    }
}
