import { Entity, ENTITY_TYPES, EntityHelpers, ParentEntity, UtteranceEntity } from '@luis/entities';
import { Utterance } from '@luis/utterances';
import { IUtteranceCompositeEntity, IUtteranceEntityWithText, IUtteranceResultsDto } from '../interfaces/IUtteranceResults';

/**
 * @description
 * Extracts the predictions for entities and composite entities for an utterance
 * and its multi intent sub predictions from the trained model prediction api.
 * Returns an array of utterance results, where the first utterance in the array
 * is the main utterance and the rest of the array are the predicted multi intent
 * sub utterances.
 *
 * @param utterance The utterance to get results for.
 * @param entities The entities of the current application.
 * @returns An array of utterance result objects where the first data point in the array
 * is the main utterance and the rest are multi intent sub predictions.
 */
export function getUtteranceTrainedPredictions(utterance: Utterance, entities: Entity[]): IUtteranceResultsDto {
	return {
		mainUtterance: {
			utterance: utterance,
			entities: _getEntitiesFromPredictionApi(utterance, entities),
			composites: _getCompositeEntitiesFromPredictionApi(utterance.predictedEntities, entities, utterance.tokenizedText)
		},
		multiIntentUtterances: utterance.multiIntentUtterances.map(u => ({
			utterance: u,
			entities: _getEntitiesFromPredictionApi(u, entities),
			composites: _getCompositeEntitiesFromPredictionApi(u.predictedEntities, entities, u.tokenizedText)
		}))
	};
}

/**
 * @description
 * Extracts the predictions for entities and composite entities for an utterance
 * and its multi intent sub predictions from the endpoint api. Returns an array
 * of utterance results, where the first utterance in the array is the main
 * utterance and the rest of the array are the predicted multi intent sub utterances.
 *
 * @param responseObject The response object received from the endpoint.
 * @returns An array of utterance result objects where the first data point in the array
 * is the main utterance and the rest are multi intent sub predictions.
 */
export function getUtteranceEndpointPredictions(responseObject: any): IUtteranceResultsDto {
	const multiIntentUtteranceResponseObject: any[] = responseObject.multiIntents || [];

	return {
		mainUtterance: {
			utterance: Utterance.importFromEndpointV3(responseObject),
			entities: _getEntitiesFromEndpointApi(responseObject),
			composites: _getCompositeEntitiesFromEndpointApi(responseObject)
		},
		multiIntentUtterances: multiIntentUtteranceResponseObject.map(subUtterance => ({
			utterance: Utterance.importFromEndpointV3(subUtterance),
			entities: _getEntitiesFromEndpointApi(subUtterance),
			composites: _getCompositeEntitiesFromEndpointApi(subUtterance)
		}))
	};
}

/**
 * @description
 * Gets the entities from the prediction api and matches them with their parent composite entities
 * by inspecting their schema from the original entities array of the app.
 *
 * So for each entity predicted in the utterance:
 * - Checks if it is a child of any of the composite entities found in the predictions for this utterance.
 * - If it was found, it is matched with that composite entity.
 * - The corresponding token text for that entity is matched with the entity name.
 *
 * @param utterance The utterance that was predicted.
 * @param entities The entities in the LUIS app.
 * @returns An array of utterance entities with their matching token text. Ex: "My name is Omar"
 * would return { entity: "firstName", text: "Omar" }.
 */
function _getEntitiesFromPredictionApi(utterance: Utterance, entities: Entity[]): IUtteranceEntityWithText[] {
	const composites = _getCompositeEntitiesFromPredictionApi(utterance.predictedEntities, entities, utterance.tokenizedText);
	const parentsAndChildren: IUtteranceEntityWithText[] = composites.reduce((a, cE) => a.concat(cE.parent, ...cE.children), []);

	return utterance.predictedEntities
		.filter(
			predictedEntity =>
				!parentsAndChildren.find(e =>
					_isSameEntity(e, {
						utteranceEntity: predictedEntity,
						text: utterance.tokenizedText.slice(predictedEntity.startTokenIndex, predictedEntity.endTokenIndex + 1).join(' ')
					})
				)
		)
		.map(uE => ({
			utteranceEntity: uE,
			text: utterance.tokenizedText.slice(uE.startTokenIndex, uE.endTokenIndex + 1).join(' ')
		}))
		.sort((a, b) => _sortUtteranceEntities(a.utteranceEntity, b.utteranceEntity, false));
}

/**
 * @description
 * Given the entities predicted, matched with the app antities, and tokenized
 * text, outputs an array of composite entities with their children, and for
 * each parent and child, the text segment it covers.
 *
 * @param utteranceEntities The predicted entities in the utterance.
 * @param entities All the entities in the application
 * @param tokens The tokenized text of the utterance.
 * @returns An array of composite entities with their text and children.
 */
function _getCompositeEntitiesFromPredictionApi(
	utteranceEntities: UtteranceEntity[],
	entities: Entity[],
	tokens: string[]
): IUtteranceCompositeEntity[] {
	const expandedEntities = EntityHelpers.expandChildren(entities);
	const inApp = utteranceEntities.filter(uE => expandedEntities.findIndex(e => e.id === uE.id) !== -1);
	const composites = inApp.filter(uE => uE.type === ENTITY_TYPES.COMPOSITE);
	const others = inApp.filter(uE => uE.type !== ENTITY_TYPES.COMPOSITE);
	const compositeEntities = <ParentEntity[]>entities.filter(e => e.type === ENTITY_TYPES.COMPOSITE);

	return composites
		.map(compositeUtteranceEntity => {
			const parentData: IUtteranceEntityWithText = {
				utteranceEntity: compositeUtteranceEntity,
				text: tokens.slice(compositeUtteranceEntity.startTokenIndex, compositeUtteranceEntity.endTokenIndex + 1).join(' ')
			};
			const utteranceCompositeEntity: IUtteranceCompositeEntity = { parent: parentData, children: [] };
			const childrenEntities = compositeEntities.find(c => c.id === compositeUtteranceEntity.id).children;

			utteranceCompositeEntity.children = others
				.filter(uE =>
					childrenEntities.find(
						c =>
							(c.id === uE.id || c.id === uE.roleId) &&
							compositeUtteranceEntity.startTokenIndex <= uE.startTokenIndex &&
							uE.endTokenIndex <= compositeUtteranceEntity.endTokenIndex
					)
				)
				.map(uE => ({
					utteranceEntity: uE,
					text: tokens.slice(uE.startTokenIndex, uE.endTokenIndex + 1).join(' ')
				}))
				.sort((a, b) => _sortUtteranceEntities(a.utteranceEntity, b.utteranceEntity, false));

			return utteranceCompositeEntity;
		})
		.sort((a, b) => _sortUtteranceEntities(a.parent.utteranceEntity, b.parent.utteranceEntity, false));
}

/**
 * @description
 * Gets the predicted entities from the endpoint api and matches them with their parent composite entities
 * by inspecting the composite entities returned from this endpoint response.
 *
 * So for each entity predicted in the utterance:
 * - Checks if it is a child of any of the composite entities found in the predictions for this utterance.
 * - If it was found, it is matched with that composite entity.
 * - The corresponding token text for that entity is matched with the entity name.
 *
 * @param endpointApiObject The response object recieved from the endpoint.
 * @returns An array of utterance entities with their matching token text. Ex: "My name is Omar"
 * would return { entity: "firstName", text: "Omar" }.
 */
function _getEntitiesFromEndpointApi(endpointApiObject: any): IUtteranceEntityWithText[] {
	const utterance = Utterance.importFromEndpointV3(endpointApiObject);
	const composites = _getCompositeEntitiesFromEndpointApi(endpointApiObject);
	const parentsAndChildren: IUtteranceEntityWithText[] = composites.reduce((a, cE) => a.concat(cE.parent, ...cE.children), []);

	return utterance.predictedEntities
		.filter(predictedEntity => !composites.find(composite => predictedEntity.name === composite.parent.utteranceEntity.name))
		.filter(
			predictedEntity =>
				!parentsAndChildren.find(e =>
					_isSameEntity(e, {
						utteranceEntity: predictedEntity,
						text: utterance.text.slice(predictedEntity.startCharIndex, predictedEntity.endCharIndex + 1)
					})
				)
		)
		.map(uE => ({
			utteranceEntity: uE,
			text: utterance.text.slice(uE.startCharIndex, uE.endCharIndex + 1)
		}))
		.sort((a, b) => _sortUtteranceEntities(a.utteranceEntity, b.utteranceEntity, true));
}

/**
 * @description
 * Gets the composite entities from the endpoint api predictions by parsing a specific field that
 * is only returned in the endpoint api.
 *
 * @param endpointApiObject The response object received from the endpoint.
 * @returns An array of composite entities predicted in the utterance along with the matched
 * text for each composite entity.
 */
function _getCompositeEntitiesFromEndpointApi(endpointApiObject: any): IUtteranceCompositeEntity[] {
	const entitiesInstances = endpointApiObject.prediction.entities.$instance;
	const compositeParents = entitiesInstances ? _getCompositeEntityParentsFromEndpointApi(entitiesInstances) : [];

	return compositeParents
		.map(parent => ({
			parent: parent,
			children: _getCompositeEntityChildrenFromEndpointReponse(
				parent,
				endpointApiObject.prediction.entities[
					parent.utteranceEntity.role ? parent.utteranceEntity.role : parent.utteranceEntity.name
				]
			)
		}))
		.sort((a, b) => _sortUtteranceEntities(a.parent.utteranceEntity, b.parent.utteranceEntity, true));
}

/**
 * @description
 * Gets the composite entity parents as utterance entities with their text
 * from the entities main object.
 *
 * @param entitiesObject The entities object.
 */
function _getCompositeEntityParentsFromEndpointApi(entitiesObject: any): IUtteranceEntityWithText[] {
	return Object.keys(entitiesObject).reduce((output, name) => {
		const entityInstances: any[] = entitiesObject[name];
		const isEntityComposite = entityInstances.some(entity => entity.modelTypeId === ENTITY_TYPES.COMPOSITE);

		if (isEntityComposite) {
			return output.concat(
				entityInstances.map(instance => ({
					utteranceEntity: UtteranceEntity.importFromEndpointV3(instance),
					text: instance.text
				}))
			);
		}

		return output;
	}, []);
}

/**
 * @description
 * Gets the children for a given composite parent entity from the
 * entities endpoint response.
 *
 * @param parentToMatch The parent that I want to get the children for.
 * @param parentsData The object of parents to search in.
 * @returns The children of the composite entity given.
 */
function _getCompositeEntityChildrenFromEndpointReponse(
	parentToMatch: IUtteranceEntityWithText,
	parentsData: any[]
): IUtteranceEntityWithText[] {
	const parentsStartIndeces = parentsData
		.map(datum => datum.$instance)
		.map(parentInstance => {
			if (!parentInstance) {
				return [];
			}

			const childNames = Object.keys(parentInstance);
			const minStartIndexForEachChild = childNames.map(childName => {
				const children: any[] = parentInstance[childName];
				const childrenStartIndeces = children.map(child => child.startIndex).sort((a, b) => (a > b ? 1 : -1));

				return childrenStartIndeces[0];
			});

			return minStartIndexForEachChild.sort((a, b) => (a > b ? 1 : -1))[0];
		});

	const indexOfMatchedParent = parentsStartIndeces.findIndex(
		index => parentToMatch.utteranceEntity.startCharIndex <= index && index <= parentToMatch.utteranceEntity.endCharIndex
	);
	const matchedComposite = parentsData[indexOfMatchedParent];

	if (!matchedComposite || !matchedComposite.$instance) {
		return [];
	}

	const matchedChildrenNames = Object.keys(matchedComposite.$instance);

	return matchedChildrenNames
		.reduce((children: IUtteranceEntityWithText[], childName) => {
			const childInstances: any[] = matchedComposite.$instance[childName];

			return children.concat(
				childInstances.map(childInstance => ({
					utteranceEntity: UtteranceEntity.importFromEndpointV3(childInstance),
					text: childInstance.text
				}))
			);
		}, [])
		.sort((a, b) => _sortUtteranceEntities(a.utteranceEntity, b.utteranceEntity, true));
}

/**
 * @description
 * Given to utterance entities, this function determines whether these entities are the same entity or not,
 * based on the entity name, entity role and indices.
 *
 * @returns True if the entities are the same and false otherwise.
 */
function _isSameEntity(a: IUtteranceEntityWithText, b: IUtteranceEntityWithText): boolean {
	return (
		a.text === b.text &&
		a.utteranceEntity.name === b.utteranceEntity.name &&
		(a.utteranceEntity.startTokenIndex === null ||
			(a.utteranceEntity.startTokenIndex === b.utteranceEntity.startTokenIndex &&
				a.utteranceEntity.endTokenIndex === b.utteranceEntity.endTokenIndex)) &&
		(a.utteranceEntity.startCharIndex === null ||
			(a.utteranceEntity.startCharIndex === b.utteranceEntity.startCharIndex &&
				a.utteranceEntity.endCharIndex === b.utteranceEntity.endCharIndex)) &&
		(b.utteranceEntity.role !== '' || (b.utteranceEntity.role === null || a.utteranceEntity.role === b.utteranceEntity.role))
	);
}

/**
 * @description
 * Sorts the utterance entities by their start indeces based on the type
 * we are using for comparison (character vs token) and if they are equal,
 * then we sort the by their names.
 *
 * @param a The first utterance entity.
 * @param b The second utterance entity.
 * @param useCharacterIndex A flag to whether use character indeces or token indeces.
 */
function _sortUtteranceEntities(a: UtteranceEntity, b: UtteranceEntity, useCharacterIndex: boolean): number {
	if (useCharacterIndex) {
		if (a.startCharIndex !== b.startCharIndex) {
			return a.startCharIndex > b.startCharIndex ? 1 : -1;
		}
	} else {
		if (a.startTokenIndex !== b.startTokenIndex) {
			return a.startTokenIndex > b.startTokenIndex ? 1 : -1;
		}
	}

	return a.name > b.name ? 1 : -1;
}
