import {
    ApplicationRef,
    ComponentFactoryResolver,
    Injectable,
    Injector,
    NgZone
} from '@angular/core';
import { OverlayContainer } from '../overlay/overlay-container';
import { OverlayRef } from '../overlay/overlay-ref';
import { OverlayState } from '../overlay/overlay-state';
import { OverlayPositionBuilder } from '../overlay/position/overlay-position-builder';
import { ScrollStrategyOptions } from '../overlay/scroll/scroll-strategy-options';
import { DomPortalHost } from '../portal/dom-portal-host';

// default state for new overlays
const defaultState = new OverlayState();

@Injectable()
export class OverlayService {

    constructor(
        public scrollStrategies: ScrollStrategyOptions,
        private _overlayContainer: OverlayContainer,
        private _posBuilder: OverlayPositionBuilder,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _appRef: ApplicationRef,
        private _injector: Injector,
        private _ngZone: NgZone
    ) { }

    /**
     * @method
     * @description
     * Creates an overlay with state.
     * @param state The state to initialize the overlay reference
     * with.
     * @return Newly created overlay reference.
     */
    create(state: OverlayState = defaultState): OverlayRef {
        return this.createOverlayRef(this._createPanelElement(), state);
    }

    /**
     * @method
     * @description
     * Creates an overlay reference using a DOM element.
     * @param panel The DOM element that will host the floating UI
     * @param state The state of the overlay referenbce.
     * @return Newly created overlay reference.
     */
    createOverlayRef(panel: HTMLElement, state: OverlayState): OverlayRef {
        const scrollStrat = state.scrollStrategy || this.scrollStrategies.noop();
        const portalHost = this._createPortalHost(panel);

        return new OverlayRef(portalHost, panel, state, scrollStrat, this._ngZone);
    }

    /**
     * @method
     * @description
     * Creates a DOM element for the overlay element
     * (div) and appends it to the overlay container.
     * @return Newly created panel DOM element.
     */
    _createPanelElement(): HTMLElement {
        const panel = document.createElement('div');
        panel.classList.add('m-overlay-panel');
        this._overlayContainer.getContainerElement().appendChild(panel);

        return panel;
    }

    /**
     * @method
     * @description
     * Creates a portal host out of a DOM element so that
     * UI can be attached to it.
     * @param panel Overlay DOM element that will be used as the portal host
     * where UI will be attached.
     * @return Newly created DOM portal host.
     */
    _createPortalHost(panel: HTMLElement): DomPortalHost {
        return new DomPortalHost(panel, this._componentFactoryResolver, this._appRef, this._injector);
    }

    /**
     * @method
     * @description
     * Returns position builder that will be used to
     * position the overlay on the screen.
     * @returns The position builder of the overlay service.
     */
    position(): OverlayPositionBuilder {
        return this._posBuilder;
    }
}
