import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from './error-handler.model';

/**
 * @description
 * Represents an error response object expected from a LUIS API.
 */
interface IApiErrorObject {
	code: string;
	message: string;
}

/**
 * @description
 * Represents a handler for error objects that are returned from
 * a LUIS API.
 */
export class ApiErrorHandler extends ErrorHandler {
	/**
	 * @description
	 * Handles the error response returned from a LUIS API.
	 *
	 * @param err The error object thrown.
	 * @returns A formatted Error object.
	 */
	public handle(err: HttpErrorResponse): Error {
		const jsonError: IApiErrorObject = err.error ? err.error.error : {};
		const errorObject: Error = new Error();

		if (typeof jsonError !== 'object') {
			return this._delegate(err);
		}

		if (!('code' in jsonError)) {
			return this._delegate(err);
		}

		if (!('message' in jsonError)) {
			return this._delegate(err);
		}

		errorObject.name = `${err.status}`;
		errorObject.message = `${jsonError.code}: ${jsonError.message}`;

		return errorObject;
	}
}
