import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TooltipComponent } from '../tooltip/tooltip.component';

/**
 * @description
 * Represents a copy icon that when clicked, copies the
 * copy text or display text (if copy text not available) to
 * the clipboard.
 */
@Component({
    selector: 'copy-icon',
    templateUrl: 'copy-icon.component.html',
    styleUrls: ['copy-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyIconComponent {
    @Input() public displayText: string;
    @Input() public copyText: string;
    @Input() public label: string;

    @ViewChild(TooltipComponent) public tooltipComponent: TooltipComponent;
    @ViewChild(TooltipComponent, { read: ElementRef }) public tooltipRef: ElementRef;
    @ViewChild('copyIcon') public copyIcon: ElementRef;

    private _copiedText: string;

    constructor(readonly _i18n: TranslateService) {
        this._copiedText = this._i18n.instant('ui.copy-icon.copied');
        this.label = this._i18n.instant('ui.copy-icon.click_to_copy');
    }

    /**
     * @description
     * Copies the text to the clipboard.
     */
    public onCopyClick(): void {
        const input: HTMLInputElement = document.createElement('input');

        input.value = this.copyText ? this.copyText : this.displayText;
        this.tooltipRef.nativeElement.appendChild(input);
        input.focus();
        input.select();
        document.execCommand('Copy');
        input.remove();
        this.copyIcon.nativeElement.focus();

        this.tooltipComponent.label = this._copiedText;
        this.tooltipComponent.onMouseEnter();
    }

    /**
     * @description
     * Resets the label on the tooltip.
     */
    public resetLabel(): void {
        this.tooltipComponent.label = this.label;
    }

    /**
     * @description
     * Mimics the effect of the mouse leaving the copy icon when it is used
     * via the keyboard for accessibility.
     */
    public onKeyboardBlur(): void {
        this.resetLabel();
        this.tooltipComponent.onMouseLeave();
    }
}
