import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, FileService, IToasterService, LuisConstants, PROGRESS_STATES, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthInfo } from '../models/auth.model';

/**
 * @description
 * Contains LUIS's authentication methods. Caches user session data without
 * resorting to caching layer for simplicity.
 */
@Injectable()
export class AuthService {
	private _authInfo: AuthInfo = new AuthInfo();
	private readonly _busy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private readonly _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _redirectUrlKey: string = 'luis_auth_redirect_url';

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _baseService: BaseService,
		private readonly _fileService: FileService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterSerivce: IToasterService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		this._initState();
	}

	/**
	 * @description
	 * Gets the auth info object.
	 *
	 * @returns The current auth info object.
	 */
	public get authInfo(): AuthInfo {
		return this._authInfo;
	}

	/**
	 * @description
	 * Sets the auth info object.
	 *
	 * @param value The value to set the auth info object to.
	 */
	public set authInfo(value: AuthInfo) {
		this._authInfo = value;
	}

	/**
	 * @description
	 * Gets the current session redirect url.
	 */
	public get sessionRedirectUrl(): string {
		return window.sessionStorage.getItem('luis_auth_redirect_url');
	}

	/**
	 * @method
	 * @description
	 * Sets the current session redirect url.
	 */
	public set sessionRedirectUrl(url: string) {
		if (url === null) {
			window.sessionStorage.removeItem(this._redirectUrlKey);
		} else {
			window.sessionStorage.setItem(this._redirectUrlKey, url);
		}
	}

	/**
	 * @description
	 * Gets the base url for hitting the auth service.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.directWebApiUrl}/auth`;
	}

	/**
	 * @description
	 * Logs in to LUIS.
	 */
	public login(): void {
		const url: string = `${this._baseUrl}/signin?redirect=${this._getHostUrl()}/Identity/SignIn`;

		this._goToUrl(url);
	}

	/**
	 * @description
	 * Login in a separate window for bot framework.
	 */
	public bfLogin(): void {
		const url: string = `${this._baseUrl}/signin?redirect=${window.location.protocol}//${window.location.hostname}/fedcallback.html`;
		const child: Window = window.open(url, 'Sign In', 'width = 600, height = 600');
		setInterval(() => (child.closed ? window.location.reload() : null), 300);
	}

	/**
	 * @description
	 * Logs out of LUIS, optionally redirecting to a given URL.
	 *
	 * @param redirect An optional URL to redirect to if given.
	 */
	public logout(): void {
		const url: string = `${this._baseUrl}/signout?redirect=${this._getHostUrl()}/Identity/SignOut`;

		this._goToUrl(url);
	}

	/**
	 * @description
	 * Returns the observable of the service state stream.
	 *
	 * @returns An observable of the service state.
	 */
	public isBusy(): Observable<boolean> {
		return this._busy.asObservable();
	}

	/**
	 * @description
	 * Returns the observable of the login state stream.
	 *
	 * @returns An observable of the login state.
	 */
	public isLoggedIn(): Observable<boolean> {
		return this._loggedIn.asObservable();
	}

	/**
	 * @description
	 * Initializes the service.
	 */
	private _initState(): void {
		this._check()
			.flatMap(() => this._authenticate())
			.finally(() => this._busy.next(false))
			.subscribe(
				() => {
					this._baseService.setConfigs({
						userEmail: this._authInfo.email,
						userSubKey: this._authInfo.key
					});
					this._loggedIn.next(true);
				},
				() => this._loggedIn.next(false)
			);

		this._checkForLoginErrors();
	}

	/**
	 * @description
	 * Checks if the user is logged in or not.
	 *
	 * @returns An observable to indicate completion.
	 */
	private _check(): Observable<Object> {
		const url: string = `${this._baseUrl}/authinfo`;
		const options = this._baseService.optionsBuilder
			.useJSON()
			.useSubKey(this._baseService.configs.userSubKey)
			.useCORS()
			.build();

		return this._httpService.get(url, options).do(data => this._authInfo.setAuthInfoFromApi(data));
	}

	/**
	 * @description
	 * Checks for AAD login errors via the AAD login cookie that is
	 * returned from the backend. Displays a toast with a link to
	 * download the logs from the error and close that toast.
	 */
	private _checkForLoginErrors(): void {
		const urlParams = new URLSearchParams(window.location.search);

		if (!urlParams) {
			return;
		}

		const errorObject = JSON.parse(urlParams.get('error'));

		if (!errorObject) {
			return;
		}

		const serialziedErrorObject = JSON.stringify(errorObject, undefined, 4)
			.replace(/\\n/g, '')
			.replace(/\\r/g, ' - ');

		this._toasterSerivce
			.add({
				errorMsg: [
					{ text: this._i18n.instant('portal.auth.error_acurred_while_auth') },
					{
						text: this._i18n.instant('portal.auth.click_here_to_download'),
						context: this,
						handler: () => this._fileService.save(serialziedErrorObject, `${LuisConstants.AAD_ERROR_COOKIE_NAME}.log`)
					},
					{ text: this._i18n.instant('portal.auth.send_it_when_contacting_support') }
				]
			})
			.next({ state: PROGRESS_STATES.ERROR });
	}

	/**
	 * @description
	 * Checks if the user is logged in or not.
	 *
	 * @returns An observable to indicate completion.
	 */
	private _authenticate(): Observable<Object> {
		const url: string = `${this._baseUrl}/authenticate`;

		return this._httpService
			.get(
				url,
				this._baseService.optionsBuilder
					.useJSON()
					.useSubKey(this._baseService.configs.userSubKey)
					.useCORS()
					.build()
			)
			.do(data => this._authInfo.setAuthenticateInfoFromApi(data));
	}

	/**
	 * @description
	 * Redirects the window to the given url.
	 *
	 * @param url The url to redirect to.
	 */
	private _goToUrl(url: string): void {
		window.location.href = url;
	}

	/**
	 * @description
	 * Returns the host url for the LUIS backend used to sign in.
	 *
	 * @returns The url to use for redirect.
	 */
	private _getHostUrl(): string {
		return this._baseService.configs.directApiUrl.substring(0, this._baseService.configs.directApiUrl.indexOf('/api/v2.0'));
	}
}
