import {
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	QueryList,
	Renderer,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs/Rx';
import { ViewportRuler } from '../overlay/position/viewport-ruler';
import { DropdownControlService } from '../services/dropdown-control.service';
import { UuidGeneratorService } from '../services/uuid-generator.service';
import { FocusKeyManager } from '../utils/accessibility/focus-key-manager';
import { coerceBooleanProperty } from '../utils/coercion/boolean-property';
import { DOWN_ARROW, ENTER, ESCAPE } from '../utils/keyboard/keycodes';
import { validateValue } from '../utils/validation/validate-value';
import { DropdownItemComponent, DropdownItemData, DropdownItemSelectionMode } from './dropdown-item/dropdown-item.component';

export type DropdownOrientation = 'top' | 'bottom';
export type DropdownType = 'box' | 'inline';

@Component({
	selector: 'm-dropdown',
	templateUrl: 'dropdown.component.html',
	styleUrls: ['dropdown.component.scss'],
	host: {
		'(keydown)': '_handleKeydown($event)',
		'(mousedown)': '_checkDisabled($event)'
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DropdownControlService]
})
export class DropdownComponent {
	@Input()
	get disabled() {
		return this._disabled;
	}
	set disabled(v: any) {
		this._disabled = coerceBooleanProperty(v);
	}
	public dirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public dropdownIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public choice: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public openedByMouse: boolean = false;
	public ariaId: string;
	public currentText: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public showEmptyItem: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	@ContentChildren(DropdownItemComponent) public dropdownItems: QueryList<DropdownItemComponent>;
	@ViewChild('anchorPoint', { read: ElementRef }) public anchorPointRef: ElementRef;
	@ViewChild('dropdownPanel', { read: ElementRef }) public dropdownPanelRef: ElementRef;
	@ViewChild('dropdownPanel', { read: Renderer }) public dropdownPanelRendererRef: Renderer;

	@Output() public onValueChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() public onDropdownOpen: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onDropdownClose: EventEmitter<void> = new EventEmitter<void>();

	@Input('icon') public icon: string = '';
	@Input() public showArrow: boolean = true;
	@Input() public arrowIcon: string = 'FlickUp';
	@Input() public hint: string;
	@Input() public showTitle: boolean = true;
	@Input() public orientation: DropdownOrientation = 'bottom';
	@Input() public type: DropdownType = 'box';
	@Input() public value: any;
	@Input() public emptyListPlaceholder: string;
	@Input('is-erroneous') public isErroneous: boolean;
	@Input() public ariaLabelledBy: string = null;
	@Input() public showHint: boolean = true;

	private _keyManager: FocusKeyManager;
	private _tabSubscription: Subscription;
	private _valueChangeSubscription: Subscription;
	private _originalOrientation: DropdownOrientation;
	private _disabled: boolean = false;

	constructor(
		private readonly _i18n: TranslateService,
		private _viewportRuler: ViewportRuler,
		private _dropdownService: DropdownControlService,
		_uuidService: UuidGeneratorService
	) {
		// Generate guid for dropdown content panel
		this.ariaId = _uuidService.get('m-id-');
		this.hint = this._i18n.instant('ui.dropdown.hint');
		this.emptyListPlaceholder = this._i18n.instant('ui.dropdown.no_items');
	}

	/**
	 *  Throw error if invalid orientation or type is provided.
	 */
	public ngOnInit(): void {
		// Cache initial, desired orientation
		this._originalOrientation = this.orientation;

		// Register with the event service
		this._dropdownService.registerDropdown(this);

		// Subscribe to event service to update value
		this._valueChangeSubscription = this._dropdownService.currentValue
			.asObservable()
			.do(data => this.currentText.next(data ? data.text : ''))
			.map((data: DropdownItemData) => {
				if (data !== null) {
					this._makeDirty();
					this.choice.next(data.text);
				} else {
					this.dirty.next(false);
				}

				this.closeDropdown();

				return data;
			})
			.distinctUntilChanged((data1, data2) => data1 === data2 || (data1 && data2 && data1.value === data2.value))
			.filter(data => data && data.mode === DropdownItemSelectionMode.User)
			.map(data => data.value)
			.subscribe(value => this.onValueChange.emit(value));
	}

	public ngAfterContentInit(): void {
		// Set up focus key manager
		this._keyManager = new FocusKeyManager(this.dropdownItems).withWrap();
		this._tabSubscription = this._keyManager.tabOut.subscribe(() => this.closeDropdown());
	}

	public ngAfterContentChecked(): void {
		this.showEmptyItem.next(!this.dropdownItems.length);
		this.dropdownItems.forEach(option => option.showTitle.next(this.showTitle));
	}

	public ngOnDestroy(): void {
		// Clean up subscription(s)
		if (this._tabSubscription) {
			this._tabSubscription.unsubscribe();
			this._tabSubscription = null;
		}
		if (this._valueChangeSubscription) {
			this._valueChangeSubscription.unsubscribe();
			this._valueChangeSubscription = null;
		}
	}

	/**
	 * Validate changes in input.
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		for (const prop in changes) {
			if (prop === 'type') {
				if (!validateValue(changes[prop].currentValue, ['box', 'inline'])) {
					throw new Error(
						'Invalid dropdown type provided! Type value must be one of the following: "box" "inline"' +
							'\nExample: <m-dropdown type="inline"></m-dropdown>'
					);
				}
			} else if (prop === 'orientation') {
				if (!validateValue(changes[prop].currentValue, ['top', 'bottom'])) {
					throw new Error(
						'Invalid dropdown orientation provided! Orientation value must be one of the following: "top" "bottom"' +
							'\nExample: <m-dropdown orientation="bottom"></m-dropdown>'
					);
				}
			} else if (prop === 'value') {
				const dropDownValue = changes[prop].currentValue;
				const dropDownComp = this._dropdownService.findItemForValue(dropDownValue);
				if (dropDownComp) {
					dropDownComp.select(DropdownItemSelectionMode.Programmatic);
				} else {
					this._dropdownService.updateValue(null);
				}
			}
		}
	}

	/**
	 * Returns a valid tabindex attribute value depending on disabled state.
	 */
	public _getTabIndex(): string {
		return this._disabled ? '-1' : '0';
	}

	/**
	 * Clears the dropdown selected value.
	 */
	public _clearSelection(): void {
		this._dropdownService.currentValue.next(null);
	}

	/**
	 * Puts the dropdown into a 'dirty' state after an option is selected.
	 */
	public _makeDirty(): void {
		this.dirty.next(true);
	}

	/**
	 * Toggles dropdown visibility state.
	 */
	public toggleDropdown(): void {
		if (this.dropdownIsOpen.getValue()) {
			this.closeDropdown();
		} else {
			this.openDropdown();
		}
	}

	/**
	 * Closes the dropdown.
	 */
	public closeDropdown(): void {
		if (this.dropdownIsOpen.getValue()) {
			this.dropdownIsOpen.next(false);
			// If the dropdown was opened by keyboard, focus the anchor
			if (!this.openedByMouse) {
				this._focusAnchor();
			}
			// Reset the opened by mouse boolean
			this.openedByMouse = false;
			// Reset original orientation
			this.orientation = this._originalOrientation;
			this.onDropdownClose.emit();
		}
	}

	/**
	 * Opens the dropdown.
	 */
	public openDropdown(): void {
		if (!this.dropdownIsOpen.getValue() && !this._disabled) {
			// Perform clipping check
			this._checkDropdownClipping();
			this.dropdownIsOpen.next(true);
			// If the dropdown was opened by keyboard, focus the first item
			if (!this.openedByMouse) {
				// Ensure that angular tick occurrs, change detection fires, and the menu
				// is actually opened after the subject value is detected before attempting
				// to focus the first value.
				setTimeout(() => this.focusFirstItem());
			}
			this.onDropdownOpen.emit();
		}
	}

	/**
	 * Handle a keyboard event from the menu, delegating to the appropriate action.
	 */
	public _handleKeydown(event: KeyboardEvent): void {
		switch (event.keyCode) {
			case ESCAPE:
				if (this.dropdownIsOpen.getValue()) {
					this.closeDropdown();
					event.stopPropagation();
				}

				return;
			case ENTER:
				if (!this.dropdownIsOpen.getValue()) {
					event.stopPropagation();
					this.openDropdown();
				}

				return;
			case DOWN_ARROW:
				if (!this.dropdownIsOpen.getValue()) {
					event.stopPropagation();
					this.openDropdown();

					return;
				}
			// falls through.
			default:
				if (this.dropdownIsOpen.getValue()) {
					this._keyManager.onKeydown(event);
					event.stopPropagation();
				}

				return;
		}
	}

	/**
	 * Handles a click on the dropdown backdrop.
	 */
	public _handleBackdropClick(): void {
		this.closeDropdown();
	}

	/**
	 * Handles a click on the dropdown anchor point.
	 */
	public _handleAnchorClick(): void {
		this.openedByMouse = true;
		this.toggleDropdown();
	}

	/**
	 * Stops click event propogation if disabled.
	 */
	public _checkDisabled(event: Event): void {
		if (this._disabled) {
			event.preventDefault();
			event.stopImmediatePropagation();
		}
	}

	/**
	 * This function will check whether the element will clip out of the viewport with its
	 * current orientation value. If it will clip, a fallback position will be tried and if
	 * the result is better, will be used instead.
	 *
	 * TODO(toanzian): Might want to abstract this into a utility function later on. Could be used for
	 * tooltip component as well.
	 */
	private _checkDropdownClipping(): void {
		switch (this.orientation) {
			case 'top': {
				this.orientation =
					this._getVisibleAreaForOrientation('top') >= this._getVisibleAreaForOrientation('bottom') ? 'top' : 'bottom';

				return;
			}
			case 'bottom': {
				this.orientation =
					this._getVisibleAreaForOrientation('bottom') >= this._getVisibleAreaForOrientation('top') ? 'bottom' : 'top';

				return;
			}
			default:
				throw new Error('Somehow hit default case inside of _checkDropdownClipping()!');
		}
	}

	/**
	 * Given a dropdown orientation, calculate the visible area of the
	 *  the dropdown panel within the viewport.
	 */
	private _getVisibleAreaForOrientation(pos: DropdownOrientation): number {
		let overflowY = 0;

		// Get client rects of viewport, dropdown panel, and dropdown anchor
		const viewportRect = this._viewportRuler.getViewportRect();
		const dropdownPanelRect: ClientRect = this.dropdownPanelRef.nativeElement.getBoundingClientRect();
		const dropdownAnchorRect: ClientRect = this.anchorPointRef.nativeElement.getBoundingClientRect();

		/** Depending on dropdown orientation, calculate visible area of
		 * dropdown panel within the current viewport.
		 *
		 * All cases follow the same pattern:
		 *     - Calculate extreme edge position of panel (bottom for below, right for right, etc.)
		 *     - If the extreme edge extends beyond the viewport, subtract the difference from the
		 *       affected dimension (width for x, height for y)
		 *     - Perform an area calculation using new dimensions (if altered at all)
		 */
		switch (pos) {
			case 'top': {
				// Calculate 'top' of panel if placed above anchor
				const panelTop = dropdownAnchorRect.top - dropdownPanelRect.height;
				if (panelTop < 0) {
					// Get y overflow
					overflowY = -panelTop;
				}
				// calcaulate visible height
				const visibleHeight = dropdownPanelRect.height - overflowY;

				// calculate visible area
				return Math.floor(visibleHeight * dropdownPanelRect.width);
			}
			case 'bottom': {
				const panelBottom = dropdownAnchorRect.bottom + dropdownPanelRect.height;
				if (panelBottom > viewportRect.height) {
					overflowY = panelBottom - viewportRect.height;
				}
				const visibleHeight = dropdownPanelRect.height - overflowY;

				return Math.floor(visibleHeight * dropdownPanelRect.width);
			}
			default:
				throw new Error('Somehow hit default case inside of _getVisibleAreaForPosition()!');
		}
	}

	/**
	 * Focus the first item in the menu. This method is used by the menu trigger
	 * to focus the first item when the menu is opened by the ENTER key.
	 */
	private focusFirstItem(): void {
		this._keyManager.setFirstItemActive();
	}

	/**
	 * Focuses the dropdown anchor point after closing the menu with keyboard.
	 */
	private _focusAnchor(): void {
		this.anchorPointRef.nativeElement.focus();
	}
}
