import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { BehaviorSubject } from 'rxjs';
import { ICompositeWrapMenuEvent } from '../../../../interfaces/ICompositeWrapMenuEvent';
import { IEntityMenuEvent } from '../../../../interfaces/IEntityMenuEvent';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../../interfaces/IEntityService';
import { ISubMenu } from '../../../../interfaces/ISubmenu';
import { Entity, ENTITY_TYPES, EntityRole } from '../../../../models/entity.model';
import { ParentEntity } from '../../../../models/parent-entity.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'roles-menu',
	templateUrl: 'roles-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class RolesMenuComponent implements ISubMenu {
	@Input() public subMenu: ISubMenu;
	@Input() public roleName: string;
	@Input() public mainEntity: Entity;

	@Output() public existingEntityClick: EventEmitter<IEntityMenuEvent> = new EventEmitter();
	@Output() public compositeEntityClick: EventEmitter<IEntityMenuEvent> = new EventEmitter();

	@ViewChild('menu') public menu: MatMenu;

	public ENTITY_TYPES: typeof ENTITY_TYPES = ENTITY_TYPES;
	public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public enableRolesLabeling: boolean = true;

	constructor(
		private _i18n: TranslateService,
		@Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService,
		@Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService
	) {}

	/**
	 * @description
	 * This method checks if the given role matches any of the entity roles.
	 */
	public selectedRoleMatchesRoles(roles: EntityRole[]): boolean {
		return roles.find(role => role.name === this.roleName) !== undefined;
	}

	/**
	 * @description
	 * This method decides whether to show roles list or not based on whether the selected
	 * entity is the main entity labeled with or not only in the case of closed entity type.
	 */
	public showRolesList(selectedEntity: Entity): boolean {
		if (
			selectedEntity.type === ENTITY_TYPES.CLOSED &&
			(this.mainEntity === undefined || (this.mainEntity && this.mainEntity.id !== selectedEntity.id))
		) {
			return false;
		}

		return true;
	}

	/**
	 * @description
	 * Adds a role to a specific entity.
	 *
	 * @param event Native click event.
	 * @param entity The entity to which the role should be added
	 */
	public addRole(event: KeyboardEvent, entity: Entity): void {
		event.stopPropagation();
		const role = this.querySubject.getValue().trim();
		if (!role) {
			return;
		}

		const entityRole = entity.clone().appendRole(role);

		this._entityService
			.add(entityRole)
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.roles-menu.create_toast') }))
			.subscribe(roleId => {
				entityRole.id = `${roleId}`;
				this.addLabelRole(entity, entityRole);
				this.querySubject.next('');
			});
	}

	/**
	 * @description
	 * Labels the selected entity with a specific role.
	 *
	 * @param entity The entity that the segment is labeled with.
	 * @param role The role to be applied.
	 */
	public addLabelRole(entity: Entity, role: EntityRole): void {
		const event = { entity: entity, eventData: { role: role.name, roleId: role.id } };
		if (entity.type === ENTITY_TYPES.COMPOSITE && entity instanceof ParentEntity) {
			this.compositeEntityClick.emit(event);

			return;
		}
		this.existingEntityClick.emit(event);
	}

	/**
	 * @description
	 * Removes the selected role from the given segment.
	 *
	 * @param entity The entity that the segment is labeled with.
	 */
	public removeRoleLabel(entity: Entity): void {
		const event = { entity: entity, eventData: { role: undefined } };
		if (entity.type === ENTITY_TYPES.COMPOSITE) {
			this.compositeEntityClick.emit(event);

			return;
		}
		this.existingEntityClick.emit(event);
	}
}
