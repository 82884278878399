import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IKeyHelpersService, KEY_HELPERS_SERVICE_TOKEN } from '../interfaces/IKeyHelpersService';
import { IKeyTableMetaData } from '../interfaces/IKeyTableMetaData';
import { EndpointKey } from '../models/azure-key.model';
import { CONTINENT } from '../models/continent.model';

/**
 * @description
 * Returns appropiate key meta data based on the given key's region
 * and the current domain of the website.
 */
@Pipe({
    name: 'keyRegionCheck'
})
export class KeyRegionCheckPipe implements PipeTransform {
    constructor(private _i18n: TranslateService, @Inject(KEY_HELPERS_SERVICE_TOKEN) private _keyHelpersService: IKeyHelpersService) {}

    /**
     * @description
     * Checks if the key given does not belong to the current domain.
     *
     * @param key The key to check
     * @returns The key meta data based on the truth table
     * mentioned in the description.
     */
    public transform(key: EndpointKey): IKeyTableMetaData {
        const currentContinent: CONTINENT = this._keyHelpersService.getDomainInfo().continent;
        const continentRegions: Map<CONTINENT, string[]> = this._keyHelpersService.getContinentRegions();
        const groupedContinents: CONTINENT[] = [CONTINENT.US, CONTINENT.BRAZIL, CONTINENT.ASIA];
        const keyMetaData: IKeyTableMetaData = {
            isDisabled: true,
            keyName: this._i18n.instant('publish-pane.key-region-check.not_applicable_in_domain', {
                name: key.name,
                location: key.location
            })
        };

        let isKeyDisabled: boolean;

        if (groupedContinents.indexOf(currentContinent) !== -1) {
            isKeyDisabled = groupedContinents.map(c => continentRegions.get(c).indexOf(key.location) !== -1).find(b => b) === undefined;
        } else {
            isKeyDisabled = continentRegions.get(currentContinent).indexOf(key.location) === -1;
        }

        if (!isKeyDisabled) {
            keyMetaData.isDisabled = false;
            keyMetaData.keyName = `${key.name} - (${key.location})`;
        }

        return keyMetaData;
    }
}
