/**
 * @description
 * An enumeration of the endpoint versions.
 */
export enum ENDPOINT_VERSIONS {
	VERSION_2,
	VERSION_3
}

/**
 * @description
 * Represents an interface of the allowed settings that can manipulate an
 * app's endpoint url. Used in the publish page to generated a copiable URL
 * for the user to use.
 */
export interface IUrlSettings {
	staging?: boolean;

	bing?: boolean;

	verbose?: boolean;

	endpointVersion?: ENDPOINT_VERSIONS;

	multiIntentEnabled?: boolean;
}
