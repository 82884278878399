import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from './error-handler.model';

/**
 * @description
 * Represents a generic error handler that gets called if all other
 * specific error handlers could not parse the error.
 */
export class GenericErrorHandler extends ErrorHandler {
	/**
	 * @description
	 * Handles the error by providing a default message.
	 *
	 * @param err The error that was thrown.
	 * @returns A formatted Error object.
	 */
	public handle(err: HttpErrorResponse): Error {
		const errorObject = new Error();
		errorObject.name = `${err.status}`;
		errorObject.message = 'A server error occured. Please refresh the page and try again.';

		return errorObject;
	}
}
