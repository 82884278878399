import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
    ViewChildren,
    QueryList
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { UP_ARROW, DOWN_ARROW, ESCAPE, ENTER } from '../utils/keyboard/keycodes';

// Interface of the array of options to be received from the parent.
export interface ISearchCreateItem {
    text: string;
    subtext: string;
    id: string;
    type: ItemType;
}

export enum ItemType {
    default,
    disabled,
    normal,
    group
}

@Component({
    selector: 'm-search-create',
    templateUrl: 'search-create.component.html',
    styleUrls: ['./search-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class SearchCreateComponent {
    @Input() public placeholderText: string;
    @Input() public optionsList: ISearchCreateItem[]; // every item in dropdown menu
    @Input() public optionsVisible: boolean;
    @Input() public pickedId: string;
    @Input() public selectedId: string;
    @Input() public loading: boolean;
    @Input() public errorText: string;

    @Output() public selectedIdChange: EventEmitter<string> = new EventEmitter<string>();
    @Output('textChange') public onChange: EventEmitter<string> = new EventEmitter<string>();
    @Output('picked') public onPicked = new EventEmitter<ISearchCreateItem>();
    @Output('focus') public onFocus = new EventEmitter();
    @Output('blur') public onBlur = new EventEmitter();

    @ViewChild('container', { read: ElementRef }) public container: ElementRef;
    @ViewChild('queryField', { read: ElementRef }) public queryField: ElementRef;
    @ViewChild('optionsListElement', { read: ElementRef }) public optionsElementsList: ElementRef;
    @ViewChildren('optionElement', { read: ElementRef }) public optionsElements: QueryList<ElementRef>;

    public currentOptionsMap = new Map<string, ISearchCreateItem>();
    public currentInputValue = new BehaviorSubject<string>('');

    /**
     * Conversts list of options to map
     * @param options options to convert to map
     */
    private static OptionsToMap(options: ISearchCreateItem[]): Map<string, ISearchCreateItem> {
        const ret = new Map<string, ISearchCreateItem>();
        options && options.forEach(v => ret.set(v.id, v));

        return ret;
    }

    /**
     * On component changes callback
     *
     * @param changes changes in component
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['optionsList']) {
            this.currentOptionsMap = SearchCreateComponent.OptionsToMap(this.optionsList);
        }

        if (changes['pickedId']) {
            this.setCurrentItemText(this.pickedId);
        }

        if (changes['optionsVisible'] && changes['optionsVisible'].currentValue === false) {
            this.setCurrentItemText(this.pickedId);
        }
    }

    /**
     * @description
     * Send data to the parent with user's input value
     */
    public onQueryChange(event: UIEvent): void {
        const inputValue: string = this.queryField.nativeElement.value;
        this.currentInputValue.next(inputValue);
        this.onChange.emit(inputValue);
    }

    /**
     * @description
     * Fires when the user clicks an item in the list or hits the enter key in the text field.
     * @param id id of the option from optionList
     */
    public onItemCommit(option: ISearchCreateItem, event: UIEvent): void {
        if (!this.checkTypeDisabled(option) && !this.checkTypeGroup(option)) {
            this.selectedIdChange.emit(option.id);
            this.commitItem(option.id);
        } else {
            event.stopImmediatePropagation();
            event.preventDefault();
        }
    }

    /**
     * @description
     * Shows the dropdown list of the items on text field focus
     */
    public onFocusInput(): void {
        (<any>this.queryField.nativeElement).setSelectionRange(0, this.queryField.nativeElement.value.length);
        this.onFocus.emit();
    }

    /**
     * @description
     * Hides the item list when text field is defocused
     */
    public onBlurInput(): void {
        this.onBlur.emit();
    }

    /**
     * @description
     * check the current option to verify the ItemType is disabled
     * returning true applies the disabled class
     * @param option currently selected item from optionsList
     */
    public checkTypeDisabled(option: ISearchCreateItem): boolean {
        return option.type === ItemType.disabled;
    }

    /**
     * @description
     * check the current option to verify the ItemType is default
     * returning true applies the default class
     * @param option currently selected item from optionsList
     */
    public checkTypeDefault(option: ISearchCreateItem): boolean {
        return option.type === ItemType.default;
    }

    /**
     * @description
     * check the current option to verify the ItemType is normal
     * returning true applies the normal class
     * @param option currently selected item from optionsList
     */
    public checkTypeNormal(option: ISearchCreateItem): boolean {
        return option.type === ItemType.normal;
    }

    /**
     * @description
     * check the current option to verify the ItemType is groupIdgroupentifier
     * returning true applies the group class
     * @param option currently selected item from optionsList
     */
    public checkTypeGroup(option: ISearchCreateItem): boolean {
        return option.type === ItemType.group;
    }

    /**
     * Handles keyboard event
     * @param event keyboard event
     */
    public onKeyUp(event: KeyboardEvent): void {
        if (!this.optionsVisible) {
            this.onFocus.emit();

            return;
        }

        switch (event.keyCode) {
            case UP_ARROW:
                this.moveSelection(-1, event);
                this._scrollSelectedElementIntoView(-1);
                break;
            case DOWN_ARROW:
                this.moveSelection(1, event);
                this._scrollSelectedElementIntoView(1);
                break;
            case ESCAPE:
                this.queryField.nativeElement.focus();
                this.onBlurInput();
                break;
            case ENTER:
                this.onItemCommit(this.currentOptionsMap.get(this.selectedId), event);
                this.queryField.nativeElement.focus();
                event.preventDefault();
                event.stopPropagation();
                break;
            default:
        }
    }

    /**
     * Moves current selection to next or previous based upon input
     *
     * @param direction +1 for next, -1 for previous
     * @param event They keyboard UI event
     */
    public moveSelection(direction: number, event: UIEvent): void {
        const index = this.optionsList.indexOf(this.currentOptionsMap.get(this.selectedId));
        for (let i = 0; i < this.optionsList.length; ++i) {
            let nextIndex = (index + direction + direction * i) % this.optionsList.length;
            if (nextIndex < 0) {
                nextIndex = this.optionsList.length + nextIndex;
            }

            const nextSelectionItem = this.optionsList[nextIndex];
            if (!this.checkTypeDisabled(nextSelectionItem) && !this.checkTypeGroup(nextSelectionItem)) {
                this.selectedIdChange.emit(nextSelectionItem.id);
                this.selectedId = nextSelectionItem.id;
                break;
            }
        }

        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * The ID to be picked
     * @param id to be picked
     */
    private commitItem(id: string): void {
        if (!this.currentOptionsMap.has(id)) {
            return;
        }

        const entry = this.currentOptionsMap.get(id);
        this.onPicked.emit(entry);
    }

    /**
     * Populates the current input value corresponding to pickedId
     */
    private setCurrentItemText(newId: string): void {
        if (this.currentOptionsMap.has(newId)) {
            const item = this.currentOptionsMap.get(newId);
            this.currentInputValue.next(item.text);
            this.onChange.emit(item.text);
        } else {
            this.currentInputValue.next(null);
        }
    }

    /**
     * @description
     * Scrolls the container to the selected element.
     *
     * @param direction The direction of the scroll.
     */
    private _scrollSelectedElementIntoView(direction: number): void {
        const selectedElement = this.optionsElements.toArray().find(element => element.nativeElement.classList.contains('selected-id'));

        if (selectedElement) {
            selectedElement.nativeElement.scrollIntoView(direction > 0 ? true : false);
        }
    }
}
