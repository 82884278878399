import { Injectable } from '@angular/core';
import { BaseService, IResourceApiParser } from '@luis/core';
import { Intent, INTENT_TYPES } from '../models/intent.model';

/**
 * @description
 * Represents an intent "factory" that provides methods to create an intent based
 * on the intent type.
 */
@Injectable()
export class IntentFactoryService {
	constructor(private _baseService: BaseService) {}

	/**
	 * @description
	 * Gets the base web url for manipulating application's intents.
	 *
	 * @returns The base url for intents manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/versions/${this._baseService.configs.versionId}`;
	}

	/**
	 * @method
	 * @description
	 * Creates an intent based on the model received from the api object from the web.
	 *
	 * @param apiObject A model received from the web.
	 * @returns Returns an intent casted from the models.
	 */
	public importFromApi(apiObject: any): Intent {
		let parser: IResourceApiParser;

		if (apiObject.customPrebuiltDomainName !== undefined && apiObject.typeId === INTENT_TYPES.SIMPLE) {
			parser = this.getParserFunction(INTENT_TYPES.DOMAIN);
		} else {
			parser = this.getParserFunction(apiObject.typeId);
		}

		return parser ? <Intent>parser(apiObject) : undefined;
	}

	/**
	 * @method
	 * @description
	 * Returns the parser function to use to parse the api object
	 * as received from the server to generate the intent of the
	 * given type.
	 *
	 * @param intentType The intent type to create.
	 * @returns An parser function that returns an intent.
	 */
	public getParserFunction(intentType: number): IResourceApiParser {
		switch (intentType) {
			case INTENT_TYPES.SIMPLE:
			case INTENT_TYPES.DOMAIN:
				return Intent.importFromApi;
			default:
				return undefined;
		}
	}

	/**
	 * @method
	 * @description
	 * Gets the correct url to add an intent based on the
	 * intent type.
	 *
	 * @param intentType The intent type of the intent to
	 * be added.
	 * @returns The url to call to add the intent.
	 */
	public getPostUrl(intentType: number): string {
		switch (intentType) {
			case INTENT_TYPES.SIMPLE:
				return `${this._baseUrl}/intents`;
			case INTENT_TYPES.DOMAIN:
				return `${this._baseUrl}/customprebuiltintents`;
			default:
				return undefined;
		}
	}
}
