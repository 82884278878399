import { Pipe, PipeTransform } from '@angular/core';
import { EntityWrapService } from '@luis/entities';
import { Observable } from 'rxjs/Rx';

@Pipe({
    name: 'wrapUnderlineExpander'
})
export class WrapUnderlinExpanderPipe implements PipeTransform {
    constructor(
        private _entityWrapService: EntityWrapService
    ) { }

    /**
     * @method
     * @description
     * Returns true if the composite wrap underline should be expanded to
     * give a smoother continuos look or not. The underline should be expanded
     * to the left if the token on the left is part of the wrapping process and
     * the same applies to the other direction on the right.
     *
     * @param isHovered A trigger to fire the pipe.
     * @param index The index of the token that was hovered.
     * @param direction True if the right is being considered and false
     * if left is being considered.
     * @returns An observable of whether the line should be
     * expanded in the given direction or not.
     */
    public transform(_: any, index: number, direction: boolean): Observable<boolean> {
        return Observable.combineLatest(
            this._entityWrapService.getWrapIndeces(),
            this._entityWrapService.getWrapHoverIndeces()
        )
            .map(([indeces, hoverIndeces]) => {
                if (direction) {
                    return (indeces.start <= (index + 1) && (index + 1) <= indeces.end) ||
                        (hoverIndeces.start <= (index + 1) && (index + 1) <= hoverIndeces.end);
                }
                else {
                    return (indeces.start <= (index - 1) && (index - 1) <= indeces.end) ||
                        (hoverIndeces.start <= (index - 1) && (index - 1) <= hoverIndeces.end);
                }
            });
    }
}
