import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as c3 from 'c3';
import { AxisData, CHART_TYPES } from '../../models/chart.model';
import { ChartsRendererService } from '../../services/charts-renderer.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

export interface IPieCharClickInput {
	id: string;
	index: number;
	name: string;
	ratio: number;
	value: number;
}

@Component({
	selector: 'pie-chart',
	template: '<loading [visible]="!(isInitialized)"></loading>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent extends BaseChartComponent implements AfterViewInit {
	@Output() public chartClick: EventEmitter<IPieCharClickInput> = new EventEmitter<IPieCharClickInput>();

	constructor(
		private readonly elementRef: ElementRef,
		private readonly chartsRenderer: ChartsRendererService,
		private readonly i18n: TranslateService
	) {
		super(i18n, elementRef, chartsRenderer);
	}

	public ngAfterViewInit(): void {
		this.xData
			.zip(this.yData, (xData, yData) => ({ xData, yData }))
			.map(this.filterZeroedData)
			.subscribe(({ xData, yData }) => {
				const config: c3.ChartConfiguration = this._getConfig(xData, yData);
				if (!this.isInitialized) {
					this.init(config);
					this.isInitialized = true;
				} else {
					this.update({ columns: config.data.columns });
				}
			});
	}

	/**
	 * @description
	 * Initializes the chart component with the required configuration
	 */
	protected init(config: c3.ChartConfiguration): void {
		super.init({ ...config, bindto: this.elementRef.nativeElement });
	}

	/**
	 * @description
	 * Transforms xAxisData and yAxisData to a data structure accepted by ChartRendererService
	 */
	private _transformAxisDataToColumns(xData: AxisData, yData: AxisData): c3.PrimitiveArray[] {
		return xData.data.map((intentName: string, index: number) => [intentName, yData.data[index]]);
	}

	/**
	 * @description
	 * Creates the configuration accepted by the ChartRendererService for generating a pie chart
	 */
	private _getConfig(xData: AxisData, yData: AxisData): c3.ChartConfiguration {
		const data: any = {
			columns: this._transformAxisDataToColumns(xData, yData),
			type: CHART_TYPES.PIE,
			empty: {
				label: {
					text: this.emptyLabelText
				}
			},
			color: (_, d) => {
				const index: number = xData.data.indexOf(d) % this.colors.length;

				return this.colors[index];
			},
			onclick: this._handleClick
		};

		return { data };
	}

	/**
	 * @description
	 * A function invoked with the bar data that was clicked on.
	 */
	private _handleClick = (data: IPieCharClickInput): void => {
		this.chartClick.emit(data);
	};
}
