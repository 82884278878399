import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { APP_VERSION_SERVICE_TOKEN, IAppVersionService } from '../../../interfaces/IAppVersionService';
import { AppVersion } from '../../../models/app-version.model';
import { VersionNameInputComponent } from '../version-name-input/version-name-input.component';

/**
 * @description
 * Represents the modal for creating a new app.
 */
@Component({
	selector: 'version-clone-modal',
	templateUrl: 'version-clone-modal.component.html',
	styleUrls: ['version-clone-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionCloneModalComponent implements OnInit {
	@ViewChild('versionNameInput') public versionNameInput: VersionNameInputComponent;

	public isButtonDisabled: Observable<boolean>;

	private readonly _inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<AppVersionCloneModalComponent>,
		@Inject(MAT_DIALOG_DATA) private readonly _version: AppVersion,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _versionService: IAppVersionService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Adds a new app to the account with the form data.
	 */
	public cloneVersion(): void {
		this.isButtonDisabled
			.first()
			.filter(value => !value)
			.map(() => {
				this._inProgress.next(true);

				this._versionService
					.clone(this._version.id, this.versionNameInput.versionName)
					.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('apps.version-clone-modal.clone_toast') }))
					.finally(() => this._inProgress.next(false))
					.subscribe(version => this._dialogRef.close(version));
			})
			.subscribe();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.isButtonDisabled = Observable.combineLatest(
			this._inProgress.asObservable(),
			this.versionNameInput.versionNameValid.asObservable()
		).map(data => {
			const isInProgress: boolean = data[0];
			const isValid: boolean = data[1];

			return isInProgress || !isValid;
		});
	}
}
