import { IRect, ISize } from '../utils/rectangle';

export interface IBestFit {
	percentage: number;
	target: IRect;
	popup: IRect;
}

export type Alignment =
'auto'
| 'top left'
| 'top center'
| 'top right'
| 'middle left'
| 'middle right'
| 'bottom left'
| 'bottom center'
| 'bottom right';

export function alignPopup(targetBounds: IRect, popupSize: ISize, alignment: string, spacing:number = 12): { target: IRect, popup: IRect } {
	const SPACING = spacing;
	const nextPopupBounds = {
		height: popupSize.height,
		left: targetBounds.left,
		top: targetBounds.top,
		width: popupSize.width
	};

	const nextTargetBounds = {
		height: targetBounds.height,
		left: targetBounds.left,
		paddingBottom: 0,
		paddingLeft: 0,
		paddingRight: 0,
		top: targetBounds.top,
		width: targetBounds.width
	};

	switch (alignment) {
		case 'middle left':
			nextPopupBounds.left -= popupSize.width + SPACING;
			nextTargetBounds.left -= SPACING;
			nextTargetBounds.width += SPACING;
			break;

		case 'middle right':
			nextPopupBounds.left += targetBounds.width + SPACING;
			nextTargetBounds.width += SPACING;
			break;

		case 'bottom left':
		case 'top left':
			break;

		case 'bottom right':
		case 'top right':
			nextPopupBounds.left += targetBounds.width - popupSize.width;
			break;

		case 'bottom center':
		case 'top center':
			nextPopupBounds.left += (targetBounds.width - popupSize.width) / 2;
			break;
		default:
	}

	switch (alignment) {
		case 'top center':
		case 'top left':
		case 'top right':
			nextPopupBounds.top -= popupSize.height + SPACING;
			nextTargetBounds.top -= SPACING;
			nextTargetBounds.height += SPACING;
			break;

		case 'bottom center':
		case 'bottom left':
		case 'bottom right':
			nextPopupBounds.top += targetBounds.height + SPACING;
			nextTargetBounds.height += SPACING;
			break;

		default:
			nextPopupBounds.top += (targetBounds.height - popupSize.height) / 2;
	}

	return {
		target: nextTargetBounds,
		popup: nextPopupBounds
	};
}

export function onScreenPercentage(popupBounds: IRect, viewport: { height: number, left: number, top: number, width: number}): number {
	const documentRect = document.documentElement.getBoundingClientRect();

	const onScreenBounds = {
		bottom: Math.min(popupBounds.top + popupBounds.height, viewport.top + viewport.height),
		left: Math.max(popupBounds.left, viewport.left),
		right: Math.min(popupBounds.left + popupBounds.width, viewport.left + viewport.width),
		top: Math.max(popupBounds.top, viewport.top)
	};

	const onScreenArea = (onScreenBounds.right - onScreenBounds.left) * (onScreenBounds.bottom - onScreenBounds.top);
	const boundingArea = popupBounds.width * popupBounds.height;

	return onScreenArea / boundingArea;
}

export function findPopupBoundsBestFit(
	targetBounds: IRect,
	popupSize: ISize,
	alignment: string | string[],
	spacing: number,
	viewport: { height: number, left: number, top: number, width: number}): IBestFit {
	let alignments: string[];

	if (Array.isArray(alignment)) {
		alignments = alignment;
	} else {
		alignments = alignment === 'auto' ?
			['top center', 'top left', 'top right', 'bottom center', 'bottom left', 'bottom right', 'middle right', 'middle left'] :
			[alignment];
	}

	return alignments.reduce<IBestFit>((bestFit, align, index) => {
		if (!bestFit || bestFit.percentage < 1) {
			const bounds = alignPopup(targetBounds, popupSize , align, spacing); // get target and tooltip
			const percentage = onScreenPercentage(bounds.popup, viewport);

			if (!bestFit || percentage > bestFit.percentage) {
				return {
					percentage,
					target: bounds.target,
					popup: bounds.popup
				};
			}
		}

		return bestFit;
	}, null);
}
