import { AfterContentChecked, ChangeDetectionStrategy, Component, Inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericPromptService, IToasterService, PromptButtonTypes, SelectionMap, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { EMPTY_SORT_PROPS, ISortPipeProps, ToolbarInputComponent } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, flatMap, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { ClosedEntityFocusManager } from '../../../models/closed-entity-focus-manager.mode';
import { ClosedEntity, ClosedSublist } from '../../../models/closed-entity.model';
import { ImportListModalComponent } from './import-list-modal/import-list-modal.component';
import { SublistComponent } from './sublist/sublist.component';

/**
 * @description
 * Displays a closed entity's sublist details. These details are a list
 * of canonical-form/synonym pairs.
 */
@Component({
	selector: 'closed-details',
	templateUrl: 'closed-details.component.html',
	styleUrls: ['closed-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClosedDetailsComponent implements OnInit, AfterContentChecked {
	@Input() public entity: Observable<ClosedEntity>;
	@Input() public enableListSuggestions: boolean;

	public currentPage: number;
	public displayedSublists: Observable<ClosedSublist[]>;
	public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>(EMPTY_SORT_PROPS);
	public valueEntered: Subject<boolean> = new Subject<boolean>();
	public query: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public selectionMap: SelectionMap = new SelectionMap();
	public isSearchExact: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	@ViewChildren(SublistComponent) private readonly _items: QueryList<SublistComponent>;

	private _isInProgress: boolean = false;
	private _keyManager: ClosedEntityFocusManager;

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogService: MatDialog,
		private readonly _promptService: GenericPromptService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngAfterContentChecked(): void {
		this._keyManager = new ClosedEntityFocusManager(this._items);
	}

	/**
	 * @description
	 * Adds a new value to the closed list. Sets the editable sublist to the new one.
	 */
	public addSublist(name: string, field: ToolbarInputComponent): void {
		const sublistName: string = name.trim();

		if (!name.length || this._isInProgress) {
			return;
		}

		this._isInProgress = true;

		this.entity
			.first()
			.map(e => new ClosedSublist(null, sublistName, [], e))
			.do(s => (s.parent.sublists = [...s.parent.sublists, s]))
			.flatMap(s => this._entityService.add(s))
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.closed-details.add_toast') }))
			.finally(() => (this._isInProgress = false))
			.subscribe(() => {
				field.inputField.nativeElement.value = '';
				this.valueEntered.next(true);
			});
	}

	/**
	 * @description
	 * Opens the import dialog. Imports the sublists to the entity if
	 * the file provided was valid.
	 */
	public onImportClick(): void {
		this._dialogService
			.open(ImportListModalComponent, { width: '600px' })
			.afterClosed()
			.pipe(
				filter(sublists => sublists),
				flatMap(sublists => this.entity.first().map(e => ({ parent: e, sublists: sublists }))),
				map(
					e =>
						new ClosedEntity(e.parent.id, e.parent.name, [...e.parent.sublists, ...e.sublists], e.parent.roles, e.parent.parent)
				),
				flatMap(e =>
					this._entityService
						.update(e, true)
						.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.closed-details.add_toast') }))
				)
			)
			.subscribe();
	}

	/**
	 * @description
	 * Deletes the selected sublists from the selection map.
	 */
	public onDeleteClick(): void {
		const ids = this.selectionMap.selectedItems;
		this.entity
			.pipe(
				first(),
				flatMap(entity => {
					const warningText =
						entity.roles.length === 0
							? ''
							: 'Deleting a sublist will also automatically remove all associated labels from the utterances';

					return this._promptService
						.prompt(
							'Delete child?',
							`Are you sure you want to delete ${ids.length > 1 ? 'those sublists' : 'this sublist'}? ${warningText}`,
							{ ok: 'Ok', cancel: 'Cancel' },
							{ ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
						)
						.filter(response => response === 'ok')
						.map(() => ids.map(id => this._items.find(component => component.sublist.id === id)))
						.map(sublistsComponents => sublistsComponents.map(s => s.deleteSublist()));
				})
			)
			.subscribe(() => this.selectionMap.markUnselected(ids));
	}

	/**
	 * @description
	 * Fired when tab key pressed
	 * on the sublist component in the edit mode.
	 * Its output to focus on the next sublist component.
	 *
	 * @param event The keyboard Event pressed.
	 */
	public onTabClicked(i: number, event: KeyboardEvent): void {
		this._keyManager.onTabClicked(i, event);
	}

	/**
	 * @description
	 * Fired when shift + tab key pressed
	 * on the pagination component.
	 * Its output to focus on the first sublist component.
	 *
	 * @param event The keyboard Event pressed.
	 */
	public onPaginationShiftTabClicked(event: KeyboardEvent): void {
		this._keyManager.lastSublistFocus(event);
	}

	/**
	 * @description
	 * Track Angular for loop.
	 */
	public trackSublist(index: number, sublist: ClosedSublist): number {
		return sublist.id;
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.displayedSublists = Observable.combineLatest(
			this.entity,
			this.query.asObservable().debounceTime(200),
			this.isSearchExact.asObservable()
		).map(([entity, query, isExact]) => entity.sublists.filter(e => e.isQueryFound(query, isExact)));
	}
}
