/**
 * @description
 * Represents the core authentication information regarding an account.
 *
 * @param id The id of the user.
 * @param name The name of the user.
 * @param email The email of the user.
 * @param tou The terms of use acceptance status.
 */
export class AuthInfo {
    public id: string;
    public name: string;
    public email: string;
    public tou: boolean;
    public key: string;

    /**
     * @method
     * @description
     * Sets the basic auth info from the api.
     *
     * @param apiObject The authentication object received
     * from the api.
     */
    public setAuthInfoFromApi(apiObject: any): void {
        this.name = apiObject.name;
    }

    /**
     * @method
     * @description
     * Sets the authentication info from the api.
     *
     * @param apiObject The authentication object received
     * from the api.
     */
    public setAuthenticateInfoFromApi(apiObject: any): void {
        this.id = apiObject.UserId;
        this.email = apiObject.Email;
        this.key = apiObject.SubscriptionKey;
        this.tou = apiObject.IsTermsOfUseAccepted;
    }
}
