import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProgressTracker, SelectionMap } from '@luis/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { Entity, ENTITY_TYPES } from '../../../models/entity.model';
import { PrebuiltEntityInfo } from '../../../models/prebuilt-entity-info.model';
import { PrebuiltEntity } from '../../../models/prebuilt-entity.model';
import { EntityUtilitiesService } from '../../../services/entity-util.service';

/**
 * @description
 * Represents the modal for listing and adding prebuilt entities.
 */
@Component({
	selector: 'prebuilt-entity-list-modal',
	templateUrl: 'prebuilt-entity-list-modal.component.html',
	styleUrls: ['prebuilt-entity-list-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrebuiltEntityListModalComponent implements OnInit {
	public prebuiltsToDisplay: Observable<PrebuiltEntityInfo[]>;
	public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public isValid: Observable<boolean>;
	public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public selectionMap: SelectionMap = new SelectionMap();
	public tracker: ProgressTracker = new ProgressTracker();

	constructor(
		private readonly _dialogRef: MatDialogRef<PrebuiltEntityListModalComponent>,
		private readonly _entityUtilService: EntityUtilitiesService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Updates the search query stream with the new entered query.
	 */
	public onQueryChange(query: string): void {
		this.querySubject.next(query.trim());
	}

	/**
	 * @description
	 * Updates the selection map when a checkbox value changes.
	 *
	 * @param prebuiltName The prebuilt name of the checkbox that changed.
	 */
	public onCheckboxChange(prebuiltName: string): void {
		if (this.selectionMap.isSelected(prebuiltName)) {
			this.selectionMap.markUnselected([prebuiltName]);
		} else {
			this.selectionMap.markSelected([prebuiltName]);
		}
	}

	/**
	 * @description
	 * Adds the selected entities to the application.
	 */
	public onDoneClick(): void {
		const prebuilts: PrebuiltEntity[] = this.selectionMap.selectedItems.map(name => new PrebuiltEntity('', <string>name));
		this.inProgress.next(true);

		this._entityService
			.batchAdd(prebuilts, ENTITY_TYPES.PREBUILT)
			.finally(() => this.inProgress.next(false))
			.subscribe(data => this._dialogRef.close(data));
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.prebuiltsToDisplay = combineLatest(this._entityService.get(), this._entityUtilService.getPrebuiltEntitiesInfo())
			.pipe(map(([entities, prebuiltInfos]) => this._filterPrebuiltEntities(entities, prebuiltInfos)))
			.trackProgress(this.tracker.getTracker());

		this.isValid = this.selectionMap.selectedItemsAsync.map(ids => ids.length !== 0);
	}

	/**
	 * @description
	 * Filters the prebuilt entities info based on the existing entities
	 * and the whether datetime v1 should be deprecated or not.
	 *
	 * @param entities The entities added to the application.
	 * @param prebuiltInfos The prebuilt entity infos available.
	 * @returns An array of prebuilt entity infos to display.
	 */
	private _filterPrebuiltEntities(entities: Entity[], prebuiltInfos: PrebuiltEntityInfo[]): PrebuiltEntityInfo[] {
		let filteredInfos: PrebuiltEntityInfo[] = prebuiltInfos.map(p => p.clone());

		// Deprecate datetime v1 if datetime v2 is found.
		if (filteredInfos.find(p => p.name === 'datetimeV2') !== undefined) {
			const datetimeIndex: number = filteredInfos.findIndex(p => p.name === 'datetime');
			filteredInfos.splice(datetimeIndex, datetimeIndex === -1 ? 0 : 1);
		}

		// Deprecate geography and encylopedia.
		filteredInfos = filteredInfos.filter(p => p.name !== 'geography' && p.name !== 'encyclopedia');

		// Filter out those who are not already added.
		filteredInfos = filteredInfos.filter(p => entities.find(e => e.name === p.name) === undefined);

		// Set up the selection map.
		this.selectionMap.setMapItems(filteredInfos.map(p => p.name));

		return filteredInfos;
	}
}
