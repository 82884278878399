import { IResource } from '@luis/core';
import { AppEndpointConfig } from './app-endpoint-config.model';

/**
 * @description
 * Represents a LUIS application class object.
 *
 * @param id The id of the app.
 * @param name The name of the app.
 * @param culture The culture of the app.
 * @param description The description of the app.
 * @param createdDate The creation date of the app.
 * @param endpontHitsCount The number of endpoint hits this app made.
 * @param endpointConfigs The endpoint configurations of this app.
 */
export class App implements IResource {
    public id: string;
    public name: string;
    public culture: string;
    public description: string;
    public createdDate: Date;
    public endpontHitsCount: number;
    public endpointConfigs: AppEndpointConfig[];
    public activeVersion: string;
    public ownerEmail: string;

    constructor(
        id: string = '', name: string = '',
        culture: string = '', description: string = '',
        createdDate: Date = new Date(), endpontHitsCount: number = 0,
        endpointConfigs: AppEndpointConfig[] = [], activeVersion: string = '',
        ownerEmail: string = ''
    ) {
        this.id = id;
        this.name = name;
        this.culture = culture;
        this.description = description;
        this.createdDate = createdDate;
        this.endpontHitsCount = endpontHitsCount;
        this.endpointConfigs = endpointConfigs;
        this.activeVersion = activeVersion;
        this.ownerEmail = ownerEmail;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): App {
        return new App(
            apiObject.id,
            apiObject.name,
            apiObject.culture,
            apiObject.description || '',
            new Date(apiObject.createdDateTime),
            apiObject.endpointHitsCount,
            AppEndpointConfig.importBatchFromApi(apiObject.endpoints),
            apiObject.activeVersion || '',
            apiObject.ownerEmail
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            name: this.name,
            description: this.description,
            culture: this.culture
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): App {
        return new App(
            this.id.slice(),
            this.name.slice(),
            this.culture.slice(),
            this.description.slice(),
            new Date(this.createdDate.getTime()),
            this.endpontHitsCount,
            this.endpointConfigs.map(e => e.clone()),
            this.activeVersion.slice(),
            this.ownerEmail.slice()
        );
    }
}
