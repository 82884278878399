import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IToasterService, ProgressTracker, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { Entity } from '../../../models/entity.model';

/**
 * @description
 * Represents the modal for renaming an entity.
 */
@Component({
	selector: 'entity-rename-modal',
	templateUrl: 'entity-rename-modal.component.html',
	styleUrls: ['entity-rename-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityRenameModalComponent implements OnInit {
	public formEntity: Entity;
	public isButtonDisabled: Observable<boolean>;
	public _tracker: ProgressTracker = new ProgressTracker();
	private readonly _isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<EntityRenameModalComponent>,
		@Inject(MAT_DIALOG_DATA) private readonly _entityToRename: Entity,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Notifies any listeners to the stream whether the entity is valid or not.
	 */
	public onModelChange(): void {
		this._isValid.next(this._isEntityValid(this.formEntity));
	}

	/**
	 * @description
	 * Validates the entity, and if successfully validated, renames it.
	 */
	public renameEntity(): void {
		if (this._isEntityValid(this.formEntity)) {
			this._entityService
				.update(this.formEntity, false, true)
				.trackProgress(this._tracker.getTracker())
				.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.entity-rename-modal.rename_toast') }))
				.subscribe(() => this._dialogRef.close());
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.formEntity = this._entityToRename.clone();

		this.isButtonDisabled = Observable.combineLatest(this._tracker.isStarted, this._isValid)
			.startWith([false, true])
			.map(data => {
				const [inProgress, isValid] = data;

				return inProgress || !isValid;
			});
	}

	/**
	 * @description
	 * Checks if the entity name is not empty.
	 *
	 * @param entity The entity to check.
	 * @returns True if the entity is valid and false otherwise.
	 */
	private _isEntityValid(entity: Entity): boolean {
		const entityNameLength: number = entity.name.trim().length;

		return entityNameLength > 0 && entityNameLength <= 50;
	}
}
