export class StaticRouteBuilder {
    private _route: string;

    constructor(prefix: string = '') {
        this._route = prefix;
    }

    public error(): StaticRouteBuilder {
        this._route = this._route.concat('/404');

        return this;
    }

    public home(): StaticRouteBuilder {
        this._route = this._route.concat('/home');

        return this;
    }

    public maintenance(): StaticRouteBuilder {
        this._route = this._route.concat('/maintenance');

        return this;
    }

    public welcome(): StaticRouteBuilder {
        this._route = this._route.concat('/welcome');

        return this;
    }

    public build(): string {
        return this._route;
    }
}
