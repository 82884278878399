import { LiveAnnouncer } from '@angular/cdk/a11y';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApiModule } from '@luis/api';
import { AppsModule } from '@luis/apps';
import { AuthModule } from '@luis/auth';
import { CookieConsentService, CoreModule, ENVIRONMENT_TOKEN } from '@luis/core';
import { PublishPaneModule } from '@luis/publish-pane';
import { IntercomUIModule } from '@luis/ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CSHeaderModule } from 'cs-header';
import { AppRouteService } from '../common/services/app-route.service';
import { environment } from '../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { BfProxyComponent } from './components/bf-proxy/bf-proxy.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { FontInjectorComponent } from './components/font-injector/font-injector';
import { GlobalHeaderComponent } from './components/global-header/global-header.component';
import { LoggedInHeaderComponent } from './components/global-header/logged-in-header/logged-in-header.component';
import { LoggedOutHeaderComponent } from './components/global-header/logged-out-header/logged-out-header.component';
import { UserVoiceLoaderDirective } from './directives/user-voice-loader.directive';
import { httpInterceptorProviders } from './models/interceptors';
import { AdminService } from './services/admin.service';
import { LoaderPageService } from './services/loader-page.service';

/**
 * @description
 * Defines a factory function for the translation http loader.
 *
 * @param http The http client to use.
 * @returns The translation http loader.
 */
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json?cacheBuster=' + new Date().getTime());
}

@NgModule({
	imports: [
		SharedModule,
		HttpClientModule,
		CSHeaderModule,
		ApiModule.forRoot(),
		AuthModule.forRoot(),
		CoreModule.forRoot(),
		AppsModule.forRoot(),
		IntercomUIModule.forRoot(),
		PublishPaneModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		})
	],
	exports: [BfProxyComponent, CookieConsentComponent, FontInjectorComponent, GlobalHeaderComponent],
	declarations: [
		BfProxyComponent,
		CookieConsentComponent,
		FontInjectorComponent,
		GlobalHeaderComponent,
		LoggedInHeaderComponent,
		LoggedOutHeaderComponent,
		UserVoiceLoaderDirective
	],
	providers: [
		AdminService,
		CookieConsentService,
		AppRouteService,
		LoaderPageService,
		LiveAnnouncer,
		{
			provide: ENVIRONMENT_TOKEN,
			useValue: environment
		},
		httpInterceptorProviders
	]
})
export class CommonModule {}
