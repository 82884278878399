import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IDictionary } from '../../interfaces/utils/IDictionaryStorageService';
import { FEATURE_OPT_SETTINGS_TYPES } from '../../models/utils/feature-opt-settings.model';
import { FeatureOptSettingsService } from '../../services/utils/feature-opt-settings.service';

@Component({
	selector: 'feature-opt-settings',
	templateUrl: './feature-opt-settings.component.html',
	styleUrls: ['./feature-opt-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureOptSettingsComponent implements OnInit {
	@Input() public disabled = false;

	public featureOptSettings: Observable<IDictionary<boolean>>;
	public featureOptSettingsTypes: typeof FEATURE_OPT_SETTINGS_TYPES = FEATURE_OPT_SETTINGS_TYPES;

	constructor(private readonly _featureOptSettingsService: FeatureOptSettingsService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Sets the feature given with the value given.
	 *
	 * @param feature The feature to opt in/out to/from.
	 * @param value The value to either opt in (true) or opt out (false).
	 */
	public setFeatureOptSetting(feature: FEATURE_OPT_SETTINGS_TYPES, value: boolean): void {
		if (this.disabled) {
			return;
		}

		this._featureOptSettingsService.setFeatureOptSetting(feature, value);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.featureOptSettings = this._featureOptSettingsService.getAllOptSettings();
	}
}
