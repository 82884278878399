import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileService, IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { APP_UTILITIES_SERVICE_TOKEN, IAppUtilitiesService } from '../../../interfaces/IAppUtilitiesService';
import { App } from '../../../models/app.model';

/**
 * @description
 * Represents the modal for importing a new app.
 */
@Component({
	selector: 'app-logs-import-modal',
	templateUrl: 'app-logs-import-modal.component.html',
	styleUrls: ['app-logs-import-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLogsImportModalComponent implements OnInit {
	public isButtonDisabled: Observable<boolean>;

	private _selectedFile: File;
	private readonly _isFileValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _fileService: FileService,
		private readonly _dialogRef: MatDialogRef<AppLogsImportModalComponent>,
		@Inject(MAT_DIALOG_DATA) private readonly _app: App,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_UTILITIES_SERVICE_TOKEN) private readonly _appUtilService: IAppUtilitiesService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Updates the validation stream when the file value changes.
	 *
	 * @param appFile The app file input element.
	 */
	public onFileChange(file: File): void {
		this._selectedFile = file;
		this._isFileValid.next(this._validateFile(file.name));
	}

	/**
	 * @description
	 * Imports the app file given with the optional app name.
	 *
	 * @param appFile The file to import
	 * @param appName An optional name for the new app.
	 */
	public importApp(): void {
		if (this._validateFile(this._selectedFile.name)) {
			this._inProgress.next(true);

			this._fileService
				.read(this._selectedFile)
				.flatMap(content => this._appUtilService.importLogs(this._app, content))
				.trackProgress(this._toasterService.add())
				.finally(() => this._inProgress.next(false))
				.subscribe(() => this._dialogRef.close());
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.isButtonDisabled = Observable.combineLatest(this._inProgress.asObservable(), this._isFileValid.asObservable()).map(
			([inProgress, isValid]) => inProgress || !isValid
		);
	}

	/**
	 * @description
	 * Validates the file name and extension
	 *
	 * @param fileName The file name to validate.
	 * @returns True if the file name is valid and false otherwise.
	 */
	private _validateFile(fileName: string): boolean {
		const extension: string = fileName.replace(/^.*\./, '');

		return fileName.trim() !== '' && extension.toLocaleLowerCase() === 'csv';
	}
}
