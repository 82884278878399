import { UtteranceIntent } from '@luis/intents';

/**
 * @dynamic
 * @description
 * Represents a training result utterance which is ambiguous or incorrect.
 *
 * @param ambiguousIntents An array of intents which this utterance ambiguous with.
 * @param incorrectIntents An array of intents which this utterance incorrect with.
 */
export class TrainingResultUtterance {

    constructor(
        public utteranceId: number = null,
        public ambiguousIntents: UtteranceIntent[] = [],
        public incorrectIntents: UtteranceIntent[] = []
    ) { }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): TrainingResultUtterance {
        return new TrainingResultUtterance(
            apiObject.id,
            apiObject.ambiguousIntentPredictions.map(i => UtteranceIntent.importFromApi(i))
                .sort((a, b) => a.score < b.score ? 1 : -1) || [],
            apiObject.misclassifiedIntentPredictions.map(i => UtteranceIntent.importFromApi(i))
                .sort((a, b) => a.score < b.score ? 1 : -1) || []
        );
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): TrainingResultUtterance {
        return new TrainingResultUtterance(
            this.utteranceId,
            this.ambiguousIntents.map(i => i.clone()),
            this.incorrectIntents.map(i => i.clone())
        );
    }
}
