import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { IToastOptions, Toast } from '../../../models/utils/toast.model';

export const TOASTER_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IToasterService');

/**
 * @description
 * Describes the interface of the service that manages toasts
 * and their lifetime.
 */
export interface IToasterService {
	get(): Observable<Toast[]>;

	add(options?: IToastOptions): any;

	delete(toast: Toast): void;
}
