import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SearchAsyncPipe } from "./search.pipe";

@Pipe({
    name: 'prefixSearchAsync'
})
export class PrefixSearchAsyncPipe extends SearchAsyncPipe {
    constructor(private ref: ChangeDetectorRef) {
        super(ref, PrefixSearchAsyncPipe.search);
    }

    private static search(needle: string, haystack: string, caseSensitive: boolean): boolean {
        needle = needle || '';
        haystack = haystack || '';

        if (caseSensitive) {
            return haystack.startsWith(needle);
        }
        else {
            return haystack.toLocaleLowerCase().startsWith(needle.toLocaleLowerCase());
        }
    }
}
