import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { Domain } from '../../models/domain.model';

@Component({
    selector: 'domain-card',
    templateUrl: 'domain-card.component.html',
    styleUrls: ['domain-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainCardComponent implements OnChanges {
    @Input() public domain: Domain;
    @Input() public isAdded: boolean;
    @Input() public isLoading: boolean;

    @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() public onLearnMoreClick: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _cdRef: ChangeDetectorRef
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        this._cdRef.markForCheck();
    }

    /**
     * @method
     * @description
     * Notifies the parent that the domain import/delete button
     * was clicked.
     */
    public onDomainClick(): void {
        this.clicked.emit();
    }

    /**
     * @method
     * @description
     * Notifies the parent that the domain learn more link
     * was clicked.
     */
    public learnMoreClicked(): void {
        this.onLearnMoreClick.emit();
    }
}
