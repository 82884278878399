import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Intent } from '@luis/intents';
import { UtterancePattern } from '@luis/patterns';
import { Utterance } from '@luis/utterances';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
	selector: 'pattern-inspector',
	templateUrl: './pattern-inspector.component.html',
	styleUrls: ['./pattern-inspector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class PatternInspectorComponent implements OnInit {
	@Input() public intents: Observable<Intent[]>;
	@Input() public utterance: Utterance = new Utterance();
	@Input() public isEditable: boolean;

	public visiblePatterns: Observable<UtterancePattern[]>;
	public toggleAllPatterns: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public editPatternMode: boolean = false;

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Toggles whether to show all patterns or the top matched
	 * one only.
	 */
	public togglePatternsView(): void {
		this.toggleAllPatterns.next(!this.toggleAllPatterns.getValue());
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.visiblePatterns = this.toggleAllPatterns.asObservable().map(all => {
			if (!this.utterance.predictedPatterns.length) {
				return [];
			}

			return all ? this.utterance.predictedPatterns : [this.utterance.predictedPatterns[0]];
		});
	}
}
