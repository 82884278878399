import { Inject, Injectable, OnDestroy } from '@angular/core';
import { RequestOptionsBuilder } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	EventBusMessage,
	EventBusService,
	Id,
	IResourceCacheService,
	IToasterService,
	RESOURCE_CACHE_SERVICE_TOKEN,
	TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { IPatternService } from '../interfaces/IPatternService';
import { Pattern } from '../models/pattern.model';
import { UtterancePattern } from '../models/utterance-pattern.model';

/**
 * @description
 * Represents a concrete implementation of the IPatternService interface for pattern related
 * operations for a LUIS application. This implementation is meant for production use.
 */
@Injectable()
export class PatternService implements IPatternService, OnDestroy {
	private _domainAddedSubscription: Subscription = new Subscription();

	constructor(
		private readonly _baseService: BaseService,
		private readonly _i18n: TranslateService,
		private readonly _eventBus: EventBusService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(RESOURCE_CACHE_SERVICE_TOKEN) private readonly _resourceCacheService: IResourceCacheService
	) {
		this._subscribeToEventBus();
	}

	/**
	 * @description
	 * Gets the base web url for manipulating application's intents.
	 *
	 * @returns The base url for intents manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}`;
	}

	public ngOnDestroy(): void {
		this._domainAddedSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Gets the patterns of the application or a given intent.
	 *
	 * @returns An observable of the application's patterns.
	 */
	public get(refresh?: boolean): Observable<Pattern[]> {
		const url: string = `${this._baseUrl}/patternrules`;
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return <Observable<Pattern[]>>this._resourceCacheService.get(`${url}?take=500`, Pattern.importFromApi, builder.build(), url);
	}

	/**
	 * @description
	 * Adds a new pattern to the application.
	 *
	 * @param pattern The pattern to add.
	 * @param intentId The id of the intent to add the pattern to.
	 * @param refresh If true, the pattern is refresehd from the api after adding it.
	 * @returns An observable of the id of the newly added intent.
	 */
	public add(pattern: Pattern, refresh: boolean = false): Observable<Id | void> {
		const baseUrl: string = `${this._baseUrl}/patternrule`;
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return this._resourceCacheService
			.post(baseUrl, pattern, builder.build(), Pattern.importFromApi, `${this._baseUrl}/patternrules`, null, true)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	/**
	 * @description
	 * Updates the given pattern.
	 *
	 * @param pattern The pattern to update.
	 * @returns An observable to indicate completion.
	 */
	public update(pattern: Pattern): Observable<void> {
		const baseUrl: string = `${this._baseUrl}/patternrules`;

		return this._resourceCacheService
			.put(
				`${baseUrl}/${pattern.id}`,
				pattern,
				this._baseService.defaultOptionsBuilder.build(),
				baseUrl,
				null,
				Pattern.importFromApi,
				true
			)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	/**
	 * @description
	 * Updates existing patterns from the application.
	 *
	 * @param patterns The patterns to update.
	 * @returns An observable to indicate completion.
	 */
	public batchUpdate(patterns: Pattern[]): Observable<void> {
		const baseUrl: string = `${this._baseUrl}/patternrules`;

		return this._resourceCacheService
			.batchPut(baseUrl, patterns, this._baseService.defaultOptionsBuilder.build())
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	/**
	 * @description
	 * Deletes an existing pattern from the application.
	 *
	 * @param pattern The pattern to delete
	 * @param intentId The id of the intent to delete the pattern from.
	 * @returns An observable to indicate completion.
	 */
	public delete(pattern: Pattern): Observable<void> {
		const baseUrl: string = `${this._baseUrl}/patternrules`;

		return this._resourceCacheService
			.delete(`${baseUrl}/${pattern.id}`, pattern.id, this._baseService.defaultOptionsBuilder.build(), baseUrl)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	/**
	 * @description
	 * Deletes existing patterns from the application.
	 *
	 * @param patterns The patterns to delete.
	 * @returns An observable to indicate completion.
	 */
	public batchDelete(patterns: Pattern[]): Observable<Id[]> {
		const baseUrl: string = `${this._baseUrl}/patternrules`;

		return this._resourceCacheService
			.batchDelete(baseUrl, patterns.map(p => p.id), this._baseService.defaultOptionsBuilder.build(), true)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)));
	}

	public updateTestingUtterancePattern(utterance: UtterancePattern, intentName: string): void {
		this.get()
			.first()
			.map(patterns => patterns.find(p => p.text === utterance.patternName))
			.map(pattern => pattern.clone())
			.do(pattern => (pattern.intentName = intentName))
			.flatMap(pattern => this.update(pattern))
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('patterns.pattern-service.edit_pattern_toast') }))
			.subscribe();
	}

	/**
	 * Invalidates all the data of the patterns path in cache.
	 */
	private _invalidateCache(): void {
		const url: string = `${this._baseUrl}/patternrules`;

		this._resourceCacheService.invalidate(url);
	}

	/**
	 * @description
	 * Subscribes to event bus to react to certain bus events.
	 */
	private _subscribeToEventBus(): void {
		this._domainAddedSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.INVALIDATE_PATTERNS_CACHE, () => this._invalidateCache());
	}
}
