import { Pipe, PipeTransform } from '@angular/core';
import { SelectionMap } from '../models/utils/selection-map.model';

@Pipe({
	name: 'selectionUpdate'
})
export class SelectionUpdatePipe implements PipeTransform {
	public transform(data: any[], selectionMap: SelectionMap, keyParam: string): any[] {
		if (!data) {
			return data;
		}

		const keys: any[] = data.map(d => d[keyParam]);
		const selectedKeys = selectionMap.selectedItems;
		const keysNotFound = selectionMap.allItems.filter(s => keys.indexOf(s) === -1).length > 0;

		if (selectedKeys.length === 0 || keysNotFound) {
			selectionMap.setMapItems(keys);
		}

		return data;
	}
}
