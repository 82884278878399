import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentInstance, MeasureableComponent, PopupMeasurementService } from '../services/popup-measurement.service';

/**
 * Popup Measuring Component - Use this component to provide the dimensions of a popup,
 * particularly when another component needs to know the size of said popup for placement.
 * Usage: add the following 'invisible' component to the html page: (see app.component.html)
 * <popup-measurement-component></popup-measurement-component>
 * Required dependencies: AlignmentHostMarkerDirective and PopupMeasurementService
 */
@Component({
    selector: 'popup-measurement-component',
    template: `
    <div class="measurement-layer">
        <ng-container #alignmentHost></ng-container>
    </div>`
})

export class PopupMeasuringComponent implements OnDestroy {
    @ViewChild('alignmentHost', { read: ViewContainerRef }) public alignmentHost: ViewContainerRef;

    private _componentInstance: ComponentInstance;
    private _componentRef: ComponentRef<{}>;
    private _measureSubscription: Subscription;

    public measuring: boolean = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private popupMeasurementService: PopupMeasurementService
    ) {
        this._measureSubscription = this.popupMeasurementService.measureObservable.subscribe(this.measureChanged.bind(this));
        this.popupMeasurementService.registerMeasurementComponent(this);
    }

    public ngOnDestroy() {
        if (this._measureSubscription) {
            this._measureSubscription.unsubscribe();
            this._measureSubscription = null;
        }
    }

    private measureChanged(nextComponentInstance: ComponentInstance) {
        if (this._componentInstance) {
            return nextComponentInstance.reject(new Error('another component is being measured'));
        }

        this._componentInstance = nextComponentInstance;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(nextComponentInstance.component as any);

        this.alignmentHost.clear();

        const componentRef = this._componentRef = this.alignmentHost.createComponent(componentFactory);

        Object.keys(nextComponentInstance.props).forEach(name => {
            componentRef.instance[name] = nextComponentInstance.props[name];
        });

        this.changeDetector.detectChanges();

        this.measuring = true;
    }

    public ngAfterViewChecked(): void {
        if (this.measuring) {
            this.measuring = false;
            this.reportMeasurement();
        }
    }

    private reportMeasurement() {
        const size = (this._componentRef.instance as MeasureableComponent).measure();

        this._componentInstance.resolve(size);
        this._componentInstance = null;

        this.alignmentHost.clear();
    }
}
