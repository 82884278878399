import { InjectionToken } from '@angular/core';
import { Id } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { Intent } from '../models/intent.model';

export const INTENT_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IIntentService');

/**
 * @description
 * Represents an interface for an intent service. An intent service should contain
 * CRUD methods for LUIS intents.
 */
export interface IIntentService {
    get(refresh?: boolean): Observable<Intent[]>;

    add(intent: Intent, refresh?: boolean): Observable<Id | void>;

    update(intent: Intent): Observable<void>;

    delete(intent: Intent, keepUtterances?: boolean): Observable<void>;

    batchDelete(intents: Intent[], keepUtterances?: boolean): Observable<Id[]>;
}
