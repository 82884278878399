import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BaseModalComponent } from '../../components/base-modal/base-modal.component';
import { IModalComponent } from '../../interfaces/utils/IModalComponent';
import { PromptButtonTypes } from '../../services/utils/generic-prompt.service';

const PROMPT_BUTTON_TYPES: any = {
	Primary: 'm-button-primary-md',
	Danger: 'm-button-destructive-md',
	Success: 'm-button-green-md',
	Default: 'm-button-secondary-md',
	PrimaryOutline: 'm-button-primary-outline-md',
	DangerOutline: 'm-button-destructive-outline-md',
	SuccessOutline: 'm-button-green-outline-md',
	DefaultOutline: 'm-button-secondary-outline-md'
};

/**
 * @description
 * Represents the generic, pop-out modal prompt window that will be
 * displayed like an alert. Used primarily by the GenericPromptService.
 */
@Component({
	selector: 'prompt-panel',
	templateUrl: './prompt-panel.component.html',
	styleUrls: ['./prompt-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptPanelComponent extends BaseModalComponent implements IModalComponent<string>, OnInit {
	@Input() public title: string;
	@Input() public contentText: string;
	@Input() public buttons: { [value: string]: string };
	@Input() public classes: { [value: string]: string };

	public arrayOfButtonKeys: any;
	public buttonTypes: typeof PromptButtonTypes = PromptButtonTypes;

	public ngOnInit(): void {
		super.ngOnInit();
		this.arrayOfButtonKeys = Object.keys(this.buttons);
	}

	/**
	 * @description
	 * Returns the key value associated with the button that
	 * was clicked.
	 *
	 * @param value The string key value associated with the button.
	 */
	public onClick(event: KeyboardEvent, value: string): void {
		event.stopPropagation();
		this.response.next(value);
		this.response.complete();
	}

	/**
	 * @description
	 * Closes the propmt.
	 */
	public onCancelClick(): void {
		this.response.next(null);
		this.response.complete();
	}

	/**
	 * @description
	 * Takes a button's key and uses it to return
	 * the corresponding CSS class for its type.
	 *
	 * @param key The button key used to look up the button's class and type.
	 * @returns The corresponding button class name.
	 */
	public fetchButtonClass(key: any): string {
		return PROMPT_BUTTON_TYPES[this.buttonTypes[this.classes[key]]];
	}

	/**
	 * @description
	 * Returns the CSS class corresponding to
	 * the default button type.
	 *
	 * @returns The default button class name.
	 */
	public fetchDefaultClass(): string {
		return PROMPT_BUTTON_TYPES[this.buttonTypes[this.buttonTypes.Default]];
	}
}
