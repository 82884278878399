import { Pipe, PipeTransform } from '@angular/core';
import { IToolBarOps } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { PhraseList } from '../models/phrase-list.model';

/**
 * @description
 */
@Pipe({
    name: 'selectedPhraseListsModeAsync'
})
export class SelectedPhraseListsModePipe implements PipeTransform {

    /**
     * @description
     * Checks the state of the selected phrase lists to manage the table toolbar view.
     *
     * @param toolBarOps The toolbar options.
     * @param phraseLists The list of all phrase lists.
     * @param selectedPhraseListsIds The ids of the selected phrase lists.
     * @param mode The flag to distinguish between the active and deactive buttons
     * where a value of true means that the phraselist will be activated and false
     * is vice versa.
     * @returns A boolean to indicate whether the command bar button is visible or not.
     */
    public transform(
        toolBarOps: Observable<IToolBarOps>, phraseLists: Observable<PhraseList[]>,
        selectedPhraseListsIds: Observable<number[]>, mode: boolean
    ): Observable<boolean> {
        return Observable.combineLatest(toolBarOps, phraseLists, selectedPhraseListsIds)
            .map(([ops, lists, selectedLists]) => {
                const activePhraseListsCnt = lists
                    .filter(phraseList => selectedLists.indexOf(phraseList.id) !== -1)
                    .filter(selectedPhraseLists => selectedPhraseLists.isActive)
                    .length;

                const inactivePhraseListsCnt = selectedLists.length - activePhraseListsCnt;

                return (ops.single || ops.batch) && ((mode && inactivePhraseListsCnt > 0) || (!mode && activePhraseListsCnt > 0));
            });
    }
}
