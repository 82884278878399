import { Injectable } from '@angular/core';
import { IDictionary, IDictionaryStorageService } from '../../interfaces/utils/IDictionaryStorageService';

@Injectable()
export class DictionaryLocalStorageService implements IDictionaryStorageService {
	/**
	 * @description
	 * Gets the dictionary stored in the local storage with the given
	 * storage key.
	 *
	 * @param storageKey The key to use to fetch the dictionary from storage.
	 * @returns The stored dictionary.
	 */
	public getDictionary<T>(storageKey: string): IDictionary<T> | undefined {
		const dictionaryString = window.localStorage.getItem(storageKey);

		if (!dictionaryString) {
			return undefined;
		}

		return JSON.parse(dictionaryString);
	}

	/**
	 * @description
	 * Sets the given dictionary object in place of the storage key that
	 * was given.
	 *
	 * @param storageKey The storage key to use to store the dictionary.
	 * @param dictionary The dictionary to store.
	 */
	public setDictionary<T>(storageKey: string, dictionary: IDictionary<T>): void {
		window.localStorage.setItem(storageKey, JSON.stringify(dictionary));
	}
}
