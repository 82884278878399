import { IEntityMenuEvent } from './IEntityMenuEvent';

export enum ENTITY_DROPDOWN_OPERATIONS {
    EXISTING_ENTITY,
    NEW_ENTITY,
    DELETE_ENTITY,
    PREBUILT_ENTITY,
    EDIT_ENTITY,
    LABEL_COMPOSITE_WITH_ROLE
}

export interface IEntityDropdownOperationPayload {
    operation: ENTITY_DROPDOWN_OPERATIONS;
    event?: IEntityMenuEvent;
}
