/**
 * @description
 * An enumeration of the operations that you can apply on an app.
 */
export enum APP_OPERATIONS {
    ADD,
    IMPORT,
    IMPORT_LOGS,
    RENAME,
    EXPORT,
    EXPORT_LOGS,
    EXPORT_CONTAINER_PRODUCTION,
    EXPORT_CONTAINER_STAGING,
    DELETE
}
