import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, OnInit } from '@angular/core';
import {
	BaseModalComponent,
	BaseService,
	GENERIC_CACHE_SERVICE_TOKEN,
	IGenericCacheService,
	IModalComponent,
	IUrlSettings,
	LuisConstants
} from '@luis/core';
import { EndpointKey, IKeyService, KEY_SERVICE_TOKEN } from '@luis/publish-pane';
import { Observable, Subject } from 'rxjs/Rx';
import { IEndpointDebugSettings } from '../../../../interfaces/IEndpointDebugSettings';
import { MatDialogRef } from '@angular/material';

/**
 * @description
 * Represents the modal for changing the endpoint test
 * pane settings.
 */
@Component({
	selector: 'settings-modal',
	templateUrl: 'settings-modal.component.html',
	styleUrls: ['settings-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsModalComponent implements OnInit {
	public keys: Observable<EndpointKey[]>;
	public settings: IUrlSettings = {};
	public key: EndpointKey;
	public bingKey: string;

	constructor(
		private readonly _baseService: BaseService,
		private readonly _dialogRef: MatDialogRef<SettingsModalComponent>,
		@Inject(KEY_SERVICE_TOKEN) private readonly _keyService: IKeyService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Saves the current settings.
	 */
	public save(): void {
		const settings: IEndpointDebugSettings = {
			settings: this.settings,
			key: this.key,
			bingKey: this.bingKey ? this.bingKey.trim() : ''
		};

		this._cacheService.put(LuisConstants.DEBUG_SETTINGS_KEY, settings, this._baseService.optionsBuilder.useCacheOnly().build());
		this._dialogRef.close();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._cacheService
			.get(LuisConstants.DEBUG_SETTINGS_KEY, null, this._baseService.optionsBuilder.useCacheOnly().build())
			.first()
			.filter(settings => settings !== undefined)
			.subscribe((settings: IEndpointDebugSettings) => {
				this.key = settings.key;
				this.settings = settings.settings;
			});

		this.keys = this._keyService.getAppKeys();
	}
}
