import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    HostListener,
    Input,
    Output,
    QueryList
} from '@angular/core';
import { ESCAPE, FocusKeyManager, LEFT_ARROW } from '@luis/ui';
import { BaseItemComponent } from '../../items/base-item/base-item.component';

@Component({
    selector: 'items-container',
    templateUrl: './items-container.component.html',
    styleUrls: ['./items-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsContainerComponent implements AfterContentInit {
    @Input() public hasMaxHeight: boolean = true;
    @Input() public autoFocus: boolean = true;
    @Input() public trapEvents: boolean = true;
    @Input() public allowInnerClose: boolean = true;

    @Output() public requestClose: EventEmitter<void> = new EventEmitter<void>();

    @ContentChildren(BaseItemComponent, { descendants: true }) public items: QueryList<BaseItemComponent>;

    private _keyManager: FocusKeyManager;

    public ngAfterContentInit(): void {
        this._keyManager = new FocusKeyManager(this.items).withWrap();
        this._keyManager.tabOut.first().subscribe(() => this.requestClose.emit());

        if (this.autoFocus) {
            setTimeout(() => this._keyManager.setFirstItemActive(), 0);
        }
    }

    /**
     * @description
     * When the container is focused, the first item of the container should
     * automatically be focused instead.
     */
    public focus(): void {
        this._keyManager.setFirstItemActive();
    }

    /**
     * @description
     * Handles the keydown for accessbility for this component.
     */
    @HostListener('keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent): void {
        if (this.trapEvents) {
            event.stopPropagation();
        }

        switch (event.keyCode) {
            case LEFT_ARROW:
            case ESCAPE:
                if (this.allowInnerClose) {
                    this.requestClose.emit();
                }
                break;
            default:
                if (this._keyManager) {
                    this._keyManager.lastKeyPressed = event.keyCode;
                    this._keyManager.onKeydown(event);
                    break;
                }
        }
    }
}
