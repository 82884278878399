import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ProgressTracker } from '@luis/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IPhraseListUtilitiesService, PHRASE_LIST_UTILITIES_SERVICE_TOKEN } from '../../../../interfaces/IPhraseListUtilitiesService';
import { PhraseList } from '../../../../models/phrase-list.model';
import { Suggestion, SuggestionApiParameter } from '../../../../models/suggestion.model';

/**
 * @description
 * Contains functioniality to build phrases for a phrase list feature
 * either by using suggestions or without.
 */
@Component({
	selector: 'phrase-builder',
	templateUrl: 'phrase-builder.component.html',
	styleUrls: ['phrase-builder.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhraseBuilderComponent implements OnInit {
	@Input() public phraseList: PhraseList;
	@Input() public showSuggestions: boolean;
	@Output() public enterClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public previousSuggestion: EventEmitter<Suggestion> = new EventEmitter<Suggestion>();

	public buffer: string;
	public phrases: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	public suggestions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	public suggestionTrigger: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public suggestionsTracker: ProgressTracker = new ProgressTracker();

	private readonly _previousSuggestion: BehaviorSubject<Suggestion> = new BehaviorSubject<Suggestion>(null);
	private readonly _triggerAt: number = 2;

	constructor(@Inject(PHRASE_LIST_UTILITIES_SERVICE_TOKEN) private readonly _phraseListUtilService: IPhraseListUtilitiesService) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Gets the value of the input buffer for this builder. The input buffer differs based on
	 * whether there are suggestions showed or not. If there are suggestions, then the input buffer
	 * stores the text field values in a temporary buffer variable that we later on append to the
	 * phrases stream that are displayed in the pill container. That is, that buffer refernce would not
	 * contain the final phrase values. If suggestions are not shown, then the input buffer references
	 * the actual phrase list phrases, the final values.
	 */
	get inputBuffer(): string {
		return this.showSuggestions ? this.buffer : this.phraseList.phrases;
	}

	/**
	 * @description
	 * Adds text to the buffer, either by placing the text in the temporary buffer
	 * or in the phrase list phrases. Checks if the phrases are valid when the text
	 * is set. The distiniction is explained in the get inputBuffer method docs.
	 *
	 * @param text The text to set.
	 */
	set inputBuffer(text: string) {
		if (this.showSuggestions) {
			this.buffer = text;
		} else {
			this.phraseList.phrases = text;
		}

		this.isValid.emit(this._validate());
	}

	/**
	 * @description
	 * If suggestions are shown, then the buffer text is split on commas and transferred onto
	 * the phrases stream and into the phrase list phrases. If no suggestions are shown,
	 * notifies the parent that Enter was clicked to submit the phrase list.
	 */
	public submitBuffer(): void {
		if (this.showSuggestions) {
			const phrases: string[] = this._splitCommaSeparatedString(this.buffer);
			let curr: string[] = this.phrases.getValue();
			curr = curr.concat(...phrases);
			this.phrases.next(curr);
			this.phraseList.phrases = curr.join(',');
			this.buffer = '';
			this._checkSuggestionTrigger(phrases.length);
		} else {
			this.enterClicked.emit();
		}

		this.isValid.emit(this._validate());
	}

	/**
	 * @description
	 * Removes a phrase from the tokenized phrases pills. Updates
	 * the phrase list phrases.
	 *
	 * @param phrase The phrase to remove.
	 */
	public removePhrase(phrase: string): void {
		const curr: string[] = this.phrases.getValue().filter(t => t !== phrase);
		this.phrases.next(curr);
		this.phraseList.phrases = curr.join(',');
	}

	/**
	 * @description
	 * Gets suggestions for the given values. If no values are present, the function
	 * does nothing.
	 */
	public getSuggestions(): void {
		const id: number = this.phraseList.id == null ? undefined : this.phraseList.id;

		if (this.phrases.getValue().length > 0) {
			this._previousSuggestion
				.asObservable()
				.flatMap(prevSuggestion =>
					prevSuggestion ? Observable.of(prevSuggestion) : this._phraseListUtilService.getPreSuggestions(id)
				)
				.do(prevSuggestion => this.previousSuggestion.emit(prevSuggestion))
				.flatMap(prevSuggestion =>
					this._phraseListUtilService.getSuggestions(id, new SuggestionApiParameter(this.phrases.getValue(), 10, prevSuggestion))
				)
				.first()
				.trackProgress(this.suggestionsTracker.getTracker())
				.subscribe(suggestion => {
					if (suggestion) {
						this._previousSuggestion.next(suggestion);
						this.suggestions.next(suggestion.entries);
					}
				});
		}
	}

	/**
	 * @description
	 * Adds a suggestion to the phrase list phrases and the phrases stream.
	 *
	 * @param suggestion The suggestion to add.
	 */
	public addSuggestion(suggestion: string): void {
		const currPhrases: string[] = this.phrases.getValue();
		currPhrases.push(suggestion);
		this.phrases.next(currPhrases);
		this.phraseList.phrases = currPhrases.join(',');
	}

	/**
	 * @description
	 * Adds all the suggestions available to the current phrases stream
	 * and the phrase list phrases.
	 */
	public addAllSuggestions(): void {
		const currSuggestions: string[] = this.suggestions.getValue();
		const currPhrases: string[] = this.phrases.getValue();
		currPhrases.push(...currSuggestions);
		this.phrases.next(currPhrases);
		this.phraseList.phrases = currPhrases.join(',');
		this.suggestions.next([]);
		this.getSuggestions();
	}

	/**
	 * @description
	 * Initializes the component state.
	 */
	private _initState(): void {
		this.isValid.emit(this._validate());
		this.suggestionTrigger.subscribe(count => {
			if (count > this._triggerAt) {
				this.getSuggestions();
				this.suggestionTrigger.next(0);
			}
		});

		if (this.showSuggestions) {
			this.phrases.next(this._splitCommaSeparatedString(this.phraseList.phrases));
			this.getSuggestions();
		}
	}

	/**
	 * @description
	 * Checks that the phrase list phrases are valid.
	 */
	private _validate(): boolean {
		return this.phraseList.phrases.trim().length > 0;
	}

	/**
	 * @description
	 * Splits the string given on commas. Ensures that there are no empty
	 * or whitespaced string tokens.
	 *
	 * @param text The text to split.
	 * @returns A string array of the comma separated tokens.
	 */
	private _splitCommaSeparatedString(text: string): string[] {
		return text
			.split(',')
			.map(t => t.trim())
			.filter(t => t.length);
	}

	/**
	 * @description
	 * Updates the value count increase tracker stream. This value triggers suggestion
	 * fetching if it more than the defined 'triggerAt' value.
	 *
	 * @param valueIncrease The value to increase the trigger tracker stream by.
	 */
	private _checkSuggestionTrigger(valueIncrease: number): void {
		let currentTriggerVal: number = this.suggestionTrigger.getValue();
		currentTriggerVal += valueIncrease;
		this.suggestionTrigger.next(currentTriggerVal);
	}
}
