import { ILinkable, IResource } from '@luis/core';
import { Entity, ENTITY_TYPES, EntityRole } from './entity.model';

/**
 * @description
 * Represents a pattern.any entity type. Used only in patterns.
 *
 * @param whiteList The white lists for this entity.
 */
export class PatternAnyEntity extends Entity {
    public whiteList: WhiteListItem[];

    constructor(
        id: string = '', name: string = '',
        type: number = ENTITY_TYPES.PATTERN_ANY, whiteList: WhiteListItem[] = [],
        roles: EntityRole[] = [], parent: Entity = null
    ) {
        super(id, name, type, null, roles, parent);
        this.whiteList = whiteList;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): PatternAnyEntity {
        const patternEntity: PatternAnyEntity = <PatternAnyEntity>Entity.constructBaseEntity(apiObject, new PatternAnyEntity());
        patternEntity.whiteList = apiObject.explicitList ? (<any[]>apiObject.explicitList).map(e => WhiteListItem.importFromApi(e)) : [];
        patternEntity.whiteList.forEach(w => w.setParent(patternEntity));
        patternEntity.labelCount = null;

        return patternEntity;
    }

    /**
     * @method
     * @description
     * Adds a new value to the white lists values.
     */
    public appendItem(): void {
        const item: WhiteListItem = new WhiteListItem();
        item.setParent(this);
        this.whiteList.push(item);
    }

    /**
     * @method
     * @description
     * Removes the item at the given index.
     *
     * @param index The index of the item to remove.
     */
    public removeItem(index: number): void {
        this.whiteList.splice(index, 1);
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            id: this.id,
            name: this.name,
            explicitList: this.whiteList.map(w => w.exportToApi()),
            roles: this.roles.map(r => r.exportToApi())
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @param entityParent The parent to use instead of the object's current parent
     * in case the parent is cloned so child references the new parent.
     * @returns A deep clone of this object.
     */
    public clone(entityParent?: Entity): PatternAnyEntity {
        const patternEntity: PatternAnyEntity = <PatternAnyEntity>Entity.cloneBaseEntity(this, new PatternAnyEntity(), entityParent);
        patternEntity.whiteList = this.whiteList.map(w => w.clone(patternEntity));

        return patternEntity;
    }

    /**
     * @method
     * @description
     * Checks if the current entity is valid or not.
     *
     * @returns True if the entity is valid and false otherwise.
     */
    public isValid(): boolean {
        const whiteListsValid: boolean = this.whiteList.find(w => w.value.trim().length === 0) === undefined;

        return super.isValid() && whiteListsValid;
    }
}

/**
 * @description
 * Represents a whitelist item. In reality the API does
 * not have a corresponding id. The id is used to manipulate
 * the whitelists in the frontend.
 *
 * @param id The (virtual) id of the white list value
 * @param value The value of the whitelist item.
 * @param type The type of the white list item.
 * @param parent The parent of the white list item.
 */
export class WhiteListItem implements IResource, ILinkable {
    public id: string;
    public value: string;
    public type: number;
    public parent: PatternAnyEntity;

    constructor(id: string = '', value: string = '', type: number = ENTITY_TYPES.WHITELIST_ITEM, parent: PatternAnyEntity = null) {
        this.id = id;
        this.value = value;
        this.parent = parent;
        this.type = type;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): WhiteListItem {
        return new WhiteListItem(apiObject.id, apiObject.explicitListItem);
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            explicitListItem: this.value.trim()
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @param parent The parent to use instead of the object's current parent
     * in case the parent is cloned so child references the new parent.
     * @returns A deep clone of this object.
     */
    public clone(parent?: PatternAnyEntity): WhiteListItem {
        return new WhiteListItem(
            this.id,
            this.value.slice(),
            this.type,
            parent ? parent : this.parent
        );
    }

    /**
     * @method
     * @description
     * Sets this value's parent to the passed argument value.
     *
     * @param parentEntity The parent entity to assign this entry to.
     */
    public setParent(parentEntity: PatternAnyEntity): void {
        this.parent = parentEntity;
    }
}
