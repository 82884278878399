import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FILTER_OPTIONS } from '../models/filter.model';

export const ANALYTICS_UI_STATE_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('AnalyticsUIStatusService');

/**
 * @description
 * The DTO that keeps the state of the cards in the analytics page.
 */
export type CardsUIState = {
	overviewCard: boolean;
	predictionsPerIntentCard: boolean;
	detailedIntentViewCard: boolean;
};

export enum CARDS {
	OVERALL,
	INTENT_PREDICTIONS,
	INTENT_DETAILED_VIEW
}

/**
 * @description
 * Represents a concrete implementation for keeping the UI state for the analytics page.
 */
@Injectable()
export class AnalyticsUIStatusService {
	private _lastUsedCard: number = -1;
	private _filterRatio: string = '';

	private readonly _cardsState: BehaviorSubject<CardsUIState> = new BehaviorSubject<CardsUIState>({
		overviewCard: true,
		predictionsPerIntentCard: false,
		detailedIntentViewCard: false
	});
	private readonly _appliedFilter: BehaviorSubject<number> = new BehaviorSubject<number>(FILTER_OPTIONS.NONE);
	private readonly _selectedIntentIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	public getCardsStates(): CardsUIState {
		return this._cardsState.getValue();
	}

	public getCardsStatesAsync(): Observable<CardsUIState> {
		return this._cardsState.asObservable();
	}

	public toggleOverviewCard(): void {
		this._lastUsedCard = CARDS.OVERALL;
		const cards = this._cardsState.getValue();
		this._cardsState.next({
			...cards,
			overviewCard: !cards.overviewCard
		});
	}

	public togglePredictionsPerIntentCard(): void {
		this._lastUsedCard = CARDS.INTENT_PREDICTIONS;
		const cards = this._cardsState.getValue();
		this._cardsState.next({
			...cards,
			predictionsPerIntentCard: !cards.predictionsPerIntentCard
		});
	}

	public toggleDetailedIntentViewCard(): void {
		this._lastUsedCard = CARDS.INTENT_DETAILED_VIEW;
		const cards = this._cardsState.getValue();
		this._cardsState.next({
			...cards,
			detailedIntentViewCard: !cards.detailedIntentViewCard
		});
	}

	public get lastUsedCard(): number {
		return this._lastUsedCard;
	}

	public set lastUsedCard(card: number) {
		this._lastUsedCard = card;
	}

	public setAppliedFilter(filter: number): void {
		this._appliedFilter.next(filter);
	}

	public set filterRatio(ratio: string) {
		this._lastUsedCard = CARDS.INTENT_DETAILED_VIEW;
		this._filterRatio = ratio;
	}

	public get filterRatio(): string {
		return this._filterRatio;
	}

	public set selectedIndex(i: number) {
		this._lastUsedCard = CARDS.INTENT_DETAILED_VIEW;
		this._selectedIntentIndex.next(i);
	}

	public get selectedIntentIndex(): BehaviorSubject<number> {
		return this._selectedIntentIndex;
	}

	public get appliedFilter(): BehaviorSubject<number> {
		return this._appliedFilter;
	}
}
