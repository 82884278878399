import { Inject, Injectable } from '@angular/core';
import { GenericPromptService, LuisModel, PromptButtonTypes, ProgressTracker } from '@luis/core';
import { Entity } from '@luis/entities';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { IUtteranceService, UTTERANCE_SERVICE_TOKEN } from '../interfaces/services/IUtteranceService';
import { Utterance } from '../models/utterance.model';

/**
 * @description
 * A dto object that attaches metdata to the suggested utterance.
 */
export interface ISuggestUtteranceDto {
	utterance: Utterance;

	isTopLevel: boolean;

	children: number[];
}

@Injectable()
export class SuggestUtterancesService {
	private readonly _normalizedUtterances: BehaviorSubject<Map<number, ISuggestUtteranceDto>> = new BehaviorSubject(undefined);

	private _idIterator: number = 0;

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _promptService: GenericPromptService,
		@Inject(UTTERANCE_SERVICE_TOKEN) private readonly _utteranceService: IUtteranceService
	) {}

	/**
	 * @description
	 * Gets the suggested utterance for the given model. Stores the utterances in the normalized
	 * store so that deletions and updates can be easily executed.
	 *
	 * @param model The model to get the utterances for.
	 * @returns Observable of the normalized map of utterance id and utterance dto objects.
	 */
	public getSuggestedUtterances(
		model: LuisModel,
		tracker: ProgressTracker,
		roleId?: string
	): Observable<Map<number, ISuggestUtteranceDto>> {
		this._idIterator = 0;

		this._utteranceService
			.suggest(model, roleId)
			.pipe(
				map(utterances => this._preprocessUtterances(utterances)),
				map(utterances => this._normalizeUtterances(utterances, true))
			)
			.trackProgress(tracker.getTracker())
			.subscribe(normalizedMap => this._normalizedUtterances.next(normalizedMap));

		return this._normalizedUtterances.asObservable().pipe(filter(normalizedMap => normalizedMap !== undefined));
	}

	/**
	 * @description
	 * Gets the children utterances for the given utterance id from the normalized map.
	 *
	 * @param id The id of the utterance to get the children for.
	 * @returns An observable of an array of the utterance dtos.
	 */
	public getUtteranceChildren(id: number): Observable<ISuggestUtteranceDto[]> {
		return this._normalizedUtterances.asObservable().pipe(
			map(normalizedMap => {
				const parent = normalizedMap.get(id);

				if (!parent) {
					return [];
				}

				return parent.children.map(childId => normalizedMap.get(childId)).filter(child => child !== undefined);
			})
		);
	}

	/**
	 * @description
	 * Adds the utterance given to the app's labeled examples. It then removes
	 * the utterance from the normalized map.
	 *
	 * @param utteranceDto The utterance to add to the labeled utterances.
	 */
	public addUtterance(utteranceDto: ISuggestUtteranceDto): void {
		this._utteranceService.add(utteranceDto.utterance.clone()).subscribe(() => this._removeUtteranceFromMap(utteranceDto.utterance.id));
	}

	/**
	 * @description
	 * Updates the utterance data in the normalized map. Utterance data includes the intent
	 * to assign the utterance to and the labeled entities to add.
	 *
	 * @param utteranceDto The utterance dto object to update.
	 */
	public updateUtterance(utteranceDto: ISuggestUtteranceDto): void {
		const normalizedMap = this._normalizedUtterances.getValue();
		normalizedMap.set(utteranceDto.utterance.id, utteranceDto);
		this._normalizedUtterances.next(normalizedMap);
	}

	/**
	 * @description
	 * Deletes the given utterance data from the normalized map.
	 *
	 * @param utteranceDto The utterance dto to delete.
	 */
	public deleteUtterance(utteranceDto: ISuggestUtteranceDto): void {
		this._promptService
			.prompt(
				this._i18n.instant('utterances.suggest-table.delete_utterance'),
				this._i18n.instant('utterances.suggest-table.are_you_want_to_delete_utterance'),
				{ ok: this._i18n.instant('utterances.suggest-table.ok'), cancel: this._i18n.instant('utterances.suggest-table.cancel') },
				{ ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
			)
			.pipe(
				filter(choice => choice === 'ok'),
				flatMap(() => (utteranceDto.isTopLevel ? this._utteranceService.deleteSuggest([utteranceDto.utterance]) : of(undefined)))
			)
			.subscribe(() => this._removeUtteranceFromMap(utteranceDto.utterance.id));
	}

	/**
	 * @description
	 * Preprocesses the array of utterances as follows:
	 * - Assign a nonce id to each utterance, as suggested utterances don't come with ids.
	 * - Assign the top predicted intent as the labeled intent of the utterance, as this is
	 * most likely what the user will want.
	 * - Assign the predicted entities that are of machine learned type as labeled entities
	 * to the utterance. That ensures that when the utterance is added as a label, these
	 * entities will be included with the call.
	 *
	 * @param utterances The utterances to pre process.
	 * @returns An array of utterances with their ids and labels set.
	 */
	private _preprocessUtterances(utterances: Utterance[]): Utterance[] {
		utterances.forEach(u => {
			u.id = this._idIterator;
			this._idIterator = this._idIterator + 1;

			u.labeledIntent = u.predictedIntents[0].clone();
			u.labeledEntities = u.predictedEntities.filter(e => Entity.isMachineLearned(e.type));
			u.labeledEntities = u.labeledEntities.map(e => e.clone());
			u.multiIntentUtterances = this._preprocessUtterances(u.multiIntentUtterances);
		});

		return utterances;
	}

	/**
	 * @description
	 * Normalizes the utterance by flattening any utterance/child utterance relations and
	 * adding them into one map with the utterance ids.
	 *
	 * @param utterances The utterances to normalize.
	 * @param isTopLevel A flag indicaing whether the utterances area provided contains the
	 * "root" utterances or are they children of other root utterances.
	 * @returns Returns a map of the utterance id and utterance dtos.
	 */
	private _normalizeUtterances(utterances: Utterance[], isTopLevel: boolean): Map<number, ISuggestUtteranceDto> {
		let normalizedUtterances = new Map<number, ISuggestUtteranceDto>();

		utterances.forEach(u => {
			normalizedUtterances.set(u.id, {
				isTopLevel,
				utterance: u,
				children: u.multiIntentUtterances.map(m => m.id)
			});
			normalizedUtterances = new Map([...normalizedUtterances, ...this._normalizeUtterances(u.multiIntentUtterances, false)]);
		});

		return normalizedUtterances;
	}

	/**
	 * @description
	 * Removes the utterance from the normalized map.
	 *
	 * @param id The id of the utterance to remove.
	 */
	private _removeUtteranceFromMap(id: number): void {
		const normalizedMap = this._normalizedUtterances.getValue();
		normalizedMap.delete(id);
		this._normalizedUtterances.next(normalizedMap);
	}
}
