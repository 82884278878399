import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, LuisConstants } from '@luis/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs/Rx';
import { IKeyHelpersService, KEY_HELPERS_SERVICE_TOKEN } from '../interfaces/IKeyHelpersService';
import { IKeyService } from '../interfaces/IKeyService';
import { IKeyStoreService, KEY_STORE_SERVICE_TOKEN } from '../interfaces/IKeyStoreService';
import { EndpointKey } from '../models/azure-key.model';

@Injectable()
export class KeyService implements IKeyService {
	private _keys: EndpointKey[];
	private readonly _keysSubject: BehaviorSubject<EndpointKey[]> = new BehaviorSubject<EndpointKey[]>(null);

	constructor(
		private readonly _baseService: BaseService,
		@Inject(KEY_STORE_SERVICE_TOKEN) private readonly _keyStoreService: IKeyStoreService,
		@Inject(KEY_HELPERS_SERVICE_TOKEN) private readonly _keyHelpersService: IKeyHelpersService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		setTimeout(() => {
			this._eagerLoadKeys();
		}, 200);
	}

	/**
	 * @description
	 * Gets the assigned keys to this application. For each key, tries to get the original
	 * key for it from the Azure ARM Apis, and if the key path is not present, then fallbacks
	 * to filling the information of the key endpoint url from the regions/endpoints map.
	 *
	 * @param includeNulledKeys A flag to either include nulled keys (keys which the user does
	 * not have permission to access) or not. The default is that they are not included.
	 * @returns An observable of the endpoint keys fetched.
	 */
	public getAppKeys(): Observable<EndpointKey[]> {
		const progKey = new EndpointKey(
			'',
			LuisConstants.PROGRAMMATIC_KEY_ID,
			LuisConstants.PROGRAMMATIC_KEY_NAME,
			this._keyHelpersService.getDomainInfo().region,
			'LUIS',
			'',
			new Date(),
			'',
			{ key1: this._baseService.configs.userSubKey, key2: '' }
		);

		return this._keysSubject
			.asObservable()
			.filter(keys => keys !== null)
			.flatMap(keys => (keys.length ? Observable.combineLatest(keys.map(k => this._matchWithAzureKey(k))) : Observable.of([])))
			.map(keys => keys.filter(k => k))
			.map(foundKeys => [progKey].concat(foundKeys));
	}

	/**
	 * @description
	 * Gets the keys that the user has no access to.
	 *
	 * @returns An observable of an array of keys the user
	 * has no access to see.
	 */
	public getKeysWithNoAccess(): Observable<EndpointKey[]> {
		return this._keysSubject
			.asObservable()
			.filter(keys => keys !== null)
			.map(keys => keys.filter(k => k.keyPath !== null && k.keyPath !== LuisConstants.USER_ASSIGNED_KEYPATH))
			.flatMap(keys => {
				if (!keys.length) {
					return Observable.of([]);
				}

				// I am interested to return the keys that are not found in the keysWitNulls array
				// as that means they were not found in Azure.
				return Observable.combineLatest(keys.map(k => this._matchWithAzureKey(k))).map(keysWithNulls =>
					keys.reduce((acc, key) => (keysWithNulls.find(k => k && k.id === key.id) ? acc : [...acc, key]), [])
				);
			});
	}

	/**
	 * @description
	 * Assigns the given key to the current app.
	 *
	 * @param key The key to assign.
	 * @returns An observable to indicate completion.
	 */
	public assignKeyToApp(key: EndpointKey): Observable<any> {
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/subscriptions`;

		return this._httpService
			.post(url, key.exportToApi(), this._baseService.defaultOptionsBuilder.build())
			.map(() => this._keys.findIndex(k => this._doKeysMatch(k, key)))
			.do(keyIndex => (keyIndex === -1 ? this._keys.unshift(key) : (this._keys[keyIndex] = key)))
			.do(() => this._keysSubject.next(this._keys));
	}

	/**
	 * @description
	 * Unassigns the given key from the current app.
	 *
	 * @param key The key to unassign.
	 * @returns An observable to indicate completion.
	 */
	public unassignKeysFromApp(keys: EndpointKey[]): Observable<any> {
		let callIterator: number = 0;
		let callFinishCount: number = 0;
		let intervalHandle: number;

		return Observable.create((observer: Observer<EndpointKey[]>) => {
			const deleteItem: Function = () => {
				const key: EndpointKey = keys[callIterator];
				const requestPath: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/subscriptions/${
					key.id
				}`;

				this._httpService
					.delete(requestPath, this._baseService.defaultOptionsBuilder.build())
					.map(() => this._keys.findIndex(k => this._doKeysMatch(k, key)))
					.map(index => this._keys.splice(index, 1))
					.do(() => this._keysSubject.next(this._keys))
					.subscribe(
						u => {
							callFinishCount = callFinishCount + 1;
							if (callFinishCount === keys.length) {
								observer.next(keys);
								observer.complete();
							}
						},
						error => callFinishCount
					);

				callIterator = callIterator + 1;
				if (callIterator === keys.length) {
					clearInterval(intervalHandle);
				}
			};

			intervalHandle = window.setInterval(deleteItem, 300);
		});
	}

	/**
	 * @description
	 * Eager loads all the keys assigned to an application only when
	 * ensured that the app id is actually loaded.
	 */
	private _eagerLoadKeys(): void {
		this._baseService.configsAsync
			.filter(configs => configs.appId !== '')
			.first()
			.subscribe(() => {
				const url: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/subscriptions`;
				const parser: (data: Object[]) => EndpointKey[] = (data: Object[]) =>
					data.map(d => EndpointKey.importFromAssignedKeysApi(d));

				this._httpService
					.get(url, this._baseService.defaultOptionsBuilder.build())
					.map(parser)
					.catch(() => Observable.of([]))
					.do(keys => (this._keys = keys))
					.subscribe(keys => this._keysSubject.next(keys));
			});
	}

	/**
	 * @description
	 * Checks if these keys are the same by trying to match their data.
	 *
	 * @param a The first key to match
	 * @param b The second key to match
	 * @returns True if matched and false otherwise.
	 */
	private _doKeysMatch(a: EndpointKey, b: EndpointKey): boolean {
		if (b.id !== '' && a.id === b.id) {
			return true;
		}
		if (b.keyStrings.key1 !== '' && a.keyStrings.key1 === b.keyStrings.key1) {
			return true;
		}

		return false;
	}

	/**
	 * @description
	 * Searches for a key in the key store of Azure keys.
	 *
	 * @param originalKey The key to search for.
	 * @returns An observable of the endpoint key found or
	 * null if nothing was found.
	 */
	private _matchWithAzureKey(originalKey: EndpointKey): Observable<EndpointKey> {
		return this._keyStoreService.getKeyById(originalKey.id).map(matchedKey => (matchedKey ? matchedKey : null));
	}
}
