/**
 * @description
 * Represents the Microsoft compliance consent object that gets
 * fetched via the Microsoft banner.
 */
export class Consent {
	public isConsentRequired: boolean;
	public cookieName: string;
	public consentDate: Date;
	public markup: string;
	public js: string;
	public css: string;

	constructor(
		isConsentRequired: boolean = false,
		cookieName: string = '',
		consentDate: Date = new Date(),
		markup: string = '',
		js: string = '',
		css: string = ''
	) {
		this.isConsentRequired = isConsentRequired;
		this.cookieName = cookieName;
		this.consentDate = consentDate;
		this.markup = markup;
		this.js = js;
		this.css = css;
	}

	public static importFromApi(apiObject: any): Consent {
		return new Consent(
			apiObject.IsConsentRequired,
			apiObject.CookieName,
			apiObject.MinimumConsentDate,
			apiObject.Markup,
			apiObject.Js[0],
			apiObject.Css[0]
		);
	}

	public clone(): Consent {
		return new Consent(
			this.isConsentRequired,
			this.cookieName.slice(),
			new Date(this.consentDate.getTime()),
			this.markup.slice(),
			this.js.slice(),
			this.css.slice()
		);
	}
}
