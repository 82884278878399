import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericPromptService, IResourceOperation, IToasterService, PromptButtonTypes, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../interfaces/IEntityService';
import { ENTITY_OPERATIONS } from '../../models/entity-operations.model';
import { Entity } from '../../models/entity.model';
import { EntityUtilitiesService } from '../../services/entity-util.service';
import { EntityCreationModalComponent } from '../modals/entity-creation-modal/entity-creation-modal.component';
import { EntityRenameModalComponent } from '../modals/entity-rename-modal/entity-rename-modal.component';
import { PrebuiltEntityListModalComponent } from '../modals/prebuilt-entity-list-modal/prebuilt-entity-list-modal.component';

/**
 * @description
 * Represents an entity list component that hosts an entity table. The entity list is
 * responsible for any service communcation and mediating between children components and
 * external services.
 */
@Component({
	selector: 'entity-list',
	templateUrl: './entity-list.component.html',
	styleUrls: ['./entity-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityListComponent implements OnInit {
	@Output() public entityClicked: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() public newEntityCreated: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() public domainEntityClicked: EventEmitter<Entity> = new EventEmitter<Entity>();

	public entities: Observable<Entity[]>;

	constructor(
		private readonly _promptService: GenericPromptService,
		private readonly _entityUtilService: EntityUtilitiesService,
		private readonly _dialogService: MatDialog,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Fires an event that an entity was clicked.
	 *
	 * @param entity The entity that was clicked.
	 */
	public onEntityClick(entity: Entity): void {
		this.entityClicked.emit(entity);
	}

	/**
	 * @description
	 * Applies the given operation on the entity based on received.
	 *
	 * @param entityOp The entities and the operation to apply on it.
	 */
	public onOperationClicked(entityOp: IResourceOperation): void {
		switch (entityOp.operation) {
			case ENTITY_OPERATIONS.RENAME:
				this._createRenameEntityModal(entityOp);
				break;
			case ENTITY_OPERATIONS.DELETE:
				this._deleteEntity(entityOp);
				break;
			case ENTITY_OPERATIONS.ADD:
				this._createNewEntity(entityOp);
				break;
			case ENTITY_OPERATIONS.ADD_PREBUILT:
				this._createPrebuiltEntity(entityOp);
				break;
			case ENTITY_OPERATIONS.ADD_DOMAIN:
				this._onDomainEntityClick(entityOp);
				break;
			default:
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.entities = this._entityService.get();
	}

	/**
	 * @description
	 * Launches the entity creation modal. Notifies the parent component
	 * that a new entity was created.
	 *
	 * @param entityOp The operation metadata.
	 */
	private _createNewEntity(entityOp: IResourceOperation): void {
		this._dialogService
			.open(EntityCreationModalComponent, { width: '600px' })
			.afterClosed()
			.pipe(filter(entity => entity))
			.subscribe(entity => {
				entityOp.response.complete();
				this.newEntityCreated.emit(entity);
			});
	}

	/**
	 * @description
	 * Launcehs the prebuilt entities modal.
	 *
	 * @param entityOp The operation metadata.
	 */
	private _createPrebuiltEntity(entityOp: IResourceOperation): void {
		this._dialogService
			.open(PrebuiltEntityListModalComponent, { width: '800px' })
			.afterClosed()
			.pipe(filter(entities => entities))
			.subscribe(() => entityOp.response.complete());
	}

	/**
	 * @description
	 * Fires an event that an domain entity button was clicked.
	 *
	 * @param entityOp The operation metadata.
	 */
	private _onDomainEntityClick(entityOp: IResourceOperation): void {
		this.domainEntityClicked.emit();
		entityOp.response.complete();
	}

	/**
	 * @description
	 * Creates a prompt modal to delete the given entities.
	 *
	 * @param entityOp The operation metadata.
	 */
	private _deleteEntity(entityOp: IResourceOperation): void {
		const isBatch: boolean = entityOp.items.length > 1;
		const entities: Entity[] = <Entity[]>entityOp.items;
		let promptMessage: string = `Are you sure you want to delete ${isBatch ? 'those entities' : "entity ':name'"}? :extension`;

		this._entityUtilService
			.getLabelsPerEntity()
			.first()
			.do(labelsMap => {
				let labels: number = 0;
				entities.forEach(entity => (labels = labels + (labelsMap.has(entity.id) ? labelsMap.get(entity.id) : 0)));

				if (labels > 0) {
					promptMessage = promptMessage.replace(
						/:extension/,
						`This will remove all labels for ${isBatch ? 'those entities' : 'this entity'} from all utterances.`
					);
				} else {
					promptMessage = promptMessage.replace(/:extension/, '');
				}
			})
			.switchMap(() =>
				this._promptService.prompt(
					`Delete ${isBatch ? 'entities' : 'entity'}?`,
					promptMessage.replace(/:name/, entities[0].name),
					{ ok: 'Ok', cancel: 'Cancel' },
					{ cancel: PromptButtonTypes.Default, ok: PromptButtonTypes.Danger }
				)
			)
			.filter(choice => choice === 'ok')
			.flatMap(() =>
				Observable.combineLatest(entities.map(entity => this._entityService.delete(entity))).trackProgress(
					this._toasterService.add({ startMsg: isBatch ? 'Deleting entities' : 'Deleting entity' })
				)
			)
			.filter(response => response !== null)
			.subscribe(() => entityOp.response.complete());
	}

	/**
	 * @description
	 * Creates an entity rename modal.
	 *
	 * @param entityOp The entities and the operation to apply on them.
	 */
	private _createRenameEntityModal(entityOp: IResourceOperation): void {
		this._dialogService
			.open(EntityRenameModalComponent, { width: '600px', data: entityOp.items[0] })
			.afterClosed()
			.subscribe(() => entityOp.response.complete());
	}
}
