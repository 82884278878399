import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { EndpointKey } from '../models/azure-key.model';
import { Subscription } from '../models/subscription.model';
import { Tenant } from '../models/tenant.model';

export const KEY_STORE_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IKeyStoreService');

/**
 * @description
 * Represents an interface for a service that fetches and stores
 * all the Azure keys available for all subscriptions for all
 * tenants that the current user belongs to.
 */
export interface IKeyStoreService {
    initService(): void;

    getTenants(): Observable<Tenant[]>;

    getTenantSubscriptions(tenantId: string): Observable<Subscription[]>;

    getSubscriptionKeys(subscriptionId: string): Observable<EndpointKey[]>;

    getKeyById(id: string): Observable<EndpointKey>;
}
