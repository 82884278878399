import { Component } from '@angular/core';

@Component({
    selector: 'm-nav-bar',
    templateUrl: 'nav-bar.component.html',
    styleUrls: ['nav-bar.component.scss']
})

export class NavBarComponent {

}
