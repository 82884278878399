import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { getFollowingFocusableItem } from '../utils/accessibility/focus.helpers';
import { BACKSPACE, COMMA, ENTER, SPACE } from '../utils/keyboard/keycodes';

export type PillPadding = 'full' | 'half' | 'none' | 'complex';
export type RemoveIcon = 'add' | 'delete';

export interface IListItem {
	icon?: string;
	title?: string;
	text: string;
	value: string;
}

export interface IRemovedItem {
	item: any;
	index: number;
}

/**
 * @description
 * Displays the given values in pill shapes.
 */
@Component({
	selector: 'm-pill-values',
	templateUrl: 'pill-values.component.html',
	styleUrls: ['pill-values.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PillValuesComponent implements OnInit, OnDestroy {
	@Input() public inputItems: Observable<any[]>;
	@Input() public textField: string;
	@Input() public valueField: string;
	@Input() public titleField: string;
	@Input() public allowDelete: boolean = true;
	@Input() public allowAdd: boolean = false;
	@Input() public inputPlaceholder: string;
	@Input() public padding: PillPadding = 'half';
	@Input() public icon: RemoveIcon = 'delete';
	@Input() public maxInputCount: number = Infinity;

	@Output() public deleteItem: EventEmitter<IRemovedItem> = new EventEmitter<IRemovedItem>();
	@Output() public addItem: EventEmitter<string> = new EventEmitter<string>();
	@Output() public tabClicked: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
	@Output() public enterWhileEmpty: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

	@ViewChild('newValueField') public newValueField: ElementRef;
	@ViewChildren('listItem') public listItemElements: QueryList<ElementRef>;

	public listItems: BehaviorSubject<IListItem[]> = new BehaviorSubject<IListItem[]>([]);
	public showInput: boolean;
	private _originalListItems: any[] = [];
	private _itemsSubscription: Subscription;
	private _lastKeyupValue: string = '';

	constructor(private _elementRef: ElementRef, readonly _i18n: TranslateService) {
		this.inputPlaceholder = _i18n.instant('ui.pill-values.add_item');
	}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._itemsSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Deletes a value from the pills list.
	 *
	 * @param index The index of the pill to delete.
	 */
	public deleteValue(index: number): void {
		if (this.allowDelete) {
			const listItems: IListItem[] = this.listItems.getValue();
			const deletedItem: any = this._originalListItems[index];

			listItems.splice(index, 1);

			this._originalListItems.splice(index, 1);
			this.listItems.next(listItems);
			this.deleteItem.emit({ item: deletedItem, index: index });

			let element: HTMLElement;
			const prevElement = this.listItemElements.toArray()[index - 1];
			if (prevElement) {
				element = prevElement.nativeElement;
			}

			const elementToFocus =
				element || getFollowingFocusableItem(this._elementRef.nativeElement, this._elementRef.nativeElement, true, 'prev');

			elementToFocus.focus();
		}
	}

	/**
	 * @description
	 * Adds a value to the pills list.
	 */
	public addValue(keyEvent?: KeyboardEvent): void {
		if (keyEvent && keyEvent.keyCode === BACKSPACE) {
			if (this._lastKeyupValue === '') {
				const values: IListItem[] = this.listItems.getValue();
				if (values.length) {
					const lastIndex: number = values.length - 1;
					this.deleteValue(lastIndex);
				}
			}
		} else if (keyEvent === undefined || keyEvent.keyCode === ENTER || keyEvent.keyCode === COMMA) {
			const value: string = this._cleanValue(this.newValueField.nativeElement.value.trim());
			this.newValueField.nativeElement.value = '';

			if (value.length) {
				const listItems: IListItem[] = this.listItems.getValue();
				listItems.push({ text: value, value: '' });
				this.listItems.next(listItems);
				this.addItem.emit(value);
			} else {
				this.enterWhileEmpty.emit(keyEvent);
			}
		}
		this._lastKeyupValue = this.newValueField.nativeElement.value;
	}

	/**
	 * @description
	 * Focuses the input field.
	 */
	public focus(): void {
		this.newValueField.nativeElement.focus();
	}

	/**
	 * @description
	 * Prevent bubbling up the event if the SPACE is entered.
	 */
	public onKeyDown(event: KeyboardEvent): void {
		if (event.keyCode === SPACE) {
			event.stopPropagation();
		}
	}

	/**
	 * @description
	 * Cleans the comma separated values given.
	 *
	 * @param values The values to be cleaned.
	 * @returns The cleaned values.
	 */
	private _cleanValue(values: string): string {
		return values
			.split(',')
			.map(t => t.trim())
			.filter(t => t.length)
			.join(',');
	}

	/**
	 * @description
	 * Initializes the component state.
	 */
	private _initState(): void {
		this._itemsSubscription = this.inputItems
			.do(items => (this._originalListItems = items))
			.do(() => this.listItems.next([]))
			.map(items =>
				items.map(item =>
					typeof item === 'object'
						? {
								text: item[this.textField] ? item[this.textField].toString() : '',
								value: item[this.valueField] ? item[this.valueField].toString() : '',
								title: item[this.titleField] ? item[this.titleField].toString() : '',
								icon: item.icon.toString()
						  }
						: { text: item, value: item }
				)
			)
			.do(items => this.listItems.next(items))
			.do(items => (this.showInput = this.allowAdd && items.length < this.maxInputCount))
			.subscribe(() => {});
	}
}
