import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDictionary } from '../../interfaces/utils/IDictionaryStorageService';
import { LuisConstants } from '../../models/utils/constants.model';
import { FEATURE_OPT_SETTINGS_TYPES } from '../../models/utils/feature-opt-settings.model';
import { DictionaryLocalStorageService } from '../../services/utils/dictionary-storage.service';

@Injectable()
export class FeatureOptSettingsService {
	private readonly _featureOptSettings: BehaviorSubject<IDictionary<boolean>> = new BehaviorSubject({});

	constructor(private readonly _dictionaryStorageService: DictionaryLocalStorageService) {
		this._initState();
	}

	/**
	 * @description
	 * Gets all the opt in/out settings stored in the local storage for all features.
	 *
	 * @returns An observable of the dictionary of features with their opt in/out
	 * settings or undefined if the opt in/out settings did not exist before.
	 */
	public getAllOptSettings(): Observable<IDictionary<boolean>> {
		return this._featureOptSettings.asObservable();
	}

	/**
	 * @description
	 * Gets the opt in/out settings for a specific feature or returns undefined if
	 * the opt in/out settings didn't exist before.
	 *
	 * @param feature The feature enumeration to get the opt in/out settings for.
	 * @returns An observable of the boolean value of the opt in/out settings set
	 * or undefined if not set.
	 */
	public getFeatureOptSettings(feature: FEATURE_OPT_SETTINGS_TYPES): Observable<boolean> | Observable<undefined> {
		return this.getAllOptSettings().pipe(map(dictionary => dictionary[feature]));
	}

	/**
	 * @description
	 * Sets the opt in/out settings for the given feature with the value given.
	 *
	 * @param feature The feature to set the opt in/out settings for.
	 * @param value The value of the opt in/out settings to set.
	 */
	public setFeatureOptSetting(feature: FEATURE_OPT_SETTINGS_TYPES, value: boolean): void {
		const optSettings = this._featureOptSettings.getValue();

		optSettings[feature] = value;

		this._dictionaryStorageService.setDictionary<boolean>(LuisConstants.FEATURE_OPT_SETTINGS_STORAGE_KEY, optSettings);
		this._featureOptSettings.next(optSettings);
	}

	/**
	 * @description
	 * Initializes the service.
	 */
	private _initState(): void {
		const opts = this._dictionaryStorageService.getDictionary<boolean>(LuisConstants.FEATURE_OPT_SETTINGS_STORAGE_KEY);
		this._featureOptSettings.next(opts || {});
	}
}
