import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AxisData, DropdownComponent } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { filter, flatMap, map, shareReplay, startWith } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { ITrainingResultService, TRAINING_RESULT_SERVICE_TOKEN } from '../../interfaces/ITrainingResultService';
import { FILTER_OPTIONS } from '../../models/filter.model';
import { IntentMetadata } from '../../models/intent-metadata.model';
import { STATUS_CODES, TrainingResultMetaData } from '../../models/training-result-metadata.model';

enum SORT_OPTIONS {
	CORRECT,
	UNCLEAR,
	INCORRECT,
	ALL_UTTERANCES,
	INTENT_NAME
}

enum LEGEND {
	CORRECT,
	UNCLEAR,
	INCORRECT,
	NONE
}

@Component({
	selector: 'distribution-chart',
	templateUrl: 'distribution-chart.component.html',
	styleUrls: ['distribution-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributionChartComponent implements OnInit, OnDestroy {
	public legend: typeof LEGEND = LEGEND;
	public sortOps: typeof SORT_OPTIONS = SORT_OPTIONS;
	public filterOps: typeof FILTER_OPTIONS = FILTER_OPTIONS;

	public ratio: string = '';

	public xData: Observable<AxisData>;
	public yData: Observable<AxisData>;

	public yAxisOpt: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public colors: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(['#0078D7', '#F2610C', '#A80000']);
	public dataToDefocus: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	public selectedLegend: BehaviorSubject<number> = new BehaviorSubject<number>(LEGEND.NONE);
	public sortingOption: BehaviorSubject<number> = new BehaviorSubject<number>(this.sortOps.CORRECT);

	private _trainingResult: Observable<TrainingResultMetaData>;
	private _legendSubscription: Subscription = new Subscription();
	private _x: string[] = [];
	private _yNumUtterances: number[][] = [[], [], []];
	private _yPercentUtterances: number[][] = [[], [], []];

	constructor(
		private readonly _i18n: TranslateService,
		@Inject(TRAINING_RESULT_SERVICE_TOKEN) private readonly _trainingResultService: ITrainingResultService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._legendSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Returns how the tooltip of each bar appears.
	 */
	public createTooltip = (index: number): string => {
		const totalUtterancesNum: number = this._yNumUtterances[0][index] + this._yNumUtterances[1][index] + this._yNumUtterances[2][index];
		const legendItem: string = 'display: inline-block; height: 12px; width: 12px; margin-right: 8px;';
		const numCol: string = 'text-align: right; padding-right: 10px; font-weight: bold;';

		return `
            <div style="
                font-weight: normal;
                background-color: white;
                box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.16);
            ">
                <div style="
                    background-color: black;
                    color: white;
                    padding: 3px 10px;
                    font-family: "Segoe UI";
                    font-size: 13px;
                    letter-spacing: 0.43px;
                    line-height: 18px;
                ">
                    <span style="font-weight: 200;">${this._i18n.instant('training.distribution-chart.tooltip_title')}</span>
                    <span style="font-weight: bold;">${this._x[index]}</span>
                </div>

                <div style="padding: 5px 10px; padding-bottom: 15px; padding-right: 30px;">
                    <table>
                        <tbody>
                            <tr>
                                <td style="width: 200px;">${this._i18n.instant('training.distribution-chart.tooltip_legend')}</td>
                                <td style="${numCol}">${totalUtterancesNum}</td>
                            </tr>
                            <tr style="width: 200px;">
                                <td style="width: 200px;">
                                    <div style="${legendItem} background-color: #A80000"></div>
                                    ${this._i18n.instant('training.distribution-chart.tooltip_legend_incorrect')}
                                </td>
                                <td style="${numCol}">${this._yNumUtterances[2][index]}</td>
                                <td>(${this._yPercentUtterances[2][index].toFixed(1)}%)</td>
                            </tr>
                            <tr style="width: 200px;">
                                <td style="width: 200px;">
                                    <div style="${legendItem} background-color: #0078D7"></div>
                                    ${this._i18n.instant('training.distribution-chart.tooltip_legend_correct')}
                                </td>
                                <td style="${numCol}">${this._yNumUtterances[0][index]}</td>
                                <td>(${this._yPercentUtterances[0][index].toFixed(1)}%)</td>
                            </tr>
                            <tr style="width: 200px;">
                                <td style="width: 200px;">
                                    <div style="${legendItem} background-color: #F2610C;"></div>
                                    ${this._i18n.instant('training.distribution-chart.tooltip_legend_unclear')}
                                </td>
                                <td style="${numCol}">${this._yNumUtterances[1][index]}</td>
                                <td>(${this._yPercentUtterances[1][index].toFixed(1)}%)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        `;
	};

	public sortingChanged(sortOpt: number, sortDropdown: DropdownComponent): void {
		this.sortingOption.next(sortOpt);
		this.selectedLegend.next(sortOpt > this.legend.NONE ? this.legend.NONE : sortOpt);
		if (sortDropdown !== null) {
			sortDropdown._clearSelection();
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._trainingResult = this._trainingResultService.getStatus().pipe(
			filter(status => status === STATUS_CODES.COMPLETED),
			flatMap(() => this._trainingResultService.getMetaData()),
			startWith(new TrainingResultMetaData()),
			shareReplay()
		);

		this._legendSubscription = Observable.combineLatest(this._trainingResult, this.selectedLegend.asObservable().distinctUntilChanged())
			.pipe(
				map(([tR, legend]) => {
					const legends = ['correct', 'unclear', 'incorrect'];
					this.dataToDefocus.next(legends.filter((l, i) => i === legend && legend !== this.legend.NONE));
					if (legend !== this.legend.NONE) {
						this.sortingChanged(legend, null);
					}
				})
			)
			.subscribe();

		this._initData();
	}

	/**
	 * @description
	 * Initializes the x data and y data of the distribution bar chart.
	 */
	private _initData(): void {
		this.xData = Observable.combineLatest(this._trainingResult, this.sortingOption, this.yAxisOpt).pipe(
			map(([trainingResult, sort, isNumber]) => {
				this._x = this._sort(Array.from(trainingResult.utterancesPerIntent.values()), sort, isNumber).map(
					intent => intent.intentName
				);

				return new AxisData('Intent', this._x, false);
			})
		);

		this.yData = Observable.combineLatest(this._trainingResult, this.sortingOption, this.yAxisOpt).pipe(
			map(([trainingResult, sort, isNumber]) => {
				this._yNumUtterances = [[], [], []];
				this._yPercentUtterances = [[], [], []];
				this._sort(Array.from(trainingResult.utterancesPerIntent.values()), sort, isNumber).forEach(intent => {
					// Correct utterances.
					this._yNumUtterances[0].push(intent.numCorrectUtterances);
					// Ambiguous utterances (for bar chart).
					this._yNumUtterances[1].push(intent.numUnclearUtterances);
					// Incorrect utterances.
					this._yNumUtterances[2].push(intent.numIncorrectUtterances);

					// Correct utterances percentage.
					this._yPercentUtterances[0].push(intent.correctAccuracy);
					// Ambiguous and correct utterances (for bar chart) percentage.
					this._yPercentUtterances[1].push(intent.unclearAccuracy);
					// Incorrect utterances percentage.
					this._yPercentUtterances[2].push(intent.incorrectAccuracy);
				});

				return new AxisData('Utterances', isNumber ? this._yNumUtterances : this._yPercentUtterances, false);
			})
		);
	}

	/**
	 * @description
	 * Sorts the given data with respect to the current sorting option.
	 */
	private _sort(arr: IntentMetadata[], sort: number, isNumber: boolean): IntentMetadata[] {
		return arr.sort((a, b) => {
			switch (sort) {
				case this.sortOps.ALL_UTTERANCES:
					return a.numUtterances < b.numUtterances ? -1 : 1;
				case this.sortOps.CORRECT:
					const correctA = isNumber ? a.numCorrectUtterances : a.correctAccuracy;
					const correctB = isNumber ? b.numCorrectUtterances : b.correctAccuracy;

					return correctA < correctB ? -1 : 1;
				case this.sortOps.UNCLEAR:
					const unclearA = isNumber ? a.numUnclearUtterances : a.unclearAccuracy;
					const unclearB = isNumber ? b.numUnclearUtterances : b.unclearAccuracy;

					return unclearA < unclearB ? -1 : 1;
				case this.sortOps.INCORRECT:
					const incorrectA = isNumber ? a.numIncorrectUtterances : a.incorrectAccuracy;
					const incorrectB = isNumber ? b.numIncorrectUtterances : b.incorrectAccuracy;

					return incorrectA < incorrectB ? -1 : 1;
				case this.sortOps.INTENT_NAME:
					return a.intentName.toLocaleLowerCase() < b.intentName.toLocaleLowerCase() ? -1 : 1;
				default:
			}
		});
	}
}
