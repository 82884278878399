import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    QueryList
} from '@angular/core';
import { ENTER, ESCAPE, TAB } from '../keyboard/keycodes';
import { FocusKeyManager, IFocusable } from './focus-key-manager';
import { FOCUS_TRAP_ATTRIBUTE, getFocusableItems, getFollowingFocusableItem } from './focus.helpers';
import { FocusDirection } from './list-key-manager';

/**
 * @description
 *
 * @param focusDirection Choose the direction of arrow keys to navigate the focus
 * with. Vertical means using Up/Down arrows, and horizontal means using Left/Right.
 * @param allowArrowFocusIn A flag that controls whether arrow keys should auto focus
 * next item in trap list or not, when the current focus is on the trap parent element
 * itself. Useful to turn on when using with navigation bars or tablists.
 * @param allowInnerTabKeyOut A flag that controls whether pressing the Tab or Shift Tab
 * key while inside the trap zone should leave the tab zone focus area or not. Useful to
 * turn on when using with navigation bars or tablists.
 * @param allowOuterTabKeyOut A flag that controls whether pressing the Tab or Shift Tab
 * key while outside the trap zone should focus the next focusable item outside the trap
 * zone or not.
 * @param allowContainerFocus A flag that controls whether the focus trap container itself
 * should be focusable or not. Set to false if you do not want to explicitly set a focus
 * on the container of the trap.
 */
@Directive({
    selector: `[${FOCUS_TRAP_ATTRIBUTE}]`
})
export class FocusTrapDirective implements OnInit, OnDestroy {
    @Input(FOCUS_TRAP_ATTRIBUTE) public focusDirection: FocusDirection = 'horizontal';
    @Input() public allowArrowFocusIn: boolean = false;
    @Input() public allowInnerTabKeyOut: boolean = false;
    @Input() public allowOuterTabKeyOut: boolean = false;
    @Input() public allowContainerFocus: boolean = true;

    @HostBinding('attr.tabIndex') public get tabIndex(): string { return this.allowContainerFocus ? '0' : null; }

    protected _items: QueryList<IFocusable> = new QueryList<IFocusable>();
    protected _keyManager: FocusKeyManager;
    private _mutationObserver: MutationObserver;

    constructor(private _el: ElementRef) { }

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        if (this._mutationObserver) {
            this._mutationObserver.disconnect();
        }
    }

    /**
     * @description
     * Directly focuses the given element by finding it in the key manager
     * and focusing it via the key manager. If it wasn't found, this call
     * is ignored.
     *
     * @param element The element to focus.
     */
    public focusSpecificElement(element: ElementRef): void {
        const elementIndex = this._items.toArray().findIndex(item => item === element.nativeElement);

        if (elementIndex !== -1) {
            this._keyManager.setActiveItem(elementIndex);
        }
    }

    /**
     * @description
     * Checks if the key pressed corresponds to any special
     * handler for keys.
     *
     * @param event The event object received.
     */
    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        switch (event.keyCode) {
            case ENTER:
                return this._handleEnterKey(event);
            case ESCAPE:
                return this._handleEscapeKey(event);
            case TAB:
                return this._handleTabKey(event);
            default:
                // Fall through to key manager to handle key arrow presses or
                // any other keys. If the keymanager did in fact handle it,
                // the event should be stopped here.
                if (this.allowArrowFocusIn || document.activeElement !== this._el.nativeElement) {
                    const isHandled = this._keyManager.onKeydown(event);

                    if (isHandled) {
                        event.stopPropagation();
                    }
                }
        }
    }

    /**
     * @description
     * If the element receives focus from outside the trap (via TAB key or any other way),
     * then do the following:
     *
     * - If the container is focusable, then focus the container itself.
     *
     * - If the container itself is not focusable via the allowContainerFocus flag being false,
     * then focus the last active element. If there was no previously activated element, then focus
     * the first element in the trap zone.
     *
     * @param focusEvent The focus event that fired.
     */
    @HostListener('focusin', ['$event'])
    public onFocusIn(focusEvent: FocusEvent): void {
        const elementFocusedBefore = focusEvent.relatedTarget;
        const isElementFocusedBeforeOutsideTrap = elementFocusedBefore && !this._el.nativeElement.contains(elementFocusedBefore);

        if (isElementFocusedBeforeOutsideTrap) {
            if (this.allowContainerFocus) {
                this._el.nativeElement.focus();
            }
            else if (this._keyManager.activeItem) {
                this._keyManager.setActiveItem(this._keyManager.activeItemIndex);
            }
            else {
                this._keyManager.setFirstItemActive();
            }
        }
    }

    /**
     * @description
     * Initializes the state.
     */
    private _initState(): void {
        this._mutationObserver = new MutationObserver(() => {
            this._items.reset(getFocusableItems(this._el.nativeElement, this._el.nativeElement));
        });

        this._mutationObserver.observe(this._el.nativeElement, {
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['style', 'href']
        });

        this._keyManager = new FocusKeyManager(<any>this._items, this.focusDirection).withWrap();
    }

    /**
     * @description
     * If the enter key was pressd while I was focused on the
     * trap element itself, drill down and focus the first item of the trap
     * element.
     *
     * @param event The keyboard event that was fired.
     */
    private _handleEnterKey(event: KeyboardEvent): void {
        const isFocusOnTrap = document.activeElement === this._el.nativeElement;

        if (isFocusOnTrap) {
            event.stopPropagation();
            this._keyManager.setFirstItemActive();
        }
    }

    /**
     * @description
     * If the focus is inside the trap zone, pressing Escape should focus
     * the trap zone element.
     *
     * @param event The keyboard event that was fired.
     */
    private _handleEscapeKey(event: KeyboardEvent): void {
        const isFocusInside = document.activeElement !== this._el.nativeElement;

        if (isFocusInside) {
            event.stopPropagation();
            this._el.nativeElement.focus();
        }
    }

    /**
     * @description
     * Handles the tab key with the following rules:
     *
     * - If the focus is inside the trap zone and the flag to allow tabbing to move
     * us outside the trap zone, then find the next focusable item outside the trap
     * zone and focus it.
     *
     * - If the focus is inside the trap zone with that flag turned off, then simply
     * focus the next item inside the trap zone.
     *
     * - If focus is outside the trap zone and the flag to allow the tab key to jump
     * off to the first focusable element outside the trap, the do so.
     *
     * - If the tab key was not handled by any of the rules above, then let the event
     * bubble up for its parent to handle or be handled by the default HTML engine.
     *
     * @param event The keyboard event that was fired with the Tab key.
     */
    private _handleTabKey(event: KeyboardEvent): void {
        const isFocusInside = document.activeElement !== this._el.nativeElement;
        let handled = true;

        if (isFocusInside && this.allowInnerTabKeyOut) {
            this._focusNextFocusableItemOutsideTrapZone(event);
        }
        else if (isFocusInside) {
            event.shiftKey ? this._keyManager.setPreviousItemActive() : this._keyManager.setNextItemActive();
        }
        else if (this.allowOuterTabKeyOut) {
            this._focusNextFocusableItemOutsideTrapZone(event);
        }
        else {
            handled = false;
        }

        if (handled) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    /**
     * @description
     * Focuses the next focusable item outside the trap zone. "Next" doesn't necessarily
     * mean "after". The direction of the search is based on whether the Shift key was
     * pressed or not.
     *
     * @param event The keyboard event that was fired.
     */
    private _focusNextFocusableItemOutsideTrapZone(event: KeyboardEvent): void {
        const nextFocusable = event.shiftKey ?
            getFollowingFocusableItem(this._el.nativeElement, this._el.nativeElement, true, 'prev') :
            getFollowingFocusableItem(this._el.nativeElement, this._el.nativeElement, true, 'next');

        if (nextFocusable) {
            nextFocusable.focus();
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
