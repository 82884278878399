﻿/**
 * @const
 * @description
 * Object containing the types of charts are supported by the chart renderer service
 */
export const CHART_TYPES: any = {
    LINE: 'line',
    PIE: 'pie',
    GAUGE: 'gauge',
    BAR: 'bar',
    CONFUSION: 'scatter',
    DONUT: 'donut',
    STACKED_BAR: 'bar'
};

/**
 * @description
 * Class representing the axis data used to construct charts components
 */
export class AxisData {
    public title: string;
    public data: any[];
    public isTimeSeries: boolean;

    constructor(title: string, data: any[], isTimeSeries: boolean) {
        this.title = title;
        this.data = data;
        this.isTimeSeries = isTimeSeries;
    }

    /**
     * @method
     * @description
     */
    public clone(): AxisData {
        return new AxisData(
            this.title.slice(),
            this.data.slice(),
            this.isTimeSeries
        );
    }
}
