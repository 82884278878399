import { IResource } from '@luis/core';

/**
 * @description
 * Represents an app version. Each app in LUIS can have multiple versions (snapshots).
 *
 * @param id The app version id.
 * @param createdDate The app version created date.
 * @param modifiedDate The app version modified date
 * @param trainedDate The app version trained date
 * @param publishedDate The app version published date.
 * @param endpointUrl The app version endpoint url.
 * @param endpointKey The app version endpoint key.
 * @param intentsCount The app version intents count.
 * @param entitiesCount The app version entities count.
 * @param endpointHitsCount The app version endpoint hits count.
 * @param isTrained The app version trained status
 * @param isDirty The app version dirty status (An app version
 * is dirty if any changes occured that would require training to reflect)
 */
export class AppVersion implements IResource {
    public id: string;
    public createdDate: Date;
    public modifiedDate: Date;
    public trainedDate: Date;
    public publishedDate: Date;
    public intentsCount: number;
    public entitiesCount: number;
    public endpointHitsCount: number;
    public isDirty: boolean;
    public isTrained: boolean;

    constructor(
        id: string = '', createdDate: Date = new Date(),
        modifiedDate: Date = new Date(), trainedDate: Date = null,
        publishedDate: Date = null, endpointHitsCount: number = 0,
        isDirty: boolean = false
    ) {
        this.id = id;
        this.createdDate = createdDate;
        this.modifiedDate = modifiedDate;
        this.trainedDate = trainedDate;
        this.publishedDate = publishedDate;
        this.endpointHitsCount = endpointHitsCount;
        this.isTrained = this.trainedDate ? true : false;
        this.isDirty = isDirty;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): AppVersion {
        return new AppVersion(
            apiObject.version,
            new Date(apiObject.createdDateTime),
            new Date(apiObject.lastModifiedDateTime),
            apiObject.lastTrainedDateTime ? new Date(apiObject.lastTrainedDateTime) : null,
            apiObject.lastPublishedDateTime ? new Date(apiObject.lastPublishedDateTime) : null,
            apiObject.endpointHitsCount,
            apiObject.trainingStatus === 'Trained' ? false : true
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            version: this.id
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): AppVersion {
        return new AppVersion(
            this.id.slice(),
            new Date(this.createdDate.getTime()),
            new Date(this.modifiedDate.getTime()),
            this.trainedDate ? new Date(this.trainedDate.getTime()) : this.trainedDate,
            this.publishedDate ? new Date(this.publishedDate.getTime()) : this.publishedDate,
            this.endpointHitsCount,
            this.isDirty
        );
    }
}
