/**
 * @description
 * An enumeration of the continents with supported regions.
 */
export enum CONTINENT {
    ALL,
    US,
    BRAZIL,
    EU,
    ASIA,
    AU
}
