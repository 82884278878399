import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output
} from '@angular/core';
import { APP_SERVICE_TOKEN, IAppService } from '@luis/apps';
import { IToasterService, ProgressTracker, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { ENTITY_SERVICE_TOKEN, ENTITY_TYPES, IEntityService } from '@luis/entities';
import { IIntentService, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { DOMAIN_SERVICE_TOKEN, IDomainService } from '../../interfaces/IDomainService';
import { Domain } from '../../models/domain.model';

/**
 * @description
 * Represents a domain card that contains info about the
 * underlying domain and whether it is added or not.
 */
export interface IDomainCard {
    domain: Domain;
    isAdded: boolean;
    isLoading: boolean;
}

@Component({
    selector: 'domain-list',
    templateUrl: './domain-list.component.html',
    styleUrls: ['./domain-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainListComponent implements OnInit {
    @Output() public learnMoreClicked: EventEmitter<Domain> = new EventEmitter<Domain>();

    public domainCards: Observable<IDomainCard[]>;
    public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public loadingDomains: Map<string, boolean> = new Map<string, boolean>();
    public domainsFetchTracker: ProgressTracker = new ProgressTracker();

    private _refreshTrigger: Subject<void> = new Subject<void>();

    constructor(
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(INTENT_SERVICE_TOKEN) private _intentService: IIntentService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService,
        @Inject(APP_SERVICE_TOKEN) private _appService: IAppService,
        @Inject(DOMAIN_SERVICE_TOKEN) private _domainService: IDomainService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @method
     * @description
     * Adds the new query received on the query stream.
     *
     * @param query The query typed by the user.
     */
    public onQueryChange(query: string): void {
        this.querySubject.next(query);
    }

    /**
     * @method
     * @description
     * Adds or deletes a domain when it is clicked, based
     * on its current addition status.
     *
     * @param domainCard The domain card clicked.
     */
    public onDomainClick(domainCard: IDomainCard): void {
        this._setLoadingState(domainCard, true);

        if (domainCard.isAdded) {
            this._domainService.delete(domainCard.domain)
                .do(() => domainCard.isAdded = false)
                .do(() => this._refreshTrigger.next())
                .trackProgress(this._toasterService.add())
                .finally(() => this._setLoadingState(domainCard, false))
                .subscribe();
        }
        else {
            this._domainService.add(domainCard.domain)
                .do(() => domainCard.isAdded = true)
                .do(() => this._refreshTrigger.next())
                .trackProgress(this._toasterService.add())
                .finally(() => this._setLoadingState(domainCard, false))
                .subscribe();
        }
    }

    /**
     * @method
     * @description
     * Notifies parent that a domain learn more link was clicked.
     *
     * @param domainCard The card that was clicked.
     */
    public onLearnMoreClicked(domainCard: IDomainCard): void {
        this.learnMoreClicked.emit(domainCard.domain);
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this._getDomains();
    }

    /**
     * @description
     * Gets the domains, marks them as added or not added based on the
     * intents and entities in the application.
     */
    private _getDomains(): void {
        this.domainCards = this._appService.getSingle()
            .first()
            .flatMap(app => Observable.combineLatest(
                this._intentService.get(),
                this._entityService.get(),
                this._domainService.get(app.culture),
                this.querySubject.asObservable()
                    .distinctUntilChanged()
                    .debounceTime(200),
                this._refreshTrigger.asObservable()
                    .startWith(undefined)
            ))
            .map(([intents, entities, domains, query, trigger]) => {
                const filteredIntents = intents.filter(i => i.domainName !== null);
                const filteredEntities = entities.filter(e => e.domainName !== null || e.type === ENTITY_TYPES.PREBUILT);

                return domains
                    .filter(d => d.name.toLocaleLowerCase()
                        .startsWith(query.toLocaleLowerCase()))
                    .sort((l, r) => l.name.toLocaleLowerCase() > r.name.toLocaleLowerCase() ? 1 : -1)
                    .map(d => {
                        const modelMap: Map<string, boolean> = new Map<string, boolean>();
                        const domainCard: IDomainCard = { domain: d, isAdded: false, isLoading: this.loadingDomains.get(d.name) || false };

                        // Added domain models in the model map.
                        d.intents.forEach(i => modelMap.set(i.uniqueName, false));
                        d.entities.forEach(e => modelMap.set(e.uniqueName, false));

                        // App intents and entities marked as found in map
                        filteredIntents
                            .forEach(i => modelMap.has(i.uniqueName) ? modelMap.set(i.uniqueName, true) : undefined);
                        filteredEntities
                            .forEach(e => {
                                const entityUniqueName: string = e.uniqueName;
                                const key = entityUniqueName ? entityUniqueName : `${ d.name }.${ e.name }`;
                                modelMap.has(key) ? modelMap.set(key, true) : null;
                            });

                        domainCard.isAdded = Array
                            .from(modelMap.values())
                            .filter(b => !b).length === 0;

                        return domainCard;
                    });
                })
            .trackProgress(this.domainsFetchTracker.getTracker());
    }

    /**
     * @description
     *
     * @param domainCard
     * @param val
     */
    private _setLoadingState(domainCard: IDomainCard, val: boolean): void {
        domainCard.isLoading = val;
        this.loadingDomains.set(domainCard.domain.name, val);
    }
}
