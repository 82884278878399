import {
    ChangeDetectionStrategy,
    Component,
    OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { Announcement } from '../../../common/models/announcement.model';
import { AdminService } from '../../../common/services/admin.service';

@Component({
    selector: 'announcement-center',
    templateUrl: 'announcement-center.component.html',
    styleUrls: ['announcement-center.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementCenterComponent implements OnInit {
    public announcements: BehaviorSubject<Announcement[]> = new BehaviorSubject<Announcement[]>([]);

    constructor(private _adminService: AdminService) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Deletes the announcement at the given index.
     *
     * @param index The index of the announcement to delete.
     */
    public delete(index: number): void {
        const announcements: Announcement[] = this.announcements.getValue();
        announcements.splice(index, 1);
        this.announcements.next(announcements);
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this._adminService.getSettings()
            .first()
            .map(settings => settings.announcements)
            .subscribe(announcements => this.announcements.next(announcements));
    }
}
