import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RegexEntity } from '../../../../models/regex-entity.model';

/**
 * @description
 * Represents the entity creation dialog component for creating new
 * regex entities.
 */
@Component({
	selector: 'regex-creation',
	templateUrl: 'regex-creation.component.html',
	styleUrls: ['regex-creation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegexCreationComponent {
	@Input() public entity: RegexEntity;
	@Output() public changedEntity: EventEmitter<RegexEntity> = new EventEmitter<RegexEntity>();
	@Output() public enterClicked: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * @method
	 * @description
	 * Adds the regex field text as the entity's regex value. Notifies the parent that
	 * the enter key was clicked.
	 */
	public onEnterClick(): void {
		this.entity.regex = this.contextEntityRegex;
		this.changedEntity.emit(this.entity);
		this.enterClicked.emit();
	}

	/**
	 * @method
	 * @description
	 * Gets the value of the current entity regex.
	 *
	 * @returns The regex of the entity.
	 */
	get contextEntityRegex(): string {
		return this.entity.regex;
	}

	/**
	 * @method
	 * @description
	 * Sets the current entity's regex with the given argument.
	 *
	 * @param regex The regex to set.
	 */
	set contextEntityRegex(regex: string) {
		this.entity.regex = regex;
		this.changedEntity.emit(this.entity);
	}
}
