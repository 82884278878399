import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { UtteranceInspectorModule } from '../utterance-inspector/utterance-inspector.module';
import { ChatBladeComponent } from './chat-blade/chat-blade.component';
import { ChatContainerComponent } from './chat-blade/chat-container/chat-container.component';
import { DebugBladeComponent } from './debug-blade/debug-blade.component';
import { EndpointDebugBladeComponent } from './endpoint-debug-blade/endpoint-debug-blade.component';
import { SettingsModalComponent } from './endpoint-debug-blade/settings-modal/settings-modal.component';

@NgModule({
	imports: [SharedModule, UtteranceInspectorModule],
	declarations: [ChatBladeComponent, ChatContainerComponent, DebugBladeComponent, EndpointDebugBladeComponent, SettingsModalComponent],
	exports: [ChatBladeComponent, ChatContainerComponent, DebugBladeComponent, EndpointDebugBladeComponent, SettingsModalComponent],
	providers: [],
	entryComponents: [SettingsModalComponent]
})
export class BladesModule {}
