import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs/Rx';

/**
 * @description
 * Represents a version name input component. The version name input is
 * responsible for version name data entry and validation.
 */
@Component({
	selector: 'version-name-input',
	templateUrl: './version-name-input.component.html',
	styleUrls: ['./version-name-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionNameInputComponent implements OnInit, OnDestroy {
	@Input() public isVersionNameRequired: boolean = true;
	@Input() public initialVersionName: string = '';

	@Output() public versionNameSubmit: EventEmitter<string> = new EventEmitter<string>();

	public versionNameSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public versionNameValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public validationMessage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	private _versionNameSubscription: Subscription;
	private readonly _reservedWords: string[] = ['import'];

	constructor(private readonly _i18n: TranslateService) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._versionNameSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Gets the current value of the version name stream.
	 *
	 * @returns The current value of the version name stream.
	 */
	get versionName(): string {
		return this.versionNameSubject.getValue();
	}

	/**
	 * @description
	 * Sets the version name stream to the given value.
	 */
	set versionName(value: string) {
		this.versionNameSubject.next(value.trim());
	}

	/**
	 * @description
	 * Submits the version name by emitting the event.
	 */
	public submitVersionName(): void {
		this.versionNameSubmit.emit(this.versionName);
	}

	/**
	 * @description
	 * Validates the version name input.
	 *
	 * @param versionName The version name
	 * @returns True if the version name is valid and false otherwise.
	 */
	private _isValidVersionName(versionName: string): boolean {
		const specialChars: string[] = ['\\', '/', ':', '?', '&', '=', '*', '+', '(', ')', '%', '@', '$', '~', '!', '#'];

		if (specialChars.filter(c => versionName.indexOf(c) !== -1).length !== 0) {
			this.validationMessage.next(this._i18n.instant('apps.version-name.special_char_error', { chars: specialChars.join(' ') }));

			return false;
		}

		if (this.isVersionNameRequired && versionName === '') {
			return false;
		}

		if (versionName.length > 128) {
			this.validationMessage.next(this._i18n.instant('apps.version-name.char_count_error', { maxNameLength: 128 }));

			return false;
		}

		if (versionName.endsWith('.')) {
			this.validationMessage.next(this._i18n.instant('apps.version-name.dot_end_error'));

			return false;
		}

		if (this._reservedWords.filter(word => word === versionName.toLocaleLowerCase()).length !== 0) {
			this.validationMessage.next(this._i18n.instant('apps.version-name.reserved_word_error', { word: versionName }));

			return false;
		}

		this.validationMessage.next(null);

		return true;
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.versionName = this.initialVersionName;

		this._versionNameSubscription = this.versionNameSubject
			.asObservable()
			.subscribe(vN => this.versionNameValid.next(this._isValidVersionName(vN)));
	}
}
