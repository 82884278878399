import { NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from '@luis/core';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { IntentCreationModalComponent } from './components/intent-creation-modal/intent-creation-modal.component';
import { IntentDeleteModalComponent } from './components/intent-delete-modal/intent-delete-modal.component';
import { IntentDropdownComponent } from './components/intent-dropdown/intent-dropdown.component';
import { IntentListComponent } from './components/intent-list/intent-list.component';
import { IntentTableComponent } from './components/intent-list/intent-table/intent-table.component';
import { IntentRenameModalComponent } from './components/intent-rename-modal/intent-rename-modal.component';
import { INTENT_SERVICE_TOKEN } from './interfaces/IIntentService';
import { IntentScorePipe } from './pipes/intent-score.pipe';
import { IntentFactoryService } from './services/intent-factory.service';
import { IntentUtilitiesService } from './services/intent-util.service';
import { IntentService } from './services/intent.service';

@NgModule({
	imports: [CoreModule, FormsModule, IntercomUIModule, NgxPaginationModule, TranslateModule, MatDialogModule],
	exports: [IntentListComponent, IntentDropdownComponent, IntentScorePipe],
	declarations: [
		IntentListComponent,
		IntentCreationModalComponent,
		IntentTableComponent,
		IntentRenameModalComponent,
		IntentDeleteModalComponent,
		IntentDropdownComponent,
		IntentScorePipe
	],
	entryComponents: [IntentCreationModalComponent, IntentDeleteModalComponent, IntentRenameModalComponent]
})
export class IntentsModule {
	public static providersForChild(): Provider[] {
		return [
			IntentFactoryService,
			IntentUtilitiesService,
			{
				provide: INTENT_SERVICE_TOKEN,
				useClass: IntentService
			}
		];
	}
}
