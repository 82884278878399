/**
 * @description
 * Represents an interface for any token selection state object.
 *
 * @param start The start token index of the selection.
 * @param end The end token index of the selection.
 */
export interface ISelection {
    start: number;
    end: number;
}

/**
 * @description
 * Represents an interface for any token hover state object.
 *
 * @param start The start token index of the hovering.
 * @param end The end token index of the hovering.
 */
export interface IHover {
    start: boolean;
    end: boolean;
}

/**
 * @description
 * Represents an interface for any coordinate offset object.
 *
 * @param top The offset of the object relative to some parent from the top.
 * @param left The offset of the object relative to some parent from the left.
 */
export interface IOffset {
    top: number;
    left: number;
}

/**
 * @description
 * The type of view options that utterance table support.
 */
export enum VIEW_OPTIONS {
    TOKEN_LABEL_VIEW,
    ENTITY_LABEL_VIEW,
    SIMPLE_TABLE_VIEW,
    DETAILED_TABLE_VIEW,
    ALL_VIEW,
    ERRORS_VIEW
}
