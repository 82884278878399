import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnInit
} from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../interfaces/IEntityService';
import { Entity, ENTITY_TYPES } from '../../models/entity.model';

/**
 * @description
 * Displays the details of the entity with the given id based on that entity's
 * type. For example, parent type entities have different details than closed
 * list entities.
 */
@Component({
    selector: 'entity-details',
    templateUrl: 'entity-details.component.html',
    styleUrls: ['entity-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityDetailsComponent implements OnInit {
    @Input() public entityId: Observable<string>;
    @Input() public enableListSuggestions: boolean = false;

    public currentEntity: Observable<Entity>;
    public entityTypes: typeof ENTITY_TYPES = ENTITY_TYPES;

    constructor(
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @method
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.currentEntity = Observable.combineLatest(
            this.entityId,
            this._entityService.get(),
            (entityId, entities) => entities.find(e => e.id === entityId))
            .filter(e => e !== null && e !== undefined)
            .map(e => e.clone());
    }
}
