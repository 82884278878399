import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
    GenericPromptService,
    IDataItem,
    IToasterService,
    LuisModel,
    ProgressTracker,
    PromptButtonTypes,
    SelectionMap,
    TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { ENTITY_SERVICE_TOKEN, EntityHelpers, IEntityService } from '@luis/entities';
import { Intent } from '@luis/intents';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Rx';
import { IItemTableToolbarOps } from '../interfaces/IItemTableToolbarOps';
import { IPatternService, PATTERN_SERVICE_TOKEN } from '../interfaces/IPatternService';
import { PatternEntityExtractorFsm } from '../models/fsms/pattern-entity-extractor-fsm.model';
import { Pattern } from '../models/pattern.model';

export interface IInitialData {
    model: LuisModel;

    selectionMap: SelectionMap;

    skip?: number;

    take?: number;

    predictionsTracker?: ProgressTracker;
}

@Injectable()
export class PatternStoreService implements OnDestroy {
    private _patterns: Pattern[] = [];
    private _data: IInitialData;

    constructor(
        private _i18n: TranslateService,
        private _promptService: GenericPromptService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(PATTERN_SERVICE_TOKEN) private _patternService: IPatternService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) {}

    public ngOnDestroy(): void {}

    /**
     * @description
     * Initializes the service.
     *
     * @param data The initial data for this service.
     * @returns The data type this service represents.
     */
    public initialize(data: IInitialData): void {
        this._data = data;
    }

    /**
     * @description
     * Gets the patterns for the current model.
     */
    public fetch(): Observable<IDataItem[]> {
        return Observable.combineLatest(this._patternService.get(), this._entityService.get())
            .do(([patterns, entities]) =>
                patterns.forEach(p => {
                    const fsm: PatternEntityExtractorFsm = new PatternEntityExtractorFsm(p.text, EntityHelpers.expandChildren(entities));
                    fsm.evaluate();
                    p.entities = fsm.getExtractedEntities();
                })
            )
            .map(([patterns]) => patterns)
            .map(items => items.map((item, i) => this._updateItemCache(item, i)));
    }

    /**
     * @description
     * Gets the toolbar ops allowable for patterns
     *
     * @returns An observable
     * of the toolbar ops allowed.
     */
    public getToolbarOps(): Observable<IItemTableToolbarOps> {
        const ops: IItemTableToolbarOps = {
            delete: { enabled: true, label: this._i18n.instant('patterns.pattern-store.delete_patterns') },
            entityFilter: true,
            intentFilter: true,
            errorFilter: false,
            labelView: false,
            reassignIntent: true,
            search: { enabled: true, label: this._i18n.instant('patterns.pattern-store.search_for_patterns'), initialValue: '' }
        };

        return Observable.of(ops);
    }

    /**
     * @description
     * Reassigns the currently selected patterns to the
     * given intent.
     *
     * @param intent The intent to reassign the patterns to.
     * @returns An observable to indicate completion.
     */
    public batchReassign(intent: Intent): Observable<any> {
        const selectedPatterns: Pattern[] = this._patterns
            .filter(p => this._data.selectionMap.selectedItems.indexOf(p.id) !== -1)
            .map(p => p.clone());

        selectedPatterns.forEach(p => (p.intentName = intent.name));

        return this._patternService.batchUpdate(selectedPatterns).trackProgress(this._toasterService.add());
    }

    /**
     * @description
     * Deletes the currently selected patterns.
     *
     * @returns An observable to indicate
     * completion of the deletion process.
     */
    public batchDelete(): Observable<any> {
        const selectedPatterns: Pattern[] = this._patterns.filter(p => this._data.selectionMap.selectedItems.indexOf(p.id) !== -1);

        return this._promptService
            .prompt(
                this._i18n.instant('patterns.pattern-store.delete_patterns_q'),
                this._i18n.instant('patterns.pattern-store.are_you_sure_you_want_delete_patterns', { length: selectedPatterns.length }),
                { ok: this._i18n.instant('patterns.pattern-store.ok'), cancel: this._i18n.instant('patterns.pattern-store.cancel') },
                { ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
            )
            .filter(choice => choice === 'ok')
            .flatMap(() =>
                this._patternService
                    .batchDelete(selectedPatterns)
                    .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('patterns.pattern-store.delete_patterns_toast') }))
            )
            .do(ids => this._data.selectionMap.markUnselected(this._data.selectionMap.selectedItems.filter((id, i) => ids[i] !== null)));
    }

    /**
     * @description
     * Submits the pattern.
     *
     * @returns An observable to
     * indicate completion.
     */
    public submitItem(item: Pattern): Observable<any> {
        const pattern: Pattern = new Pattern('', item.text, item.intentName, item.entities);

        return this._patternService.add(pattern).trackProgress(this._toasterService.add());
    }

    /**
     * @description
     * Updates the items in the local cache. This prevents the utterance table
     * from refreshing all the utterances.
     */
    private _updateItemCache(item: IDataItem, index: number): IDataItem {
        if (this._patterns[index] === undefined || !item.isEqual(this._patterns[index])) {
            this._patterns[index] = item.clone();
        }

        return this._patterns[index];
    }
}
