import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AppPermissions } from '../models/app-permissions.model';

export const APP_PERMISSIONS_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IAppPermissionsService');

/**
 *
 * @description
 * Represents an interface for apps permission service. Permissions service
 * should contain CRUD operations for app collaborators.
 */
export interface IAppPermissionsService {
    get(): Observable<AppPermissions>;

    update(permissions: AppPermissions): Observable<void>;
}
