import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { Toast } from '../../../models/utils/toast.model';

@Component({
    selector: 'toast',
    templateUrl: 'toast.component.html',
    styleUrls: ['toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
    @Input() public toast: Toast;
    @Input() public isShowAllVisible: boolean;
    @Input() public isShowAllActive: boolean;
    @Output() public onDeleteClick: EventEmitter<Toast> = new EventEmitter<Toast>();
    @Output() public onShowAllClick: EventEmitter<void> = new EventEmitter<void>();
}
