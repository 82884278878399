﻿import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { APP_SERVICE_TOKEN, AppEndpointConfig, IAppService } from '@luis/apps';
import {
	BaseService,
	ENDPOINT_VERSIONS,
	GENERIC_CACHE_SERVICE_TOKEN,
	IGenericCacheService,
	LuisConstants,
	ProgressTracker
} from '@luis/core';
import { EndpointKey, EndpointUrlGeneratorPipe, IKeyService, KEY_SERVICE_TOKEN } from '@luis/publish-pane';
import { BLADE_OPS, BladeComponent, BLADES, BladeTrackerService } from '@luis/ui';
import { Utterance } from '@luis/utterances';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { IEndpointDebugSettings } from '../../../interfaces/IEndpointDebugSettings';
import { IUtteranceResultsDto } from '../../../interfaces/IUtteranceResults';
import { getUtteranceEndpointPredictions } from '../../../models/entity-results-extractor.model';
import { SettingsModalComponent } from './settings-modal/settings-modal.component';

/**
 * @description
 * Represents the debug blade for the endpoint response.
 */
@Component({
	selector: 'endpoint-debug-blade',
	templateUrl: 'endpoint-debug-blade.component.html',
	styleUrls: ['endpoint-debug-blade.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [EndpointUrlGeneratorPipe]
})
export class EndpointDebugBladeComponent extends BladeComponent implements OnInit, OnDestroy {
	@Input() public utterance: Observable<Utterance>;
	@Input() public showJsonToggle: boolean = true;
	@Input() public showOptionsToggle: boolean = true;

	public hideTools: Observable<boolean>;
	public publishedResponse: BehaviorSubject<IUtteranceResultsDto> = new BehaviorSubject<IUtteranceResultsDto>(null);
	public rawResponse: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	public isStagingSlot: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public currentSlot: Observable<AppEndpointConfig>;
	public endpointUrl: Observable<string>;
	public tracker: ProgressTracker = new ProgressTracker();
	public keys: Observable<EndpointKey[]>;
	public isRawView: boolean = false;

	protected currentBlade: BLADES = BLADES.ENDPOINT_DEBUG;

	private _utteranceSubscription: Subscription = new Subscription();

	constructor(
		private readonly _baseService: BaseService,
		private readonly _bladeTrackerService: BladeTrackerService,
		private readonly _urlPipe: EndpointUrlGeneratorPipe,
		private readonly _dialogService: MatDialog,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(KEY_SERVICE_TOKEN) private readonly _keyService: IKeyService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		super(_bladeTrackerService);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this._initState();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this._utteranceSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Closes the debug console and closes the edit mode.
	 */
	public closeBlade(): void {
		this._bladeTrackerService.applyOp(BLADE_OPS.ENDPOINT_DEBUG_CLOSE);
	}

	/**
	 * @description
	 * Opens the additional settings modal.
	 */
	public openSettingsModal(): void {
		this._dialogService
			.open(SettingsModalComponent)
			.afterClosed()
			.subscribe(() => this._getEndpointResponse());
	}

	/**
	 * @description
	 * Switches between the endpoint slots.
	 */
	public switchSlot(): void {
		const isStaging: boolean = this.isStagingSlot.getValue();
		this.isStagingSlot.next(!isStaging);
		this._getEndpointResponse();
	}

	/**
	 * @description
	 * Initialize the component.
	 */
	private _initState(): void {
		this._initSettingsInCache();

		this.currentSlot = Observable.combineLatest(this._appService.getSingle(), this.isStagingSlot.asObservable()).pipe(
			map(([app, isStaging]) => app.endpointConfigs.find(c => c.isStaging === isStaging))
		);

		this.keys = this._keyService.getAppKeys();

		this.endpointUrl = Observable.combineLatest(
			this.currentSlot,
			this.keys,
			this._cacheService.get(LuisConstants.DEBUG_SETTINGS_KEY, null, this._baseService.optionsBuilder.useCacheOnly().build())
		)
			.filter(([appSlot]) => appSlot !== undefined)
			.flatMap(([appSlot, keys, settings]) => {
				const programmaticKey: EndpointKey = keys.find(k => k.keyStrings.key1 === this._baseService.configs.userSubKey);
				let debugSettings = <IEndpointDebugSettings>settings;

				if (debugSettings === undefined) {
					debugSettings = { key: programmaticKey, settings: {}, bingKey: '' };
				} else if (!debugSettings.key) {
					debugSettings.key = programmaticKey;
				}

				debugSettings.settings.staging = appSlot.isStaging;
				debugSettings.settings.endpointVersion = ENDPOINT_VERSIONS.VERSION_3;
				debugSettings.settings.multiIntentEnabled = true;

				return this._urlPipe.transform(debugSettings.key, Observable.of(debugSettings.settings), debugSettings.bingKey);
			});

		this.hideTools = this._bladeTrackerService.getBladeStates().map(m => !m.get(BLADES.ENDPOINT_DEBUG));

		this._utteranceSubscription = this.utterance.subscribe(() => this._getEndpointResponse());
	}

	/**
	 * @description
	 * Sets the cache with an empty settings object to persist
	 * the blade settings accross this session.
	 */
	private _initSettingsInCache(): void {
		const opts = this._baseService.optionsBuilder.useCacheOnly().build();
		const defaultSettings: IEndpointDebugSettings = {
			settings: { verbose: true },
			key: null,
			bingKey: ''
		};

		this._cacheService.post(LuisConstants.DEBUG_SETTINGS_KEY, defaultSettings, opts).subscribe();
	}

	/**
	 * @description
	 * Gets the endpoint response for the current url and utterance.
	 */
	private _getEndpointResponse(): void {
		Observable.combineLatest(this.utterance.first(), this.endpointUrl.first())
			.do(([utterance]) => {
				if (utterance === null || utterance === undefined) {
					this.publishedResponse.next(null);
					this.rawResponse.next(null);
				}
			})
			.filter(([utterance]) => utterance !== null && utterance !== undefined)
			.switchMap(([utterance, url]) =>
				this._httpService
					.get(`${url}${encodeURI(utterance.text)}`, this._baseService.optionsBuilder.build())
					.trackProgress(this.tracker.getTracker())
			)
			.do(response => this.rawResponse.next(this.highlightJsonSyntax(JSON.stringify(response, null, 2))))
			.map(getUtteranceEndpointPredictions)
			.subscribe(utterance => this.publishedResponse.next(utterance));
	}

	/**
	 * @description
	 * Highlights the given JSON syntax based on the data type.
	 *
	 * @param json The json string to highlight.
	 * @returns The string with the classes added.
	 */
	private highlightJsonSyntax(json: string): string {
		const formattedJson = json
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;');

		return formattedJson.replace(
			/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
			match => {
				let cls: string = 'number';

				if (/^"/.test(match)) {
					cls = /:$/.test(match) ? 'key' : 'string';
				} else if (/true|false/.test(match)) {
					cls = 'boolean';
				} else if (/null/.test(match)) {
					cls = 'null';
				}

				return `<span class="${cls}">${match}</span>`;
			}
		);
	}
}
