import { Component, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'm-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InputComponent {
    @Input("input-style") inputClassName: string = '';
    @Input("place-holder") placeHolderText: string;
    @Input("value") value: string;
    @Input("id") inputId: string;

    @Output("save") onSave: EventEmitter<string> = new EventEmitter();
    @Output("valueChange") valueChange: EventEmitter<string> = new EventEmitter();

    public save(): void {
        this.onSave.emit(this.value);
    }

    public onValueChange(e: UIEvent) {
        this.valueChange.emit(this.value);
    }
}
