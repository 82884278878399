import { ENVIRONMENT, IEnvironment } from '@luis/core';
/**
 * @const
 * @description
 * Represents a configuration for the Luis development environment.
 */
export const environment: IEnvironment = {
    production: false,
    env: ENVIRONMENT.USGOVVIRGINIA,
    directApiUrl: 'https://dialogicefairfax.usgovcloudapp.net/api/v2.0',
    directWebApiUrl: 'https://dialogicefairfax.usgovcloudapp.net/webapi/v2.0',
    apiUrl: 'https://dialogicefairfax.usgovcloudapp.net/api/v2.0',
    webApiUrl: 'https://dialogicefairfax.usgovcloudapp.net/api/v2.0',
    blobStoreUrl: 'https://luisuswestfrontend.blob.core.windows.net'
};
