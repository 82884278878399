import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

/**
 * This is the anchor component that will render using melder styles
 */

@Component({
    selector: 'a[m-anchor], button[m-anchor]',
    template: '<ng-content></ng-content>',
    styleUrls: ['anchor.component.scss'],
    host: {
        '[class.m-anchor-link]': 'true'
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AnchorComponent { }
