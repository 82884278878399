import { Inject, Injectable, OnDestroy } from '@angular/core';
import { IWebSocketRequest, SUPPORTED_SOCKET_CALLS, WebsocketService } from '@luis/api';
import { APP_SERVICE_TOKEN, APP_VERSION_SERVICE_TOKEN, IAppService, IAppVersionService } from '@luis/apps';
import { AuthService } from '@luis/auth';
import {
	BaseService,
	IToasterService,
	ITrackerMessage,
	ITrainingService,
	PROGRESS_STATES,
	TOASTER_SERVICE_TOKEN,
	TRAINING_SERVICE_TOKEN
} from '@luis/core';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { IPhraseListService, PHRASE_LIST_SERVICE_TOKEN } from '@luis/features';
import { IIntentService, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { TranslateService } from '@ngx-translate/core';
import { Observer, Subscription } from 'rxjs/Rx';
import { CollaboratorEvent } from '../models/collaborator-event.model';

@Injectable()
export class CollaboratorEventsProxyService implements OnDestroy {
	private _collaboratorEventsSubscription: Subscription = new Subscription();

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _authService: AuthService,
		private readonly _baseService: BaseService,
		private readonly _websocketService: WebsocketService,
		@Inject(TRAINING_SERVICE_TOKEN) private readonly _trainingService: ITrainingService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _versionService: IAppVersionService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService,
		@Inject(PHRASE_LIST_SERVICE_TOKEN) private readonly _phraseListService: IPhraseListService
	) {}

	public ngOnDestroy(): void {
		this._collaboratorEventsSubscription.unsubscribe();
	}

	/**
	 * @method
	 * @description
	 * Initializes the service.
	 */
	public initService(): void {
		this._initCollaboratorsSubscription();
	}

	/**
	 * @description
	 * Initializes the subscription to LUIS's Redis pub/sub connection through
	 * the web sockets hub. Subscribes to the web socket service bus for messages
	 * matching the generated caller id. The sequence is as follows:
	 *
	 * 1) Client --- (web sockets) ---> Luis WebApp
	 *
	 * 2) Luis WebApp --- (redis connection) ---> Luis Redis Store
	 *
	 * 3) Luis Redis Store --- (redis connection pub/sub) ---> Luis WebApp
	 *
	 * 4) Luis WebApp --- (web sockets) ---> Client
	 */
	private _initCollaboratorsSubscription(): void {
		const id: number = Date.now();
		const request: IWebSocketRequest = {
			appId: this._baseService.configs.appId,
			versionId: this._baseService.configs.versionId,
			queryParams: { user: encodeURIComponent(this._authService.authInfo.email) }
		};

		this._websocketService.invokeCall(id, SUPPORTED_SOCKET_CALLS.REDIS_SUBSCRIBE, this._baseService.configs.userSubKey, request);

		this._collaboratorEventsSubscription = this._websocketService.messageBus
			.filter(m => m !== undefined && m !== null)
			.filter(m => m.id === id)
			.map(m => JSON.parse(m.body))
			.map(data => CollaboratorEvent.importFromApi(data))
			.subscribe(e => this._handleCollaboratorEvent(e), error => null);
	}

	/**
	 * @description
	 * Reacts to the collaborator event message received and refreshes the
	 * appropiate resources based on the message.
	 *
	 * @param event The event message received from the websocket.
	 */
	private _handleCollaboratorEvent(event: CollaboratorEvent): void {
		let message: string = 'portal.collaborator-events-proxy.one_of_your_resource_has_been_modified';
		let resource: string;

		if (!event.isMutative()) {
			return;
		}

		if (event.isIntentChange()) {
			resource = 'portal.collaborator-events-proxy.intents';
			this._intentService.get(true);
		} else if (event.isEntityChange()) {
			resource = 'portal.collaborator-events-proxy.entities';
			this._entityService.get(true);
		} else if (event.isFeatureChange()) {
			resource = 'portal.collaborator-events-proxy.features';
			this._phraseListService.get(true);
		} else if (event.isAppChange()) {
			resource = 'portal.collaborator-events-proxy.apps';
			this._appService.get(0, true);
		} else if (event.isTrain()) {
			message = 'portal.collaborator-events-proxy.collaborator_has_issued_train';
			this._versionService.get(0, null, true);
			this._trainingService.train();
		} else if (event.isPublish()) {
			message = 'portal.collaborator-events-proxy.collaborator_has_issued_publish';
			this._versionService.get(0, null, true);
		} else {
			resource = 'portal.collaborator-events-proxy.resources';
		}

		message = this._i18n.instant(message, { resource: this._i18n.instant(resource) });

		const toastTracker: Observer<ITrackerMessage> = this._toasterService.add({ startMsg: message });
		setTimeout(() => toastTracker.next({ state: PROGRESS_STATES.ENDED }), 5000);
	}
}
