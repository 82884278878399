import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { GATEKEEPER_FEATURE, IResourceOperation, SelectionMap } from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { INTENT_OPERATIONS } from '../../../models/intent-operation.model';
import { Intent } from '../../../models/intent.model';
import { IntentUtilitiesService } from '../../../services/intent-util.service';

/**
 * @description
 * Represents an intent table. Lists the intents in an application. Provides
 * tools for manipulating the intents in the table.
 */
@Component({
    selector: 'intent-table',
    templateUrl: './intent-table.component.html',
    styleUrls: ['./intent-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntentTableComponent implements OnInit {
    @Input() public intents: Observable<Intent[]>;
    @Input() public showDomainIntentButton: boolean;

    @Output() public clickedIntent: EventEmitter<Intent> = new EventEmitter<Intent>();
    @Output() public applyOperation: EventEmitter<IResourceOperation> = new EventEmitter<IResourceOperation>();

    public selectionMap: SelectionMap = new SelectionMap();
    public currentPage: number;
    public intentOps: any = INTENT_OPERATIONS;
    public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public displayedIntents: Observable<Intent[]>;
    public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
        property: 'name', type: SORT_TYPE.ASCENDING
    });
    public gateKeeperFeature: typeof GATEKEEPER_FEATURE = GATEKEEPER_FEATURE;

    constructor(private _intentUtilService: IntentUtilitiesService) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Notifies parent that an operation was clicked to apply to the given intent.
     *
     * @param operation The operation to run. Should be one of the allowed operations
     * in the INTENT_OPERATIONS enum.
     */
    public onOperationClick(operation: INTENT_OPERATIONS): void {
        const selectedIntentsIds: string[] = <string[]>this.selectionMap.selectedItems;
        const intentOp: IResourceOperation = { items: [], operation: operation, response: new BehaviorSubject<boolean>(false) };

        this.intents
            .first()
            .map(intents => intents.filter(intent => selectedIntentsIds.indexOf(intent.id) !== -1))
            .do(selectedIntents => intentOp.items = selectedIntents)
            .subscribe(() => this.applyOperation.emit(intentOp));

        intentOp.response.subscribe(null, null, () => this.selectionMap.markUnselected(selectedIntentsIds));
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this.displayedIntents = Observable.combineLatest(this.intents, this._intentUtilService.getLabelsPerIntent())
            .map(([intents, labelsCount = new Map<string, number>()]) => intents.map(i => new Intent(i.id, i.name, labelsCount.get(i.id))));
    }
}
