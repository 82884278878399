import { HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService, IRequestOptions } from '@luis/api';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, last, map } from 'rxjs/operators';
import { PROGRESS_STATES } from '../../helpers/track-progress.operator';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '../../interfaces/services/utils/IToasterService';

@Injectable()
export class FileService {
	constructor(
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService
	) {}

	/**
	 * @description
	 * Downloads data from the given url.
	 *
	 * @param url The url to download from.
	 * @param fileName The file name to save the data to.
	 * @param options The request options to use.
	 * @param showSuccessToast A flag to indicate whether the terminal
	 * toast is to be used or not.
	 * @returns An observable to indicate completion.
	 */
	public download(url: string, fileName: string, options: IRequestOptions, showSuccessToast: boolean = true): Observable<void> {
		let downloadTracker = this._toasterService.add({ startMsg: 'Processing file on server ...' });

		return this._httpService.download(url, options).pipe(
			map(ev => {
				switch (ev.type) {
					case HttpEventType.DownloadProgress:
						const percentDone = Math.round((ev.loaded * 100) / ev.total);
						const msg = `Downloading file ... ${percentDone}% completed`;

						downloadTracker.next({ state: PROGRESS_STATES.ENDED });
						downloadTracker = this._toasterService.add({ startMsg: msg });
						break;
					case HttpEventType.Response:
						downloadTracker.next({ state: PROGRESS_STATES.ENDED });

						if (showSuccessToast) {
							downloadTracker = this._toasterService.add({ endMsg: 'Download completed successfully' });
							downloadTracker.next({ state: PROGRESS_STATES.ENDED });
						}
						
						this.save(ev.body, fileName);
						break;
					default:
				}
			}),
			last(),
			catchError(error => {
				downloadTracker.next({ state: PROGRESS_STATES.ENDED });

				return throwError(error);
			})
		);
	}

	/**
	 * @description
	 * Uploads the given data to the server.
	 *
	 * @param url The url to upload to.
	 * @param body The data to upload.
	 * @param options The request options to use.
	 * @param showSuccessToast A flag to indicate whether the terminal
	 * toast is to be used or not.
	 * @returns An observable to indicate completion.
	 */
	public upload<T>(url: string, body: Object, options: IRequestOptions, showSuccessToast: boolean = true): Observable<T> {
		let uploadTracker = this._toasterService.add({ startMsg: 'Uploading file ...' });

		return this._httpService.upload<T>(url, body, options).pipe(
			map(ev => {
				switch (ev.type) {
					case HttpEventType.UploadProgress:
						const percentDone = Math.round((ev.loaded * 100) / ev.total);
						const msg = percentDone === 100 ? 'Processing file on server ...' : `Uploading file ... ${percentDone}% completed`;

						uploadTracker.next({ state: PROGRESS_STATES.ENDED });
						uploadTracker = this._toasterService.add({ startMsg: msg });
						break;
					case HttpEventType.Response:
						uploadTracker.next({ state: PROGRESS_STATES.ENDED });

						if (showSuccessToast) {
							uploadTracker = this._toasterService.add({ endMsg: 'Upload completed successfully' });
							uploadTracker.next({ state: PROGRESS_STATES.ENDED });
						}

						return ev.body;
					default:
				}
			}),
			last(),
			catchError(error => {
				uploadTracker.next({ state: PROGRESS_STATES.ENDED });

				return throwError(error);
			})
		);
	}

	/**
	 * @description
	 * Saves the data given via browser download dialog.
	 *
	 * @param data The data to save.
	 * @param fileName The file name to save it as.
	 */
	public save(data: string | Blob, fileName: string): void {
		if ('chrome' in window) {
			const link = document.createElement('a');
			const urlRef = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));

			link.href = urlRef;
			link.download = fileName;
			link.click();
		} else if ('msSaveOrOpenBlob' in window.navigator) {
			const blob = new Blob([data]);
			window.navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			const file = new File([data], fileName);
			window.open(URL.createObjectURL(file));
		}
	}

	/**
	 * @description
	 * Reads the file given from disk to a string variable.
	 *
	 * @param file The file to read from disk.
	 * @returns An observable of the content of the file.
	 */
	public read(file: File): Observable<string> {
		return Observable.create((observer: Observer<string>) => {
			const fileReader: FileReader = new FileReader();

			fileReader.readAsText(file);
			fileReader.onload = () => {
				observer.next(<string>fileReader.result);
				observer.complete();
			};
			fileReader.onerror = e => observer.error(e);
		});
	}
}
