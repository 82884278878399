import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { UuidGeneratorService } from '../services';

/**
 * @description
 * Represents a section in the sidebar
 * page.
 */
@Component({
	selector: 'm-extendable-card',
	templateUrl: 'extendable-card.component.html',
	styleUrls: ['extendable-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendableCardComponent {
	@Input() public cardTitle: string = '';
	@Input() public callout: string = null;
	@Input() public isExpanded: boolean = true;

	@Output() public expandingToggled: EventEmitter<void> = new EventEmitter<void>();

	@ContentChild(TemplateRef) public detailRef;

	public ariaId: string;

	constructor(private readonly _uuidService: UuidGeneratorService) {
		// Generate guid for card content panel
		this.ariaId = this._uuidService.get('m-id-');
	}

	public toggleExpanding(): void {
		this.isExpanded = !this.isExpanded;
		this.expandingToggled.emit();
	}
}
