import { Inject, Injectable, OnDestroy } from '@angular/core';
import { RequestOptionsBuilder } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	EventBusMessage,
	EventBusService,
	Id,
	IModelChangeBusMessage,
	IResourceCacheService,
	MODEL_CHANGE_TYPE,
	RESOURCE_CACHE_SERVICE_TOKEN
} from '@luis/core';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { Observable, Subscription } from 'rxjs/Rx';
import { IIntentService } from '../interfaces/IIntentService';
import { Intent, INTENT_TYPES } from '../models/intent.model';
import { IntentFactoryService } from './intent-factory.service';

/**
 * @description
 * Represents a concrete implementation of the IIntentService interface for intent related
 * operations for a LUIS application. This implementation is meant for production use.
 */
@Injectable()
export class IntentService implements IIntentService, OnDestroy {
	private _domainChangeSubscription: Subscription = new Subscription();

	constructor(
		private _baseService: BaseService,
		private _intentFactoryService: IntentFactoryService,
		private _eventBus: EventBusService,
		@Inject(RESOURCE_CACHE_SERVICE_TOKEN) private _resourceCacheService: IResourceCacheService,
		@Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
	) {
		this._subscribeToEventBus();
	}

	public ngOnDestroy(): void {
		this._domainChangeSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Gets the base web url for manipulating application's intents.
	 *
	 * @returns The base url for intents manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}/intents`;
	}

	/**
	 * @description
	 * Gets the intents of the application.
	 *
	 * @returns An observable of the application's intents.
	 */
	public get(refresh: boolean = false): Observable<Intent[]> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return <Observable<Intent[]>>(
			this._resourceCacheService.get(
				`${this._baseUrl}?take=500`,
				this._intentFactoryService.importFromApi.bind(this._intentFactoryService),
				builder.build(),
				this._baseUrl
			)
		);
	}

	/**
	 * @description
	 * Adds an intent to the application.
	 *
	 * @param intent The intent to add.
	 * @param refresh If true, the intent is refresehd from the api after adding it.
	 * @returns An observable of the id of the newly added intent.
	 */
	public add(intent: Intent, refresh: boolean = false): Observable<Id | void> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;
		const modelChangeMessage: IModelChangeBusMessage = {
			model: intent,
			changeType: MODEL_CHANGE_TYPE.ADD
		};

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return this._resourceCacheService
			.post(
				this._intentFactoryService.getPostUrl(intent.type),
				intent,
				builder.build(),
				this._intentFactoryService.getParserFunction(intent.type),
				this._baseUrl
			)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)))
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_CHANGED, modelChangeMessage)))
			.do(() => {
				if (intent.type === INTENT_TYPES.DOMAIN) {
					this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.INVALIDATE_ENTITIES_CACHE, null));
					this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.INVALIDATE_PATTERNS_CACHE, null));
				}
			});
	}

	/**
	 * @description
	 * Updates an existing intent in the application.
	 *
	 * @param intent The intent to update.
	 * @returns An observable to indicate completion.
	 */
	public update(intent: Intent): Observable<void> {
		const modelChangeMessage: IModelChangeBusMessage = { model: intent, changeType: MODEL_CHANGE_TYPE.UPDATE };
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder.skipMarkDirty();

		return this._resourceCacheService
			.put(`${this._baseUrl}/${intent.id}`, intent, builder.build(), this._baseUrl)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_CHANGED, modelChangeMessage)));
	}

	/**
	 * @description
	 * Deletes an existing intent from the application.
	 *
	 * @param id The id of the intent to delete.
	 * @param keepUtterances Flag whether to keep utterances in None intent or not.
	 * @returns An observable to indicate completion.
	 */
	public delete(intent: Intent, keepUtterances: boolean = false): Observable<void> {
		const options = this._baseService.defaultOptionsBuilder.build();
		if (!keepUtterances) {
			options.params = options.params.append('deleteUtterances', 'true');
		}

		return this._resourceCacheService
			.delete(`${this._baseUrl}/${intent.id}`, intent.id, options, this._baseUrl)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)))
			.do(() => this._notifyIntentsDeletion([intent]))
			.do(() => (keepUtterances ? this._deleteNoneIntentCache() : null));
	}

	/**
	 * @description
	 * Deletes existing intents from the application.
	 *
	 * @param intents The intents to delete.
	 * @returns An observable to indicate completion.
	 */
	public batchDelete(intents: Intent[], keepUtterances: boolean = false): Observable<Id[]> {
		const options = this._baseService.defaultOptionsBuilder.build();
		if (!keepUtterances) {
			options.params = options.params.append('deleteUtterances', 'true');
		}

		return this._resourceCacheService
			.batchDelete(this._baseUrl, intents.map(p => p.id), options, false)
			.do(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.NEEDS_TRAINING, null)))
			.do(() => this._notifyIntentsDeletion(intents))
			.do(() => (keepUtterances ? this._deleteNoneIntentCache() : null));
	}

	/**
	 * @description
	 * Sends a bus event to delete the none intent utterance cache.
	 * This is because the none intent gained new utterances from an
	 * intent that was deleted with 'keepUtterances' option turned on.
	 */
	private _deleteNoneIntentCache(): void {
		this.get()
			.first()
			.map(intents => intents.find(i => i.name === 'None').id)
			.subscribe(intentId => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.DELETE_NONE_INTENT, intentId)));
	}

	/**
	 * @description
	 * Sends events to notify that the given intents have
	 * been deleted.
	 *
	 * @param intents The intents to notify for.
	 */
	private _notifyIntentsDeletion(intents: Intent[]): void {
		intents.forEach(i => {
			const modelChangeMessage: IModelChangeBusMessage = { model: i, changeType: MODEL_CHANGE_TYPE.DELETE };
			setTimeout(() => this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_CHANGED, modelChangeMessage)), 0);
		});
	}

	/**
	 * @description
	 * Subscribes to event bus to react to certain bus events.
	 */
	private _subscribeToEventBus(): void {
		this._domainChangeSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.DOMAIN_CHANGED, () => this.get(true));
	}
}
