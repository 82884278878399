import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { UuidGeneratorService } from '../services/uuid-generator.service';

const sizeMap = {
    medium: 'md',
    small: 'sm',
    large: 'lg'
};

/**
 * @description
 * This is the default button component that will
 * render the default Office Fabric button.
 */
@Component({
    selector: 'button[m-button], a[m-button]',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.scss'],
    host: {
        '[attr.aria-labelledby]': 'ariaLabelId || null',
        '[attr.aria-describedby]': 'ariaDescriptionId || null',
        '[attr.aria-disabled]': 'disabled',
        '[attr.disabled]': 'disabled || null',
        '[class.m-button-base]': 'true'
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit, OnChanges {
    @Input('icon') icon: string = '';
    @Input('aria-label') ariaLabel: string = '';
    @Input('aria-description') ariaDescription: string = '';
    @Input('disabled') disabled: string;

    @Input('m-button') buttonType: string = 'secondary';
    @Input('m-button-outlined') outlined: boolean = false;
    @Input('m-button-size') size: string = 'medium';

    ariaLabelId: string;
    ariaDescriptionId: string;
    currentButtonClass: string;
    targetElement: HTMLElement;

    constructor(
        private _uuidService: UuidGeneratorService,
        private _renderer: Renderer,
        elementRef: ElementRef) {
        this.targetElement = elementRef.nativeElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const newButtonClass = this.getButtonClass();
        if (this.currentButtonClass === newButtonClass) {
            return;
        }

        this._renderer.setElementClass(this.targetElement, this.currentButtonClass, false);
        this._renderer.setElementClass(this.targetElement, newButtonClass, true);

        if (this.buttonType === 'primary') {
            this._renderer.setElementClass(this.targetElement, 'm-focus-dark', true);
        }

        this.currentButtonClass = newButtonClass;
    }

    ngOnInit(): void {
        this.ariaLabelId = null;
        this.ariaDescriptionId = null;

        if (!this.ariaLabel) {
            this.ariaLabelId = this._uuidService.get('m-id-');
        }

        if (this.ariaDescription) {
            this.ariaDescriptionId = this._uuidService.get('m-id-');
        }
    }

    private getButtonClass(): string {
        const size = (this.size && sizeMap[this.size.toLocaleLowerCase()]) || 'md';
        let style = this.buttonType || 'secondary';

        if (this.outlined) {
            style = `${style}-outline`;
        }

        if (this.disabled) {
            style = 'disabled';
        }

        return `m-button-${style}-${size}`;
    }
}
