import { NgModule, Provider } from '@angular/core';
import { BATCH_TESTING_SERVICE_TOKEN } from '../../interfaces/IBatchTestingService';
import { ConfusionCountAsyncPipe } from '../../pipes/confusion-count-async.pipe';
import { BatchTestingService } from '../../services/batch-testing.service';
import { SharedModule } from '../../shared/shared.module';
import { BatchTestingBladeComponent } from './batch-testing-blade.component';
import { DatasetImportModalComponent, IMPORT_DATASET_MODAL_COMP_TOKEN } from './dataset-import-modal/dataset-import-modal.component';
import { DatasetRenameModalComponent, RENAME_DATASET_MODAL_COMP_TOKEN } from './dataset-rename-modal/dataset-rename-modal.component';
import { DatasetResultsComponent } from './dataset-results/dataset-results.component';
import { ModelResultsComponent } from './dataset-results/model-results/model-results.component';
import { ModelSidebarComponent } from './dataset-results/model-sidebar/model-sidebar.component';
import { ResultUtterancesComponent } from './dataset-results/result-utterances/result-utterances.component';
import { ResultsGraphComponent } from './dataset-results/results-graph/results-graph.component';
import { DatasetTableComponent } from './dataset-table/dataset-table.component';

@NgModule({
	imports: [SharedModule],
	declarations: [
		BatchTestingBladeComponent,
		DatasetImportModalComponent,
		DatasetRenameModalComponent,
		DatasetResultsComponent,
		DatasetTableComponent,
		ModelResultsComponent,
		ModelSidebarComponent,
		ResultsGraphComponent,
		ResultUtterancesComponent,
		ConfusionCountAsyncPipe
	],
	exports: [
		BatchTestingBladeComponent,
		DatasetImportModalComponent,
		DatasetRenameModalComponent,
		DatasetResultsComponent,
		DatasetTableComponent,
		ModelResultsComponent,
		ModelSidebarComponent,
		ResultsGraphComponent,
		ResultUtterancesComponent,
		ConfusionCountAsyncPipe
	],
	providers: [],
	entryComponents: [DatasetImportModalComponent, DatasetRenameModalComponent]
})
export class BatchTestingBladeModule {
	public static providersForChild(): Provider[] {
		return [
			{
				provide: BATCH_TESTING_SERVICE_TOKEN,
				useClass: BatchTestingService
			},
			{
				provide: IMPORT_DATASET_MODAL_COMP_TOKEN,
				useValue: {
					componentType: DatasetImportModalComponent,
					defaultParams: {}
				}
			},
			{
				provide: RENAME_DATASET_MODAL_COMP_TOKEN,
				useValue: {
					componentType: DatasetRenameModalComponent,
					defaultParams: {}
				}
			}
		];
	}
}
