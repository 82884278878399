import { Inject, Injectable } from '@angular/core';
import { IRequestOptions } from '@luis/api';
import { Observable } from 'rxjs/Rx';
import { ILinkable } from '../../interfaces/models/ILinkable';
import { Id, IResource, IResourceApiParser } from '../../interfaces/models/IResource';
import { ILinkableCacheAdapterService } from '../../interfaces/services/caches/ILinkableCacheAdapterService';
import { IResourceCacheService, RESOURCE_CACHE_SERVICE_TOKEN } from '../../interfaces/services/caches/IResourceCacheService';
import { BaseService } from '../utils/base.service';

/**
 * @description
 * Represents a concrete implementation for the Ilinkable data resources to data service layer adapter interface.
 */
@Injectable()
export class LinkableCacheAdapterService implements ILinkableCacheAdapterService {
	constructor(
		private readonly _baseService: BaseService,
		@Inject(RESOURCE_CACHE_SERVICE_TOKEN) private readonly _resourceCacheService: IResourceCacheService
	) {}

	/**
	 * @description
	 * Adds a new data object to the application.
	 *
	 * @param data The data object to add.
	 * @param parser The callback to use to parse the api response. Used when refresh is true.
	 * @param prefixMap A type prefix map to use to build url path for non-root elements
	 * @param rootPrefixMap A type prefix map to use to build url path for root elements
	 * @param options Options to pass to the http service.
	 * @param cachePath A cache path that acts as key to the cache store.
	 * @returns An observable of the id of the created entity.
	 */
	public add(
		data: ILinkable & IResource,
		parser: IResourceApiParser,
		prefixMap: Map<number, string>,
		rootPrefixMap: Map<number, string>,
		options: IRequestOptions,
		cachePath: string
	): Observable<Id | void> {
		return this._resourceCacheService.post(
			`${this._baseService.configs.apiUrl}/${this._getPathUrl(data, prefixMap, rootPrefixMap)}`,
			data,
			options,
			parser,
			cachePath,
			this._getParent(data)
		);
	}

	/**
	 * @description
	 * Updates an existing data object in the application.
	 *
	 * @param data The data object to update.
	 * @param parser The callback to use to parse the api response. Used when refresh is true.
	 * @param prefixMap A type prefix map to use to build url path for non-root elements.
	 * @param rootPrefixMap A type prefix map to use to build url path for root elements.
	 * @param options Options to pass to the http service.
	 * @param cachePath A cache path that acts as key to the cache store.
	 * @returns An observable to indicate completion.
	 */
	public update(
		data: ILinkable & IResource,
		prefixMap: Map<number, string>,
		rootPrefixMap: Map<number, string>,
		options: IRequestOptions,
		cachePath: string,
		refresh: boolean = false,
		parser: IResourceApiParser = null
	): Observable<any> {
		const url: string = `${this._baseService.configs.apiUrl}/${this._getPathUrl(data, prefixMap, rootPrefixMap)}/${data.id}`;
		const updateObs: Observable<void> = this._resourceCacheService.put(url, data, options, cachePath, this._getParent(data));

		if (refresh && parser !== null) {
			return updateObs.flatMap(() => this._resourceCacheService.refreshSingle(url, parser, options, cachePath));
		}

		return updateObs;
	}

	/**
	 * @description
	 * Deletes the data object from the application.
	 *
	 * @param data The data object to delete.
	 * @param prefixMap A type prefix map to use to build url path for non-root elements.
	 * @param rootPrefixMap A type prefix map to use to build url path for root elements.
	 * @param options Options to pass to the http service.
	 * @param cachePath A cache path that acts as key to the cache store.
	 * @returns An observable to indicate completion.
	 */
	public delete(
		data: ILinkable & IResource,
		prefixMap: Map<number, string>,
		rootPrefixMap: Map<number, string>,
		options: IRequestOptions,
		cachePath: string,
		bodyData: Object = null
	): Observable<void> {
		return this._resourceCacheService.delete(
			`${this._baseService.configs.apiUrl}/${this._getPathUrl(data, prefixMap, rootPrefixMap)}/${data.id}`,
			data.id,
			options,
			cachePath,
			this._getParent(data),
			bodyData
		);
	}

	/**
	 * @description
	 * Returns the root parent of the given data object. If the object has no parent,
	 * returns null.
	 *
	 * @param data The child data object to get parent.
	 * @returns The root parent of the child data object
	 */
	private _getParent(data: ILinkable & IResource): ILinkable & IResource {
		return data.parent ? this._getParentRecurser(data) : null;
	}

	/**
	 * @description
	 * Recurse over the data object's parent tree.
	 *
	 * @param data The data of which to inspect tree.
	 * @returns The root parent of the child data object
	 */
	private _getParentRecurser(data: ILinkable & IResource): ILinkable & IResource {
		return data.parent ? this._getParentRecurser(data.parent) : data;
	}

	/**
	 * @description
	 * Gets the web url to call based on the chain of data objects to root
	 * using the chain ids and the prefixes map to get corresponding prefixes
	 *
	 * @param data The child data object to get path for.
	 * @param prefixMap A type prefix map to use to build url path for non-root elements
	 * @param rootPrefixMap A type prefix map to use to build url path for root elements
	 * @returns The web url to call containing the chain prefixes and corresponding ids.
	 */
	private _getPathUrl(data: ILinkable & IResource, prefixMap: Map<number, string>, rootPrefixMap: Map<number, string>): string {
		return `${this._getBaseUrl()}/${this._getPathToRoot(data, prefixMap, rootPrefixMap)}`;
	}

	/**
	 * @description
	 * Gets the url path to call based on the chain of data objects to root
	 * using the chain ids and the prefixes map to get corresponding prefixes
	 *
	 * @param data The child data object to get path for.
	 * @param prefixMap A type prefix map to use to build url path for non-root elements
	 * @param rootPrefixMap A type prefix map to use to build url path for root elements
	 * @returns The url path containing the chain prefixes and corresponding ids.
	 */
	private _getPathToRoot(data: ILinkable & IResource, prefixMap: Map<number, string>, rootPrefixMap: Map<number, string>): string {
		if (data.parent) {
			return `${this._getPathToRoot(data.parent, prefixMap, rootPrefixMap)}/${data.parent.id}/${prefixMap.get(data.type)}`;
		}

		return `${rootPrefixMap.get(data.type)}`;
	}

	/**
	 * @description
	 * Gets the base web url to call.
	 *
	 * @returns The base url to use for web call.
	 */
	private _getBaseUrl(): string {
		return `apps/${this._baseService.configs.appId}/versions/${this._baseService.configs.versionId}`;
	}
}
