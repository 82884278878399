import { IPattern, IPatternFsmResult } from '../../interfaces/IPatternFsm';
import { parse } from '../../utils/generated-pattern-parser-validator.js';

/**
 * @description
 * Represents a utility class for validating a pattern. Validation rules
 * are represented in the transition states constant.
 */
export class PatternValidator implements IPattern {
    private _text: string;

    constructor(text: string) {
        this._text = text;
    }

    /**
     * @method
     * @description
     * Checks if the given pattern string is valid or not.
     *
     * @param isComplete Checks if the pattern is completed or not.
     *
     * @returns True if pattern is valid and false otherwise.
     */
    public evaluate(isComplete: boolean): IPatternFsmResult {
        if (isComplete) {
            try {
                parse(this._text);
            } catch (err) {
                const errLocation = err.hash.loc.last_column;
                const errorMsg: string = `Incomplete expression`;
                return {
                    result: false,
                    error: { index: errLocation, message: errorMsg }
                };
            }
        }
        return {
            result: true,
            error: null
        };
    }
}
