import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { BUS_EVENTS } from '../../models/utils/event-bus.model';
import { EventBusMessage } from './../../models/utils/event-bus.model';
import { EventBusService } from './event-bus.service';

@Injectable()
export class DirtyBitService {
	private readonly _isDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private readonly _eventbus: EventBusService) {
		this._subscribeToBus();
	}

	/**
	 * @description
	 * Marks the application as dirty or not (there are untrained changes that happened
	 * to the application or vice versa).
	 *
	 * @param isDirty The dirty state of the application.
	 */
	public setDirty(isDirty: boolean): void {
		this._eventbus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_DIRTY, isDirty));
	}

	/**
	 * @description
	 * Subscribes to the dirty state stream of the application.
	 */
	public observe(): Observable<boolean> {
		return this._isDirty.asObservable();
	}

	/**
	 * @description
	 * Subscribes to the event bus to listen for changes to the dirty state.
	 */
	private _subscribeToBus(): void {
		this._eventbus.subscribeToBus(BUS_EVENTS.MODEL_DIRTY, v => this._isDirty.next(v));
	}
}
