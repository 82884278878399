import {
    ComponentFactoryResolver,
    Directive,
    HostListener,
    Input,
    ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { IPopupOption } from '../interfaces/IPopupOption';
import { PopupComponent } from '../popup.component';

/**
 * @description
 * Renders the popup menu of options, with a fixed position
 * whenever the component is created via a right click on the
 * host element.
 */
@Directive({
    selector: '[popupRenderer]'
})
export class PopupRendererDirective {
    @Input('popupRenderer') public options: IPopupOption[] = [];

    constructor(
        private _viewContainerRef: ViewContainerRef,
        private _resolver: ComponentFactoryResolver
    ) { }

    /**
     * @description
     * Creates the popup menu in the position where the right
     * click was made. Clears the popup menu view container
     * when the popup menu is destroyed.
     *
     * @param event The right click event that we extract the
     * mouse position from.
     */
    @HostListener('contextmenu', ['$event'])
    public renderPopup(event: MouseEvent): void {
        if (!this.options.length) {
            return;
        }

        event.preventDefault();

        const popup: PopupComponent = this._createPopupComponent();
        const position: ClientRect = this._getMenuPosition(event);
        const activeElement = document.activeElement;

        popup.options = this.options;
        popup.origin = position;
        popup.closeSubject = new Subject();
        popup.closeSubject.subscribe(null, null, () => {
            this._viewContainerRef.clear();
            (<any>activeElement).focus();
        });
    }

    /**
     * @description
     * Creates the popup component and returns an instance of it.
     */
    private _createPopupComponent(): PopupComponent {
        this._viewContainerRef.clear();

        const factory = this._resolver.resolveComponentFactory(PopupComponent);
        const componentRef = this._viewContainerRef.createComponent(factory);

        return componentRef.instance;
    }

    /**
     * @description
     * Gets the position the menu should open relative to
     * the document origin based on the mouse click position
     * of the event.
     * 
     * @param event The event that was fired on right click.
     */
    private _getMenuPosition(event: MouseEvent): ClientRect {
        const position: ClientRect = <any>{};

        if (event.pageX || event.pageY) {
            position.left = event.pageX;
            position.top = event.pageY;
        }
        else if (event.clientX || event.clientY) {
            position.left = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            position.top = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        return position;
    }
}
