import { TrainingResultUtterance } from './training-result-utterance.model';

/**
 * @dynamic
 * @description
 * Represents an intent training result.
 *
 * @param incorrectUtterances An arry of the incorrect utterances which assigned to the specified intent.
 * @param ambiguousUtterances An arry of the ambiguous utterances which assigned to the specified intent.
 */
export class IntentTrainingResult {
	constructor(
		public incorrectUtterances: Map<number, TrainingResultUtterance> = new Map<number, TrainingResultUtterance>(),
		public ambiguousUtterances: Map<number, TrainingResultUtterance> = new Map<number, TrainingResultUtterance>()
	) {}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): IntentTrainingResult {
		const utterances: TrainingResultUtterance[] = apiObject.utterances.map(u => TrainingResultUtterance.importFromApi(u));
		const incorrect = new Map<number, TrainingResultUtterance>();
		const ambiguous = new Map<number, TrainingResultUtterance>();

		utterances.filter(u => u.incorrectIntents.length > 0).forEach(u => incorrect.set(u.utteranceId, u));
		utterances
			.filter(u => u.incorrectIntents.length === 0 && u.ambiguousIntents.length > 0)
			.forEach(u => ambiguous.set(u.utteranceId, u));

		return utterances.length > 0 ? new IntentTrainingResult(incorrect, ambiguous) : new IntentTrainingResult();
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): IntentTrainingResult {
		const incorrectUtterances = new Map<number, TrainingResultUtterance>();
		this.incorrectUtterances.forEach((item, id) => incorrectUtterances.set(id, item.clone()));

		const ambiguousUtterances = new Map<number, TrainingResultUtterance>();
		this.ambiguousUtterances.forEach((item, id) => ambiguousUtterances.set(id, item.clone()));

		return new IntentTrainingResult(incorrectUtterances, ambiguousUtterances);
	}
}
