import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { IModalHost } from './../../interfaces/utils/IModalHost';
import { ModalHostService } from './../../services/utils/modal-host.service';

/**
 * @description
 * Alternative for @ViewChild decorator within the ModalHostComponent.
 * @ViewChild was not able to access a tagged (#var_name) element within
 * our html template if it was not at the root level of the HTML.
 *
 * The tagged variable was supposed to be where our modal dialog content
 * was inserted into the DOM.
 *
 * So we instead have this component nested inside of our ModalHostComponent,
 * and have this element emit a reference to itself up to the ModalHostComponent
 * when it is initialized.
 */
@Component({
    selector: 'modal-host-content',
    template: '<div #container></div>'
})
export class ModalHostContentComponent implements OnInit {
    @Output() public ready: EventEmitter<ViewContainerRef> = new EventEmitter<ViewContainerRef>();
    @ViewChild('container', { read: ViewContainerRef }) public viewContainerRef: ViewContainerRef;

    public ngOnInit(): void {
        this.ready.emit(this.viewContainerRef);
    }
}

/**
 * @description
 * Actual modal component consisting of the absolutely positioned
 * background overlay, and a content container in the center of the screen.
 * Components using the ModalHostService will be displayed within this component.
 */
@Component({
    selector: 'modal-host',
    templateUrl: './modal-host.component.html',
    styleUrls: ['./modal-host.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalHostComponent implements OnInit, OnDestroy, IModalHost {
    @Output() public visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public modalContentContainer: ViewContainerRef;

    constructor(
        private _root: ElementRef,
        private _modalHostService: ModalHostService
    ) {
        this.visible.subscribe(b => this.visibilityChange.emit(b));
    }

    public ngOnInit(): void {
        this._modalHostService.hostContainer = this;
    }

    public ngOnDestroy(): void {
        this._modalHostService.hostContainer = null;
    }

    public setContainerRef(e: ViewContainerRef): void {
        this.modalContentContainer = e;
    }

    public getContainerRef(): ViewContainerRef {
        return this.modalContentContainer;
    }

    public show(): void {
        this.visible.next(true);
    }

    public hide(): void {
        this.visible.next(false);
    }

    public destroyAndHide(): void {
        this._modalHostService.destroy();
    }
}
