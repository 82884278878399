import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IResourceOperation, PaginationUIPayload, SelectionMap } from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { AppEndpointConfig } from '../../../models/app-endpoint-config.model';
import { APP_OPERATIONS } from '../../../models/app-operations.model';
import { App } from '../../../models/app.model';

/**
 * @description
 * Represents an app table. Lists the apps in an application. Provides
 * tools for manipulating the intents in the table.
 */
@Component({
    selector: 'app-table',
    templateUrl: './app-table.component.html',
    styleUrls: ['./app-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppTableComponent implements OnInit, OnDestroy {
    @Input() public query: BehaviorSubject<string>;
    @Input() public pageIndex: BehaviorSubject<number>;
    @Input() public paginationUIPayload: PaginationUIPayload<App> = new PaginationUIPayload();

    @Output() public clickedApp: EventEmitter<App> = new EventEmitter<App>();
    @Output() public applyOperation: EventEmitter<IResourceOperation> = new EventEmitter<IResourceOperation>();

    public selectionMap: SelectionMap = new SelectionMap();
    public appOps: any = APP_OPERATIONS;
    public selectedAppEndpointConfigs: Observable<{ production: AppEndpointConfig; staging: AppEndpointConfig }>;
    public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
        property: 'createdDate',
        type: SORT_TYPE.DESCENDING
    });

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        if (this.paginationUIPayload) {
            this.paginationUIPayload.dispose();
        }
    }

    /**
     * @description
     * Notifies parent that an operation was clicked to apply to the given app.
     *
     * @param operation The operation to run. Should be one of the allowed operations
     * in the APP_OPERATIONS enum.
     */
    public onOperationClick(operation: APP_OPERATIONS): void {
        const selectedAppsIds: string[] = <string[]>this.selectionMap.selectedItems;
        const appOperation: IResourceOperation = { items: [], operation: operation, response: new BehaviorSubject<boolean>(false) };

        this.paginationUIPayload.items
            .first()
            .map(apps => apps.filter(app => selectedAppsIds.indexOf(app.id) !== -1))
            .do(selectedApps => (appOperation.items = selectedApps))
            .subscribe(() => this.applyOperation.emit(appOperation));

        appOperation.response.subscribe(null, null, () => this.selectionMap.markUnselected(selectedAppsIds));
    }

    /**
     * @description
     * Initialize the component.
     */
    private _initState(): void {
        this.selectedAppEndpointConfigs = Observable.combineLatest(this.paginationUIPayload.items, this.selectionMap.selectedItemsAsync)
            .filter(([_, selectedIds]) => selectedIds.length === 1)
            .map(([apps, selectedIds]) => apps.find(app => app.id === selectedIds[0]))
            .filter(app => app !== undefined)
            .map(app => ({
                production: app.endpointConfigs.find(c => c.isStaging === false),
                staging: app.endpointConfigs.find(c => c.isStaging === true)
            }));
    }
}
