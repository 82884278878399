import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as c3 from 'c3';
import { Subscription } from 'rxjs';
import { AxisData, CHART_TYPES } from '../../models/chart.model';
import { ChartsRendererService } from '../../services/charts-renderer.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

export interface IBarChartClickInput {
	x: number;
	value: number;
	id: string;
	index: number;
	name: string;
}

@Component({
	selector: 'bar-chart',
	template: '<loading [visible]="!(isInitialized)"></loading>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartComponent extends BaseChartComponent implements AfterViewInit, OnDestroy {
	@Output() public chartClick: EventEmitter<IBarChartClickInput> = new EventEmitter<IBarChartClickInput>();

	private _xDataSubscription: Subscription;

	constructor(private readonly i18n: TranslateService, private elementRef: ElementRef, chartsRenderer: ChartsRendererService) {
		super(i18n, elementRef, chartsRenderer);
	}

	public ngAfterViewInit(): void {
		this._xDataSubscription = this.xData
			.zip(this.yData, (xData, yData) => ({ xData, yData }))
			.map(this.filterZeroedData)
			.subscribe(({ xData, yData }) => {
				const config: c3.ChartConfiguration = this._getConfig(xData, yData);
				if (!this.isInitialized) {
					this.init(config);
					this.isInitialized = true;
				} else {
					this.update({
						columns: config.data.columns,
						categories: config.axis.x.categories
					});
				}
			});
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this._xDataSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Initializes the chart component with the required configuration
	 */
	protected init(config: c3.ChartConfiguration): void {
		super.init({
			...config,
			bindto: this.elementRef.nativeElement
		});
	}

	/**
	 * @description
	 * Creates the configuration accepted by the ChartRendererService for generating a bar chart
	 */
	private _getConfig(xData: AxisData, yData: AxisData): c3.ChartConfiguration {
		const data: any = {
			columns: this._transformYDataToColumns(yData),
			type: CHART_TYPES.BAR,
			empty: {
				label: {
					text: this.emptyLabelText
				}
			},
			color: (_, d) => {
				return this.colors[d.index % this.colors.length];
			},
			onclick: this._handleClick(xData)
		};
		const axis: any = {
			x: {
				type: 'category',
				categories: this._transformXDataToCategories(xData),
				tick: {
					format: () => ''
				}
			},
			y: {
				show: false
			}
		};
		const tooltip: any = {
			format: {
				title: index => {
					return xData.data[index];
				}
			}
		};

		return { data, axis, tooltip };
	}

	/**
	 * @description
	 * Transforms yAxisData to columns array accepted by ChartRendererService
	 */
	private _transformYDataToColumns(yData: AxisData): any[] {
		return [[yData.title, ...yData.data]];
	}

	/**
	 * @description
	 * Transforms xAxisData to categories array accepted by ChartRendererService
	 */
	private _transformXDataToCategories(xData: AxisData): any[] {
		return [...xData.data.map((entityName: string) => entityName)];
	}

	/**
	 * @description
	 * A function invoked with the slice data that was clicked on.
	 */
	private _handleClick = (xData: AxisData) => (data: IBarChartClickInput): void => {
		const chartBarName: string = xData.data[data.x];
		this.chartClick.emit({
			...data,
			id: chartBarName,
			name: chartBarName
		});
	};
}
