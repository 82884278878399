import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClosedEntity } from '../../../../models/closed-entity.model';
import { Entity, ENTITY_TYPES } from '../../../../models/entity.model';
import { ParentEntity } from '../../../../models/parent-entity.model';
import { PatternAnyEntity } from '../../../../models/pattern-any-entity.model';
import { RegexEntity } from '../../../../models/regex-entity.model';

/**
 * @description
 * Represents an object to be displayed in the dropdown.
 */
export interface IDropdownType {
	type: ENTITY_TYPES;
	name: string;
	isDefault: boolean;
}

/**
 * @description
 * Represents the entity type dropdown in the entity creation modal
 * dialog. Notifies the parent whenever a type is changed.
 */
@Component({
	selector: 'type-dropdown',
	templateUrl: 'type-dropdown.component.html',
	styleUrls: ['type-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeDropdownComponent implements OnInit {
	@Input() public initialType: ENTITY_TYPES = ENTITY_TYPES.SIMPLE;
	@Input() public isTypeLocked: boolean;
	@Input() public typesToExclude: ENTITY_TYPES[] = [];
	@Output() public onTypeChange: EventEmitter<Entity> = new EventEmitter<Entity>();

	public entityTypes: typeof ENTITY_TYPES = ENTITY_TYPES;
	public visibleTypes: IDropdownType[];
	public defaultType: IDropdownType;

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @method
	 * @description
	 * Notifies the parent that the selected type has changed. Emits
	 * an empty entity of the type chosen.
	 *
	 * @param type The type chosen.
	 */
	public typeChanged(type: string): void {
		switch (+type) {
			case ENTITY_TYPES.SIMPLE:
				this.onTypeChange.emit(new Entity());
				break;
			case ENTITY_TYPES.HIERARCHICAL:
				this.onTypeChange.emit(new ParentEntity('', '', ENTITY_TYPES.HIERARCHICAL));
				break;
			case ENTITY_TYPES.COMPOSITE:
				this.onTypeChange.emit(new ParentEntity());
				break;
			case ENTITY_TYPES.CLOSED:
				this.onTypeChange.emit(new ClosedEntity());
				break;
			case ENTITY_TYPES.REGEX:
				this.onTypeChange.emit(new RegexEntity());
				break;
			case ENTITY_TYPES.PATTERN_ANY:
				this.onTypeChange.emit(new PatternAnyEntity());
				break;
			default:
				this.onTypeChange.emit(new Entity());
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.visibleTypes = this._getVisibleTypes();
		this.defaultType = this.visibleTypes.find(vT => vT.isDefault);
	}

	/**
	 * @description
	 * Gets the types that should be visible in the dropdown
	 * based on the excluded types and the current flighted options.
	 *
	 * @returns An array of the visible types.
	 */
	private _getVisibleTypes(): IDropdownType[] {
		let visibleTypes: IDropdownType[] = [
			{ type: ENTITY_TYPES.SIMPLE, name: 'Simple', isDefault: this.initialType === ENTITY_TYPES.SIMPLE },
			{ type: ENTITY_TYPES.HIERARCHICAL, name: 'Hierarchical', isDefault: this.initialType === ENTITY_TYPES.HIERARCHICAL },
			{ type: ENTITY_TYPES.COMPOSITE, name: 'Composite', isDefault: this.initialType === ENTITY_TYPES.COMPOSITE },
			{ type: ENTITY_TYPES.CLOSED, name: 'List', isDefault: this.initialType === ENTITY_TYPES.CLOSED },
			{ type: ENTITY_TYPES.REGEX, name: 'Regex', isDefault: this.initialType === ENTITY_TYPES.REGEX },
			{ type: ENTITY_TYPES.PATTERN_ANY, name: 'Pattern.any', isDefault: this.initialType === ENTITY_TYPES.PATTERN_ANY }
		];

		visibleTypes = visibleTypes.filter(vE => this.typesToExclude.findIndex(tE => tE === vE.type) === -1);

		return visibleTypes;
	}
}
