import { BaseItemFilter, IDataItem } from '@luis/core';
import { Entity } from '@luis/entities';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { Pattern } from '../pattern.model';

/**
 * @description
 * Represents an entity item filter that filters data items
 * based on whether all the entities chosen exist in the item
 * or not. Used by the item filter pipe.
 */
export class EntityItemFilter extends BaseItemFilter {
    private _entities: Map<string, Entity> = new Map<string, Entity>();
    private _entitiesSubject: BehaviorSubject<Entity[]> = new BehaviorSubject<Entity[]>([]);

    /**
     * @method
     * @description
     * Toggles the activation of the filter by either adding a
     * new entity to the entity map or removing if it was already existing.
     *
     * @param entity The entity to toggle.
     */
    public toggleActivation(entity: Entity): void {
        if (this._entities.has(entity.id)) {
            this._entities.delete(entity.id);
        }
        else {
            this._entities.set(entity.id, entity);
        }

        this._entitiesSubject.next(Array.from(this._entities.values()));
    }

    /**
     * @method
     * @description
     * Checks if the filter is active by checking the size
     * of the entities map. If no entities are active for
     * filtering, then the filter is not active.
     */
    public isActive(): boolean {
        return this._entities.size !== 0;
    }

    /**
     * @method
     * @description
     * Gets the current filter entities as an observable.
     */
    public getEntities(): Observable<Entity[]> {
        return this._entitiesSubject.asObservable();
    }

    /**
     * @method
     * @description
     * Filters the given item by whether it contains the
     * given entities or not.
     *
     * @param item The item to filter.
     * @returns True if the item should be displayed
     * and false if it should not be displayed.
     */
    public filter(item: IDataItem): boolean {
        const entities: Entity[] = Array.from(this._entities.values());

        for (const entity of entities) {
            const isFound: boolean = this._checkIfEntityIsFound(item, entity);
            if (!isFound) {
                return false;
            }
        }

        return true;
    }

    /**
     * @description
     * Checks if the entity is found in the given item or not.
     *
     * @param item The item to check the entity in.
     * @param entity The entity to check.
     * @returns True if the entity exists in the item and
     * vice versa.
     */
    private _checkIfEntityIsFound(item: IDataItem, entity: Entity): boolean {
         if (item instanceof Pattern) {
            return item.entities.find(e => e.id === entity.id) !== undefined;
        }
        else {
            return false;
        }
    }
}
