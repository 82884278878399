import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IResourceOperation, IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IIntentService, INTENT_SERVICE_TOKEN } from '../../interfaces/IIntentService';
import { INTENT_OPERATIONS } from '../../models/intent-operation.model';
import { Intent } from '../../models/intent.model';
import { IntentCreationModalComponent } from '../intent-creation-modal/intent-creation-modal.component';
import { IntentDeleteModalComponent } from '../intent-delete-modal/intent-delete-modal.component';
import { IntentRenameModalComponent } from '../intent-rename-modal/intent-rename-modal.component';

/**
 * @description
 * Represents an intent list component that hosts an intent table. The intent list is
 * responsible for any service communcation and mediating between children components and
 * external services.
 */
@Component({
	selector: 'intent-list',
	templateUrl: './intent-list.component.html',
	styleUrls: ['./intent-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntentListComponent implements OnInit {
	@Output() public intentClicked: EventEmitter<Intent> = new EventEmitter<Intent>();
	@Output() public domainIntentClicked: EventEmitter<Intent> = new EventEmitter<Intent>();
	@Output() public newIntentCreated: EventEmitter<Intent> = new EventEmitter<Intent>();

	public intents: Observable<Intent[]>;

	constructor(
		private readonly _dialogService: MatDialog,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Applies the given operation on the intent based on received.
	 *
	 * @param intentOp The intents and the operation to apply on it.
	 */
	public onOperationClicked(intentOp: IResourceOperation): void {
		switch (intentOp.operation) {
			case INTENT_OPERATIONS.ADD:
				this.onNewIntentClick(intentOp);
				break;
			case INTENT_OPERATIONS.ADD_PREBUILT:
				this.onDomainIntentClick(intentOp);
				break;
			case INTENT_OPERATIONS.RENAME:
				this._createRenameIntentModal(intentOp);
				break;
			case INTENT_OPERATIONS.DELETE:
				this._deleteIntents(intentOp);
				break;
			default:
		}
	}

	/**
	 * @description
	 * Notifies the parent that the domain intent button
	 * was clicked.
	 * Initializes the component.
	 */
	private _initState(): void {
		this.intents = this._intentService.get();
	}

	/**
	 * @description
	 * Opens up the modal for intent creation. Notifies the parent
	 * component that an intent was created on successfull creation.
	 *
	 * @param intentOp The intent(s) and the operation to apply on it.
	 */
	private onNewIntentClick(intentOp: IResourceOperation): void {
		this._dialogService
			.open(IntentCreationModalComponent, { width: '550px' })
			.afterClosed()
			.filter(intent => intent)
			.subscribe(intent => {
				intentOp.response.complete();
				this.newIntentCreated.emit(intent);
			});
	}

	/**
	 * @description
	 * Fires an event that an domain intent button was clicked.
	 *
	 * @param intentOp The intent(s) and the operation to apply on it.
	 */
	private onDomainIntentClick(intentOp: IResourceOperation): void {
		this.domainIntentClicked.emit();
		intentOp.response.complete();
	}

	/**
	 * @description
	 * Creates a prompt modal to delete the given intents.
	 *
	 * @param intentOp The intent(s) and the operation to apply on it.
	 */
	private _deleteIntents(intentOp: IResourceOperation): void {
		const deletedItems = (<Intent[]>intentOp.items).filter(item => item.name !== 'None');

		this._dialogService
			.open(IntentDeleteModalComponent, { width: '600px', data: intentOp.items })
			.afterClosed()
			.filter(keepUtterances => keepUtterances !== '')
			.flatMap(keepUtterances =>
				this._intentService
					.batchDelete(deletedItems, keepUtterances)
					.trackProgress(
						this._toasterService.add({ startMsg: intentOp.items.length > 1 ? 'Deleting intents' : 'Deleting intent' })
					)
			)
			.subscribe(() => intentOp.response.complete());
	}

	/**
	 * @description
	 * Creates an intent rename modal.
	 *
	 * @param intentOp The intents and the operation to apply on it.
	 */
	private _createRenameIntentModal(intentOp: IResourceOperation): void {
		this._dialogService
			.open(IntentRenameModalComponent, { width: '550px', data: intentOp.items[0] })
			.afterClosed()
			.subscribe(() => intentOp.response.complete());
	}
}
