import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { BaseService, GATEKEEPER_FEATURE, GenericPromptService, IUrlSettings, ProgressTracker, PromptButtonTypes } from '@luis/core';
import { ToggleComponent } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IPublishService, PUBLISH_SERVICE_TOKEN } from '../../interfaces/IPublishService';
import { POPUPS } from '../../models/popup-messages.model';
import { PersistantPublishSettings } from '../../models/publish.model';

@Component({
	selector: 'endpoint-settings',
	templateUrl: 'endpoint-settings.component.html',
	styleUrls: ['endpoint-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EndpointSettingsComponent implements OnInit {
	@Output() public endpointSettingsUpdated: EventEmitter<IUrlSettings> = new EventEmitter<IUrlSettings>();

	public programmaticKey: string;
	public urlSettings: IUrlSettings = { verbose: true };
	public persistantPublishSettings: Observable<PersistantPublishSettings>;
	public isStagingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public popUpEnums: typeof POPUPS.TYPES = POPUPS.TYPES;
	public publishSettingsTracker: ProgressTracker = new ProgressTracker();
	public gateKeeperFeature: typeof GATEKEEPER_FEATURE = GATEKEEPER_FEATURE;

	constructor(
		private _i18n: TranslateService,
		private _baseService: BaseService,
		private _promptService: GenericPromptService,
		@Inject(PUBLISH_SERVICE_TOKEN) private _publishService: IPublishService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Changes the publishing slot. Reflects this change in both the
	 * staging stream and the endpoint url settings stream.
	 */
	public changeSlot(isStaging: boolean): void {
		this.isStagingSubject.next(isStaging);
		this.urlSettings.staging = isStaging;
		this.endpointSettingsUpdated.emit(this.urlSettings);
	}

	/**
	 * @description
	 * Updates the publish setting given and updates the url if it has
	 * a url constituent.
	 *
	 * @param key The key of the setting.
	 * @param value The value of the setting.
	 * @param toggleComp The toggle component to focus after the popup closes.
	 * @param popupType The popup type to show.
	 */
	public updateSetting(key: string, value: any, toggleComp: ToggleComponent, popupType?: POPUPS.TYPES): void {
		this.persistantPublishSettings
			.first()
			.do(settings => (settings[key] = value))
			.flatMap(settings => this._publishService.updatePublishSettings(settings))
			.trackProgress(this.publishSettingsTracker.getTracker())
			.subscribe(() =>
				setTimeout(() => {
					toggleComp._onFocus();
					if (popupType !== undefined && value) {
						this._showPopup(popupType);
					}
				})
			);
	}

	/**
	 * @description
	 * Updates the endpoint url.
	 *
	 * @param key The key of the setting.
	 * @param value The value of the setting.
	 */
	public updateUrl(key: string, value: any): void {
		this.urlSettings[key] = value;
		this.endpointSettingsUpdated.emit(this.urlSettings);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.persistantPublishSettings = this._publishService.getPublishSettings().do(settings => {
			this.urlSettings.bing = settings.bing;
			this.endpointSettingsUpdated.emit(this.urlSettings);
		});

		this.programmaticKey = this._baseService.configs.userSubKey;
	}

	/**
	 * @description
	 * Shows the popup with the given enumeration.
	 *
	 * @param popupEnum The popup to open.
	 * @param value The activation state of the Bing Spell Check feature.
	 */
	private _showPopup(type: POPUPS.TYPES): void {
		const popupData: POPUPS.IPopupData = POPUPS.createPopUp(type, this._i18n);
		this._promptService
			.prompt(
				popupData.title,
				popupData.body,
				{ ok: this._i18n.instant('publish-pane.endpoint-settings.ok') },
				{ ok: PromptButtonTypes.Primary }
			)
			.subscribe();
	}
}
