/**
 * @description
 * An enumeration of all the events that are allowed in the system.
 */
export enum BUS_EVENTS {
	// Sends an event indicating that utterances have changed.
	UTTERANCE_CHANGED,

	// Deletes the none intent utterance cache. Sends the none intent id.
	DELETE_NONE_INTENT,

	// Sends an IModelChangeBusMessage.
	MODEL_CHANGED,

	// Sends an IModelChangeBusMessage.
	ROLE_CHANGED,

	// Model dirty event for event bus
	MODEL_DIRTY,

	// Sends the changed domain
	DOMAIN_CHANGED,

	// Sends a boolean of whether app was already up to date or not.
	TRAIN_OCCURRED,

	// Sends a boolean of whether app was published or not.
	PUBLISH_OCCURRED,

	// Sends true if modal was opened and false if closed.
	MODAL_TOGGLED,

	// Sends App needs training message
	NEEDS_TRAINING,

	// Sends true when the chunking service starts and false when it's done along with the type of
	// the chunking service that has started or ended
	CHUNKING_PROCESS_RUNNING,

	// Sends Entities cache needs invalidation.
	INVALIDATE_ENTITIES_CACHE,

	// Sends Patterns cache needs invalidation.
	INVALIDATE_PATTERNS_CACHE,

	// Signals that the utterance cache should be invalidated.
	INVALIDATE_UTTERANCES_CACHE
}

/**
 * @description
 * Represents a message that can be sent to the system wide event bus.
 *
 * @param type The event type, should be of BUS_EVENTS enum.
 * @param data The message data.
 */
export class EventBusMessage {
	public type: number;
	public data: any;

	constructor(type: number, data?: any) {
		this.type = type;
		this.data = data;
	}
}
