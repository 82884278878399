import { Injectable } from '@angular/core';
import { IDictionary } from '../../interfaces/utils/IDictionaryStorageService';
import { LuisConstants } from '../../models/utils/constants.model';
import { FEATURE_RATING_TYPES } from '../../models/utils/feature-rating.model';
import { DictionaryLocalStorageService } from './dictionary-storage.service';

/**
 * @description
 * This service keeps the track of the ratings for all features
 * accross the website.
 */
@Injectable()
export class FeatureRatingService {
	constructor(private readonly _dictionaryStorageService: DictionaryLocalStorageService) {}
	/**
	 * @description
	 * Gets all the ratings stored in the local storage for all features.
	 *
	 * @returns A dictionary of features with their ratings or undefined
	 * if the ratings did not exist before.
	 */
	public getAllRatings(): IDictionary<boolean> | undefined {
		return this._dictionaryStorageService.getDictionary<boolean>(LuisConstants.FEATURE_RATINGS_STORAGE_KEY);
	}

	/**
	 * @description
	 * Gets the rating for a specific feature or returns undefined if
	 * the rating didn't exist before.
	 *
	 * @param feature The feature enumeration to get the rating for.
	 * @returns A boolean value of the rating set or undefined if not set.
	 */
	public getFeatureRating(feature: FEATURE_RATING_TYPES): boolean | undefined {
		const ratings = this.getAllRatings();

		if (!ratings) {
			return undefined;
		}

		return ratings[feature];
	}

	/**
	 * @description
	 * Sets the rating for the given feature with the value given.
	 *
	 * @param feature The feature to set the rating for.
	 * @param value The value of the rating to set.
	 */
	public setFeatureRating(feature: FEATURE_RATING_TYPES, value: boolean): void {
		const ratings = this.getAllRatings() || {};

		ratings[feature] = value;

		this._dictionaryStorageService.setDictionary(LuisConstants.FEATURE_RATINGS_STORAGE_KEY, ratings);
	}
}
