import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';

export const APP_STATS_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IAppPermissionsService');

/**
 * @description
 * Represents an interface for apps stats service. Stats service
 * should contain getter methods for app statistics.
 */
export interface IAppStatsService {
    getEndpointHits(start: Date, end: Date): Observable<Map<string, number>>;
}
