import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccessiblePaginationControlsComponent } from './components/accessible-pagination/accessible-pagination.component';
import { BaseModalComponent } from './components/base-modal/base-modal.component';
import { FeatureOptSettingsComponent } from './components/feature-opt-settings/feature-opt-settings.component';
import { FeatureRatingComponent } from './components/feature-rating/feature-rating.component';
import { ModalHostComponent, ModalHostContentComponent } from './components/modal-host/modal-host.component';
import { PromptPanelComponent } from './components/prompt-panel/prompt-panel.component';
import { ToastComponent } from './components/toaster/toast/toast.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { EventDirective } from './directives/analytics-event.directive';
import { PageViewDirective } from './directives/analytics-pageview.directive';
import { BlobPathDirective } from './directives/blob-path.directive';
import { GateKeeperDirective } from './directives/gate-keeper.directive';
import { PositioningFromParent } from './directives/position-from-parent.directive';
import { TabWithinElementDirective } from './directives/tab-within-element.directive';
import { ViewClippingDetectorDirective } from './directives/view-clipping-detector.directive';
import { GENERIC_CACHE_SERVICE_TOKEN } from './interfaces/services/caches/IGenericCacheService';
import { LINKABLE_CACHE_ADAPTER_SERVICE_TOKEN } from './interfaces/services/caches/ILinkableCacheAdapterService';
import { RESOURCE_CACHE_SERVICE_TOKEN } from './interfaces/services/caches/IResourceCacheService';
import { TOASTER_SERVICE_TOKEN } from './interfaces/services/utils/IToasterService';
import { TRAINING_SERVICE_TOKEN } from './interfaces/services/utils/ITrainingService';
import { ExactMatchAsyncPipe } from './pipes/exact-match-async.pipe';
import { IsTableItemDeleteVisibleAsyncPipe } from './pipes/is-table-item-delete-visible-async.pipe';
import { ItemFilterAsyncPipe } from './pipes/item-filter-async.pipe';
import { MapAsyncPipe } from './pipes/map-async.pipe';
import { SearchAsyncPipe } from './pipes/search-async.pipe';
import { SelectionUpdatePipe } from './pipes/selection-update.pipe';
import { SharedObservablePipe } from './pipes/shared-observable-async.pipe';
import { SortAsyncPipe } from './pipes/sort-async.pipe';
import { TextMiddleEllipsisPipe } from './pipes/text-middle-ellipsis.pipe';
import { ToastIconAsyncPipe } from './pipes/toast-icon-async.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { GenericCacheService } from './services/caches/generic-cache.service';
import { LinkableCacheAdapterService } from './services/caches/linkable-cache-adapter.service';
import { PaginationResourceCacheService } from './services/caches/pagination-resource-cache.service';
import { ResourceCacheService } from './services/caches/resource-cache.service';
import { AnalyticsService } from './services/utils/analytics.service';
import { BaseService } from './services/utils/base.service';
import { ConfigService } from './services/utils/config.service';
import { DirtyBitService } from './services/utils/dirty-bit.service';
import { EventBusService } from './services/utils/event-bus.service';
import { FeatureOptSettingsService } from './services/utils/feature-opt-settings.service';
import { FeatureRatingService } from './services/utils/feature-rating.service';
import { FileService } from './services/utils/file.service';
import { GateKeeperService } from './services/utils/gate-keeper.service';
import { GenericPromptService, PROMPT_PANEL_COMP_TOKEN } from './services/utils/generic-prompt.service';
import { ModalHostService } from './services/utils/modal-host.service';
import { ResourceChunkingService } from './services/utils/resource-chunking.service';
import { ResourceCountService } from './services/utils/resource-count.service';
import { ToasterService } from './services/utils/toaster.service';
import { TrainingService } from './services/utils/training.service';
import { DictionaryLocalStorageService } from './services/utils/dictionary-storage.service';

@NgModule({
	imports: [CommonModule, FormsModule, IntercomUIModule, NgxPaginationModule, RouterModule, TranslateModule],
	exports: [
		IsTableItemDeleteVisibleAsyncPipe,
		SearchAsyncPipe,
		MapAsyncPipe,
		SortAsyncPipe,
		SharedObservablePipe,
		CommonModule,
		ModalHostComponent,
		PromptPanelComponent,
		PositioningFromParent,
		TabWithinElementDirective,
		ViewClippingDetectorDirective,
		ItemFilterAsyncPipe,
		ExactMatchAsyncPipe,
		ToasterComponent,
		ToastIconAsyncPipe,
		BaseModalComponent,
		TrimPipe,
		TextMiddleEllipsisPipe,
		AccessiblePaginationControlsComponent,
		EventDirective,
		PageViewDirective,
		ToastComponent,
		SelectionUpdatePipe,
		GateKeeperDirective,
		BlobPathDirective,
		FeatureRatingComponent,
		FeatureOptSettingsComponent
	],
	declarations: [
		IsTableItemDeleteVisibleAsyncPipe,
		SearchAsyncPipe,
		MapAsyncPipe,
		SortAsyncPipe,
		SharedObservablePipe,
		TabWithinElementDirective,
		ViewClippingDetectorDirective,
		PositioningFromParent,
		ModalHostComponent,
		ModalHostContentComponent,
		PromptPanelComponent,
		ItemFilterAsyncPipe,
		ExactMatchAsyncPipe,
		ToasterComponent,
		ToastIconAsyncPipe,
		BaseModalComponent,
		TrimPipe,
		TextMiddleEllipsisPipe,
		AccessiblePaginationControlsComponent,
		EventDirective,
		PageViewDirective,
		ToastComponent,
		SelectionUpdatePipe,
		GateKeeperDirective,
		BlobPathDirective,
		FeatureRatingComponent,
		FeatureOptSettingsComponent
	],
	entryComponents: [PromptPanelComponent]
})
export class CoreModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: CoreModule,
			providers: [
				AnalyticsService,
				BaseService,
				ConfigService,
				EventBusService,
				GenericPromptService,
				ModalHostService,
				DirtyBitService,
				GateKeeperService,
				FileService,
				PaginationResourceCacheService,
				ResourceChunkingService,
				ResourceCountService,
				DictionaryLocalStorageService,
				FeatureRatingService,
				FeatureOptSettingsService,
				{
					provide: GENERIC_CACHE_SERVICE_TOKEN,
					useClass: GenericCacheService
				},
				{
					provide: RESOURCE_CACHE_SERVICE_TOKEN,
					useClass: ResourceCacheService
				},
				{
					provide: LINKABLE_CACHE_ADAPTER_SERVICE_TOKEN,
					useClass: LinkableCacheAdapterService
				},
				{
					provide: TOASTER_SERVICE_TOKEN,
					useClass: ToasterService
				},
				{
					provide: PROMPT_PANEL_COMP_TOKEN,
					useValue: {
						componentType: PromptPanelComponent,
						defaultParams: {}
					}
				}
			]
		};
	}

	public static providersForChild(): Provider[] {
		return [
			DirtyBitService,
			PaginationResourceCacheService,
			ResourceChunkingService,
			ResourceCountService,
			{
				provide: GENERIC_CACHE_SERVICE_TOKEN,
				useClass: GenericCacheService
			},
			{
				provide: RESOURCE_CACHE_SERVICE_TOKEN,
				useClass: ResourceCacheService
			},
			{
				provide: LINKABLE_CACHE_ADAPTER_SERVICE_TOKEN,
				useClass: LinkableCacheAdapterService
			},
			{
				provide: TRAINING_SERVICE_TOKEN,
				useClass: TrainingService
			}
		];
	}
}
