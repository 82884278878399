import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';

let internalId = 0;

@Component({
    selector: 'm-toggle',
    templateUrl: 'toggle.component.html',
    styleUrls: ['toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToggleComponent implements OnInit, OnChanges {
    @Input() disabled?: boolean;
    @Input() toggleOnText?: string;
    @Input() toggleOffText?: string;
    @Input() checked?: boolean;

    @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('toggleElement') public toggleElement: ElementRef;

    toggleId: string;
    displayText: string;
    isFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isChecked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
        this.toggleId = `m-toggle-id-${internalId}`;
        internalId += 1;
    }

    ngOnInit(): void {
        this.displayText = this.checked ? this.toggleOnText : this.toggleOffText;
        this.isChecked.subscribe((checked) => {
            this.displayText = checked ? this.toggleOnText : this.toggleOffText;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['checked']) {
            this.isChecked.next(
                <boolean>changes['checked'].currentValue
            );
        }
    }

    public _onFocus(): void {
        this.isFocused.next(true);
        this.toggleElement.nativeElement.focus();
    }

    public _onBlur(): void {
        this.isFocused.next(false);
    }

    public _toggleChecked(e: UIEvent): void {
        e.preventDefault();

        if (this.disabled) {
            return;
        }

        const newValue = !this.isChecked.getValue();
        this.isChecked.next(newValue);

        this.checkedChange.emit(newValue);
    }
}
