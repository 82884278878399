import { NgModule, Provider } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from '@luis/core';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DomainCardComponent } from './components/domain-card/domain-card.component';
import { DomainEntitiesModalComponent } from './components/domain-entities-modal/domain-entities-modal.component';
import { DomainIntentsModalComponent } from './components/domain-intents-modal/domain-intents-modal.component';
import { DomainListComponent } from './components/domain-list/domain-list.component';
import { TileBodyComponent } from './components/tile-view/tile-body/tile-body.component';
import { TileCaptionComponent } from './components/tile-view/tile-caption/tile-caption.component';
import { TileViewComponent } from './components/tile-view/tile-view.component';
import { TileComponent } from './components/tile-view/tile/tile.component';
import { DOMAIN_SERVICE_TOKEN } from './interfaces/IDomainService';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DomainService } from './services/domain.service';

@NgModule({
	imports: [CoreModule, IntercomUIModule, TranslateModule, MatDialogModule],
	exports: [DomainListComponent, TruncatePipe, DomainIntentsModalComponent, DomainEntitiesModalComponent],
	declarations: [
		TileViewComponent,
		TileComponent,
		TileBodyComponent,
		TileCaptionComponent,
		DomainListComponent,
		DomainCardComponent,
		TruncatePipe,
		DomainIntentsModalComponent,
		DomainEntitiesModalComponent
	],
	entryComponents: [DomainIntentsModalComponent, DomainEntitiesModalComponent]
})
export class DomainsModule {
	public static providersForChild(): Provider[] {
		return [
			{
				provide: DOMAIN_SERVICE_TOKEN,
				useClass: DomainService
			}
		];
	}
}
