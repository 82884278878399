import { Pipe, PipeTransform } from '@angular/core';
import { Id, IToolBarOps } from '@luis/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';

/**
 * @description
 */
@Pipe({
    name: 'activeEditModeAsync'
})
export class ActiveEditModeAsync implements PipeTransform {

    /**
     * @method
     * @description
     * Checks the state of the selected ids to manage the edit mode per row.
     *
     * @param id The id of the current item.
     * @param toolBarOps The toolbar options.
     * @param selectedUtterancesIds The ids of the selected utterances.
     * @param editMode A boolean represents the editing state in the errored utterance table.
     */
    public transform(id: Id, toolBarOps: Observable<IToolBarOps>, selectedIds: Observable<Id[]>,
                     editMode: BehaviorSubject<boolean>): Observable<boolean> {
        if (!selectedIds) {
            return Observable.of(false);
        }

        return Observable.combineLatest(toolBarOps, selectedIds, editMode)
            .map(data => data[0].single && data[2] && data[1].indexOf(id) !== -1);
    }
}
