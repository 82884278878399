import { Component, OnInit } from '@angular/core';
import { AuthService } from '@luis/auth';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
	selector: 'global-header',
	templateUrl: 'global-header.component.html'
})
export class GlobalHeaderComponent implements OnInit {
	public isLoggedIn: Observable<boolean>;

	constructor(public readonly _authService: AuthService) {}

	public ngOnInit(): void {
		this.isLoggedIn = this._authService.isLoggedIn().pipe(shareReplay());
	}
}
