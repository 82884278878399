import {
    Directive,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GateKeeperService } from '../services/utils/gate-keeper.service';

/**
 * @description
 * This directive hides and displays the component based on the permission
 * of the input feature comparing to the features permission object that
 * returned from gate keeper service.
 */
@Directive({
    selector: '[gateKeeper]'
})
export class GateKeeperDirective implements OnDestroy {
    @Input() set gateKeeper(features: string[]) {
        this._gateKeeperSubscription = this._gateKeeper.subscribeToGate()
            .subscribe(permissions => {
                const permit: boolean = features.reduce((acc, permission) => acc || permissions[permission], false);
                if (permit && !this._hasView) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    this._hasView = true;
                }
                else if (!permit && this._hasView) {
                    this.viewContainer.detach();
                    this._hasView = false;
                }
            });
    }

    private _hasView: boolean = false;
    private _gateKeeperSubscription: Subscription = new Subscription();

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private _gateKeeper: GateKeeperService) { }

    public ngOnDestroy(): void {
        this._gateKeeperSubscription.unsubscribe();
    }
}
