import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ClosedEntity, ClosedSublist } from '../../../../models/closed-entity.model';

/**
 * @description
 * Represents the entity creation dialog component for creating new
 * closed entities.
 */
@Component({
    selector: 'closed-creation',
    templateUrl: 'closed-creation.component.html',
    styleUrls: ['closed-creation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClosedCreationComponent implements OnInit {
    @Input() public entity: ClosedEntity;
    @Input() public word: string;

    @Output() public changedEntity: EventEmitter<ClosedEntity> = new EventEmitter<ClosedEntity>();
    @Output() public enterClicked: EventEmitter<void> = new EventEmitter<void>();

    private _KEY_CODES: { COMMA: number, ENTER: number } = { COMMA: 188, ENTER: 13 };

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @method
     * @description
     * Adds the current text in the given field as a value in the given sublist if the keyboard
     * event that fired this function corresponds to a comma key.
     *
     * @param sublist The sublist to add the value to.
     * @param sublistValueField The input field to add the value to.
     * @param keyEvent The keyboard event object passed when keyboard event fired.
     */
    public onSublistValueKeyPress(sublist: ClosedSublist, sublistValueField: HTMLInputElement, keyEvent: KeyboardEvent): void {
        const addSublist: boolean =
            (keyEvent.keyCode === this._KEY_CODES.COMMA ||
                keyEvent.keyCode === this._KEY_CODES.ENTER) &&
            !!sublistValueField.value;

        const commitEntry: boolean =
            keyEvent.keyCode === this._KEY_CODES.ENTER &&
            keyEvent.ctrlKey;

        if (addSublist) {
            this.addSublist(sublist, sublistValueField);
        }
    }

    /**
     * Adds sublist to closed sublist and commits value if specified
     * @param sublist to add sublist value to
     * @param sublistValueField HTML element that carries the value
     * @param commit to indicated if values should be commited immediately to sublist
     */
    public addSublist(sublist: ClosedSublist, sublistValueField: HTMLInputElement): void {
        this._addValueToEntry(sublist, sublistValueField.value, sublistValueField.value.endsWith(','));
        sublistValueField.value = '';
    }

    /**
     * @method
     * @description
     * Adds any remaining text as an sublist value. Notifies the parent that
     * the enter key was clicked.
     */
    public onEnterClick(sublist: ClosedSublist, sublistValueField: HTMLInputElement): void {
        this._addValueToEntry(sublist, sublistValueField.value, false);
        sublistValueField.value = '';
        this.enterClicked.emit();
    }

    /**
     * @description
     * Adds the given calue to the given sublist's values. Removes last character in string
     * if trailing comma flag is set. Emits the changed entity if the value was added.
     *
     * @param sublist The sublist to add the value in.
     * @param value The value to add.
     * @param isCommaTrailed A flag specifiying whether a trailing comma is existing or not.
     */
    private _addValueToEntry(sublist: ClosedSublist, value: string, isCommaTrailed: boolean): void {
        const newValue: string = value.trim();
        if (newValue.length > 0) {
            sublist.addValue(isCommaTrailed && newValue.indexOf(',') !== -1 ? newValue.slice(0, -1) : newValue);
            this.changedEntity.emit(this.entity);
        }
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        if (this.word) {
            this.entity.sublists.push(new ClosedSublist(-1, this.word));
        }
    }
}
