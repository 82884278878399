import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, OnInit } from '@angular/core';
import { BaseModalComponent, FileService, IModalComponent, IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { BATCH_TESTING_SERVICE_TOKEN, IBatchTestingService } from '../../../interfaces/IBatchTestingService';
import { DatasetMetadata } from '../../../models/dataset-metadata.model';

export const IMPORT_DATASET_MODAL_COMP_TOKEN: InjectionToken<string> = new InjectionToken('DatasetImportModalComponent');

/**
 * @description
 * Represents the modal for importing a new dataset.
 */
@Component({
	selector: 'dataset-import-modal',
	templateUrl: 'dataset-import-modal.component.html',
	styleUrls: ['dataset-import-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetImportModalComponent extends BaseModalComponent implements OnInit, IModalComponent<DatasetMetadata> {
	@Input() public response: Subject<DatasetMetadata>;

	public isButtonDisabled: Observable<boolean>;
	public isHelpVisible: boolean = false;

	private _selectedFile: File;
	private _isFileValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _isNameValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private _fileService: FileService,
		@Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
		@Inject(BATCH_TESTING_SERVICE_TOKEN) private _batchTestingService: IBatchTestingService
	) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this._initState();
	}

	/**
	 * @method
	 * @description
	 * Updates the validation stream when the file value changes.
	 *
	 * @param file The dataset file to validate.
	 */
	public onFileChange(file: File): void {
		this._selectedFile = file;
		this._isFileValid.next(this._validateFile(file.name));
	}

	/**
	 * @method
	 * @description
	 * Checks if the name field and appends the result on the name
	 * validator stream.
	 *
	 * @param nameField The dataset name field.
	 */
	public onNameChange(nameField: HTMLInputElement): void {
		const datasetName: string = nameField.value;
		this._isNameValid.next(datasetName !== null && datasetName.trim() !== '');
	}

	/**
	 * @method
	 * @description
	 * Imports the dataset file given with the dataset name.
	 *
	 * @param datasetName The name of the new dataset.
	 */
	public importDataset(datasetName: HTMLInputElement): void {
		if (this._validateFile(this._selectedFile.name)) {
			this._inProgress.next(true);

			this._fileService
				.read(this._selectedFile)
				.flatMap(content => this._batchTestingService.import(content, datasetName.value.trim()))
				.trackProgress(this._toasterService.add())
				.finally(() => this._inProgress.next(false))
				.subscribe(dataset => {
					this.response.next(dataset);
					this.response.complete();
				});
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.isButtonDisabled = Observable.combineLatest(
			this._inProgress.asObservable(),
			this._isFileValid.asObservable(),
			this._isNameValid.asObservable()
		).map(([inProgress, isFileValid, isNameValid]) => inProgress || !isFileValid || !isNameValid);
	}

	/**
	 * @description
	 * Validates the file name and extension
	 *
	 * @param fileName The file name to validate.
	 * @returns True if the file name is valid and false otherwise.
	 */
	private _validateFile(fileName: string): boolean {
		const extension: string = fileName.replace(/^.*\./, '');

		return fileName.trim() !== '' && extension.toLocaleLowerCase() === 'json';
	}
}
