import { BaseItemFilter, IDataItem } from '@luis/core';
import { Pattern } from '../pattern.model';

/**
 * @description
 * Represents an error item filter that filters items
 * based on whether there are errors in the item or not. Used
 * by the item errors filter pipe.
 */
export class ErrorItemFilter extends BaseItemFilter {
    /**
     * @method
     * @description
     * Filters the given item by whether it contains errors
     * or not.
     *
     * @param item The item to filter.
     * @returns True if the item should be displayed
     * and false if it should not be displayed.
     */
    public filter(item: IDataItem): boolean {
        if (item instanceof Pattern) {
            return this._checkForPatternErrors(item);
        }
        else {
            return false;
        }
    }

    /**
     * @description
     * Checks for errors in the given pattern.
     *
     * @param pattern The pattern to check for errors.
     * @returns True if the pattern contains errors
     * and false otherwise.
     */
    private _checkForPatternErrors(pattern: Pattern): boolean {
        return false;
    }
}
