import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs/Rx';
import { EventBusMessage } from '../../models/utils/event-bus.model';

/**
 * @description
 * Represents the system wide event bus service. Used to decouple intercommunication
 * between peer services.
 */
@Injectable()
export class EventBusService {
	private readonly _eventBus: Subject<EventBusMessage> = new Subject<EventBusMessage>();

	/**
	 * @description
	 * Subscribes to the event bus and listens to a certain event occurence. Fires
	 * the provided callback when that event occurs.
	 *
	 * @param eventType The event enumeration to listen to. Should be of enum
	 * BUS_EVENTS defined in the event bus model file.
	 * @param callback The function to fire when the event occurs.
	 */
	public subscribeToBus(eventType: number, callback: (m) => void): Subscription {
		return this._eventBus
			.asObservable()
			.filter(m => m.type === eventType)
			.map(m => m.data)
			.subscribe(callback);
	}

	/**
	 * @description
	 * Publishes the given message to the bus.
	 *
	 * @param message The message to publish.
	 */
	public publishToBus(message: EventBusMessage): void {
		this._eventBus.next(message);
	}
}
