import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { IIntentService, INTENT_SERVICE_TOKEN } from '../../interfaces/IIntentService';
import { Intent } from '../../models/intent.model';

/**
 * @description
 * Represents the modal for creating a new intent.
 */
@Component({
	selector: 'intent-creation-modal',
	templateUrl: 'intent-creation-modal.component.html',
	styleUrls: ['intent-creation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntentCreationModalComponent {
	public isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<IntentCreationModalComponent>,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService
	) {}

	/**
	 * @description
	 * Fired when the intent field value changes. Validates the current
	 * field contents and updates the validation stream.
	 *
	 * @param query The current string in the name field.
	 */
	public onNameChange(query: string): void {
		this.isValid.next(this._validateIntentName(query));
	}

	/**
	 * @description
	 * Creates the intent when the enter key is clicked.
	 *
	 * @param name The name of the intent to create.
	 */
	public onEnterClick(name: string): void {
		this.onCreateClick(name);
	}

	/**
	 * @description
	 * Adds a new intent to the application given the intent name.
	 *
	 * @param name The new intent name to add.
	 */
	public onCreateClick(name: string): void {
		if (this._validateIntentName(name)) {
			const newIntent: Intent = new Intent('', name.trim());
			this.inProgress.next(true);

			this._intentService
				.add(newIntent)
				.finally(() => this.inProgress.next(false))
				.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('intents.intent-creation-modal.add_toast') }))
				.subscribe(id => {
					newIntent.id = <string>id;
					this._dialogRef.close(newIntent);
				});
		}
	}

	/**
	 * @description
	 * Checks if the intent name is valid.
	 *
	 * @param name The name to validate
	 * @returns True if the name is valid and false otherwise.
	 */
	private _validateIntentName(name: string): boolean {
		const intentNameLength: number = name.trim().length;

		return intentNameLength > 0 && intentNameLength <= 50;
	}
}
