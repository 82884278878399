import { ICloneable } from '@luis/core';

/**
 * @description
 * Represents the sentiment result for an utterance.
 *
 * @param label The label of the sentiment.
 * @param score The score of the sentiment.
 */
export class UtteranceSentiment implements ICloneable {
	public label: string;
	public score: number;

	constructor(label: string = '', score: number = null) {
		this.label = label;
		this.score = score;
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): UtteranceSentiment {
		return new UtteranceSentiment(apiObject.label, apiObject.score);
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): UtteranceSentiment {
		return new UtteranceSentiment(this.label.slice(), this.score);
	}
}
