import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @description
 * Represents a section in the sidebar page.
 */
@Component({
    selector: '[m-sidebar-section]',
    templateUrl: 'sidebar-section.component.html',
    styleUrls: ['sidebar-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSectionComponent {
    @Input() public sectionTitle: string;

    public isExpanded: boolean = true;
}
