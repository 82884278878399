import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: '[error-underline]',
	templateUrl: 'error-underline.component.html',
	styleUrls: ['./error-underline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorUnderlineComponent {
	@Input() public showError: boolean = false;
}
