import { InjectionToken } from '@angular/core';
import { IRequestOptions } from '@luis/api';
import { IPage } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { Utterance } from '../../models/utterance.model';

export const UTTERANCE_CACHE_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IUtteranceCacheService');

/**
 * @description
 * Represents an interface for an utterance cache service. An utterance cache service is responsible for
 * making web calls and cacheing their results in a central cache for all utterances across LUIS models.
 */
export interface IUtteranceCacheService {
	get(
		basePath: string,
		modelId: string,
		pageIndex: number,
		getAll: boolean,
		options?: IRequestOptions,
		parser?: (response: any) => Utterance
	): Observable<IPage<Utterance>>;

	post(
		basePath: string,
		modelName: string,
		utterance: Utterance,
		options?: IRequestOptions,
		parser?: typeof Utterance.importNewIdFromApi
	): Observable<number>;

	batchPost(
		basePath: string,
		modelName: string,
		utterances: Utterance[],
		options?: IRequestOptions,
		parser?: typeof Utterance.importNewIdsFromBatchApi,
		type?: 'intent' | 'entity'
	): Observable<number[]>;

	replace(
		basePath: string,
		modelName: string,
		utterance: Utterance,
		idToReplace: number,
		options?: IRequestOptions,
		parser?: typeof Utterance.importNewIdFromApi
	): Observable<number>;

	delete(
		basePath: string,
		modelName: string,
		id: number,
		options?: IRequestOptions,
		parser?: typeof Utterance.importFromApi
	): Observable<void>;

	batchDelete(
		basePath: string,
		modelName: string,
		ids: number[],
		options?: IRequestOptions,
		parser?: typeof Utterance.importFromApi
	): Observable<number[]>;

	mergeBatch(
		intentId: string,
		utterances: Utterance[],
		updateFunction: (oldUtterance: Utterance, newUtterance: Utterance) => Utterance
	): void;
}
