import { ICloneable } from '@luis/core';
import { Utterance } from '@luis/utterances';
import { EntityConfusion } from './entity-confusion.model';

/**
 * @description
 * Represents an utterance in the batch dataset of utterances used
 * to test the application.
 *
 * @param utterance The utterance that was tested.
 * @param confusionScores A dictionary of (intent/entity ids) along with
 * the confusion score
 */
export class DatasetResultItem implements ICloneable {
	public utterance: Utterance;
	public entityConfusions: Map<string, EntityConfusion[]>;

	constructor(utterance: Utterance = null, confusionScores: Map<string, EntityConfusion[]> = new Map()) {
		this.utterance = utterance;
		this.entityConfusions = confusionScores;
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): DatasetResultItem {
		const utterance: Utterance = Utterance.importFromApi(apiObject);
		const confusionMatrix: Map<string, EntityConfusion[]> = new Map<string, EntityConfusion[]>();

		Object.keys(apiObject.roleConfusions).forEach(id =>
			confusionMatrix.set(id, EntityConfusion.importFromApi(apiObject.roleConfusions[id]))
		);

		Object.keys(apiObject.entityConfusions).forEach((id, index) => {
			confusionMatrix.set(id, EntityConfusion.importFromApi(apiObject.entityConfusions[id]));
		});

		return new DatasetResultItem(utterance, confusionMatrix);
	}

	/**
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): DatasetResultItem {
		const matrixClone: Map<string, EntityConfusion[]> = new Map<string, EntityConfusion[]>();
		this.entityConfusions.forEach((item, id) => matrixClone.set(id, item));

		return new DatasetResultItem(this.utterance.clone(), matrixClone);
	}
}
