import { PaginationInstance } from 'ngx-pagination';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { IResource } from '../../interfaces/models/IResource';
import { IPage } from '../caches/paginated-cache.model';
import { ProgressTracker } from './progress-tracker.model';

export interface IPaginationUIPayloadInput<T extends IResource> {
    page$: Observable<IPage<T>>;
    pageIndex$: Observable<number>;
    chunkingTrigger$: Observable<boolean>;
    chunkingTracker: ProgressTracker;
    config: { pageSize: number; ngxPaginationId?: string };
}

/**
 * @description
 * Represents a helper class to be used in angular templates, facilitating a number of common pagination related
 * tasks.
 */
export class PaginationUIPayload<T extends IResource> {
    private _page$: ReplaySubject<IPage<T>> = new ReplaySubject(1);
    private _pageIndex$: ReplaySubject<number> = new ReplaySubject(1);
    private _chunkingTrigger$: ReplaySubject<boolean> = new ReplaySubject(1);
    private _config: { pageSize: number; ngxPaginationId?: string };
    private _chunkingTracker: ProgressTracker;

    private _pageSub: Subscription = new Subscription();
    private _pageIndexSub: Subscription = new Subscription();
    private _chunkingTriggerSub: Subscription = new Subscription();

    /**
     * @description
     * Initializes the model.
     *
     * @param page$ An observable carrying the currently selected page.
     * @param pageIndex$ An observable carrying the currently selected page index.
     * @param chunkingTrigger$ An observable carrying whether the user is searching or filtering the data.
     * @param config Object containing configuration params.
     */
    public init({ page$, pageIndex$, chunkingTrigger$, chunkingTracker, config }: IPaginationUIPayloadInput<T>): void {
        this._pageSub = page$.pipe(tap(x => this._page$.next(x))).subscribe();
        this._pageIndexSub = pageIndex$.pipe(tap(x => this._pageIndex$.next(x))).subscribe();
        this._chunkingTriggerSub = chunkingTrigger$.pipe(tap(x => this._chunkingTrigger$.next(x))).subscribe();
        this._chunkingTracker = chunkingTracker;
        this._config = config;
    }

    /**
     * @description
     * Unsubscribes from all the observable streams.
     */
    public dispose(): void {
        this._pageSub.unsubscribe();
        this._pageIndexSub.unsubscribe();
        this._chunkingTriggerSub.unsubscribe();
    }

    /**
     * @description
     * Returns an observable array of the currently selected page.
     */
    public get items(): Observable<T[]> {
        return this._page$.pipe(map(page => page.data));
    }

    /**
     * @description
     * Returns an observable boolean flag indicating whether data is being
     * loaded from the backend or not.
     */
    public get isLoading(): Observable<boolean> {
        return combineLatest(this._page$, this._chunkingTrigger$).pipe(
            map(([page, chunkingTrigger]) => (!page.isInitialized || page.isRefreshing) && !chunkingTrigger),
            startWith(true)
        );
    }

    /*
     * @description
     * Returns an observable boolean flag indicating whether the page has no items or not.
     */
    public get isEmpty(): Observable<boolean> {
        return this._page$.pipe(map(page => page.isInitialized && !page.isRefreshing && page.data.length === 0));
    }

    /*
     * @description
     * Returns an observable boolean flag indicating whether all the pages have been fetched or not.
     */
    public get isAllFetched(): Observable<boolean> {
        return this._page$.pipe(map(page => page.data.length >= page.totalItems));
    }

    /*
     * @description
     * Returns an observable boolean flag indicating whether the user is searching or not.
     */
    public get isSearching(): Observable<boolean> {
        return this._chunkingTrigger$;
    }

    /**
     * @description
     * Returns an observbale of the page info that gets fed into the pagination library.
     */
    public get pageInfo(): Observable<PaginationInstance> {
        return combineLatest(this._page$, this._pageIndex$).pipe(
            map(([page, pageIndex]) => ({
                id: this._config.ngxPaginationId,
                itemsPerPage: this._config.pageSize,
                currentPage: pageIndex,
                totalItems: page ? page.totalItems : undefined
            })),
            startWith({
                id: this._config.ngxPaginationId,
                currentPage: 1,
                itemsPerPage: this._config.pageSize
            })
        );
    }

    /**
     * @description
     * Returns a unique id identifying the pagination instance.
     */
    public get ngxPaginationId(): string {
        if (this._config === undefined) {
            return '';
        }

        return this._config.ngxPaginationId;
    }

    /*
     * @description
     * Returns an observable boolean flag indicating if no results matching the query were found.
     * @param items The filtered data array
     */
    public noSearchResults(items: T[]): Observable<boolean> {
        return combineLatest(this.isSearching, this._chunkingTracker.isEnded).pipe(
            map(([isFiltering, isEnded]) => isFiltering && isEnded && items.length === 0)
        );
    }

    /*
     * @description
     * Returns an observable boolean flag indicating if the user is searching for results and no data is shown yet to the user.
     * @param items The filtered data array
     */
    public loadingSearchResults(items: T[]): Observable<boolean> {
        return combineLatest(this.isSearching, this._chunkingTracker.isEnded).pipe(
            map(([isFiltering, isEnded]) => isFiltering && !isEnded && items.length === 0)
        );
    }

    /*
     * @description
     * Returns an observable boolean flag indicating if the user is searching for results and the data shown to the user is
     * less than the page size.
     *
     * @param items The filtered data array
     */
    public loadingMoreSearchResults(items: T[]): Observable<boolean> {
        return combineLatest(this.isSearching, this._chunkingTracker.isEnded).pipe(
            map(([isFiltering, isEnded]) => isFiltering && !isEnded && items.length > 0 && items.length < this._config.pageSize)
        );
    }
}
