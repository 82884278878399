import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { EntitiesModule } from '@luis/entities';
import { IntentsModule } from '@luis/intents';
import { TranslateModule } from '@ngx-translate/core';
import { TokenPositionDirective } from '../../directives/token-position.directive';
import { SharedModule } from '../../shared/shared.module';
import { CompositeSegmentComponent } from './composite-segment/composite-segment.component';
import { EntitySegmentComponent } from './entity-segment/entity-segment.component';
import { ErrorUnderlineComponent } from './error-underline/error-underline.component';
import { LabelableUtteranceComponent } from './labelable-utterance.component';
import { PlainSegmentComponent } from './plain-segment/plain-segment.component';

@NgModule({
	imports: [SharedModule, EntitiesModule, IntentsModule, MatMenuModule, TranslateModule],
	declarations: [
		CompositeSegmentComponent,
		EntitySegmentComponent,
		LabelableUtteranceComponent,
		ErrorUnderlineComponent,
		PlainSegmentComponent,
		TokenPositionDirective
	],
	exports: [
		CompositeSegmentComponent,
		EntitySegmentComponent,
		LabelableUtteranceComponent,
		ErrorUnderlineComponent,
		PlainSegmentComponent,
		TokenPositionDirective
	],
	providers: []
})
export class LabelableUtteranceModule {}
