import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Rx';
import { DEFAULT_SEARCH_PROPS, ISearchPipeProps, SEARCH_TYPES } from '../interfaces/utils/ISearchPipeProps';

/**
 * @description
 * Searches for the given query in the given dataset.
 */
@Pipe({
    name: 'searchAsync'
})
export class SearchAsyncPipe implements PipeTransform {
    constructor(private _ref: ChangeDetectorRef) {}

    /**
     * @description
     * Searches for the given query in the given dataset with the given
     * search options.
     *
     * @param searchSet The dataset to search for the query in.
     * @param query The query to search for.
     * @param fields The fields of the dataset to enumerate.
     * @param options The search options
     */
    public transform(
        searchSet: Observable<any> | any,
        query: Observable<string>,
        fields: string[],
        options?: Observable<ISearchPipeProps>
    ): any {
        let _options: Observable<ISearchPipeProps> = options;
        const searchSet$ = searchSet instanceof Observable ? searchSet : of(searchSet);

        if (!_options) {
            _options = Observable.of(DEFAULT_SEARCH_PROPS);
        }

        if (!searchSet) {
            return searchSet$;
        }

        if (!query) {
            return searchSet$;
        }

        if (fields.length < 1) {
            return searchSet$;
        }

        return Observable.combineLatest(searchSet$, query, _options).map(([_searchSet, _query, opts]) => {
            const ret: any[] = [];

            this._ref.markForCheck();

            for (const obj of _searchSet) {
                for (const field of fields) {
                    const needle = _query ? _query.trim() : '';
                    const haystack = obj[field] ? obj[field].trim() : '';
                    const isExactMatch: boolean = this._isExactMatch(needle, haystack, opts.caseSensitive);
                    const searchFunction: Function = this._getSearchFunction(opts.searchType);

                    if (isExactMatch || (obj[field] && searchFunction(needle, haystack, opts.caseSensitive))) {
                        ret.push(obj);
                        break;
                    }
                }
            }

            return ret;
        });
    }

    /**
     * @description
     * Gets the correct search function based on the given search type.
     *
     * @param searchType The search type to get the function for.
     * @returns The correct search function for the search type.
     */
    private _getSearchFunction(searchType: SEARCH_TYPES): Function {
        switch (searchType) {
            case SEARCH_TYPES.STARTS_WITH:
                return this._startsWithSearch;
            case SEARCH_TYPES.PREFIX:
            default:
                return this._prefixSearch;
        }
    }

    /**
     * @description
     * Checks if the needle and haystack exactly match each other or not.
     *
     * @param needle The search query.
     * @param haystack The text to search in.
     * @param caseSensitive True if search is case sensitive and false otherwise.
     * @returns True if they are an exact match.
     */
    private _isExactMatch(needle: string, haystack: string, caseSensitive: boolean): boolean {
        let _needle: string = needle;
        let _haystack: string = haystack;

        if (!caseSensitive) {
            _needle = _needle.toLocaleLowerCase();
            _haystack = _haystack.toLocaleLowerCase();
        }

        return _needle === _haystack;
    }

    /**
     * @description
     * Searches the haystack for the needle in prefix fashion.
     *
     * @param needle The search query.
     * @param haystack The text to search in.
     * @param caseSensitive True if search is case sensitive and false otherwise.
     * @returns True if match found and false otherwise.
     */
    private _prefixSearch(needle: string, haystack: string, caseSensitive: boolean): boolean {
        const hlen: number = haystack.length;
        const nlen: number = needle.length;
        let _needle: string = needle;
        let _haystack: string = haystack;

        if (nlen > hlen) {
            return false;
        }

        if (!caseSensitive) {
            _needle = _needle.toLocaleLowerCase();
            _haystack = _haystack.toLocaleLowerCase();
        }

        if (nlen === hlen) {
            return _needle === _haystack;
        }

        return _haystack.includes(_needle);
    }

    /**
     * @description
     * Searches the haystack for the needle in starts with fashion.
     *
     * @param needle The search query.
     * @param haystack The text to search in.
     * @param caseSensitive True if search is case sensitive and false otherwise.
     * @returns True if match found and false otherwise.
     */
    private _startsWithSearch(needle: string, haystack: string, caseSensitive: boolean): boolean {
        const hlen: number = haystack.length;
        const nlen: number = needle.length;
        let _needle: string = needle;
        let _haystack: string = haystack;

        if (nlen > hlen) {
            return false;
        }

        if (!caseSensitive) {
            _haystack = _haystack.toLocaleLowerCase();
            _needle = _needle.toLocaleLowerCase();
        }

        if (nlen === hlen) {
            return _needle === _haystack;
        }

        return _haystack.startsWith(_needle);
    }
}
