import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { IAppVersionStates } from '../interfaces/IAppVersionStates';

export enum PIPE_MODE {
	CLASS,
	ARIA_LABEL,
	STATE_SUBTITLE,
	PUBLISH_ENV
}

/**
 * @description
 * Produces the correct class objects for the legend block for the version row
 * in the versions table based on the states of the versions for a
 * specific application.
 */
@Pipe({
	name: 'versionLegendAsync'
})
export class VersionLegendAsyncPipe implements PipeTransform {
	/**
	 * @description
	 * Handles several manipualations to generate metadata for versions
	 * based in the version id input and the current version states.
	 *
	 * @param versionId The version id to consider.
	 * @param versionStates The current version states.
	 * @param mode The pipe mode to use.
	 * @returns The manipulation based on the pipe mode.
	 */
	public transform(versionId: string, versionStates: Observable<IAppVersionStates>, mode: PIPE_MODE): Observable<any> {
		return versionStates.map(states => {
			switch (mode) {
				case PIPE_MODE.ARIA_LABEL:
					return this._handleAriaLabelMode(versionId, states);
				case PIPE_MODE.CLASS:
					return this._handleClassMode(versionId, states);
				case PIPE_MODE.STATE_SUBTITLE:
					return this._handleStateMode(versionId, states);
				case PIPE_MODE.PUBLISH_ENV:
					return this._handleEnvMode(versionId, states);
				default:
					return this._handleClassMode(versionId, states);
			}
		});
	}

	/**
	 * @description
	 * Generates the appropiate aria label for the version row based on
	 * the given version id and states.
	 *
	 * @param versionId The version id of the row
	 * @param states The current app version states.
	 * @returns The appropiate aria label for the given id.
	 */
	private _handleAriaLabelMode(versionId: string, states: IAppVersionStates): string {
		let label: string = '';

		if (versionId === states.active) {
			label = 'Currently active version';
		}

		if (versionId === states.dev && versionId === states.prod) {
			label = `${label} - Deployed on staging and production slots`;
		}
		if (versionId === states.dev) {
			label = `${label} - Deployed on staging slot`;
		}
		if (versionId === states.prod) {
			label = `${label} - Deployed on production slot`;
		}

		return label;
	}

	/**
	 * @description
	 * Generates the appropiate CSS class settings for the version row based on
	 * the given version id and states.
	 *
	 * @param versionId The version id of the row
	 * @param states The current app version states.
	 * @returns The appropiate CSS class settings for the given id.
	 */
	private _handleClassMode(versionId: string, states: IAppVersionStates): Object {
		if (versionId === states.dev && versionId === states.prod) {
			return { both: true };
		}
		if (versionId === states.dev) {
			return { dev: true };
		}
		if (versionId === states.prod) {
			return { prod: true };
		}

		return null;
	}

	/**
	 * @description
	 * Returns a string to show the current state of a given version.
	 *
	 * @param versionId The version id to display the state for.
	 * @param states The states of the versions in the app.
	 * @returns The string to display.
	 */
	private _handleStateMode(versionId: string, states: IAppVersionStates): string {
		let versionLabel: string = '';

		if (versionId === states.active) {
			versionLabel = versionLabel.concat('Active');
		}
		if (versionId === states.dev) {
			versionLabel = versionLabel.concat(!versionLabel ? 'Staging' : ' & Staging');
		}
		if (versionId === states.prod) {
			versionLabel = versionLabel.concat(!versionLabel ? 'Production' : ' & Production');
		}

		return versionLabel ? `(${versionLabel})` : '';
	}

	/**
	 * @description
	 * Returns a string to show the current publish environent of a given version.
	 *
	 * @param versionId The version id to display the state for.
	 * @param states The states of the versions in the app.
	 * @returns The string to display.
	 */
	private _handleEnvMode(versionId: string, states: IAppVersionStates): string {
		if (versionId === states.dev && states.devDate > states.prodDate) {
			return 'Staging';
		}
		if (versionId === states.prod) {
			return 'Production';
		}
	}
}
