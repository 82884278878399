import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * @description
 * The default pagination controls component. Actually just a default implementation
 * of a custom template.
 */
@Component({
    selector: 'accessible-pagination-controls',
    templateUrl: './accessible-pagination.component.html',
    styleUrls: ['./accessible-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AccessiblePaginationControlsComponent {

    constructor(private _i18n: TranslateService) { }

    @Input() public id: string;
    @Input() public maxSize: number = 7;
    @Input() public showPageControls: boolean = true;
    @Input()
    get directionLinks(): boolean {
        return this._directionLinks;
    }
    set directionLinks(value: boolean) {
        this._directionLinks = !!value && <any>value !== 'false';
    }
    @Input()
    get autoHide(): boolean {
        return this._autoHide;
    }
    set autoHide(value: boolean) {
        this._autoHide = !!value && <any>value !== 'false';
    }
    @Input() public previousLabel: string = this._i18n.instant('core.accessible-pagination.previous');
    @Input() public nextLabel: string = this._i18n.instant('core.accessible-pagination.next');
    @Input() public screenReaderPaginationLabel: string = this._i18n.instant('core.accessible-pagination.title');
    @Input() public screenReaderPageLabel: string = this._i18n.instant('core.accessible-pagination.page');
    @Input() public screenReaderCurrentLabel: string = this._i18n.instant('core.accessible-pagination.current_page');
    @Output() public pageChange: EventEmitter<number> = new EventEmitter<number>();

    private _directionLinks: boolean = true;
    private _autoHide: boolean = false;
}
