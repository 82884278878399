import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Entity, UtteranceEntity } from '@luis/entities';
import { Intent, UtteranceIntent } from '@luis/intents';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { ISuggestUtteranceDto, SuggestUtterancesService } from '../../../../services/suggest-utterances.service';

@Component({
	selector: 'suggest-row',
	templateUrl: 'suggest-row.component.html',
	styleUrls: ['suggest-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestRowComponent implements OnInit {
	@Input() public utteranceDto: ISuggestUtteranceDto;
	@Input() public intents: Observable<Intent[]>;
	@Output() public editEntity: EventEmitter<Entity> = new EventEmitter<Entity>();

	@ViewChild('expansionArea') public expansionArea: ElementRef;

	public isExpansionAreaExpanded: boolean = true;
	public defaultIntentName: Observable<string>;
	public childrenUtterances: Observable<ISuggestUtteranceDto[]>;

	constructor(private readonly _suggestUtteranceService: SuggestUtterancesService) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngAfterViewInit(): void {
		this._initExpansionAreaHeight();
	}

	/**
	 * @description
	 * Toggles the expansion area with animation.
	 */
	public toggleExpansionArea(): void {
		this.isExpansionAreaExpanded = !this.isExpansionAreaExpanded;

		const heightToSet = this.isExpansionAreaExpanded ? `${this.expansionArea.nativeElement.scrollHeight}px` : '0px';

		this.expansionArea.nativeElement.style.maxHeight = heightToSet;
	}

	/**
	 * @description
	 * Adds the utterance to the app's labeled data.
	 */
	public addUtterance(): void {
		this._suggestUtteranceService.addUtterance(this.utteranceDto);
	}

	/**
	 * @description
	 * Removes the utterance from the unlabled suggestions.
	 */
	public deleteUtterance(): void {
		this._suggestUtteranceService.deleteUtterance(this.utteranceDto);
	}

	/**
	 * @description
	 * Notifies parent of changed utterance when an intent changes.
	 *
	 * @param intent The intent that the utterance changed to.
	 */
	public onIntentChange(intent: Intent): void {
		this.utteranceDto.utterance.labeledIntent =
			this.utteranceDto.utterance.predictedIntents.find(i => i.id === intent.id) || new UtteranceIntent(intent.id, intent.name, null);

		this._suggestUtteranceService.updateUtterance(this.utteranceDto);
	}

	/**
	 * @description
	 * Notifies parent of changed utterance when entities change.
	 *
	 * @param utteranceEntities The new array of utterance entities to change to.
	 */
	public onEntityChange(utteranceEntities: UtteranceEntity[]): void {
		this.utteranceDto.utterance.labeledEntities = utteranceEntities;
		this._suggestUtteranceService.updateUtterance(this.utteranceDto);
	}

	/**
	 * @description
	 * Initialize the component.
	 */
	private _initState(): void {
		this.defaultIntentName = Observable.of(this.utteranceDto.utterance.labeledIntent.name);

		this.childrenUtterances = this._suggestUtteranceService.getUtteranceChildren(this.utteranceDto.utterance.id).pipe(shareReplay());
	}

	/**
	 * @description
	 * Initializes the height of the expansion area with the height of the content
	 * to ensure the expansion/collapse occurrs with animation.
	 */
	private _initExpansionAreaHeight(): void {
		if (this.expansionArea) {
			this.expansionArea.nativeElement.style.maxHeight = `${this.expansionArea.nativeElement.scrollHeight}px`;
		}
	}
}
