import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
	CompositeSegment,
	Entity,
	ENTITY_DROPDOWN_OPERATIONS,
	EntitySegment,
	EntityWrapService,
	ICompositeWrapMenuEvent,
	IEntityDropdownOperationPayload,
	IEntityMenuEvent,
	IUtteranceEntityChangeEvent,
	UtteranceEntity
} from '@luis/entities';
import { ESCAPE, getBoundingClientRectToBody } from '@luis/ui';
import { filter, mapTo, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs/Rx';
import { ISegmentComponent } from '../../../interfaces/utils/ISegmentComponent';
import { VIEW_OPTIONS } from '../../../models/plain-segment.model';
import { ItemTableService } from '../../../services/item-table.service';
import { SegmentFocusService } from '../../../services/segment-focus.service';
import { ILabelableOperationPayload, LabelableUtteranceComponent } from '../labelable-utterance.component';

/**
 * @description
 * Represents an entity segment of a labelable utterance. An entity segment abstracts
 * tokens that belong to an utterance entity and all the functionality needed to manipulate
 * said utterance entity.
 */
@Component({
	selector: 'entity-segment',
	templateUrl: 'entity-segment.component.html',
	styleUrls: ['entity-segment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySegmentComponent implements ISegmentComponent, OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input() public segment: EntitySegment;
	@Input() public parentSegment: CompositeSegment;
	@Output() public onWrapEntityClicked: EventEmitter<ICompositeWrapMenuEvent> = new EventEmitter<ICompositeWrapMenuEvent>();
	@Output() public onCompositeHovered: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public onOperation: EventEmitter<ILabelableOperationPayload> = new EventEmitter<ILabelableOperationPayload>();

	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

	@ViewChild('container') public containerRef: ElementRef;
	@ViewChild('label', { read: ElementRef }) public labelPill: ElementRef;

	public viewModes: typeof VIEW_OPTIONS = VIEW_OPTIONS;
	public showDropdown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isHovered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public viewMode: Observable<VIEW_OPTIONS>;
	public menuClosed: Observable<void>;
	public isEntityMachineLearned: Observable<boolean>;
	public segmentPredictionsString: string;
	public hasErrorSubject: ReplaySubject<boolean> = new ReplaySubject(1);
	public clickOutsideChecker: typeof LabelableUtteranceComponent.clickOutsideChecker = LabelableUtteranceComponent.clickOutsideChecker;
	public segmentSubject: ReplaySubject<EntitySegment> = new ReplaySubject(1);

	private _showDropdownSubscription: Subscription = new Subscription();
	private _segmentSubscription: Subscription = new Subscription();

	constructor(
		private _entityWrapService: EntityWrapService,
		private _itemTableService: ItemTableService,
		private _segmentFocusService: SegmentFocusService
	) {}

	public get segmentLabel(): Observable<string> {
		return this.viewMode.pipe(
			map(viewMode => {
				if (viewMode === this.viewModes.ENTITY_LABEL_VIEW) {
					return this.segment.entity.name + (this.segment.role ? `:${this.segment.role}` : '');
				}

				return this.segment.text.join(' ');
			})
		);
	}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.segment && changes.segment.currentValue !== changes.segment.previousValue) {
			this.segmentSubject.next(changes.segment.currentValue);
		}
	}

	public ngAfterViewInit(): void {
		if (this._segmentFocusService.shouldSegmentFocus(this.segment)) {
			this.labelPill.nativeElement.focus();
		}
	}

	public ngOnDestroy(): void {
		this._showDropdownSubscription.unsubscribe();
		this._segmentSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Calculates the distance from the segment to the bottom of the page.
	 */
	public calcDistanceToBottom = () => window.innerHeight - getBoundingClientRectToBody(this.containerRef.nativeElement).top;

	/**
	 * @description
	 * Shows the entity dropdown when the entity segment is clicked.
	 */
	public onClick(event: KeyboardEvent, config: { forceOpenMenu?: boolean; enableCompositeChildren?: boolean }): void {
		const { forceOpenMenu = false, enableCompositeChildren = false } = config;
		Observable.combineLatest(
			this._entityWrapService.getWrapMode().first(),
			this._entityWrapService.isValidSelection(this.segment.start).first()
		)
			.first()
			.do(([mode, isValid]) => (mode && !isValid ? this._entityWrapService.setWrapMode(false) : null))
			.subscribe(() => {
				// Show the dropdown menu only if the event is a keyboard event (i.e. triggered by pressing Enter).
				// or if `forceOpenMenu` is set to `true` 
				if (event instanceof KeyboardEvent || forceOpenMenu) {
					this.showDropdown.next(true);
				}

				this._entityWrapService.setIsCompositeChildren(enableCompositeChildren);
				this._entityWrapService.setWrapIndeces(this.segment.start, this.segment.end);
			});
	}

	/**
	 * @description
	 * Handles the keydown for accessbility for this component.
	 */
	@HostListener('document:keydown', ['$event'])
	public handleKeyDown(event: KeyboardEvent): void {
		switch (event.keyCode) {
			case ESCAPE:
				event.stopPropagation();
				this.onClickOutside();
				break;
			default:
		}
	}

	/**
	 * @description
	 * Hides the entity dropdown when the mouse is clicked outside the segment data.
	 */
	public onClickOutside(): void {
		this.showDropdown.next(false);
	}

	/**
	 * @description
	 * Triggers the hover stream that the entity was hovered upon.
	 * Notifies the labeling service of the current wrap composite
	 * hovering indeces.
	 */
	public onMouseEnter(): void {
		this.isHovered.next(true);
		this._entityWrapService.setWrapHoverIndeces(this.segment.start, this.segment.end);
	}

	/**
	 * @description
	 * Triggers the hover stream that the entity was no longer hovered upon.
	 * Notifies the labeling service of the current wrap composite
	 * hovering indeces.
	 */
	public onMouseLeave(): void {
		this.isHovered.next(false);
		this._entityWrapService.setWrapHoverIndeces(null, null);
	}

	/**
	 * @description
	 * Notifies parent (usually a composite segment) that the composite
	 * underline in this segment was hovered.
	 *
	 * @param state The hover state, true if hovered on
	 * and false otherwise.
	 */
	public onCompositeHover(state: boolean): void {
		this.onCompositeHovered.emit(state);
	}

	public handleOperation(payload: IEntityDropdownOperationPayload): void {
		const { event, operation } = payload;
		this.showDropdown.next(false);
		switch (operation) {
			case ENTITY_DROPDOWN_OPERATIONS.EXISTING_ENTITY: {
				const changeEvent = this._handleDropdownExistingEntityClick(event);
				this.onOperation.emit({ event: changeEvent, operation });

				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.NEW_ENTITY: {
				const changeEvent = this._handleDropdownNewEntityClick(event);
				this.onOperation.emit({ event: changeEvent, operation });

				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.DELETE_ENTITY: {
				const changeEvent = this._handleDropdownDeleteEntityClick(event);
				this.onOperation.emit({ event: changeEvent, operation });

				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.LABEL_COMPOSITE_WITH_ROLE: {
				this.onOperation.emit({
					event: {
						newUtteranceEntity: new UtteranceEntity(
							event.entity.id,
							event.entity.name,
							event.entity.type,
							(event.eventData && event.eventData.role) || '',
							this.segment.start,
							this.segment.end
						),
						entityEvent: event
					},
					operation
				});
				break;
			}
			default:
				this.onOperation.emit({ event: { entityEvent: event }, operation });
		}
	}

	/**
	 * @method
	 * @description
	 * Notifies parent that a wrap event occured.
	 *
	 * @param event The wrap event metadata
	 */
	public handleDropdownCompositeWrapClick(event: ICompositeWrapMenuEvent): void {
		if (event.closeMenu) {
			this.showDropdown.next(false);
		}
		event.startIndex = this.segment.start;
		event.endIndex = this.segment.end;
		this.onWrapEntityClicked.emit(event);
	}

	/**
	 * @description
	 * Notifies parent that an exisitng entity was clicked in the dropdown menu.
	 * Emits both the old labeled utterance entity and the new one.
	 */
	private _handleDropdownExistingEntityClick(entityEvent: IEntityMenuEvent): IUtteranceEntityChangeEvent {
		return {
			oldUtteranceEntity: new UtteranceEntity(
				this.segment.entity.id,
				this.segment.entity.name,
				this.segment.entity.type,
				(entityEvent.eventData && entityEvent.eventData.role) || '',
				this.segment.start,
				this.segment.end
			),
			newUtteranceEntity: new UtteranceEntity(
				entityEvent.entity.id,
				entityEvent.entity.name,
				entityEvent.entity.type,
				(entityEvent.eventData && entityEvent.eventData.role) || '',
				this.segment.start,
				this.segment.end,
				null,
				null,
				(entityEvent.eventData && entityEvent.eventData.roleId) || ''
			),
			entityEvent: entityEvent
		};
	}

	/**
	 * @description
	 * Emits the utterance entity that should be deleted when the entity segment is
	 * clicked for deletion. If the entity segment is a child of a parent segment, then
	 * the dropdown emits either a child or parent for deletion.
	 *
	 * @param entity The entity emitted for deletion by the dropdown menu. In
	 *                        case the entity segment component is a child (of a composite
	 *                        segment), the entity is of the parent type.
	 */
	private _handleDropdownDeleteEntityClick(entityEvent?: IEntityMenuEvent): IUtteranceEntityChangeEvent {
		return {
			newUtteranceEntity: null,
			oldUtteranceEntity: new UtteranceEntity(
				entityEvent.entity.id,
				entityEvent.entity.name,
				entityEvent.entity.type,
				'',
				this.segment.start,
				this.segment.end
			),
			entityEvent
		};
	}

	/**
	 * @description
	 * Notifies the parent that a new entity was created for the current selection
	 * Emits both the old labeled utterance entity and the new one.
	 */
	private _handleDropdownNewEntityClick(entityEvent: IEntityMenuEvent): IUtteranceEntityChangeEvent {
		return {
			oldUtteranceEntity: new UtteranceEntity(
				this.segment.entity.id,
				this.segment.entity.name,
				this.segment.entity.type,
				'',
				this.segment.start,
				this.segment.end
			),
			newUtteranceEntity: new UtteranceEntity('', entityEvent.entity.name, null, '', this.segment.start, this.segment.end),
			entityEvent
		};
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.viewMode = this._itemTableService.getViewMode();
		this.isEntityMachineLearned = this.segmentSubject.pipe(
			map(segment => Entity.isMachineLearned(segment.entity.type) || this.parentSegment !== undefined)
		);

		this._showDropdownSubscription = this.showDropdown
			.pipe(
				tap(isShown => {
					if (this.menuTrigger && this.menuTrigger.menu) {
						if (isShown) {
							this.menuTrigger.openMenu();
						} else {
							this.menuTrigger.closeMenu();
						}
					}
				})
			)
			.subscribe();

		this.menuClosed = this.showDropdown.pipe(
			filter(val => val === false),
			mapTo(null)
		);

		this.segmentSubject.next(this.segment);

		this._segmentSubscription = this.segmentSubject.pipe(tap(segment => this._trackSegmentErrors(segment))).subscribe();
	}

	/**
	 * @description
	 * Initializes the segment error variables that are passed to the
	 * entity dropdown to show or hide entity errors. If the current
	 * entity type is not labelable (prebuilts, regex, list, etc.)
	 * then no errors should be generated.
	 */
	private _trackSegmentErrors(segment: EntitySegment): void {
		if (Entity.isMachineLearned(segment.entity.type)) {
			this.segmentPredictionsString = segment.predictions.map(e => e.name).join(',');
			this.hasErrorSubject.next(this.segmentPredictionsString !== segment.entity.name);
		} else {
			this.segmentPredictionsString = segment.entity.name;
			this.hasErrorSubject.next(false);
		}
	}
}
