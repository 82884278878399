import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Intent } from '../../models/intent.model';

/**
 * @description
 * Represents a cofirmation modal to delete an intent with the
 * additional option of keeping the intent's utterances in the
 * None intent.
 */
@Component({
	selector: 'intent-delete-modal',
	templateUrl: 'intent-delete-modal.component.html',
	styleUrls: ['intent-delete-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntentDeleteModalComponent implements OnInit {
	public keepUtterances: boolean;
	public title: string;
	public message: string;

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<IntentDeleteModalComponent>,
		@Inject(MAT_DIALOG_DATA) private readonly _intents: Intent[]
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Changes the value of the flag whether to keep the
	 * intent's utterances or not.
	 *
	 * @param keep True if the utterances are to be
	 * transferred to the None intent and false otherwise.
	 */
	public onCheckChange(keep: boolean): void {
		this.keepUtterances = keep;
	}

	/**
	 * @description
	 * Notifies the caller that the Ok button was pressed and sends
	 * back the choice of whether to keep the utterances or not.
	 */
	public deleteIntent(): void {
		this._dialogRef.close(this.keepUtterances);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		const isBatch: boolean = this._intents.length > 1;

		this.title = isBatch
			? this._i18n.instant('intents.intent-delete-modal.modal_title_plural')
			: this._i18n.instant('intents.intent-delete-modal.modal_title_singular');

		this.message = isBatch
			? this._i18n.instant('intents.intent-delete-modal.modal_instructions_plural')
			: this._i18n.instant('intents.intent-delete-modal.modal_instructions_singular', { name: this._intents[0].name });
	}
}
