import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ENTER, ESCAPE } from '@luis/ui';
import { BehaviorSubject } from 'rxjs/Rx';
import { Utterance } from '../../../../models/utterance.model';

/**
 * @description
 * Represents an input field for utterances in the item table.
 */
@Component({
	selector: 'utterance-input',
	templateUrl: 'utterance-input.component.html',
	styleUrls: ['utterance-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtteranceInputComponent implements OnInit, OnChanges {
	@Input() public utterance: Utterance;
	@Input() public forceSubmit: boolean;

	@Output() public utteranceSubmitted: EventEmitter<Utterance> = new EventEmitter<Utterance>();
	@Output() public itemCanceled: EventEmitter<void> = new EventEmitter<void>();

	public inputData: BehaviorSubject<string> = new BehaviorSubject<string>('');

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.forceSubmit && changes.forceSubmit.currentValue) {
			this.onEnterClick();
		}
	}

	/**
	 * @description
	 * Notifies the parent that an utterance was submitted
	 * in the field.
	 */
	public onEnterClick(): void {
		const utteranceText: string = this.inputData.getValue().trim();
		const utteranceToSubmit: Utterance = this.utterance || new Utterance();

		if (this._isValid(utteranceText)) {
			utteranceToSubmit.text = utteranceText;
			this.utteranceSubmitted.emit(utteranceToSubmit);
			this.inputData.next('');
		}
	}

	/**
	 * @description
	 * Prevent bubbling up the event if the user presses space key.
	 */
	public onKeyDown(event: KeyboardEvent): void {
		if (event.keyCode === ENTER) {
			return;
		}
		if (event.keyCode === ESCAPE) {
			this.itemCanceled.emit();

			return;
		}
		event.stopPropagation();
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.utterance = this.utterance ? this.utterance.clone() : new Utterance();

		this.inputData.next(this.utterance.text);
	}

	/**
	 * @description
	 * Checks if the utterance in the input field is
	 * valid or not.
	 */
	private _isValid(text: string): boolean {
		if (text.length > 0 && text.length <= 500) {
			return true;
		}

		return false;
	}
}
