import { BaseItemFilter, IDataItem } from '@luis/core';
import { Intent } from '@luis/intents';
import { BehaviorSubject, Observable } from 'rxjs';
import { Utterance } from '../utterance.model';

export enum INTENT_FILTER_TYPES {
    LABLED_INTENT,
    NEAREST_RIVAL
}

/**
 * @description
 * Represents an intent item filter that filters data items
 * based on whether the intent chosen exists in the item
 * or not. Used by the item filter pipe.
 */
export class IntentItemFilter extends BaseItemFilter {
    private _intents: Map<string, Intent> = new Map<string, Intent>();
    private _intentsSubject: BehaviorSubject<Intent[]> = new BehaviorSubject<Intent[]>([]);
    private _trainingDate: BehaviorSubject<Date>;

    constructor(private _type: number = INTENT_FILTER_TYPES.LABLED_INTENT) {
        super();
    }

    public setTrainingDate(date:BehaviorSubject<Date>): void {
        this._trainingDate = date;
    }

    /**
     * @method
     * @description
     * Toggles the activation of the filter by either adding a
     * new entity to the entity map or removing if it was already existing.
     *
     * @param intent The intent to toggle.
     */
    public toggleActivation(intent: Intent): void {
        if (!intent) {
            this._intents.clear();
        } else if (this._intents.has(intent.id)) {
            this._intents.delete(intent.id);
        } else {
            this._intents.set(intent.id, intent);
        }

        this._intentsSubject.next(Array.from(this._intents.values()));
    }

    /**
     * @method
     * @description
     * Checks if the filter is active by checking the intent object.
     * If intent is null, then the filter is not active.
     */
    public isActive(): boolean {
        return this._intents.size !== 0;
    }

    /**
     * @method
     * @description
     * Gets the current filter intents as an observable.
     */
    public getIntents(): Observable<Intent[]> {
        return this._intentsSubject.asObservable();
    }

    /**
     * @method
     * @description
     * Gets the current filter intents as an observable.
     */
    public get intents(): Intent[] {
        return this._intentsSubject.getValue();
    }

    /**
     * @method
     * @description
     * Filters the given item by whether it contains the
     * given intent or not.
     *
     * @param item The item to filter.
     * @returns True if the item should be displayed
     * and false if it should not be displayed.
     */
    public filter(item: IDataItem): boolean {
        if (this._trainingDate && item instanceof Utterance && this._trainingDate.getValue() < item.assignedDate) {
            return true;
        }
        if (item instanceof Utterance) {
            switch (this._type) {
                case INTENT_FILTER_TYPES.NEAREST_RIVAL:
                    return item.nearestRival && this._intents.has(item.nearestRival.id);
                default:
                case INTENT_FILTER_TYPES.LABLED_INTENT:
                    return this._intents.has(item.labeledIntent.id);

            }
        } else {
            return false;
        }
    }
}
