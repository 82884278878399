﻿import { IApiExportable, ICloneable } from '@luis/core';

/**
 * @description
 * Represents publish settings for publishing an app.
 *
 * @param versionId The version id to publish to.
 * @param region The region to publish to.
 * @param isStaging A flag to indicate the slot to publish to.
 */
export class PublishSettings implements ICloneable, IApiExportable {
    public versionId: string;
    public region: string;
    public isStaging: boolean;

    constructor(versionId: string = '', isStaging: boolean = false, region: string = null) {
        this.versionId = versionId;
        this.isStaging = isStaging;
        this.region = region;
    }

    /**
     * @method
     * @description
     * Exports this object in a format that the api understands.
     *
     * @returns An object readable by the api.
     */
    public exportToApi(): Object {
        return {
            versionId: this.versionId,
            isStaging: this.isStaging,
            region: this.region
        };
    }

    /**
     * @method
     * @description
     * Clones the current object.
     *
     * @returns A clone of this object.
     */
    public clone(): PublishSettings {
        return new PublishSettings(
            this.versionId.slice(),
            this.isStaging,
            this.region ? this.region.slice() : this.region
        );
    }
}

/**
 * @description
 * Represents publish settings that are to be persisted
 * accross multiple sessions.
 */
export class PersistantPublishSettings implements ICloneable, IApiExportable {
    public bing: boolean;
    public sentiment: boolean;
    public speech: boolean;
    public timezone: string;
    public verbose: boolean;

    constructor(
        bing: boolean = false, sentiment: boolean = false,
        speech: boolean = false, timezone: string = '0.0',
        verbose: boolean = true
    ) {
        this.bing = bing;
        this.sentiment = sentiment;
        this.speech = speech;
        this.timezone = timezone;
        this.verbose = verbose;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): PersistantPublishSettings {
        return new PersistantPublishSettings(
            apiObject.spellChecker,
            apiObject.sentimentAnalysis,
            apiObject.speech
        );
    }

    /**
     * @method
     * @description
     * Exports this object in a format that the api understands.
     *
     * @returns An object readable by the api.
     */
    public exportToApi(): Object {
        return {
            sentimentAnalysis: this.sentiment,
            speech: this.speech,
            spellChecker: this.bing,
            timezone: this.timezone,
            verbose: this.verbose
        };
    }

    /**
     * @method
     * @description
     * Clones the current object.
     *
     * @returns A clone of this object.
     */
    public clone(): PersistantPublishSettings {
        return new PersistantPublishSettings(
            this.bing,
            this.sentiment,
            this.speech,
            this.timezone.slice(),
            this.verbose
        );
    }
}
