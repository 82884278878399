import { Entity, ENTITY_TYPES } from '../models/entity.model';
import { ParentEntity } from '../models/parent-entity.model';

/**
 * @description
 * Represents an enumeration of the current states an entity
 * dropdown can be displaying.
 */
export enum MENU_STATES {
	NEW,
	ENTITY,
	COMPOSITE,
	WRAP
}

export function getMenuState(entity: Entity, parent: ParentEntity, wrapMode: boolean): MENU_STATES {
	if (wrapMode) {
		return MENU_STATES.WRAP;
	}

	if (!entity && !parent) {
		return MENU_STATES.NEW;
	}

	if (entity && entity.type === ENTITY_TYPES.COMPOSITE) {
		return MENU_STATES.COMPOSITE;
	}

	if (parent && parent.type === ENTITY_TYPES.COMPOSITE) {
		return MENU_STATES.COMPOSITE;
	}

	return MENU_STATES.ENTITY;
}
