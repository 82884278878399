import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@luis/core';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { EndpointSettingsComponent } from './components/endpoint-settings/endpoint-settings.component';
import { ExternalSettingsComponent } from './components/external-settings/external-settings.component';
import { KeySelectorComponent, NEW_KEY_MODAL_COMP_TOKEN } from './components/keys-table/key-selector/key-selector.component';
import { KeysTableComponent } from './components/keys-table/keys-table.component';
import { PUBLISH_MODAL_COMP_TOKEN, PublishModalComponent } from './components/publish-modal/publish-modal.component';
import { ARM_TOKEN_SERVICE_TOKEN } from './interfaces/IArmTokenService';
import { KEY_HELPERS_SERVICE_TOKEN } from './interfaces/IKeyHelpersService';
import { KEY_SERVICE_TOKEN } from './interfaces/IKeyService';
import { KEY_STORE_SERVICE_TOKEN } from './interfaces/IKeyStoreService';
import { PUBLISH_SERVICE_TOKEN } from './interfaces/IPublishService';
import { EndpointUrlGeneratorPipe } from './pipes/endpoint-url-generator-async.pipe';
import { KeyRegionCheckPipe } from './pipes/key-region-check.pipe';
import { ArmTokenService } from './services/arm-token.service';
import { KeyHelpersService } from './services/key-helpers.service';
import { KeyStoreService } from './services/key-store.service';
import { KeyService } from './services/key.service';
import { PublishService } from './services/publish.service';

@NgModule({
    imports: [
        CoreModule,
        IntercomUIModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        KeysTableComponent,
        ExternalSettingsComponent,
        EndpointSettingsComponent,
        EndpointUrlGeneratorPipe,
        KeyRegionCheckPipe
    ],
    declarations: [
        KeysTableComponent,
        ExternalSettingsComponent,
        EndpointSettingsComponent,
        KeySelectorComponent,
        EndpointUrlGeneratorPipe,
        KeyRegionCheckPipe,
        PublishModalComponent
    ],
    entryComponents: [
        KeySelectorComponent,
        PublishModalComponent
    ]
})
export class PublishPaneModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: PublishPaneModule,
            providers: [
                {
                    provide: ARM_TOKEN_SERVICE_TOKEN,
                    useClass: ArmTokenService
                },
                {
                    provide: KEY_STORE_SERVICE_TOKEN,
                    useClass: KeyStoreService
                },
                {
                    provide: KEY_HELPERS_SERVICE_TOKEN,
                    useClass: KeyHelpersService
                }
            ]
        };
    }

    public static providersForChild(): Provider[] {
        return [
            {
                provide: PUBLISH_SERVICE_TOKEN,
                useClass: PublishService
            },
            {
                provide: KEY_SERVICE_TOKEN,
                useClass: KeyService
            },
            {
                provide: NEW_KEY_MODAL_COMP_TOKEN, useValue: {
                    componentType: KeySelectorComponent,
                    defaultParams: {}
                }
            },
            {
                provide: PUBLISH_MODAL_COMP_TOKEN, useValue: {
                    componentType: PublishModalComponent,
                    defaultParams: {}
                }
            }
        ];
    }
}
