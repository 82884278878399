import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';

@Injectable()
export class LoaderPageService {
    private _isLoaderVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * @method
     * @description
     * Sets the visibility of the loader stream with the
     * value given.
     *
     * @param val True or false based on whether
     * to open or close the loader.
     */
    public setVisibility(val: boolean): void {
        this._isLoaderVisible.next(val);
    }

    /**
     * @method
     * @description
     * Returns an observable to the loader visiblity
     * stream
     *
     * @returns An observable of the
     * current stream visibility.
     */
    public observe(): Observable<boolean> {
        return this._isLoaderVisible.asObservable();
    }
}
