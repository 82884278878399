import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Inject,
    Injector,
    Input,
    OnInit,
} from '@angular/core';
import {
    GATEKEEPER_FEATURE,
    GenericPromptService,
    IModalComponentToken,
    IToasterService,
    IUrlSettings,
    LuisConstants,
    ModalHostService,
    ProgressTracker,
    PromptButtonTypes,
    SelectionMap,
    TOASTER_SERVICE_TOKEN,
} from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IKeyHelpersService, KEY_HELPERS_SERVICE_TOKEN } from '../../interfaces/IKeyHelpersService';
import { IKeyService, KEY_SERVICE_TOKEN } from '../../interfaces/IKeyService';
import { ITimeZone, timeZones } from '../../interfaces/ITimeZone';
import { EndpointKey } from '../../models/azure-key.model';
import { CONTINENT } from '../../models/continent.model';
import { NEW_KEY_MODAL_COMP_TOKEN } from './key-selector/key-selector.component';
import { TranslateService } from '@ngx-translate/core';

/**
 * @description
 * Contains logic and view layers for all key related actions
 * in the publish dashboard.
 */
@Component({
    selector: 'keys-table',
    templateUrl: 'keys-table.component.html',
    styleUrls: ['keys-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeysTableComponent implements OnInit {
    @Input() public endpointSettings: Observable<IUrlSettings>;

    public regions: Observable<Map<string, string>>;
    public continents: typeof CONTINENT = CONTINENT;
    public visibleContinent: BehaviorSubject<number> = new BehaviorSubject<number>(this.continents.ALL);
    public visibleKeys: Observable<EndpointKey[]>;
    public nonAccessibleKeysCount: Observable<number>;
    public query: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public isDeleteEnabled: Observable<boolean>;
    public timeZones: ITimeZone[] = timeZones;
    public defaultTimeZone: ITimeZone;
    public programmaticKeyId: string = LuisConstants.PROGRAMMATIC_KEY_ID;
    public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
        property: 'modifiedDate',
        type: SORT_TYPE.DESCENDING,
    });
    public currentPage: number;
    public assignKeyTracker: ProgressTracker = new ProgressTracker();
    public keysTracker: ProgressTracker = new ProgressTracker();
    public selectionMap: SelectionMap = new SelectionMap();
    public gateKeeperFeature: typeof GATEKEEPER_FEATURE = GATEKEEPER_FEATURE;

    private _keys: Observable<EndpointKey[]>;
    private _continentsDirectory: Map<CONTINENT, string[]>;

    constructor(
        private _i18n: TranslateService,
        private _modalService: ModalHostService,
        private _promptService: GenericPromptService,
        private _cdRef: ChangeDetectorRef,
        private _injector: Injector,
        private _resolver: ComponentFactoryResolver,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(KEY_SERVICE_TOKEN) private _keyService: IKeyService,
        @Inject(KEY_HELPERS_SERVICE_TOKEN) private _keyHelpersService: IKeyHelpersService,
        @Inject(NEW_KEY_MODAL_COMP_TOKEN) private _keyModalComponent: IModalComponentToken<EndpointKey>,
    ) {}

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Opens the key modal.
     */
    public openKeyModal(event: Event): void {
        event.preventDefault();

        this._modalService
            .create(this._keyModalComponent, {}, { resolver: this._resolver, injector: this._injector })
            .flatMap(key => this._keyService.assignKeyToApp(key).trackProgress(this.assignKeyTracker.getTracker()))
            .subscribe(null, error => error);
    }

    /**
     * @description
     * Unassigns the given endpoint key from the current app.
     *
     * @param keys The keys to unassign from the app.
     */
    public unAssignKeys(): void {
        const selectedIds: string[] = <string[]>this.selectionMap.selectedItems;

        this._keys
            .first()
            .map(keys => keys.filter(k => selectedIds.indexOf(k.id) !== -1))
            .flatMap(keys =>
                this._promptService
                    .prompt(
                        this._i18n.instant('publish-pane.keys-table.unassign_key_q'),
                        this._i18n.instant('publish-pane.keys-table.this_action_will_unassign_key'),
                        {
                            ok: this._i18n.instant('publish-pane.keys-table.ok'),
                            cancel: this._i18n.instant('publish-pane.keys-table.cancel'),
                        },
                        { ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default },
                    )
                    .map(choice => ({ keys: keys, choice: choice })),
            )
            .filter(payload => payload.choice === 'ok')
            .flatMap(payload =>
                this._keyService
                    .unassignKeysFromApp(payload.keys)
                    .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('publish-pane.keys-table.unassign_keys_toast') })),
            )
            .subscribe(() => this.selectionMap.markUnselected(selectedIds));
    }

    /**
     * @description
     * Updates the timezone of the selected keys.
     *
     * @param timeZone The timzone to change.
     */
    public updateTimeZone(timezone: ITimeZone): void {
        const selectedIds = this.selectionMap.selectedItems;

        this._keys
            .first()
            .map(keys => keys.filter(k => selectedIds.indexOf(k.id) !== -1))
            .do(keys => keys.forEach(k => (k.timeZone = timezone)))
            .subscribe(() => this._cdRef.markForCheck());
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this._continentsDirectory = this._keyHelpersService.getContinentRegions();

        this.defaultTimeZone = timeZones.find(t => t.value === this._keyHelpersService.getDomainInfo().timeZone);

        this._keys = this._keyService
            .getAppKeys()
            .trackProgress(this.keysTracker.getTracker())
            .do(keys => keys.forEach(k => (k.timeZone = this.defaultTimeZone)))
            .do(() => this._cdRef.detectChanges());

        this.visibleKeys = Observable.combineLatest(this._keys, this.visibleContinent).map(([keys, c]) =>
            keys.filter(k => this._continentsDirectory.get(c).indexOf(k.location) !== -1),
        );

        this.nonAccessibleKeysCount = this._keyService.getKeysWithNoAccess().map(keys => keys.length);

        this.isDeleteEnabled = Observable.combineLatest(this._keys, this.selectionMap.selectedItemsAsync)
            .map(([keys, selectedIds]) => keys.filter(k => selectedIds.indexOf(k.id) !== -1))
            .map(keys => keys.length > 0 && !keys.find(k => k.id === LuisConstants.PROGRAMMATIC_KEY_ID));
    }
}
