import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
	CompositeSegment,
	ENTITY_DROPDOWN_OPERATIONS,
	EntityWrapService,
	IUtteranceEntityChangeEvent,
	SEGMENT_TYPES,
	UtteranceEntity
} from '@luis/entities';
import { BehaviorSubject } from 'rxjs/Rx';
import { ILabelableOperationPayload } from '../labelable-utterance.component';

@Component({
	selector: 'composite-segment',
	templateUrl: 'composite-segment.component.html',
	styleUrls: ['composite-segment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompositeSegmentComponent {
	@Input() public segment: CompositeSegment;
	@Output() public entityClicked: EventEmitter<UtteranceEntity> = new EventEmitter<UtteranceEntity>();
	@Output() public onOperation: EventEmitter<ILabelableOperationPayload> = new EventEmitter<ILabelableOperationPayload>();

	public segmentTypes: any = SEGMENT_TYPES;
	public hoverSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private _entityWrapService: EntityWrapService) {}

	public trackByFunc(index: number): number {
		return index;
	}

	/**
	 * @description
	 * If an existing composite entity segment is clicked,
	 * composite wrapping should be disabled, since overlapping
	 * composite entity wrapping is not allowed in the UI.
	 */
	public onClick(): void {
		this._entityWrapService.setWrapMode(false);
	}

	/**
	 * @description
	 * Handles the operations emitted from the entity underlying segment.
	 *
	 * @param payload The operation event payload.
	 */
	public handleOperation(payload: ILabelableOperationPayload): void {
		const { event, operation } = payload;
		switch (operation) {
			case ENTITY_DROPDOWN_OPERATIONS.LABEL_COMPOSITE_WITH_ROLE: {
				const changeEvent = this._handleDropdownCompositeEntityRoleLabel(event);
				this.onOperation.emit({ event: changeEvent, operation });
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.DELETE_ENTITY: {
				const changeEvent = this._handleDropdownDeleteEntityClick(event);
				this.onOperation.emit({ event: changeEvent, operation });

				break;
			}
			default:
				this.onOperation.emit({ event, operation });
		}
	}

	/**
	 * @description
	 * Notifies the parent with the utterance entity that should be deleted. If the data
	 * received has the same entity name, then what is to be deleted is the composite
	 * parent utterance entity, else the entity utterance entity that was sent is to be
	 * deleted itself.
	 *
	 * @param entity The utterance entity to delete. Could be a composite
	 * child or composite parent.
	 */
	private _handleDropdownDeleteEntityClick(entityChangeEvent: IUtteranceEntityChangeEvent): IUtteranceEntityChangeEvent {
		let utteranceEntity = entityChangeEvent.oldUtteranceEntity;
		if (utteranceEntity && utteranceEntity.id === this.segment.composite.id) {
			utteranceEntity = new UtteranceEntity(
				this.segment.composite.id,
				this.segment.composite.name,
				this.segment.composite.type,
				'',
				this.segment.start,
				this.segment.end
			);
		}

		return {
			...entityChangeEvent,
			oldUtteranceEntity: utteranceEntity
		};
	}

	/**
	 * @description
	 * Notifies parent that the selected composite utterance entity is to be labeled with the specified role.
	 *
	 * @param entityEvent The entity to create the utterance entity for.
	 */
	private _handleDropdownCompositeEntityRoleLabel(event: IUtteranceEntityChangeEvent): IUtteranceEntityChangeEvent {
		return {
			...event,
			newUtteranceEntity: new UtteranceEntity(
				this.segment.composite.id,
				this.segment.composite.name,
				this.segment.composite.type,
				(event.entityEvent && event.entityEvent.eventData.role) || '',
				this.segment.start,
				this.segment.end
			)
		};
	}
}
