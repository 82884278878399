import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Rx';

@Pipe({
    name: 'wordMatchAsync'
})
export class WordMatchAsyncPipe implements PipeTransform {
    public transform(dataName: string, query: Observable<string>): Observable<string> {
        return query.map(q => {
            const boldSegment: string = dataName.slice(0, q.length);
            const nonBoldSegment: string = dataName.slice(q.length);

            return `<strong>${boldSegment}</strong><span>${nonBoldSegment}</span>`;
        });
    }
}
