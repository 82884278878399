import { IResource } from '@luis/core';

/**
 * @description
 * An enumeration of the states that a subscription can be in.
 */
export enum SUBSCRIPTION_STATE {
    ENABLED,
    WARNED,
    PAST_DUE,
    DISABLED,
    DELETED
}

/**
 * @description
 * Represents an Azure subscription.
 *
 * @param id The id of the subscription.
 * @param name The name of the subscription.
 * @param state The state of the subscription
 */
export class Subscription implements IResource {
    public id: string;
    public name: string;
    public state: number;

    constructor(id: string = '', name: string = '', state: number = SUBSCRIPTION_STATE.ENABLED) {
        this.id = id;
        this.name = name;
        this.state = state;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): Subscription {
        return new Subscription(
            apiObject.subscriptionId || '',
            apiObject.displayName || '',
            apiObject.state
        );
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): Subscription {
        return new Subscription(
            this.id.slice(),
            this.name.slice(),
            this.state
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {};
    }
}
