import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, Inject, Injector, OnInit } from '@angular/core';
import {
	GenericPromptService,
	IInjectorContext,
	IModalComponentToken,
	IToasterService,
	ModalHostService,
	PromptButtonTypes,
	TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { ENTITY_SERVICE_TOKEN, EntityService } from '@luis/entities';
import { BLADE_OPS, BladeComponent, BLADES, BladeTrackerService } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { BATCH_TESTING_SERVICE_TOKEN, IBatchTestingService } from '../../interfaces/IBatchTestingService';
import { IDatasetDto } from '../../interfaces/IDatasetDto';
import { DATASET_OPERATIONS, IDatasetOperation } from '../../interfaces/IDatasetOperation';
import { DATASET_RUN_STATE, DatasetMetadata } from '../../models/dataset-metadata.model';
import { DatasetScorer } from '../../models/dataset-scorer.model';
import { IMPORT_DATASET_MODAL_COMP_TOKEN } from './dataset-import-modal/dataset-import-modal.component';
import { RENAME_DATASET_MODAL_COMP_TOKEN } from './dataset-rename-modal/dataset-rename-modal.component';

/**
 * @description
 * Represents the batch testing blade in the testing console.
 */
@Component({
	selector: 'batch-testing-blade',
	templateUrl: './batch-testing-blade.component.html',
	styleUrls: ['./batch-testing-blade.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchTestingBladeComponent extends BladeComponent implements OnInit {
	public datasetDtos: Observable<IDatasetDto[]>;
	public selectedDto: BehaviorSubject<IDatasetDto> = new BehaviorSubject<IDatasetDto>(null);
	public datasetOps: typeof DATASET_OPERATIONS = DATASET_OPERATIONS;

	protected currentBlade: BLADES = BLADES.BATCH_TESTING;

	private readonly _datasetDtosStream: BehaviorSubject<IDatasetDto[]> = new BehaviorSubject<IDatasetDto[]>(null);
	private _injectorContext: IInjectorContext;

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _modalService: ModalHostService,
		private readonly _promptService: GenericPromptService,
		private readonly _bladeTrackerService: BladeTrackerService,
		private readonly _injector: Injector,
		private readonly _resolver: ComponentFactoryResolver,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: EntityService,
		@Inject(BATCH_TESTING_SERVICE_TOKEN) private readonly _batchTestingService: IBatchTestingService,
		@Inject(IMPORT_DATASET_MODAL_COMP_TOKEN) private readonly _datasetImportModalComp: IModalComponentToken<DatasetMetadata>,
		@Inject(RENAME_DATASET_MODAL_COMP_TOKEN) private readonly _datasetRenameModalComp: IModalComponentToken<DatasetMetadata>
	) {
		super(_bladeTrackerService);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this._initState();
	}

	/**
	 * @description
	 * Opens the single testing panel and closes the batch testing panel.
	 */
	public openSingleTestingConsole(): void {
		this._bladeTrackerService.applyOp(BLADE_OPS.CHAT_OPEN);
		this.closeBlade();
	}

	/**
	 * @description
	 * Applies the dataset operation based on the op type.
	 *
	 * @param datasetOp The dataset operation to perform.
	 */
	public applyDatasetOp(datasetOp: IDatasetOperation): void {
		switch (datasetOp.operation) {
			case DATASET_OPERATIONS.IMPORT:
				this._import();
				break;
			case DATASET_OPERATIONS.RUN:
				this._run(datasetOp.dataset);
				break;
			case DATASET_OPERATIONS.RENAME:
				this._rename(datasetOp.dataset);
				break;
			case DATASET_OPERATIONS.EXPORT:
				this._export(datasetOp.dataset);
				break;
			case DATASET_OPERATIONS.DELETE:
				this._delete(datasetOp.dataset);
				break;
			case DATASET_OPERATIONS.OPEN:
				this._open(datasetOp.dataset);
				break;
			default:
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._batchTestingService
			.get()
			.map(datasets => this._mergeDatasetsIntoDtos(datasets, this._datasetDtosStream.getValue()))
			.subscribe(dtos => this._datasetDtosStream.next(dtos));

		this.datasetDtos = this._datasetDtosStream.asObservable().filter(dtos => dtos !== null);

		this._injectorContext = { injector: this._injector, resolver: this._resolver };
	}

	/**
	 * @description
	 * Merges the new datasets into the stream. Datasets with exisiting
	 * metadata have their data transferred to the new array in the stream.
	 *
	 * @param datasets The new datasets to merge.
	 * @param dtos The existing dtos array to merge onto.
	 * @returns A new array with the new datasets merged using the existing
	 * dtos array information.
	 */
	private _mergeDatasetsIntoDtos(datasets: DatasetMetadata[], dtos: IDatasetDto[]): IDatasetDto[] {
		return datasets.map(dataset => {
			const existingDto = dtos ? dtos.find(dto => dto.dataset.id === dataset.id) : null;

			return {
				dataset: dataset,
				state: existingDto ? existingDto.state : DATASET_RUN_STATE.NOT_RUN,
				scorer: existingDto ? existingDto.scorer : null
			};
		});
	}

	/**
	 * @description
	 * Opens the import modal to add a new dataset.
	 */
	private _import(): void {
		this._modalService.create(this._datasetImportModalComp, {}, this._injectorContext).subscribe(null, error => error);
	}

	/**
	 * @description
	 * Executes the given dataset agains the current app version.
	 *
	 * @param dataset The dataset to execute.
	 */
	private _run(dataset: DatasetMetadata): void {
		const dtoToRun = this._datasetDtosStream.getValue().find(dto => dto.dataset.id === dataset.id);

		dtoToRun.state = DATASET_RUN_STATE.RUNNING;
		this._udpateDtoStream(dtoToRun);

		Observable.combineLatest(this._batchTestingService.run(dataset), this._entityService.get().first())
			.trackProgress(this._toasterService.add())
			.map(([results, entities]) => new DatasetScorer(results, entities))
			.flatMap(scorer => scorer.overallCorrectCount.first().map(count => ({ scorer, count })))
			.subscribe(
				({ count, scorer }) => {
					dtoToRun.scorer = scorer;
					dtoToRun.dataset.lastResult = count;
					dtoToRun.dataset.lastRun = new Date();
					dtoToRun.state = dtoToRun.scorer.resultItems.length === count ? DATASET_RUN_STATE.SUCCESS : DATASET_RUN_STATE.FAIL;

					this._update(dtoToRun.dataset);
					this._udpateDtoStream(dtoToRun);
				},
				error => {
					dtoToRun.state = DATASET_RUN_STATE.NOT_RUN;
					this._udpateDtoStream(dtoToRun);
				}
			);
	}

	/**
	 * @description
	 * Renames the given dataset.
	 *
	 * @param dataset The dataset to rename.
	 */
	private _rename(dataset: DatasetMetadata): void {
		this._modalService
			.create(this._datasetRenameModalComp, { datasetToRename: dataset }, this._injectorContext)
			.subscribe(null, error => error);
	}

	/**
	 * @description
	 * Updates the given dataset.
	 *
	 * @param dataset The dataset to update.
	 */
	private _update(dataset: DatasetMetadata): void {
		this._batchTestingService.update(dataset).subscribe();
	}

	/**
	 * @description
	 * Exports the given dataset.
	 *
	 * @param dataset The dataset to export.
	 */
	private _export(dataset: DatasetMetadata): void {
		this._batchTestingService.export(dataset).subscribe();
	}

	/**
	 * @description
	 * Deletes the given dataset.
	 *
	 * @param dataset The dataset to delete.
	 */
	private _delete(dataset: DatasetMetadata): void {
		this._promptService
			.prompt(
				this._i18n.instant('test-pane.batch-testing.delete_dataset'),
				this._i18n.instant('test-pane.batch-testing.are_you_want_to_delete_dataset', { name: dataset.name }),
				{ ok: this._i18n.instant('test-pane.batch-testing.ok'), cancel: this._i18n.instant('test-pane.batch-testing.cancel') },
				{ ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
			)
			.filter(choice => choice === 'ok')
			.flatMap(() => this._batchTestingService.delete(dataset))
			.subscribe();
	}

	/**
	 * @description
	 * Opens the results of the given dataset.
	 *
	 * @param dataset The dataset to view the results of.
	 * @param results The results of the dataset that ran.
	 */
	private _open(dataset: DatasetMetadata): void {
		this._datasetDtosStream
			.first()
			.map(dtos => dtos.find(dto => dto.dataset.id === dataset.id))
			.subscribe(dto => this.selectedDto.next(dto));
	}

	/**
	 * @description
	 * Updates the states of the Dtos.
	 *
	 * @param dto The dto to update on the stream.
	 */
	private _udpateDtoStream(dto: IDatasetDto): void {
		const datasetDtos = this._datasetDtosStream.getValue();
		const foundIndex = datasetDtos.findIndex(dtos => dtos.dataset.id === dto.dataset.id);

		if (foundIndex !== -1) {
			datasetDtos[foundIndex] = dto;
		} else {
			datasetDtos.push(dto);
		}

		this._datasetDtosStream.next(datasetDtos);
	}
}
