import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module';
import { PageNotFoundComponent } from './components/404/404.component';
import { FooterComponent } from './components/home/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { IntroSlidesModalComponent } from './components/intro-slides-modal/intro-slides-modal.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { OverviewComponent } from './components/overview/overview.component';
import { SurveyComponent } from './components/survey/survey.component';
import { StaticRoutingModule } from './static-routing.module';

@NgModule({
	imports: [SharedModule, StaticRoutingModule, MatDialogModule],
	declarations: [
		HomeComponent,
		FooterComponent,
		SurveyComponent,
		OverviewComponent,
		MaintenanceComponent,
		PageNotFoundComponent,
		IntroSlidesModalComponent
	],
	entryComponents: [IntroSlidesModalComponent, SurveyComponent],
	providers: []
})
export class StaticModule {}
