import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    ViewContainerRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { IInputHandlerPayload } from '../../../interfaces/IInputHandlerPayload';
import { IInputHandlerService } from '../../../interfaces/IInputHandlerService';
import { BracesHandlerComponent } from './braces-handler.component';

@Injectable()
export class BracesHandlerService implements IInputHandlerService {
    constructor(
        private _componentFactory: ComponentFactoryResolver
    ) { }

    /**
     * @method
     * @description
     * Executes the handler by passing the streams to the dynamically
     * created component corresponding to this handler.
     *
     * @param inputField The pattern input field itself.
     * @param responseStream The stream where new text can be placed.
     * @param patternEntitiesStream A stream of the generated
     * pattern entities.
     * @param componentBed The component container where dynamic components
     * can be created and added.
     */
    public execute(
        inputField: HTMLInputElement, responseStream: BehaviorSubject<IInputHandlerPayload>,
        componentBed: ViewContainerRef
    ): void {
        componentBed.clear();

        const componentFactory: ComponentFactory<BracesHandlerComponent> =
            this._componentFactory.resolveComponentFactory(BracesHandlerComponent);

        const componentRef: ComponentRef<BracesHandlerComponent> = componentBed.createComponent(componentFactory);

        componentRef.instance.responseStream = responseStream;
        componentRef.instance.inputField = inputField;
    }
}
