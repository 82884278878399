﻿import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { AdminService } from '../../common/services/admin.service';
import { AppRouteService } from '../../common/services/app-route.service';

@Injectable()
export class MaintenanceGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _adminService: AdminService,
        private _routeService: AppRouteService
    ) { }

    /**
     * @description
     * Checks if you can navigate to the website or maintenance page.
     */
    public canActivate(): Observable<boolean> {
        return this._adminService.getSettings()
            .map(settings => settings.maintenanceMode)
            .map(mode => {
                if (mode) {
                    this._router.navigate([this._routeService.builder().staticPages().maintenance().build()]);

                    return false;
                }

                return true;
            });
    }
}
