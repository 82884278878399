import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IWebSocketRequest } from '../interfaces/IWebSocketRequest';
import { IWebSocketResponse } from '../interfaces/IWebSocketResponse';

/**
 * @description
 * The supported calls by the websocket.
 */
export enum SUPPORTED_SOCKET_CALLS {
	REDIS_SUBSCRIBE
}

/**
 * @description
 * Represents a service that invokes websocket calls.
 */
@Injectable()
export class WebsocketService {
	private readonly _connString: string = '/webSocketHub';
	private readonly _serverMethodName: string = 'forwardApiCall';
	private readonly _clientMethodName: string = 'pushApiResponse';

	private readonly _isReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _responseBus: BehaviorSubject<IWebSocketResponse> = new BehaviorSubject(undefined);

	private _connection: HubConnection;

	constructor() {
		this.initService();
	}

	/**
	 * @description
	 * Gets an instance of the socket response bus.
	 *
	 * @returns The observable of the subject that receives the data.
	 */
	public get messageBus(): Observable<IWebSocketResponse> {
		return this._responseBus.asObservable();
	}

	/**
	 * @description
	 * Invokes a server call to the web socket hub.
	 *
	 * @param id A unique caller id to identify the message.
	 * @param type The call type based on the available apis the web socket service supports.
	 * @param key The subscription key of the user that the web socket service will use
	 * to authenticate the user on behalf of him/her.
	 * @param params The params that will be used to construct the url.
	 */
	public invokeCall(id: number, type: SUPPORTED_SOCKET_CALLS, key: string, data: IWebSocketRequest): void {
		this._isReady
			.asObservable()
			.first()
			.filter(status => status)
			.subscribe(async () => this._connection.invoke(this._serverMethodName, id, type, key, data));
	}

	/**
	 * @description
	 * Helper function that casts the response into a concrete business logic interface (object)
	 * that the rest of the application can use. It also adds that response to the server
	 * responses queue (subject).
	 *
	 * @param id The caller id that had made the call to the server that the server is now replying to.
	 * @param response The json response that returned from that call.
	 */
	private handleCallResponse(id: number, response: string): void {
		const serverResponse: IWebSocketResponse = { id: id, body: response };
		this._responseBus.next(serverResponse);
	}

	/**
	 * @description
	 * Initializes the service. Starts up connection with the hub
	 * and registers the callback function to the server responses.
	 */
	private initService(): void {
		this._connection = new HubConnectionBuilder()
			.withUrl(this._connString)
			.configureLogging(LogLevel.None)
			.build();

		this._connection
			.start()
			.then(() => this._isReady.next(true))
			.catch(err => err);

		this._connection.on(this._clientMethodName, this.handleCallResponse.bind(this));
	}
}
