import { ICloneable } from '@luis/core';

/**
 * @description
 * Represents an application culture class. An app culture is a culture that can be assigned
 * to a LUIS app.
 *
 * @param code The culture locale code.
 * @param name The culture name.
 */
export class AppCulture implements ICloneable {
    public code: string;
    public name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): AppCulture {
        return new AppCulture(apiObject.code, apiObject.name);
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): AppCulture {
        return new AppCulture(
            this.code.slice(),
            this.name.slice()
        );
    }
}
