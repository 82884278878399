import { BaseItemFilter , IDataItem} from '@luis/core';
import { Intent } from '@luis/intents';
import { Pattern } from '../pattern.model';

/**
 * @description
 * Represents an intent item filter that filters data items
 * based on whether the intent chosen exists in the item
 * or not. Used by the item filter pipe.
 */
export class IntentItemFilter extends BaseItemFilter {
    private _intent: Intent = null;

    /**
     * @method
     * @description
     * Toggles the activation of the filter by either adding a
     * new entity to the entity map or removing if it was already existing.
     *
     * @param intent The intent to toggle.
     */
    public toggleActivation(intent: Intent): void {
        this._intent = intent;
    }

    /**
     * @method
     * @description
     * Checks if the filter is active by checking the intent object.
     * If intent is null, then the filter is not active.
     */
    public isActive(): boolean {
        return this._intent !== null;
    }

    /**
     * @method
     * @description
     * Filters the given item by whether it contains the
     * given intent or not.
     *
     * @param item The item to filter.
     * @returns True if the item should be displayed
     * and false if it should not be displayed.
     */
    public filter(item: IDataItem): boolean {
      if (item instanceof Pattern) {
            return item.intentName === this._intent.name;
        }
        else {
            return false;
        }
    }
}
