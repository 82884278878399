import { InjectionToken } from '@angular/core';
import { Id, ILinkable, IResource } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { Entity } from '../models/entity.model';

export const ENTITY_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IEntityService');

/**
 * @description
 * Represents an interface for an entity service. An entity service should contain
 * CRUD methods for LUIS entities.
 */
export interface IEntityService {
    get(refresh?: boolean): Observable<Entity[]>;

    add(entity: Entity | (ILinkable & IResource), refresh?: boolean): Observable<Id | void>;

    batchAdd(entities: Entity[], entitiesType: number): Observable<Entity[]>;

    update(entity: Entity | (ILinkable & IResource), refresh?: boolean, clean?: boolean): Observable<void>;

    delete(entity: Entity | (ILinkable & IResource), includeId?: boolean, bodyData?: Object): Observable<void>;
}
