import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseItemFilter, IDataItem, LuisConstants } from '@luis/core';
import { Entity, ENTITY_SERVICE_TOKEN, EntityHelpers, IEntityService } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { DropdownComponent } from '@luis/ui';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { IItemTableToolbarOps } from '../../interfaces/IItemTableToolbarOps';
import { EntityItemFilter } from '../../models/filters/entity-item-filter.model';
import { ErrorItemFilter } from '../../models/filters/error-item-filter.model';
import { IntentItemFilter } from '../../models/filters/intent-item-filter.model';

@Component({
	selector: 'toolbar',
	templateUrl: 'toolbar.component.html',
	styleUrls: ['toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy {
	@Input() public items: Observable<IDataItem[]>;
	@Input() public toolbarOps: Observable<IItemTableToolbarOps>;
	@Output() public reassignClicked: EventEmitter<Intent> = new EventEmitter<Intent>();
	@Output() public deleteClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() public filtersChanged: EventEmitter<BaseItemFilter[]> = new EventEmitter<BaseItemFilter[]>();
	@Output() public queryChanged: EventEmitter<string> = new EventEmitter<string>();

	public intents: Observable<Intent[]>;
	public entities: Observable<Entity[]>;
	public itemFilters: Map<LuisConstants.ITEM_TABLE_FILTERS, BaseItemFilter> = new Map<LuisConstants.ITEM_TABLE_FILTERS, BaseItemFilter>();
	public entitiesToChoose: Observable<Entity[]>;
	public filterEntities: Observable<Entity[]>;
	public toggleSearchBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _opsSubscription: Subscription = new Subscription();

	constructor(
		@Inject(INTENT_SERVICE_TOKEN) private _intentService: IIntentService,
		@Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._opsSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Notifies the parent that the reassign button was clicked.
	 */
	public reassignIntent(intent: Intent): void {
		this.reassignClicked.emit(intent);
	}

	/**
	 * @description
	 * Notifies the parent that the delete button was clicked.
	 */
	public delete(): void {
		this.deleteClicked.emit();
	}

	/**
	 * @description
	 * Toggles the entity item filter activation.
	 */
	public toggleEntity(entity: Entity, entitiesFilterField: DropdownComponent): void {
		this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.ENTITY).toggleActivation(entity);
		this.filtersChanged.emit(Array.from(this.itemFilters.values()));

		if (entitiesFilterField) {
			entitiesFilterField._clearSelection();
		}
	}

	/**
	 * @description
	 * Toggles the intent item filter activation.
	 */
	public toggleIntent(intent: Intent): void {
		this.itemFilters.get(LuisConstants.ITEM_TABLE_FILTERS.INTENT).toggleActivation(intent);
		this.filtersChanged.emit(Array.from(this.itemFilters.values()));
	}

	/**
	 * @description
	 * Toggles the search bar visiblity.
	 */
	public toggleSearch(): void {
		const currentState: boolean = this.toggleSearchBar.getValue();
		this.toggleSearchBar.next(!currentState);

		// It will be closed.
		if (currentState) {
			this.queryChanged.next('');
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.intents = this._intentService.get();

		this.entities = this._entityService.get();

		this._opsSubscription = this.toolbarOps.subscribe(ops => {
			this.queryChanged.emit(ops.search.initialValue);

			// Shows the search bar if the initial value is not empty.
			if (ops.search.initialValue !== '') {
				this.toggleSearch();
			}
		});

		this._registerItemFilters();

		this.entitiesToChoose = Observable.combineLatest(this.entities, this.filterEntities).map(([es, fEs]) =>
			EntityHelpers.expandChildren(es).filter(e => fEs.find(fE => fE.id === e.id) === undefined)
		);
	}

	/**
	 * @description
	 * Registers the supported utterance filters.
	 */
	private _registerItemFilters(): void {
		const errorFilter: ErrorItemFilter = new ErrorItemFilter();
		const entityFilter: EntityItemFilter = new EntityItemFilter();
		const intentFilter: IntentItemFilter = new IntentItemFilter();
		this.filterEntities = entityFilter.getEntities();

		this.itemFilters.set(LuisConstants.ITEM_TABLE_FILTERS.ERROR, errorFilter);
		this.itemFilters.set(LuisConstants.ITEM_TABLE_FILTERS.ENTITY, entityFilter);
		this.itemFilters.set(LuisConstants.ITEM_TABLE_FILTERS.INTENT, intentFilter);
	}
}
