import { CONFUSION_TYPE } from '../models/entity-confusion.model';

export interface IModelConfusionAggregate {
	tp: number;

	fp: number;

	tn: number;

	fn: number;

	utteranceScores: Map<number, { score: number; confusion: CONFUSION_TYPE }[]>;
}

export function emptyResultFactory(): IModelConfusionAggregate {
	return {
		tp: 0,
		fp: 0,
		tn: 0,
		fn: 0,
		utteranceScores: new Map<number, { score: number; confusion: CONFUSION_TYPE }[]>()
	};
}
