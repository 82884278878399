import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Observable';

const DEFAULT_OPTS = {
    caseSensitive: false
};

export class SearchAsyncPipe implements PipeTransform {
    constructor(private _ref: ChangeDetectorRef,
        private _searchFunction: (needle: string, haystack: string, caseSensitive: boolean) => boolean) {
    }

    public transform(searchSet: any, query: any, fields: string[], options?: any): any {
        options = { ...DEFAULT_OPTS, ...options };

        if (!searchSet) {
            return searchSet;
        }

        if (!query) {
            return searchSet;
        }

        if (fields.length < 1) {
            return searchSet;
        }

        return Observable.combineLatest(
            searchSet,
            query,
            (_searchSet: any, _query: any) => {
                this._ref.markForCheck();

                let ret = [];
                for (let obj of _searchSet) {
                    for (let field of fields) {
                        let isExactMatch = this.isExactMatch(_query, obj[field], options.caseSensitive);
                        if (isExactMatch || (obj[field] && this._searchFunction(_query, obj[field], options.caseSensitive))) {
                            ret.push(obj);
                            break;
                        }
                    }
                }

                return ret;
            }
        );
    }

    private isExactMatch(needle: string, haystack: string, caseSensitive: boolean): boolean {
        needle = needle || '';
        haystack = haystack || '';

        if (!caseSensitive) {
            needle = needle.toLocaleLowerCase();
            haystack = haystack.toLocaleLowerCase();
        }

        return needle === haystack;
    }
}
