import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DOWN_ARROW, ESCAPE, LEFT_ARROW, RIGHT_ARROW, TAB, UP_ARROW } from '@luis/ui';

@Component({
    selector: 'menu-search-item',
    templateUrl: 'menu-search-item.component.html',
    styleUrls: ['menu-search-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSearchItemComponent {
    @Input() placeholder: string;
    @Input() value: string;
    @Input() disabled: boolean = false;

    @Output() public changed: EventEmitter<string> = new EventEmitter();
    @Output() public enterClick: EventEmitter<KeyboardEvent> = new EventEmitter();

    @ViewChild('field') public menuItem: any;

    public focus(): void {
        this.menuItem._elementRef.nativeElement.focus();
    }

    /**
     * @description
     * Prevent bubbling up the event except for cases where it's necessary.
     */
    public onKeyDown(event: KeyboardEvent): void {
        if (
            event.keyCode === ESCAPE ||
            event.keyCode === DOWN_ARROW ||
            event.keyCode === UP_ARROW ||
            event.keyCode === LEFT_ARROW ||
            event.keyCode === RIGHT_ARROW ||
            event.keyCode === TAB
        ) {
            return;
        }
        event.stopPropagation();
    }
}
