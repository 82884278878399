import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material';
import { Ng5SliderModule } from 'ng5-slider';
import { SharedModule } from '../../shared/shared.module';
import { BasicFiltersBladeComponent } from './basic-filters-blade/basic-filters-blade.component';
import { EntitiesFilterBladeComponent } from './entities-filter-blade/entities-filter-blade.component';
import { FilterBladeComponent } from './filter-blade.component';
import { NearestRivalBladeComponent } from './nearest-rival-blade/nearest-rival-blade.component';

@NgModule({
	imports: [SharedModule, MatSliderModule, FormsModule, Ng5SliderModule],
	declarations: [FilterBladeComponent, BasicFiltersBladeComponent, EntitiesFilterBladeComponent, NearestRivalBladeComponent],
	exports: [FilterBladeComponent]
})
export class FilterBladeModule {}
