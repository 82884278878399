import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'overview-examples',
    templateUrl: 'overview-examples.component.html',
    styleUrls: ['overview-examples.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewExamplesComponent {
    public selectedTab: BehaviorSubject<number> = new BehaviorSubject<number>(1);
}
