/**
 * @description
 * An enumeration of the operations that you can apply on a phrase list.
 */
export enum PHRASE_LIST_OPERATIONS {
    ADD,
    ACTIVATE,
    DEACTIVATE,
    DELETE
}
