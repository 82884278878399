import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '@luis/core';
import { Locale, supportedLocales } from '../../../../app/models/locale.model';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'home-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
    public locales: Locale[] = supportedLocales;
    public currentYear: number;
    public env: ENVIRONMENT;

    public ngOnInit(): void {
        this._initComponent();
    }

    /**
     * @description
     * Changes the website locale.
     *
     * @param locale The locale to use.
     */
    public changeLocale(locale: Locale): void {
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initComponent(): void {
        this.currentYear = new Date().getFullYear();
        this.env = environment.env;
    }
}
