import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService, RequestOptionsBuilder } from '@luis/api';
import { BaseService, FileService, Id, IPage, PaginationResourceCacheService, ProgressTracker, ResourceChunkingService } from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { of } from 'rxjs';
import { filter, first, mapTo, switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs/Rx';
import { IAppVersionService } from '../interfaces/IAppVersionService';
import { AppVersion } from '../models/app-version.model';
import { App } from '../models/app.model';

/**
 * @description
 * Represents a concrete implementation of the IAppVersionService interface for app version related
 * operations for a LUIS account. This implementation is meant for production use.
 */
@Injectable()
export class AppVersionService implements IAppVersionService, OnDestroy {
	private static readonly sortProps: ISortPipeProps = {
		property: 'createdDate',
		type: SORT_TYPE.DESCENDING
	};

	private _chunkingSubscription: Subscription = new Subscription();

	constructor(
		private readonly _baseService: BaseService,
		private readonly _fileService: FileService,
		private readonly _paginationCacheService: PaginationResourceCacheService<AppVersion>,
		private readonly _chunkingService: ResourceChunkingService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {}

	public ngOnDestroy(): void {
		this._chunkingSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Intializes the service and hooks in the chunking service.
	 *
	 * @param trigger An observable that's responsible for starting the chunking service.
	 * @param chunkingTracker Progress tracker object that passed to the chunking service.
	 */
	public init(trigger: Observable<boolean>, chunkingTracker: ProgressTracker): void {
		this._chunkingSubscription = this._chunkingService
			.init(
				this._getModelName(),
				trigger.pipe(
					filter(val => val === true),
					mapTo(null)
				),
				AppVersion.importFromApi
			)
			.trackProgress(chunkingTracker.getTracker())
			.subscribe();
	}

	/**
	 * @description
	 * Gets the versions of the app. If the function gets called with refresh set to true, the
	 * chunking service gets initialized and starts fetching chunks from the backend, when the chunking
	 * process completes, the fetch query gets executed which itself gets a page of versions.
	 *
	 * @param pageIndex The index of the page to be fetched.
	 * @param app An app for which the versions should be fetched
	 * @param refresh A boolean flag indicating whether the data in the cache should refreshed or not and due
	 * to a legacy use of this parameter which entails fetching all the versions for this app from the backend, this
	 * function uses the chunking service to refresh all the data in the cache.
	 * @param getAll A flag indicating whether to get all the versions for this app from the cache or not.
	 *
	 * @returns An observable of the app's versions.
	 */
	public get(pageIndex: number, app: App = null, refresh: boolean = false, getAll: boolean = false): Observable<IPage<AppVersion>> {
		const url = this._getModelName(app);
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		const fetch$ = this._paginationCacheService.get(
			url,
			url,
			pageIndex,
			getAll,
			builder.build(),
			AppVersion.importFromApi,
			AppVersionService.sortProps
		);

		if (refresh) {
			return this._chunkingService.init(this._getModelName(), of(null), AppVersion.importFromApi).pipe(switchMap(() => fetch$));
		}

		return fetch$;
	}

	/**
	 * @description
	 * Gets a specific version.
	 *
	 * @returns An observable of the app's versions.
	 */
	public getVersion(versionId: string, app: App = null, refresh: boolean = false): Observable<AppVersion> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;
		const builderWithCacheOnly: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder.useCacheOnly();

		return this._paginationCacheService.getSingle(
			this._getModelName(app),
			versionId,
			builder.build(),
			builderWithCacheOnly.build(),
			AppVersion.importFromApi,
			refresh,
			AppVersionService.sortProps
		);
	}

	/**
	 * @description
	 * Performs a CACHE ONLY update to the version data.
	 *
	 * @param app An optional app for the version.
	 * @param version The app version to update.
	 * @returns An observable to indicate completion.
	 */
	public put(app: App = null, version: AppVersion): Observable<void> {
		const url = this._getModelName(app);
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		builder.useCacheOnly();

		return this._paginationCacheService.replace(url, url, version, version.id, builder.build()).pipe(mapTo(null));
	}

	/**
	 * @description
	 * Renames the app version to a new id.
	 *
	 * @param app The app to rename the version of.
	 * @param oldVersionId The old version id.
	 * @param version The application version to with the new renamed id.
	 */
	public rename(app: App = null, oldVersionId: string, version: AppVersion): Observable<void> {
		const url = this._getModelName(app);

		return this._paginationCacheService
			.replace(`${url}/${oldVersionId}/`, url, version, oldVersionId, this._baseService.defaultOptionsBuilder.build())
			.pipe(mapTo(null));
	}

	/**
	 * @description
	 * Imports the given version JSON string to the app.
	 *
	 * @param jsonString The json string of the version to import.
	 * @param newVersionName The new version name.
	 * @returns An observable of the new version id.
	 */
	public import(jsonString: string, versionName: string = '', app: App = null): Observable<AppVersion> {
		const urlParams: string = `subscription-key=${this._baseService.configs.userSubKey}`;
		const options = this._baseService.defaultOptionsBuilder.build();
		let url: string = `${this._baseService.configs.apiUrl}/${this._getModelName(app)}/import?${urlParams}`;

		if (versionName) {
			url = `${url}&versionId=${versionName}`;
		}

		return this._fileService
			.upload<string>(url, jsonString, options)
			.flatMap(versionId => this.getVersion(versionId, null, true).pipe(first()));
	}

	/**
	 * @description
	 * Gets the given version's export url.
	 *
	 * @param version The version to export.
	 * @param app An optional app that the version belongs to.
	 * @returns An observable to indicate completion.
	 */
	public export(version: AppVersion, app: App = null): Observable<void> {
		const appId = app ? app.id : this._baseService.configs.appId;
		const baseUrl: string = `${this._baseService.configs.apiUrl}/${this._getModelName(app)}/${version.id}/`;
		const options = this._baseService.defaultOptionsBuilder.build();

		return this._fileService.download(
			`${baseUrl}/export?subscription-key=${this._baseService.configs.userSubKey}`,
			`${appId}_v${version.id}.json`,
			options
		);
	}

	/**
	 * @description
	 * Export the version for a container.
	 *
	 * @param version The version to export.
	 * @param app An optional app that the version belongs to.
	 * @returns An observable to indicate completion.
	 */
	public exportForContainer(version: AppVersion, app: App = null): Observable<void> {
		const appId = app ? app.id : this._baseService.configs.appId;
		const url: string = `${this._baseService.configs.webApiUrl}/package/${appId}/versions/${version.id}/gzip`;
		const options = this._baseService.defaultOptionsBuilder.build();

		return this._fileService.download(url, `${appId}_v${version.id}.gz`, options, false);
	}

	/**
	 * @description
	 * Clones the given version to a new version.
	 *
	 * @param versionId The id of the version to clone
	 * @param newVersionName The new version name.
	 * @param app An optional app that the version belongs to.
	 * @returns An observable of the new version id.
	 */
	public clone(versionId: string, newVersionName: string, app: App = null): Observable<AppVersion> {
		const url: string = `${this._baseService.configs.apiUrl}/${this._getModelName(app)}/${versionId}/clone`;
		const payload: any = { version: newVersionName };

		return this._httpService
			.post<string>(url, payload, this._baseService.defaultOptionsBuilder.build())
			.flatMap(id => this.getVersion(<string>id, null, true).pipe(first()));
	}

	/**
	 * @description
	 * Deletes an existing version from the app.
	 *
	 * @param id The id of the version to delete.
	 * @param app An optional app that the version belongs to.
	 * @returns An observable to indicate completion.
	 */
	public delete(id: string, app: App = null): Observable<void> {
		const url = `${this._getModelName(app)}/${id}/`;

		return this._paginationCacheService.delete(url, url, id, this._baseService.defaultOptionsBuilder.build(), AppVersion.importFromApi);
	}

	/**
	 * @description
	 * Deletes list of versions from the app.
	 *
	 * @param ids The isd of the versions to delete.
	 * @param app An optional app that the version belongs to.
	 * @returns An observable to indicate completion.
	 */
	public batchDelete(versions: AppVersion[], app: App = null): Observable<Id[]> {
		return this._paginationCacheService.batchDelete(
			this._getModelName(app),
			this._getModelName(app),
			versions.map(version => version.id),
			this._baseService.defaultOptionsBuilder.build(),
			AppVersion.importFromApi
		);
	}

	private _getModelName(app: App = null): string {
		const appId: string = app !== null ? app.id : this._baseService.configs.appId;

		return `apps/${appId}/versions`;
	}
}
