import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
	SimpleChanges,
	OnChanges
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
	CompositeSegment,
	ENTITY_DROPDOWN_OPERATIONS,
	EntityWrapService,
	ICompositeWrapMenuEvent,
	IEntityDropdownOperationPayload,
	IEntityMenuEvent,
	IUtteranceEntityChangeEvent,
	Segment,
	UtteranceEntity
} from '@luis/entities';
import { ENTER, ESCAPE, LEFT_ARROW, RIGHT_ARROW, getBoundingClientRectToBody } from '@luis/ui';
import { distinctUntilChanged, filter, mapTo, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, ReplaySubject } from 'rxjs/Rx';
import { ISegmentComponent } from '../../../interfaces/utils/ISegmentComponent';
import { IHover, IOffset, ISelection } from '../../../models/plain-segment.model';
import { SegmentFocusService } from '../../../services/segment-focus.service';
import { ILabelableOperationPayload, LabelableUtteranceComponent } from '../labelable-utterance.component';

const EMPTY_POSITION: IOffset = { top: null, left: null };
const EMPTY_SELECTION: ISelection = { start: null, end: null };
const EMPTY_HOVER: IHover = { start: false, end: false };
const FULL_HOVER: IHover = { start: true, end: true };
const START_HOVER: IHover = { start: true, end: false };
const END_HOVER: IHover = { start: false, end: true };

/**
 * @description
 * Represents a plain text segment in a labelable utterance. A plain text segment contains
 * one or more plain text word tokens that can be selected to create new entities. It also
 * abstracts away the logic needed to manipulate the entity dropdown positioning.
 */
@Component({
	selector: 'plain-segment',
	templateUrl: 'plain-segment.component.html',
	styleUrls: ['plain-segment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlainSegmentComponent implements ISegmentComponent, OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input() public enableLabeling: boolean = true;
	@Input() public segment: Segment;
	@Input() public parentSegment: CompositeSegment;
	@Output() public onWrapEntityClicked: EventEmitter<ICompositeWrapMenuEvent> = new EventEmitter<ICompositeWrapMenuEvent>();
	@Output() public onCompositeHovered: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public onOperation: EventEmitter<ILabelableOperationPayload> = new EventEmitter<ILabelableOperationPayload>();

	@ViewChildren('token') public tokens: QueryList<ElementRef>;

	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
	@ViewChild('container') public containerRef: ElementRef;

	public selection: BehaviorSubject<ISelection> = new BehaviorSubject<ISelection>(EMPTY_SELECTION);
	public showDropdown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public dropdownPosition: BehaviorSubject<IOffset> = new BehaviorSubject<IOffset>(EMPTY_POSITION);
	public currentWrapMode: Observable<boolean>;
	public segmentPredictionsString: Observable<string>;
	public hoverStates: BehaviorSubject<IHover[]> = new BehaviorSubject<IHover[]>([]);
	public menuClosed: Observable<void>;
	public clickOutsideChecker: typeof LabelableUtteranceComponent.clickOutsideChecker = LabelableUtteranceComponent.clickOutsideChecker;
	public segmentSubject: ReplaySubject<Segment> = new ReplaySubject(1);

	private _showDropdownSubscription: Subscription = new Subscription();
	private _segmentSubscription: Subscription = new Subscription();

	constructor(
		private _elementRef: ElementRef,
		private _entityWrapService: EntityWrapService,
		private _segmentFocusService: SegmentFocusService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngAfterViewInit(): void {
		this._checkInitialFocus();
	}

	public ngOnDestroy(): void {
		this._showDropdownSubscription.unsubscribe();
		this._segmentSubscription.unsubscribe();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.segment && changes.segment.currentValue !== changes.segment.previousValue) {
			this.segmentSubject.next(changes.segment.currentValue);
		}
	}

	/**
	 * @description
	 * Calculates the distance from the segment to the bottom of the page.
	 */
	public calcDistanceToBottom = () => window.innerHeight - getBoundingClientRectToBody(this.containerRef.nativeElement).top;

	public handleOperation(payload: IEntityDropdownOperationPayload): void {
		const { event, operation } = payload;
		this.showDropdown.next(false);
		switch (operation) {
			case ENTITY_DROPDOWN_OPERATIONS.EXISTING_ENTITY: {
				const changeEvent = this._handleDropdownExistingEntityClick(event);
				this.onOperation.emit({ event: changeEvent, operation });
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.NEW_ENTITY: {
				const changeEvent = this._handleDropdownNewEntityClick(event);
				this.onOperation.emit({ event: changeEvent, operation });
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.DELETE_ENTITY: {
				const changeEvent = this._handleDropdownDeleteEntityClick(event);
				this.onOperation.emit({ event: changeEvent, operation });
				break;
			}
			case ENTITY_DROPDOWN_OPERATIONS.LABEL_COMPOSITE_WITH_ROLE: {
				this.onOperation.emit({
					event: {
						newUtteranceEntity: new UtteranceEntity(
							event.entity.id,
							event.entity.name,
							event.entity.type,
							(event.eventData && event.eventData.role) || '',
							this.segment.start,
							this.segment.end
						),
						entityEvent: event
					},
					operation
				});
				break;
			}
			default:
				this.onOperation.emit({ event: { entityEvent: event }, operation });
		}
	}

	/**
	 * @description
	 * Shows the hovering brackets according to the token index that was hovered on and
	 * the current active selection. Sets the hovering tokens of composite wrapping.
	 *
	 * @param index The token index of the token hovered.
	 */
	public onMouseEnter(index: number): void {
		const sel: ISelection = this.selection.getValue();
		const hoverStates = this.hoverStates.getValue();

		if (sel.start === EMPTY_SELECTION.start && sel.end === EMPTY_SELECTION.end) {
			hoverStates[index] = FULL_HOVER;
		} else if (sel.start <= index && index < sel.end) {
			hoverStates[index] = END_HOVER;
		} else if (index < sel.start) {
			hoverStates[index] = START_HOVER;
		} else if (index > sel.end) {
			hoverStates[index] = END_HOVER;
		}

		this.hoverStates.next(hoverStates);
		this._entityWrapService.setWrapHoverIndeces(this.segment.start + index, this.segment.start + index);
	}

	/**
	 * @description
	 * Hides all the hover brackets from a token when the mouse is no longer hovered
	 * over it. Unsets the hovering composite wrapping indeces.
	 *
	 * @param index The token index of the token no longer hovered.
	 */
	public onMouseLeave(index: number): void {
		const hoverStates = this.hoverStates.getValue();

		hoverStates[index] = EMPTY_HOVER;
		this.hoverStates.next(hoverStates);
		this._entityWrapService.setWrapHoverIndeces(null, null);
	}
	
	/**
	 * @description
	 * Creates a new selection or expands/collapses an existing selection based on the token
	 * that was clicked.
	 *
	 * @param config Config object containing, the token index that was clicked, whether the dropdown should be
	 * opened manually or using the MATTrigger, and if labelling inside composite entities should be enabled.
	 */
	public onClick(config: { index: number; forceOpenMenu: boolean; enableCompositeChildren: boolean }): void {
		const { index, forceOpenMenu = true, enableCompositeChildren = false } = config;
		const sel: ISelection = this.selection.getValue();
		const hoverStates: IHover[] = this.hoverStates.getValue();
		let newSelection: ISelection;

		Observable.combineLatest(
			this._entityWrapService.getWrapMode().first(),
			this._entityWrapService.isValidSelection(this.segment.start + index).first()
		)
			.first()
			.do(([mode, isValid]) => (mode && !isValid ? this._entityWrapService.setWrapMode(false) : null))
			.subscribe(() => {
				if (sel.start === EMPTY_SELECTION.start && sel.end === EMPTY_SELECTION.end) {
					newSelection = { start: index, end: index };
				} else if (index < sel.start) {
					newSelection = { start: index, end: sel.end };
				} else if (index > sel.end) {
					newSelection = { start: sel.start, end: index };
				} else {
					newSelection = { start: sel.start, end: index };
				}

				hoverStates[index] = EMPTY_HOVER;

				this.selection.next(newSelection);
				this.hoverStates.next(hoverStates);

				if (forceOpenMenu) {
					this.showDropdown.next(true);
				}

				this._entityWrapService.setIsCompositeChildren(enableCompositeChildren);
				this._entityWrapService.setWrapIndeces(
					this.segment.start + this.selection.getValue().start,
					this.segment.start + this.selection.getValue().end
				);
			});
	}

	/**
	 * @description
	 * Handles the behaviour of the plain segment when using
	 * the keyboard.
	 *
	 * @param index The index of the token the focus was on.
	 * @param event The event data that occurred.
	 */
	public onKeyPress(index: number, event: KeyboardEvent): void {
		switch (event.keyCode) {
			case RIGHT_ARROW:
			case LEFT_ARROW:
				if (event.shiftKey) {
					this.onClick({ index, forceOpenMenu: false, enableCompositeChildren: true });
				}
				break;
			case ENTER:
				// Event is already captured
				if (event.type !== 'keyup') {
					this.onClick({ index, forceOpenMenu: true, enableCompositeChildren: true });
				}
				break;
			case ESCAPE:
				if (this.selection.getValue() !== EMPTY_SELECTION) {
					this.onClickOutside();
					event.stopPropagation();
				}
				break;
			default:
		}

		// Checks the focused element after the keys have been pressed. If the focused element by then does not
		// belong to this plain segment, remove focus from it and clear the selection.
		if (!this.showDropdown.getValue()) {
			setTimeout(() => (!this._elementRef.nativeElement.contains(document.activeElement) ? this.onClickOutside() : null), 0);
		}
	}

	/**
	 * @description
	 * Handles the keydown for accessbility for this component.
	 */
	@HostListener('document:keydown', ['$event'])
	public handleKeyDown(event: KeyboardEvent): void {
		switch (event.keyCode) {
			case ESCAPE:
				event.stopPropagation();
				this.onClickOutside();
				break;
			default:
		}
	}

	/**
	 * @description
	 * Cancels all selections and hides the entity dropdown when user clicks
	 * outside the segment.
	 */
	public onClickOutside(): void {
		this.selection.next(EMPTY_SELECTION);
		this.showDropdown.next(false);
	}

	/**
	 * @description
	 * Notifies parent (usually a composite segment) that the composite
	 * underline in this segment was hovered.
	 *
	 * @param state The hover state, true if hovered on
	 * and false otherwise.
	 */
	public onCompositeHover(state: boolean): void {
		this.onCompositeHovered.emit(state);
	}

	/**
	 * @description
	 * Moves the entity dropdown to the provided position by emitting
	 * the position through the position subject.
	 *
	 * @param position The position to move the dropdown to.
	 */
	public moveDropdown(position: IOffset): void {
		this.dropdownPosition.next(position);
	}

	/**
	 * @description
	 * Notifies parent that a wrap event occured.
	 *
	 * @param event The wrap event metadata
	 */
	public handleDropdownCompositeWrapClick(event: ICompositeWrapMenuEvent): void {
		if (event.closeMenu) {
			this.showDropdown.next(false);
		}
		event.startIndex = this.segment.start + this.selection.getValue().start;
		event.endIndex = this.segment.start + this.selection.getValue().end;
		this.onWrapEntityClicked.emit(event);
	}

	/**
	 * @description
	 * Notifies parent that a new utterance entity was requested for creation
	 * for the current selection.
	 *
	 * @param entityEvent The entity to create the utterance entity for.
	 */
	private _handleDropdownExistingEntityClick(entityEvent: IEntityMenuEvent): IUtteranceEntityChangeEvent {
		return {
			oldUtteranceEntity: null,
			newUtteranceEntity: new UtteranceEntity(
				entityEvent.entity.id,
				entityEvent.entity.name,
				entityEvent.entity.type,
				(entityEvent.eventData && entityEvent.eventData.role) || '',
				(this.selection.getValue().start || 0) + this.segment.start,
				(this.selection.getValue().end || 0) + this.segment.start,
				null,
				null,
				(entityEvent.eventData && entityEvent.eventData.roleId) || ''
			),
			entityEvent: entityEvent
		};
	}

	/**
	 * @description
	 * Notifies the parent that a new entity was created for the current selection.
	 *
	 * @param entityName The name for the new entity to create.
	 */
	private _handleDropdownNewEntityClick(entityEvent: IEntityMenuEvent): IUtteranceEntityChangeEvent {
		return {
			oldUtteranceEntity: null,
			newUtteranceEntity: new UtteranceEntity(
				'',
				entityEvent.entity.name,
				null,
				'',
				this.selection.getValue().start + this.segment.start,
				this.selection.getValue().end + this.segment.start
			),
			entityEvent
		};
	}

	/**
	 * @description
	 * In the case that the plain segment is part of a parent segment, then
	 * dropdown menu could fire a delete emission for the parent entity. Forward
	 * this request to the parent.
	 *
	 * @param entityEvent The entity event
	 */
	private _handleDropdownDeleteEntityClick(entityEvent: IEntityMenuEvent): IUtteranceEntityChangeEvent {
		return {
			newUtteranceEntity: null,
			oldUtteranceEntity: new UtteranceEntity(
				entityEvent.entity.id,
				entityEvent.entity.name,
				entityEvent.entity.type,
				'',
				this.segment.start,
				this.segment.end
			),
			entityEvent
		};
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.hoverStates.next(this.segment.text.map(t => EMPTY_HOVER));

		this.currentWrapMode = this._entityWrapService.getWrapMode();

		this.segmentPredictionsString = this.selection
			.asObservable()
			.filter(selection => selection.start !== null && selection.end !== null)
			.map(selection => this.segment.predictions.filter(p => this._doesPredictionOverlapWithSelection(selection, p)))
			.map(entities => entities.map(e => e.name))
			.map(names => names.join(','));

		this._showDropdownSubscription = this.showDropdown
			.pipe(
				tap(isShown => {
					if (this.menuTrigger) {
						if (isShown) {
							this.menuTrigger.openMenu();
						} else {
							this.menuTrigger.closeMenu();
						}
					}
				})
			)
			.subscribe();

		this.menuClosed = this.showDropdown.pipe(
			filter(val => val === false),
			mapTo(null)
		);

		this.segmentSubject.next(this.segment);

		this._segmentSubscription = this.segmentSubject.subscribe(segment => {
			this.hoverStates.next(segment.text.map(t => EMPTY_HOVER));
		});
	}

	/**
	 * @description
	 * Checks if the two labeled entities overlap or not.
	 *
	 * @param a The first entity.
	 * @param b The second entity.
	 * @returns True if the entities overlap and false otherwise.
	 */
	private _doesPredictionOverlapWithSelection(selection: ISelection, entity: UtteranceEntity): boolean {
		if (entity.startTokenIndex <= selection.start && entity.endTokenIndex >= selection.start) {
			return true;
		}
		if (entity.startTokenIndex >= selection.start && entity.startTokenIndex <= selection.end) {
			return true;
		}

		return false;
	}

	/**
	 * @description
	 * Checks if this segment was focused before alteration happened to the labeled
	 * utterance and whether it should be focused now or not.
	 */
	private _checkInitialFocus(): void {
		if (this._segmentFocusService.shouldSegmentFocus(this.segment)) {
			const tokenToFocus = this.tokens.find((_, index) => this.segment.start + index === this._segmentFocusService.lastFocusedIndex);

			if (tokenToFocus) {
				tokenToFocus.nativeElement.focus();
			}
		}
	}
}
