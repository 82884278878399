import {
    Entity,
    EntityHelpers,
    EntityRole
} from '@luis/entities';
import { IPattern, IPatternFsmResult } from '../../interfaces/IPatternFsm';
import { IExtractedName, PatternEntityExtractorFsm } from './pattern-entity-extractor-fsm.model';

/**
 * @description
 * Represents a FSM to validate the entities and roles
 * in the pattern text.
 */
export class PatternEntityFsm implements IPattern {
    private _text: string;
    private _entities: Entity[];

    constructor(text: string, entities: Entity[]) {
        this._text = text;
        this._entities = EntityHelpers.expandChildren(entities);
    }

    /**
     * @method
     * @description
     * Checks if all the names between the paranthesis are all
     * valid entity names.
     *
     * @param isTerminal True if the expression has ended
     * and false if it is still being written.
     */
    public evaluate(isTerminal: boolean = false): IPatternFsmResult {
        const extractor: PatternEntityExtractorFsm = new PatternEntityExtractorFsm(this._text, this._entities);
        extractor.evaluate();
        const extractedEntities: IExtractedName[] = extractor.getRawExtractedEntities();

        for (const e of extractedEntities) {
            if (!this._checkIfEntityNameExists(e.value)) {
                return {
                    result: false,
                    error: {
                        index: e.startIndex,
                        message: `${e.value} is not a valid entity`
                    }
                };
            }
        }

        return { result: true };
    }

    /**
     * @description
     * Checks if the given name corresponds to an existing entity and/or
     * an existing entity/role combination.
     *
     * @param name The entity name to search for.
     * @returns True if found and vice versa.
     */
    private _checkIfEntityNameExists(name: string): boolean {
        const [entityName, roleName] = name.indexOf('::') === -1 ? name.split(':') : [name, undefined];
        const entity: Entity = this._entities.find(e => e.name === entityName);

        if (entity === undefined) {
            return false;
        }

        if (roleName !== undefined) {
            const role: EntityRole = entity.roles.find(r => r.name === roleName);

            if (role === undefined) {
                return false;
            }
        }

        return true;
    }
}
