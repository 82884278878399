import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IToastOptions, Toast } from '../../models/utils/toast.model';

/**
 * @description
 * Represents a service that keeps track of all toasts in the system.
 * Toasts are various system notifications whether they be loading,
 * information messages, warnings, or errors.
 */
@Injectable()
export class ToasterService {
	private readonly _toastsSubject: BehaviorSubject<Toast[]>;
	private readonly _toasts: Toast[];
	private readonly _toastDismissTime: number = 3000;

	constructor() {
		this._toasts = [];
		this._toastsSubject = new BehaviorSubject<Toast[]>([]);
	}

	/**
	 * @description
	 * Exposes the underlying toasts subject as an infinite observable.
	 *
	 * @returns An infinite observable of the array of toasts.
	 */
	public get(): Observable<Toast[]> {
		return this._toastsSubject.asObservable();
	}

	/**
	 * @description
	 * Creates a new toast with the given messages. Removes the toast automatically
	 * 3 seconds after success.
	 *
	 * @param data The message to display on start or toast options.
	 * @param onEndMsg The message to display on successful end.
	 * @param onErrorMsg The message to display on error.
	 * @param isDismissible Whether the toast can be dismissed or not.
	 * @returns An observable of the tracker state.
	 */
	public add(options?: IToastOptions): any {
		const newToast: Toast = new Toast(options);

		if (!newToast.options.isDismissible) {
			this._setDismissalBehaviours(newToast);
		}

		this._toasts.unshift(newToast);
		this._notify();

		if (newToast.options.returnToast) {
			return { tracker: newToast.getTracker(), toast: newToast };
		} else {
			return newToast.getTracker();
		}
	}

	/**
	 * @description
	 * Deletes the toast given from the array of toasts
	 *
	 * @param toast The toast to delete.
	 */
	public delete(toast: Toast): void {
		const indexToDelete: number = this._toasts.indexOf(toast);

		if (indexToDelete !== -1) {
			this._toasts.splice(indexToDelete, 1);
			this._notify();
		}
	}

	/**
	 * @description
	 * Removes toast after set timeouts at certain points of the
	 * toast lifetime.
	 *
	 * @param toast The toast to set the timeouts for.
	 */
	private _setDismissalBehaviours(toast: Toast): void {
		toast.isEnded
			.filter(state => state)
			.first()
			.subscribe(() => setTimeout(() => this.delete(toast), this._toastDismissTime));

		toast.hasData
			.filter(state => state)
			.first()
			.subscribe(() => setTimeout(() => this.delete(toast), this._toastDismissTime));
	}

	/**
	 * @description
	 * Notifies any subscribers with the new value by adding it to the stream.
	 */
	private _notify(): void {
		this._toastsSubject.next(this._toasts);
	}
}
