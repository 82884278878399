import { HttpHeaders, HttpParams } from '@angular/common/http';
import { IRequestOptions } from '../interfaces/IRequestOptions';

/**
 * @description
 * Custom header names that can be used in the request options.
 *
 * FORCE_CACHE_REFRESH: Forces the cache to refresh; gets data from the server even if cache exists.
 * CACHE_ONLY: Applies changes to the cache only. No Http call is made.
 * CLEAN_OPERATION: Skips marking the application/cache as dirty.
 */
export const CUSTOM_HEADERS: { FORCE_CACHE_REFRESH: string; CACHE_ONLY: string; CLEAN_OPERATION: string } = {
	FORCE_CACHE_REFRESH: 'X-Cache-Refresh',
	CACHE_ONLY: 'X-Cache-Only',
	CLEAN_OPERATION: 'X-Clean-Operation'
};

/**
 * @description
 * A class that applies the builder pattern to create http request options. Options
 * are used by angular http calls and the cacheing layer implemented.
 */
export class RequestOptionsBuilder {
	constructor(private readonly _options: IRequestOptions = {}) {
		if (!_options.headers) {
			_options.headers = new HttpHeaders();
		}

		_options.params = new HttpParams();
	}

	/**
	 * @description
	 * Applies the CORS credentials flag to enable cookies on CORS enabled
	 * server domains.
	 */
	public useCORS(): RequestOptionsBuilder {
		this._options.withCredentials = true;

		return this;
	}

	/**
	 * @description
	 * Applies the JSON content type header to the request options.
	 */
	public useJSON(): RequestOptionsBuilder {
		this._options.headers = this._options.headers.set('Content-Type', 'application/json; charset=utf-8');

		return this;
	}

	/**
	 * @description
	 * Applies the ARM header for Azure related calls with the given token.
	 *
	 * @param token The authorization token to use with the ARM calls.
	 */
	public useARMToken(token: string): RequestOptionsBuilder {
		this._options.headers = this._options.headers.set('Authorization', `Bearer ${token}`);

		return this;
	}

	/**
	 * @description
	 * Applies the LUIS CIG programmatic key header with the given key.
	 *
	 * @param key The key to add to the header.
	 */
	public useSubKey(key: string): RequestOptionsBuilder {
		this._options.headers = this._options.headers.set('Ocp-Apim-Subscription-Key', key);

		return this;
	}

	/**
	 * @description
	 * Applies the custom header to force refresh the cache even if it existed
	 * by always making the http server.
	 */
	public forceCacheRefresh(): RequestOptionsBuilder {
		this._options.headers = this._options.headers.set(CUSTOM_HEADERS.FORCE_CACHE_REFRESH, 'SET');

		return this;
	}

	/**
	 * @description
	 * Applies the custom header for using cache only with no http calls to
	 * the request options.
	 */
	public useCacheOnly(): RequestOptionsBuilder {
		this._options.headers = this._options.headers.set(CUSTOM_HEADERS.CACHE_ONLY, 'SET');

		return this;
	}

	/**
	 * @description
	 * Applies the custom header for skipping marking the app as dirty.
	 */
	public skipMarkDirty(): RequestOptionsBuilder {
		this._options.headers = this._options.headers.set(CUSTOM_HEADERS.CLEAN_OPERATION, 'SET');

		return this;
	}

	/**
	 * @description
	 * Returns the built request options.
	 */
	public build(): IRequestOptions {
		return this._options;
	}
}
