import { InjectionToken } from '@angular/core';
import { IRequestOptions } from '@luis/api';
import { Observable } from 'rxjs/Rx';
import { Id, IResource, IResourceApiParser } from '../../models/IResource';

export const RESOURCE_CACHE_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IResourceCacheService');

/**
 * @description
 * Represents an interface for a data service. A data service is responsible for
 * making web calls and cacheing their results in a central cache for all LUIS
 * resources across an app.
 */
export interface IResourceCacheService {
	get(path: string, parser: IResourceApiParser, options: IRequestOptions, cachePath?: string): Observable<IResource[]>;

	post(
		path: string,
		data: IResource,
		options: IRequestOptions,
		parser?: IResourceApiParser,
		cachePath?: string,
		dataToCache?: IResource,
		cacheResponse?: boolean
	): Observable<Id | void>;

	batchPost(
		path: string,
		data: IResource[],
		parser: IResourceApiParser,
		options: IRequestOptions,
		cachePath?: string
	): Observable<IResource[]>;

	put(
		path: string,
		data: IResource,
		options: IRequestOptions,
		cachePath?: string,
		dataToCache?: IResource,
		parser?: IResourceApiParser,
		cacheResponse?: boolean
	): Observable<void>;

	batchPut(path: string, data: IResource[], options: IRequestOptions, cachePath?: string): Observable<void>;

	refreshSingle(path: string, parser: IResourceApiParser, options: IRequestOptions, cachePath?: string): Observable<IResource>;

	delete(
		path: string,
		id: Id,
		options: IRequestOptions,
		cachePath?: string,
		dataToCache?: IResource,
		bodyData?: Object
	): Observable<void>;

	batchDelete(path: string, items: Id[], options: IRequestOptions, batchDelete: boolean, cachePath?: string): Observable<Id[]>;

	invalidate(path: string): void;
}
