import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';

let internalId = 0;

/**
 * This is the checkbox component that will render using melder styles
 */

@Component({
    selector: 'm-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CheckboxComponent implements OnChanges {
    @Input() disabled?: boolean;
    @Input() name?: string;
    @Input() id?: string;
    @Input() value?: string;
    @Input('aria-label') ariaLabel?: string;
    @Input('aria-labelledby') ariaLabelledBy?: string;
    @Input() checked?: boolean;

    @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('checkbox', { read: ElementRef }) checkBoxElem: ElementRef;

    checkboxId: string;
    isFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isChecked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isHovered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
        this.checkboxId = `m-checkbox-id-${internalId}`;
        internalId += 1;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['checked']) {
            this.isChecked.next(
                <boolean> changes['checked'].currentValue
            );
        }
    }

    _onFocus(): void {
        this.isFocused.next(true);
        this.checkBoxElem.nativeElement.focus();
    }

    _onBlur(): void {
        this.isFocused.next(false);
    }

    _onHover(): void {
        this.isHovered.next(true);
    }

    _offHover(): void {
        this.isHovered.next(false);
    }

    _toggleChecked(e: UIEvent): void {
        e.preventDefault();
        e.stopPropagation();

        if (this.disabled) {
            return;
        }

        const newValue = !this.isChecked.getValue();

        this.checkedChange.emit(newValue);
    }
}
