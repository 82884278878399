import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileService, IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APP_VERSION_SERVICE_TOKEN, IAppVersionService } from '../../../interfaces/IAppVersionService';
import { VersionNameInputComponent } from '../version-name-input/version-name-input.component';

/**
 * @description
 * Represents the modal for importing a new app.
 */
@Component({
	selector: 'version-import-modal',
	templateUrl: 'version-import-modal.component.html',
	styleUrls: ['version-import-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionImportModalComponent implements OnInit {
	@ViewChild('versionNameInput') public versionNameInput: VersionNameInputComponent;

	public isButtonDisabled: Observable<boolean>;

	private _selectedFile: File;
	private readonly _isFileValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _fileService: FileService,
		private readonly _dialogRef: MatDialogRef<AppVersionImportModalComponent>,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _versionService: IAppVersionService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Updates the validation stream when the file value changes.
	 *
	 * @param file The version file.
	 */
	public onFileChange(file: File): void {
		this._selectedFile = file;
		this._isFileValid.next(this._validateFile(file.name));
	}

	/**
	 * @description
	 * Imports the version file selected.
	 */
	public importVersion(): void {
		if (this._validateFile(this._selectedFile.name)) {
			this._inProgress.next(true);

			this._fileService
				.read(this._selectedFile)
				.flatMap(content => this._versionService.import(content, this.versionNameInput.versionName))
				.trackProgress(this._toasterService.add())
				.finally(() => this._inProgress.next(false))
				.subscribe(version => this._dialogRef.close(version));
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.isButtonDisabled = Observable.combineLatest(
			this._inProgress.asObservable(),
			this._isFileValid.asObservable(),
			this.versionNameInput.versionNameValid.asObservable()
		).map(([inProgress, isFileValid, isVersionNameValid]) => inProgress || !isFileValid || !isVersionNameValid);
	}

	/**
	 * @description
	 * Validates the file name and extension
	 *
	 * @param fileName The file name to validate.
	 * @returns True if the file name is valid and false otherwise.
	 */
	private _validateFile(fileName: string): boolean {
		const extension: string = fileName.replace(/^.*\./, '');

		return fileName.trim() !== '' && extension.toLocaleLowerCase() === 'json';
	}
}
