import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { FEATURE_OPT_SETTINGS_TYPES, FEATURE_RATING_TYPES, FeatureOptSettingsService, IDictionary } from '@luis/core';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IUtteranceResultsDto } from '../../interfaces/IUtteranceResults';

@Component({
	selector: 'utterance-inspector',
	templateUrl: 'utterance-inspector.component.html',
	styleUrls: ['utterance-inspector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtteranceInspectorComponent implements OnInit {
	@Input() public data: IUtteranceResultsDto;
	@Input() public isEditable: boolean;
	@Input() public theme: 'light' | 'dark' = 'dark';

	public intents: Observable<Intent[]>;
	public featureOptSettings: Observable<IDictionary<boolean>>;
	public utteranceReset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public featureRatingTypes: typeof FEATURE_RATING_TYPES = FEATURE_RATING_TYPES;
	public featureOptSettingsTypes: typeof FEATURE_OPT_SETTINGS_TYPES = FEATURE_OPT_SETTINGS_TYPES;

	constructor(
		private readonly _featureOptSettingsService: FeatureOptSettingsService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			this.utteranceReset.next(false);
			setTimeout(() => this.utteranceReset.next(true), 0);
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.intents = this._intentService.get();

		this.featureOptSettings = this._featureOptSettingsService.getAllOptSettings();
	}
}
