import { Component, ViewEncapsulation } from "@angular/core";

/**
 * Simple label component that applies the right style.
 */
@Component({
    selector: "label[m-label]",
    template: '<ng-content></ng-content>',
    styleUrls: ['./label.component.scss'],

    host: {
        '[class.m-label]': 'true'
    },
    encapsulation: ViewEncapsulation.None,
})
export class LabelComponent {
}
