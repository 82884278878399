import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ClosedDetailsComponent } from './closed-details/closed-details.component';
import { ImportListModalComponent } from './closed-details/import-list-modal/import-list-modal.component';
import { SublistComponent } from './closed-details/sublist/sublist.component';
import { SuggestionBuilderComponent } from './closed-details/suggestion-builder/suggestion-builder.component';
import { CompositeDetailsComponent } from './composite-details/composite-details.component';
import { EntityDetailsComponent } from './entity-details.component';
import { HierarchicalDetailsComponent } from './hierarchical-details/hierarchical-details.component';
import { RegexDetailsComponent } from './regex-details/regex-details.component';
import { RoleDetailsComponent } from './role-details/role-details.component';

@NgModule({
	imports: [SharedModule],
	exports: [EntityDetailsComponent],
	declarations: [
		EntityDetailsComponent,
		ClosedDetailsComponent,
		RegexDetailsComponent,
		ImportListModalComponent,
		CompositeDetailsComponent,
		HierarchicalDetailsComponent,
		SuggestionBuilderComponent,
		RoleDetailsComponent,
		SublistComponent
	],
	entryComponents: [ImportListModalComponent]
})
export class EntityDetailsModule {}
