import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseService, IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { APP_SERVICE_TOKEN, IAppService } from '../../../interfaces/IAppService';
import { APP_UTILITIES_SERVICE_TOKEN, IAppUtilitiesService } from '../../../interfaces/IAppUtilitiesService';
import { AppCulture } from '../../../models/app-culture.model';
import { App } from '../../../models/app.model';

/**
 * @description
 * Represents the dialog for creating a new app.
 */
@Component({
	selector: 'app-creation-modal',
	templateUrl: 'app-creation-modal.component.html',
	styleUrls: ['app-creation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppCreationModalComponent implements OnInit {
	public cultures: Observable<AppCulture[]>;
	public appForm: FormGroup;
	public maxDescriptionLength: number = 400;
	public isButtonDisabled: Observable<boolean>;

	private readonly _inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _formBuilder: FormBuilder,
		private readonly _baseService: BaseService,
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<AppCreationModalComponent>,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(APP_UTILITIES_SERVICE_TOKEN) private readonly _appUtilService: IAppUtilitiesService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Fired when a culture option is selected from the dropdown, and
	 * updates the corresponding form group control.
	 *
	 * @param cultureCode Code corresponding to the selected culture option in dropdown.
	 */
	public onCultureChange(cultureCode: string): void {
		this.appForm.patchValue({ culture: cultureCode });
	}

	/**
	 * @description
	 * Adds a new app to the account with the form data.
	 */
	public createApp(): void {
		if (this.appForm.status === 'VALID') {
			this._inProgress.next(true);

			const newApp: App = new App(
				'',
				this.appForm.get('name').value.trim(),
				this.appForm.get('culture').value.trim(),
				this.appForm.get('description').value.trim()
			);
			newApp.ownerEmail = this._baseService.configs.userEmail;

			this._appService
				.add(newApp, true)
				.finally(() => this._inProgress.next(false))
				.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('apps.app-creation-modal.add_toast') }))
				.subscribe(id => {
					newApp.id = <string>id;
					this._dialogRef.close(newApp);
				});
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.cultures = this._appUtilService.getCultures().do(cultures =>
			cultures.map(c => {
				if (c.code === 'tr-tr') {
					c.name = 'Turkish (Preview)';
				}
			})
		);
		this.appForm = this._formBuilder.group({
			name: ['', Validators.required],
			culture: ['en-us', Validators.required],
			description: ['', Validators.maxLength(400)]
		});

		this.isButtonDisabled = Observable.combineLatest(
			this._inProgress.asObservable(),
			this.appForm.statusChanges.startWith('INVALID')
		).map(data => {
			const isInProgress: boolean = data[0];
			const formStatus: string = data[1];

			return isInProgress || formStatus !== 'VALID';
		});
	}
}
