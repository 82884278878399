import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Entity } from '@luis/entities';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { IInputHandlerPayload } from '../../interfaces/IInputHandlerPayload';
import { IFsmError, IPatternFsmResult } from '../../interfaces/IPatternFsm';
import { FsmRunner } from '../../models/fsms/fsm-runner.model';
import { PatternEntityExtractorFsm } from '../../models/fsms/pattern-entity-extractor-fsm.model';
import { PatternEntity } from '../../models/pattern.model';
import { InputHandlerService } from '../../services/input-handler.service';
import { BracesHandlerService } from './braces-handler/braces-handler.service';

@Component({
    selector: 'pattern-input-handler',
    templateUrl: 'input-handler.component.html'
})
export class InputHandlerComponent implements OnInit, OnDestroy {
    @Input() public strokeStream: BehaviorSubject<IInputHandlerPayload>;
    @Input() public clickStream: BehaviorSubject<MouseEvent>;
    @Input() public patternEntityStream: BehaviorSubject<PatternEntity[]>;
    @Input() public errorStream: BehaviorSubject<IFsmError>;
    @Input() public entities: Entity[] = [];
    @Input() public inputField: HTMLInputElement;
    @ViewChild('componentBed', { read: ViewContainerRef }) public componentBed: ViewContainerRef;

    private _handlerSubscription: Subscription = new Subscription();

    constructor(
        private _handlerService: InputHandlerService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        this._handlerSubscription.unsubscribe();
    }

    private _initState(): void {
        this.entities = this.entities ? this.entities : [];
        this._registerHandlers();
        this._subscribeToStream();
    }

    /**
     * @description
     * Registers all supported key stroke handlers.
     */
    private _registerHandlers(): void {
        this._handlerService.registerHandler(BracesHandlerService);
    }

    /**
     * @description
     * Subscribes to the utterance text stream. Fires all registered
     * key stroke handlers when the stream emits a value.
     */
    private _subscribeToStream(): void {
        this._handlerSubscription = Observable.combineLatest(this.strokeStream, this.clickStream).subscribe(([payload, event]) => {
            const fsmsResult: IPatternFsmResult = FsmRunner.runValidators(payload.currentText, null, false);
            const extractor: PatternEntityExtractorFsm = new PatternEntityExtractorFsm(payload.currentText, this.entities);

            this.errorStream.next(fsmsResult.result ? null : fsmsResult.error);
            this._handlerService.execute(this.inputField, this.strokeStream, this.componentBed);

            extractor.evaluate();
            this.patternEntityStream.next(extractor.getExtractedEntities());
        });
    }
}
