import {
    Observable,
    Observer,
    Operator,
    Subscriber
} from 'rxjs/Rx';
import { TeardownLogic } from 'rxjs/Subscription';

/**
 * @description
 * An interface of the message type that would be passed to the tracker from the operator.
 */
export interface ITrackerMessage {
    state: number;
    error?: Error;
}

/**
 * @description
 * An enum of the possible states an observable can be tracked as.
 */
export enum PROGRESS_STATES {
    NOT_STARTED,
    STARTED,
    HAS_DATA,
    ENDED,
    ERROR,
    INDETERMINATE
}

/**
 * @method
 * @description
 * An extension to the RxJS library operators. Tracks the progress of the underlying observable. Pushes the progress
 * state to notifiers through the given tracker. A tracker can either be an object that implements the observer interface
 * or a factory that produces such an object.
 *
 * @param this Ensures that the function is called from an observable type.
 * @param tracker An object that implements the observer interface or a
 * factory method that produces an object that implements the observer interface.
 * @returns Simply forwards the underlying observable.
 */
export function trackProgress<T>(
    this: Observable<T>,
    tracker: Observer<ITrackerMessage> | (() => Observer<ITrackerMessage>)): Observable<T> {
    return this.lift(new TrackProgressOperator(tracker));
}

/**
 * @description
 * A concrete implementation of the operator interface. This class simply constructs the track progress subscriber
 * which handles the heavy logic.
 */
class TrackProgressOperator<T> implements Operator<T, T> {
    private _tracker: Observer<ITrackerMessage> | (() => Observer<ITrackerMessage>);

    constructor(tracker: Observer<ITrackerMessage> | (() => Observer<ITrackerMessage>)) {
        this._tracker = tracker;
    }

    public call(subscriber: Subscriber<T>, source: any): TeardownLogic {
        return source.subscribe(new TrackProgressSubscriber(subscriber, this._tracker));
    }
}

/**
 * @description
 */
class TrackProgressSubscriber<T> extends Subscriber<T> {
    private _tracker: Observer<ITrackerMessage>;

    constructor(destination: Subscriber<T>, tracker: Observer<ITrackerMessage> | (() => Observer<ITrackerMessage>)) {
        super(destination);
        this._onSubscribe(tracker);
    }

    /**
     * @protected
     * @description
     * Notifies the tracker that a data item has been received on the
     * stream at least once.
     *
     * @param data The data received on the stream.
     */
    protected _next(data: any): void {
        this._tracker.next({ state: PROGRESS_STATES.HAS_DATA });
        this.destination.next(data);
    }

    /**
     * @protected
     * @description
     * Notifies the tracker observer that the underlying observable has terminated
     * with error. Passes on the error object.
     *
     * @param err The error object thrown.
     */
    protected _error(err: Error): void {
        this._tracker.next({ state: PROGRESS_STATES.ERROR, error: err });
        this.destination.error(err);
    }

    /**
     * @protected
     * @description
     * Notifies the tracker observer that the underlying observable has completed.
     */
    protected _complete(): void {
        this._tracker.next({ state: PROGRESS_STATES.ENDED });
        this.destination.complete();
    }

    /**
     * @description
     * Creates the local tracker observer eithre by calling the tracker factory function (if it
     * is a function) or direct assignment.
     *
     * @param tracker An observer or observer factory
     * to create an observer that is used to notify the current state of the operator.
     */
    private _onSubscribe(tracker: Observer<ITrackerMessage> | (() => Observer<ITrackerMessage>)): void {
        if (this.isFunction(tracker)) {
            this._tracker = (<() => Observer<ITrackerMessage>>tracker)();
        }
        else {
            this._tracker = <Observer<ITrackerMessage>>tracker;
        }

        this._tracker.next({ state: PROGRESS_STATES.STARTED });
    }

    /**
     * @description
     * Checks if the given object is a function or not.
     *
     * @param object The object to check.
     * @returns True if object is of function type and false otherwise.
     */
    private isFunction(object: Object): boolean {
        return typeof object === 'function';
    }
}

// Add the toast method to the observable prototype.
Observable.prototype.trackProgress = trackProgress;

// Declare the trackProgress method on the observable class to be accessible across all packages using core.
declare module 'rxjs/internal/Observable' {
    interface Observable<T> {
        trackProgress: typeof trackProgress;
    }
}
