import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CoreModule } from '@luis/core';
import { EntitiesModule } from '@luis/entities';
import { IntentsModule } from '@luis/intents';
import { PatternsModule } from '@luis/patterns';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { ActiveEditModeAsync } from '../pipes/active-edit-mode-async.pipe';
import { EntityWrapSelectionAsyncPipe } from '../pipes/entity-wrap-selection.pipe';
import { HasErrorPipe } from '../pipes/has-error.pipe';
import { PlainWrapSelectionAsyncPipe } from '../pipes/plain-wrap-selection.pipe';
import { WrapUnderlinExpanderPipe } from '../pipes/wrap-underline-expander.pipe';

@NgModule({
	imports: [
		CommonModule,
		CoreModule,
		PatternsModule,
		IntercomUIModule,
		NgxPaginationModule,
		IntentsModule,
		EntitiesModule,
		TranslateModule,
		MatExpansionModule
	],
	declarations: [EntityWrapSelectionAsyncPipe, PlainWrapSelectionAsyncPipe, WrapUnderlinExpanderPipe, HasErrorPipe, ActiveEditModeAsync],
	exports: [
		CommonModule,
		CoreModule,
		PatternsModule,
		IntercomUIModule,
		NgxPaginationModule,
		EntityWrapSelectionAsyncPipe,
		PlainWrapSelectionAsyncPipe,
		WrapUnderlinExpanderPipe,
		HasErrorPipe,
		IntentsModule,
		EntitiesModule,
		TranslateModule,
		ActiveEditModeAsync,
		MatExpansionModule
	]
})
export class SharedModule {}
