import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector
} from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs/Rx';
import { IModalComponentToken } from '../../interfaces/utils/IModalComponent';
import { IModalHost } from '../../interfaces/utils/IModalHost';
import { BUS_EVENTS, EventBusMessage } from '../../models/utils/event-bus.model';
import { EventBusService } from './event-bus.service';

/**
 * @description
 * Represents an interface to provide custom injector context
 * for the modal to render.
 */
export interface IInjectorContext {
    resolver: ComponentFactoryResolver;

    injector: Injector;
}

/*
 *  This service will be injected into a module, and hooked up to a container element (visibleDialog)
 *  that will allow any sort of custom Component to be passed in and rendered to the screen as
 *  a pop-up style dialog with an overlay.
 */
@Injectable()
export class ModalHostService {
    private visibleDialog: ComponentRef<any>;
    private modalHost: IModalHost = null;

    constructor(
        private _eventBusService: EventBusService,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector
    ) { }

    /**
     * @description
     * Sets the modal host container as the given host component factory.
     *
     * @param host The modal host component factory.
     */
    public set hostContainer(host: IModalHost) {
        this.modalHost = host;
    }

    /**
     * @description
     * Creates a new component and injects it in the modal host
     * wrapper component.
     *
     * @param componentInfo The component that will be
     * appended in the modal host component.
     * @param parameters Optional parameters to be passed to the
     * component that will be added to the modal host.
     * @returns The result of the component processing.
     */
    public create<T>(componentInfo: IModalComponentToken<T>, parameters?: Object, context?: IInjectorContext): Observable<T> {
        const returnSubject: Subject<T> = new Subject<T>();
        const compParams: Object = componentInfo.defaultParams || {};

        this._create(componentInfo.componentType, { ...compParams, ...parameters, response: returnSubject }, context);
        this._eventBusService.publishToBus(new EventBusMessage(BUS_EVENTS.MODAL_TOGGLED, true));

        return returnSubject
            .asObservable()
            .finally(() => this.destroy());
    }

    /**
     * @description
     * Destroys the modal host component.
     */
    public destroy(): void {
        this.visibleDialog.destroy();
        this.visibleDialog = null;
        this._eventBusService.publishToBus(new EventBusMessage(BUS_EVENTS.MODAL_TOGGLED, false));
        this.modalHost.hide();
    }

    /**
     * @description
     * Returns the reference of the current component
     * in the modal host.
     *
     * @returns An observable of the current
     * component in the dialog.
     */
    public dialog<T>(): Observable<ComponentRef<T>> {
        return Observable.of(this.visibleDialog);
    }

    /**
     * @description
     * Creates the component and replaces it in the modal dialog
     * host. Passes the parameters to the component.
     *
     * @param component The component type to create.
     * @param parameters
     */
    private _create<T>(component: any, parameters?: Object, context?: IInjectorContext): Observable<ComponentRef<T>> {
        const resolver = context ? context.resolver : this._componentFactoryResolver;
        const injector = context ? context.injector : this._injector;

        const componentFactory: ComponentFactory<any> = resolver.resolveComponentFactory(component);
        const ret: ReplaySubject<any> = new ReplaySubject();

        // Clears any existing dialogs.
        this.modalHost.getContainerRef().clear();

        const componentRef: ComponentRef<any> = this.modalHost.getContainerRef().createComponent(componentFactory, 0, injector);
        Object.assign(componentRef.instance, parameters);
        this.visibleDialog = componentRef;
        this.modalHost.show();
        ret.next(componentRef);
        ret.complete();

        return ret.asObservable();
    }
}
