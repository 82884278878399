import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, CHUNKING_PROCESS_TYPE, ChunkingService, EventBusService, IPageInfo, IPaginationCacheService } from '@luis/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UTTERANCE_CACHE_SERVICE_TOKEN } from '../interfaces/services/IUtteranceCacheService';
import { Utterance } from '../models/utterance.model';
import { PredictionCacheService } from './prediction-cache.service';

@Injectable()
export class UtteranceChunkingService extends ChunkingService<Utterance> {
	protected chunkingType: CHUNKING_PROCESS_TYPE = CHUNKING_PROCESS_TYPE.UTTERANCES;
	constructor(
		eventBusService: EventBusService,
		private readonly _baseService: BaseService,
		private readonly _predictionCacheService: PredictionCacheService,
		@Inject(UTTERANCE_CACHE_SERVICE_TOKEN) utteranceCacheService: IPaginationCacheService<Utterance>,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		super(eventBusService, utteranceCacheService);
	}

	private get _baseUrl(): string {
		return `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}`;
	}

	/**
	 * @description
	 * Fetches and merges utterances from both the reviewLabels and reviewPredictions APIs
	 *
	 * @param modelId intent or entity id
	 * @param pageInfo skip and take values for the current chunk
	 * @returns Observable of array of utterances with both labels and predictions
	 */
	public fetchData(modelId: string, { skip, take }: IPageInfo): Observable<Utterance[]> {
		const labelsPath = `${this._baseUrl}/models/${modelId}/reviewLabels?skip=${skip}&take=${take}`;

		return combineLatest(
			this._httpService
				.get<Object[]>(labelsPath, this._baseService.defaultOptionsBuilder.build())
				.pipe(map(response => response.map(u => Utterance.importFromApi(u)))),

			this._predictionCacheService.predictRange(modelId, { skip, take })
		).map(([labeledUtternces, predictedUtterances]) =>
			labeledUtternces.map((labeledUtterance, index) =>
				PredictionCacheService.mergeUtterances(labeledUtterance, predictedUtterances[index])
			)
		);
	}
}
