import { QueryList } from '@angular/core';
import { SublistComponent } from '../components/entity-details/closed-details/sublist/sublist.component';

/**
 * @description
 * Manage the focus of sublists.
 *
 * @param sublist QuerryList of all sublists component.
 */
export class ClosedEntityFocusManager {
    private _items: QueryList<SublistComponent>;

    constructor(sublists: QueryList<SublistComponent>) {
        this._items = sublists;
    }

    public onTabClicked(index: number, event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.prevSublistFocus(index, event);
        }
        else {
            this.nextSublistFocus(index, event);
        }
    }

    /**
     * @method
     * @description
     * Focus on the next sublist component.
     *
     * @param index The index of the sublist.
     * @param event The event emmited by the user.
     */
    public nextSublistFocus(index: number, event: KeyboardEvent): void {
        const items = this._items.toArray();
        if (index !== this._items.length - 1) {
            event.preventDefault();
            items[index].editMode.next(false);
            items[index + 1].editMode.next(true);
            setTimeout(() => items[index + 1].focus(), 0);
        }
    }

    /**
     * @method
     * @description
     * Focus on the previous sublist component.
     *
     * @param index The index of the sublist.
     * @param event The event emmited by the user.
     */
    public prevSublistFocus(index: number, event: KeyboardEvent): void {
        const items = this._items.toArray();
        if (index !== 0) {
            event.preventDefault();
            items[index].editMode.next(false);
            items[index - 1].editMode.next(true);
            setTimeout(() => items[index - 1].focus(), 0);

        }
    }

    /**
     * @method
     * @description
     * Focus on the last sublist component.
     *
     * @param index The index of the sublist.
     * @param event The event emmited by the user.
     */
    public lastSublistFocus(event: KeyboardEvent): void {
        const items = this._items.toArray();
        event.preventDefault();
        items[items.length - 1].editMode.next(true);
        items[items.length - 1].focus();
        setTimeout(() => items[items.length - 1].focus(), 0);
    }
}
