import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { EMPTY_SORT_PROPS, ISortPipeProps } from '../interfaces/ISortPipeProps';
import { TableRowComponent } from '../table-row/table-row.component';

@Injectable()
export class TableService {
	private readonly _multiSelectEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _selectAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly _selectedRows: BehaviorSubject<Map<TableRowComponent, boolean>> = new BehaviorSubject<Map<TableRowComponent, boolean>>(
		new Map<TableRowComponent, boolean>()
	);
	private _sortProps: BehaviorSubject<ISortPipeProps>;

	/**
	 * @description
	 * Sets the reference to the sort props to the given subject.
	 *
	 * @param sortProps The sort props subject pertaining to the
	 * current table.
	 */
	public setSortPropsSubject(sortProps: BehaviorSubject<ISortPipeProps>): void {
		this._sortProps = sortProps || new BehaviorSubject<ISortPipeProps>(EMPTY_SORT_PROPS);
	}

	/**
	 * @description
	 * Gets the current stream properties.
	 */
	public getSortProps(): Observable<ISortPipeProps> {
		return this._sortProps.asObservable();
	}

	/**
	 * @description
	 * Sorts the data table by the given property by passing the sorting pipe the appropiate
	 * sorting properties.
	 *
	 * @param property The object property to sort the data table on.
	 */
	public updateSortProps(property: string): void {
		const currentSortProps: ISortPipeProps = this._sortProps.getValue();
		const sortProps: ISortPipeProps = { property: property, type: 1 };

		if (currentSortProps.property === property) {
			sortProps.type = currentSortProps.type * -1;
		}

		this._sortProps.next(sortProps);
	}

	/**
	 * @description
	 * Gets the multi select subject as an observable.
	 */
	public getMultiSelectStatus(): Observable<boolean> {
		return this._multiSelectEnabled.asObservable();
	}

	/**
	 * @description
	 * Sets the current value as the multi select stream.
	 *
	 * @param val The value to set.
	 */
	public setMultiSelectStatus(val: boolean): void {
		this._multiSelectEnabled.next(val);
	}

	/**
	 * @description
	 * Gets the select all status as an observable.
	 */
	public getSelectAll(): Observable<boolean> {
		return this._selectAll.asObservable();
	}

	/**
	 * @description
	 * Toggles the select all status.
	 *
	 * @param state The select all state to set.
	 */
	public setSelectAll(state: boolean): void {
		this._selectAll.next(state);
	}

	/**
	 * @description
	 * Gets the currently selected rows as an observable. In
	 * cases where the select all checkbox is used, all rows
	 * register that they are selected almost instantly. That
	 * is, the table service set selected row function gets
	 * called multiple times very quickly, and this function
	 * would update multiple times for no reason. To avoid unecessary
	 * processing, a debounce time of 100ms is added to ensure that
	 * when rows are selected programmatically, the function only
	 * fires its observable once after all of them are marked
	 * as selected.
	 *
	 * @returns An observable of the selected table rows.
	 */
	public getSelectedRows(): Observable<TableRowComponent[]> {
		return this._selectedRows
			.asObservable()
			.debounceTime(100)
			.map(map => Array.from(map.keys()));
	}

	/**
	 * @description
	 * Sets the selection state of the given row.
	 *
	 * @param row The row to set.
	 * @param state The state to set it in.
	 */
	public setSelectionOnRow(row: TableRowComponent, state: boolean): void {
		const map: Map<TableRowComponent, boolean> = this._selectedRows.getValue();
		state ? map.set(row, state) : map.delete(row);
		this._selectedRows.next(map);
	}
}
