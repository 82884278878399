import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_SERVICE_TOKEN, IAppService } from '@luis/apps';
import { IToasterService, ProgressTracker, SelectionMap, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN, INTENT_TYPES, IntentUtilitiesService } from '@luis/intents';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { finalize, first, flatMap, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { DOMAIN_SERVICE_TOKEN, IDomainService } from '../../interfaces/IDomainService';
import { Domain, DomainModel } from '../../models/domain.model';

/**
 * @description
 * Represents the modal for listing and adding prebuilt entities.
 */
@Component({
	selector: 'domain-intents-modal',
	templateUrl: 'domain-intents-modal.component.html',
	styleUrls: ['domain-intents-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainIntentsModalComponent implements OnInit {
	public domainIntentsToDisplay: Observable<DomainModel[]>;
	public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public isValid: Observable<boolean>;
	public inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public selectionMap: SelectionMap = new SelectionMap();
	public tracker: ProgressTracker = new ProgressTracker();

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _intentUtilService: IntentUtilitiesService,
		private readonly _dialogRef: MatDialogRef<DomainIntentsModalComponent>,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(DOMAIN_SERVICE_TOKEN) private readonly _domainService: IDomainService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Updates the search query stream with the new entered query.
	 */
	public onQueryChange(query: string): void {
		this.querySubject.next(query.trim());
	}

	/**
	 * @description
	 * Updates the selection map when a checkbox value changes.
	 *
	 * @param domainIntentName The domain intent name of the checkbox that changed.
	 */
	public onCheckboxChange(domainIntentName: string): void {
		if (this.selectionMap.isSelected(domainIntentName)) {
			this.selectionMap.markUnselected([domainIntentName]);
		} else {
			this.selectionMap.markSelected([domainIntentName]);
		}
	}

	/**
	 * @description
	 * Adds the selected intents to the application.
	 */
	public onDoneClick(): void {
		const domainIntents: Intent[] = this.selectionMap.selectedItems
			.map(name => (<string>name).split('.'))
			.map(names => new Intent('', `${names[0]}.${names[1]}`, 0, INTENT_TYPES.DOMAIN, names[0], names[1]));

		this.inProgress.next(true);

		combineLatest(domainIntents.map(i => this._intentService.add(i)))
			.pipe(
				flatMap(() => this._intentUtilService.getLabelsPerIntent(true)),
				finalize(() => this.inProgress.next(false))
			)
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('domains.domain-intents-modal.add_intent') }))
			.subscribe(() => this._dialogRef.close());
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.domainIntentsToDisplay = this._appService
			.getSingle()
			.pipe(
				first(),
				flatMap(app => Observable.combineLatest(this._intentService.get(), this._domainService.get(app.culture))),
				map(([intents, domains]) => this._filterDomainIntents(intents, domains))
			)
			.trackProgress(this.tracker.getTracker());

		this.isValid = this.selectionMap.selectedItemsAsync.map(ids => ids.length !== 0);
	}

	/**
	 * @description
	 * Filters the domain intents to display based on what of them have
	 * already been added before.
	 *
	 * @param intents The intents that are added in the application.
	 * @param domains The list of domains that are available.
	 * @returns The domain intents while ensuring that they are not already added.
	 */
	private _filterDomainIntents(intents: Intent[], domains: Domain[]): DomainModel[] {
		const addedDomainIntents: Intent[] = intents.filter(i => i.domainName !== null);
		const filteredDomainIntents: DomainModel[] = domains
			.reduce((acc, curr) => acc.concat(curr.intents), [])
			.filter(dI => addedDomainIntents.find(i => i.uniqueName === dI.uniqueName) === undefined);

		this.selectionMap.setMapItems(filteredDomainIntents.map(dI => dI.uniqueName));

		return filteredDomainIntents;
	}
}
