import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TooltipBubbleComponent } from '../tooltip/tooltip-bubble.component';
import { Alignment, findPopupBoundsBestFit } from '../utils/popupAlignment';
import { getBoundingClientRectToBody, rectToStyle } from '../utils/rectangle';

/**
 * Callout component - clicking on question icon shows the tooltip bubble / popup.
 *     the location of the popup changes based on the icon's location in the document
 * Usage:
 *  <p>Text that needs to be further defined or elaborated on <m-callout [label]="'This is the callout text elaborating on the paragraph
 *  text'"></m-callout></p>
 */
@Component({
	selector: 'm-callout',
	templateUrl: 'callout.component.html',
	styleUrls: ['callout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalloutComponent implements AfterContentChecked {
	@Input() public alignment?: Alignment;
	@Input('aria-label') public ariaLabel?: string;

	@ViewChild('tooltipBubble') public tooltipBubble: TooltipBubbleComponent;
	@ViewChild('callout') public callout: ElementRef;

	public targetBoundsStyle: any;
	public bubbleBoundsStyle: any;
	public show: boolean = false;
	public measuring: boolean = false;

	constructor(private readonly changeDetector: ChangeDetectorRef) { }

	public ngAfterContentChecked(): void {
		if (this.measuring) {
			const calloutSize = getBoundingClientRectToBody(this.callout.nativeElement);
			const bubbleSize = this.tooltipBubble.measure();
			const documentRect = document.documentElement.getBoundingClientRect();
			const viewport = {
				height: document.documentElement.clientHeight,
				left: -documentRect.left,
				top: -documentRect.top,
				width: document.documentElement.clientWidth
			};

			const bubbleBestFit =
				findPopupBoundsBestFit(calloutSize, bubbleSize, this.alignment || ['top left', 'bottom left', 'middle left'], 8, viewport);

			bubbleBestFit.popup.top -= calloutSize.top;
			bubbleBestFit.popup.left -= calloutSize.left;

			this.bubbleBoundsStyle = rectToStyle(bubbleBestFit.popup);
			this.measuring = false;

			this.changeDetector.markForCheck();
		}
	}

	public showTooltip(): void {
		this._applyTooltipVisibilityChanges(true);
	}

	public hideTooltip(): void {
		this._applyTooltipVisibilityChanges(false);
	}

	public toggleTooltip(): void {
		this._applyTooltipVisibilityChanges(!this.show);
	}

	private _applyTooltipVisibilityChanges(setting: boolean): void {
		this.show = setting;
		this.measuring = setting;
		this.changeDetector.detectChanges();
	}
}
