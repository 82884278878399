﻿import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { BaseService } from './base.service';
import { CookieConsentService } from './cookie-consent.service';

/**
 * @description
 * Represents a service to load and fire Google analytics functions,
 * used by tracking directives.
 */
@Injectable()
export class AnalyticsService {
	private readonly _trackingID: string = 'UA-125411402-2';
	private _isConsented: boolean = false;

	constructor(private readonly _consentService: CookieConsentService, private readonly _baseService: BaseService) {
		this._subscribeToEventBus();
	}

	/**
	 * @description
	 * Sends a pageview to google analytics to be processed.
	 *
	 * @param pageName The page name to be tracked.
	 */
	public trackPage(pageName: string): void {
		if (!this._isConsented || this._baseService.configs.isDevelopment) {
			return;
		}

		this._gtag('config', this._trackingID, {
			anonymize_ip: true,
			page_title: pageName,
			page_path: pageName
		});
	}

	/**
	 * @description
	 * Sends an event to google analytics to be processed.
	 *
	 * @param category The event's category, preferably the type of the thing
	 * being interacted with. Ex: Video
	 * @param action The event's action, preferably the type of interaction
	 * which happened. Ex: Play
	 * @param label The event's Label, optional, preferably an indicator of
	 * the specific thing being interacted with. Ex: PokemonGoTrailer.mp4
	 * @param value The event's value, optional, can be the event's
	 * importance or how much revenue it makes.
	 */
	public trackEvent(category: string, action: string, label: string, value: number): void {
		if (!this._isConsented || this._baseService.configs.isDevelopment) {
			return;
		}

		this._gtag('event', action, {
			event_category: category,
			event_label: label,
			value: value
		});
	}

	/**
	 * @description
	 * Subscribes to the event bus.
	 */
	private _subscribeToEventBus(): void {
		this._consentService
			.isConsented()
			.pipe(filter(consent => consent))
			.subscribe(() => {
				this._isConsented = true;
				this._initService();
			});
	}

	/**
	 * @description
	 * Sets the tracking object if it was not set.
	 * Also creates the default tracker to be used.
	 */
	private _initService(): void {
		if (this._baseService.configs.isDevelopment) {
			return;
		}

		if (window['gtag'] == null) {
			this._loadLibrary();
		}
	}

	/**
	 * @description
	 * Loads google analytics library and sets the tracking object
	 * (ga) as a method inside var window.
	 */
	private _loadLibrary(): void {
		const scriptElement: HTMLScriptElement = document.createElement('script');
		scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${this._trackingID}`;
		scriptElement.charset = 'UTF-8';
		scriptElement.async = false;
		document.head.appendChild(scriptElement);

		const win: any = window;
		win.dataLayer = win.dataLayer || [];

		this._gtag('js', new Date());
		this._gtag('config', this._trackingID, { anonymize_ip: true });
	}

	/**
	 * @description
	 * Calls the GTag global function to pass configurations to
	 * Google Anayltics.
	 */
	private _gtag(..._: any[]): void {
		(<any>window).dataLayer.push(arguments);
	}
}
