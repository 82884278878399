import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService, RequestOptionsBuilder } from '@luis/api';
import { APP_VERSION_SERVICE_TOKEN, AppVersion, IAppVersionService } from '@luis/apps';
import {
	BaseService,
	BUS_EVENTS,
	EventBusService,
	GENERIC_CACHE_SERVICE_TOKEN,
	IGenericCacheService,
	IToasterService,
	ITrackerMessage,
	PROGRESS_STATES,
	TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { PersistantPublishSettings } from '@luis/publish-pane';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, flatMap, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs/Rx';
import { ITrainingResultService } from '../interfaces/ITrainingResultService';
import { IntentMetadata } from '../models/intent-metadata.model';
import { IntentTrainingResult } from '../models/intent-training-result.model';
import { FaultyIntent, STATUS_CODES, TrainingResultMetaData, VersionsComparison } from '../models/training-result-metadata.model';

/**
 * @description
 * Represents a concrete implementation of the ITrainingResultService interface for training result related
 * operations for a LUIS application. This implementation is meant for production use.
 */
@Injectable()
export class TrainingResultService implements ITrainingResultService, OnDestroy {
	private readonly _thresholdOfIntentsWithLowUtterances: number = 95;
	private readonly _thresholdOfIntentsWithHighUtterances: number = 98;
	private readonly _alpha: number = 1;
	private _minThreshold: number = 10;
	private _paths: string[] = [];

	private _trainOccuredSubscription: Subscription = new Subscription();
	private _trainingDateSubscription: Subscription = new Subscription();
	private _serviceStatusSubscription: Subscription = new Subscription();
	private _publishOccuredSubscription: Subscription = new Subscription();
	private _versions: Observable<AppVersion[]>;
	private _serviceStatus$: Observable<STATUS_CODES>;
	private _trainingTracker: Observer<ITrackerMessage>;
	private _trainingStatus: boolean = false;
	private _publishPath: string;

	private readonly _trainingDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
	private readonly _serviceStatus: BehaviorSubject<STATUS_CODES> = new BehaviorSubject<STATUS_CODES>(STATUS_CODES.NOT_STARTED);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _baseService: BaseService,
		private readonly _eventBus: EventBusService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _versionService: IAppVersionService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _genericCacheService: IGenericCacheService
	) {}

	public ngOnDestroy(): void {
		this._trainOccuredSubscription.unsubscribe();
		this._trainingDateSubscription.unsubscribe();
		this._serviceStatusSubscription.unsubscribe();
		this._publishOccuredSubscription.unsubscribe();

		if (this._trainingTracker) {
			this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
		}
	}

	public getVersions(): Observable<AppVersion[]> {
		if (!this._versions) {
			this._versions = this._versionService.get(0, null, true, true).pipe(
				map(page => page.data),
				shareReplay()
			);
		}

		return this._versions;
	}

	public getPublishSetting(isStaging: boolean = false): Observable<PersistantPublishSettings> {
		this._publishPath = `${this._baseService.configs.apiUrl}/apps/${
			this._baseService.configs.appId
		}/publishsettings?isStaging=${isStaging}`;

		return this._genericCacheService.get(
			this._publishPath,
			PersistantPublishSettings.importFromApi,
			this._baseService.defaultOptionsBuilder.build()
		);
	}

	public getLastTrainingDate(versionId?: string): Observable<Date> {
		return this._trainingDate.asObservable().distinctUntilChanged();
	}

	public getStatus(): Observable<STATUS_CODES> {
		return this.getLastTrainingDate().pipe(
			filter(trained => trained !== null),
			tap(() => {
				if (!this._trainingStatus) {
					this._getStatusAsync();
				}
			}),
			flatMap(() => this._serviceStatus$)
		);
	}

	/**
	 * @description
	 * Gets the last training result of the application.
	 *
	 * @returns An observable of the application's last training result.
	 */
	public getMetaData(version?: string): Observable<TrainingResultMetaData> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		return this.getLastTrainingDate()
			.filter(date => date !== null)
			.flatMap(() => this._serviceStatus$)
			.filter(i => i === STATUS_CODES.COMPLETED)
			.flatMap(() => {
				this._paths.push(`${this._baseUrl}/${version || this._baseService.configs.versionId}/statsmetadata`);

				return this._genericCacheService
					.get(
						`${this._baseUrl}/${version || this._baseService.configs.versionId}/statsmetadata`,
						TrainingResultMetaData.importFromApi,
						builder.build()
					)
					.do(trainingReult => (trainingReult.version = this._baseService.configs.versionId));
			});
	}

	/**
	 * @description
	 * Gets the differences between the two training results of the current version and the given trained version.
	 *
	 * @param version The version id.
	 * @returns The differences array.
	 */
	public compareWithVersion(version: string): Observable<VersionsComparison> {
		return Observable.combineLatest(this.getMetaData(), this.getMetaData(version)).map(([current, compareWith]) => {
			const currentCorrect = (current.numCorrectUtterances * 100) / current.numUtterances;
			const currentUnclear = (current.numAmbiguousUtterances * 100) / current.numUtterances;
			const currentIncorrect = (current.numIncorrectUtterances * 100) / current.numUtterances;
			const compareWithCorrect = (compareWith.numCorrectUtterances * 100) / compareWith.numUtterances;
			const compareWithUnclear = (compareWith.numAmbiguousUtterances * 100) / compareWith.numUtterances;
			const compareWithIncorrect = (compareWith.numIncorrectUtterances * 100) / compareWith.numUtterances;

			return {
				correct: Number((currentCorrect - compareWithCorrect).toFixed(1)),
				unclear: Number((currentUnclear - compareWithUnclear).toFixed(1)),
				incorrect: Number((currentIncorrect - compareWithIncorrect).toFixed(1)),
				numIntents: current.numIntents - compareWith.numIntents,
				numEntities: current.numEntities - compareWith.numEntities,
				numUtterances: current.numUtterances - compareWith.numUtterances
			};
		});
	}

	public getIntentsWithLowNumOfUtterances(): Observable<IntentMetadata[]> {
		return this.getMetaData().map(tR => {
			// Some calculations to get the bounds of the balanced intent.
			const mean = tR.numUtterances / tR.numIntents;
			const intents = Array.from(tR.utterancesPerIntent.values()).sort((a, b) => (a.numUtterances < b.numUtterances ? -1 : 1));
			const standardDeviation = this._getStandardDeviation(intents, mean, tR.numIntents);
			this._minThreshold = Math.min(this._minThreshold, mean);
			const minUnbalancedThreshold = Math.max(this._minThreshold, mean - this._alpha * standardDeviation);

			// Filter the intents with respect to the claculated bounds.
			return intents.filter(
				intent =>
					intent.correctAccuracy < this._thresholdOfIntentsWithLowUtterances && intent.numUtterances < minUnbalancedThreshold
			);
		});
	}

	public getIntentsWithHighNumOfUtterances(): Observable<IntentMetadata[]> {
		return this.getMetaData().map(tR => {
			// Some calculations to get the bounds of the balanced intent.
			const mean = tR.numUtterances / tR.numIntents;
			const intents = Array.from(tR.utterancesPerIntent.values()).sort((a, b) => (a.numUtterances > b.numUtterances ? -1 : 1));
			const standardDeviation = this._getStandardDeviation(intents, mean, tR.numIntents);
			const maxUnbalancedThreshold = mean + this._alpha * standardDeviation;

			// Filter the intents with respect to the claculated bounds.
			return intents.filter(
				intent =>
					intent.correctAccuracy < this._thresholdOfIntentsWithHighUtterances && intent.numUtterances > maxUnbalancedThreshold
			);
		});
	}

	public getAmbiguousIntents(): Observable<IntentMetadata[]> {
		return this.getMetaData().map(tR =>
			Array.from(tR.utterancesPerIntent.values())
				.filter(i => i.unclearAccuracy !== 0)
				.sort((a, b) => (a.unclearAccuracy < b.unclearAccuracy ? 1 : -1))
		);
	}

	public getIncorrectIntents(): Observable<IntentMetadata[]> {
		return this.getMetaData().map(tR =>
			Array.from(tR.utterancesPerIntent.values())
				.filter(i => i.incorrectAccuracy !== 0)
				.sort((a, b) => (a.incorrectAccuracy < b.incorrectAccuracy ? 1 : -1))
		);
	}

	public getIntentsMetaData(): Observable<IntentMetadata[]> {
		return this.getMetaData().map(tR =>
			Array.from(tR.utterancesPerIntent.values()).sort((a, b) => (a.numUtterances < b.numUtterances ? -1 : 1))
		);
	}

	public getFaultyUtterancesPerIntent(intentId: string): Observable<IntentTrainingResult> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		return this._serviceStatus$
			.filter(i => i === STATUS_CODES.COMPLETED)
			.flatMap(() => {
				this._paths.push(`${this._baseUrl}/${this._baseService.configs.versionId}/intents/${intentId}/stats`);

				return this._genericCacheService.get(
					`${this._baseUrl}/${this._baseService.configs.versionId}/intents/${intentId}/stats`,
					IntentTrainingResult.importFromApi,
					builder.build()
				);
			});
	}

	public getAmbiguousWith(intentId: string): Observable<FaultyIntent[]> {
		return this.getFaultyUtterancesPerIntent(intentId).map(intent => {
			const map: Map<string, [string, number]> = new Map<string, [string, number]>();
			Array.from(intent.ambiguousUtterances.values()).forEach(u => {
				if (!map.has(u.ambiguousIntents[0].id)) {
					map.set(u.ambiguousIntents[0].id, [u.ambiguousIntents[0].name, 0]);
				}
				map.set(u.ambiguousIntents[0].id, [u.ambiguousIntents[0].name, map.get(u.ambiguousIntents[0].id)[1] + 1]);
			});

			return Array.from(map.entries())
				.map(e => {
					return { name: e[1][0], id: e[0], utterancesCount: e[1][1] };
				})
				.sort((a, b) => (a.utterancesCount < b.utterancesCount ? 1 : -1));
		});
	}

	public getIncorrectWith(intentId: string): Observable<FaultyIntent[]> {
		return this.getFaultyUtterancesPerIntent(intentId).map(intent => {
			const map: Map<string, [string, number]> = new Map<string, [string, number]>();
			Array.from(intent.incorrectUtterances.values()).forEach(u =>
				u.incorrectIntents.forEach(i => {
					if (!map.has(i.id)) {
						map.set(i.id, [i.name, 0]);
					}
					map.set(i.id, [i.name, map.get(i.id)[1] + 1]);
				})
			);

			return Array.from(map.entries())
				.map(e => {
					return { name: e[1][0], id: e[0], utterancesCount: e[1][1] };
				})
				.sort((a, b) => (a.utterancesCount < b.utterancesCount ? 1 : -1));
		});
	}

	public initService(): void {
		this._baseService.configsAsync
			.pipe(
				filter(configs => Boolean(configs.appId) && Boolean(configs.versionId)),
				first(),
				flatMap(configs =>
					this._httpService
						.get<Date>(`${this._baseUrl}/${configs.versionId}/state`, this._baseService.defaultOptionsBuilder.build())
						.do(date => this._trainingDate.next(date !== null ? new Date(date) : null))
				)
			)
			.subscribe();

		this._serviceStatus$ = this._baseService.configsAsync.pipe(
			filter(configs => Boolean(configs.appId) && Boolean(configs.versionId)),
			flatMap(() => this._trainingDate),
			filter(date => date !== null),
			flatMap(() => this._serviceStatus.distinctUntilChanged()),
			startWith(null)
		);

		this._serviceStatusSubscription = this._serviceStatus$
			.filter(status => status !== null)
			.subscribe(status => {
				if (status === STATUS_CODES.COMPLETED) {
					this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
					this._trainingTracker = this._toasterService.add({
						startMsg: this._i18n.instant('training.training-result-service.stats_loaded')
					});
					this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
				} else if (status === STATUS_CODES.RUNNING) {
					this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
					this._trainingTracker = this._toasterService.add({
						startMsg: this._i18n.instant('training.training-result-service.loading_stats')
					});
				} else if (status === STATUS_CODES.NOT_STARTED) {
					this.getStatus();
				}
			});
		this._subscribeToEventBus();
	}

	public resetService(): void {
		this._serviceStatus.next(STATUS_CODES.NOT_STARTED);
		this._trainingDate.next(null);
	}

	private _getStatusAsync(version?: string): void {
		if (this._serviceStatus.getValue() === STATUS_CODES.NOT_STARTED) {
			this._trainingStatus = true;
			if (this._trainingTracker) {
				this._trainingTracker.next({ state: PROGRESS_STATES.ENDED });
			}
			this._trainingTracker = this._toasterService.add({
				startMsg: this._i18n.instant('training.training-result-service.loading_stats')
			});
			this._trainingDateSubscription = this.getLastTrainingDate()
				.pipe(
					filter(date => date !== null),
					flatMap(() =>
						this._httpService.post(
							`${this._baseUrl}/${version || this._baseService.configs.versionId}/stats`,
							{},
							this._baseService.defaultOptionsBuilder.build(),
							true
						)
					),
					flatMap(response => {
						let path = `${(<HttpResponse<{}>>response).headers.get('operation-location')}`;
						if (!this._baseService.configs.isDevelopment) {
							path = path.replace('/api/', '/webapi/');
						}

						return this._handleTrainingResultStatus(0, path);
					})
				)
				.subscribe();
		}
	}

	/**
	 * @description
	 * Gets the base web url for manipulating last training result.
	 *
	 * @returns The base url for last training result manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/versions`;
	}

	/**
	 * @description
	 * Subscribes to event bus to react to certain bus events.
	 */
	private _subscribeToEventBus(): void {
		this._trainOccuredSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.TRAIN_OCCURRED, () => {
			this._invalidateCache();
			this._trainingStatus = false;
			this._serviceStatus.next(STATUS_CODES.NOT_STARTED);
			this._baseService.configsAsync
				.pipe(
					filter(configs => Boolean(configs.appId) && Boolean(configs.versionId)),
					first(),
					flatMap(configs =>
						this._httpService
							.get<Date>(`${this._baseUrl}/${configs.versionId}/state`, this._baseService.defaultOptionsBuilder.build())
							.do(date => this._trainingDate.next(date !== null ? new Date(date) : null))
					)
				)
				.subscribe();
		});

		this._publishOccuredSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.PUBLISH_OCCURRED, () => {
			this._genericCacheService.invalidate(this._publishPath);
		});
	}

	private _handleTrainingResultStatus(currentTrial: number, url: string): Observable<number> {
		const trial: number = currentTrial + 1;
		let res: Observable<number> = this._getOperationStatus(url);
		res.subscribe(status => {
			if (status !== STATUS_CODES.COMPLETED && status !== STATUS_CODES.FAILED) {
				setTimeout(() => (res = this._handleTrainingResultStatus(trial, url)), this._getPollTime(trial));
			}
			this._serviceStatus.next(status);
		});

		return res;
	}

	private _invalidateCache(): void {
		this._paths.forEach(p => this._genericCacheService.invalidate(p));
		this._paths = [];
	}

	private _getOperationStatus(url: string): Observable<number> {
		return this._httpService.get(url, this._baseService.defaultOptionsBuilder.build()).map(opStatus => (<any>opStatus).status);
	}

	/**
	 * @description
	 * Gets poll time with exponential backoff.
	 */
	private _getPollTime(trial: number): number {
		const resetCount: number = 13;

		return Math.exp((trial % resetCount) * 0.3) * 1000;
	}

	private _getStandardDeviation(arr: IntentMetadata[], mean: number, total: number): number {
		return Math.sqrt(arr.map(intent => Math.pow(intent.numUtterances - mean, 2)).reduce((sum, value) => sum + value, 0) / total);
	}
}
