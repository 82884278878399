import { QueryList } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import {
    DOWN_ARROW,
    LEFT_ARROW,
    RIGHT_ARROW,
    TAB,
    UP_ARROW
} from '../keyboard/keycodes';

/**
 * @description
 * This interface is for items that can be disabled. The type passed into
 * ListKeyManager must extend this interface.
 */
export interface IDisableable {
    disabled?: boolean;
}

/**
 * @description
 * Represents the allowable focus navigation directions.
 */
export type FocusDirection = 'vertical' | 'horizontal';

/**
 * @description
 * This class manages keyboard events for selectable lists. If you pass it a query list
 * of items, it will set the active item correctly when arrow events occur.
 */
export class ListKeyManager<T extends IDisableable> {
    private _activeItemIndex: number = null;
    private _activeItem: T;
    private _tabOut: Subject<void> = new Subject<void>();
    private _wrap: boolean = false;

    constructor(private _items: QueryList<T>, private _focusDirection: FocusDirection = 'vertical') { }

    /**
     * @description
     * Turns on wrapping mode, which ensures that the active item will wrap to
     * the other end of list when there are no more items in the given direction.
     *
     * @returns The ListKeyManager that the method was called on.
     */
    public withWrap(): this {
        this._wrap = true;

        return this;
    }

    /**
     * @description
     * Sets the active item to the item at the index specified.
     *
     * @param index The index of the item to be set as active.
     */
    public setActiveItem(index: number): void {
        this._activeItemIndex = index;
        this._activeItem = this._items.toArray()[index];
    }

    /**
     * @description
     * Sets the active item depending on the key event passed in.
     *
     * @param event Keyboard event to be used for determining which element should be active.
     * @returns True if the key was handled or false if it was not.
     */
    public onKeydown(event: KeyboardEvent): boolean {
        const nextItemKey = this._focusDirection === 'vertical' ? DOWN_ARROW : RIGHT_ARROW;
        const prevItemKey = this._focusDirection === 'vertical' ? UP_ARROW : LEFT_ARROW;

        switch (event.keyCode) {
            case nextItemKey:
                this.setNextItemActive();
                event.preventDefault();

                return true;
            case prevItemKey:
                this.setPreviousItemActive();
                event.preventDefault();

                return true;
            case TAB:
                // Note that we shouldn't prevent the default action on tab.
                this._tabOut.next(null);
                // Preventing default here so that tab breaks out of current menu
                // on stack and goes back to previously focused item.
                event.preventDefault();

                return true;
            default:
                return false;
        }
    }

    /**
     * @description
     * Returns the index of the currently active item.
     */
    public get activeItemIndex(): number {
        return this._activeItemIndex;
    }

    /**
     * @description
     * Returns the currently active item.
     */
    public get activeItem(): T {
        return this._activeItem;
    }

    /**
     * @description
     * Sets the active item to the first enabled item in the list.
     */
    public setFirstItemActive(): void {
        this._setActiveItemByIndex(0, 1);
    }

    /**
     * @description
     * Sets the active item to the last enabled item in the list.
     */
    public setLastItemActive(): void {
        this._setActiveItemByIndex(this._items.length - 1, -1);
    }

    /**
     * @description
     * Sets the active item to the next enabled item in the list.
     */
    public setNextItemActive(): void {
        this._activeItemIndex === null ? this.setFirstItemActive() : this._setActiveItemByDelta(1);
    }

    /**
     * @description
     * Sets the active item to a previous enabled item in the list.
     */
    public setPreviousItemActive(): void {
        this._activeItemIndex === null && this._wrap ? this.setLastItemActive()
            : this._setActiveItemByDelta(-1);
    }

    /**
     * @description
     * Allows setting of the activeItemIndex without any other effects.
     *
     * @param index The new activeItemIndex.
     */
    public updateActiveItemIndex(index: number): void {
        this._activeItemIndex = index;
    }

    /**
     * @description
     * Observable that emits any time the TAB key is pressed, so
     * components can react when focus is shifted off of the list.
     */
    get tabOut(): Observable<void> {
        return this._tabOut.asObservable();
    }

    /**
     * @description
     * This method sets the active item, given a list of items and the delta between the
     * currently active item and the new active item. It will calculate differently
     * depending on whether wrap mode is turned on.
     */
    private _setActiveItemByDelta(delta: number, items: T[] = this._items.toArray()): void {
        this._wrap ? this._setActiveInWrapMode(delta, items)
            : this._setActiveInDefaultMode(delta, items);
    }

    /**
     * @description
     * Sets the active item properly given "wrap" mode. In other words, it will continue to move
     * down the list until it finds an item that is not disabled, and it will wrap if it
     * encounters either end of the list.
     */
    private _setActiveInWrapMode(delta: number, items: T[]): void {
        // when active item would leave menu, wrap to beginning or end
        this._activeItemIndex =
            (this._activeItemIndex + delta + items.length) % items.length;

        // skip all disabled menu items recursively until an enabled one is reached
        if (items[this._activeItemIndex].disabled) {
            this._setActiveInWrapMode(delta, items);
        } else {
            this.setActiveItem(this._activeItemIndex);
        }
    }

    /**
     * @description
     * Sets the active item properly given the default mode. In other words, it will
     * continue to move down the list until it finds an item that is not disabled. If
     * it encounters either end of the list, it will stop and not wrap.
     */
    private _setActiveInDefaultMode(delta: number, items: T[]): void {
        this._setActiveItemByIndex(this._activeItemIndex + delta, delta, items);
    }

    /**
     * @description
     * Sets the active item to the first enabled item starting at the index specified. If the
     * item is disabled, it will move in the fallbackDelta direction until it either
     * finds an enabled item or encounters the end of the list.
     */
    private _setActiveItemByIndex(index: number, fallbackDelta: number, items: T[] = this._items.toArray()): void {
        let iterator: number = index;

        if (!items[iterator]) {
            return;
        }

        while (items[iterator].disabled) {
            iterator += fallbackDelta;

            if (!items[iterator]) {
                return;
            }
        }

        this.setActiveItem(iterator);
    }
}
