import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResource } from '../../interfaces/models/IResource';
import { IPageInfo } from '../../models/caches/paginated-cache.model';
import { CHUNKING_PROCESS_TYPE } from '../../models/utils/chunking-process-payload.model';
import { PaginationResourceCacheService } from '../caches/pagination-resource-cache.service';
import { BaseService } from './base.service';
import { ChunkingService } from './chunking.service';
import { EventBusService } from './event-bus.service';

@Injectable()
export class ResourceChunkingService extends ChunkingService<IResource> {
	protected chunkingType: CHUNKING_PROCESS_TYPE = CHUNKING_PROCESS_TYPE.RESOURCE;

	constructor(
		eventBusService: EventBusService,
		paginationResourceCacheService: PaginationResourceCacheService<IResource>,
		private readonly _baseService: BaseService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService
	) {
		super(eventBusService, paginationResourceCacheService);
	}

	protected fetchData(modelName: string, { skip, take }: IPageInfo, parser: (respose: any) => IResource): Observable<any> {
		const basePath = this._getBaseUrl(modelName);

		return this._httpService
			.get<Object[]>(`${basePath}?skip=${skip}&take=${take}`, this._baseService.defaultOptionsBuilder.build())
			.pipe(map(data => data.map(parser)));
	}

	/**
	 * @description
	 * Gets the base web url for manipulating apps.
	 *
	 * @returns The base url for apps manipulation.
	 */
	private _getBaseUrl(modelName: string): string {
		return `${this._baseService.configs.apiUrl}/${modelName}`;
	}
}
