import { IResource } from '../../interfaces/models/IResource';

/**
 * @description
 * Represents a base class for a LUIS model. A model is either an intent or an entity.
 *
 * @param id The id of the model.
 * @param name The name of the model.
 * @param type The type id of the model.
 */
export class LuisModel implements IResource {
    public id: string;
    public name: string;
    public type: number;

    constructor(id: string = '', name: string = '', type: number = 0) {
        this.id = id;
        this.name = name.split('').map(c => c.charCodeAt(0) < 32 ? '' : c).join('');
        this.type = type;
    }

    /**
     * @method
     * @description
     * Checks if the model is new/empty or not.
     *
     * @returns True if model is empty and false otherwise.
     */
    public isEmpty(): boolean {
        return !this.id.length && !this.name.length;
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            id: this.id,
            name: this.name
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): LuisModel {
        return new LuisModel(
            this.id.slice(),
            this.name.slice(),
            this.type
        );
    }
}
