﻿import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import {
    App,
    APP_SERVICE_TOKEN,
    APP_VERSION_SERVICE_TOKEN,
    AppVersion,
    IAppService,
    IAppVersionService
} from '@luis/apps';
import { Observable } from 'rxjs/Rx';
import { AppRouteService } from '../../common/services/app-route.service';

@Injectable()
export class ApplicationResolve implements Resolve<App> {
    constructor(
        private _router: Router,
        private _routeService: AppRouteService,
        @Inject(APP_SERVICE_TOKEN) private _appService: IAppService,
        @Inject(APP_VERSION_SERVICE_TOKEN) private _versionService: IAppVersionService
    ) { }

    /**
     * @method
     * @description
     * Checks that the given app with the given version exists in the account.
     *
     * @param route The current route.
     * @returns An observable of the app resolved.
     */
    public resolve(route: ActivatedRouteSnapshot): Observable<App> {
        const appId: string = route.params['appId'];
        const versionId: string = route.params['versionId'];

        return this._appService.getSingle(appId)
            .first()
            .switchMap(app => (app ? this._getCurrentVersion(app, versionId) : Observable.of(null)).map(version => [app, version]))
            .do(([app, version]) => version ? app.activeVersion = version.id : null)
            .map(([app, version]) => version ? app : null)
            .do(app => app ? app : this._router.navigate([this._routeService.builder().apps().build()]));
    }

    /**
     * @description
     * Gets the current app version either from the default
     * fetched versions or from an explicit version call.
     *
     * @param app The app to get the versions for.
     * @param versionId The version id to search for.
     */
    private _getCurrentVersion(app: App, versionId: string): Observable<AppVersion> {
        return this._versionService.getVersion(versionId, app).first();
    }
}
