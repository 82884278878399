import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LuisModel } from '@luis/core';
import { AxisData, DatasetResultsGraphingService, IPointClickInput } from '@luis/ui';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IDatasetDto } from '../../../../interfaces/IDatasetDto';
import { IModelConfusionAggregate } from '../../../../interfaces/IModelConfusionAggregate';
import { IYAxisData } from '../../../../interfaces/IYAxisData';
import { CONFUSION_TYPE } from '../../../../models/entity-confusion.model';

/**
 * @description
 * Represents the results of the batch testing dataset. Displays graphical
 * data, statistical data, and detailed utterance data.
 */
@Component({
	selector: 'results-graph',
	templateUrl: 'results-graph.component.html',
	styleUrls: ['results-graph.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsGraphComponent implements OnInit, OnDestroy {
	@Input() public datasetDto: IDatasetDto;
	@Input() public activeModel: Observable<LuisModel>;
	@Output() public confusionTypeClicked: EventEmitter<CONFUSION_TYPE> = new EventEmitter<CONFUSION_TYPE>();

	public xData: Observable<AxisData>;
	public yData: Observable<AxisData>;
	public graphResetter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public confusionTypes: typeof CONFUSION_TYPE = CONFUSION_TYPE;

	private _modelSubscription: Subscription = new Subscription();

	constructor(private readonly _datasetGraphingService: DatasetResultsGraphingService) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._modelSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Shows the utterances for the current active model for the
	 * given confsuion type.
	 *
	 * @param type The confusion type to show utterances for.
	 */
	public onConfusionClick(type: CONFUSION_TYPE): void {
		this.confusionTypeClicked.emit(type);

		Observable.combineLatest(this.activeModel, this.datasetDto.scorer.modelConfusionAggregates)
			.first()
			.map(([model, map]) => map.get(model.id))
			.filter(results => results !== undefined)
			.map(results =>
				Array.from(results.utteranceScores.keys()).filter(
					id => results.utteranceScores.get(id).filter(uS => uS.confusion === type).length > 0
				)
			)
			.subscribe(ids => this._datasetGraphingService.setIds(ids));
	}

	/**
	 * @description
	 * Invokes onGraphPointClicked function on the DatasetGraphingService
	 */
	public onPointClick({ id, xValue }: IPointClickInput): void {
		this._datasetGraphingService.onGraphPointClicked(id, xValue);
		this.confusionTypeClicked.emit(null);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this._modelSubscription = Observable.combineLatest(this.activeModel, this.datasetDto.scorer.modelConfusionAggregates)
			.filter(([model]) => model !== null)
			.do(([model, confusions]) => this._generateAxisData(confusions.get(model.id)))
			.do(() => this._datasetGraphingService.reset())
			.do(() => this.graphResetter.next(false))
			.do(() => setTimeout(() => this.graphResetter.next(true), 0))
			.subscribe();
	}

	/**
	 * @description
	 * Generates axis data based on the results.
	 *
	 * @param model The luis model to plot the results for.
	 */
	private _generateAxisData(confusionData: IModelConfusionAggregate): void {
		const step: number = 1;
		const stepIndex: Map<CONFUSION_TYPE, number> = new Map<CONFUSION_TYPE, number>();
		const xData: number[] = [];
		const yData: IYAxisData[] = [];

		if (confusionData === undefined) {
			this.xData = Observable.of(new AxisData('XData', xData, false));
			this.yData = Observable.of(new AxisData('Score', yData, false));

			return;
		}

		stepIndex.set(CONFUSION_TYPE.TRUE_POSITIVE, 1);
		stepIndex.set(CONFUSION_TYPE.TRUE_NEGATIVE, 1);
		stepIndex.set(CONFUSION_TYPE.FALSE_POSITIVE, 1);
		stepIndex.set(CONFUSION_TYPE.FALSE_NEGATIVE, 1);

		Array.from(confusionData.utteranceScores.keys()).map(id => {
			confusionData.utteranceScores.get(id).forEach(data => {
				let yScore: number = data.score;

				switch (data.confusion) {
					case CONFUSION_TYPE.TRUE_POSITIVE:
						xData.push(step * stepIndex.get(CONFUSION_TYPE.TRUE_POSITIVE) * -1);
						stepIndex.set(CONFUSION_TYPE.TRUE_POSITIVE, stepIndex.get(CONFUSION_TYPE.TRUE_POSITIVE) + 1);
						break;
					case CONFUSION_TYPE.TRUE_NEGATIVE:
						yScore = yScore - 1;
						xData.push(step * stepIndex.get(CONFUSION_TYPE.TRUE_NEGATIVE) * 1);
						stepIndex.set(CONFUSION_TYPE.TRUE_NEGATIVE, stepIndex.get(CONFUSION_TYPE.TRUE_NEGATIVE) + 1);
						break;
					case CONFUSION_TYPE.FALSE_POSITIVE:
						xData.push(step * stepIndex.get(CONFUSION_TYPE.FALSE_POSITIVE) * 1);
						stepIndex.set(CONFUSION_TYPE.FALSE_POSITIVE, stepIndex.get(CONFUSION_TYPE.FALSE_POSITIVE) + 1);
						break;
					case CONFUSION_TYPE.FALSE_NEGATIVE:
						yScore = yScore - 1;
						xData.push(step * stepIndex.get(CONFUSION_TYPE.FALSE_NEGATIVE) * -1);
						stepIndex.set(CONFUSION_TYPE.FALSE_NEGATIVE, stepIndex.get(CONFUSION_TYPE.FALSE_NEGATIVE) + 1);
						break;
					default:
				}

				yData.push({ id: id, score: yScore, xValue: xData[xData.length - 1] });
			});
		});

		this.xData = Observable.of(new AxisData('XData', xData, false));
		this.yData = Observable.of(new AxisData('Score', yData, false));
	}
}
