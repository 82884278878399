import { IResource, LuisModel } from '@luis/core';

/**
 * @description
 * An enumeration of the type of intents that can be in a system.
 */
export enum INTENT_TYPES {
    SIMPLE = 0,
    DOMAIN = 100
}

/**
 * @description
 * Represents a LUIS intent.
 *
 * @param id The intent unique identifier.
 * @param name The intent name.
 * @param labelCount The number of labeled utterances labeled as this intent.
 */
export class Intent extends LuisModel implements IResource {
    constructor(
        public id: string = '',
        public name: string = '',
        public labelCount: number = 0,
        public type: number = INTENT_TYPES.SIMPLE,
        public domainName: string = null,
        public originalIntentName: string = null
    ) {
        super(id, name, type);
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): Intent {
        return new Intent(
            apiObject.id,
            apiObject.name,
            0,
            INTENT_TYPES.SIMPLE,
            apiObject.customPrebuiltDomainName ? apiObject.customPrebuiltDomainName : null,
            apiObject.customPrebuiltModelName ? apiObject.customPrebuiltModelName : null
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return this.domainName === null ? {
            name: this.name
        } : {
            name: this.name,
            modelName: this.originalIntentName,
            domainName: this.domainName
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): Intent {
        return new Intent(
            this.id.slice(),
            this.name.slice(),
            this.labelCount,
            this.type,
            this.domainName ? this.domainName.slice() : null,
            this.originalIntentName ? this.originalIntentName.slice() : null
        );
    }

    /**
     * @description
     * Returns the unique name of the intent.
     *
     * @return the unique name.
     */
    public get uniqueName(): string {
        return this.domainName ? `${this.domainName}.${this.originalIntentName}` : this.name;
    }
}
