import { Entity } from './entity.model';
import { UtteranceEntity } from './utterance-entity.model';

/**
 * @description
 * An enumeration of the allowable segment types in a labelable utterance area.
 */
export enum SEGMENT_TYPES {
    PLAIN,
    ENTITY,
    COMPOSITE
}

/**
 * @description
 * Reperesents a plain segment class. A segment is a labelable part of a labelable utterance.
 * It is a collection of one or more words that can be labeled or manipulated to add/edit/delete
 * utterance entities. A labelable utterance is formed of one or more segments.
 *
 * @param text The tokens of text that are included in this segment.
 * @param start The start index of this segment relative to the tokenized text.
 * @param end The end index of this segment relative to the tokenized text.
 * @param type The segment type. The value should be of SEGMENT_TYPES enum.
 */
export class Segment {
    public text: string[];
    public start: number;
    public end: number;
    public type: number;
    public predictions: UtteranceEntity[];

    constructor(text: string[], start: number, end: number, type: number = SEGMENT_TYPES.PLAIN, predictions: UtteranceEntity[] = []) {
        this.text = text;
        this.start = start;
        this.end = end;
        this.type = type;
        this.predictions = predictions;
    }

    public clone(): Segment {
        return new Segment(
            this.text.map(t => t.slice()),
            this.start,
            this.end,
            this.type,
            this.predictions.map(p => p.clone())
        );
    }
}

/**
 * @description
 * Reperesents an entity segment class. An entity segment is an already labeled segment
 * of a labelable utterance.
 *
 * @param entity The entity name of the labeled entity of this segment.
 */
export class EntitySegment extends Segment {
    public entity: Entity;
    public role: string;

    constructor(text: string[], start: number, end: number, entity: Entity, predictions: UtteranceEntity[] = [], role: string) {
        super(text, start, end, SEGMENT_TYPES.ENTITY, predictions);
        this.entity = entity;
        this.role = role;
    }

    public clone(): EntitySegment {
        return new EntitySegment(
            this.text.map(t => t.slice()),
            this.start,
            this.end,
            this.entity.clone(),
            this.predictions.map(p => p.clone()),
            this.role.slice()
        );
    }
}

/**
 * @description
 * Reperesents a composite segment class. A composite entity segment is an already labeled segment
 * of a labelable utterance that is labeled as a composite entity.
 *
 * @param composite The composite entity name of the labeled entity of this segment.
 * @param children The children segments of this composite entity.
 */
export class CompositeSegment extends Segment {
    public composite: Entity;
    public children: Segment[];
    public role: string;

    constructor(text: string[], start: number, end: number, composite: Entity, children: Segment[] = [], role: string) {
        super(text, start, end, SEGMENT_TYPES.COMPOSITE);
        this.composite = composite;
        this.children = children;
        this.role = role;
    }

    public clone(): CompositeSegment {
        return new CompositeSegment(
            this.text.map(t => t.slice()),
            this.start,
            this.end,
            this.composite.clone(),
            this.children.map(c => c.clone()),
            this.role.slice()
        );
    }
}
