import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    InjectionToken,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    BaseModalComponent,
    IModalComponent,
    ProgressTracker
} from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import * as Rx from 'rxjs/Rx';
import { IKeyStoreService, KEY_STORE_SERVICE_TOKEN } from '../../../interfaces/IKeyStoreService';
import { EndpointKey } from '../../../models/azure-key.model';
import { Subscription } from '../../../models/subscription.model';
import { Tenant } from '../../../models/tenant.model';

export const NEW_KEY_MODAL_COMP_TOKEN: InjectionToken<string> = new InjectionToken('KeyCreationModalComponent');

@Component({
    selector: 'key-selector',
    templateUrl: 'key-selector.component.html',
    styleUrls: ['key-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeySelectorComponent extends BaseModalComponent implements OnInit, OnDestroy, IModalComponent<EndpointKey> {
    @Input() public response: Subject<EndpointKey> = new EventEmitter<EndpointKey>();

    public keyForm: FormGroup;
    public tenants: Observable<Tenant[]>;
    public subscriptions: Observable<Subscription[]>;
    public keys: Observable<EndpointKey[]>;
    public selectedKey: Observable<EndpointKey>;
    public isKeyReady: Observable<boolean>;

    public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
        property: 'name', type: SORT_TYPE.ASCENDING
    });

    private _subscriptionsSubscription: Rx.Subscription = new Rx.Subscription();
    private _keysSubscription: Rx.Subscription = new Rx.Subscription();

    constructor(
        private _formBuilder: FormBuilder,
        @Inject(KEY_STORE_SERVICE_TOKEN) private _keyStoreService: IKeyStoreService
    ) { super(); }

    public ngOnInit(): void {
        super.ngOnInit();
        this._initState();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this._subscriptionsSubscription.unsubscribe();
        this._keysSubscription.unsubscribe();
    }

    /**
     * @description
     * Gets the key string for the selected key.
     */
    public submitKey(): void {
        const currentKey: string = this.keyForm.get('key').value;

        this._keyStoreService.getKeyById(currentKey)
            .subscribe(key => {
                this.response.next(key);
                this.response.complete();
            });
    }

    /**
     * @description
     * Initializes the component state.
     */
    private _initState(): void {
        this._createForm();

        this.tenants = this._keyStoreService.getTenants();

        this.subscriptions = this.keyForm.get('tenant').valueChanges
            .filter(v => v !== '')
            .flatMap(tenantId => this._keyStoreService.getTenantSubscriptions(tenantId));

        this.keys = this.keyForm.get('subscription').valueChanges
            .filter(v => v !== '')
            .flatMap(id => this._keyStoreService.getSubscriptionKeys(id));

        this.selectedKey = this.keyForm.get('key').valueChanges
            .flatMap(keyId => this._keyStoreService.getKeyById(keyId))
            .shareReplay();

        this.isKeyReady = this.keyForm.get('key').statusChanges.map(s => s === 'VALID');
    }

    /**
     * @description
     * Initializes the form group.
     */
    private _createForm(): void {
        this.keyForm = this._formBuilder.group({
            tenant: ['', Validators.required],
            subscription: [{ value: '', disabled: true }, Validators.required],
            key: [{ value: '', disabled: true }, Validators.required],
            regionTimeZone: [{ value: '', disabled: true }, Validators.required]
        });

        this._subscriptionsSubscription = this._createSubscription('tenant', 'subscription');

        this._keysSubscription = this._createSubscription('subscription', 'key');
    }

    /**
     * @description
     * Creates a subscription between a source and dependant target that
     * disables or enables the target based on the value of the dependant
     * in the key form.
     *
     * @param source The source input control name
     * @param target The target input control name
     * @returns A subscription object to cleanup on destroy.
     */
    private _createSubscription(source: string, target: string): Rx.Subscription {
        return this.keyForm.get(source).valueChanges.subscribe(v => {
            if (v && v !== '') {
                this.keyForm.get(target).enable();
            }
            else {
                this.keyForm.get(target).disable();
            }
        });
    }
}
