import { Inject, Injectable } from '@angular/core';
import { CUSTOM_HEADERS, HTTP_SERVICE_TOKEN, HttpService, IRequestOptions } from '@luis/api';
import { ISortPipeProps } from '@luis/ui';
import { Observable, of } from 'rxjs';
import { first, flatMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { Id, IResource } from '../../interfaces/models/IResource';
import { IPage, IPageInfo, PaginatedCache } from '../../models/caches/paginated-cache.model';
import { CHUNKING_PROCESS_TYPE } from '../../models/utils/chunking-process-payload.model';
import { BaseService } from '../utils/base.service';
import { DirtyBitService } from '../utils/dirty-bit.service';
import { EventBusService } from '../utils/event-bus.service';
import { ResourceCountService } from '../utils/resource-count.service';
import { PaginationCacheService } from './pagination-cache.service';

@Injectable()
export class PaginationResourceCacheService<T extends IResource> extends PaginationCacheService<T> {
	protected chunkingType: CHUNKING_PROCESS_TYPE = CHUNKING_PROCESS_TYPE.RESOURCE;

	constructor(
		ebService: EventBusService,
		private readonly _resourceCountService: ResourceCountService,
		private readonly _dirtyBitService: DirtyBitService,
		private readonly _baseService: BaseService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: HttpService
	) {
		super(ebService);
	}

	public get(
		modelName: string,
		cachePath: string,
		pageIndex: number,
		getAll: boolean,
		options: IRequestOptions,
		parser: (response: any) => T,
		sortingProps: ISortPipeProps
	): Observable<IPage<T>> {
		const basePath = this._getBaseUrl(modelName);

		return this._resourceCountService.getCount(modelName).pipe(
			first(),
			map(count => new Map([[modelName, count]])),
			tap(labelsCount => this.prepopulateCaches(labelsCount, sortingProps)),
			switchMap(() => super.get(basePath, cachePath, pageIndex, getAll, options, parser, sortingProps))
		);
	}

	public getSingle(
		modelName: string,
		id: string,
		options: IRequestOptions,
		cacheIRequestOptions: IRequestOptions,
		parser: (response: any) => T,
		refresh: boolean = false,
		sortingProps: ISortPipeProps
	): Observable<T> {
		const url: string = `${this._getBaseUrl(modelName)}/${id}/`;
		const getModel$ = this.httpGet(url, modelName, undefined, options).pipe(
			map(parser),
			tap(newModel => this.post(modelName, modelName, newModel, cacheIRequestOptions, response => response, true))
		);

		if (refresh) {
			getModel$.subscribe();
		}

		return this.get(modelName, modelName, 0, true, options, parser, sortingProps).pipe(
			map(({ data }) => data.find(model => model.id === id)),
			switchMap(model => (model === undefined ? getModel$ : of(model)))
		);
	}

	public post(
		modelName: string,
		cachePath: string,
		model: T,
		options: IRequestOptions,
		parser: (response: any) => Id,
		outOfOrder: boolean = false
	): Observable<Id> {
		const basePath = this._getBaseUrl(modelName);
		const cache = this._getCache(cachePath);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);
		const cacheOnly: boolean = options.headers.has(CUSTOM_HEADERS.CACHE_ONLY);

		if (cacheOnly) {
			return of(cache.addOrUpdate(model.clone(), undefined, outOfOrder)).pipe(mapTo(model.id));
		}

		return super
			.post(basePath, cachePath, model, options, parser, outOfOrder)
			.pipe(tap(!skipMarkDirty ? this._markDirty.bind(this) : null));
	}

	public batchPost(
		modelName: string,
		cachePath: string,
		models: T[],
		options: IRequestOptions,
		parser: (response: any) => Id[]
	): Observable<Id[]> {
		const basePath = this._getBaseUrl(modelName);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		return super.batchPost(basePath, cachePath, models, options, parser).pipe(tap(!skipMarkDirty ? this._markDirty.bind(this) : null));
	}

	public replace(modelName: string, cachePath: string, model: T, idToReplace: Id, options: IRequestOptions): Observable<Id> {
		const basePath = this._getBaseUrl(modelName);
		const cache = this._getCache(cachePath);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);
		const cacheOnly: boolean = options.headers.has(CUSTOM_HEADERS.CACHE_ONLY);

		if (cacheOnly) {
			return of(cache.addOrUpdate(model.clone(), idToReplace)).pipe(mapTo(model.id));
		}

		return this.httpPut(basePath, cachePath, model.exportToApi(), options).pipe(
			flatMap(() => this.reflectModelInCaches(cachePath, model.clone(), idToReplace, undefined, cachePath).mapTo(model.id)),
			tap(!skipMarkDirty ? this._markDirty.bind(this) : null)
		);
	}

	public batchDelete(
		modelName: string,
		cachePath: string,
		ids: Id[],
		options: IRequestOptions,
		parser: (response: any) => T
	): Observable<Id[]> {
		const basePath = this._getBaseUrl(modelName);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		return super.batchDelete(basePath, cachePath, ids, options, parser).pipe(tap(!skipMarkDirty ? this._markDirty.bind(this) : null));
	}

	protected httpGet(basePath: string, cachePath: string, pageInfo?: IPageInfo, options?: IRequestOptions): Observable<any> {
		if (pageInfo) {
			return this._httpService.get(`${basePath}?skip=${pageInfo.skip}&take=${pageInfo.take}`, options);
		}

		return this._httpService.get(basePath, options);
	}

	protected httpPost(basePath: string, cachePath: string, model: Object | Object[], options: IRequestOptions): Observable<any> {
		return this._httpService.post(basePath, model, options);
	}

	protected httpPut(basePath: string, cachePath: string, model: Object, options: IRequestOptions): Observable<any> {
		return this._httpService.put(basePath, model, options);
	}

	protected httpDelete(basePath: string, cachePath: string, id: Id, options: IRequestOptions): Observable<any> {
		return this._httpService.delete(`${basePath}/${id}/`, options);
	}

	protected reflectModelInCaches(
		basePath: string,
		model: T,
		idToReplace?: Id,
		outOfOrder?: boolean,
		modelName?: string
	): Observable<void> {
		const cache = this._cacheMap.get(modelName);
		cache.addOrUpdate(model, idToReplace, outOfOrder);

		return of(null);
	}

	protected reflectModelsInCaches(basePath: string, models: T[]): Observable<void> {
		throw new Error('Method not implemented.');
	}

	/**
	 * @description
	 * Gets the base web url for manipulating apps.
	 *
	 * @returns The base url for apps manipulation.
	 */
	private _getBaseUrl(modelName: string): string {
		return `${this._baseService.configs.apiUrl}/${modelName}`;
	}

	/**
	 * @description
	 * Creates empty caches with the total sizes fetched from the backend.
	 */
	protected prepopulateCaches(labelsMap: Map<string, number>, sortingProps: ISortPipeProps): void {
		labelsMap.forEach((count, id) => {
			if (!this._cacheMap.has(id)) {
				this._cacheMap.set(id, new PaginatedCache<T>(PaginationResourceCacheService.PAGE_SIZE, count, sortingProps));
			}
		});
	}

	/**
	 * @description
	 * Marks the current app as dirty.
	 */
	private _markDirty(): void {
		this._dirtyBitService.setDirty(true);
	}
}
