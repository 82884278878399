import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TooltipService } from '../services/tooltip.service';
import { Alignment } from '../utils/popupAlignment';
import { getBoundingClientRectToBody } from '../utils/rectangle';

@Component({
    selector: 'm-tooltip',
    template: `
    <span #tooltip class="m-tooltip" [attr.aria-label]="label ? label : null" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
        <ng-content></ng-content>
    </span>`
})
export class TooltipComponent implements AfterContentInit {
    @Input() alignment: Alignment = 'auto';
    @Input() label: string;
    @Input('tooltip-style') style: 'light' | 'error' | 'normal' = 'normal';
    @Input() show: boolean = false;
    @Input() isVisible: boolean = true;
    @ViewChild('tooltip') target: ElementRef;

    constructor(private tooltipService: TooltipService) { }

    ngAfterContentInit(): void {
        if (this.show) {
            this.showTooltip();
        }
    }

    public onMouseEnter(): void {
        if (this.isVisible) {
            this.showTooltip();
        }
    }

    public onMouseLeave(): void {
        this.tooltipService.hide();
    }

    private showTooltip(): void {
        if (this.label) {
            this.tooltipService.show({
                alignment: this.alignment,
                bounds: getBoundingClientRectToBody(this.target.nativeElement),
                label: this.label,
                style: this.style
            });
        }
    }
}
