import { Inject, Injectable } from '@angular/core';
import { CUSTOM_HEADERS, HTTP_SERVICE_TOKEN, IHttpService, IRequestOptions } from '@luis/api';
import { Observable, Observer } from 'rxjs/Rx';
import { Id, IResource, IResourceApiParser } from '../../interfaces/models/IResource';
import { IResourceCacheService } from '../../interfaces/services/caches/IResourceCacheService';
import { ResourceCache } from '../../models/caches/resource-cache.model';
import { DirtyBitService } from '../utils/dirty-bit.service';

/**
 * @description
 * Represents a concrete implementation for the data service interface. This implementation
 * is meant for production usage.
 */
@Injectable()
export class ResourceCacheService implements IResourceCacheService {
	private _cacheMap: Map<string, ResourceCache>;

	constructor(@Inject(HTTP_SERVICE_TOKEN) private _httpService: IHttpService, private _dirtyBitService: DirtyBitService) {
		this._cacheMap = new Map<string, ResourceCache>();
	}

	/**
	 * @description
	 * Gets the resource with the given path. Caches the result with path as cache key.
	 *
	 * @param path The path to call.
	 * @param parser The callback to use to parse the api response.
	 * @param options Options to pass to the http service.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @returns An observable of IResource objects.
	 */
	public get(path: string, parser: IResourceApiParser, options: IRequestOptions, cachePath?: string): Observable<IResource[]> {
		let cache: ResourceCache;

		if (!this._checkForCache(cachePath ? cachePath : path) || options.headers.has(CUSTOM_HEADERS.FORCE_CACHE_REFRESH)) {
			cache = this._getCache(cachePath ? cachePath : path);
			cache.reset();

			this._httpService
				.get(path, options)
				.map(response => (<any[]>response).map(parser).filter(parsedDatum => parsedDatum !== undefined))
				.subscribe(data => cache.set(<IResource[]>data.filter(d => typeof d !== undefined)), error => console.error(error, path));
		} else {
			cache = this._getCache(cachePath ? cachePath : path);
		}

		return cache.getItems();
	}

	/**
	 * @description
	 * Posts a new resource to the given path. Caches the added object on success.
	 *
	 * @param path The path to call.
	 * @param data The data to add. Must implement IResource interface.
	 * @param options Options to pass to the http service.
	 * @param parser The callback to use to parse the api response. Used when refresh is true.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @param dataToCache Optional root parent resource data. Should be provided if the data
	 * given is a child.
	 * @param cacheResponse Whether to cache the response or not.
	 * @returns An observable of the id of the new resource.
	 */
	public post(
		path: string,
		data: IResource,
		options: IRequestOptions,
		parser?: IResourceApiParser,
		cachePath?: string,
		dataToCache?: IResource,
		cacheResponse?: boolean
	): Observable<Id | void> {
		let cache: ResourceCache;
		const cacheHadExisted: boolean = this._checkForCache(cachePath ? cachePath : path);
		const refresh: boolean = options.headers.has(CUSTOM_HEADERS.FORCE_CACHE_REFRESH);
		const cacheOnly: boolean = options.headers.has(CUSTOM_HEADERS.CACHE_ONLY);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);
		const updaterFunction: Function = response => {
			if (cacheResponse && parser && response !== undefined) {
				cache.add(parser(response));
			} else if (dataToCache) {
				cache.update(dataToCache.clone());
			} else {
				cache.add(data.clone());
			}
		};

		if (cacheOnly && cacheHadExisted) {
			cache = this._getCache(cachePath ? cachePath : path);

			return Observable.of(updaterFunction());
		} else if (cacheOnly) {
			return Observable.of(null);
		} else {
			cache = this._getCache(cachePath ? cachePath : path);
		}

		return this._httpService
			.post(path, data.exportToApi(), options)
			.do(idData => (typeof idData === 'string' || typeof idData === 'number' ? (data.id = idData) : null))
			.do((idData: { id: Id }) => (idData.id !== undefined ? (data.id = idData.id) : null))
			.do(idData => updaterFunction(idData))
			.map(() => data.id)
			.flatMap<any, any>(id => (refresh ? this.refreshSingle(`${path}/${id}`, parser, options, cachePath) : Observable.of(id)))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	/**
	 * @description
	 * Posts an array of new resources to the given path. Caches the added objects on success.
	 *
	 * @param path The path to call.
	 * @param data The data to add. Must implement IResource interface.
	 * @param parser The function to parse the resulting objects.
	 * @param options Options to pass to the http service.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @returns An observable of the id of the new resource.
	 */
	public batchPost(
		path: string,
		data: IResource[],
		parser: IResourceApiParser,
		options: IRequestOptions,
		cachePath?: string
	): Observable<IResource[]> {
		const cache: ResourceCache = this._getCache(cachePath ? cachePath : path);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		return this._httpService
			.post(path, data.map(datum => datum.exportToApi()), options)
			.map(response => (<any[]>response).map(parser))
			.do(parsedData => cache.batchAdd(parsedData))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	/**
	 * @description
	 * Updates an existing resource to the given path. Caches the updated object on success.
	 *
	 * @param path The path to call.
	 * @param data The data to update. Must implement IResource interface.
	 * @param options Options to pass to the http service.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @param dataToCache Optional root parent resource data. Should be provided if the data
	 * given is a child.
	 * @param parser Optional function to parse the resulting objects.
	 * @param cacheResponse Whether to cache the response or not.
	 * @returns An observable indicating completion.
	 */
	public put(
		path: string,
		data: IResource,
		options: IRequestOptions,
		cachePath?: string,
		dataToCache?: IResource,
		parser?: IResourceApiParser,
		cacheResponse?: boolean
	): Observable<void> {
		const cache: ResourceCache = this._getCache(cachePath !== undefined ? cachePath : path);
		const cacheable: IResource = dataToCache ? dataToCache.clone() : data.clone();
		const cacheOnly: boolean = options.headers.has(CUSTOM_HEADERS.CACHE_ONLY);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);
		if (cacheOnly) {
			return Observable.of(cache.update(cacheable));
		}

		return this._httpService
			.put<void>(path, data.exportToApi(), options)
			.do(response => (cacheResponse && parser ? cache.update(parser(response)) : cache.update(cacheable)))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	/**
	 * @description
	 * Updates existing resources to the given path. Caches the updated objects on success.
	 *
	 * @param path The path to call.
	 * @param data The data to update. Must implement IResource interface.
	 * @param options Options to pass to the http service.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @returns An observable indicating completion.
	 */
	public batchPut(path: string, data: IResource[], options: IRequestOptions, cachePath?: string): Observable<void> {
		const cache: ResourceCache = this._getCache(cachePath ? cachePath : path);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);
		const cacheOnly: boolean = options.headers.has(CUSTOM_HEADERS.CACHE_ONLY);

		if (cacheOnly) {
			return Observable.of(cache.batchUpdate(data));
		}

		return this._httpService
			.put<void>(path, data.map(datum => datum.exportToApi()), options)
			.do(() => cache.batchUpdate(data))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	/**
	 * @description
	 * Refreshes the cached item with the result of the get call for a single
	 * resource item. Usually used in tandem with update (PUT, PATCH) requests.
	 *
	 * @param path The path to call.
	 * @param parser The callback to use to parse the api response.
	 * @param options Options to pass to the http service.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @returns An observable of IResource objects.
	 */
	public refreshSingle(path: string, parser: IResourceApiParser, options: IRequestOptions, cachePath?: string): Observable<IResource> {
		const cache: ResourceCache = this._getCache(cachePath ? cachePath : path);

		return this._httpService
			.get(`${path}`, options)
			.map(parser)
			.do(r => cache.update(r));
	}

	/**
	 * @description
	 * Deletes an existing resource from the given path. Deletes the object from cache on success.
	 *
	 * @param path The path to call.
	 * @param id The id of the resource to delete.
	 * @param options Options to pass to the http service.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @param dataToCache Optional root parent resource data. Should be provided if the data
	 * given is a child.
	 * @param bodyData Optional data to be sent in the request body.
	 * @returns An observable indicating completion.
	 */
	public delete(
		path: string,
		id: Id,
		options: IRequestOptions,
		cachePath?: string,
		dataToCache?: IResource,
		bodyData: Object = null
	): Observable<void> {
		const cache: ResourceCache = this._getCache(cachePath ? cachePath : path);
		const cacheOnly: boolean = options.headers.has(CUSTOM_HEADERS.CACHE_ONLY);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		if (cacheOnly) {
			return Observable.of(cache.delete(id));
		}

		return this._httpService
			.request('DELETE', path, bodyData, options)
			.map($ => (dataToCache ? cache.update(dataToCache) : cache.delete(id)))
			.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
	}

	/**
	 * @description
	 * Batch deletes the given items. Deletes the items from the cache on success.
	 *
	 * @param path  The path to call.
	 * @param items The items to delete.
	 * @param options Options to pass to the http service.
	 * @param cachePath An optional cache path that overrides the path parameter
	 * as key to the cache store.
	 * @returns The ids of the successfully deleted items.
	 */
	public batchDelete(path: string, items: Id[], options: IRequestOptions, batchDelete: boolean, cachePath?: string): Observable<Id[]> {
		const cache: ResourceCache = this._getCache(cachePath ? cachePath : path);
		const cacheOnly: boolean = options.headers.has(CUSTOM_HEADERS.CACHE_ONLY);
		const skipMarkDirty: boolean = options.headers.has(CUSTOM_HEADERS.CLEAN_OPERATION);

		if (cacheOnly) {
			cache.batchDelete(items);

			return Observable.of(items);
		}

		if (batchDelete) {
			return this._httpService
				.request('DELETE', path, items, options)
				.map(() => items)
				.do(() => cache.batchDelete(items))
				.do(!skipMarkDirty ? this._markDirty.bind(this) : null);
		} else {
			let callIterator: number = 0;
			let callFinishCount: number = 0;
			let intervalHandle: number;
			const successfulIds: Id[] = [];

			return Observable.create((observer: Observer<(string | number)[]>) => {
				const deleteItem: Function = () => {
					const id: Id = items[callIterator];
					const requestPath: string = `${path}/${id}/`;

					this._httpService
						.delete(requestPath, options)
						.map(() => successfulIds.push(id))
						.do(!skipMarkDirty ? this._markDirty.bind(this) : null)
						.subscribe(
							u => {
								callFinishCount = callFinishCount + 1;
								if (callFinishCount === items.length) {
									cache.batchDelete(successfulIds);
									observer.next(successfulIds);
									observer.complete();
								}
							},
							error => callFinishCount
						);

					callIterator = callIterator + 1;
					if (callIterator === items.length) {
						clearInterval(intervalHandle);
					}
				};

				intervalHandle = window.setInterval(deleteItem, 300);
			});
		}
	}

	/**
	 * @description
	 * Deletes the given key (path) from the current cache.
	 *
	 * @param path the path to invalidate.
	 */
	public invalidate(path: string): void {
		if (this._cacheMap.has(path)) {
			this._cacheMap.delete(path);
		}
	}

	/**
	 * @description
	 * Gets the cache storage correspnding to the given path. Creates a
	 * new empty for paths that didn't exist before.
	 *
	 * @param path The path to get the cache for.
	 * @returns The cache for the given path.
	 */
	private _getCache(path: string): ResourceCache {
		if (!this._cacheMap.has(path)) {
			this._cacheMap.set(path, new ResourceCache());
		}

		return this._cacheMap.get(path);
	}

	/**
	 * @description
	 * Checks for the existance of a cache for the given path.
	 *
	 * @param path The path to check for.
	 * @returns True if a cache exists and false otherwise.
	 */
	private _checkForCache(path: string): boolean {
		return this._cacheMap.has(path);
	}

	/**
	 * @description
	 * Marks the current app as dirty.
	 */
	private _markDirty(): void {
		this._dirtyBitService.setDirty(true);
	}
}
