/**
 * @description
 * An enumeration of the operations that you can apply on an entity.
 */
export enum ENTITY_OPERATIONS {
    ADD,
    ADD_PREBUILT,
    ADD_DOMAIN,
    RENAME,
    DELETE
}
