import { Entity, EntityHelpers, EntityRole } from '@luis/entities';
import { IFsmTransition, IPattern, IPatternFsmResult } from '../../interfaces/IPatternFsm';
import { PatternEntity } from '../../models/pattern.model';

/**
 * @constant
 * @description
 * The enumeration of states allowable.
 */
enum STATES {
    INITIAL,
    ESCAPE_BRACES,
    OPEN_BRACES,
    BRACES_CONTENT
}

/**
 * @description
 * The allowable states for the braces finite state machine
 */
const TRANSITIONS: IFsmTransition[] = [
    { from: STATES.INITIAL, to: STATES.ESCAPE_BRACES, action: /\\/ },
    { from: STATES.INITIAL, to: STATES.INITIAL, action: /[^{]/ },
    { from: STATES.INITIAL, to: STATES.OPEN_BRACES, action: /{/ },
    { from: STATES.ESCAPE_BRACES, to: STATES.INITIAL, action: /./ },
    { from: STATES.OPEN_BRACES, to: STATES.BRACES_CONTENT, action: /[^}]/ },
    { from: STATES.OPEN_BRACES, to: STATES.INITIAL, action: /}/ },
    { from: STATES.BRACES_CONTENT, to: STATES.INITIAL, action: /}/ },
    { from: STATES.BRACES_CONTENT, to: STATES.BRACES_CONTENT, action: /[^}]/ }
];

/**
 * @description
 * Represent an extracted entity name along with the
 * index of its occurence.
 */
export interface IExtractedName {
    value: string;

    startIndex: number;
}

/**
 * @description
 * A finite state machine to resolve entities in braces.
 */
export class PatternEntityExtractorFsm implements IPattern {
    private _text: string;
    private _entities: Entity[];
    private _currentState: STATES;
    private _prevTransition: IFsmTransition;
    private _extractedEntities: IExtractedName[] = [];

    constructor(text: string, entities: Entity[]) {
        this._text = text;
        this._entities = EntityHelpers.expandChildren(entities);
        this._currentState = STATES.INITIAL;
    }

    /**
     * @method
     * @description
     * Checks if the current cursor is between braces or not.
     *
     * @returns The resulting validity of
     * the FSM.
     */
    public evaluate(): IPatternFsmResult {
        let itr: number = 0;
        let entityItr: number = 0;

        while (itr < this._text.length) {
            this._moveState(itr);

            if (this._currentState === STATES.BRACES_CONTENT) {
                let entry: IExtractedName = this._extractedEntities[entityItr];

                if (entry) {
                    entry.value = entry.value + this._text[itr];
                }
                else {
                    entry = { value: this._text[itr], startIndex: itr };
                }

                this._extractedEntities[entityItr] = entry;
            }
            else {
                entityItr = this._extractedEntities[entityItr] ? entityItr + 1 : entityItr;
            }

            itr = itr + 1;
        }

        return { result: true };
    }

    /**
     * @method
     * @description
     * Gets the raw extracted entities, including the
     * entity string and the start index of the entities.
     *
     * @returns The extracted entities
     * with names.
     */
    public getRawExtractedEntities(): IExtractedName[] {
        return this._extractedEntities;
    }

    /**
     * @method
     * @description
     * Gets the extracted entities by the FSM in the text.
     *
     * @returns The entities that were extracted from
     * the text.
     */
    public getExtractedEntities(): PatternEntity[] {
        return this._extractedEntities.map((e, i) => this._getPatternEntityByName(e.value, i));
    }

    /**
     * @description
     * Moves the FSM to the next state based on the matched action.
     *
     * @param index The current index of the character to check in the string.
     */
    private _moveState(index: number): void {
        const char: string = this._text[index];
        this._prevTransition = TRANSITIONS.filter(t => t.from === this._currentState).find(t => t.action.test(char));
        this._currentState = this._prevTransition.to;
    }

    /**
     * @description
     * Gets the pattern entity by the given name. This name me either be
     * an entity name or an entity name with a role.
     *
     * @param name The entity name.
     * @returns The entity that matches this name.
     */
    private _getPatternEntityByName(name: string, index: number): PatternEntity {
        const [entityName, roleName] = name.split(':');
        const entity: Entity = this._entities.find(e => e.name === entityName) || new Entity();
        const role: EntityRole = entity.roles.find(r => r.name === roleName) || new EntityRole();

        return new PatternEntity(entity.id, entity.name, index, entity.type, role.id);
    }
}
