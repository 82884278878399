import { from, Observable, of } from 'rxjs';
import { concatMap, finalize, first, reduce, switchMap, tap } from 'rxjs/operators';
import { IResource } from '../../interfaces/models/IResource';
import { IPaginationCacheService } from '../../interfaces/services/utils/IPaginationCacheService';
import { IPageInfo } from '../../models/caches/paginated-cache.model';
import { CHUNKING_PROCESS_TYPE, ChunkingProcessPayload } from '../../models/utils/chunking-process-payload.model';
import { BUS_EVENTS, EventBusMessage } from '../../models/utils/event-bus.model';
import { PaginationCacheService } from '../caches/pagination-cache.service';
import { EventBusService } from './event-bus.service';

/**
 * @description
 * Represents a generic implementation of the chunking process.
 */
export abstract class ChunkingService<T extends IResource> {
	public static readonly CHUNK_SIZE: number = 200;

	protected abstract chunkingType: CHUNKING_PROCESS_TYPE;

	constructor(private readonly _eventBusService: EventBusService, private readonly _paginationService: IPaginationCacheService<T>) {}

	/**
	 * Starts fetching chunks of data from the backend when the trigger emits. Flags to the event bus that
	 * the chunking process has started.
	 *
	 * @param modelId The model id.
	 * @param trigger An observable that triggers the start of the chunking process.
	 * @param parser A parser which turns the backend response into DTOs.
	 *
	 * @returns An observable of void that emits when the chunking process is done.
	 */
	public init(modelId: string, trigger: Observable<void>, parser: (respose: any) => T): Observable<void> {
		return trigger.pipe(
			first(),
			tap(() =>
				this._eventBusService.publishToBus(
					new EventBusMessage(BUS_EVENTS.CHUNKING_PROCESS_RUNNING, new ChunkingProcessPayload(this.chunkingType, true))
				)
			),
			switchMap(() => {
				if (this._paginationService.isAllFetched(modelId)) {
					return of(null);
				}

				const totalSize = this._paginationService.getTotalSize(modelId);
				const numberOfChunks = Math.ceil(totalSize / ChunkingService.CHUNK_SIZE);
				this._paginationService.removePageGaps(modelId);

				const seedArr = [...Array(numberOfChunks)].map((_, i) => i);

				return from(seedArr).pipe(
					concatMap(chunkIndex =>
						this.fetchData(
							modelId,
							{ skip: chunkIndex * ChunkingService.CHUNK_SIZE, take: ChunkingService.CHUNK_SIZE },
							parser
						).pipe(
							tap(data => {
								const pageSize = PaginationCacheService.PAGE_SIZE;
								const numberOfPages = Math.ceil(ChunkingService.CHUNK_SIZE / pageSize);
								for (let index = 0; index < numberOfPages; index = index + 1) {
									// Add the data to the cache
									this._paginationService.addPage(
										modelId,
										data.slice(index * pageSize, (index + 1) * pageSize),
										chunkIndex * numberOfPages + index
									);
								}
							})
						)
					),
					// Waits for all inner observables to emit and then completes.
					// Null is being passed since the data is of no interest for us here.
					reduce(() => null, null),
					finalize(() =>
						this._eventBusService.publishToBus(
							new EventBusMessage(BUS_EVENTS.CHUNKING_PROCESS_RUNNING, new ChunkingProcessPayload(this.chunkingType, false))
						)
					)
				);
			})
		);
	}

	protected abstract fetchData(modelId: string, { skip, take }: IPageInfo, parser?: (response: any) => T): Observable<T[]>;
}
