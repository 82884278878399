import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { BaseService } from '@luis/core';
import { Entity, ENTITY_SERVICE_TOKEN, IEntityService } from '@luis/entities';
import { BLADE_OPS, BladeComponent, BLADES, BladeTrackerService } from '@luis/ui';
import { Utterance } from '@luis/utterances';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IUtteranceResultsDto } from '../../../interfaces/IUtteranceResults';
import { getUtteranceTrainedPredictions } from '../../../models/entity-results-extractor.model';

/**
 * @description
 * Represents the debug blade in the testing console for the
 * active model. Displays the utterance and controls for editing
 * the utterance intent.
 */
@Component({
	selector: 'debug-blade',
	templateUrl: 'debug-blade.component.html',
	styleUrls: ['debug-blade.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebugBladeComponent extends BladeComponent implements OnInit {
	@Input() public utterance: Observable<Utterance>;

	public data: Observable<IUtteranceResultsDto>;
	public hideTools: Observable<boolean>;
	public currentVersion: string;
	public entities: Observable<Entity[]>;

	protected currentBlade: BLADES = BLADES.DEBUG;

	constructor(
		private readonly _baseService: BaseService,
		private readonly _bladeTrackerService: BladeTrackerService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {
		super(_bladeTrackerService);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this._initState();
	}

	/**
	 * @description
	 * Signals the blade tracker service that endpoint comparison
	 * is required.
	 */
	public compareWithEndpoint(): void {
		this._bladeTrackerService.applyOp(BLADE_OPS.ENDPOINT_DEBUG_OPEN);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.hideTools = this._bladeTrackerService.getBladeStates().map(m => m.get(BLADES.ENDPOINT_DEBUG));

		this.currentVersion = this._baseService.configs.versionId;

		this.entities = this._entityService.get();

		this.data = combineLatest(this.utterance, this.entities).pipe(
			filter(([utterance]) => utterance !== null),
			map(([utterance, entities]) => getUtteranceTrainedPredictions(utterance, entities))
		);
	}
}
