import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'm-toolbar-input',
    templateUrl: 'toolbar-input.component.html',
    styleUrls: ['toolbar-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarInputComponent {
    @Input() public placeholder: string = '';
    @Input() public initialValue: string = '';
    @Input() public width: number = 250;
    @Input() public search: boolean = false;

    @Output() public keyPressed: EventEmitter<string> = new EventEmitter<string>();
    @Output() public enterPressed: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('field') public inputField: ElementRef;

    /**
     * @description
     * Clears the search field.
     */
    public clearField(): void {
        this.inputField.nativeElement.value = '';
        this.enterPressed.emit('');
        this.inputField.nativeElement.focus();
    }
}
