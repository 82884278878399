import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { GATEKEEPER_FEATURE, IResourceOperation, SelectionMap } from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ENTITY_OPERATIONS } from '../../../models/entity-operations.model';
import { Entity, ENTITY_TYPES } from '../../../models/entity.model';
import { EntityUtilitiesService } from '../../../services/entity-util.service';

/**
 * @description
 * Represents an entity table. Lists the entities in an application. Provides
 * tools for manipulating the entities in the table.
 */
@Component({
    selector: 'entity-table',
    templateUrl: './entity-table.component.html',
    styleUrls: ['./entity-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityTableComponent implements OnInit {
    @Input() public entities: Observable<Entity[]>;
    @Output() public clickedEntity: EventEmitter<Entity> = new EventEmitter<Entity>();
    @Output() public applyOperation: EventEmitter<IResourceOperation> = new EventEmitter<IResourceOperation>();

    public currentPage: any;
    public entityOps: any = ENTITY_OPERATIONS;
    public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public displayedEntities: Observable<Entity[]>;
    public isRenameButtonVisible: Observable<boolean>;
    public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
        property: 'name',
        type: SORT_TYPE.ASCENDING
    });
    public selectionMap: SelectionMap = new SelectionMap();
    public gateKeeperFeature: typeof GATEKEEPER_FEATURE = GATEKEEPER_FEATURE;

    constructor(private _entitiesUtilService: EntityUtilitiesService) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Notifies parent that an operation was clicked to apply to the given entity.
     *
     * @param operation The operation to run. Should be one of the allowed operations
     * in the ENTITY_OPERATIONS enum.
     */
    public onOperationClick(operation: ENTITY_OPERATIONS): void {
        const selectedEntitiesIds: string[] = <string[]>this.selectionMap.selectedItems;
        const entityOperation: IResourceOperation = { items: [], operation: operation, response: new BehaviorSubject<boolean>(false) };

        this.entities
            .first()
            .map(entities => entities.filter(entity => selectedEntitiesIds.indexOf(entity.id) !== -1))
            .do(selectedEntities => entityOperation.items = selectedEntities)
            .subscribe(() => this.applyOperation.emit(entityOperation));

        entityOperation.response.subscribe(null, null, () => this.selectionMap.markUnselected(selectedEntitiesIds));
    }

    /**
     * @description
     * Initializes the component state.
     */
    private _initState(): void {
        this.displayedEntities = Observable.combineLatest(this.entities, this._entitiesUtilService.getLabelsPerEntity())
            .do(([entities, labelsCount = new Map<string, number>()]) => entities.forEach(e =>
                e.labelCount = labelsCount.get(e.id) !== undefined ? labelsCount.get(e.id) : e.labelCount
            ))
            .map(([entities]) => entities);

        this.isRenameButtonVisible = Observable.combineLatest(this.selectionMap.toolbarOps, this.entities)
            .map(([ops, entities]) => {
                const selectedEntity = entities.find(e => this.selectionMap.isSelected(e.id));

                return ops.single && selectedEntity && selectedEntity.type !== ENTITY_TYPES.PREBUILT;
            });
    }
}
