import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '@luis/entities';
import { Observable } from 'rxjs/Rx';
import { IExtractedName, PatternEntityExtractorFsm } from '../models/fsms/pattern-entity-extractor-fsm.model';
import { Pattern } from '../models/pattern.model';

/**
 * @description
 * Represents a meta data object for extracting entity
 * segments in a pattern.
 */
interface ISegment {
    text: string;

    isEntity: boolean;
}

/**
 * @description
 * Highlights the entity tokens in the given pattern.
 */
@Pipe({
    name: 'patternEntityHighlighterAsync'
})
export class PatternEntityHighlighterAsyncPipe implements PipeTransform {
    public transform(pattern: Pattern, entities: Observable<Entity[]>, className: string): Observable<string> {
        return entities.map(es => {
            const segmentArray: ISegment[] = [];
            const extractor: PatternEntityExtractorFsm = new PatternEntityExtractorFsm(pattern.text, es);
            extractor.evaluate();
            const extracted: IExtractedName[] = extractor.getRawExtractedEntities();
            let start: number = 0;

            if (extracted.length === 0) {
                segmentArray.push({ text: pattern.text, isEntity: false });
            }
            else {
                for (const e of extracted) {
                    const prefix: string = pattern.text.slice(start, e.startIndex - 1);
                    const entity: string = pattern.text.slice(e.startIndex, e.startIndex + e.value.length);
                    start = e.startIndex + e.value.length + 1;
                    segmentArray.push({ text: prefix, isEntity: false });
                    segmentArray.push({ text: entity, isEntity: true });
                }
                const suffix: string = pattern.text.slice(start);
                segmentArray.push({ text: suffix, isEntity: false });
            }

            return segmentArray.reduce((acc, val) => acc.concat(`<span class="${val.isEntity ? className : ''}">${val.text}</span>`), '');
        });
    }
}
