import { Pipe, PipeTransform } from '@angular/core';
import { ISortPipeProps } from '@luis/ui';
import { Observable } from 'rxjs/Rx';

/**
 * @description
 * Represents a data transform pipe for arrays. Sorts the given array data
 * based on the given sorting properties. Receieves and outputs an observable.
 */
@Pipe({
	name: 'sortAsync'
})
export class SortAsyncPipe implements PipeTransform {
	/**
	 * @description
	 * Sorts the data array based on the sorting properties given
	 * in the parameters. Receives and reuturns an observable.
	 *
	 * @param data The observable of the data array to sort.
	 * @param sortProps The sorting properties to sort using.
	 * @returns The sorted data array.
	 */
	public transform(data: Observable<Object[]>, sortProps: Observable<ISortPipeProps>): Observable<Object[]> {
		return Observable.combineLatest(data, sortProps).map(([d, props]) =>
			d.sort((a, b) => {
				let propertyA = a[props.property];
				let propertyB = b[props.property];

				if (typeof propertyA === 'string' && typeof propertyB === 'string') {
					propertyA = propertyA.toLowerCase();
					propertyB = propertyB.toLowerCase();
				}

				return propertyA < propertyB ? props.type * 1 : props.type * -1;
			})
		);
	}
}
