/**
 * @description
 * An enumeration of the operations that you can apply on an app.
 */
export enum APP_VERSION_OPERATIONS {
    SWITCH,
    RENAME,
    EXPORT,
    EXPORT_CONTAINER,
    DELETE,
    CLONE,
    IMPORT
}
