import { AppRouteBuilder } from './app-route-builder.model';
import { StaticRouteBuilder } from './static-route-builder.model';
import { UserRouteBuilder } from './user-route-builder.model';

export class MainRouteBuilder {
    private _route: string;
    private _defaultAppId: string;
    private _defaultVersionId: string;

    constructor(defaultAppId?: string, defaultVersionId?: string) {
        this._route = '';
        this._defaultAppId = defaultAppId;
        this._defaultVersionId = defaultVersionId;
    }

    public apps(): MainRouteBuilder {
        this._route = this._route.concat('/applications');

        return this;
    }

    public app(id?: string, versionId?: string): AppRouteBuilder {
        let route: string;

        if (id && versionId) {
            route = `/applications/${id}/versions/${versionId}`;
        }
        else if (this._defaultAppId && this._defaultVersionId) {
            route = `/applications/${this._defaultAppId}/versions/${this._defaultVersionId}`;
        }
        else {
            route = '/applications';
        }

        this._route = this._route.concat(route);

        return new AppRouteBuilder(this._route);
    }

    public user(): UserRouteBuilder {
        this._route = this._route.concat('/user');

        return new UserRouteBuilder(this._route);
    }

    public staticPages(): StaticRouteBuilder {
        return new StaticRouteBuilder();
    }

    public build(): string {
        return this._route;
    }
}
