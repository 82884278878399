import { NgModule } from '@angular/core';
import { InputHandlerService } from '../../services/input-handler.service';
import { SharedModule } from '../../shared/shared.module';
import { DropdownsModule } from '../dropdowns/dropdowns.module';
import { BracesHandlerComponent } from './braces-handler/braces-handler.component';
import { InputHandlerComponent } from './input-handler.component';

@NgModule({
    imports: [
        SharedModule,
        DropdownsModule
    ],
    declarations: [
        InputHandlerComponent,
        BracesHandlerComponent
    ],
    exports: [
        InputHandlerComponent,
        BracesHandlerComponent
    ],
    providers: [
        InputHandlerService
    ],
    entryComponents: [
        BracesHandlerComponent
    ]
})
export class InputHandlerModule { }
