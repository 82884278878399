import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AppVersion } from '@luis/apps';
import { BaseService } from '@luis/core';
import { AxisData } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { filter, flatMap, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ITrainingResultService, TRAINING_RESULT_SERVICE_TOKEN } from '../../interfaces/ITrainingResultService';
import { IntentMetadata } from '../../models/intent-metadata.model';
import { FilteredIntentInfo, STATUS_CODES, TrainingResultMetaData, VersionsComparison } from '../../models/training-result-metadata.model';
import { ANALYTICS_UI_STATE_SERVICE_TOKEN, AnalyticsUIStatusService, CARDS } from '../../services/analytics-ui-status.service';

@Component({
	selector: 'accuracy-chart',
	templateUrl: 'accuracy-chart.component.html',
	styleUrls: ['accuracy-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccuracyChartComponent implements OnInit {
	@Output() public filteredIntentClicked: EventEmitter<FilteredIntentInfo> = new EventEmitter<FilteredIntentInfo>();

	public xData: Observable<AxisData>;
	public yData: Observable<AxisData>;
	public accuracy: Observable<number[]>;
	public versions: Observable<AppVersion[]>;
	public donutChartTitle: Observable<string>;
	public showSuggestions: Observable<boolean>;
	public unclearIntents: Observable<IntentMetadata[]>;
	public incorrectIntents: Observable<IntentMetadata[]>;
	public comparedAccuracy: Observable<VersionsComparison>;
	public lowUtterancesIntents: Observable<IntentMetadata[]>;
	public trainingResult: Observable<TrainingResultMetaData>;
	public highUtterancesIntents: Observable<IntentMetadata[]>;
	public comparedVersionId: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _baseService: BaseService,
		@Inject(TRAINING_RESULT_SERVICE_TOKEN) private readonly _trainingResultService: ITrainingResultService,
		@Inject(ANALYTICS_UI_STATE_SERVICE_TOKEN) private readonly _analyticsUIStateService: AnalyticsUIStatusService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public goToIntentWithFilters(targetIntentId: string, evalFilter: number): void {
		this._analyticsUIStateService.lastUsedCard = CARDS.OVERALL;
		this.filteredIntentClicked.emit({
			selectedIntentId: targetIntentId,
			filters: {
				applied: true,
				evaluation: {
					correct: evalFilter === 0,
					incorrect: evalFilter === 1,
					unclear: evalFilter === 2
				},
				nearestRivalIntentsIds: []
			}
		});
	}

	public onVersionComparisonChange(versionId: string): void {
		this.comparedVersionId.next(versionId);
		if (versionId === null) {
			return;
		}
		this.comparedAccuracy = this._trainingResultService.compareWithVersion(versionId);
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.trainingResult = this._trainingResultService.getStatus().pipe(
			filter(status => status === STATUS_CODES.COMPLETED),
			flatMap(() => this._trainingResultService.getMetaData()),
			shareReplay()
		);

		// Calculate the accuracy.
		this.accuracy = this.trainingResult.map(tR => {
			const total: number = tR.numUtterances === 0 ? 1 : tR.numUtterances;

			return [
				(tR.numCorrectUtterances * 100) / total,
				(tR.numAmbiguousUtterances * 100) / total,
				(tR.numIncorrectUtterances * 100) / total
			]
				.map(num => num.toFixed(1))
				.map(Number);
		});

		this.donutChartTitle = this.trainingResult.pipe(
			map(trainingResult => {
				const total: number = trainingResult.numUtterances === 0 ? 1 : trainingResult.numUtterances;
				const correctAcc: number = (trainingResult.numCorrectUtterances * 100) / total;

				return `${
					correctAcc === 100 ? correctAcc : correctAcc.toFixed(1)
				}% <tspan y='6mm' x='0.3mm'style='font-size: 13px; line-height: 20px;'>${this._i18n.instant(
					'training.accuracy-chart.donut_title'
				)}</tspan>`;
			})
		);

		// Load the data.
		this.xData = Observable.of(new AxisData('Type', ['Correctly predicted', 'Unclear', 'Incorrectly predicted'], false));
		this.yData = this.accuracy.map(ratios => new AxisData('Ratio', ratios, false));

		// Get the other versions
		this.versions = this._trainingResultService.getVersions().pipe(
			map(versions => versions.filter(v => v.id !== this._baseService.configs.versionId && v.trainedDate !== null)),
			shareReplay()
		);

		// Initialize the most faulty intents.
		this._getFaultyIntents();
	}

	/**
	 * @description
	 * Gets the most faulty intents.
	 */
	private _getFaultyIntents(): void {
		// Get imbalanced intents.
		this.lowUtterancesIntents = this._trainingResultService.getIntentsWithLowNumOfUtterances().pipe(map(arr => arr.slice(0, 3)));
		this.highUtterancesIntents = this._trainingResultService.getIntentsWithHighNumOfUtterances().pipe(map(arr => arr.slice(0, 3)));

		// Get incorrect intents.
		this.incorrectIntents = this._trainingResultService.getIncorrectIntents().map(arr => arr.slice(0, 3));

		// Get unclear intents.
		this.unclearIntents = this._trainingResultService.getAmbiguousIntents().map(arr => arr.slice(0, 3));

		this.showSuggestions = Observable.combineLatest(this.lowUtterancesIntents, this.incorrectIntents, this.unclearIntents).map(
			intents => intents.reduce((acc, arr) => acc + arr.length, 0) > 0
		);
	}
}
