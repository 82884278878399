import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BfProxyComponent } from '../common/components/bf-proxy/bf-proxy.component';
import { AuthGuard } from './router-guards/auth-guard.service';
import { MaintenanceGuard } from './router-guards/maintenance-guard.service';
import { CanDeactivateGuard } from './router-hooks/can-deactivate.service';
import { ApplicationResolve } from './router-resolvers/application-resolve.service';

const routes: Routes = [
    {
        path: 'applications/:appId/versions/:versionId',
        loadChildren: '../application/application.module#ApplicationModule',
        canActivate: [AuthGuard, MaintenanceGuard],
        data: { title: 'LUIS: Application' }
    },
    {
        path: 'applications',
        loadChildren: '../applications/applications.module#ApplicationsModule',
        canActivate: [AuthGuard, MaintenanceGuard],
        data: { title: 'LUIS: Applications List Page' }
    },
    {
        path: 'user',
        loadChildren: '../user/user.module#UserModule',
        canActivate: [AuthGuard, MaintenanceGuard],
        data: { title: 'LUIS: User Settings Page' }
    },
    {
        path: 'home/subkey',
        component: BfProxyComponent,
        canActivate: [AuthGuard, MaintenanceGuard]
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
        data: { title: 'LUIS (Language Understanding) – Cognitive Services – Microsoft Azure' }
    },
    {
        path: '**',
        redirectTo: '/404',
        data: { title: 'LUIS: Not Found' }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard,
        MaintenanceGuard,
        CanDeactivateGuard,
        ApplicationResolve
    ]
})
export class AppRoutingModule { }
