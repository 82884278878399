﻿/**
 * @description
 * Represents a locale that can be supported by the application.
 *
 * @param name The locale readable name.
 * @param code The locale unique code.
 */
export class Locale {
    public name: string;
    public code: string;

    constructor(name: string, code: string) {
        this.name = name;
        this.code = code;
    }
}

/**
 * @const
 * @description
 * An array of the supported locales.
 */
export const supportedLocales: Locale[] = [
    new Locale('English (United States)', 'en'),
    new Locale('Chinese (China)', 'zh')
];
