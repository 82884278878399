import { Inject, Injectable } from '@angular/core';
import { BaseService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IAppStatsService } from '../interfaces/IAppStatsService';

/**
 * @description
 * Represents a concrete implementation of the IAppStatsService interface for app
 * statistics related operations for a LUIS app. This implementation is meant for production use.
 */
@Injectable()
export class AppStatsService implements IAppStatsService {
	constructor(
		private readonly _baseService: BaseService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	/**
	 * @method
	 * @description
	 * Gets the base web url for manipulating app settings.
	 *
	 * @returns The base url for app settings manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}/stats`;
	}

	/**
	 * @method
	 * @description
	 * Gets the endpoint hits for a specific app in a map of day/endpoint hits.
	 *
	 * @param start The start date of the endpoints to hit.
	 * @param end The end date of the endpoints to hit.
	 * @returns An Observable of the endpoints map.
	 */
	public getEndpointHits(start: Date, end: Date): Observable<Map<string, number>> {
		const url: string = `${this._baseUrl}/endpointhitshistory?from=${start.toISOString()}&to=${end.toISOString()}`;

		const parser: (data: any) => Map<string, number> = data => {
			const endpointsMap: Map<string, number> = new Map<string, number>();
			Object.keys(data).map(k => endpointsMap.set(k, data[k]));

			return endpointsMap;
		};

		return this._cacheService.get(url, parser, this._baseService.defaultOptionsBuilder.build());
	}
}
