import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IToasterService, ProgressTracker, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { APP_SERVICE_TOKEN, IAppService } from '../../../interfaces/IAppService';
import { App } from '../../../models/app.model';

/**
 * @description
 * Represents the modal for importing a new app.
 */
@Component({
	selector: 'app-rename-modal',
	templateUrl: 'app-rename-modal.component.html',
	styleUrls: ['app-rename-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppRenameModalComponent implements OnInit {
	public formApp: App;
	public isButtonDisabled: Observable<boolean>;
	public _tracker: ProgressTracker = new ProgressTracker();
	private readonly _isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<AppRenameModalComponent>,
		@Inject(MAT_DIALOG_DATA) private readonly _app: App,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Notifies any listeners to the stream whether the app is valid or not.
	 */
	public onModelChange(): void {
		this._isValid.next(this._isAppValid(this.formApp));
	}

	/**
	 * @description
	 * Validates the app, and if successfully validated, renames it.
	 */
	public renameApp(): void {
		if (this._isAppValid(this.formApp)) {
			this._appService
				.update(this.formApp)
				.trackProgress(this._tracker.getTracker())
				.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('apps.app-rename-modal.rename_toast') }))
				.subscribe(() => this._dialogRef.close());
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.formApp = this._app.clone();

		this.isButtonDisabled = Observable.combineLatest(this._tracker.isStarted, this._isValid)
			.startWith([false, true])
			.map(data => {
				const [inProgress, isValid] = data;

				return inProgress || !isValid;
			});
	}

	/**
	 * @description
	 * Checks if the app name is not empty.
	 *
	 * @param app The app to check.
	 * @returns True if the app is valid and false otherwise.
	 */
	private _isAppValid(app: App): boolean {
		return app.name.trim().length > 0;
	}
}
