import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * @description
 * A directive to check if a mouse click was outside of the element the directive is on.
 */
@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    @Input() public checker: (element: HTMLElement, clickedOn: HTMLElement) => boolean;
    @Output() public clickOutside: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private _elementRef: ElementRef) {}

    @HostListener('document:mousedown', ['$event'])
    public onClick(event: Event): void {
        const targetElement: HTMLElement = <HTMLElement>event.target;
        const clickedInside: boolean = this._elementRef.nativeElement.contains(targetElement);

        const checkerVal = this.checker ? this.checker(this._elementRef.nativeElement, targetElement) : true;

        if (!clickedInside && checkerVal) {
            this.clickOutside.emit(true);
        }
    }
}
