import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    GenericPromptService,
    IToasterService,
    PromptButtonTypes,
    SelectionMap,
    TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { ToolbarInputComponent } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../interfaces/IEntityService';
import { Entity, ENTITY_TYPES } from '../../../models/entity.model';
import { ParentEntity } from '../../../models/parent-entity.model';

/**
 * @description
 * Represents the entity details for a hierarchical entity. Contains controls
 * for adding, deleting and updating hierarchical entity children.
 */
@Component({
    selector: 'hierarchical-details',
    templateUrl: 'hierarchical-details.component.html',
    styleUrls: ['hierarchical-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchicalDetailsComponent implements OnInit, OnDestroy {
    @Input() public entity: Observable<ParentEntity>;
    @ViewChild('addIcon', { read: ElementRef }) public addIconRef: ElementRef;

    public refreshableEntity: BehaviorSubject<ParentEntity> = new BehaviorSubject<ParentEntity>(null);
    public selectionMap: SelectionMap = new SelectionMap();
    public children: Observable<Entity[]>;

    private _entitySubscription: Subscription = new Subscription();

    constructor(
        private _promptService: GenericPromptService,
        private _i18n: TranslateService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        this._entitySubscription.unsubscribe();
    }

    /**
     * @description
     * Appends a new child to the entity children.
     */
    public addChild(childName: string, toolbarInput: ToolbarInputComponent): void {
        const name: string = childName.trim();

        if (!name.length) {
            return;
        }

        this.entity
            .first()
            .map(parent => parent.clone())
            .do(parent => parent.appendChild())
            .map(parent => parent.children[parent.children.length - 1])
            .do(child => child.name = name)
            .do(child => child.type = ENTITY_TYPES.HIERARCHICAL_CHILD)
            .flatMap(child => this._entityService.add(child))
            .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.hierarchical-details.add_toast') }))
            .subscribe(() => toolbarInput.inputField.nativeElement.value = '', () => this._revertChanges());
    }

    /**
     * @description
     * Updates an existing child with a new name.
     *
     * @param child The child entity to update.
     */
    public renameChild(child: Entity): void {
        const name: string = child.name.trim();

        if (!name.length) {
            return;
        }

        child.name = name;

        this.entity
            .first()
            .map(parent => parent.children.find(c => c.id === child.id))
            .filter(unmutatedChild => unmutatedChild.name !== child.name)
            .flatMap(() => this._entityService.update(child)
                .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.hierarchical-details.rename_toast') })))
            .subscribe(null, () => this._revertChanges());
    }

    /**
     * @description
     * Deletes the selected childs from the selection map.
     */
    public onDeleteClicked(): void {
        const ids: string[] = <string[]>this.selectionMap.selectedItems;

        this._promptService
            .prompt(
                'Delete child?',
                `Are you sure you want to delete ${ids.length > 1 ? 'those children' : 'this child'}?`,
                { ok: 'Ok', cancel: 'Cancel' },
                { ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
            )
            .filter(response => response === 'ok')
            .flatMap(() => this.entity.first())
            .map(entity => entity.clone())
            .map(entity => ({ entity: entity, children: ids.map(id => entity.children.find(c => c.id === id)) }))
            .do(data => data.children.forEach(child => data.entity.removeChild(child)))
            .flatMap(data => Observable.combineLatest(data.children.map((child) => this._entityService.delete(child)))
                .trackProgress(this._toasterService.add({ startMsg: `Deleting ${ids.length > 1 ? 'children' : 'child'}` }))
            )
            .subscribe(() => this.selectionMap.markUnselected(ids), () => this._revertChanges());
    }

    /**
     * @description
     * Angular for loop tracking function avoids rerendering.
     */
    public trackChild(index: number, child: any): number {
        return child.id;
    }

    /**
     * @description
     * Initializes the component.
     */
    private _initState(): void {
        this._entitySubscription = this.entity
            .filter(e => e.type === ENTITY_TYPES.HIERARCHICAL)
            .do(e => e.children.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1))
            .subscribe(e => this.refreshableEntity.next(e));

        this.children = this.refreshableEntity.map(e => e.children);
    }

    /**
     * @description
     * Reverts the changes to the editable entity with the original
     * entity stream value.
     */
    private _revertChanges(): void {
        this.entity.first().subscribe(e => this.refreshableEntity.next(e.clone()));
    }
}
