import { NgModule, Provider } from '@angular/core';
import { IntentsModule } from '@luis/intents';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { DropdownsModule } from './components/dropdowns/dropdowns.module';
import { InputHandlerModule } from './components/input-handler/input-handler.module';
import { PatternInputComponent } from './components/pattern-input/pattern-input.component';
import { PatternRowComponent } from './components/pattern-row/pattern-row.component';
import { PatternTableComponent } from './components/pattern-table/pattern-table.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { PATTERN_SERVICE_TOKEN } from './interfaces/IPatternService';
import { PatternStoreService } from './services/pattern-store.service';
import { PatternService } from './services/pattern.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
    imports: [
        SharedModule,
        DropdownsModule,
        InputHandlerModule,
        IntercomUIModule,
        NgxPaginationModule,
        IntentsModule,
        TranslateModule
    ],
    exports: [
        InputHandlerModule,
        PatternInputComponent,
        PatternRowComponent,
        PatternTableComponent,
        SharedModule
    ],
    declarations: [
        PatternInputComponent,
        PatternRowComponent,
        PatternTableComponent,
        ToolbarComponent
    ]
})
export class PatternsModule {
    public static providersForChild(): Provider[] {
        return [
            {
                provide: PATTERN_SERVICE_TOKEN,
                useClass: PatternService
            },
            PatternStoreService
        ];
    }
}
