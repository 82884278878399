import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Domain } from '../models/domain.model';

export const DOMAIN_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IDomainService');

/**
 * @description
 * Represents an interface for a domain service. A domain service should contain
 * CRUD methods for LUIS prebuilt domains.
 */
export interface IDomainService {
    get(culture?: string): Observable<Domain[]>;

    add(domain: Domain): Observable<string[]>;

    delete(domain: Domain): Observable<void>;
}
