import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, FileService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IBatchTestingService } from '../interfaces/IBatchTestingService';
import { DatasetMetadata } from '../models/dataset-metadata.model';
import { DatasetResultItem } from '../models/dataset-result-item.model';

/**
 * @description
 * Represents the service that gets, adds, and updates dataset metadata. It
 * also contains functions for running a dataset against the current trained
 * LUIS model/application.
 */
@Injectable()
export class BatchTestingService implements IBatchTestingService {
	constructor(
		private readonly _baseService: BaseService,
		private readonly _fileService: FileService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	/**
	 * @description
	 * Gets the base url for the cache.
	 *
	 * @returns The base url for batch tests.
	 */
	private get cacheUrl(): string {
		return `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets`;
	}

	/**
	 * @description
	 * Gets the batch datasets for this application.
	 */
	public get(): Observable<DatasetMetadata[]> {
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets`;
		const parser: (data: any[]) => DatasetMetadata[] = (data: any[]) => data.map(d => DatasetMetadata.importFromApi(d));

		return this._cacheService.get(url, parser, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Downloads the given dataset
	 *
	 * @param dataset The dataset to export.
	 * @returns An observable to indicate completion.
	 */
	public export(dataset: DatasetMetadata): Observable<void> {
		const baseUrl: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}`;
		const options = this._baseService.defaultOptionsBuilder.build();

		return this._fileService.download(
			`${baseUrl}/testdatasets/${dataset.id}/download?Subscription-Key=${this._baseService.configs.userSubKey}`,
			`${dataset.name}.json`,
			options
		);
	}

	/**
	 * @description
	 * Runs the dataset on the application.
	 *
	 * @param dataset The dataset to run.
	 * @returns An array of data set results.
	 */
	public run(dataset: DatasetMetadata): Observable<DatasetResultItem[]> {
		const baseUrl: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/versions/${
			this._baseService.configs.versionId
		}`;
		const url: string = `${baseUrl}/testdatasets/${dataset.id}/run`;

		return this._httpService
			.get(url, this._baseService.defaultOptionsBuilder.build())
			.map(data => (<any[]>data).map(d => DatasetResultItem.importFromApi(d)));
	}

	/**
	 * @description
	 * Adds the dataset to the application.
	 *
	 * @param name The dataset name to add.
	 * @param json The JSON string of the dataset.
	 * @returns An observable of the dataset added.
	 */
	public import(json: string, name: string): Observable<DatasetMetadata> {
		const datasetsUrl: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets`;
		const urlParams: string = `dataSetName=${name}&subscription-key=${this._baseService.configs.userSubKey}`;
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets?${urlParams}`;
		const newDataset: DatasetMetadata = new DatasetMetadata('', name, 0, null, null);
		const options = this._baseService.defaultOptionsBuilder.build();

		try {
			newDataset.size = JSON.parse(json).length;
		} catch (e) {
			newDataset.size = 0;
		}

		return this._fileService
			.upload<string>(url, json, options)
			.do(datasetId => (newDataset.id = datasetId))
			.flatMap(() =>
				this._cacheService
					.get(datasetsUrl, null, this._baseService.optionsBuilder.useCacheOnly().build())
					.first()
					.do(datasets => (<DatasetMetadata[]>datasets).unshift(newDataset))
					.flatMap(datasets =>
						this._cacheService.post(datasetsUrl, datasets, this._baseService.optionsBuilder.useCacheOnly().build())
					)
					.map(() => newDataset)
			);
	}

	/**
	 * @description
	 * Updates the batch dataset information.
	 *
	 * @param dataset The dataset to update.
	 * @returns An observable to indicate completion.
	 */
	public update(dataset: DatasetMetadata): Observable<void> {
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets/${dataset.id}`;

		return this._httpService
			.put(url, dataset.exportToApi(), this._baseService.defaultOptionsBuilder.build())
			.flatMap(() => this._cacheService.get(this.cacheUrl, null, this._baseService.optionsBuilder.useCacheOnly().build()).first())
			.do(datasets => (datasets[(<DatasetMetadata[]>datasets).findIndex(d => d.id === dataset.id)] = dataset))
			.flatMap(sets => this._cacheService.put(this.cacheUrl, sets, this._baseService.optionsBuilder.useCacheOnly().build()));
	}

	/**
	 * @description
	 * Renames the batch dataset.
	 *
	 * @param dataset The dataset to update.
	 * @returns An observable to indicate completion.
	 */
	public rename(dataset: DatasetMetadata): Observable<void> {
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets/${
			dataset.id
		}/rename`;

		return this._httpService
			.put(url, dataset.exportToApi(), this._baseService.defaultOptionsBuilder.build())
			.flatMap(() => this._cacheService.get(this.cacheUrl, null, this._baseService.optionsBuilder.useCacheOnly().build()).first())
			.do(datasets => (datasets[(<DatasetMetadata[]>datasets).findIndex(d => d.id === dataset.id)] = dataset))
			.flatMap(sets => this._cacheService.put(this.cacheUrl, sets, this._baseService.optionsBuilder.useCacheOnly().build()));
	}

	/**
	 * @description
	 * Deletes the given batch dataset.
	 *
	 * @param dataset The dataset to delete.
	 * @returns An observable to indicate completion.
	 */
	public delete(dataset: DatasetMetadata): Observable<void> {
		const cacheUrl: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets`;
		const url: string = `${this._baseService.configs.webApiUrl}/apps/${this._baseService.configs.appId}/testdatasets/${dataset.id}`;

		return this._httpService
			.delete(url, this._baseService.defaultOptionsBuilder.build())
			.flatMap(() => this._cacheService.get(cacheUrl, null, this._baseService.optionsBuilder.useCacheOnly().build()).first())
			.do((datasets: DatasetMetadata[]) => datasets.splice(datasets.findIndex(d => d.id === dataset.id), 1))
			.flatMap(sets => this._cacheService.put(cacheUrl, sets, this._baseService.optionsBuilder.useCacheOnly().build()));
	}
}
