import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';

export const ARM_TOKEN_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IArmTokenService');

/**
 * @description
 * An interface for the arm token service. The arm token service contains methods
 * to manipulate ARM (Azure Resource Manager) tokens. These are used to get Azure
 * subscription keys that are then used by the user to hit the endpoint.
 */
export interface IArmTokenService {
    getDefaultToken(): Observable<string>;

    getTokenForTenant(tenantId: string): Observable<string>;
}
