import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { LuisModel } from '@luis/core';
import { Entity, ENTITY_SERVICE_TOKEN, EntityHelpers, IEntityService, EntityRole } from '@luis/entities';
import { IIntentService, Intent, INTENT_SERVICE_TOKEN } from '@luis/intents';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IDatasetDto } from '../../../../interfaces/IDatasetDto';
import { shareReplay, map, tap } from 'rxjs/operators';

/**
 * @description
 * Represents the sidebar of Luis models.
 */
@Component({
	selector: 'model-sidebar',
	templateUrl: 'model-sidebar.component.html',
	styleUrls: ['model-sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelSidebarComponent implements OnInit {
	@Input() public datasetDto: IDatasetDto;

	@Output() public modelClicked: EventEmitter<LuisModel> = new EventEmitter<LuisModel>();

	public intents: Observable<Intent[]>;
	public entities: Observable<Entity[]>;
	public roles: Observable<EntityRole[]>;
	public correctModelAggregates: Observable<Map<string, number>>;
	public totalModelAggregates: Observable<Map<string, number>>;
	public activeModel: BehaviorSubject<LuisModel> = new BehaviorSubject<LuisModel>(undefined);
	public showPrebuilts: boolean = false;
	public expandedItem = null;

	constructor(
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService,
		@Inject(ENTITY_SERVICE_TOKEN) private readonly _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Toggles the expandable section on and off.
	 */
	public toggleExpandableSection(event: KeyboardEvent, model: LuisModel) {
		event.stopPropagation();
		if (model instanceof Entity) {
			this.expandedItem = this.expandedItem !== model.id ? model.id : null;
		}
	}

	/**
	 * @description
	 * Notifies the parent that a model was clicked.
	 *
	 * @param model The model that was clicked.
	 */
	public onModelClicked(model: LuisModel): void {
		// Close the section if another entity is selected.
		if (model instanceof Entity) {
			this.expandedItem = this.expandedItem === model.id ? this.expandedItem : null;
		}
		if (this.activeModel.getValue() === model) {
			return;
		}

		this.activeModel.next(model);
		this.modelClicked.emit(model);
	}

	/**
	 * @description
	 * Toggles the visibility of prebuilt entities.
	 */
	public togglePrebuilts(): void {
		this.showPrebuilts = !this.showPrebuilts;
		this.datasetDto.scorer.includePrebuilts = this.showPrebuilts;
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		const sortingFunction = (a: LuisModel, b: LuisModel) => (a.name > b.name ? 1 : -1);

		this.correctModelAggregates = this.datasetDto.scorer.correctModelConfusionAggregates.pipe(shareReplay());

		this.totalModelAggregates = this.datasetDto.scorer.totalModelConfusionAggregates.pipe(shareReplay());

		this.intents = this._intentService.get().pipe(
			map(intents => intents.sort(sortingFunction)),
			tap(intents => this.onModelClicked(intents[0]))
		);

		this.entities = this._entityService.get().pipe(
			map(entities => EntityHelpers.expandChildren(entities)),
			map(entities => entities.sort(sortingFunction))
		);
	}
}
