import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import { StaticModule } from '../static/static.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appTranslationInitializerFactory } from './models/app-translation-initializer.model';

@NgModule({
	imports: [BrowserModule, BrowserAnimationsModule, SharedModule, CommonModule, StaticModule, AppRoutingModule],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appTranslationInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		}
	]
})
export class AppModule {}
