import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'm-nav-bar-button',
    templateUrl: 'nav-bar-button.component.html',
    styleUrls: ['../nav-bar.component.scss']
})

export class NavBarButtonComponent {

    @Input() text: string;
    @Input() disabled?: boolean;
    @Output() onClick = new EventEmitter();

    public onClickHandler(event: any) {
        if (!this.disabled) {
            this.onClick.emit();
        }
    }
}
