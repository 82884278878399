import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { VIEW_OPTIONS } from '../models/plain-segment.model';

@Injectable()
export class ItemTableService {
    private _viewMode: BehaviorSubject<VIEW_OPTIONS> = new BehaviorSubject<VIEW_OPTIONS>(VIEW_OPTIONS.ENTITY_LABEL_VIEW);

    /**
     * @description
     * Gets the current view mode in the item table.
     *
     * @returns An observable
     * of the current view mode.
     */
    public getViewMode(): Observable<VIEW_OPTIONS> {
        return this._viewMode.asObservable();
    }

    /**
     * @description
     * Sets the view mode to the given view mode.
     *
     * @param viewMode The view mode to set.
     */
    public setViewMode(viewMode: VIEW_OPTIONS): void {
        this._viewMode.next(viewMode);
    }

    /**
     * @description
     * Toggles the view mode in order.
     */
    public toggleViewMode(): void {
        const viewMode: VIEW_OPTIONS = this._viewMode.getValue();
        this._viewMode.next(viewMode === VIEW_OPTIONS.ENTITY_LABEL_VIEW ? VIEW_OPTIONS.TOKEN_LABEL_VIEW : VIEW_OPTIONS.ENTITY_LABEL_VIEW);
    }
}
