import { Entity, EntityRole } from './entity.model';

/**
 * @description
 * Helper model class for facilitating the creation of entities with roles as children for composite entities.
 * This should be used only in angular components to facilitate creating, editing and deleting entities with roles.
 * It shouldn't be used as a contract between different services and components.
 */
export class CompoundEntity {
    constructor(public readonly entity: Entity, public readonly role?: EntityRole) {}

    public get id(): string {
        return this.entity.id + (this.role ? `:${this.role.id}` : '');
    }

    public get name(): string {
        return this.entity.name + (this.role ? `:${this.role.name}` : '');
    }

    public get hasRole(): boolean {
        return this.role !== undefined;
    }

    /**
     * @description
     * Takes an entity and returns its id concatenated with the role id if it exits.
     */
    public static constructCompoundId(entity: Entity): string {
        return entity.id + (entity.roles[0] ? `:${entity.roles[0].id}` : '');
    }

    /**
     * @description
     * Takes an entity and a role and returns the entity name concatenated with the role name if it exits.
     */
    public static constructEntityWithRoleName(entity: Entity, roleId?: string): string {
        const selectedRole = roleId && entity.roles.find(role => role.id === roleId);

        return entity.name + (selectedRole ? `:${selectedRole.name}` : '');
    }

    /**
     * @description
     * Takes in array of entities and returns an array of CompoundEntity, containing all the entities
     * together with their roles expanded as compound entities.
     */
    public static expandEntityRoles(entities: Entity[]): CompoundEntity[] {
        return entities.reduce((acc, entity) => {
            // Adding main entity with roles
            entity.roles.forEach(role => {
                acc.push(new CompoundEntity(entity, role));
            });

            // Adding main entity
            acc.push(new CompoundEntity(entity));

            return acc;
        }, []);
    }

    /**
     * @description
     * Splits a compound id into entity id and role id if it exists.
     */
    public static splitCompoundId(compoundId: string): [string, string | undefined] {
        return <[string, string | undefined]>compoundId.split(':');
    }
}
