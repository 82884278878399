import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IToasterService, ProgressTracker, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { IIntentService, INTENT_SERVICE_TOKEN } from '../../interfaces/IIntentService';
import { Intent } from '../../models/intent.model';

/**
 * @description
 * Represents the modal for renaming an intent.
 */
@Component({
	selector: 'intent-rename-modal',
	templateUrl: 'intent-rename-modal.component.html',
	styleUrls: ['intent-rename-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntentRenameModalComponent implements OnInit {
	public formIntent: Intent;
	public isButtonDisabled: Observable<boolean>;
	public _tracker: ProgressTracker = new ProgressTracker();
	private readonly _isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _dialogRef: MatDialogRef<IntentRenameModalComponent>,
		@Inject(MAT_DIALOG_DATA) private readonly _intent: Intent,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(INTENT_SERVICE_TOKEN) private readonly _intentService: IIntentService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @method
	 * @description
	 * Notifies any listeners to the stream whether the intent is valid or not.
	 */
	public onModelChange(): void {
		this._isValid.next(this._isIntentValid(this.formIntent));
	}

	/**
	 * @method
	 * @description
	 * Validates the intent, and if successfully validated, renames it.
	 */
	public renameIntent(): void {
		if (this._isIntentValid(this.formIntent)) {
			this._intentService
				.update(this.formIntent)
				.trackProgress(this._tracker.getTracker())
				.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('intents.intent-rename-modal.rename_toast') }))
				.subscribe(() => this._dialogRef.close());
		}
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.formIntent = this._intent.clone();

		this.isButtonDisabled = Observable.combineLatest(this._tracker.isStarted, this._isValid)
			.startWith([false, true])
			.map(data => {
				const [inProgress, isValid] = data;

				return inProgress || !isValid;
			});
	}

	/**
	 * @description
	 * Checks if the intent name is not empty.
	 *
	 * @param intent The intent to check.
	 * @returns True if the intent is valid and false otherwise.
	 */
	private _isIntentValid(intent: Intent): boolean {
		const intentNameLength: number = intent.name.trim().length;

		return intentNameLength > 0 && intentNameLength <= 50;
	}
}
