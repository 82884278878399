
/**
 * @description
 * Represents an announcement object that is displayable
 * on the website.
 *
 * @param title The title of the announcement.
 * @param body The body of the announcement.
 */
export class Announcement {
    public title: string;
    public body: string;

    constructor(title: string = '', body: string = '') {
        this.title = title;
        this.body = body;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): Announcement {
        return new Announcement(
            apiObject.title,
            apiObject.body
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            title: this.title,
            body: this.body
        };
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): Announcement {
        return new Announcement(this.title.slice(), this.body.slice());
    }
}
