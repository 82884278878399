﻿import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { App, APP_VERSION_SERVICE_TOKEN, AppsModule, IAppVersionService } from '@luis/apps';
import { AuthService } from '@luis/auth';
import {
	BaseService,
	BUS_EVENTS,
	CoreModule,
	DirtyBitService,
	EventBusService,
	GateKeeperService,
	ITrainingService,
	TRAINING_SERVICE_TOKEN
} from '@luis/core';
import { DomainsModule } from '@luis/domains';
import { EntitiesModule } from '@luis/entities';
import { FeaturesModule } from '@luis/features';
import { IntentsModule } from '@luis/intents';
import { PatternsModule } from '@luis/patterns';
import { PublishPaneModule } from '@luis/publish-pane';
import { TestPaneModule } from '@luis/test-pane';
import { ITrainingResultService, TRAINING_RESULT_SERVICE_TOKEN, TrainingModule } from '@luis/training';
import { BladeTrackerService } from '@luis/ui';
import { UtterancesModule } from '@luis/utterances';
import { BehaviorSubject, Subscription } from 'rxjs/Rx';
import { CollaboratorEventsProxyService } from './shared/services/collaborator-events-proxy.service';
import { TrainingEventsProxyService } from './shared/services/training-events-proxy.service';

@Component({
	selector: 'application',
	templateUrl: './application.component.html',
	styleUrls: ['./application.component.scss'],
	providers: [
		...AppsModule.providersForChild(),
		...CoreModule.providersForChild(),
		...DomainsModule.providersForChild(),
		...EntitiesModule.providersForChild(),
		...FeaturesModule.providersForChild(),
		...IntentsModule.providersForChild(),
		...PatternsModule.providersForChild(),
		...PublishPaneModule.providersForChild(),
		...TestPaneModule.providersForChild(),
		...UtterancesModule.providersForChild(),
		...TrainingModule.providersForChild(),
		TrainingEventsProxyService,
		CollaboratorEventsProxyService,
		BladeTrackerService,
		MatDialog
	]
})
export class ApplicationComponent implements OnInit, OnDestroy {
	public testButtonClicked: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	public testPaneClosed: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	public toasterPosition: number = 100;

	private _trainSubscription: Subscription = new Subscription();
	private _modalToggleSubscription: Subscription = new Subscription();

	constructor(
		private readonly _route: ActivatedRoute,
		private readonly _baseService: BaseService,
		private readonly _authService: AuthService,
		private readonly _eventBusService: EventBusService,
		private readonly _dirtyBitService: DirtyBitService,
		private readonly _gateKeeperService: GateKeeperService,
		private readonly _trainingProxyService: TrainingEventsProxyService,
		private readonly _collaboratorsProxyService: CollaboratorEventsProxyService,
		@Inject(TRAINING_SERVICE_TOKEN) private readonly _trainingService: ITrainingService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _appVersionService: IAppVersionService,
		@Inject(TRAINING_RESULT_SERVICE_TOKEN) private readonly _trainingResultService: ITrainingResultService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._trainingResultService.resetService();
		this._trainSubscription.unsubscribe();
		this._modalToggleSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Initializes the component. Note that the collaborators and the
	 * training proxy services MUST be initialized after the luis config
	 * has been updated.
	 */
	private _initState(): void {
		this._route.data
			.first()
			.map(data => <App>data.app)
			.do(app => this._updateConfigsAndSettings(app.id, app.activeVersion, app.culture))
			.do(() => this._collaboratorsProxyService.initService())
			.do(() => this._trainingProxyService.initService())
			.do(() => this._initializeTrainingServiceStatus())
			.subscribe();

		this._modalToggleSubscription = this._eventBusService.subscribeToBus(
			BUS_EVENTS.MODAL_TOGGLED,
			isOpen => (this.toasterPosition = isOpen ? 0 : 100)
		);

		this._trainingResultService.initService();
	}

	/**
	 * @description
	 * Updates the luis configuration object with the current app info.
	 *
	 * @param appId The current app id.
	 * @param versionId The current version id.
	 * @param appCulture The current app culture.
	 */
	private _updateConfigsAndSettings(appId: string, versionId: string, appCulture: string): void {
		this._baseService.setConfigs({
			appId: appId,
			versionId: versionId
		});

		this._gateKeeperService.fetchPermissions({
			culture: appCulture,
			email: this._authService.authInfo.email,
			env: this._baseService.configs.env
		});
	}

	/**
	 * @description
	 * Initialzies application specific subscriptions concerning
	 * training and app state.
	 */
	private _initializeTrainingServiceStatus(): void {
		this._appVersionService
			.getVersion(this._baseService.configs.versionId)
			.first()
			.map(version => version.isTrained)
			.subscribe(status => this._trainingService.initializeTrainingStatus(status));

		this._appVersionService
			.getVersion(this._baseService.configs.versionId)
			.first()
			.map(version => version.isDirty)
			.subscribe(isDirty => this._dirtyBitService.setDirty(isDirty));

		this._trainSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.TRAIN_OCCURRED, isUpToDate => {
			this._dirtyBitService.setDirty(false);
			if (!isUpToDate) {
				this._appVersionService
					.getVersion(this._baseService.configs.versionId)
					.first()
					.do(version => (version.trainedDate = new Date()))
					.do(version => (version.isDirty = false))
					.flatMap(version => this._appVersionService.put(null, version))
					.subscribe();
			}
		});
	}
}
