import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { GenericPromptService, IToasterService, PromptButtonTypes, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { Entity, UtteranceEntity } from '@luis/entities';
import { Intent, UtteranceIntent } from '@luis/intents';
import { TranslateService } from '@ngx-translate/core';
import { first, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs/Rx';
import { IUtteranceService, UTTERANCE_SERVICE_TOKEN } from '../../../../interfaces/services/IUtteranceService';
import { VIEW_OPTIONS } from '../../../../models/plain-segment.model';
import { Utterance, UTTERANCE_STATUS } from '../../../../models/utterance.model';
import { PredictionCacheService } from '../../../../services/prediction-cache.service';
import { UtteranceStoreService } from '../../../../services/utterance-store.service';
import { LabelableUtteranceComponent } from '../../../labelable-utterance/labelable-utterance.component';

/**
 * @description
 * Represents an utterance row in the utterance table. Manages mediating events emitting
 * from entity and intent changes to the parent table.
 */
@Component({
	selector: 'utterance-row',
	templateUrl: 'utterance-row.component.html',
	styleUrls: ['./utterance-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtteranceRowComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public modelId: string;
	@Input() public utterance: Utterance;
	@Input() public intents: Observable<Intent[]>;
	@Input() public editMode: Observable<boolean>;
	@Input() public trainingDate: Observable<Date>;
	@Input() public isSelected: Observable<boolean>;
	@Input() public tableView: BehaviorSubject<VIEW_OPTIONS>;

	@Output() public editModeChanged: EventEmitter<void> = new EventEmitter<void>();
	@Output() public selectionChanged: EventEmitter<void> = new EventEmitter<void>();
	@Output() public editEntity: EventEmitter<Entity> = new EventEmitter<Entity>();

	public isNewUtterance: Observable<boolean>;

	public labelingMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public utteranceStatus: BehaviorSubject<UTTERANCE_STATUS> = new BehaviorSubject<UTTERANCE_STATUS>(UTTERANCE_STATUS.CORRECT);

	public nearestRival: UtteranceIntent;
	public utteranceEntities: UtteranceEntity[];
	public viewOps: typeof VIEW_OPTIONS = VIEW_OPTIONS;
	public utteranceStates: typeof UTTERANCE_STATUS = UTTERANCE_STATUS;
	public clickOutsideChecker: typeof LabelableUtteranceComponent.clickOutsideChecker = LabelableUtteranceComponent.clickOutsideChecker;

	private _delayedAddLabelsSubscription: Subscription;
	private readonly _resetTimer: Subject<void> = new Subject<void>();

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _liveAnnouncer: LiveAnnouncer,
		private readonly _promptService: GenericPromptService,
		private readonly _utteranceStoreService: UtteranceStoreService,
		private readonly _predictionCacheServiceService: PredictionCacheService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(UTTERANCE_SERVICE_TOKEN) private readonly _utteranceService: IUtteranceService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.utterance) {
			this.nearestRival = this._getNearestIntent();
		}
	}

	public ngOnDestroy(): void {
		this._delayedAddLabelsSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Adds the changed utterance.
	 *
	 * @param intentName The intent name that the utterance changed to.
	 */
	public onIntentChange(intent: Intent): void {
		this.utterance.labeledIntent =
			this.utterance.predictedIntents.find(i => i.id === intent.id) || new UtteranceIntent(intent.id, intent.name, null);
		this._utteranceService
			.add(this.utterance)
			.trackProgress(this._toasterService.add())
			.subscribe(() =>
				this._liveAnnouncer.announce(
					`${this._i18n.instant('utterances.utterance-row.reassign_intent_announcement')} ${intent.name}`
				)
			);
	}

	/**
	 * @description
	 * Updates the utterance labeled entities and resets the 5 seconds timer observable that is responsible for submitting
	 * the labeled entities to the backend.
	 *
	 * @param utteranceEntities The new array of utterance entities to change to.
	 */
	public onEntityChange(utteranceEntities: UtteranceEntity[]): void {
		this.labelingMode.next(true);
		this.utterance.labeledEntities = utteranceEntities;
		this._resetTimer.next();
	}

	/**
	 * @description
	 * Updates the utterance labels immediately.
	 */
	public onClickOutside(): void {
		this._updateUtteranceLabeledEntities().subscribe(() => {});
	}

	/**
	 * @description
	 * Updates the utterance text.
	 *
	 * @param utterance The utterance object with the new text.
	 */
	public updateUtterance(utterance: Utterance): void {
		utterance.text = utterance.text.trim();

		if (utterance.text === '') {
			return;
		}

		if (this.utterance.text.trim() !== utterance.text) {
			this._predictionCacheServiceService
				.predict(utterance.text)
				.do(u => (u.labeledIntent = this.utterance.labeledIntent.clone()))
				.do(u => (u.labeledEntities = u.predictedEntities.filter(e => Entity.isMachineLearned(e.type))))
				.flatMap(u => this._utteranceService.replace(u, this.utterance.id))
				.trackProgress(this._toasterService.add())
				.subscribe(() => null);
		}

		this.editModeChanged.emit();
	}

	/**
	 * @description
	 * Notifies parent that an entity was requested for management.
	 *
	 * @param entity The entity to manage.
	 */
	public onEditEntity(entity: Entity): void {
		this.editEntity.emit(entity);
	}

	/**
	 * @description
	 * When the user refreshes or closes the page, any unsaved labels are submitted.
	 */
	@HostListener('window:beforeunload', ['$event'])
	public beforeUnloadHander(event: any): boolean {
		const isLabelingMode: boolean = this.labelingMode.getValue();

		if (isLabelingMode) {
			this._utteranceService.addSync(this.utterance);
		}

		return true;
	}

	public editUtterance(): void {
		this.selectionChanged.emit();
		this.editModeChanged.emit();
	}

	/**
	 * @description
	 * Deletes this utterance.
	 */
	public deleteUtterance(): void {
		this._promptService
			.prompt(
				this._i18n.instant('utterances.utterance-row.delete_utterance'),
				this._i18n.instant('utterances.utterance-row.are_you_want_to_delete_utterance'),
				{ ok: this._i18n.instant('utterances.utterance-row.ok'), cancel: this._i18n.instant('utterances.utterance-row.cancel') },
				{ ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
			)
			.filter(choice => choice === this._i18n.instant('utterances.utterance-row.ok'))
			.flatMap(() => this._utteranceService.delete(this.utterance.id))
			.trackProgress(this._toasterService.add())
			.subscribe(() => undefined);
	}

	/**
	 * @description
	 * Adds this utterance as new pattern.
	 */
	public addAsPattern(): void {
		this.selectionChanged.emit();
		this._utteranceStoreService
			.addAsPattern()
			.pipe(first())
			.subscribe();
	}

	/**
	 * @description
	 * Initialize the component.
	 */
	private _initState(): void {
		this._delayedAddLabelsSubscription = this._resetTimer
			.asObservable()
			.switchMap(() => Observable.timer(5000))
			.flatMap(() => this._updateUtteranceLabeledEntities())
			.subscribe();

		this._updateUtteranceEntities();

		this.nearestRival = this._getNearestIntent();

		this.isNewUtterance = this.trainingDate.pipe(
			map(d => d < this.utterance.assignedDate),
			shareReplay()
		);
	}

	/**
	 * @description
	 * Gets the nearest rival intent for the labeled intent to the current utterance.
	 *
	 * @returns The nearest intent.
	 */
	private _getNearestIntent(): UtteranceIntent {
		const intent = this.utterance.nearestRival;
		if (this.utterance.trainingEvaluation) {
			if (this.utterance.trainingEvaluation.incorrectIntents.length > 0) {
				this.utteranceStatus.next(UTTERANCE_STATUS.INCORRECT);
			} else if (this.utterance.trainingEvaluation.ambiguousIntents.length > 0) {
				this.utteranceStatus.next(UTTERANCE_STATUS.UNCLEAR);
			}
		} else {
			this.utteranceStatus.next(
				intent
					? this.utterance.labeledIntent.score - intent.score > 0
						? UTTERANCE_STATUS.CORRECT
						: UTTERANCE_STATUS.INCORRECT
					: UTTERANCE_STATUS.CORRECT
			);
		}

		return intent;
	}

	/**
	 * @description
	 * Populates the utterance entities used to generate suggested patterns.
	 */
	private _updateUtteranceEntities(): void {
		this.utteranceEntities = [
			...this.utterance.labeledEntities,
			...this.utterance.predictedEntities.filter(pE => !Entity.isMachineLearned(pE.type))
		];
	}

	/**
	 * @description
	 * Updates the utterance labels if the labelingMode is set to true.
	 */
	private _updateUtteranceLabeledEntities(): Observable<number> {
		if (this.labelingMode.getValue()) {
			this._updateUtteranceEntities();
			this.labelingMode.next(false);

			return this._utteranceService.add(this.utterance).trackProgress(this._toasterService.add());
		} else {
			return Observable.of(null);
		}
	}
}
