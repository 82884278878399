import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ISearchPipeProps, IToasterService, SEARCH_TYPES, TOASTER_SERVICE_TOKEN } from '@luis/core';
import {
    Entity,
    ENTITY_SERVICE_TOKEN,
    ENTITY_TYPES,
    EntityHelpers,
    EntityRole,
    IEntityService,
    ItemsContainerComponent
} from '@luis/entities';
import { DOWN_ARROW, ENTER, LEFT_ARROW, RIGHT_ARROW, TAB, UP_ARROW } from '@luis/ui';
import { of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';

/**
 * @description
 * Represents a role dropdown for use in pattern input fields.
 * Shows the roles for a given entity name.
 */
@Component({
    selector: 'role-dropdown',
    templateUrl: 'role-dropdown.component.html',
    styleUrls: ['role-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleDropdownComponent implements OnInit {
    @Input() public searchTerm: string = '';
    @Input() public fieldRef: HTMLInputElement;
    @Output() public roleClicked: EventEmitter<EntityRole> = new EventEmitter<EntityRole>();
    @Output() public newEntityClick: EventEmitter<[string, string]> = new EventEmitter<[string, string]>();
    @Output() public requestClose: EventEmitter<void> = new EventEmitter<void>();

    public foundEntity: Observable<Entity>;
    public roles: Observable<EntityRole[]>;
    public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public searchTypes: typeof SEARCH_TYPES = SEARCH_TYPES;
    public hasBeenNavigated: boolean;
    public searchProps: Observable<ISearchPipeProps> = Observable.of({ caseSensitive: false, searchType: SEARCH_TYPES.STARTS_WITH });
    public entityName: string;

    private _trigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _roleName: string;

    @ViewChild(ItemsContainerComponent) public container: ItemsContainerComponent;

    constructor(
        private _i18n: TranslateService,
        @Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService,
        @Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService
    ) {}

    public ngOnInit(): void {
        this._initState();
    }

    public get menuItemSecondaryText(): Observable<string> {
        return this.foundEntity.pipe(
            flatMap(entity => {
                if (entity) {
                    return this.querySubject;
                }

                return this.querySubject.pipe(map(query => `${this.entityName}:${query}`));
            })
        );
    }

    /**
     * @description
     * Emits an event when a new entity in the dropdown is requested to be added.
     */
    public onNewRoleClick(): void {
        const query: string = this.querySubject.getValue().trim();

        if (query !== '') {
            this.foundEntity.first().subscribe(entity => {
                if (entity) {
                    of(entity)
                        .map(e => {
                            const newRole: EntityRole = new EntityRole('', query, ENTITY_TYPES.ROLE, e);
                            e.roles.push(newRole);

                            return newRole;
                        })
                        .mergeMap(r => this._entityService.add(r).map(id => ({ role: r, id: id })))
                        .do(data => (data.role.id = <string>data.id))
                        .do(_ => this._trigger.next(true))
                        .trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('patterns.role-dropdown.create_toast') }))
                        .subscribe(data => this.roleClicked.emit(data.role));
                } else {
                    this.newEntityClick.emit([this.entityName, query]);
                }
            });
        }
    }

    /**
     * @description
     * Handles the keydown for accessbility for this component.
     */
    @HostListener('keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent): void {
        let stopEvent: boolean = true;

        switch (event.keyCode) {
            case DOWN_ARROW:
                if (!this.hasBeenNavigated) {
                    this.container.focus();
                }
                this.hasBeenNavigated = true;
                break;
            case UP_ARROW:
            case TAB:
                this.hasBeenNavigated = true;
                break;
            case ENTER:
                if (!this.hasBeenNavigated) {
                    this.container.focus();
                }
                break;
            case LEFT_ARROW:
            case RIGHT_ARROW:
                this.fieldRef.focus();
                break;
            default:
                this.fieldRef.focus();
                stopEvent = false;
        }

        if (stopEvent) {
            event.stopPropagation();
        }
    }

    /**
     * @description
     * Initialize the component.
     */
    private _initState(): void {
        [this.entityName, this._roleName] = this._extractSearchTermInfo(this.searchTerm);

        this.querySubject.next(this._roleName);

        this.foundEntity = Observable.combineLatest(this._entityService.get(), this._trigger.asObservable()).map(([entities]) =>
            EntityHelpers.expandChildren(entities).find(e => e.name === this.entityName)
        );

        this.roles = this.foundEntity.map(e => (e ? e.roles.sort((a, b) => (a.name.length > b.name.length ? 1 : -1)) : []));
    }

    /**
     * @description
     * Extracts the entity name and role from the search
     * term given.
     *
     * @param term The term that was searched for.
     * @returns A pair of strings, including
     * the entity and role names.
     */
    private _extractSearchTermInfo(term: string): [string, string] {
        const entity: string = term.slice(0, term.indexOf(':'));
        const role: string = term.slice(term.indexOf(':') + 1);

        return [entity, role];
    }
}
