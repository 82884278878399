import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { IResourceOperation, SelectionMap } from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { PHRASE_LIST_OPERATIONS } from '../../../models/phrase-list-operations.model';
import { PhraseList } from '../../../models/phrase-list.model';

/**
 * @description
 * Represents a phrase lists table. Lists the phrase lists in an application. Provides
 * tools for manipulating the phrase lists in the table.
 */
@Component({
    selector: 'phrase-list-table',
    templateUrl: './phrase-list-table.component.html',
    styleUrls: ['./phrase-list-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhraseListTableComponent implements OnInit {
    @Input() public phraseLists: Observable<PhraseList[]>;
    @Input() public areSuggestionsEnabled: boolean = true;
    @Output() public phraseListClick: EventEmitter<PhraseList> = new EventEmitter<PhraseList>();
    @Output() public applyOperation: EventEmitter<IResourceOperation> = new EventEmitter<IResourceOperation>();

    public selectedPhraseListsIds: Observable<number[]>;
    public selectionMap: SelectionMap = new SelectionMap();
    public phraseListOps: any = PHRASE_LIST_OPERATIONS;
    public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
        property: 'name', type: SORT_TYPE.ASCENDING
    });

    constructor(
        private _cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this._initState();
    }

    /**
     * @description
     * Notifies parent that an operation was clicked to apply to the given app.
     *
     * @param operation The operation to run. Should be one of the allowed operations
     * in the PHRASE_LIST_OPERATIONS enum.
     */
    public onOperationClick(operation: PHRASE_LIST_OPERATIONS): void {
        const selectedphraseListsIds: number[] = <number[]>this.selectionMap.selectedItems;
        const phraseListOperation: IResourceOperation = { items: [], operation: operation, response: new BehaviorSubject<boolean>(false) };

        this.phraseLists
            .first()
            .map(phraseLists => phraseLists.filter(phraseList => selectedphraseListsIds.indexOf(phraseList.id) !== -1))
            .do(selectedPhraseLists => phraseListOperation.items = selectedPhraseLists)
            .subscribe(() => this.applyOperation.emit(phraseListOperation));

        phraseListOperation.response.subscribe(null, null, () => {
            this.selectionMap.markUnselected(selectedphraseListsIds);
            setTimeout(() => this._cdRef.detectChanges(), 0);
        });
    }

    /**
     * @description
     * Initializes the component state.
     */
    private _initState(): void {
        this.selectedPhraseListsIds = <Observable<number[]>>this.selectionMap.selectedItemsAsync;
    }
}
