import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 * Trims the given string to a given length. Adds ellipsis in the end.
 */
@Pipe({
    name: 'textMiddleEllipsis'
})
export class TextMiddleEllipsisPipe implements PipeTransform {
    public transform(data: string, prefix: number, suffix: number): string {
        if (data) {

            if (data.length > prefix + suffix) {
                return data.slice(0, prefix).concat('...').concat(data.slice(data.length - suffix, -1));
            }
            else {
                const partition: number = data.length / 4;

                return data.slice(0, partition).concat('...').concat(data.slice(data.length - partition, -1));
            }
        }
        else {

            return '';
        }
    }
}
