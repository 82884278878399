import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Entity } from '@luis/entities';
import { Intent } from '@luis/intents';
import { BLADE_OPS, BladeComponent, BLADES, BladeTrackerService, ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { Options } from 'ng5-slider';
import { BehaviorSubject, Observable } from 'rxjs';
import { EVALUATION_TYPES, EvaluationFilters } from '../../../models/filters/evaluation-filter.model';

/**
 * @description
 * Represents the filters blade.
 */
@Component({
	selector: '[basic-filters-blade]',
	templateUrl: 'basic-filters-blade.component.html',
	styleUrls: ['basic-filters-blade.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicFiltersBladeComponent extends BladeComponent {
	@Input() public isFilterActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() public results: Observable<number>;
	@Input() public intentsToChoose: Observable<Intent[]>;
	@Input() public filteredIntents: Observable<Map<string, Intent>>;
	@Input() public entitiesToChoose: Observable<Entity[]>;
	@Input() public filteredEntities: Observable<Map<string, Entity>>;
	@Input() public activeEvaluations: BehaviorSubject<EvaluationFilters> = new BehaviorSubject<EvaluationFilters>({
		correct: false,
		incorrect: false,
		unclear: false
	});

	@Output() public filterUtterancesByIntent: EventEmitter<Intent> = new EventEmitter<Intent>();
	@Output() public filterUtterancesByEntity: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() public evaluationFilter: EventEmitter<EvaluationFilters> = new EventEmitter<EvaluationFilters>();
	@Output() public scoreFilter: EventEmitter<[number, number]> = new EventEmitter<[number, number]>();
	@Output() public clearFilters: EventEmitter<void> = new EventEmitter<void>();

	public evaluationTypes: typeof EVALUATION_TYPES = EVALUATION_TYPES;
	public currentScore: number = 0;
	public minScore: number = 0;
	public maxScore: number = 1;
	public sliderOptions: Options = {
		floor: 0,
		ceil: 1,
		step: 0.01,
		hideLimitLabels: true,
		noSwitching: true
	};
	public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
		property: 'name',
		type: SORT_TYPE.ASCENDING
	});

	protected currentBlade: BLADES = BLADES.FILTER;

	constructor(private readonly _bladeTrackerService: BladeTrackerService) {
		super(_bladeTrackerService);
	}

	public evaluationFilterChanged(type: number): void {
		const types = this.activeEvaluations.getValue();
		const currTypes = {
			correct: type !== EVALUATION_TYPES.CORRECT ? types.correct : !types.correct,
			incorrect: type !== EVALUATION_TYPES.INCORRECT ? types.incorrect : !types.incorrect,
			unclear: type !== EVALUATION_TYPES.UNCLEAR ? types.unclear : !types.unclear
		};
		this.evaluationFilter.emit(currTypes);
	}

	public openEntityBlade(): void {
		this._bladeTrackerService.applyOp(BLADE_OPS.ENTITIES_OPEN);
		this.closeBlade();
	}

	public openIntentBlade(): void {
		this._bladeTrackerService.applyOp(BLADE_OPS.INTENT_OPEN);
		this.closeBlade();
	}

	public clearFilter(): void {
		this.currentScore = 0;
		this.minScore = 0;
		this.maxScore = 1;
		this.clearFilters.emit();
	}
}
