export class UserRouteBuilder {
    private _route: string;

    constructor(prefix: string = '') {
        this._route = prefix;
    }

    public bfLogin(): UserRouteBuilder {
        this._route = this._route.concat('/subkey');

        return this;
    }

    public bfLoginLegacy(): UserRouteBuilder {
        this._route = this._route = '/home/subkey';

        return this;
    }

    public loader(): UserRouteBuilder {
        this._route = this._route.concat('/loader');

        return this;
    }

    public settings(): UserRouteBuilder {
        this._route = this._route.concat('/settings');

        return this;
    }

    public tou(): UserRouteBuilder {
        this._route = this._route.concat('/tou');

        return this;
    }

    public build(): string {
        return this._route;
    }
}
