import { OnDestroy } from '@angular/core';
import { IRequestOptions } from '@luis/api';
import { ISortPipeProps } from '@luis/ui';
import { from, Observable, Subscription } from 'rxjs';
import { flatMap, map, mapTo, reduce, startWith, tap } from 'rxjs/operators';
import { Id, IResource } from '../../interfaces/models/IResource';
import { IPaginationCacheService } from '../../interfaces/services/utils/IPaginationCacheService';
import { IPage, IPageInfo, PaginatedCache } from '../../models/caches/paginated-cache.model';
import { CHUNKING_PROCESS_TYPE, ChunkingProcessPayload } from '../../models/utils/chunking-process-payload.model';
import { BUS_EVENTS } from '../../models/utils/event-bus.model';
import { EventBusService } from '../utils/event-bus.service';

/**
 * @description
 * A generic implementation of the pagination cache service interface.
 * It interfaces between the cacheing layer and the models service layer exposed to client objects.
 * It handles pagination related tasks (e.g refreshing the data when an item is deleted).
 */
export abstract class PaginationCacheService<T extends IResource> implements IPaginationCacheService<T>, OnDestroy {
	public static readonly PAGE_SIZE: number = 10;
	protected abstract chunkingType: CHUNKING_PROCESS_TYPE;

	protected _cacheMap: Map<string, PaginatedCache<T>>;

	private _chunkingProcessRunningSubscription: Subscription = new Subscription();
	private _countChangesSubscription: Subscription = new Subscription();

	constructor(private _eventBusService: EventBusService) {
		this._cacheMap = new Map();

		this._chunkingProcessRunningSubscription = this._eventBusService.subscribeToBus(
			BUS_EVENTS.CHUNKING_PROCESS_RUNNING,
			(chunkingProcessPayload: ChunkingProcessPayload) => {
				if (chunkingProcessPayload.type === this.chunkingType) {
					this._cacheMap.forEach(cache => (cache.isChunkingProcessRunning = chunkingProcessPayload.started));
				}
			}
		);
	}

	protected abstract httpGet(basePath: string, modelId: string, pageInfo: IPageInfo, options?: IRequestOptions): Observable<any[]>;

	protected abstract httpPost(basePath: string, modelId: string, model: Object | Object[], options: IRequestOptions): Observable<any>;

	protected abstract httpPut(basePath: string, modelId: string, model: Object | Object[], options: IRequestOptions): Observable<any>;

	protected abstract httpDelete(basePath: string, modelId: string, id: Id, options: IRequestOptions): Observable<any>;

	protected abstract reflectModelInCaches(
		basePath: string,
		model: T,
		idToReplace?: Id,
		outOfOrder?: boolean,
		cachePath?: string
	): Observable<void>;

	protected abstract reflectModelsInCaches(basePath: string, models: T[], type?: string): Observable<void>;

	public ngOnDestroy(): void {
		this._chunkingProcessRunningSubscription.unsubscribe();
		this._countChangesSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Invalidates all caches.
	 */
	public invalidate(): void {
		this._cacheMap.forEach(cache => cache.invalidate(true));
	}

	/**
	 * @description
	 * Invalidates the cache starting from the first missing page.
	 */
	public removePageGaps(modelId: string): void {
		const cache = this._getCache(modelId);
		cache.invalidate();
	}

	/**
	 * @description
	 * Gets the total size of items in the specified model
	 */
	public getTotalSize(modelId: string): number {
		return this._getCache(modelId).totalSize;
	}

	/**
	 * @description
	 * Returns whether the given model's cache is all fetched from the backend or not
	 *
	 * @param modelId The ID of the model to get the utterances for
	 */
	public isAllFetched(modelId: string): boolean {
		const cache = this._getCache(modelId);

		return cache.totalSize === cache.currentSize;
	}

	/**
	 * @description
	 * Adds a new page to the paginated cache model with the specified page index
	 *
	 * @param modelId The ID of the model to get the models for
	 * @param models An array of models to be added to the new page
	 * @param pageIndex Index of the new page
	 */
	public addPage(modelId: string, models: T[], pageIndex: number): void {
		const cache = this._getCache(modelId);
		cache.addPage(models, pageIndex);
	}

	/**
	 * @description
	 * Updates the cache with the new models
	 *
	 * @param modelId The Id of the model to merge the models for.
	 * @param models An array of models to be added to the new page.
	 * @param updateFunction A pure function that merges two models and produces a new one.
	 */
	public mergeBatch(modelId: string, models: T[], updateFunction?: (oldModel: T, newModel: T) => T): void {
		const cache = this._getCache(modelId);

		cache.mergeBatch(models, updateFunction);
	}

	/**
	 * @description
	 * Gets the specified page for the given model, assumes that the caches are prepopulated with the counts.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the utterances for.
	 * @param options Http request options.
	 * @param pageIndex The index of the page to be fetched from the backend.
	 * @param getAll Whether to get everything from the cache or get the specified page only. If getAll is set true.
	 * no network requests calls will be made.
	 *
	 * @returns The page for the given model and page index.
	 */
	public get(
		basePath: string,
		modelId: string,
		pageIndex: number,
		getAll: boolean,
		options: IRequestOptions,
		parser: (response: any) => T,
		sortingProps: ISortPipeProps
	): Observable<IPage<T>> {
		const cache = this._getCache(modelId, sortingProps);

		if (getAll) {
			return cache.getAllStream();
		}

		return cache.getPageStream(pageIndex).pipe(
			tap(page => {
				if (!page.isInitialized && !page.isRefreshing) {
					this.refreshPage(basePath, modelId, pageIndex, options, parser).subscribe();
				}
			})
		);
	}

	/**
	 * @description
	 * Adds a model to the web service. Reflects this addition to the
	 * relevant caches stored in memory.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the utterances for.
	 * @param model The model to add.
	 * @param outOfOrder Indicates whether the item belongs to the first page or not.
	 * @returns An observable to indicate completion.
	 */
	public post(
		basePath: string,
		modelId: string,
		model: T,
		options: IRequestOptions,
		parser: (response: any) => Id,
		outOfOrder: boolean
	): Observable<Id> {
		return this.httpPost(basePath, modelId, model.exportToApi(), options).pipe(
			map(parser),
			tap(id => (model.id = id)),
			flatMap(id => this.reflectModelInCaches(basePath, model.clone(), undefined, outOfOrder, modelId).mapTo(id))
		);
	}

	/**
	 * @description
	 * Batch adds multiple models to the web service. Reflects the additions
	 * to the caches stored in memory.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the utterances for.
	 * @param models The models to add.
	 * @returns An array of ids resulting from the addition process. If any of the entries is null, the corresponding models
	 * addition failed.
	 */
	public batchPost(
		basePath: string,
		modelId: string,
		models: T[],
		options: IRequestOptions,
		parser: (response: any) => Id[],
		type?: string
	): Observable<Id[]> {
		return this.httpPost(basePath, modelId, models.map(u => u.exportToApi()), options).pipe(
			flatMap(response => {
				const ids = parser(response);
				let modelsWithIds: T[] = models.map(u => u.clone());
				modelsWithIds.forEach((u, i) => (u.id = ids[i]));
				modelsWithIds = modelsWithIds.filter(u => u.id !== null);

				return this.reflectModelsInCaches(basePath, modelsWithIds, type).mapTo(ids);
			})
		);
	}

	/**
	 * @description
	 * Replaces the new model with an the old model with the given id.
	 * Adds the new model in the same ordered place as the old model.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the models for.
	 * @param model The model to add and replace in the cache.
	 * @param idToReplace The id of the model to replace.
	 * @returns An observable of the new model id.
	 */
	public replace(
		basePath: string,
		modelId: string,
		model: T,
		idToReplace: Id,
		options: IRequestOptions,
		parser: (response: any) => Id
	): Observable<Id> {
		return this.httpPut(basePath, modelId, model.exportToApi(), options).pipe(
			flatMap(response => {
				model.id = parser(response);

				return this.reflectModelInCaches(basePath, model.clone(), idToReplace).mapTo(model.id);
			})
		);
	}

	/**
	 * @description
	 * Deletes the model with the given model id from the system and caches.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the models for.
	 * @param id The id of the model to delete.
	 * @returns An observable to indicate completion.
	 */
	public delete(basePath: string, modelId: string, id: Id, options: IRequestOptions, parser: (response: any) => T): Observable<void> {
		return this.batchDelete(basePath, modelId, [id], options, parser).pipe(mapTo(undefined));
	}

	/**
	 * @description
	 * Deletes the models with the given ids from the application and caches.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The ID of the model to get the models for.
	 * @param ids The ids of the models to delete.
	 * @returns An observable to indicate completion.
	 */
	public batchDelete(
		basePath: string,
		modelId: string,
		ids: Id[],
		options: IRequestOptions,
		parser: (response: any) => T
	): Observable<Id[]> {
		return from(ids).pipe(
			flatMap(id => this.httpDelete(basePath, modelId, id, options).pipe(mapTo(id))),
			// Merge deleted utterances ids into one stream
			reduce((accIds: number[], element: number) => [...accIds, element], []),
			// Refetch deleted page if needed
			flatMap(allIds => {
				for (const [key, cache] of this._cacheMap) {
					const pageInfo = cache.deleteBatch(allIds);
					if (pageInfo) {
						cache.setRefreshing(true);

						return this.refreshPage(basePath, key, pageInfo.skip / pageInfo.take, options, parser).pipe(mapTo(allIds));
					}
				}

				return Observable.of(allIds);
			})
		);
	}

	/**
	 * @description
	 * A concrete implementation for requesting a new page of utterances from the backend and updating the cache.
	 *
	 * @param basePath The base url for the api to call.
	 * @param modelId The Id of the model to get the utterances for.
	 * @param pageIndex The index of the page to be fetched
	 * @param options Http request options.
	 * @returns An observable of the newly (refreshed) page
	 */
	protected refreshPage(
		basePath: string,
		modelId: string,
		pageIndex: number,
		options: IRequestOptions,
		parser: (response: any) => T
	): Observable<IPage<T>> {
		const cache: PaginatedCache<T> = this._getCache(modelId);

		return this.httpGet(
			basePath,
			modelId,
			{ skip: pageIndex * PaginationCacheService.PAGE_SIZE, take: PaginationCacheService.PAGE_SIZE },
			options
		).pipe(
			map(response => (<any[]>response).map(parser)),
			// Add models to cache
			tap(models => cache.addPage(models, pageIndex)),
			// Refresh flag can be set to false now since the page is already loaded
			tap(() => cache.setRefreshing(false)),
			map(() => cache.getPage(pageIndex)),
			// Displays total size and loading indicator when the page is still loading
			startWith(cache.getPage(pageIndex))
		);
	}

	/**
	 * @description
	 * Creates empty caches with the total sizes fetched from the backend.
	 */
	protected prepopulateCaches(labelsMap: Map<string, number>, sortingProps?: ISortPipeProps): void {
		labelsMap.forEach((count, id) => {
			if (this._cacheMap.has(id)) {
				this._cacheMap.get(id).totalSize = count;
			} else {
				this._cacheMap.set(id, new PaginatedCache<T>(PaginationCacheService.PAGE_SIZE, count, sortingProps));
			}
		});
	}

	/**
	 * @description
	 * Gets the paginated cache from the cache map given the model id.
	 * If the cache didn't exist for the given model id, a new one is created.
	 *
	 * @param modelId The model id that serves as a cache key.
	 * @returns The paginated cache corresponding to the model id.
	 */
	protected _getCache(modelId: string, sortingProps?: ISortPipeProps): PaginatedCache<T> {
		if (!this._cacheMap.has(modelId)) {
			this._cacheMap.set(modelId, new PaginatedCache<T>(PaginationCacheService.PAGE_SIZE, 0, sortingProps));
		}

		return this._cacheMap.get(modelId);
	}
}
