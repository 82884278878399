import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@luis/core';
import { IntercomUIModule } from '@luis/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccuracyChartComponent } from './components/accuracy-chart/accuracy-chart.component';
import { DistributionChartComponent } from './components/distribution-chart/distribution-chart.component';
import { FaultyIntentsComponent } from './components/faulty-intents/faulty-intents.component';
import { TRAINING_RESULT_SERVICE_TOKEN } from './interfaces/ITrainingResultService';
import { ANALYTICS_UI_STATE_SERVICE_TOKEN, AnalyticsUIStatusService } from './services/analytics-ui-status.service';
import { TrainingResultService } from './services/training-result.service';

@NgModule({
	imports: [CoreModule, IntercomUIModule, NgxPaginationModule, FormsModule, ReactiveFormsModule, TranslateModule],
	declarations: [AccuracyChartComponent, DistributionChartComponent, FaultyIntentsComponent],
	exports: [AccuracyChartComponent, DistributionChartComponent, FaultyIntentsComponent]
})
export class TrainingModule {
	public static providersForChild(): Provider[] {
		return [
			{
				provide: TRAINING_RESULT_SERVICE_TOKEN,
				useClass: TrainingResultService
			},
			{
				provide: ANALYTICS_UI_STATE_SERVICE_TOKEN,
				useClass: AnalyticsUIStatusService
			}
		];
	}
}
