import { Inject, Injectable, OnDestroy } from '@angular/core';
import { RequestOptionsBuilder } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	EventBusService,
	GENERIC_CACHE_SERVICE_TOKEN,
	IGenericCacheService,
	IModelChangeBusMessage,
	MODEL_CHANGE_TYPE
} from '@luis/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { IIntentEntity } from '../interfaces/IIntentEntity';
import { Intent } from '../models/intent.model';

/**
 * @description
 * Contains various utility service functions related to intents, that are not intent
 * CRUD operations.
 */
@Injectable()
export class IntentUtilitiesService implements OnDestroy {
	private _utteranceChangeBusSubscription: Subscription = new Subscription();
	private _intentChangeSubscription: Subscription = new Subscription();
	private _domainChangeSubscription: Subscription = new Subscription();

	constructor(
		private _baseService: BaseService,
		private _eventBusService: EventBusService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private _cacheService: IGenericCacheService
	) {
		this._subscribeToEventBus();
	}

	public ngOnDestroy(): void {
		this._utteranceChangeBusSubscription.unsubscribe();
		this._intentChangeSubscription.unsubscribe();
		this._domainChangeSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Getter for the base web service url.
	 *
	 * @returns The base web service url.
	 */
	private get _baseUrl(): string {
		return `apps/${this._baseService.configs.appId}/versions/${this._baseService.configs.versionId}`;
	}

	/**
	 * @method
	 * @description
	 * Gets the number of labeled utterances for each intent from the
	 * generic cache service.
	 *
	 * @returns A map of intent id/label count pairs.
	 */
	public getLabelsPerIntent(refresh: boolean = false): Observable<Map<string, number>> {
		const url: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/stats/labelsperintent`;
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return this._cacheService.get(url, this._getStatisticForIntent, builder.build());
	}

	/**
	 * @method
	 * @description
	 * Gets the number of endpoint hits for each intent.
	 *
	 * @returns A map of intent id/label count pairs.
	 */
	public getEndpointsPerIntent(): Observable<Map<string, number>> {
		const url: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/intents/stats/endpointscores`;

		return this._cacheService.get(url, this._getStatisticForIntent, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @method
	 * @description
	 * Gets information about the entities in use in the labeled utterances
	 * of the intent with the given id.
	 *
	 * @param intentId The intent id to get the utterances for.
	 * @returns An observable of the entity count info.
	 */
	public getIntentEntities(intentId: string): Observable<IIntentEntity[]> {
		const url: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/intents/${intentId}/entitiescount`;
		const parser: (data: any[]) => IIntentEntity[] = (data: any[]) => data.map(d => <IIntentEntity>d);

		return this._cacheService.get(url, parser, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Subscribes to the event bus for utterance changes to any of the intents
	 * in the application to update their cached utterance count.
	 */
	private _subscribeToEventBus(): void {
		this._intentChangeSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.MODEL_CHANGED, (event: IModelChangeBusMessage) => {
			if (event.model instanceof Intent && event.changeType !== MODEL_CHANGE_TYPE.UPDATE) {
				const labelsMap: Observable<Map<string, number>> = this.getLabelsPerIntent().first();
				const cacheUrl: string = `${this._baseService.configs.webApiUrl}/${this._baseUrl}/stats/labelsperintent`;
				const options = this._baseService.optionsBuilder.useCacheOnly().build();
				let opObservable: Observable<Map<string, number>>;

				if (event.changeType === MODEL_CHANGE_TYPE.ADD) {
					opObservable = labelsMap.do(map => map.set(event.model.id, 0));
				} else {
					opObservable = labelsMap.do(map => map.delete(event.model.id));
				}

				opObservable.flatMap(map => this._cacheService.put(cacheUrl, map, options)).subscribe();
			}
		});

		this._domainChangeSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.DOMAIN_CHANGED, () =>
			this.getLabelsPerIntent(true)
		);
	}

	/**
	 * @description
	 * Converts a POJO to a Js map.
	 *
	 * @param dictionary A POJO of intent id/ count.
	 * @returns A map of intent id/ count.
	 */
	private _getStatisticForIntent(dictionary: any): Map<string, number> {
		return Object.keys(dictionary).reduce((map, k) => {
			map.set(k, dictionary[k]);

			return map;
		}, new Map<string, number>());
	}
}
