import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Entity, EntityCreationModalComponent, EntityRole } from '@luis/entities';
import { BACKSPACE } from '@luis/ui';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/Rx';
import { IInputHandlerPayload } from '../../../interfaces/IInputHandlerPayload';
import { IPatternFsmResult } from '../../../interfaces/IPatternFsm';
import { BracesFsm } from '../../../models/fsms/braces-fsm.model';

@Component({
	selector: 'braces-handler',
	templateUrl: 'braces-handler.component.html',
	styleUrls: ['braces-handler.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BracesHandlerComponent implements OnInit {
	@Input() public inputField: HTMLInputElement;
	@Input() public responseStream: BehaviorSubject<IInputHandlerPayload>;

	public showDropdown: boolean;
	public showRoleDropdown: boolean;
	public dropdownPosition: number;
	public searchTerm: string;

	private readonly _keyCodes: { openBrace: number; closeBrace: number } = {
		openBrace: 219,
		closeBrace: 221
	};

	constructor(private readonly _dialogService: MatDialog) {}

	public ngOnInit(): void {
		this._initState();
	}

	/**
	 * @description
	 * Closes the dropdowns.
	 */
	public onDropdownRequestClose(): void {
		this.showDropdown = false;
		this.showRoleDropdown = false;
	}

	/**
	 * @description
	 * Replaces the match name with the entity clicked
	 * after creating the new entity.
	 *
	 * @param event An array of the entity name and role name.
	 */
	public onNewEntityClick([entity, role]: [string, string]): void {
		this.showDropdown = false;
		this.showRoleDropdown = false;
		this._dialogService
			.open(EntityCreationModalComponent, { width: '600px', data: { initialEntity: new Entity('', entity), role } })
			.afterClosed()
			.pipe(filter(e => e !== undefined))
			.subscribe(e => this.onExistingEntityClick(e), error => error);
	}

	/**
	 * @description
	 * Injects the entity name at the cursor position or replaces
	 * the existing entity if one already was there.
	 *
	 * @param entity The entity to inject.
	 */
	public onExistingEntityClick(entity: Entity): void {
		const openBrace: number = this.inputField.value.slice(0, this.inputField.selectionStart).lastIndexOf('{');
		let closeBrace: number = this.inputField.value.slice(this.inputField.selectionStart).indexOf('}');

		if (closeBrace !== -1) {
			closeBrace = this.inputField.selectionStart + closeBrace;
		}

		const prefix: string = this.inputField.value.slice(0, openBrace);
		const suffix: string = this.inputField.value.slice(closeBrace !== -1 ? closeBrace + 1 : this.inputField.selectionStart);

		this.responseStream.next({ currentText: `${prefix}{${entity.name}}${suffix}`, currentKeyEvent: null });

		// Ensure that the next change detection cycle detects the selection outside the entity to hide the
		// dropdown menu.
		this.inputField.selectionEnd = this.inputField.selectionStart = `${prefix}{${entity.name}}`.length;

		setTimeout(() => {
			this.inputField.selectionEnd = this.inputField.selectionStart = `${prefix}{${entity.name}}`.length - 1;
			this.inputField.focus();
		});
	}

	/**
	 * @description
	 * Injects the role name at the cursor position or replaces
	 * the existing role if one already was there.
	 *
	 * @param role The role to inject.
	 */
	public onRoleClicked(role: EntityRole): void {
		const colon: number = this.inputField.value.slice(0, this.inputField.selectionStart).lastIndexOf(':');
		let closeBrace: number = this.inputField.value.slice(this.inputField.selectionStart).indexOf('}');

		if (closeBrace !== -1) {
			closeBrace = this.inputField.selectionStart + closeBrace;
		}

		const prefix: string = this.inputField.value.slice(0, colon + 1);
		const suffix: string = this.inputField.value.slice(closeBrace !== -1 ? closeBrace + 1 : this.inputField.selectionStart);
		this.responseStream.next({ currentText: `${prefix}${role.name}}${suffix}`, currentKeyEvent: null });
		this.inputField.selectionEnd = this.inputField.selectionStart = `${prefix}${role.name}}`.length;

		setTimeout(() => this.inputField.focus());
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		this.dropdownPosition = this.inputField.selectionStart * 8.5 + 16;
		this.showDropdown = this._isDropdownOpen();
		this.showRoleDropdown = this._isRoleDropdownOpen();
		this._autoTypeBraces();
		this.searchTerm = this._getSearchTerm();
	}

	/**
	 * @description
	 * Auto types the braces based on the cursor position, the previously
	 * written character, and the next character.
	 */
	private _autoTypeBraces(): void {
		const currentSelection: number = this.inputField.selectionStart;
		const currentStream: IInputHandlerPayload = this.responseStream.getValue();
		const currentCharacter: string = currentStream.currentText.slice(currentSelection - 1, currentSelection);
		const nextCharacter: string = currentStream.currentText.slice(currentSelection, currentSelection + 1);
		const keyCode: number = currentStream.currentKeyEvent ? currentStream.currentKeyEvent.keyCode : null;
		let prefix: string;
		let suffix: string;
		let output: string;
		let newSelection: number = null;

		// Auto closes an open '{'
		if (currentCharacter === '{' && keyCode === 219 && this.showDropdown) {
			prefix = currentStream.currentText.slice(0, this.inputField.selectionStart);
			suffix = currentStream.currentText.slice(this.inputField.selectionStart);
			output = `${prefix}}${suffix}`;
			newSelection = prefix.length;
		}
		// Auto deletes an open '}'
		else if (nextCharacter === '}' && keyCode === BACKSPACE && !this.showDropdown) {
			prefix = currentStream.currentText.slice(0, this.inputField.selectionStart);
			suffix = currentStream.currentText.slice(this.inputField.selectionStart + 1);
			output = `${prefix}${suffix}`;
			newSelection = prefix.length;
		}
		// Auto skips the '}' if cursor was behind an existing '}'
		else if (nextCharacter === '}' && keyCode === this._keyCodes.closeBrace) {
			prefix = currentStream.currentText.slice(0, this.inputField.selectionStart - 1);
			suffix = currentStream.currentText.slice(this.inputField.selectionStart);
			output = `${prefix}${suffix}`;
			newSelection = prefix.length + 1;
		}

		if (newSelection !== null) {
			setTimeout(() => this.responseStream.next({ currentText: output, currentKeyEvent: null }), 0);
			setTimeout(() => {
				this.inputField.selectionEnd = newSelection;
				this.inputField.click();
			}, 0);
		}
	}

	/**
	 * @description
	 * Checks if the dropdown is open or not based on the resulting state
	 * of the finite state machine.
	 */
	private _isDropdownOpen(): boolean {
		const text: string = this.inputField.value.slice(0, this.inputField.selectionStart);
		const result: IPatternFsmResult = new BracesFsm(text).evaluate();

		return result.result;
	}

	/**
	 * @description
	 * Checks if the role dropdown is open or not based on the resulting state
	 * of the finite state machine.
	 */
	private _isRoleDropdownOpen(): boolean {
		const text: string = this.inputField.value.slice(0, this.inputField.selectionStart);
		const fsm: BracesFsm = new BracesFsm(text);
		const result: IPatternFsmResult = fsm.evaluate();

		if (!result.result) {
			return false;
		}

		return fsm.isRoleState();
	}

	/**
	 * @description
	 * Captures the text between the paranthesis where the
	 * cursor exists.
	 *
	 * @returns The text between the paranthesis.
	 */
	private _getSearchTerm(): string {
		if (this.showDropdown) {
			const text: string = this.inputField.value.slice(0, this.inputField.selectionStart);

			return text.slice(text.lastIndexOf('{') + 1);
		}

		return '';
	}
}
