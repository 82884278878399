import { Injectable, OnDestroy } from '@angular/core';
import { RequestOptionsBuilder } from '@luis/api';
import { BaseService, Id, IPage, PaginationResourceCacheService, ProgressTracker, ResourceChunkingService } from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { of } from 'rxjs';
import { filter, mapTo, switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs/Rx';
import { IAppService } from '../interfaces/IAppService';
import { App } from '../models/app.model';

/**
 * @description
 * Represents a concrete implementation of the IAppService interface for app related
 * operations for a LUIS account. This implementation is meant for production use.
 */
@Injectable()
export class AppService implements IAppService, OnDestroy {
	private static sortProps: ISortPipeProps = {
		property: 'createdDate',
		type: SORT_TYPE.DESCENDING
	};

	private _chunkingServiceSubscription: Subscription = new Subscription();
	private _modelName: string = 'apps';

	constructor(
		private _chunkingService: ResourceChunkingService,
		private _baseService: BaseService,
		private _paginationCacheService: PaginationResourceCacheService<App>
	) {}

	public ngOnDestroy(): void {
		this._chunkingServiceSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Intializes the service and hooks in the chunking service.
	 *
	 * @param trigger An observable that's responsible for starting the chunking service.
	 * @param chunkingTracker Progress tracker object that passed to the chunking service.
	 */
	public init(trigger: Observable<boolean>, chunkingTracker: ProgressTracker): void {
		this._chunkingServiceSubscription = this._chunkingService
			.init(
				this._modelName,
				trigger.pipe(
					filter(val => val === true),
					mapTo(null)
				),
				App.importFromApi
			)
			.trackProgress(chunkingTracker.getTracker())
			.subscribe();
	}

	/**
	 * @description
	 * Gets the apps of the account. If the function gets called with refresh set to true, the
	 * chunking service gets initialized and starts fetching chunks from the backend, when the chunking
	 * process completes, the fetch query gets executed which itself gets a page of apps.
	 *
	 * @param pageIndex The index of the page to be fetched.
	 * @param refresh A boolean flag indicating whether the data in the cache should refreshed or not and due
	 * to a legacy use of this parameter which entails fetching all the apps for this account from the backend, this
	 * function then uses the chunking service to refresh all the data in the cache.
	 * @param getAll A flag indicating whether to get all the apps for this account from the cache or not.
	 *
	 * @returns An observable of the apps for this account.
	 */
	public get(pageIndex: number, refresh: boolean = false, getAll: boolean = false): Observable<IPage<App>> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;
		const fetch$ = this._paginationCacheService.get(
			this._modelName,
			this._modelName,
			pageIndex,
			getAll,
			builder.build(),
			App.importFromApi,
			AppService.sortProps
		);

		if (refresh) {
			return this._chunkingService.init(this._modelName, of(null), App.importFromApi).pipe(switchMap(() => fetch$));
		}

		return fetch$;
	}

	/**
	 * @description
	 * Gets a single app with the given id.
	 *
	 * @param id The id of the app to get.
	 *
	 * @returns An observable of the received app.
	 */
	public getSingle(id: string = this._baseService.configs.appId): Observable<App> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;
		const builderWithCacheOnly: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder.useCacheOnly();

		return this._paginationCacheService.getSingle(
			this._modelName,
			id,
			builder.build(),
			builderWithCacheOnly.build(),
			App.importFromApi,
			false,
			AppService.sortProps
		);
	}

	/**
	 * @method
	 * @description
	 * Adds an app to the account.
	 *
	 * @param app The app to add.
	 *
	 * @returns An observable of the id of the newly added app.
	 */
	public add(app: App): Observable<Id | void> {
		return this._paginationCacheService.post(
			this._modelName,
			this._modelName,
			app,
			this._baseService.defaultOptionsBuilder.build(),
			this._importNewIdFromApi
		);
	}

	/**
	 * @description
	 * Updates an existing app in the account.
	 *
	 * @param app The app to update.
	 * @param cacheOnly Whether to only update the cache or update the cache and backend.
	 *
	 * @returns An observable to indicate completion.
	 */
	public update(app: App, cacheOnly: boolean = false): Observable<void> {
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (cacheOnly) {
			builder.useCacheOnly();
		}
		builder.skipMarkDirty();

		return this._paginationCacheService
			.replace(`${this._modelName}/${app.id}`, this._modelName, app, app.id, builder.build())
			.pipe(mapTo(null));
	}

	/**
	 * @description
	 * Deletes an existing app from the account.
	 *
	 * @param id The id of the app to delete.
	 * @returns An observable to indicate completion.
	 */
	public delete(id: string): Observable<void> {
		return this._paginationCacheService
			.delete(this._modelName, this._modelName, id, this._baseService.defaultOptionsBuilder.build(), App.importFromApi)
			.pipe(mapTo(null));
	}

	/**
	 * @description
	 * Deletes existing apps from the application.
	 *
	 * @param apps The apps to delete.
	 * @returns An observable of the successfully deleted apps.
	 */
	public batchDelete(apps: App[]): Observable<Id[]> {
		return this._paginationCacheService.batchDelete(
			this._modelName,
			this._modelName,
			apps.map(p => p.id),
			this._baseService.defaultOptionsBuilder.build(),
			App.importFromApi
		);
	}

	private _importNewIdFromApi(response: any): string {
		return response;
	}
}
