import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Renderer
} from '@angular/core';

/**
 * @description
 * A directive that when attached to an element,
 * will check to see if the element will clip with the
 * boundary box of the current viewport. If a collision is
 * detected, a descriptive class will be applied to the element
 * that can then be styled accordingly.
 */
@Directive({
    selector: '[viewClippingDetector]'
})
export class ViewClippingDetectorDirective implements AfterViewInit {
    public divBoundingRect: ClientRect;
    public windowHeight: number = 0;
    public windowWidth: number = 0;

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer
    ) { }

    public ngAfterViewInit(): void {
        this.divBoundingRect = this._elementRef.nativeElement.getBoundingClientRect();
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
        this._detectCollisions();
    }

    /**
     * @description
     * Determines if the attached element will clip outside of the current
     * viewport and then attaches a corresponding class that can be styled
     * to handle the clipping.
     */
    private _detectCollisions(): void {

        // collision below
        if (this.divBoundingRect.bottom > this.windowHeight) {
            this._renderer.setElementClass(
                this._elementRef.nativeElement,
                'collision-y-below',
                true
            );
        }

        // collision above
        if (this.divBoundingRect.top < 0) {
            this._renderer.setElementClass(
                this._elementRef.nativeElement,
                'collision-y-above',
                true
            );
        }

        // collision right
        if (this.divBoundingRect.right > this.windowWidth) {
            this._renderer.setElementClass(
                this._elementRef.nativeElement,
                'collision-x-right',
                true
            );
        }

        // collision left
        if (this.divBoundingRect.left < 0) {
            this._renderer.setElementClass(
                this._elementRef.nativeElement,
                'collision-x-left',
                true
            );
        }
    }
}
