import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ClosedCreationComponent } from './entity-creation-modal/closed-creation/closed-creation.component';
import { CompositeCreationComponent } from './entity-creation-modal/composite-creation/composite-creation.component';
import { EntityCreationModalComponent } from './entity-creation-modal/entity-creation-modal.component';
import { RegexCreationComponent } from './entity-creation-modal/regex-creation/regex-creation.component';
import { TypeDropdownComponent } from './entity-creation-modal/type-dropdown/type-dropdown.component';
import { EntityRenameModalComponent } from './entity-rename-modal/entity-rename-modal.component';
import { PrebuiltEntityListModalComponent } from './prebuilt-entity-list-modal/prebuilt-entity-list-modal.component';

@NgModule({
	imports: [SharedModule],
	exports: [EntityCreationModalComponent, EntityRenameModalComponent, PrebuiltEntityListModalComponent],
	declarations: [
		ClosedCreationComponent,
		CompositeCreationComponent,
		EntityCreationModalComponent,
		RegexCreationComponent,
		TypeDropdownComponent,
		EntityRenameModalComponent,
		PrebuiltEntityListModalComponent
	],
	providers: [],
	entryComponents: [EntityCreationModalComponent, PrebuiltEntityListModalComponent, EntityRenameModalComponent]
})
export class ModalsModule {}
