export interface ISize {
	height: number;
	width: number;
}

export interface IRect extends ISize {
	left: number;
	top: number;
}

export function getBoundingClientRectToBody(element: HTMLElement): IRect {
	const { height, width } = element.getBoundingClientRect();

	// The top/left of getBoundingClientRect() only measured to the viewport but not the document element
	// If you scroll down a bit, that scrollTop will not be measured. And things get worse if you have multiple scrollable area
	// We use this function to measure top/left to the body (document element to be exact), and width/height is done thru getBoundingClientRect
	return {
		height,
		width,
		left: (document.body.scrollLeft || document.documentElement.scrollLeft) + element.getBoundingClientRect().left,
		top: (document.body.scrollTop || document.documentElement.scrollTop) + element.getBoundingClientRect().top
	};
}

export function rectToStyle(rect: IRect): any {
	return {
		position: 'absolute',
		'height.px': rect.height.toString(),
		'left.px': rect.left.toString(),
		'top.px': rect.top.toString(),
		'width.px': rect.width.toString()
	};
}