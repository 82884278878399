import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { BaseCellComponent } from '../base-cell/base-cell.component';
import { ISortPipeProps } from '../interfaces/ISortPipeProps';
import { AriaSortAsyncPipe } from '../pipes/aria-sort-async.pipe';
import { TableService } from '../services/table.service';

@Component({
    selector: '[m-table-header-cell]',
    templateUrl: 'table-header-cell.component.html',
    styleUrls: ['table-header-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [AriaSortAsyncPipe]
})
export class TableHeaderCellComponent extends BaseCellComponent implements OnInit, OnDestroy {
    @Input() public headerParam: string = '';

    @HostBinding('attr.tabIndex') public get tabIndex(): string { return this.headerParam ? '0' : null; }

    public sortProps: Observable<ISortPipeProps>;
    private _ariaSortSubscription: Subscription = new Subscription();

    constructor(
        private _elRef: ElementRef,
        private _renderer2: Renderer2,
        private _tableService: TableService,
        private _ariaSortAsyncPipe: AriaSortAsyncPipe
    ) {
        super(_elRef, _renderer2);
    }

    public ngOnInit(): void {
        this._initState();
    }

    public ngOnDestroy(): void {
        this._ariaSortSubscription.unsubscribe();
    }

    /**
     * @description
     * Notifies the service to mutate the sort properties
     * to sort with the current header parameter.
     */
    @HostListener('click')
    @HostListener('keyup.enter')
    public sortBy(): void {
        if (this.headerParam) {
            this._tableService.updateSortProps(this.headerParam);
        }
    }

    /**
     * @description
     * Initializes the state of the table header.
     */
    protected _initState(): void {
        super._initState();

        this._renderer2.setAttribute(this._elRef.nativeElement, 'role', 'columnheader');

        this.sortProps = this._tableService.getSortProps();

        this._ariaSortSubscription = this._ariaSortAsyncPipe.transform(this.headerParam, this.sortProps)
            .subscribe(ariaSortAttr => this._renderer2.setAttribute(this._elRef.nativeElement, 'aria-sort', ariaSortAttr));
    }
}
