import { ComponentFactoryResolver, Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import {
	BUS_EVENTS,
	EventBusService,
	GenericPromptService,
	IDataItem,
	IModalComponentToken,
	IPage,
	IToasterService,
	LuisModel,
	ModalHostService,
	ProgressTracker,
	PromptButtonTypes,
	SelectionMap,
	TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { Entity, ENTITY_TYPES, UtteranceEntity } from '@luis/entities';
import { Intent, UtteranceIntent } from '@luis/intents';
import { Pattern } from '@luis/patterns';
import { IntentTrainingResult, ITrainingResultService, STATUS_CODES, TRAINING_RESULT_SERVICE_TOKEN } from '@luis/training';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { debounceTime, filter, first, flatMap, map, mapTo, startWith, switchMap, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs/Rx';
import { UTTERANCE_TO_PATTERN_MODAL_COMP_TOKEN } from '../components/tables/utterance-table/utterance-row/utterance-to-pattern-modal/utterance-to-pattern-modal.component';
import { IUtteranceService, UTTERANCE_SERVICE_TOKEN } from '../interfaces/services/IUtteranceService';
import { IItemTableToolbarOps } from '../interfaces/utils/IItemTableToolbarOps';
import { Utterance } from '../models/utterance.model';
import { PredictionCacheService } from './prediction-cache.service';
import { SegmentsExtractionService } from './segments-extraction.service';
import { UtteranceChunkingService } from './utterance-chunking.service';

export interface InitialData {
	model: LuisModel;
	type: 'intent' | 'entity';
	selectionMap: SelectionMap;
	chunkingTrigger: Observable<boolean>;
	predictionsTracker: ProgressTracker;
	chunkingTracker: ProgressTracker;
}

@Injectable()
export class UtteranceStoreService implements OnDestroy {
	private readonly _inititalDataSubject: ReplaySubject<InitialData> = new ReplaySubject(1);
	private readonly _utterances: Utterance[] = [];

	private _modelChangeSubscription: Subscription = new Subscription();
	private _chunkingSubscription: Subscription = new Subscription();

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _eventBusService: EventBusService,
		private readonly _chunkingService: UtteranceChunkingService,
		private readonly _promptService: GenericPromptService,
		private readonly _predictionCacheService: PredictionCacheService,
		private readonly _modalService: ModalHostService,
		private readonly _injector: Injector,
		private readonly _resolver: ComponentFactoryResolver,
		private readonly _segmentsExtractor: SegmentsExtractionService,
		@Inject(TRAINING_RESULT_SERVICE_TOKEN) private readonly _trainingResultService: ITrainingResultService,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(UTTERANCE_SERVICE_TOKEN) private readonly _utteranceService: IUtteranceService,
		@Inject(UTTERANCE_TO_PATTERN_MODAL_COMP_TOKEN) private readonly _utteranceToPatternModalCompToken: IModalComponentToken<void>
	) {}

	public ngOnDestroy(): void {
		this._modelChangeSubscription.unsubscribe();
		this._chunkingSubscription.unsubscribe();
		this._inititalDataSubject.complete();
		this._inititalDataSubject.unsubscribe();
	}

	public init(data: InitialData): void {
		this._inititalDataSubject.next(data);

		this._subscribeToBusEvents();

		// Wire up chunking service
		this._chunkingSubscription = this._chunkingService
			.init(
				data.model.id,
				data.chunkingTrigger.pipe(
					filter(trigger => trigger === true),
					mapTo(null)
				),
				Utterance.importFromApi
			)
			.trackProgress(data.chunkingTracker.getTracker())
			.subscribe();
	}

	public fetch(pageIndex: number, getAll?: boolean, isEvaluated?: boolean): Observable<IPage<Utterance>> {
		return this._inititalDataSubject.pipe(
			first(),
			flatMap(data =>
				this._utteranceService.get(data.model.id, pageIndex, getAll).pipe(
					tap(page => page.data.forEach((item, i) => this._updateItemCache(item, i))),
					map(page => ({ page, data }))
				)
			),
			flatMap(({ page, data }) => combineLatest(of({ page, data }), data.chunkingTrigger.pipe(startWith(false)))),
			debounceTime(100),
			switchMap(([{ page, data }, disablePredictions]) => {
				if (disablePredictions) {
					return of({ page, data });
				} else {
					return this._predictionCacheService
						.batchPredictAndUpdateCache(data.model.id, page.data, data.predictionsTracker.getTracker())
						.pipe(
							startWith({ page, data }),
							mapTo({ page, data })
						);
				}
			}),
			flatMap(({ page, data }) => {
				const trainingRes = isEvaluated
					? this._trainingResultService.getStatus().pipe(
							filter(status => status === STATUS_CODES.COMPLETED),
							flatMap(() => this._trainingResultService.getFaultyUtterancesPerIntent(data.model.id))
					  )
					: of(new IntentTrainingResult());

				return combineLatest(of(page), trainingRes);
			}),
			map(([page, tRIntent]) => {
				if (page.data.length > 0 && isEvaluated) {
					page.data.forEach(u => {
						if (tRIntent.incorrectUtterances.has(u.id)) {
							u.trainingEvaluation = tRIntent.incorrectUtterances.get(u.id);
						} else if (tRIntent.ambiguousUtterances.has(u.id)) {
							u.trainingEvaluation = tRIntent.ambiguousUtterances.get(u.id);
						}
					});
					// TO DO: Fix me
					page.data = page.data.map(u => u.clone());
				}

				return page;
			})
		);
	}

	/**
	 * @description
	 * Gets the toolbar ops allowable for utterances
	 *
	 * @returns An observable
	 * of the toolbar ops allowed.
	 */
	public getToolbarOps(): Observable<IItemTableToolbarOps> {
		return Observable.of({
			delete: { enabled: true, label: this._i18n.instant('utterances.utterance-store.delete_utterance_toolbar_button') },
			entityFilter: true,
			errorFilter: true,
			intentFilter: false,
			labelView: true,
			reassignIntent: true,
			search: { enabled: true, label: this._i18n.instant('utterances.utterance-store.search_for_utterances'), initialValue: '' }
		});
	}

	/**
	 * @description
	 * Predicts and adds the given utterance.
	 *
	 * @param utterance The utterance text to add.
	 * @returns An observable to indicate completion.
	 */
	public submitItem(utterance: Utterance): Observable<any> {
		return this._inititalDataSubject.pipe(
			first(),
			flatMap(data =>
				this._predictionCacheService
					.predict(utterance.text)
					.flatMap(u => this._addPredictedUtterance(data.model.id, data.model.name, u))
					.trackProgress(this._toasterService.add())
			)
		);
	}

	/**
	 * @description
	 * Adds this utterance as new pattern.
	 */
	public addAsPattern(): Observable<void> {
		return this._inititalDataSubject.pipe(
			first(),
			flatMap(({ selectionMap }) => {
				const selectedUtterance: Utterance = this._utterances.filter(u => selectionMap.selectedItems.indexOf(u.id) !== -1)[0];
				const suggestedPatterns: Pattern[] = this._getSuggestedPatterns(selectedUtterance);

				return this._modalService.create(
					this._utteranceToPatternModalCompToken,
					{ intentName: selectedUtterance.labeledIntent.name, patterns: suggestedPatterns },
					{ injector: this._injector, resolver: this._resolver }
				);
			})
		);
	}

	/**
	 * @description
	 * Reassigns the currently selected utterances to the
	 * given intent.
	 *
	 * @param intent The intent to reassign the utterances to.
	 * @returns An observable to indicate completion.
	 */
	public batchReassign(intent: Intent): Observable<any> {
		return this._inititalDataSubject.pipe(
			first(),
			flatMap(({ selectionMap, type }) => {
				const selectedUtterances: Utterance[] = this._utterances.filter(u => selectionMap.selectedItems.indexOf(u.id) !== -1);
				const utterances: Utterance[] = selectedUtterances
					.map(u => u.clone())
					.map(
						u =>
							new Utterance(
								u.id,
								u.text,
								u.tokenizedText,
								new UtteranceIntent(intent.id, intent.name),
								u.predictedIntents,
								u.labeledEntities,
								u.predictedEntities,
								u.predictedPatterns
							)
					);

				return this._utteranceService
					.batchAdd(utterances, type)
					.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('utterances.utterance-store.reassign_toast') }))
					.pipe(tap(ids => selectionMap.markUnselected(selectionMap.selectedItems.filter((u, i) => ids[i] !== null))));
			})
		);
	}

	/**
	 * @description
	 * Deletes the currently selected utterances.
	 *
	 * @returns An observable to indicate
	 * completion of the deletion process.
	 */
	public batchDelete(): Observable<any> {
		return this._inititalDataSubject.pipe(
			first(),
			flatMap(data => {
				const selectedUtterances: Utterance[] = this._utterances.filter(u => data.selectionMap.selectedItems.indexOf(u.id) !== -1);

				return this._promptService
					.prompt(
						this._i18n.instant('utterances.utterance-store.delete_utterance_q'),
						this._i18n.instant('utterances.utterance-store.are_you_sure_you_want_delete_utterance', {
							length: selectedUtterances.length
						}),
						{
							ok: this._i18n.instant('utterances.utterance-store.ok'),
							cancel: this._i18n.instant('utterances.utterance-store.cancel')
						},
						{ ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default }
					)
					.pipe(
						filter(choice => choice === 'ok'),
						flatMap(() =>
							this._utteranceService.batchDelete(selectedUtterances.map(u => u.id)).trackProgress(
								this._toasterService.add({
									startMsg: this._i18n.instant('utterances.utterance-store.delete_toast')
								})
							)
						),
						tap(ids => data.selectionMap.markUnselected(data.selectionMap.selectedItems.filter((u, i) => ids[i] !== null)))
					);
			})
		);
	}

	/**
	 * @description
	 * Updates the items in the local cache. This prevents the utterance table
	 * from refreshing all the utterances.
	 */
	private _updateItemCache(item: IDataItem, index: number): IDataItem {
		if (this._utterances[index] === undefined || !item.isEqual(this._utterances[index])) {
			this._utterances[index] = item.clone();
		}

		return this._utterances[index];
	}

	/**
	 * @description
	 * Creates the observable to subscribe to to add a predicted utterance. If the utterance has
	 * predicted entities, get their types first.
	 *
	 * @param intentName The intent name of the predicted utterance.
	 * @param utterance The predicted utterance itself.
	 * @returns An observable to indicate completion.
	 */
	private _addPredictedUtterance(intentId: string, intentName: string, utterance: Utterance): Observable<number> {
		const labeledIntent = utterance.predictedIntents.filter(pI => pI.id === intentId)[0];
		utterance.labeledIntent = new UtteranceIntent(intentId, intentName, labeledIntent.score);
		utterance.labeledEntities = utterance.predictedEntities.map(e => e.clone()).filter(e => Entity.isMachineLearned(e.type));

		return this._utteranceService.add(utterance);
	}

	/**
	 * @description
	 * Subscribe to bus events.
	 */
	private _subscribeToBusEvents(): void {
		this._inititalDataSubject
			.pipe(
				first(),
				tap(data => {
					this._modelChangeSubscription = this._eventBusService.subscribeToBus(BUS_EVENTS.MODEL_CHANGED, msg => {
						if (msg.model.id === data.model.id) {
							data.model = msg.model.clone();
						}
					});
				})
			)
			.subscribe();
	}

	/**
	 * @description
	 * Gets the suggested patterns from the utterance entities.
	 *
	 * @returns The suggested patterns.
	 */
	private _getSuggestedPatterns(utterance: Utterance): Pattern[] {
		const patterns: Pattern[] = [];

		const resolvedEntities: UtteranceEntity[] = this._segmentsExtractor.resolveOverlappingEntities(
			utterance.labeledEntities,
			utterance.predictedEntities.filter(pE => !Entity.isMachineLearned(pE.type))
		);

		const nonCompositeEntities: UtteranceEntity[] = resolvedEntities
			.filter(rE => rE.type !== ENTITY_TYPES.COMPOSITE)
			.sort((a, b) => (a.startTokenIndex > b.startTokenIndex ? -1 : 1));

		if (nonCompositeEntities.length) {
			const childrenEntitiesPattern: string = this._generateSuggestedPattern(nonCompositeEntities, utterance);
			patterns.push(new Pattern('', childrenEntitiesPattern, utterance.labeledIntent.name));
		}

		const compositeEntities: UtteranceEntity[] = resolvedEntities.filter(rE => rE.type === ENTITY_TYPES.COMPOSITE);
		if (compositeEntities.length) {
			const entitiesNotInComposites: UtteranceEntity[] = nonCompositeEntities.filter(nC =>
				this._isEntityNotInComposite(nC, compositeEntities)
			);
			const compositePatternEntities: UtteranceEntity[] = [...compositeEntities, ...entitiesNotInComposites].sort((a, b) =>
				a.startTokenIndex > b.startTokenIndex ? -1 : 1
			);
			const compositePattern: string = this._generateSuggestedPattern(compositePatternEntities, utterance);
			patterns.push(new Pattern('', compositePattern, utterance.labeledIntent.name));
		}

		return patterns;
	}

	/**
	 * @description
	 * Generates suggested pattern for given utterance entities.
	 *
	 * @param entities Utterance entities sorted descendingly by startTokenIndex.
	 * @returns The suggested pattern text.
	 */
	private _generateSuggestedPattern(entities: UtteranceEntity[], utterance: Utterance): string {
		let pattern: string = utterance.text;
		entities.forEach(entity => {
			entity.tokenToCharIndeces(utterance.text, utterance.tokenizedText);
			pattern = `${pattern.slice(0, entity.startCharIndex)}{${entity.name}}${pattern.slice(entity.endCharIndex + 1)}`;
		});

		return pattern;
	}

	/**
	 * @description
	 * Checks if entity is not wrapped in composite entity.
	 *
	 * @param entity The entity to check.
	 * @param compositeEntities The composite entities.
	 * @returns True if the entity is not wrapped in a composite entity, false otherwise.
	 */
	private _isEntityNotInComposite(entity: UtteranceEntity, compositeEntities: UtteranceEntity[]): boolean {
		for (const cE of compositeEntities) {
			if (entity.startTokenIndex >= cE.startTokenIndex && entity.startTokenIndex <= cE.endTokenIndex) {
				return false;
			}
		}

		return true;
	}
}
