import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as c3 from 'c3';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AxisData, CHART_TYPES } from '../../models/chart.model';
import { ChartsRendererService } from '../../services/charts-renderer.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
	selector: '[stacked-bar-chart]',
	template: '<loading [visible]="!(isInitialized)"></loading>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackedBarChartComponent extends BaseChartComponent implements AfterViewInit, OnDestroy {
	@Input() public groups: string[] = [];
	@Input() public barWidth: number = 20;
	@Input() public isMinWidth: boolean = false;
	@Input() public groupColors: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

	private _xDataSubscription: Subscription = new Subscription();
	private _dataToDefocusSubscription: Subscription = new Subscription();

	constructor(
		private readonly i18n: TranslateService,
		private readonly elementRef: ElementRef,
		private readonly chartsRenderer: ChartsRendererService
	) {
		super(i18n, elementRef, chartsRenderer);
	}

	public ngAfterViewInit(): void {
		this._dataToDefocusSubscription = this.dataToDefocus.subscribe(ids => {
			if (ids.length > 0) {
				this.focusData(ids);
			} else {
				this.revertFocus();
			}
		});

		// Using `WithLatestFrom` to solve a race condition which is xData and yData fire in the same time so none of the emissions can
		// reach out to the data in the other one.
		this._xDataSubscription = this.xData.pipe(withLatestFrom(this.yData)).subscribe(([xData, yData]) => {
			const config: c3.ChartConfiguration = this._getConfig(xData, yData);
			if (!this.isInitialized) {
				this.init(config);
				this.isInitialized = true;
			} else {
				this.update({ data: config.data });
			}
		});
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this._dataToDefocusSubscription.unsubscribe();
		this._xDataSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Returns the given arguments if the data inside yData only contains zeros
	 */
	protected filterZeroedData({ xData, yData }: { xData: AxisData; yData: AxisData }): { xData: AxisData; yData: AxisData } {
		const isDataZeroed: boolean = yData.data.reduce((prevVal, accVal) => prevVal + accVal.length, 0) === 0;
		if (isDataZeroed || xData.data.length === 0) {
			xData.data = [];
			yData.data = [];
		}

		return { xData, yData };
	}

	/**
	 * @description
	 * Initializes the chart component with the required configuration
	 */
	protected init(config: c3.ChartConfiguration): void {
		super.init({
			...config,
			bindto: this.elementRef.nativeElement
		});
	}

	/**
	 * @description
	 * Creates the configuration accepted by the ChartRendererService for generating a bar chart
	 */
	private _getConfig(xData: AxisData, yData: AxisData): c3.ChartConfiguration {
		const maxBars: number = 500;
		const MIN_WITHOUT_PADDING: number = 17;
		const colorGroups: any = this._getGroupsColors();
		const data: any = {
			columns: this._transformYDataToColumns(yData),
			type: CHART_TYPES.STACKED_BAR,
			groups: [this.groups],
			order: null,
			empty: {
				label: {
					text: this.emptyLabelText
				}
			},
			color: (color, d) => {
				if (d.id) {
					return colorGroups[d.id];
				}
			}
		};
		const categories: any[] = xData.data;
		const bar: any = {
			width: categories.length >= MIN_WITHOUT_PADDING ? maxBars / categories.length : this.barWidth
		};
		const tooltip: any = {
			contents: (d, defultTitle, defaultValue, color) => {
				return this.createTooltip(d[0].x);
			}
		};
		const axis: any = {
			x: {
				label: {
					text: this.xLabel,
					position: 'outer-center'
				},
				tick: {
					outer: false
				},
				padding:
					categories.length < MIN_WITHOUT_PADDING
						? {
								left: (MIN_WITHOUT_PADDING - categories.length) / 2,
								right: (MIN_WITHOUT_PADDING - categories.length) / 2
						  }
						: {}
			},
			y: {
				tick: {
					count: 6,
					format: (d: number) => d.toFixed()
				},
				padding: { top: 0, bottom: 0 }
			}
		};

		return { data, tooltip, bar, axis };
	}

	private _getGroupsColors(): any {
		return this.groups.reduce((acc, gp, index) => ({ ...acc, [gp]: this.groupColors.getValue()[index] }), {});
	}
	/**
	 * @description
	 * Transforms yAxisData to columns array accepted by ChartRendererService
	 */
	private _transformYDataToColumns(yData: AxisData): any[] {
		const ret = this.groups.map((gp, i) => (yData.data[i] && yData.data[i].length > 0 ? [gp, ...yData.data[i]] : []));
		const totalLength: number = ret.reduce((acc, value) => acc + (value.length > 0 ? 0 : 1), 0);

		return totalLength === ret.length ? [] : ret;
	}
}
