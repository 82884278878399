import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { IToasterService, TOASTER_SERVICE_TOKEN } from '@luis/core';
import { PillValuesComponent } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { ENTITY_SERVICE_TOKEN, IEntityService } from '../../../../interfaces/IEntityService';
import { ClosedEntity, ClosedSublist } from '../../../../models/closed-entity.model';

/**
 * @description
 * Displays the given values in pill shapes.
 */
@Component({
	selector: '[sublist]',
	templateUrl: 'sublist.component.html',
	styleUrls: ['sublist.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SublistComponent implements OnInit, OnChanges {
	@Input() public sublist: ClosedSublist;
	@Input() public entity: Observable<ClosedEntity>;
	@Output() public tabClicked: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

	@ViewChild('pillValuesRef') public pillValuesRef: PillValuesComponent;

	public valuesObservable: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	public editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _inProgress: boolean;
	private _unmutatedEntity: Observable<ClosedEntity>;

	constructor(
		private _i18n: TranslateService,
		@Inject(TOASTER_SERVICE_TOKEN) private _toasterService: IToasterService,
		@Inject(ENTITY_SERVICE_TOKEN) private _entityService: IEntityService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['sublist']) {
			this.valuesObservable.next(changes['sublist'].currentValue.values);
		}
	}

	/**
	 * @description
	 * Deletes the given sublist from the closed entity. Accessed
	 * from the parent component.
	 *
	 * @param sublist The sublist to delete.
	 */
	public deleteSublist(): void {
		this._deleteSublist();
	}

	/**
	 * @description
	 * Updates the current sublist.
	 */
	public updateSublist(): void {
		setTimeout(() => {
			if (this._inProgress) {
				return;
			}

			this._inProgress = true;

			this._updateSublist()
				.finally(() => (this._inProgress = false))
				.subscribe(() => (this.editMode.getValue() ? this.editMode.next(false) : null));
		}, 0);
	}

	/**
	 * @description
	 * Adds sublist to closed sublist and commits value if specified
	 *
	 * @param sublist The sublist to add the value to.
	 * @param synonymValueField HTML element that carries the value
	 * @param commit Indicates if values should be commited immediately to sublist.
	 */
	public addSynonym(synonymValue: string): void {
		this._addValueToSublist(synonymValue, synonymValue.endsWith(','));
	}

	/**
	 * @description
	 * Focuses the input field inside the pill component.
	 */
	public focus(): void {
		this.pillValuesRef.focus();
	}

	/**
	 * @description
	 * Initializes the component state.
	 */
	private _initState(): void {
		this.valuesObservable.next(this.sublist.values);
		this._unmutatedEntity = this.entity.map(e => e.clone());
	}

	/**
	 * @description
	 * Adds the given value to the given sublist's values. Removes last character in string
	 * if trailing comma flag is set.
	 *
	 * @param sublist The sublist to add the value in.
	 * @param value The value to add.
	 * @param isCommaTrailed A flag specifiying whether a trailing comma is existing or not.
	 */
	private _addValueToSublist(value: string, isCommaTrailed: boolean): void {
		const newValue: string = value.trim();

		if (this.sublist.values.indexOf(value) !== -1) {
			return;
		}

		if (newValue.length > 0) {
			this.sublist.addValue(isCommaTrailed ? newValue.slice(0, -1) : newValue);
		}
	}

	/**
	 * @description
	 * Checks if the two given sublists are dissimilar or not.
	 *
	 * @param sublist The alleged mutated sublist to compare with the unmutated one.
	 * @param unmutatedSublist The unmutated (original) sublist.
	 * @returns True if the sublists are different and false otherwise.
	 */
	private _isSublistMutated(unmutatedSublist: ClosedSublist): boolean {
		if (this.sublist.name !== unmutatedSublist.name) {
			return true;
		}
		if (this.sublist.values.length !== unmutatedSublist.values.length) {
			return true;
		}
		if (this.sublist.values.find((v, i) => v !== unmutatedSublist.values[i]) !== undefined) {
			return true;
		}

		return false;
	}

	/**
	 * @description
	 * Updates the given sublist in the closed entity. Compares the updated sublist
	 * with the unmutated sublist. If they are similar, then no update occurs.
	 *
	 * @param sublist The sublist to update.
	 * @returns An observable to indicate completion. If it returns false, then no
	 * update occurred.
	 */
	private _updateSublist(): Observable<boolean> {
		return Observable.combineLatest(this.entity.first(), this._unmutatedEntity.first())
			.flatMap<[ClosedEntity, ClosedEntity], boolean>(data => {
				const [mutated, unmutated] = data;

				if (this._isSublistMutated(unmutated.sublists.find(et => et.id === this.sublist.id))) {
					const newSublists: ClosedSublist[] = [...mutated.sublists.map(et => (et.id === this.sublist.id ? this.sublist : et))];
					mutated.sublists = newSublists;

					return this._entityService
						.update(this.sublist)
						.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.sublist.update_toast') }))
						.map(() => true);
				} else {
					return Observable.of(false);
				}
			})
			.first();
	}

	/**
	 * @description
	 * Deletes the sublist from the closed entity.
	 *
	 * @param sublist The sublist to delete from the closed entity.
	 */
	private _deleteSublist(): void {
		this.sublist.parent.sublists = this.sublist.parent.sublists.filter(et => et.id !== this.sublist.id);
		this._entityService
			.delete(this.sublist)
			.trackProgress(this._toasterService.add({ startMsg: this._i18n.instant('entities.sublist.delete_toast') }))
			.subscribe(() => null);
	}
}
