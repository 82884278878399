import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
	GenericPromptService,
	IPage,
	IResourceOperation,
	IToasterService,
	LuisConstants,
	PaginationCacheService,
	PaginationUIPayload,
	ProgressTracker,
	PromptButtonTypes,
	SelectionMap,
	TOASTER_SERVICE_TOKEN
} from '@luis/core';
import { ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { APP_SERVICE_TOKEN, IAppService } from '../../../interfaces/IAppService';
import { APP_VERSION_SERVICE_TOKEN, IAppVersionService } from '../../../interfaces/IAppVersionService';
import { IAppVersionStates } from '../../../interfaces/IAppVersionStates';
import { APP_VERSION_OPERATIONS } from '../../../models/app-version-operations.model';
import { AppVersion } from '../../../models/app-version.model';
import { AppVersionCloneModalComponent } from '../version-clone-modal/version-clone-modal.component';
import { AppVersionImportModalComponent } from '../version-import-modal/version-import-modal.component';
import { AppVersionRenameModalComponent } from '../version-rename-modal/version-rename-modal.component';

/**
 * @description
 * Represents a version table. Lists the versions in an application. Provides
 * tools for manipulating the versions in the table.
 */
@Component({
	selector: 'version-table',
	templateUrl: './version-table.component.html',
	styleUrls: ['./version-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionTableComponent implements OnInit, OnDestroy {
	@Input() public activeVersion: Observable<string> = Observable.of('');
	@Output() public newVersionCreated: EventEmitter<AppVersion> = new EventEmitter<AppVersion>();
	@Output() public versionRenamed: EventEmitter<[AppVersion, string]> = new EventEmitter<[AppVersion, string]>();
	@Output() public versionDeleted: EventEmitter<AppVersion[]> = new EventEmitter<AppVersion[]>();

	public pageIndex: BehaviorSubject<number> = new BehaviorSubject(1);
	public query: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public versionStateFilter: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	public versionOps: typeof APP_VERSION_OPERATIONS = APP_VERSION_OPERATIONS;
	public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
		property: 'createdDate',
		type: SORT_TYPE.DESCENDING
	});

	public selectionMap: SelectionMap = new SelectionMap();
	public paginationUIPayload: PaginationUIPayload<AppVersion> = new PaginationUIPayload();

	public versionStates: Observable<IAppVersionStates>;
	public isDeleteVisible: Observable<boolean>;
	public isActivateVisible: Observable<boolean>;
	public isContainerExportEnabled: Observable<boolean>;

	private _page: Observable<IPage<AppVersion>>;
	private readonly _versionsSubscription: Subscription = new Subscription();
	private readonly _chunkingTracker: ProgressTracker = new ProgressTracker();
	private readonly _unsubscribeSubject: Subject<void> = new Subject<void>();

	constructor(
		private readonly _promptService: GenericPromptService,
		private readonly _i18n: TranslateService,
		private readonly _dialogService: MatDialog,
		@Inject(TOASTER_SERVICE_TOKEN) private readonly _toasterService: IToasterService,
		@Inject(APP_SERVICE_TOKEN) private readonly _appService: IAppService,
		@Inject(APP_VERSION_SERVICE_TOKEN) private readonly _versionService: IAppVersionService
	) {}

	public ngOnInit(): void {
		this._initState();
	}

	public ngOnDestroy(): void {
		this._versionsSubscription.unsubscribe();
		this._unsubscribeSubject.next(null);
		this._unsubscribeSubject.complete();
		this.paginationUIPayload.dispose();
	}

	/**
	 * @description
	 * Notifies parent that an operation was clicked to apply to the given version.
	 *
	 * @param version The version to apply the op on.
	 * @param operation The operation to run. Should be one of the allowed operations
	 * in the APP_VERSION_OPERATIONS enum.
	 */
	public onOperationClick(versionOp: APP_VERSION_OPERATIONS): void {
		const selectedVersionIds = this.selectionMap.selectedItems;
		const versionsOp: IResourceOperation = { items: [], operation: versionOp, response: new BehaviorSubject<boolean>(false) };

		this._page
			.first()
			.map(versions => versions.data.filter(v => selectedVersionIds.indexOf(v.id) !== -1))
			.do(selectedVersions => (versionsOp.items = selectedVersions))
			.subscribe(() => {
				switch (versionOp) {
					case APP_VERSION_OPERATIONS.SWITCH:
						this._switchVersion(versionsOp);
						break;
					case APP_VERSION_OPERATIONS.IMPORT:
						this._importVersion(versionsOp);
						break;
					case APP_VERSION_OPERATIONS.RENAME:
						this._renameVersion(versionsOp);
						break;
					case APP_VERSION_OPERATIONS.EXPORT:
						this._exportVersion(versionsOp);
						break;
					case APP_VERSION_OPERATIONS.EXPORT_CONTAINER:
						this._exportVersionForContainer(versionsOp);
						break;
					case APP_VERSION_OPERATIONS.CLONE:
						this._cloneVersion(versionsOp);
						break;
					case APP_VERSION_OPERATIONS.DELETE:
						this._deleteVersions(versionsOp);
						break;
					default:
				}
			});

		versionsOp.response.subscribe(null, null, () => this.selectionMap.markUnselected(selectedVersionIds));
	}

	/**
	 * @description
	 * Initializes the component.
	 */
	private _initState(): void {
		const chunkingTrigger$ = combineLatest(this.query, this.versionStateFilter).map(
			([query, filters]) => query.length > 0 || (filters !== null && filters.length > 0)
		);

		this._page = combineLatest(this.pageIndex, this.query, this.versionStateFilter).pipe(
			switchMap(([pageIndex, query, filters]) =>
				this._versionService.get(pageIndex - 1, null, false, query.length > 0 || (filters !== null && filters.length > 0)).pipe(
					takeUntil(this._unsubscribeSubject),
					map(page => ({ page, filters, pageIndex }))
				)
			),
			map(({ page, filters }) => ({
				...page,
				data: page.data.filter(version => (filters ? version.id === filters : true))
			})),
			shareReplay(1)
		);

		this.versionStates = Observable.combineLatest(
			this._appService.getSingle().pipe(filter(app => app !== undefined)),
			this.activeVersion
		)
			.map(([app, active]) => ({ app: app, states: { active: active, dev: '', prod: '', devDate: null, prodDate: null } }))
			.do(data =>
				data.app.endpointConfigs.forEach(c => {
					if (c.isStaging) {
						data.states.dev = c.versionId;
						data.states.devDate = c.publishedDate;
					} else {
						data.states.prod = c.versionId;
						data.states.prodDate = c.publishedDate;
					}
				})
			)
			.map(data => data.states)
			.shareReplay(1);

		this.isDeleteVisible = Observable.combineLatest(this.selectionMap.toolbarOps, this.selectionMap.selectedItemsAsync).map(
			([ops, selectedItems]) => (ops.single || ops.batch) && selectedItems.length !== this.selectionMap.allItems.length
		);

		this.isActivateVisible = Observable.combineLatest(this.selectionMap.toolbarOps, this.versionStates).map(
			([ops, states]) => ops.single && states.active !== this.selectionMap.selectedItems[0]
		);

		this.isContainerExportEnabled = Observable.combineLatest(this._page, this.selectionMap.selectedItemsAsync)
			.filter(([, selectedIds]) => selectedIds.length === 1)
			.map(([versions, selectedIds]) => versions.data.find(version => version.id === selectedIds[0]))
			.filter(version => version !== undefined)
			.map(version => version.isTrained);

		this._versionService.init(chunkingTrigger$, this._chunkingTracker);

		this.paginationUIPayload.init({
			page$: this._page,
			pageIndex$: this.pageIndex,
			chunkingTrigger$,
			chunkingTracker: this._chunkingTracker,
			config: { ngxPaginationId: 'versions', pageSize: PaginationCacheService.PAGE_SIZE }
		});
	}

	/**
	 * @description
	 * Opens up the modal for version import. Notifies the parent
	 * component that a version was created on successfull creation.
	 *
	 * @param versionsOp The version operation metadata.
	 */
	private _importVersion(versionsOp: IResourceOperation): void {
		this._dialogService
			.open(AppVersionImportModalComponent, { width: '600px' })
			.afterClosed()
			.filter(version => version)
			.subscribe(version => {
				versionsOp.response.complete();
				this.newVersionCreated.emit(version);
			});
	}

	/**
	 * @description
	 * Switches to the selected version.
	 *
	 * @param versionsOp The version operation metadata.
	 */
	private _switchVersion(versionsOp: IResourceOperation): void {
		// This delay to enable the DOM to activate google analytics.
		setTimeout(() => {
			this.newVersionCreated.emit(<AppVersion>versionsOp.items[0]);
			versionsOp.response.complete();
		}, 0);
	}

	/**
	 * @description
	 * Exports the given version.
	 *
	 * @param versionsOp The version operation metadata.
	 */
	private _exportVersion(versionsOp: IResourceOperation): void {
		this._versionService.export(<AppVersion>versionsOp.items[0]).subscribe(() => versionsOp.response.complete());
	}

	/**
	 * @description
	 * Exports the given version for container.
	 *
	 * @param versionsOp The version operation metadata.
	 */
	private _exportVersionForContainer(versionsOp: IResourceOperation): void {
		this._versionService
			.exportForContainer(<AppVersion>versionsOp.items[0])
			.trackProgress(
				this._toasterService.add({
					endMsg: [
						{ text: this._i18n.instant('apps.version-table.export_successful_toast') },
						{
							text: this._i18n.instant('apps.version-table.export_learn_more'),
							handler: () => window.open(LuisConstants.LUIS_CONTAINER_DOCS, '_blank')
						}
					],
					isDismissible: true
				})
			)
			.subscribe(() => versionsOp.response.complete());
	}

	/**
	 * @description
	 * Opens up the modal for version cloning. Notifies the parent
	 * component that a version was created on successfull clone.
	 *
	 * @param versionsOp The version operation metadata.
	 */
	private _cloneVersion(versionsOp: IResourceOperation): void {
		this._dialogService
			.open(AppVersionCloneModalComponent, { width: '600px', data: versionsOp.items[0] })
			.afterClosed()
			.filter(version => version)
			.subscribe(version => {
				versionsOp.response.complete();
				this.newVersionCreated.emit(version);
			});
	}

	/**
	 * @description
	 * Opens up the modal for version renaming. Notifies the parent
	 * component that a version was renamed on successful rename.
	 *
	 * @param versionsOp The version operation metadata.
	 */
	private _renameVersion(versionsOp: IResourceOperation): void {
		const oldId = versionsOp.items[0].id;

		this._dialogService
			.open(AppVersionRenameModalComponent, { width: '600px', data: versionsOp.items[0] })
			.afterClosed()
			.filter(version => version)
			.subscribe(version => {
				versionsOp.response.complete();
				this.versionRenamed.emit([version, `${oldId}`]);
			});
	}

	/**
	 * @description
	 * Creates a prompt modal to delete the given version.
	 *
	 * @param versionsOp The version operation metadata.
	 */
	private _deleteVersions(versionsOp: IResourceOperation): void {
		const isBatch: boolean = versionsOp.items.length > 1;
		const title: string = `Delete version${isBatch ? 's' : ''} ?`;
		const message: string = `Are you sure you want to delete ${isBatch ? 'these versions' : 'version ' + versionsOp.items[0].id}?`;

		this._promptService
			.prompt(title, message, { ok: 'Ok', cancel: 'Cancel' }, { ok: PromptButtonTypes.Danger, cancel: PromptButtonTypes.Default })
			.filter(choice => choice === 'ok')
			.flatMap(() => this._versionService.batchDelete(<AppVersion[]>versionsOp.items))
			.subscribe(deletedIds => {
				versionsOp.response.complete();
				this.versionDeleted.emit(<AppVersion[]>versionsOp.items.filter(item => deletedIds.indexOf(item.id) !== -1));
			});
	}
}
