import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Intent } from '@luis/intents';
import { BLADE_OPS, BladeComponent, BLADES, BladeTrackerService, ISortPipeProps, SORT_TYPE } from '@luis/ui';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * @description
 * Represents the filters blade.
 */
@Component({
	selector: '[nearest-rival-blade]',
	templateUrl: 'nearest-rival-blade.component.html',
	styleUrls: ['nearest-rival-blade.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NearestRivalBladeComponent extends BladeComponent {
	@Input() public intentsToChoose: Observable<Intent[]>;
	@Input() public filteredIntents: Observable<Map<string, Intent>>;

	@Output() public filterUtterancesByIntent: EventEmitter<Intent> = new EventEmitter<Intent>();
	@Output() public clearFilters: EventEmitter<void> = new EventEmitter<void>();

	public querySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public sortProps: BehaviorSubject<ISortPipeProps> = new BehaviorSubject<ISortPipeProps>({
		property: 'name',
		type: SORT_TYPE.ASCENDING
	});

	protected currentBlade: BLADES = BLADES.INTENT;

	constructor(private readonly _bladeTrackerService: BladeTrackerService) {
		super(_bladeTrackerService);
	}

	public allFilters(): void {
		this._bladeTrackerService.applyOp(BLADE_OPS.FILTER_OPEN);
		this.closeBlade();
	}

	public onQueryChange(query: string): void {
		this.querySubject.next(query.trim());
	}
}
