import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteService } from '../../../common/services/app-route.service';

@Component({
	selector: 'bf-proxy',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BfProxyComponent implements OnInit {
	constructor(private readonly _router: Router, private readonly _routeService: AppRouteService) {}

	public async ngOnInit(): Promise<void> {
		await this._router.navigate(
			[
				this._routeService
					.builder()
					.user()
					.bfLogin()
					.build()
			],
			{ preserveQueryParams: true }
		);
	}
}
