import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { PatternsModule } from '@luis/patterns';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { FilterBladeModule } from '../filter-blade/filter-blade.module';
import { LabelableUtteranceModule } from '../labelable-utterance/labelable-utterance.module';
import { SuggestRowComponent } from './suggest-table/suggest-row/suggest-row.component';
import { SuggestTableComponent } from './suggest-table/suggest-table.component';
import { SuggestTableToolbarComponent } from './suggest-table/table-toolbar/table-toolbar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UtteranceInputComponent } from './utterance-table/utterance-input/utterance-input.component';
import { UtteranceRowComponent } from './utterance-table/utterance-row/utterance-row.component';
import {
	UTTERANCE_TO_PATTERN_MODAL_COMP_TOKEN,
	UtteranceToPatternModalComponent
} from './utterance-table/utterance-row/utterance-to-pattern-modal/utterance-to-pattern-modal.component';
import { UtteranceTableComponent } from './utterance-table/utterance-table.component';

@NgModule({
	imports: [SharedModule, LabelableUtteranceModule, PatternsModule, TranslateModule, FilterBladeModule, MatMenuModule],
	declarations: [
		SuggestRowComponent,
		SuggestTableComponent,
		SuggestTableToolbarComponent,
		ToolbarComponent,
		UtteranceRowComponent,
		UtteranceToPatternModalComponent,
		UtteranceInputComponent,
		UtteranceTableComponent
	],
	exports: [
		SuggestRowComponent,
		SuggestTableComponent,
		SuggestTableToolbarComponent,
		ToolbarComponent,
		UtteranceRowComponent,
		UtteranceToPatternModalComponent,
		UtteranceInputComponent,
		UtteranceTableComponent
	],
	providers: [
		{
			provide: UTTERANCE_TO_PATTERN_MODAL_COMP_TOKEN,
			useValue: {
				componentType: UtteranceToPatternModalComponent,
				defaultParams: {}
			}
		}
	],
	entryComponents: [UtteranceToPatternModalComponent]
})
export class TablesModule {}
