import { ICloneable } from '@luis/core';

export interface ICollaborator {
    email: string;
}

export class AppPermissions implements ICloneable {
    public owner: string;
    public collaborators: ICollaborator[];

    constructor(owner: string, collaborators: string[]) {
        this.owner = owner.toLocaleLowerCase();
        this.collaborators = collaborators.map(e => ({ email: e.toLocaleLowerCase() }));
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): AppPermissions {
        return new AppPermissions(apiObject.owner, apiObject.emails);
    }

    /**
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): AppPermissions {
        return new AppPermissions(
            this.owner.slice(),
            this.collaborators.map(c => c.email.slice())
        );
    }
}
