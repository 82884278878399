import { Inject, Injectable } from '@angular/core';
import { HTTP_SERVICE_TOKEN, IHttpService } from '@luis/api';
import { BaseService, GENERIC_CACHE_SERVICE_TOKEN, IGenericCacheService } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { IAppPermissionsService } from '../interfaces/IAppPermissionsService';
import { AppPermissions } from '../models/app-permissions.model';

/**
 * @description
 * Represents a concrete implementation of the IAppPermissionsService interface for app
 * permissions related operations for a LUIS app. This implementation is meant for production use.
 */
@Injectable()
export class AppPermissionsService implements IAppPermissionsService {
	constructor(
		private readonly _baseService: BaseService,
		@Inject(HTTP_SERVICE_TOKEN) private readonly _httpService: IHttpService,
		@Inject(GENERIC_CACHE_SERVICE_TOKEN) private readonly _cacheService: IGenericCacheService
	) {}

	/**
	 * @description
	 * Gets the base web url for manipulating app permissions.
	 *
	 * @returns The base url for app permissions manipulation.
	 */
	private get _baseUrl(): string {
		return `${this._baseService.configs.apiUrl}/apps/${this._baseService.configs.appId}/permissions`;
	}

	/**
	 * @description
	 * Gets the application permissions.
	 *
	 * @returns An observable of the app permissions.
	 */
	public get(): Observable<AppPermissions> {
		const url: string = this._baseUrl;

		return this._cacheService.get(url, AppPermissions.importFromApi, this._baseService.defaultOptionsBuilder.build());
	}

	/**
	 * @description
	 * Updates the list of emails of collaborators.
	 *
	 * @param permissions The updated app permissions.
	 * @returns An observable to indicate completion.
	 */
	public update(permissions: AppPermissions): Observable<void> {
		const payload = { emails: permissions.collaborators.map(c => c.email) };
		const opts = this._baseService.optionsBuilder
			.skipMarkDirty()
			.useCacheOnly()
			.build();

		return this._httpService
			.put(this._baseUrl, payload, this._baseService.defaultOptionsBuilder.build())
			.flatMap(() => this._cacheService.put(this._baseUrl, permissions, opts));
	}
}
