import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'menu-item',
    templateUrl: 'menu-item.component.html',
    styleUrls: ['menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent {
    @Input() styles: Partial<{ selected: boolean; bold: boolean; danger: boolean }> = { bold: false, danger: false, selected: false };
    @Input() icon: string;
    @Input() secondaryText: string;

    @Input() triggerFor: MatMenu;
    @Input() triggerData: any;

    @Output() clicked: EventEmitter<KeyboardEvent> = new EventEmitter();
}
