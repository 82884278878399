import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LEFT_ARROW, RIGHT_ARROW } from '@luis/ui';
import { BehaviorSubject } from 'rxjs';

export enum SLIDES {
	DESIGN,
	BUILD,
	BUILD_OVERLAP,
	IMPROVE,
	IMPROVE_PATTERNS
}

/**
 * @description
 * Represents the modal for intro slides for nontrained app.
 */
@Component({
	selector: 'intro-slides-modal',
	templateUrl: 'intro-slides-modal.component.html',
	styleUrls: ['intro-slides-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntroSlidesModalComponent implements OnInit, OnDestroy {
	public readonly activeSlide: BehaviorSubject<SLIDES> = new BehaviorSubject<SLIDES>(SLIDES.DESIGN);
	public readonly slides: typeof SLIDES = SLIDES;

	private readonly _slides: number = 5;

	public ngOnInit(): void {
		document.querySelector('mat-dialog-container').classList.add('parent-styling');
	}

	public ngOnDestroy(): void {
		document.querySelector('mat-dialog-container').classList.remove('parent-styling');
	}

	@HostListener('document:keydown', ['$event'])
	public onKeyUp(ev: KeyboardEvent): void {
		if (ev.keyCode === RIGHT_ARROW) {
			this.goRight();
		} else if (ev.keyCode === LEFT_ARROW) {
			this.goLeft();
		}
	}

	public goRight(): void {
		if (this.activeSlide.getValue() + 1 < this._slides) {
			this.activeSlide.next(this.activeSlide.getValue() + 1);
		} else {
			this.activeSlide.next(this.slides.DESIGN);
		}
	}

	public goLeft(): void {
		if (this.activeSlide.getValue() - 1 >= 0) {
			this.activeSlide.next(this.activeSlide.getValue() - 1);
		} else {
			this.activeSlide.next(this.slides.IMPROVE_PATTERNS);
		}
	}
}
