import { Directive, OnDestroy, OnInit } from '@angular/core';

/**
 * @description
 * Focuses on the element that had focus before this directive
 * was created and added to the DOM.
 */
@Directive({
    selector: '[focusAfterDestroy]'
})
export class FocusAfterDestroyDirective implements OnInit, OnDestroy {
    private _focusedElementBefore: HTMLElement;

    public ngOnInit(): void {
        this._focusedElementBefore = <HTMLElement>document.activeElement;
    }

    public ngOnDestroy(): void {
        if (this._focusedElementBefore) {
            this._focusedElementBefore.focus();
        }
    }
}
