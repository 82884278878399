import { NgModule } from '@angular/core';
import { PlatformModule } from '../../utils/platform/platform.module';
import { SCROLL_DISPATCHER_PROVIDER } from './scroll-dispatcher';
import { ScrollStrategyOptions } from './scroll-strategy-options';
import { Scrollable } from './scrollable';

@NgModule({
    imports: [PlatformModule],
    exports: [Scrollable],
    declarations: [Scrollable],
    providers: [SCROLL_DISPATCHER_PROVIDER, ScrollStrategyOptions],
})
export class ScrollDispatchModule { }
