import { Inject, Injectable, OnDestroy } from '@angular/core';
import { RequestOptionsBuilder } from '@luis/api';
import {
	BaseService,
	BUS_EVENTS,
	EventBusMessage,
	EventBusService,
	Id,
	ILinkable,
	ILinkableCacheAdapterService,
	IModelChangeBusMessage,
	IResource,
	IResourceApiParser,
	IResourceCacheService,
	LINKABLE_CACHE_ADAPTER_SERVICE_TOKEN,
	MODEL_CHANGE_TYPE,
	RESOURCE_CACHE_SERVICE_TOKEN
} from '@luis/core';
import { Observable, Subscription } from 'rxjs/Rx';
import { EntityHelpers } from '../helpers/entity.helpers';
import { IEntityService } from '../interfaces/IEntityService';
import { ClosedSublist } from '../models/closed-entity.model';
import { Entity, ENTITY_TYPES, EntityRole } from '../models/entity.model';
import { ParentEntity } from '../models/parent-entity.model';
import { EntityFactoryService } from './entity-factory.service';
import { first, tap } from 'rxjs/operators';

/**
 * @description
 * Represents a concrete implementation of the IEntityService interface for intent related
 * operations for a LUIS application. This implementation is meant for production use.
 */
@Injectable()
export class EntityService implements IEntityService, OnDestroy {
	private prefixMap: Map<number, string>;
	private rootPrefixMap: Map<number, string>;
	private _domainChangeSubscription: Subscription = new Subscription();
	private _domainAddedSubscription: Subscription = new Subscription();

	constructor(
		private _baseService: BaseService,
		private _eventBus: EventBusService,
		private _entityFactory: EntityFactoryService,
		@Inject(RESOURCE_CACHE_SERVICE_TOKEN) private _resourceCacheService: IResourceCacheService,
		@Inject(LINKABLE_CACHE_ADAPTER_SERVICE_TOKEN) private _linkableCacheAdapterService: ILinkableCacheAdapterService
	) {
		this.rootPrefixMap = this._entityFactory.getUrlPrefixMap();
		this.prefixMap = this._entityFactory.getUrlPrefixMap(false);
		this._subscribeToEventBus();
	}

	public ngOnDestroy(): void {
		this._domainChangeSubscription.unsubscribe();
		this._domainAddedSubscription.unsubscribe();
	}

	/**
	 * @description
	 * Gets the base url for calling entity related functions.
	 */
	private get _baseUrl(): string {
		return `apps/${this._baseService.configs.appId}/versions/${this._baseService.configs.versionId}`;
	}

	/**
	 * @description
	 * Gets all the entities in the application.
	 *
	 * @returns An observable of all the entities in the app.
	 */
	public get(refresh: boolean = false): Observable<Entity[]> {
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/models`;
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return <Observable<Entity[]>>(
			this._resourceCacheService.get(
				`${url}?take=1000`,
				this._entityFactory.importFromApi.bind(this._entityFactory),
				builder.build(),
				url
			)
		);
	}

	/**
	 * @description
	 * Adds a new entity to the application.
	 *
	 * @param entity The entity to add.
	 * @param refresh If true, the entity is refresehd from the api after adding it.
	 * @returns An observable of the id of the created entity.
	 */
	public add(entity: Entity | (ILinkable & IResource), refresh: boolean = false): Observable<Id | void> {
		const builder: RequestOptionsBuilder = this._getRequestOptionsBuilderByEntity(entity);
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/models`;

		if (refresh) {
			builder.forceCacheRefresh();
		}

		return this._linkableCacheAdapterService
			.add(
				entity,
				this._entityFactory.importFromApi.bind(this._entityFactory),
				this.prefixMap,
				this._entityFactory.getUrlPrefixMap(true, true),
				builder.build(),
				url
			)
			.pipe(
				tap(e => {
					if (entity instanceof Entity) {
						const modelChangeMessage: IModelChangeBusMessage = { model: <any>entity, changeType: MODEL_CHANGE_TYPE.ADD };
						this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_CHANGED, modelChangeMessage));
					}

					// Prebuilt entities can be linkable (composite, hierarchical, hierarchical children) entities, when they are added their children or parents are to be added
					// too, hence we refresh the cache to get these.
					if (entity.type === ENTITY_TYPES.DOMAIN) {
						this.get(true)
							.pipe(first())
							.subscribe();
					}
				})
			);
	}

	/**
	 * @description
	 * Adds new entities to the application.
	 *
	 * @param entities The entities to add.
	 * @param entitiesType The entities to be added type.
	 * @returns An observable of the entities created.
	 */
	public batchAdd(entities: Entity[], entitiesType: number): Observable<Entity[]> {
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/models`;
		const builder: RequestOptionsBuilder = this._baseService.defaultOptionsBuilder;

		if (!entities.filter(entity => EntityHelpers.isTrainable(entity.type)).length) {
			builder.skipMarkDirty();
		}

		return <Observable<Entity[]>>(
			this._resourceCacheService
				.batchPost(
					`${this._baseService.configs.apiUrl}/${this._baseUrl}/${this._entityFactory.getUrlPrefixMap().get(entitiesType)}`,
					entities,
					this._entityFactory.getParserFunction(entitiesType),
					builder.build(),
					url
				)
				.do(() => {
					entities.forEach(entity => {
						const modelChangeMessage: IModelChangeBusMessage = { model: <Entity>entity, changeType: MODEL_CHANGE_TYPE.ADD };
						this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_CHANGED, modelChangeMessage));
					});
				})
		);
	}

	/**
	 * @description
	 * Updates an existing entity in the application.
	 *
	 * @param entity The entity to update.
	 * @returns An observable to indicate completion.
	 */
	public update(entity: Entity | (ILinkable & IResource), refresh: boolean = false, clean: boolean = false): Observable<void> {
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/models`;
		const builder: RequestOptionsBuilder = this._getRequestOptionsBuilderByEntity(entity);
		const parser: IResourceApiParser = this._entityFactory.getParserFunction(entity.type);
		const msg: IModelChangeBusMessage = { model: <Entity>entity, changeType: MODEL_CHANGE_TYPE.UPDATE };
		const needsTraining: boolean = entity instanceof Entity && EntityHelpers.isTrainable(entity.type);

		if (clean) {
			builder.skipMarkDirty();
		}

		return this._linkableCacheAdapterService
			.update(entity, this.prefixMap, this.rootPrefixMap, builder.build(), url, refresh, parser)
			.do(() => (entity instanceof Entity ? this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_CHANGED, msg)) : null))
			.do(() => (entity instanceof Entity ? this._renameCompositeChildrenReferences(entity, url) : null))
			.do(() =>
				entity instanceof EntityRole
					? this._eventBus.publishToBus(
							new EventBusMessage(BUS_EVENTS.ROLE_CHANGED, { model: entity, changeType: MODEL_CHANGE_TYPE.UPDATE })
					  )
					: null
			);
	}

	/**
	 * @description
	 * Deletes the entity from the application.
	 *
	 * @param entity The entity to delete.
	 * @returns An observable to indicate completion.
	 */
	public delete(entity: Entity | (ILinkable & IResource)): Observable<void> {
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/models`;

		return this._linkableCacheAdapterService
			.delete(entity, this.prefixMap, this.rootPrefixMap, this._getRequestOptionsBuilderByEntity(entity).build(), url)
			.do(() => {
				if (entity instanceof Entity) {
					if (entity.parent !== null && entity.parent.type === ENTITY_TYPES.COMPOSITE) {
						return;
					}
					const modelChangeMessage: IModelChangeBusMessage = { model: <Entity>entity, changeType: MODEL_CHANGE_TYPE.DELETE };
					this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.MODEL_CHANGED, modelChangeMessage));
				}
			})
			.do(() => {
				if (entity instanceof ClosedSublist) {
					this._eventBus.publishToBus(new EventBusMessage(BUS_EVENTS.INVALIDATE_UTTERANCES_CACHE));
				}
			})
			.do(() => {
				if (entity instanceof EntityRole) {
					this._eventBus.publishToBus(
						new EventBusMessage(BUS_EVENTS.ROLE_CHANGED, { model: entity, changeType: MODEL_CHANGE_TYPE.DELETE })
					);
				}
			});
	}

	/**
	 * @description
	 * On the event of entity rename, this function loops over
	 * all the composite entities and makes sure that all children
	 * that were that entity have their name updated. It then locally
	 * updates the cache with the updated entities.
	 *
	 * @param entity The entity that was updated.
	 * @param path The path of the entities in the cache.
	 */
	private _renameCompositeChildrenReferences(entity: Entity, path: string): void {
		if (entity.type === ENTITY_TYPES.COMPOSITE) {
			return;
		}

		this.get()
			.first()
			.map(entities => entities.map(e => e.clone()))
			.flatMap(entities => {
				const opts = this._baseService.optionsBuilder.useCacheOnly().build();
				const childrenThatMatch = entities
					.filter(e => e.type === ENTITY_TYPES.COMPOSITE)
					.reduce((acc: Entity[], composite: ParentEntity) => acc.concat(composite.children), [])
					.filter(child => child.id === entity.id);
				const nameToReplace =
					entity.type === ENTITY_TYPES.HIERARCHICAL_CHILD
						? (<ParentEntity>entity.parent).getChildWithFullName(entity.id).name
						: entity.name;

				if (childrenThatMatch.length) {
					childrenThatMatch.forEach(child => (child.name = nameToReplace));

					return this._resourceCacheService.batchPut(path, entities, opts);
				} else {
					return Observable.of(null);
				}
			})
			.subscribe();
	}

	/**
	 * @description
	 * Gets RequestOptionsBuilder object according to the passed entity.
	 * if the entity is not trainable, the RequestOptionsBuilder skips marking
	 * the app as dirty.
	 *
	 * @param entity The entity to check.
	 * @returns The RequestOptionsBuilder object
	 */
	private _getRequestOptionsBuilderByEntity(entity: Entity | (ILinkable & IResource)): RequestOptionsBuilder {
		if (EntityHelpers.isTrainable(entity.type)) {
			return this._baseService.defaultOptionsBuilder;
		} else {
			return this._baseService.defaultOptionsBuilder.skipMarkDirty();
		}
	}

	/**
	 * Invalidates all the data of the models path in cache.
	 */
	private _invalidateCache(): void {
		const url: string = `${this._baseService.configs.apiUrl}/${this._baseUrl}/models`;

		this._resourceCacheService.invalidate(url);
	}

	/**
	 * @description
	 * Subscribes to event bus to react to certain bus events.
	 */
	private _subscribeToEventBus(): void {
		this._domainChangeSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.DOMAIN_CHANGED, () => this.get(true));
		this._domainAddedSubscription = this._eventBus.subscribeToBus(BUS_EVENTS.INVALIDATE_ENTITIES_CACHE, () => this._invalidateCache());
	}
}
