import { IApiExportable, ICloneable } from '@luis/core';

/**
 * @description
 * Represents an utterance intent. An utterance intent is returned with a score
 * that LUIS predicts for this utterance.
 *
 * @param name The intent name.
 * @param score The intent prediction score for this utterance.
 */
export class UtteranceIntent implements ICloneable, IApiExportable {
    public id: string;
    public name: string;
    public score: number;

    constructor(id: string = '', name: string = '', score: number = 1) {
        this.id = id;
        this.name = name;
        this.score = score;
    }

    /**
     * @method
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): UtteranceIntent {
        return new UtteranceIntent(
            apiObject.id,
            apiObject.name || '',
            apiObject.score
        );
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): UtteranceIntent {
        return new UtteranceIntent(
            this.id ? this.id.slice() : this.id,
            this.name.slice(),
            this.score
        );
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {
            name: this.name
        };
    }
}
