import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as c3 from 'c3';
import { AxisData, CHART_TYPES } from '../../models/chart.model';
import { ChartsRendererService } from '../../services/charts-renderer.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

export interface IPointClickInput {
	id: number;
	xValue: number;
}

@Component({
	selector: '[confusion-matrix]',
	template: '<loading [visible]="!(isInitialized)"></loading>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfusionMatrixComponent extends BaseChartComponent implements AfterViewInit {
	@Output() public pointClick: EventEmitter<IPointClickInput> = new EventEmitter();

	constructor(private readonly i18n: TranslateService, private elementRef: ElementRef, private chartsRenderer: ChartsRendererService) {
		super(i18n, elementRef, chartsRenderer);
	}

	private static _getMaxAbsoluteValue(data: number[]): number {
		return data.length === 0 ? 0 : Math.max.apply(null, data.map(Math.abs));
	}

	/**
	 * @description Generates an array starting and ending from the given params with the specified step
	 */
	private static _range(start: number, end: number, step: number): number[] {
		const values: number[] = [];
		for (let i: number = start; i < end; i += step) {
			values.push(i);
		}

		return values;
	}

	public ngAfterViewInit(): void {
		this.xData
			.zip(this.yData, (xData, yData) => ({ xData, yData }))
			.map(this.filterZeroedData)
			.subscribe(({ xData, yData }) => {
				const config: c3.ChartConfiguration = this.getConfig(xData, yData);
				this.isInitialized = true;

				this.init(config);
			});
	}

	/**
	 * @description
	 * Initializes the chart component with the required configuration
	 */
	protected init(config: c3.ChartConfiguration): void {
		super.init({
			...config,
			bindto: this.elementRef.nativeElement
		});
	}

	/**
	 * @description
	 * Transforms AxisData to columns array accepted by ChartRendererService
	 */
	private _transformAxisDataToColumns(xData: AxisData, yData: AxisData): c3.PrimitiveArray[] {
		return [[xData.title, ...xData.data], [yData.title, ...yData.data.map(d => d.score)]];
	}

	/**
	 * @description
	 * Creates the configuration accepted by the ChartRendererService for generating a pie chart
	 */
	private getConfig(xData: AxisData, yData: AxisData): c3.ChartConfiguration {
		const maxAbsXData: number = ConfusionMatrixComponent._getMaxAbsoluteValue(xData.data);
		const data: any = {
			x: xData.title,
			columns: this._transformAxisDataToColumns(xData, yData),
			labels: false,
			type: CHART_TYPES.CONFUSION,
			onclick: this._handleClick(yData),
			color: this._generateColor,
			selection: {
				enabled: true,
				multiple: false
			}
		};

		const axis: any = {
			x: {
				min: maxAbsXData * -1,
				max: maxAbsXData,
				lines: [
					{
						value: 0
					}
				],
				tick: {
					outer: false,
					values: ConfusionMatrixComponent._range(maxAbsXData * -1, maxAbsXData, 1),
					count: 20,
					format: () => ''
				}
			},
			y: {
				min: -1,
				max: 1,
				lines: [
					{
						value: 0
					}
				]
			}
		};

		const grid: any = {
			x: {
				lines: [
					{
						value: 0
					}
				]
			},
			y: {
				show: true,
				lines: [
					{
						value: 0
					}
				]
			}
		};

		return {
			data,
			axis,
			grid,
			tooltip: {
				format: {
					title: () => '',
					name: () => 'Score',
					value: value => value
				}
			},
			point: {
				r: 5
			},
			zoom: {
				enabled: true
			}
		};
	}

	/**
	 * @description Color genertion function accepted by c3
	 */
	private _generateColor(_: any, dataPoint: { x: number; value: number }): string {
		if (dataPoint.x === undefined) {
			return;
		}

		return dataPoint.x * dataPoint.value > 0 ? '#a80000' : '#00b294';
	}

	/**
	 * @description
	 * A function invoked with the point data that was clicked on.
	 */
	private _handleClick(yData: AxisData): (dataPoint: { x: number; value: number }) => void {
		return (dataPoint: { x: number; value: number }) => {
			const selectedExample: any = yData.data.find(datum => {
				return datum.xValue === dataPoint.x && parseFloat(datum.score) === dataPoint.value;
			});

			this.pointClick.emit({
				id: parseInt(selectedExample.id, 10),
				xValue: selectedExample.xValue
			});
		};
	}
}
