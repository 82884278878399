import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AppCulture } from '../models/app-culture.model';
import { AppResourceLimits } from '../models/app-resource-limits.model';
import { App } from '../models/app.model';

export const APP_UTILITIES_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IAppUtilitiesService');

/**
 * @description
 * Represents an interface for an app service. An app service should contain
 * CRUD methods for LUIS apps.
 */
export interface IAppUtilitiesService {
    getCultures(): Observable<AppCulture[]>;

    importApp(appJsonString: string, appName?: string): Observable<App>;

    importLogs(app: App, logsCsvFile: string): Observable<void>;

    exportApp(app: App): Observable<void>;

    exportForContainer(app: App, isStaging: boolean): Observable<void>;

    getExportLogsUrl(app: App): Observable<string>;

    getAppLimits(): Observable<AppResourceLimits>;

    setAppActiveVersion(app: App, versionId: string): Observable<void>;
}
