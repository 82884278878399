import { InjectionToken } from '@angular/core';
import { Id } from '@luis/core';
import { Observable } from 'rxjs/Rx';
import { Pattern } from '../models/pattern.model';
import { UtterancePattern } from '../models/utterance-pattern.model';

export const PATTERN_SERVICE_TOKEN: InjectionToken<string> = new InjectionToken('IPatternService');

/**
 * @description
 * Represents an interface for a pattern service. A pattern service should contain
 * CRUD methods for LUIS patterns.
 */
export interface IPatternService {
	get(refresh?: boolean): Observable<Pattern[]>;

	add(pattern: Pattern, refresh?: boolean): Observable<Id | void>;

	update(pattern: Pattern): Observable<void>;

	batchUpdate(patterns: Pattern[]): Observable<void>;

	delete(pattern: Pattern): Observable<void>;

	batchDelete(patterns: Pattern[]): Observable<Id[]>;

	updateTestingUtterancePattern(utterance: UtterancePattern, intentName: string): void;
}
