import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Renderer,
    SimpleChange,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { OverlayRef } from '../overlay/overlay-ref';
import { OverlayState } from '../overlay/overlay-state';
import { HorizontalConnectionPos, VerticalConnectionPos } from '../overlay/position/connected-position';
import { ConnectedPositionStrategy } from '../overlay/position/connected-position-strategy';
import { TemplatePortal } from '../portal/portal';
import { OverlayService } from '../services/overlay.service';
import { isFakeMousedownFromScreenReader } from '../utils/accessibility/fake-mousedown';
import { ENTER } from '../utils/keyboard/keycodes';
import { throwMdMenuMissingError } from './menu-errors';
import { MenuComponent } from './menu.component';

export type MenuPositionX = 'before' | 'after' | 'toRight' | 'toLeft';
export type MenuPositionY = 'above' | 'below';

@Directive({
    selector: '[mTriggerFor]',
    host: {
        'aria-haspopup': 'true',
        '[attr.aria-expanded]': 'menuOpen || null',
        '(mousedown)': '_handleMousedown($event)',
        '(keyup)': '_handleKeydown($event)',
        '(mouseenter)': '!openOnClick ? _handleMouseEnter($event) : null'
    }
})
export class MenuTriggerDirective implements OnChanges {
    private _portal: TemplatePortal;
    private _overlayRef: OverlayRef;
    private _menuOpen: boolean = false;
    private _backdropSubscription: Subscription;
    private _positionSubscription: Subscription;
    private _childMenuCloseSubscription: Subscription;
    private _dir: 'ltr' | 'rtl' = 'ltr';  // temp fix until we put localization at a higher priority

    // tracking input type is necessary so it's possible to only auto-focus
    // the first item of the list when the menu is opened via the keyboard
    private _openedByMouse: boolean = false;

    // Trigger input for compatibility mode
    @Input('mMenuTriggerFor')
    get _matMenuTriggerFor(): MenuComponent { return this.menu; }
    set _matMenuTriggerFor(v: MenuComponent) { this.menu = v; }

    /** References the menu instance that the trigger is associated with. */
    @Input('mTriggerFor') menu: MenuComponent;

    @Input() openOnClick: boolean = false;

    /** Class to be applied to trigger element when menu is open. */
    @Input() openedClass: string = null;

    /** boolean trigger to hijack trigger */
    @Input() booleanTrigger: boolean = null;

    /** Event emitted when the associated menu is opened. */
    @Output() onMenuOpen = new EventEmitter<void>();

    /** Event emitted when the associated menu is closed. */
    @Output() onMenuClose = new EventEmitter<void>();

    constructor(
        private _overlay: OverlayService,
        private _element: ElementRef,
        private _renderer: Renderer,
        private _viewContainerRef: ViewContainerRef) { }

    ngAfterViewInit() {
        this._checkMenu();
        this.menu.close.subscribe(() => {
            // If the menu is a sub-menu, do not close after receiving a close event,
            // let the parent choose what to do.
            // If the menu is a root level menu, close when the menu emits a close event.
            this.menu.parentMenu ? null : this.closeMenu();
        });
    }

    ngAfterContentInit() {
        // Pass reference down to menu
        this.menu._triggerRef = this;
    }

    ngOnDestroy() { this.destroyMenu(); }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        // If the boolean trigger changes, open or close the menu.
        if (changes['booleanTrigger']) {
            const newBoolVal = changes['booleanTrigger'].currentValue;
            newBoolVal ? this.openMenu() : this.closeMenu();
        }
    }

    /** Whether the menu is open. */
    get menuOpen(): boolean { return this._menuOpen; }

    /** Toggles the menu between the open and closed states. */
    toggleMenu(): void {
        return this._menuOpen ? this.closeMenu() : this.openMenu();
    }

    /** Opens the menu. */
    openMenu(): void {
        if (!this._menuOpen) {
            this._createOverlay();
            this._overlayRef.attach(this._portal);
            this._subscribeToBackdrop();
            this._initMenu();
            this._setOpenedClass();
        }
    }

    /** Closes the menu. */
    closeMenu(): void {
        if (this._overlayRef) {
            this._overlayRef.detach();
            this._backdropSubscription ? this._backdropSubscription.unsubscribe() : null;
            this._resetMenu();
        }
    }

    /** Removes the menu from the DOM. */
    destroyMenu(): void {
        if (this._overlayRef) {
            this._overlayRef.dispose();
            this._overlayRef = null;

            this._cleanUpSubscriptions();
        }
    }

    /** Focuses the menu trigger. */
    focus() {
        this._element.nativeElement.focus();
    }

    /** The text direction of the containing app. */
    get dir(): 'ltr' | 'rtl' {    // temp fix until localization becomes a higher priority
        return this._dir && this._dir === 'rtl' ? 'rtl' : 'ltr';
    }

    /**
     * This method ensures that the menu closes when the overlay backdrop is clicked.
     * We do not use first() here because doing so would not catch clicks from within
     * the menu, and it would fail to unsubscribe properly. Instead, we unsubscribe
     * explicitly when the menu is closed or destroyed.
     */
    private _subscribeToBackdrop(): void {
        // If menu is a root-level menu, close all children when the root backdrop is clicked.
        // Otherwise, the menu is a sub-menu and should not worry about the backdrop.
        if (!this.menu.parentMenu) {
            this._backdropSubscription = this._overlayRef.backdropClick().subscribe(() => {
                this.menu.closeSelfAndChildMenus();
            });
        }
    }

    /** Subscribe to child menu's close event. */
    public subscribeToChildMenuClose(menu: MenuComponent) {
        this._childMenuCloseSubscription = menu.close.subscribe((a: any) => {
            // TODO(toanzian): add unsubscribe function
            // Here you can do whatever you want to after receiving a close from a child menu.
            this._handleChildCloseEvent(menu);
        });
    }

    /**
     * This method sets the menu state to open and focuses the first item if
     * the menu was opened via the keyboard.
     */
    private _initMenu(): void {
        this._setIsMenuOpen(true);

        // Should only set focus if opened via the keyboard, so keyboard users can
        // can easily navigate menu items. According to spec, mouse users should not
        // see the focus style.
        if (!this._openedByMouse) {
            this.menu.focusFirstItem();
        }
    }

    /** Fired at the parent level when receiving a child menu's close event. */
    private _handleChildCloseEvent(childMenu: MenuComponent) {
        // TODO(toanzian): Allow custom handler function to be invoked here

        // Closes the child menu and propagates the event upwards.
        childMenu._triggerRef.closeMenu();
        this.menu._emitCloseEvent();
    }

    /**
     * This method resets the menu when it's closed, most importantly restoring
     * focus to the menu trigger if the menu was opened via the keyboard.
     */
    private _resetMenu(): void {
        this._setIsMenuOpen(false);

        // Focus only needs to be reset to the host element if the menu was opened
        // by the keyboard and manually shifted to the first menu item.
        if (!this._openedByMouse) {
            this.focus();
        }
        this._openedByMouse = false;

        // Remove opened menu class
        this._removeOpenedClass();
    }

    // set state rather than toggle to support triggers sharing a menu
    private _setIsMenuOpen(isOpen: boolean): void {
        this._menuOpen = isOpen;
        this._menuOpen ? this.onMenuOpen.emit() : this.onMenuClose.emit();
    }

    /**
     *  This method checks that a valid instance of MdMenu has been passed into
     *  mdMenuTriggerFor. If not, an exception is thrown.
     */
    private _checkMenu() {
        if (!this.menu) {
            throwMdMenuMissingError();
        }
    }

    /**
     *  This method creates the overlay from the provided menu's template and saves its
     *  OverlayRef so that it can be attached to the DOM when openMenu is called.
     */
    private _createOverlay(): void {
        if (!this._overlayRef) {
            this._portal = new TemplatePortal(this.menu.templateRef, this._viewContainerRef);

            // If the menu is to be controlled by a boolean trigger, we do not want
            // to create the overlay with a backdrop so that we avoid closing the
            // menu by clicking the backdrop and its visibility is completely driven by the boolean value.
            let _config;
            if (this.booleanTrigger != null || this.menu.parentMenu) {
                _config = this._getOverlayConfig(false);
            }
            else {
                _config = this._getOverlayConfig();
            }
            const config = _config;
            this._subscribeToPositions(config.positionStrategy as ConnectedPositionStrategy);
            this._overlayRef = this._overlay.create(config);
        }
    }

    /**
     * This method builds the configuration object needed to create the overlay, the OverlayState.
     * @returns OverlayState
     */
    private _getOverlayConfig(includeBackdrop: boolean = true): OverlayState {
        const overlayState = new OverlayState();
        overlayState.positionStrategy = this._getPosition()
            .withDirection(this.dir);
        if (includeBackdrop) {
            overlayState.hasBackdrop = true;
        }
        else {
            overlayState.hasBackdrop = false;
        }
        overlayState.backdropClass = 'm-overlay-transparent-backdrop';
        overlayState.direction = this.dir;
        overlayState.scrollStrategy = this._overlay.scrollStrategies.reposition();
        return overlayState;
    }

    /**
     * Sets a class on the menu trigger when the menu is opened.
     */
    private _setOpenedClass() {
        if (this.openedClass) {
            this._renderer.setElementClass(this._element.nativeElement, this.openedClass, true);
        }
    }

    /**
     * Removes an open class on the menu trigger when the menu is closed.
     */
    private _removeOpenedClass() {
        if (this.openedClass) {
            this._renderer.setElementClass(this._element.nativeElement, this.openedClass, false);
        }
    }

    /**
     * Listens to changes in the position of the overlay and sets the correct classes
     * on the menu based on the new position. This ensures the animation origin is always
     * correct, even if a fallback position is used for the overlay.
     */
    private _subscribeToPositions(position: ConnectedPositionStrategy): void {
        // TODO(toanzian): update to use toRight and toLeft position values
        this._positionSubscription = position.onPositionChange.subscribe((change) => {
            const posX: MenuPositionX = change.connectionPair.originX === 'start' ? 'after' : 'before';
            let posY: MenuPositionY = change.connectionPair.originY === 'top' ? 'below' : 'above';

            if (!this.menu.overlapTrigger) {
                posY = posY === 'below' ? 'above' : 'below';
            }

            this.menu.setPositionClasses(posX, posY);
        });
    }

    /**
     * This method builds the position strategy for the overlay, so the menu is properly connected
     * to the trigger.
     * @returns ConnectedPositionStrategy
     */
    private _getPosition(): ConnectedPositionStrategy {

        // Determine the horizontal position for the origin to
        // overlay connection. Added toRight & toLeft logic
        // for our own context menu. Instead of writing
        // a ton of extra logic, just convert our toRight & toLeft
        // properties into overlay & origin position combinations
        // that the code can already understand.
        // TODO (toanzian): Improve fallback posiitons for toRight & toLeft
        let _fallbackX: HorizontalConnectionPos, posXOrigin: HorizontalConnectionPos, posXOverlay: HorizontalConnectionPos;
        if (this.menu.xPosition === 'before') {
            _fallbackX = 'start';
            posXOrigin = 'end';
            posXOverlay = 'end'
        } else if (this.menu.xPosition === 'after') {
            _fallbackX = 'end';
            posXOrigin = 'start';
            posXOverlay = 'start';
        } else if (this.menu.xPosition === 'toRight') {
            // When we want our menu to the right of the origin,
            // the 'start' of the overlay will be at the 'end' of
            // the origin.
            _fallbackX = 'start';
            posXOrigin = 'end';
            posXOverlay = 'start';
        } else if (this.menu.xPosition === 'toLeft') {
            // When we want our menu to the left of the origin,
            // the 'end' of the overlay will be at the 'start' of
            // the origin.
            _fallbackX = 'start';
            posXOrigin = 'start';
            posXOverlay = 'end';
        }

        const fallbackX: HorizontalConnectionPos = _fallbackX;

        const [overlayY, fallbackOverlayY]: VerticalConnectionPos[] =
            this.menu.yPosition === 'above' ? ['bottom', 'top'] : ['top', 'bottom'];

        let originY = overlayY;
        let fallbackOriginY = fallbackOverlayY;

        if (!this.menu.overlapTrigger) {
            originY = overlayY === 'top' ? 'bottom' : 'top';
            fallbackOriginY = fallbackOverlayY === 'top' ? 'bottom' : 'top';
        }

        return this._overlay.position()
            .connectedTo(this._element,
                { originX: posXOrigin, originY: originY }, { overlayX: posXOverlay, overlayY: overlayY })
            .withFallbackPosition(
                { originX: fallbackX, originY: originY },
                { overlayX: fallbackX, overlayY: overlayY })
            .withFallbackPosition(
                { originX: posXOrigin, originY: fallbackOriginY },
                { overlayX: posXOrigin, overlayY: fallbackOverlayY })
            .withFallbackPosition(
                { originX: fallbackX, originY: fallbackOriginY },
                { overlayX: fallbackX, overlayY: fallbackOverlayY });
    }

    /** Unsubscribes from backdrop and position subscriptions. */
    private _cleanUpSubscriptions(): void {
        if (this._backdropSubscription) {
            this._backdropSubscription.unsubscribe();
        }
        if (this._positionSubscription) {
            this._positionSubscription.unsubscribe();
        }
        if (this._childMenuCloseSubscription) {
            this._childMenuCloseSubscription.unsubscribe();
        }
    }

    /** Handles mouse enter events. */
    _handleMouseEnter(event: Event) {
        this._openedByMouse = true;
        this.openMenu();
    }

    /** Handles mousedown events. */
    _handleMousedown(event: MouseEvent): void {
        if (!isFakeMousedownFromScreenReader(event)) {
            this._openedByMouse = true;
        }
        // if there is no boolean trigger, open the menu and stop click
        // propagation so that the parent menu isn't closed
        if (this.booleanTrigger === null) {
            if (this.openOnClick) {
                this.toggleMenu();
            } else {
                // Prevent click-induced focus on hoverable menu triggers
                event.preventDefault();
            }
            event.stopPropagation();
        }
    }

    /** Handles keydown events. */
    _handleKeydown(event: KeyboardEvent) {
        switch (event.keyCode) {
            case ENTER:
                // open the menu on ENTER if there is no boolean trigger
                if (this.booleanTrigger === null) {
                    this.toggleMenu();
                }

                event.preventDefault();
                event.stopPropagation();
        }
    }
}
