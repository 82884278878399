import { IResource } from '@luis/core';

/**
 * @description
 * Represents an Azure tenant.
 *
 * @param id The tenant id
 * @param name The tenant name
 */
export class Tenant implements IResource {
    public id: string;
    public name: string;

    constructor(id: string = '', name: string = '') {
        this.id = id;
        this.name = name;
    }

    /**
     * @description
     * Creates a new resource object from the api object received from web.
     *
     * @param apiObject The object received by the web api.
     * @returns A new object of this resource.
     */
    public static importFromApi(apiObject: any): Tenant {
        let tenantDisplayName: string = apiObject.displayName;
        const tenantIsUndefined: boolean = tenantDisplayName === undefined;
        const domainsAreExistent: boolean = apiObject.domains && apiObject.domains.length > 0;

        if ((tenantIsUndefined || tenantDisplayName.toLocaleLowerCase() === 'default directory') && domainsAreExistent) {
            tenantDisplayName = `${apiObject.domains[0].toLocaleLowerCase().replace('.onmicrosoft.com', '')} (${tenantDisplayName})`;
        }
        else if (tenantIsUndefined) {
            tenantDisplayName = apiObject.tenantId;
        }

        return new Tenant(apiObject.tenantId, tenantDisplayName);
    }

    /**
     * @method
     * @description
     * Deep clones the resource object.
     *
     * @returns A deep clone of this object.
     */
    public clone(): Tenant {
        return new Tenant(this.id.slice(), this.name.slice());
    }

    /**
     * @method
     * @description
     * Converts this resource to an object that matches the web api.
     *
     * @returns An object that matches the web api of this resource.
     */
    public exportToApi(): Object {
        return {};
    }
}
