import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/router-guards/auth-guard.service';
import { MaintenanceGuard } from '../app/router-guards/maintenance-guard.service';
import { PageNotFoundComponent } from './components/404/404.component';
import { HomeComponent } from './components/home/home.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { OverviewComponent } from './components/overview/overview.component';

const routes: Routes = [
    {
        path: 'welcome',
        component: OverviewComponent,
        canActivate: [AuthGuard, MaintenanceGuard],
        data: { title: 'LUIS: Welcome!' }

    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard, MaintenanceGuard],
        data: { title: 'LUIS (Language Understanding) – Cognitive Services – Microsoft Azure' }
    },
    {
        path: '404',
        component: PageNotFoundComponent,
        canActivate: [AuthGuard, MaintenanceGuard],
        data: { title: 'LUIS: Not Found' }

    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        data: { title: 'LUIS: Maintenance Mode' }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class StaticRoutingModule { }
