import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as c3 from 'c3';
import { Observable, of } from 'rxjs';
import { AxisData } from '../../models/chart.model';
import { ChartsRendererService } from '../../services/charts-renderer.service';

@Component({
	selector: 'base-chart',
	template: ''
})
export class BaseChartComponent implements OnChanges, OnDestroy {
	@Input() public xLabel: string = '';
	@Input() public emptyLabelText: string = this._i18n.instant('ui.chart.empty_label_text');
	@Input() public colors: string[] = ['#004E8C', '#767676', '#83BEEC', '#ffB900', '#750B1C', '#0078D7'];

	@Input() public createTooltip: Function;
	@Input() public onLoad: Function = new Function();

	@Input() public padding: any = {};

	@Input() public xData: Observable<AxisData>;
	@Input() public yData: Observable<AxisData>;
	@Input() public chartTitle: Observable<string> = of(null);
	@Input() public dataToDefocus: Observable<string[]>;

	public isInitialized: boolean = false;

	private _backingChart: c3.ChartAPI;
	private _defaultColorOptions: any = { pattern: this.colors };
	private readonly _defaultLegendOptions: any = { show: false };
	private readonly _defaultZoomOptions: any = { enabled: false };
	private readonly _defaultGridOptions: any = { y: { show: true, lines: [] } };

	constructor(
		private readonly _i18n: TranslateService,
		private readonly _elementRef: ElementRef,
		private readonly _chartsRenderer: ChartsRendererService
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.colors) {
			this._defaultColorOptions = { pattern: this.colors };
		}
	}

	public ngOnDestroy(): void {
		if (this._backingChart) {
			this._backingChart.destroy();
		}
	}

	public focusData(ids: string[]): void {
		this._backingChart.focus(ids);
	}

	public revertFocus(): void {
		if (this._backingChart) {
			this._backingChart.revert();
		}
	}

	/**
	 * @description
	 * Updates the already created chart components
	 */
	public update = (
		{ data, columns, categories }: { data?: {}; columns?: {}; categories?: any[] },
		isDataZeroedData: boolean = false
	): void => {
		if (this._backingChart !== null) {
			data = isDataZeroedData ? { ...data, unload: isDataZeroedData } : data;
			this._backingChart.load(data, { columns, categories });
		}
	};

	/**
	 * @description
	 * Initializes the chart component with the required configuration
	 */
	protected init(chartConfiguration: c3.ChartConfiguration): void {
		chartConfiguration.grid = chartConfiguration.grid || this._defaultGridOptions;
		chartConfiguration.zoom = chartConfiguration.zoom || this._defaultZoomOptions;
		chartConfiguration.color = chartConfiguration.color || this._defaultColorOptions;
		chartConfiguration.legend = chartConfiguration.legend || this._defaultLegendOptions;

		chartConfiguration.onresize = this._onResize;
		chartConfiguration.padding = chartConfiguration.padding || {
			bottom: 32,
			left: 32,
			right: 32
		};

		this._backingChart = this._chartsRenderer.draw(chartConfiguration);
		this._elementRef.nativeElement.setAttribute('aria-hidden', 'true');
	}

	/**
	 * @description
	 * Returns the given arguments if the data inside yData only contains zeros
	 */
	protected filterZeroedData({ xData, yData }: { xData: AxisData; yData: AxisData }): { xData: AxisData; yData: AxisData } {
		const isDataZeroed: boolean = yData.data.reduce((prevVal, accVal) => prevVal + accVal, 0) === 0;
		if (isDataZeroed || xData.data.length === 0) {
			xData.data = [];
			yData.data = [];
		}

		return { xData, yData };
	}

	/**
	 * @description
	 * Gets called whenever the chart gets resized
	 */
	private readonly _onResize = (): void => {
		if (this._backingChart !== null) {
			this._backingChart.resize({
				width: this._elementRef.nativeElement.offsetWidth,
				height: this._elementRef.nativeElement.offsetHeight
			});
		}
	};
}
