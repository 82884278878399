import { Pipe, PipeTransform } from '@angular/core';
import { EntityWrapService } from '@luis/entities';
import { Observable } from 'rxjs/Rx';
import { IHover } from '../models/plain-segment.model';

@Pipe({
    name: 'plainWrapSelectionAsync'
})
export class PlainWrapSelectionAsyncPipe implements PipeTransform {
    constructor(
        private _entityWrapService: EntityWrapService
    ) { }

    /**
     * @method
     * @description
     * Returns true if an underline should be viewed under a plain token segment.
     * An underline should be viewed if composite wrap mode is on, the selection
     * is valid, and the token is either hovered on or part of the hovering
     * selection process.
     *
     * @param isHovered Triggers the pipe when the entity is hovered.
     * @param segment The segment this entity belongs to.
     * @returns An observable to indicate whether the underline
     * should be showing or not.
     */
    public transform(hoverData: IHover, tokenIndex: number, offset: number): Observable<boolean> {
        const offsetIndex: number = tokenIndex + offset;

        return Observable.combineLatest(
            this._entityWrapService.getWrapMode(),
            this._entityWrapService.isValidSelection(offsetIndex),
            this._entityWrapService.getWrapHoverIndeces()
        )
            .map(([wrapMode, isValid, hoverIndeces]) => {
                if (!wrapMode || !isValid) {
                    return false;
                }

                if (hoverData.start || hoverData.end) {
                    return true;
                }

                if (hoverIndeces.start <= offsetIndex && offsetIndex <= hoverIndeces.end) {
                    return true;
                }

                return false;
            });
    }
}
