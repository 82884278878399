import { ICloneable } from '../../interfaces/models/ICloneable';

/**
 * @description
 * Represents the states the training service can be in.
 */
export enum SERVICE_STATUS {
	READY,
	QUEUED,
	IN_PROGRESS
}

/**
 * @description
 * Represents the states a training request can result in.
 */
export enum TRAIN_STATUS {
	SUCCESS,
	FAIL,
	UP_TO_DATE,
	IN_PROGRESS,
	COLLECTING_DATA,
	UPLOADING_MODEL
}

/**
 * @description
 * Progress substatus of the training request.
 */
export enum PROGRESS_SUBSTATUS {
	COLLECTING_DATA = 'CollectingData',
	UPLOADING_MODEL = 'UploadingModel',
	IN_PROGRESS = 'InProgress',
	NONE = ''
}

/**
 * @description
 * Represents the values a LUIS model can be in across a
 * training session.
 */
export enum MODEL_STATUS {
	SUCCESS,
	FAIL,
	UP_TO_DATE,
	IN_PROGRESS,
	TOO_SOON,
	UNKNOWN,
	CAN_START,
	SAMPLING,
	HEALTH,
	QUEUED
}

/**
 * @description
 * Represents a training poll status update message.
 */
export interface ITrainingStatus {
	trainStatus?: TRAIN_STATUS;
	modelCount?: number;
	completedCount?: number;
	failedModel?: ModelTrainStatus;
}

/**
 * @description
 * Represents the training information for a LUIS model.
 *
 * @param id The unique model id.
 * @param status The details of the model training status.
 */
export class ModelTrainStatus implements ICloneable {
	public id: string;
	public status: StatusDetails;

	constructor(id: string = '', status: StatusDetails = null) {
		this.id = id;
		this.status = status;
	}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): ModelTrainStatus {
		return new ModelTrainStatus(apiObject.modelId, StatusDetails.importFromApi(apiObject.details));
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): ModelTrainStatus {
		return new ModelTrainStatus(this.id.slice(), this.status.clone());
	}
}

/**
 * @description
 * Represents the training details for a LUIS model.
 *
 * @param statusId The training status of the model
 * @param labelCount The number of labels for this model.
 * @param time The training time of this model.
 * @param error The error reasong (if any) for this model.
 */
export class StatusDetails implements ICloneable {
	constructor(
		public statusId: MODEL_STATUS = null,
		public labelCount: number = null,
		public time: string = '',
		public error: string = '',
		public progressSubstatus: PROGRESS_SUBSTATUS
	) {}

	/**
	 * @description
	 * Creates a new resource object from the api object received from web.
	 *
	 * @param apiObject The object received by the web api.
	 * @returns A new object of this resource.
	 */
	public static importFromApi(apiObject: any): StatusDetails {
		return new StatusDetails(
			apiObject.statusId,
			apiObject.exampleCount,
			apiObject.trainingDateTime,
			apiObject.failureReason,
			apiObject.progressSubstatus
		);
	}

	/**
	 * @method
	 * @description
	 * Deep clones the resource object.
	 *
	 * @returns A deep clone of this object.
	 */
	public clone(): StatusDetails {
		return new StatusDetails(this.statusId, this.labelCount, this.time.slice(), this.error.slice(), <PROGRESS_SUBSTATUS>(
			this.progressSubstatus.slice()
		));
	}
}
