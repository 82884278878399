import { BehaviorSubject } from 'rxjs/Rx';
import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Injector,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

@Component({
    selector: 'm-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.scss']
})
export class ModalComponent {

    public _closeEventFired = new BehaviorSubject<boolean>(false);
    public _tabbableElements: NodeListOf<Element> = null;

    /** Where the modal content will be inserted in the DOM. */
    @ViewChild('contentInsertionPoint', { read: ViewContainerRef }) public modalContentRef: ViewContainerRef;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector
    ) { }

    /** Creates a modal and allows the passing of data as well. */
    public _createModal<T>(contentComponent: ComponentType<T>, data?: any): ComponentRef<T> {
        // get component type
        const componentType: ComponentType<T> = contentComponent;
        // create component factory
        const factory = this._componentFactoryResolver.resolveComponentFactory(componentType);
        return this.modalContentRef.createComponent(factory, 0, this._injector);
    }

    /** Handles any sort of 'close' event such as a backdrop click, X click, or scroll event. */
    public handleCloseEvent() {
        this._closeEventFired.next(true);
    }

    /** Catches click events inside of the overlay content and stops them
     *  before they reach the backdrop so that the modal is not closed.
     */
    public handleInnerClick(e: Event) {
        e.stopPropagation();
    }
}

// TODO(toanzian): abstract this into its own file (utils maybe?)
/** Allows us to get a type from a component. */
export interface ComponentType<T> {
    new(...args: any[]): T;
}
