/**
 * @description
 * An interface for predict call optional parameters.
 */
export interface IPredictOptions {
	includePatterns: boolean;

	includeMultiIntents: boolean;
}

/**
 * @description
 * The default prediction call options.
 */
export const DEFAULT_PREDICT_OPTIONS: IPredictOptions = {
	includePatterns: false,
	includeMultiIntents: false
};
