import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Rx';

@Component({
    selector: 'sort-icon',
    templateUrl: 'sort-icon.component.html',
    styleUrls: ['sort-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortIconComponent {
    @Input() public sortType: Observable<string>;
}
